import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { LocalizationProvider, DateRangePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { ICompanyFilterForm } from '../../../interfaces/ICompanyFilterForm';
import { StateByRole } from '../../../interfaces/StateByRole';
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 220,
        width: 250,
      },
    },
};

const BootstrapInput = withStyles((theme: Theme) =>
createStyles({
    input: {
    borderRadius: 4,
    border: '1px solid #0000003b',
    padding: '18.5px 32px 18.5px 14px',
    '&:focus': {
        borderRadius: 4,
        borderWidth: 2,
        borderColor: '#3f51b5',
    },
    },
}),
)(InputBase);

interface MoreFiltersCompanyProps{
    titleSection: string;
    formValues: ICompanyFilterForm;
    formErrors: any;
    onChangeForm: any;
    changeFilterDates: any;
    allLaboralSector: Array<IGeneralParameter>;
    allCompanyType: Array<IGeneralParameter>;
    countryCitiesArray:Array<IListParametersInfo>;
    departmentArray: Array<IGeneralParameter>;
    cities: Array<IGeneralParameter>;
    reportForm: boolean;
    stateOptions?: Array<StateByRole>;
    msgForm?: boolean;
}

const MoreFiltersCompany: React.FC<MoreFiltersCompanyProps> = ({titleSection, formValues, formErrors, onChangeForm, changeFilterDates, allLaboralSector, allCompanyType, countryCitiesArray, departmentArray, cities, reportForm, stateOptions, msgForm})=>{
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const handleClickFilters = ()=>{
        setShowFilters(!showFilters)
    }

    return(
        <Grid container item xs={12} className="more-filters-box">
            <Grid container item xs={12} style={{width: '100%'}}>
                <Button variant="contained" className="more-filters-button" onClick={handleClickFilters}>
                    <Typography variant="h4" className="regular-serif">
                        {titleSection}
                    </Typography>
                    <span className="more-button-icon" >{!showFilters? <AddIcon/> : <RemoveIcon/>}</span>
                </Button>
            </Grid>
            {showFilters?
                <Grid container item xs={12} className="more-filters-container">
                    <Grid container item xs={12} style={{height: '300px'}} className="more-filters-form">
                        <Grid container item xs={12}>
                            <Typography variant="h4" className="more-filters-form-title">
                                Información de la empresa
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Razón social</Typography>
                            <TextField
                                fullWidth
                                name="name"
                                margin="none"
                                label={formValues.name === "" ? "Ingrese su razón social": ""}
                                value={formValues.name || ""}
                                onChange={onChangeForm}
                                error={!!formErrors.name}
                                helperText={formErrors.name}
                                InputLabelProps={{shrink: false}}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Nit de la empresa</Typography>
                            <TextField
                                fullWidth
                                name="nit"
                                margin="none"
                                label={formValues.nit === null ? "Ingrese número de nit": ""}
                                value={formValues.nit === null ? "" : formValues.nit}
                                onChange={onChangeForm}
                                error={!!formErrors.nit}
                                helperText={formErrors.nit}
                                InputLabelProps={{shrink: false}}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Sector laboral</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-laboralSector"
                                select
                                name="laboralSector"
                                label={formValues.laboralSector === 0 ? "Seleccione": ""}
                                value={formValues.laboralSector === 0 ? "" : formValues.laboralSector}
                                onChange={onChangeForm}
                                InputLabelProps={{shrink: false}}
                                disabled={allLaboralSector.length>0 ? false : true}
                                margin="none"
                                variant="outlined"
                            >
                                {
                                    allLaboralSector && allLaboralSector.length> 0 &&
                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                }
                                {
                                    allLaboralSector.length> 0 ?
                                    allLaboralSector.map((option: IGeneralParameter) => (
                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.laboralSector}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Tipo de empresa</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-typeOfCompany"
                                select
                                name="typeOfCompany"
                                label={formValues.typeOfCompany === 0 ? "Seleccione": ""}
                                value={formValues.typeOfCompany === 0 ? "" : formValues.typeOfCompany}
                                onChange={onChangeForm}
                                InputLabelProps={{shrink: false}}
                                disabled={allCompanyType.length>0 ? false : true}
                                margin="none"
                                variant="outlined"
                            >
                                {
                                    allCompanyType && allCompanyType.length> 0 &&
                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                }
                                {
                                    allCompanyType.length> 0 ?
                                    allCompanyType.map((option: IGeneralParameter) => (
                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.typeOfCompany}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                        {reportForm &&
                        <Grid item xs={12} sm={6} className="field-box-margin">
                            <FormControl fullWidth>
                                <Typography variant="h5" className="field-box-title">Estado en el sistema</Typography>
                                <Select
                                    id="demo-mutiple-checkbox-state"
                                    multiple
                                    name="state"
                                    value={formValues.state}
                                    onChange={onChangeForm}
                                    disabled={stateOptions && stateOptions.length>0 ? false : true}
                                    input={<BootstrapInput />}
                                    renderValue={(selected) => {
                                        return ((selected as StateByRole[]).map(elm => elm.value)).join(', ')
                                    }}
                                    MenuProps={MenuProps}
                                    variant="outlined"
                                >
                                    {stateOptions && stateOptions.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            // @ts-ignore [1]
                                            value={option}
                                        >
                                            <Checkbox checked={(formValues.state.findIndex(item=>item.value === option.value)) > -1} />
                                            <ListItemText primary={option.value} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        }
                        <Grid container item xs={12}>
                            <Typography variant="h4" className="more-filters-form-title">
                                Ubicación de la empresa
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">País</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-country"
                                select
                                name="country"
                                label={formValues.country === 0 ? "Seleccione": ""}
                                value={formValues.country === 0 ? "" : formValues.country}
                                onChange={onChangeForm}
                                InputLabelProps={{shrink: false}}
                                margin="none"
                                variant="outlined"
                            >
                                {
                                    countryCitiesArray && countryCitiesArray.length> 0 &&
                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                }
                                {countryCitiesArray && countryCitiesArray.length>0 ?
                                    countryCitiesArray.map((option: IListParametersInfo) => (
                                    <MenuItem disabled={option.id === 170 ? false : true} key={option.id} value={option.id}>
                                        {option.grandParentName}
                                    </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.country}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Departamento</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-department"
                                select
                                name="department"
                                label={formValues.department === 0 ? "Seleccione": ""}
                                value={formValues.department === 0 ? "" : formValues.department}
                                onChange={onChangeForm}
                                InputLabelProps={{shrink: false}}
                                margin="none"
                                variant="outlined"
                                disabled={(departmentArray.length>0 && (formValues.country === 170 || formValues.country === 0) ? false : true)}
                            >
                                {
                                    departmentArray && departmentArray.length> 0 &&
                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                }
                                {
                                    departmentArray.length> 0 ?
                                    departmentArray.map((option: IGeneralParameter) => (
                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.department}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Municipio</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-city"
                                select
                                name="city"
                                label={formValues.city === 0 ? "Seleccione": ""}
                                value={formValues.city === 0 ? "" : formValues.city}
                                onChange={onChangeForm}
                                InputLabelProps={{shrink: false}}
                                margin="none"
                                variant="outlined"
                                disabled={(cities && cities.length > 0 && (((formValues.country === 170 || formValues.country === 0) && formValues.department >0) || (formValues.country !== 170 && formValues.country > 0)) ? false : true)}
                            >
                                {
                                    cities && cities.length> 0 &&
                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                }
                                {
                                    cities.length> 0 ?
                                    cities.map((option: IGeneralParameter) => (
                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.city}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                        {msgForm ?
                            <>
                                <Grid container item xs={12}>
                                    <Typography variant="h4" className="more-filters-form-title">
                                        Información adicional
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} xl={4} className="field-box-margin">
                                    <Typography variant="h5" className="field-box-title">Fecha de inscripción al portal</Typography>
                                    <Grid container item xs={12}>
                                        <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale} >
                                            <DateRangePicker
                                                disableFuture
                                                calendars={1}
                                                PopperProps={{
                                                    placement:"bottom"
                                                }}
                                                value={formValues.rangeRegister || null}
                                                onChange={(value)=>changeFilterDates('rangeRegister',value)}
                                                renderInput={(startProps, endProps) => (
                                                    <Grid container item xs={12} alignItems="center">
                                                        <Grid item xs={12} sm={5}>
                                                            <TextField
                                                                {...startProps}
                                                                autoComplete ="off"
                                                                fullWidth
                                                                name="createdAtStart"
                                                                margin="none"
                                                                label={formValues.rangeRegister[0] === null ? "DD/MM/AAAA" : ""}
                                                                error={!!formErrors.createdAtStart}
                                                                helperText={formErrors.createdAtStart}
                                                                InputLabelProps={{shrink: false}}
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={2}>
                                                            <Typography variant="h5" className="field-box-title" style={{textAlign: 'center'}}>
                                                                Hasta
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={5}>
                                                            <TextField
                                                                {...endProps}
                                                                fullWidth
                                                                autoComplete ="off"
                                                                name="createdAtEnd"
                                                                margin="none"
                                                                label={formValues.rangeRegister[1] === null ? "DD/MM/AAAA" : ""}
                                                                error={!!formErrors.createdAtEnd}
                                                                helperText={formErrors.createdAtEnd}
                                                                InputLabelProps={{shrink: false}}
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid container item xs={12}>
                                    <Typography variant="h4" className="more-filters-form-title">
                                        Información adicional
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}  className="field-box-margin ">    
                                <Typography variant="h5" className="field-box-title">Fecha de Activacion en la Bolsa de empleo</Typography>
                                <Grid container item xs={12}>
                                        <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale} >
                                        <DateRangePicker
                                            disableFuture
                                            calendars={1}
                                            PopperProps={{
                                                placement:"bottom"
                                            }}
                                            value={formValues.inJobBankDate || null}
                                            onChange={(value)=>changeFilterDates('inJobBankDate',value)}
                                            renderInput={(startProps, endProps) => (
                                                <Grid container item xs={12} alignItems="center">
                                                    <Grid item xs={12} sm={5}>
                                                        <TextField
                                                            {...startProps}
                                                            autoComplete ="off"
                                                            fullWidth
                                                            name="inJobBankStart"
                                                            margin="none"
                                                            label={formValues.inJobBankDate[0] ===null ? "DD/MM/AAAA" : ""}
                                                            error={!!formErrors.inJobBankStart}
                                                            helperText={formErrors.inJobBankStart}
                                                            InputLabelProps={{shrink: false}}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <Typography variant="h5" className="field-box-title" style={{textAlign: 'center'}}>
                                                            Hasta
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={5}>
                                                        <TextField
                                                            {...endProps}
                                                            fullWidth
                                                            autoComplete ="off"
                                                            name="inJobBankEnd"
                                                            margin="none"
                                                            label={formValues.inJobBankDate[1] ===null ? "DD/MM/AAAA" : ""}
                                                            error={!!formErrors.inJobBankEnd}
                                                            helperText={formErrors.inJobBankEnd}
                                                            InputLabelProps={{shrink: false}}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
            :
                null
            }
        </Grid>
    )
}

export default MoreFiltersCompany;