import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridColDef, GridOverlay, GridColParams, GridCellParams, GridPageChangeParams, GridBaseComponentProps } from '@material-ui/data-grid';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Select from '@material-ui/core/Select';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import Pagination from '@material-ui/lab/Pagination';
import { IUserRole } from '../../../interfaces/IUserRole';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 14,
      padding: '5px 6px 10px 12px',
      height: '30px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  }),
)(InputBase);

interface UsersSIETableProps{
    rows: Array<IUserRole>;
    pageSize: number;
    onChanhePageSize: any;
    initPage: number;
    setInitPage:any;
    handleRowUser: any;
    setCurrentPage: any;
}

const UsersSIETable: React.FC<UsersSIETableProps> = ({ rows, pageSize, onChanhePageSize,  initPage, setInitPage, handleRowUser, setCurrentPage}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [userSelected, setUserSelected] = useState<IUserRole>(new IUserRole());

    const columnsAnnouncement: GridColDef[] = [
        { field: 'name', headerName: 'Nombre', width: 280, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Nombre'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'username', headerName: 'Nombre de usuario', width: 200, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Nombre de usuario'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'documentId', headerName: 'numero de documento', width: 180, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-break">
                    Número de <br/> documento
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="doc-cell">{params.value}</span>
        )} },
        { field: 'status', headerName: 'Estado', width: 130, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Estado'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <strong>
                {params.value === 'Activo' && <div className="active-state state-button-cell">Activo</div>}
                {params.value === 'Inactivo' && <div className="inactive-state state-button-cell">Inactivo</div>}
                {params.value === null && <div className="null-state state-button-cell">Sin estado</div>}
            </strong>
        ) },
        { field: 'rolesString', headerName: 'Rol', width: 250, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Rol'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <strong>
                <span className="doc-cell">{params.value}</span>
            </strong>
        ) },
        { field: 'Acción', headerName: 'Acciones', width: 100, sortable: false ,renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Acciones'}
                </span>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=>handleClick(e,params)}>
                    <SettingsIcon/>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    {
                        userSelected.status === "Activo" &&
                        <MenuItem value="InactivateUser" onClick={(e)=>{handleCloseMenu();handleRowUser("InactivateUser", "Inactivar Usuario", userSelected)}}>Inactivar Usuario</MenuItem>
                    }
                    {
                        (userSelected.status === "Inactivo" || userSelected.status === null) &&
                        <MenuItem value="ActivateUser" onClick={(e)=>{handleCloseMenu();handleRowUser("ActivateUser", "Activar Usuario", userSelected)}}>Activar Usuario</MenuItem>
                    }
                    <MenuItem value="AssignRoles" onClick={(e)=>{handleCloseMenu();handleRowUser('AssignRoles', 'Asociar y desasociar roles', userSelected)}}>Asociar y desasociar roles</MenuItem>
                </Menu>
            </>
        ) },
    ];

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, params:GridCellParams) => {
        setAnchorEl(event.currentTarget);
        const user: IUserRole = Object.assign(params.row);
        setUserSelected(user);
    };

    const handlePage = (pageParams: GridPageChangeParams)=>{
        setCurrentPage(pageParams.page + 1);
        setInitPage(1);
    }


    return(
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid autoHeight className="data-grid-list" disableColumnMenu rows={rows} columns={columnsAnnouncement} pageSize={pageSize} pagination density="compact" disableSelectionOnClick  onPageChange={(params:GridPageChangeParams)=>handlePage(params)}
                components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                        Footer:(props)=>CustomFooter(props, initPage, onChanhePageSize, pageSize)
                }}
                />
            </Grid>
        </Grid>
    )
}
const CustomFooter = (props: GridBaseComponentProps, initPage:number, onChanhePageSize?: any, valueRows?: number)=> {
    const { state, api } = props;
    const rowsPerPageOptions = [5,10,25,50,100];

    useEffect(()=>{
        if(initPage === 0){
            api.current.setPage(0)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[initPage]);

    return (
        <>
            <div className="footer-container">
                <div className="rows-number-container">
                    <span className="rows-number-text">Mostrar</span>
                    <Select
                        id="demo-simple-select"
                        value={valueRows}
                        onChange={onChanhePageSize}
                        input={<BootstrapInput/>}
                    >
                        {rowsPerPageOptions.map(item=>(
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                    <span className="rows-number-text">Registros</span>
                </div>
                <Pagination
                    className="pagination-container"
                    color="primary"
                    page={state.pagination.page+1}
                    count={state.pagination.pageCount}
                    onChange={(event, value) =>api.current.setPage(value-1)}
                    showFirstButton
                    showLastButton
                />
            </div>
        </>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large"/>
            <div className="not-results-text">Sin registros</div>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps)=><KeyboardArrowUpIcon fontSize="small"/>
const customSortDesc = (props: GridBaseComponentProps)=><KeyboardArrowDownIcon fontSize="small"/>

export default UsersSIETable