import { IGeneralParameter } from './IGeneralParameter';

export class IOfferForm{
    id?: number | null = null;
    company: number | null = null;
    jobTitle: string = "";
    nameCompanyVisible: boolean = false;
    typePosition: number = 0;
    jobPosition: number = 1;
    numberVacancy: number = 0;
    description: string = "";
    country: number  = 0;
    department: number = 0;
    city: number;
    laboralSector: number = 0;
    typeContract: number = 0;
    salaryVisible: boolean = false;
    rangeSalary: string = "";
    jobOfferStartDate: Date | null;
    jobOfferEndDate: Date | null;
    estimatedDateCompletion: Date | null = null;
    variableSalary: boolean = false;
    disability: boolean = false;
    telework: boolean = false;
    descriptionVariableSalary: string = "";
    knowlegeArea: Array<IGeneralParameter> = [];
    profession: number = 0;
    professions: Array<IGeneralParameter> = [];
    experience: boolean = false;
    experienceYears: number = 0;
    experienceMonths: number = 0;
    experienceDays: number = 0;
    skill: number = 0;
    skills: Array<IGeneralParameter> = [];
    other_skill: string = "";
    otherSkills: Array<any> = [];
    academicLevel: number = 0;
    languageSelected: number = 0;
    language: Array<IGeneralParameter> = [];
    keyword: string = "";
    keywords: Array<any> = []; 
    occupation: number = 0;
    occupations: Array<IGeneralParameter> = [];
    program: number = 0;
    programs: Array<IGeneralParameter> = [];
    constructor (def:any){
        this.id = def.id || null;
        this.company = def.company || null;
        this.jobTitle = def.jobTitle || "";
        this.nameCompanyVisible = def.nameCompanyVisible || false;
        this.typePosition = def.typePosition || 0;
        this.jobPosition = def.jobPosition || 1;
        this.numberVacancy = def.numberVacancy  || 0;
        this.description = def.description || "";
        this.country = def.country || 0;
        this.department = def.department || 0;
        this.city = def.city || 0;
        this.laboralSector = def.laboralSector || 0;
        this.typeContract = def.typeContract || 0;
        this.salaryVisible = def.salaryVisible || false;
        this.rangeSalary = def.rangeSalary || "";
        this.jobOfferStartDate = def.jobOfferStartDate ?  new Date(def.jobOfferStartDate) : null;
        this.jobOfferEndDate = def.jobOfferEndDate ? new Date(def.jobOfferEndDate) : null
        this.estimatedDateCompletion = def.estimatedDateCompletion ?  new Date(def.estimatedDateCompletion) : null;
        this.variableSalary = def.variableSalary || false;
        this.descriptionVariableSalary = def.descriptionVariableSalary || "";
        this.disability = def.disability || false;
        this.telework = def.telework || false;
        this.knowlegeArea = def.knowlegeAreaParams ? def.knowlegeAreaParams : [];
        this.profession = def.profession || 0;
        this.professions = def.professionsParams || [];
        this.experience = def.experience || false;
        this.experienceYears = def.experienceYears || 0;
        this.experienceMonths = def.experienceMonths || 0;
        this.experienceDays = def.experienceDays || 0;
        this.skill = def.skill || 0;
        this.skills = def.skillsParams ? def.skillsParams : [];
        this.otherSkills = def.otherSkillsParams ? def.otherSkillsParams : [];
        this.keywords = def.keywordsParams ? def.keywordsParams : [];
        this.occupation = def.occupation || 0;
        this.occupations = def.occupationsParams ? def.occupationsParams : [];
        this.program = def.program || 0;
        this.programs = def.programsParams ? def.programsParams : [];
        this.academicLevel = def.academicLevel || 0;
        this.languageSelected = def.languageSelected || 0;
        this.language = def.languageParams ? def.languageParams : [];
    }
}