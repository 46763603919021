import React from 'react'
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import {Link}  from 'react-router-dom';
import imgHomeEgresados from '../../../assets/images/IMG_HOME_EGRESADOS.png';
import './CheckStateBox.scss';

const CheckStateBox: React.FC = () => {
  return (

      <Grid container className="landing-consult-container" >
        <Grid container item  xs={12} md={6} >
          <Grid container className="first-part">
            <img src={imgHomeEgresados} alt="consulta estado" className="person-img"/>
            <Grid container item md={6} className="text-container">
              <Typography variant="h1" className="title-container">
                  Servicios para egresados
              </Typography>
              <Typography variant="h5" className="consult-text">
                En esta sección puede informarse y acceder a los servicios que la Universidad Nacional de Colombia ofrece a sus egresados. También puede consultar el estado de su registro
              </Typography>
            </Grid>
          </Grid>
          <Grid container item direction="column" xs={12}  className="container-btn">
            <Button variant="contained" className="consult-btn" size="small" component ={ Link } to="/consulta-estado">
              <Typography variant="h6" className="regular-serif">
                Consultar el estado de su registro
              </Typography>
            </Button>
          </Grid>
          <Grid container item xs={12} className="more-info-container">
              <Link className="more-info-link" to="/">
                  Más información
              </Link>
          </Grid>
        </Grid>

        <Grid  container item  xs={12} md={6} direction="column"  className="general-box">
          <Grid >
            <Typography variant="h1" className="general-link first-link">Comunidad universitaria</Typography>
            <Typography variant="h1" className="general-link second-link">Beneficios para los egresados</Typography>
             <a target='_blank' rel='noopener' href={'https://drive.google.com/drive/folders/13w461b0LiTux6OpB5vAGJfLppvDdx9KC?usp=sharing'}>
              <Typography variant="h1" className="general-link third-link">Instructivos</Typography>
            </a>
            <Typography variant="h1" className="general-link fourth-link">Orientación Ocupacional</Typography>
          </Grid>
        </Grid>
      </Grid>

  )
}

export default CheckStateBox
