import { IPresignedUrl, IPreSignedUrlReq } from '../interfaces/IPreSignedUrl';
const baseUrl = process.env.REACT_APP_SIE_APP_PROXY_BASE_URL;

export const getSignUrl = async (bodyRequest:IPreSignedUrlReq) => {
    try {
       const response = await fetch(
           `${baseUrl}/filesystem/sign-url`,
           {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(bodyRequest)
        }
       );
       if (response.status === 200) {
           const urlObj: IPresignedUrl = await response.json();
           return urlObj;
       }
   } catch (error) {
       return error
   }
}

export const uploadFileSignedUrl = async (url: string, bodyRequest: File) => {
    try {
       const response = await fetch(
           url,
           {
            method: 'PUT',
            body: bodyRequest
        }
       );
       if (response.status === 200) {
           return response;
       }
   } catch (error) {
       return error
   }
}

export const deleteFileS3 = async (folderFileKey:string) => {
    try {
        const response = await fetch(
            `${baseUrl}/filesystem/deleteDocument/${folderFileKey}`,
            {
                method: 'DELETE'
            }
        );
        return response
    } catch (error) {
        return error
    }
}

