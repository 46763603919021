export class leaveReasons {
  reason: string = "";
  description: string = "";
}
export class IProfileCompany{
  id?: number | null = null;
  name: string = "";
  nit: number | null = null;
  verificationDigit: number | null = null;
  typeOfCompany: number = 0;
  typeOfPerson: number = 0;
  laboralSector: number = 0;
  country: number = 0;
  department: number = 0;
  city: number = 0;
  address: string = "";
  phone: string = "";
  website: string = "";
  contactName: string = "";
  position: string = "";
  email: string = "";
  status: string = "";
  phoneContact: string = "";
  cellphoneCompany: string = "";
  cellphoneContact: string = "";
  createdAt: string = "";
  termsOfUse: boolean = false;
  updatedAt: string = "";
  infoContactName: string = "";
  infoContactPhone: string = "";
  infoContactCellphone: string = "";
  infoContactMail: string = "";
  repLegalCountry: number = 0;
  repLegalDepartment: number = 0;
  repLegalCity: number = 0;
  sieRetirement: boolean = false;
  reasons: leaveReasons | null = null;
}

export class IHeadquarter{
  nit: number = 0;
  name: string = "";
  city: number = 0;
  address: string = "";
  phone: string = "";
}

export interface IProfileCompanyStructure{
  company:IProfileCompany;
  headquarter: Array<IHeadquarter>;
}