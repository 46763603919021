import { IGeneralParameter } from "./IGeneralParameter";
import { DateRange } from '@material-ui/pickers';
import { StateByRole } from "./StateByRole";
import { AttachingFile } from './IGraduateFilterObjMsg';

export class IGraduateFilterForm{
    names: string = "";
    surname: string = "";
    gender: number = 0;
    doc: string = "";
    state: Array<StateByRole> = [];
    updateDate: DateRange<Date> = [null,null];
    campus: number = 0;
    school: Array<IGeneralParameter> = [];
    curriculum: Array<IGeneralParameter> = [];
    academicLevel: number = 0;
    graduationDate: DateRange<Date> = [null,null];
    typeOfAssociation: number = 0;
    association: number = 0;
    inJobBank: boolean | undefined = undefined;
    inJobBankDate: DateRange<Date> = [null,null];
    extraEmail: string = "";
    aditionalEmails: Array<string> = [];
    attachingFiles:Array<AttachingFile>=[];
    subject: string = "";
    html: string = "";
    observation: number = 0;
    otherObservation: string = "";

}