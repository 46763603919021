import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from "@material-ui/core/TextField"
import Typography from '@material-ui/core/Typography';
import RegisterContext from "../../../utils/RegisterContext";
import { IRegisterGraduate } from '../../../interfaces/IRegisterGraduate';
import { IGeneralParameter } from "../../../interfaces/IGeneralParameter";

import '../Stepper.scss';

const LaboralInfo: React.FC = () => {
  const registerContext = React.useContext(RegisterContext);
  const formValues: IRegisterGraduate = registerContext.formValues;
  const isValidFirstStep =
    registerContext.firstAccorDone &&
    registerContext.secondAccorDone &&
    registerContext.thirdAccorDone
  const isValid =
    formValues.currentJobSituation > 0

  useEffect(()=>{
    registerContext.handleValidForm('panel3', isValid);
  })


  return (
    <>
      <Grid container spacing={2}>

        <Grid item xs={12} sm={6} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Situación laboral Actual*</Typography>
          <TextField
            fullWidth
            id="outlined-select-currentJobSituation"
            select
            name="currentJobSituation"
            label={formValues.currentJobSituation === 0 ? "Seleccione": ""}
            value={formValues.currentJobSituation === 0 ? "" : formValues.currentJobSituation}
            onChange={registerContext.handleChange}
            InputLabelProps={{shrink: false}}
            margin="none"
            variant="outlined"
          >
            {
              registerContext.allEmploySituation && registerContext.allEmploySituation.length>0 ?
              registerContext.allEmploySituation.map((option: IGeneralParameter) => (
                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                  {option.name}
                </MenuItem>
              ))
            :
              <MenuItem value={formValues.currentJobSituation}></MenuItem>
            }
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Tipo de empresa</Typography>
          <TextField
            fullWidth
            id="outlined-select-typeOfCompany"
            select
            name="typeOfCompany"
            label={formValues.typeOfCompany === 0 ? "Seleccione": ""}
            value={formValues.typeOfCompany === 0 ? "" : formValues.typeOfCompany}
            onChange={registerContext.handleChange}
            InputLabelProps={{shrink: false}}
            margin="none"
            variant="outlined"
            disabled={(formValues.currentJobSituation === 3 || formValues.currentJobSituation === 4) ? false : true}
          >
            {
              registerContext.allCompanyType && registerContext.allCompanyType.length> 0 &&
              <MenuItem value={0}><em>Ninguno</em></MenuItem>
            }
            {
              registerContext.allCompanyType && registerContext.allCompanyType.length>0 ?
              registerContext.allCompanyType.map((option: IGeneralParameter) => (
                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                  {option.name}
                </MenuItem>
              ))
            :
              <MenuItem value={formValues.typeOfCompany}></MenuItem>
            }
          </TextField>
        </Grid>

        <Grid item xs={12} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Sector laboral</Typography>
          <TextField
            fullWidth
            id="outlined-select-laboralSector"
            select
            name="laboralSector"
            label={formValues.laboralSector === 0 ? "Seleccione": ""}
            value={formValues.laboralSector === 0 ? "" : formValues.laboralSector}
            onChange={registerContext.handleChange}
            InputLabelProps={{shrink: false}}
            margin="none"
            variant="outlined"
            disabled={(formValues.currentJobSituation === 3 || formValues.currentJobSituation === 4) ? false : true}
          >
            {
              registerContext.allLaboralSector && registerContext.allLaboralSector.length> 0 &&
              <MenuItem value={0}><em>Ninguno</em></MenuItem>
            }
            {
              registerContext.allLaboralSector && registerContext.allLaboralSector.length>0 ?
              registerContext.allLaboralSector.map((option: IGeneralParameter) => (
                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                  {option.name}
                </MenuItem>
              ))
            :
              <MenuItem value={formValues.laboralSector}></MenuItem>
            }
          </TextField>
        </Grid>

      </Grid>

      <div style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          className="accordion-btn"
          endIcon={<ExpandMoreIcon/>}
          disabled={!isValid}
          onClick={isValid ? (isValidFirstStep ? registerContext.handleChangeAccordion('panel3',false, 'panel3') :registerContext.handleChangeAccordion('panel3',true, 'panel3')) : null}
        >
          Terminar
        </Button>
      </div>

    </>
  )
}

export default LaboralInfo
