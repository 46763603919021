import React, { useState, useEffect }  from 'react';
import Grid from '@material-ui/core/Grid';
import ProfileGraduateStateFilters from '../../../../components/GraduateProfile/ProfileGraduateStateFilters';
import UserContext from '../../../../utils/UserContext';
import AlertBox from '../../../../components/ShareComponents/AlertBox';
import AdditionalInformationProfile from '../../../../components/GraduateProfile/AdditionalInformationProfile';

import '../graduate.scss'

const GraduateProfileView: React.FC = () => {
    const userContext = React.useContext(UserContext);
    const [selectedFilter, setSelectedFilter] = useState<string>('');

    useEffect(()=>{
        if(userContext.stateProfileSection){
            setSelectedFilter(userContext.stateProfileSection)
        } else {
            setSelectedFilter('Mi perfil')
        }
    },[userContext.stateProfileSection])

    const handleClick = async(e:any, state:string)=>{
        setSelectedFilter(state);
        userContext.setStateProfileSection(state);
    }

    return (
        <>
            <Grid item xs={12} className="state-offers-section">
                <ProfileGraduateStateFilters selected={selectedFilter} handleClick={handleClick}/>
            </Grid>
            <Grid container className="main-container">
                <Grid container item xs={12}>
                    {
                        userContext.stateProfileSection === "Información adicional" &&
                        <>
                            <AlertBox
                                title="Información"
                                alert="info"
                                variantType="outlined"
                            >
                                <span className="alert-simple-text">
                                    En esta sección usted puede ver y actualizar su curriculum, también puede adjuntar su hoja de vida
                                </span>
                            </AlertBox>
                        </>
                    }
                </Grid>
                {
                userContext.stateProfileSection === "Mi perfil" &&
                <>
                    <Grid container item xs={12}>
                            Mi perfil
                    </Grid>
                </>
                }
                {
                    userContext.stateProfileSection === "Información adicional" &&
                    <>
                        <Grid container item xs={12} className="additional-info-profile-container">
                            <AdditionalInformationProfile/>
                        </Grid>
                    </>
                }
            </Grid>
        </>
    )
}

export default GraduateProfileView
