import React from 'react';
import Stepper from '../../../components/Stepper/GraduateStepper';
import Grid from "@material-ui/core/Grid";
import NotificationBox from '../../../components/ShareComponents/NotificationBox';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import './SignUpGraduate.scss';

const SignUpGraduate: React.FC = ()=>{
    return(
        <Grid container className="signup-container">
            <Grid container className="main-container">
                <Grid container item xs={12}>
                    <Grid container item xs={12} lg={10}>
                        <Stepper/>
                    </Grid>
                    <Grid container item xs={12} lg={2} justify="center">
                        <NotificationBox
                            bgColor="#fff"
                            title="Registro egresados"
                            titleColor="#7DA52D"
                            description=""
                            descColor="#7DA52D"
                            positionBox="sticky"
                            icon={<ErrorOutlineIcon style={{color: '#7DA52D'}} fontSize="large"/>}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SignUpGraduate