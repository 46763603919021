import React, { useState, useEffect }  from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { usePromiseTracker } from 'react-promise-tracker';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import GradeIcon from '@material-ui/icons/Grade';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import NewsList from '../../../components/NewsAndEvents/NewsList';
import {getEventsNews} from '../../../services/NewsEventsService';
import { IGetNewEvent } from '../../../interfaces/IGetNewEvent';
import ButtonBackListOffers from '../../../components/LaboralOffers/ButtonBackListOffers';
import LoadingLockPage from '../../../components/ShareComponents/LoadingLockPage';
import { IFilterNewsEvents } from '../../../interfaces/IFilterNewsEvents';
import Validations from '../../../helpers/Validations';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { getCATEGORY } from '../../../services/ParameterService';
import { sortByKey } from '../../../helpers/sharedFunctions';
import NewsEventsSearch from '../../../components/NewsAndEvents/NewsEventsSearch';
import ShowFiltersApplied from '../../../components/ManagementState/ShowFiltersApplied';
import { ChipData } from '../../../interfaces/ChipData';

import '../NewsAndEvents.scss';


const newsValidations = {
  keyWord: {
    error: "",
    validate: "textNumber",
    required: false
  },
  category: {
      validate: "select",
      required: false
  },
  date: {
    error: "",
    validate: "normalDate",
    required: false
  }
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
      className="tab-news-events-panel-container"
    >
      {value === index && (
        <Box p={3}>
          <Grid container spacing={1}>
            {children}
          </Grid>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: any)=> {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const NewsPageView: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker();
  const [urlBack, setUrlBack] = useState<string>('');
  const [textBack, setTextBack] = useState<string>('');
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [value, setValue] = React.useState(0);
  const [dataFiltered, setDataFiltered] = useState<Array<IGetNewEvent>>([]);
  const [prevData, setPrevData] = useState<Array<IGetNewEvent>>([]);
  const [allNews, setAllNews] = useState<Array<IGetNewEvent>>([]);
  const [outstandingNews, setOutstandingNews] = useState<Array<IGetNewEvent>>([]);
  const [todayNews, setTodayNews] = useState<Array<IGetNewEvent>>([]);
  const [filterValues, setFilterValues] = useState<IFilterNewsEvents>(new IFilterNewsEvents());
  const [formErrors, setFormErrors] = useState<IFilterNewsEvents>(new IFilterNewsEvents());
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [chipData, setChipData] = useState<ChipData[]>([]);
  const [categoryArray, setCategoryArray] = useState<Array<IGeneralParameter>>([]);
  const isValid =
    (
      filterValues.keyWord.length > 0 &&
      !formErrors.keyWord &&
      !formErrors.date
    ) ||
    (
      !formErrors.keyWord &&
      !formErrors.date &&
      filterValues.category > 0
    ) ||
    (
      !formErrors.keyWord &&
      !formErrors.date &&
      moment(filterValues.date).isValid()
    )

  useEffect(() => {
    window.scrollTo(0, 0);
    const urlPath = window.location.href;
    if(urlPath.includes('empresa')){
      setUrlBack('/empresa/dashboard');
      setTextBack('Ir al dashboard');
    } else if (urlPath.includes('egresado')){
      setUrlBack('/egresado/dashboard');
      setTextBack('Ir al dashboard');
    } else {
      setUrlBack('/');
      setTextBack('Regresar al home');
    }
    const getNews = async() =>{
      let resAlltList: Array<IGetNewEvent> = [];
      const getAllNews: Response = await getEventsNews({type: "Noticia", state:"Publicado"});
      if (getAllNews.status === 200){
        resAlltList = (await getAllNews.json()).publishings;
        setAllNews(resAlltList);
        setDataFiltered(resAlltList);
        setPrevData(resAlltList);
      }
      const getOustandingNews: Response = await getEventsNews({type: "Noticia", highlight: true});
      if (getOustandingNews.status === 200){
        let resOustandingList = (await getOustandingNews.json()).publishings;
        setOutstandingNews(resOustandingList);
      }
      const resTodayList = resAlltList.filter(item=> moment(item.publishDate).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD"));
      setTodayNews(resTodayList);
      let responseCategory:Array<IGeneralParameter> = await getCATEGORY();
      responseCategory = sortByKey(responseCategory, 'name');
      setCategoryArray(responseCategory);
      setInitLoad(false);
    }
    getNews();
  }, [])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    let data: Array<IGetNewEvent> = [];
    if(newValue === 1){
      data=outstandingNews;
      setDataFiltered(outstandingNews);
      setPrevData(outstandingNews);
    } else if (newValue === 2){
      data=todayNews;
      setDataFiltered(todayNews);
      setPrevData(todayNews);
    } else {
      data=allNews;
      setDataFiltered(allNews);
      setPrevData(allNews);
    }
    filterData(data);
  };

  const onChangeFilters = (e: any) =>{
    const { name, value } = e.target;
    setFilterValues(prev => ({
      ...prev,
      [name]: value
    }));
    const error = Validations(name, value, newsValidations) || "";
    setFormErrors(prev=>({
        ...prev,
        [name]: error
    }));
    if(name === 'keyWord'){
      if(value !== ''){
          setSearchActive(true)
      } else {
          setSearchActive(false)
      }
    }
  }

  const handleChangeDate = (dateValue: Date | null, nameField: string) => {
    const value =dateValue ? dateValue?.toString() : '';
    setFilterValues(prev => ({
      ...prev,
      [nameField]: dateValue
    }));
    const error: string = Validations(nameField, value, newsValidations) || "";
    setFormErrors(prev=>({
      ...prev,
      [nameField]: error
    }));
  }

  const cancelSearch = ()=>{
    setFilterValues(prev => ({
        ...prev,
        keyWord: ''
    }));
    setFormErrors(prev=>({
        ...prev,
        keyWord: ''
    }));
    setSearchActive(false)
  }

  const clearFilter = ()=>{
    setFilterValues(prev => ({
      ...prev,
      keyWord: '',
      category: 0,
      date: ''
    }));
    setSearchActive(false);
    setDataFiltered(prevData);
    setChips('clear');
  }

  const applyFilters = () =>{
    setChips('apply');
    filterData(prevData);
  }

  useEffect(()=>{
    if(!isValid){
      if(filterValues.keyWord==="" && filterValues.category === 0 && !filterValues.date){
        setDataFiltered(prevData);
        setChips('clear');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isValid])

  const filterData = (dataToFilter: Array<IGetNewEvent>) => {
    let resultKeyword: Array<IGetNewEvent> = [];
    let resultCategory: Array<IGetNewEvent> = [];
    let resultDate: Array<IGetNewEvent> = [];
    if(filterValues.keyWord.length>0){
      resultKeyword = dataToFilter.filter(item=>item.name.toLowerCase().includes(filterValues.keyWord.toLowerCase()));
    } else {
      resultKeyword = dataToFilter;
    }
    if(filterValues.category>0){
      resultCategory = resultKeyword.filter(item=>item.category === filterValues.category);
    } else {
      resultCategory = resultKeyword;
    }
    if(filterValues.date){
      resultDate = resultCategory.filter(item=>moment(item.publishDate).format("YYYY-MM-DD") === moment(filterValues.date).format("YYYY-MM-DD"));
    } else {
      resultDate = resultCategory;
    }
    setDataFiltered(resultDate)
  }

  const setChips = (action: string) =>{
    let tempArray: Array<ChipData> = [];
    let index = 0;
    const fieldNames=[
      {key: 'keyWord', description: 'Palabra clave'},
      {key: 'category', description: 'Categoria'},
      {key: 'date', description: 'Fecha de publicación'},
    ];
    if(action === "clear"){
        setChipData([]);
    } else {
      for (const [key, value] of Object.entries(filterValues)) {
        if(value!==0 && value!=='' && value!==null ){
          let obj;
          const fieldObj = fieldNames.find(field=>field.key === key);
          if (key === 'category'){
            obj = categoryArray.find(category=>category.parameter_code === value);
          }
          if(obj){
            tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + obj.name })
          } else {
            tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + (key === "date" ? moment(value).format("dddd, DD MMMM YYYY") : value)})
          }
          index++;
        }
      }
      setChipData(tempArray);
    }
  }

  return (
    <>
    <div>
      <Grid container item xs={12} className="back-offers-list-section">
        <ButtonBackListOffers url={urlBack} text={textBack}/>
      </Grid>
      <Grid container item xs={12} className="new-events-list-section">
          <Grid container className="main-container">
              <Grid container item xs={12} md={3} lg={2}>
                  <Grid className="title-news-events-section">
                    <Typography variant="h1" className="news-events-title" >
                      Noticias
                    </Typography>
                  </Grid>
              </Grid>
              <Grid container item xs={12}>
                <NewsEventsSearch
                  type="noticias"
                  filterValues={filterValues}
                  formErrors={formErrors}
                  onChangeFilters={onChangeFilters}
                  handleChangeDate={handleChangeDate}
                  searchActive={searchActive}
                  cancelSearch={cancelSearch}
                  categoryArray={categoryArray}
                  isValid={isValid}
                  applyFilters={applyFilters}
                  clearFilter={clearFilter}
                />
              </Grid>
              <Grid container item xs={12}>
                <ShowFiltersApplied chipData={chipData}/>
              </Grid>
              <Grid className="admin-news-events-tabs">
                <AppBar position="static" className="nav-news-events-management">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="simple tabs example"
                    className="tabs-management tabs-management-admin"
                  >
                    <Tab className="border-icon" icon={<AllInboxIcon />} label="Todos" {...a11yProps(0)} />
                    <Tab className="border-icon" icon={<GradeIcon />} label="Destacadas" {...a11yProps(1)} />
                    <Tab icon={<CalendarTodayIcon />} label="Hoy" {...a11yProps(2)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  {
                    dataFiltered.length>0 &&
                    <Grid container item xs={12}>
                      <NewsList data={dataFiltered} />
                    </Grid>
                  }
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {
                    dataFiltered.length>0 &&
                    <Grid container item xs={12}>
                      <NewsList data={dataFiltered} />
                    </Grid>
                  }
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {
                    dataFiltered.length>0 &&
                    <Grid container item xs={12}>
                      <NewsList data={dataFiltered} />
                    </Grid>
                  }
                </TabPanel>
                <LoadingLockPage load={initLoad}/>
                <LoadingLockPage load={promiseInProgress}/>
              </Grid>
          </Grid>
      </Grid>
    </div>
    </>
  )
}

export default NewsPageView
