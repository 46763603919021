import React, { useState, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './ShowFilters.scss';


interface ShowFiltersProps{
    titleSection: string;
    filtersData: Array<any>;
    stateCheck?:any;
    showDelete?: boolean;
    deleteFilter?: any;
    isValid?: boolean;
    onApplyFilters?: any;
    showButtons: boolean;
}

const ShowFilters:React.FC<ShowFiltersProps> = ({titleSection, filtersData, stateCheck, showButtons, showDelete, deleteFilter, isValid, onApplyFilters})=>{
    const [stateSelect, setStateSelected]= useState<boolean>(false);

    useEffect(()=>{
        if(stateCheck){
            const foundSelected = Object.keys(stateCheck).filter(function(key) {
                return stateCheck[key] === true;
            });
            if(foundSelected.length>0){
                setStateSelected(true)
            } else {
                setStateSelected(false)
            }
        }
    },[stateCheck])

    return(
        <Grid container item xs={12} className="show-filters-container">
            {
                filtersData.length > 0 &&
                <Grid container item xs={12} style={{margin: '8px 0'}}>
                    <Typography variant="h4" className="bold-serif-primaryColor">{titleSection}</Typography>
                </Grid>
            }
            <Paper component="ul" style={{backgroundColor: 'transparent'}}>
                {filtersData.map((data) => {
                    return (
                        <li key={data.key}>
                            <Chip
                            label={data.label}
                            onDelete={showDelete ? deleteFilter(data) : null}
                            className="chip-styles"
                            />
                        </li>
                    );
                })}
            </Paper>
            {
                showButtons && (stateSelect || filtersData.length>0) &&
                <Grid item xs={12} className="apply-filters-box">
                    {
                        (filtersData.length>0) &&
                        <Button variant="contained" className="apply-filters-button" style={{background:'red'}} onClick={(value)=>onApplyFilters(value,'clear')}>
                            <Typography variant="h5" className="apply-filters-text">Limpiar filtros</Typography>
                        </Button>
                    }
                    {
                        (stateSelect || filtersData.length>0) &&
                        <Button disabled={!isValid} variant="contained" className="apply-filters-button" onClick={(value)=>onApplyFilters(value,'apply')}>
                            <Typography variant="h5" className="apply-filters-text">Aplicar filtros</Typography>
                        </Button>
                    }
                </Grid>
            }
        </Grid>
    )
}

export default ShowFilters;