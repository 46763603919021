import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import './ButtonColors.scss';

const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    }
});

const ButtonColors: React.FC = ()=>{
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={3} className="line-one line-height">
                </Grid>
                <Grid item xs={3} className="line-two line-height">
                </Grid>
                <Grid item xs={3} className="line-three line-height">
                </Grid>
                <Grid item xs={3} className="line-fourth line-height">
                </Grid>
            </Grid>
        </div>
    )
}

export default ButtonColors