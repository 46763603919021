import { IRole } from "./IRole";

export class IUserRole{
    id?: number = 0;
    name: string = "";
    userId: number = 0;
    username: string = "";
    status: string = "";
    documentId: string = "";
    roles: Array<IRole> = [];
    rolesString?: string = "";
    rolesIds?: Array<number> = [];
}