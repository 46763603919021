import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import './AlertBox.scss';
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

interface IAlertBox{
  title?: string;
  alert: any;
  variantType:any;
  children?: any;
}

const AlertBox: React.FC<IAlertBox> = ({title, alert, variantType, children}) => {
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <Collapse in={open}
    className="margin-collapse"
    >
      <Alert variant={variantType.length>0 ? variantType : "standard"} severity={alert}
      action={
        <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={() => {
            setOpen(false);
        }}
        >
        <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      className="text-alert"
      >
        <AlertTitle>
          <Typography variant="h3" className="bold-serif-primaryColor">
            {title}
          </Typography>
        </AlertTitle>
        {children}
      </Alert>
    </Collapse>
    </div>
  )
}

export default AlertBox
