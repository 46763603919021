import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridColDef, GridOverlay, GridColParams, GridCellParams, GridBaseComponentProps } from '@material-ui/data-grid';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

interface UsersLDAPTableProps {
    rows: Array<any>;
    handleRowSelected: any;
}

const UsersLDAPTable: React.FC<UsersLDAPTableProps> = ({ rows, handleRowSelected }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [rowSelected, setRowSelected] = useState<any>({});
    const pageSize = 10;

    const columnsAnnouncement: GridColDef[] = [
        { field: 'fullname', headerName: 'Nombre/Apellidos', width: 300, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Nombre/Apellidos'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'username', headerName: 'Nombre de usuario', width: 250, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Nombre de usuario'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="doc-cell">{params.value}</span>
        )} },
        { field: 'documentId', headerName: 'Número de identificación', width: 250, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Número de documento'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="doc-cell">{params.value}</span>
        )} },
        { field: 'email', headerName: 'Correo electrónico', width: 220, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Correo electrónico'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
            return(
                <span className="doc-cell">{params.value}</span>
        )} },
        { field: 'Acción', headerName: 'Registrar', width: 100, sortable: false ,renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Registrar'}
                </span>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=>handleClick(e,params)}>
                    <SettingsIcon/>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem value={1} onClick={(e)=>{handleCloseMenu();handleRowSelected(rowSelected)}}>Registrar usuario</MenuItem>
                </Menu>
            </>
        ) },
    ];

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, params:GridCellParams) => {
        setAnchorEl(event.currentTarget);
        const user: any = Object.assign(params.row);
        setRowSelected(user)
    };

    return(
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid autoHeight className="data-grid-list" disableColumnMenu rows={rows} columns={columnsAnnouncement} pageSize={pageSize} pagination density="compact" disableSelectionOnClick
                components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                }}
                />
            </Grid>
        </Grid>
    )
}


const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large"/>
            <div className="not-results-text">Sin registros</div>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps)=><KeyboardArrowUpIcon fontSize="small"/>
const customSortDesc = (props: GridBaseComponentProps)=><KeyboardArrowDownIcon fontSize="small"/>

export default UsersLDAPTable