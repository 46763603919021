import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { DateRangePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { IFilterOfferForm } from '../../../interfaces/IFilterOfferForm';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { StateByRole } from '../../../interfaces/StateByRole';
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';

import './AnnouncementFilters.scss';


const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 220,
        width: 250,
      },
    },
};

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        input: {
            borderRadius: 4,
            border: '1px solid #0000003b',
            padding: '18.5px 32px 18.5px 14px',
            '&:focus': {
                borderRadius: 4,
                borderWidth: 2,
                borderColor: '#3f51b5',
            },
        },
    }),
)(InputBase);

interface AnnouncementFiltersProps{
    title: string;
    formValues: IFilterOfferForm;
    formErrors: any;
    onChangeForm: any;
    changeFilterDates?: any;
    allLaboralSector?: Array<IGeneralParameter>;
    allTypePositions?: Array<IGeneralParameter>;
    stateOptions?: Array<StateByRole>;
    countryCitiesArray?:Array<IListParametersInfo>;
    departmentArray?: Array<IGeneralParameter>;
    cities?: Array<IGeneralParameter>;
    reportForm: boolean;
}

const AnnouncementFilters: React.FC<AnnouncementFiltersProps> = ({title, formValues, formErrors, onChangeForm, changeFilterDates, allLaboralSector, allTypePositions, stateOptions,countryCitiesArray, departmentArray, cities, reportForm})=>{

    const urlPath = window.location.href;
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [showCompanyFilters, setShowCompanyFilters] = useState<boolean>(false);

    useEffect(()=>{
        if(urlPath.includes("/empresa-info")){
            setShowCompanyFilters(false);
        } else {
            setShowCompanyFilters(true);
        }
    },[urlPath])

    const handleClickFilters = ()=>{
        setShowFilters(!showFilters)
    }

    return(
        <Grid container item xs={12} className="announcement-filters-box">
            <Grid container item xs={12} style={{width: '100%'}}>
                <Button variant="contained" className="announcement-filters-button" onClick={handleClickFilters}>
                    <Typography variant="h3" className="bold-serif">
                        {title}
                    </Typography>
                    <span className="announcement-button-icon">{!showFilters? <AddIcon/> : <RemoveIcon/>}</span>
                </Button>
            </Grid>

            {showFilters ?
            <Grid container item xs={12} className="announcement-filters-container">
                <Grid container item xs={12} style={{height: '300px'}}className="announcement-filters-form">
                    {reportForm &&
                        <Grid container item xs={12}>
                            <Typography variant="h4" className="bold-serif-primaryColor">
                                Sobre el cargo
                            </Typography>
                        </Grid>
                    }
                    {reportForm &&
                        <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                            <Typography variant="h5" className="field-box-title">Nombre del cargo</Typography>
                            <TextField
                                fullWidth
                                name="jobTitle"
                                margin="none"
                                label={formValues.jobTitle === "" ? "Ingrese Nombre del cargo": ""}
                                value={formValues.jobTitle }
                                onChange={onChangeForm}
                                error={!!formErrors.jobTitle}
                                helperText={formErrors.jobTitle}
                                InputLabelProps={{shrink: false}}
                                variant="outlined"
                            />
                        </Grid>
                    }
                    {reportForm &&
                        <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                            <Typography variant="h5" className="field-box-title">Sector laboral</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-laboralSector"
                                select
                                name="laboralSector"
                                label={formValues.laboralSector === 0 ? "Seleccione": ""}
                                value={formValues.laboralSector === 0 ? "" : formValues.laboralSector}
                                onChange={onChangeForm}
                                error={!!formErrors.laboralSector}
                                helperText={formErrors.laboralSector}
                                InputLabelProps={{shrink: false}}
                                disabled={allLaboralSector && allLaboralSector.length>0 ? false : true}
                                margin="none"
                                variant="outlined"
                            >
                                {
                                    allLaboralSector && allLaboralSector.length> 0 &&
                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                }
                                {
                                    allLaboralSector && allLaboralSector.length> 0 ?
                                    allLaboralSector.map((option: IGeneralParameter) => (
                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                        {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.laboralSector}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                    }
                    {reportForm &&
                        <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                            <Typography variant="h5" className="field-box-title field-title-typePosition">Tipo de cargo</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-typePosition"
                                select
                                name="typePosition"
                                label={formValues.typePosition === 0 ? "Seleccione": ""}
                                value={formValues.typePosition === 0 ? "" : formValues.typePosition}
                                onChange={onChangeForm}
                                error={!!formErrors.typePosition}
                                helperText={formErrors.typePosition}
                                InputLabelProps={{shrink: false}}
                                margin="none"
                                variant="outlined"
                            >
                                {
                                    allTypePositions && allTypePositions.length> 0 &&
                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                }
                                {
                                    allTypePositions && allTypePositions.length>0 ?
                                    allTypePositions.map((option: IGeneralParameter) => (
                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                        {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.typePosition}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                    }
                    <Grid container item xs={12}>
                        <Typography variant="h4" className="bold-serif-primaryColor">
                            Sobre La oferta laboral
                        </Typography>
                    </Grid>
                    {reportForm &&
                        <Grid item xs={12} sm={6} className="field-box-margin">
                            <FormControl fullWidth>
                                <Typography variant="h5" className="field-box-title">Estado en el sistema</Typography>
                                <Select
                                    id="demo-mutiple-checkbox-state"
                                    multiple
                                    name="state"
                                    value={formValues.state}
                                    onChange={onChangeForm}
                                    disabled={stateOptions && stateOptions.length>0 ? false : true}
                                    input={<BootstrapInput />}
                                    renderValue={(selected) => {
                                        return ((selected as StateByRole[]).map(elm => elm.value)).join(', ')
                                    }}
                                    MenuProps={MenuProps}
                                    variant="outlined"
                                >
                                {stateOptions && stateOptions.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        // @ts-ignore [1]
                                        value={option}
                                    >
                                        <Checkbox checked={(formValues.state.findIndex(item=>item.value === option.value)) > -1} />
                                        <ListItemText primary={option.value} />
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    }
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                        <Typography variant="h5" className="field-box-title">País</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-country"
                            select
                            name="country"
                            label={formValues.country === 0 ? "Seleccione": ""}
                            value={formValues.country === 0 ? "" : formValues.country}
                            onChange={onChangeForm}
                            error={!!formErrors.country}
                            helperText={formErrors.country}
                            InputLabelProps={{shrink: false}}
                            margin="none"
                            variant="outlined"
                        >
                            {
                            countryCitiesArray && countryCitiesArray.length> 0 &&
                            <MenuItem value={0}><em>Ninguno</em></MenuItem>
                            }
                            {countryCitiesArray && countryCitiesArray.length>0 ?
                            countryCitiesArray.map((option: IListParametersInfo) => (
                                <MenuItem disabled={option.id === 170 ? false : true} key={option.id} value={option.id}>
                                {option.grandParentName}
                                </MenuItem>
                            ))
                            :
                            <MenuItem value={formValues.country}></MenuItem>
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} className="field-box-margin">
                        <Typography variant="h5" className="field-box-title">Departamento</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-department"
                            select
                            name="department"
                            label={formValues.department === 0 ? "Seleccione": ""}
                            value={formValues.department === 0 ? "" : formValues.department}
                            onChange={onChangeForm}
                            error={!!formErrors.department}
                            helperText={formErrors.department}
                            InputLabelProps={{shrink: false}}
                            disabled={(departmentArray && departmentArray.length>0 && (formValues.country === 170 || formValues.country === 0) ? false : true)}
                            margin="none"
                            variant="outlined"
                        >
                            {
                                departmentArray && departmentArray.length> 0 &&
                                <MenuItem value={0}><em>Ninguno</em></MenuItem>
                            }
                            {
                                departmentArray && departmentArray.length>0 ?
                                departmentArray.map((option: IGeneralParameter) => (
                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                        {option.name}
                                    </MenuItem>
                                ))
                            :
                                <MenuItem value={formValues.department}></MenuItem>
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} className="field-box-margin">
                        <Typography variant="h5" className="field-box-title">Municipio</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-city"
                            select
                            name="city"
                            label={formValues.city === 0 ? "Seleccione": ""}
                            value={formValues.city === 0 ? "" : formValues.city}
                            onChange={onChangeForm}
                            error={!!formErrors.city}
                            helperText={formErrors.city}
                            InputLabelProps={{shrink: false}}
                            margin="none"
                            variant="outlined"
                            disabled={cities && cities.length>0 ? false : true}
                        >
                            {
                                cities && cities.length> 0 &&
                                <MenuItem value={0}><em>Ninguno</em></MenuItem>
                            }
                            {
                                cities && cities.length>0 ?
                                cities.map((option: IGeneralParameter) => (
                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                    {option.name}
                                    </MenuItem>
                                ))
                            :
                                <MenuItem value={formValues.city}></MenuItem>
                            }
                        </TextField>
                    </Grid>
                    {reportForm &&
                        <Grid item xs={12} md={6} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Fecha de inicio convocatoria</Typography>
                            <Grid container item xs={12}>
                                <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale} >
                                    <DateRangePicker
                                        calendars={1}
                                        PopperProps={{
                                            placement:"bottom"
                                        }}
                                        value={formValues.rangeStartDate || null}
                                        onChange={(value)=>changeFilterDates('rangeStartDate',value)}
                                        renderInput={(startProps, endProps) => (
                                            <Grid container item xs={12} alignItems="center">
                                                <Grid item xs={12} sm={5}>
                                                    <TextField
                                                        {...startProps}
                                                        autoComplete ="off"
                                                        fullWidth
                                                        name="rangeStartDateSince"
                                                        margin="none"
                                                        label={formValues.rangeStartDate[0] === null ? "DD/MM/AAAA" : ""}
                                                        error={!!formErrors.rangeStartDateSince}
                                                        helperText={formErrors.rangeStartDateSince}
                                                        InputLabelProps={{shrink: false}}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Typography variant="h5" className="field-box-title" style={{textAlign: 'center'}}>
                                                        Hasta
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={5}>
                                                    <TextField
                                                        {...endProps}
                                                        fullWidth
                                                        autoComplete ="off"
                                                        name="rangeStartDateUntil"
                                                        margin="none"
                                                        label={formValues.rangeStartDate[1] === null ? "DD/MM/AAAA" : ""}
                                                        error={!!formErrors.rangeStartDateUntil}
                                                        helperText={formErrors.rangeStartDateUntil}
                                                        InputLabelProps={{shrink: false}}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    }
                    {reportForm &&
                        <Grid item xs={12} md={6} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Fecha de fin convocatoria</Typography>
                            <Grid container item xs={12}>
                                <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale} >
                                    <DateRangePicker
                                        calendars={1}
                                        PopperProps={{
                                            placement:"bottom"
                                        }}
                                        value={formValues.rangeEndDate || null}
                                        onChange={(value)=>changeFilterDates('rangeEndDate',value)}
                                        renderInput={(startProps, endProps) => (
                                            <Grid container item xs={12} alignItems="center">
                                                <Grid item xs={12} sm={5}>
                                                    <TextField
                                                        {...startProps}
                                                        autoComplete ="off"
                                                        fullWidth
                                                        name="rangeEndDateSince"
                                                        margin="none"
                                                        label={formValues.rangeEndDate[0] === null ? "DD/MM/AAAA" : ""}
                                                        error={!!formErrors.rangeEndDateSince}
                                                        helperText={formErrors.rangeEndDateSince}
                                                        InputLabelProps={{shrink: false}}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Typography variant="h5" className="field-box-title" style={{textAlign: 'center'}}>
                                                        Hasta
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={5}>
                                                    <TextField
                                                        {...endProps}
                                                        fullWidth
                                                        autoComplete ="off"
                                                        name="rangeEndDateUntil"
                                                        margin="none"
                                                        label={formValues.rangeEndDate[1] === null ? "DD/MM/AAAA" : ""}
                                                        error={!!formErrors.rangeEndDateUntil}
                                                        helperText={formErrors.rangeEndDateUntil}
                                                        InputLabelProps={{shrink: false}}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    }
                    {showCompanyFilters &&
                        <>
                            <Grid container item xs={12}>
                                <Typography variant="h4" className="bold-serif-primaryColor">
                                    sobre la empresa que publicó la oferta
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className="field-box-margin">
                                <Typography variant="h5" className="field-box-title">Razón social</Typography>
                                <TextField
                                    fullWidth
                                    name="name"
                                    margin="none"
                                    label={formValues.name === "" ? "Ingrese su razón social": ""}
                                    value={formValues.name || ""}
                                    onChange={onChangeForm}
                                    error={!!formErrors.name}
                                    helperText={formErrors.name}
                                    InputLabelProps={{shrink: false}}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className="field-box-margin">
                                <Typography variant="h5" className="field-box-title">Nit de la empresa</Typography>
                                <TextField
                                    fullWidth
                                    name="nit"
                                    margin="none"
                                    label={formValues.nit === null ? "Ingrese número de nit": ""}
                                    value={formValues.nit === null ? "" : formValues.nit}
                                    onChange={onChangeForm}
                                    error={!!formErrors.nit}
                                    helperText={formErrors.nit}
                                    InputLabelProps={{shrink: false}}
                                    variant="outlined"
                                />
                            </Grid>
                        </>
                    }
                    {reportForm &&
                        <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                            <Typography variant="h5" className="field-box-title">Sector laboral empresa</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-laboralSectorCompany"
                                select
                                name="laboralSectorCompany"
                                label={formValues.laboralSectorCompany === 0 ? "Seleccione": ""}
                                value={formValues.laboralSectorCompany === 0 ? "" : formValues.laboralSectorCompany}
                                onChange={onChangeForm}
                                error={!!formErrors.laboralSectorCompany}
                                helperText={formErrors.laboralSectorCompany}
                                InputLabelProps={{shrink: false}}
                                disabled={allLaboralSector && allLaboralSector.length>0 ? false : true}
                                margin="none"
                                variant="outlined"
                            >
                                {
                                    allLaboralSector && allLaboralSector.length> 0 &&
                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                }
                                {
                                    allLaboralSector && allLaboralSector.length> 0 ?
                                    allLaboralSector.map((option: IGeneralParameter) => (
                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                        {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.laboralSectorCompany}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                    }
                </Grid>
            </Grid>
            :
                null
            }
        </Grid>
    )
}

export default AnnouncementFilters;