import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import '../GraduateProfile.scss';

interface ProfileGraduateFiltersProps{
    selected: string;
    handleClick: any;
}

const ProfileGraduateStateFilters: React.FC<ProfileGraduateFiltersProps> = ({ selected, handleClick }) => {
    const options = [
        {id: 1, name: 'Mi perfil', state: 'Mi perfil'},
        {id: 2, name: 'Información adicional', state: 'Información adicional'},
    ]
    return (
        <Grid item xs={12} className="state-filters-profile-container">
            <Grid container item xs={12} className="container-profile-buttons">
                {
                    options.map(item=>(
                        <Button key={item.id} className={selected === item.state ? 'button-profile-option-active' : 'button-profile-option'} onClick={(e)=>handleClick(e, item.state)}>
                            <Typography variant="h5" className="regular-serif">
                                {item.name}
                            </Typography>
                        </Button>
                    ))
                }
            </Grid>
        </Grid>
    )
}

export default ProfileGraduateStateFilters
