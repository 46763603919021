import React, { useState, useEffect, useRef, Component} from 'react';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { usePromiseTracker } from 'react-promise-tracker';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Typography from '@material-ui/core/Typography';
import PrintIcon from '@material-ui/icons/Print';
import PrincipalTitle from '../../ShareComponents/PrincipalTitle';
import SectionTitle from '../../ShareComponents/SectionTitle';
import ApplicantCurriculumInfoSection from '../ApplicantCurriculumInfoSection';
import { IPersonalInfoCV, IReferent } from '../../../interfaces/IPersonalInfoCV';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import { IExperience, ILaboralInfoCV } from '../../../interfaces/ILaboralInfoCV';
import { IGeneralSIA } from '../../../interfaces/IGeneralSIA';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';
import { GENDER } from '../../../services/SIAService';
import { getUserAcademicInfo, getUserLaboralInfo, getUserPersonalInfo } from '../../../services/GraduateService';
import { IAcademicInfoCV, ILanguage, IOherStudies, IScholarship, IEducationInfo } from '../../../interfaces/IAcademicInfoCV';
import { getACADEMIC_LEVEL, getCAMPUS_FACULTIES, getCOUNTRIES_CITIES, getEMPLOYMENT_AND_PROFESSION_RELATIONSHIP, getEMPLOYMENT_RELATIONSHIP, getETHNIC_GROUP, getID_TYPE, getLANGUAGE, getLANGUAGELEVEL, getPERFORMANCE_AREA, getPROGRAMS } from '../../../services/ParameterService';

import './ApplicantCurriculumVitae.scss';


interface ApplicantCVViewPops{
    ref: any;
    personalInfo: IPersonalInfoCV;
    docTypeUser: string;
    ethnicGroupName: string;
    genderName: string;
    countryCitiesArray: Array<IListParametersInfo>;
    countryName: string;
    cityName: string;
    birthCountryName: string;
    birthCityName: string;
    academicInfo: IAcademicInfoCV;
    allLanguages: Array<IGeneralParameter>;
    languageLevels: Array<IGeneralParameter>;
    laboralInfo: ILaboralInfoCV;
    employRelationArray: Array<IGeneralParameter>;
    perfomanceAreaArray: Array<IGeneralParameter>;
    relationProffessionArray: Array<IGeneralParameter>;
    closeReferentArray: Array<IReferent>;
    personalReferentArray: Array<IReferent>;
    laboralReferentArray: Array<IReferent>;
}

class ApplicantCVView extends Component<ApplicantCVViewPops> {
    render() {
        const {
            personalInfo,
            docTypeUser,
            ethnicGroupName,
            genderName,
            countryCitiesArray,
            countryName,
            cityName,
            birthCountryName,
            birthCityName,
            academicInfo,
            allLanguages,
            languageLevels,
            laboralInfo,
            employRelationArray,
            perfomanceAreaArray,
            relationProffessionArray,
            closeReferentArray,
            personalReferentArray,
            laboralReferentArray
        } = this.props
      return (
        <Grid container item xs={12} className="main-container" style={{padding: '10px'}}>
            <PrincipalTitle principalTitle="Formato de Hoja de Vida" titleSize={24}/>
            <SectionTitle title="Datos personales del egresado" titleSize={20} />
            {personalInfo.personalIdentificationData.photo && personalInfo.personalIdentificationData.photo.length > 0 ?
                <>
                    <Grid container item xs={12} justify="space-between" className="section-photography" style={{marginBottom:"15px"}}>
                        <Grid className="photography-curriculum-container">
                            <img src={personalInfo.personalIdentificationData.photo} alt="imagen perfil" className="curriculum-photography-description"/>
                        </Grid>
                        <Grid className="special-padding-cv">
                            <ApplicantCurriculumInfoSection cols={4} firstTitle="Nombre completo" firtsValue={`${personalInfo.personalIdentificationData.names} ${personalInfo.personalIdentificationData.surname} ${personalInfo.personalIdentificationData.secondsurname}`} secondTitle="Fecha de nacimiento" secondValue={personalInfo.personalIdentificationData.birthdate === null ? '' : moment(personalInfo.personalIdentificationData.birthdate).format("DD/MM/YYYY")} thirdTitle="Documento de identidad" thirdValue={`${docTypeUser}  ${personalInfo.personalIdentificationData.doc}`} fourthTitle="Lugar de nacimiento" fourthValue={`${birthCityName} ${birthCountryName}`} />
                            <ApplicantCurriculumInfoSection cols={4} firstTitle="Números telefónicos" firtsValue={`${personalInfo.personalIdentificationData.phone} ${personalInfo.personalIdentificationData.additionalPhone && '-' + personalInfo.personalIdentificationData.additionalPhone} `} secondTitle="Grupo étnico" secondValue={ethnicGroupName} thirdTitle="Sexo" thirdValue={genderName} fourthTitle="Correo electrónico" fourthValue="">
                                <span className="link-responsive">{personalInfo.personalIdentificationData.email}</span>
                            </ApplicantCurriculumInfoSection>
                            <ApplicantCurriculumInfoSection cols={4} firstTitle="Dirección de residencia" firtsValue={personalInfo.personalIdentificationData.address} secondTitle="Lugar de residencia" secondValue={`${cityName} ${countryName}`}/>
                        </Grid>
                    </Grid>
                </>
                :
                <>
                    <Grid className="only-data" style={{marginBottom:"15px"}}>
                        <ApplicantCurriculumInfoSection cols={4} firstTitle="Nombre completo" firtsValue={`${personalInfo.personalIdentificationData.names} ${personalInfo.personalIdentificationData.surname} ${personalInfo.personalIdentificationData.secondsurname}`} secondTitle="Fecha de nacimiento" secondValue={personalInfo.personalIdentificationData.birthdate === null ? '' : moment(personalInfo.personalIdentificationData.birthdate).format("DD/MM/YYYY")} thirdTitle="Documento de identidad" thirdValue={`${docTypeUser}  ${personalInfo.personalIdentificationData.doc}`} fourthTitle="Lugar de nacimiento" fourthValue={`${birthCityName} ${birthCountryName}`} />
                        <ApplicantCurriculumInfoSection cols={4} firstTitle="Números telefónicos" firtsValue={`${personalInfo.personalIdentificationData.phone} ${personalInfo.personalIdentificationData.additionalPhone && '-' + personalInfo.personalIdentificationData.additionalPhone} `} secondTitle="Grupo étnico" secondValue={ethnicGroupName} thirdTitle="Sexo" thirdValue={genderName} fourthTitle="Correo electrónico" fourthValue="">
                            <span className="link-responsive">{personalInfo.personalIdentificationData.email}</span>
                        </ApplicantCurriculumInfoSection>
                        <ApplicantCurriculumInfoSection cols={4} firstTitle="Dirección de residencia" firtsValue={personalInfo.personalIdentificationData.address} secondTitle="Lugar de residencia" secondValue={`${cityName} ${countryName}`} />
                    </Grid>
                </>
            }
            <SectionTitle title="Información académica del egresado" titleSize={20} />
            <PrincipalTitle principalTitle="Estudios realizados en la UNAL" titleSize={18} />
            {academicInfo.educationUnal.educations.map((eduUnal:IEducationInfo, index:number)=>(
                <Grid key={index} container item xs={12} direction="column">
                    <ApplicantCurriculumInfoSection cols={4} firstTitle="Sede" firtsValue={eduUnal.campusName} secondTitle="Facultad" secondValue={eduUnal.schoolName} thirdTitle="Programa" thirdValue={eduUnal.curriculumName} fourthTitle="Nivel educativo" fourthValue={eduUnal.academicLevelName}/>
                    <ApplicantCurriculumInfoSection cols={3} firstTitle="Título obtenido" firtsValue="" secondTitle="Fecha de inicio" secondValue={moment(eduUnal.dateInit).format('DD-MMMM-YYYY')==="Fecha inválida" ? "" : moment(eduUnal.dateInit).format('DD-MMMM-YYYY')} thirdTitle="Fecha de finalización" thirdValue={moment(eduUnal.date).format('DD-MMMM-YYYY')==="Fecha inválida" ? "" : moment(eduUnal.date).format('DD-MMMM-YYYY')} />
                    {academicInfo.educationUnal && index !== academicInfo.educationUnal.educations.length-1 &&
                        <Grid className="line-section-divider" />
                    }
                </Grid>
            ))}
            <PrincipalTitle principalTitle="Otros estudios realizados" titleSize={18} />
            {academicInfo.otherStudies.map((otherStudy:IOherStudies, index:number)=>(
                <Grid key={index} container item xs={12} direction="column">
                    <ApplicantCurriculumInfoSection cols={4} firstTitle="Nombre de la institución educativa" firtsValue={otherStudy.institution} secondTitle="Facultad" secondValue={otherStudy.faculty} thirdTitle="Programa" thirdValue={otherStudy.program} fourthTitle="Título obtenido" fourthValue={otherStudy.title} />
                    <ApplicantCurriculumInfoSection cols={2} firstTitle="Fecha de inicio" firtsValue={moment(otherStudy.dateInit).format('DD-MMMM-YYYY')==="Fecha inválida" ? "" : moment(otherStudy.dateInit).format('DD-MMMM-YYYY')} secondTitle="Fecha de finalización" secondValue={moment(otherStudy.dateEnd).format('DD-MMMM-YYYY')==="Fecha inválida" ? "" : moment(otherStudy.dateEnd).format('DD-MMMM-YYYY')}/>
                    {academicInfo.otherStudies && index !== academicInfo.otherStudies.length-1 &&
                        <Grid className="line-section-divider" />
                    }
                </Grid>
            ))}
            <PrincipalTitle principalTitle="Becas" titleSize={18} />
            {academicInfo.scholarships.map((scholarchip: IScholarship, index: number)=>{
                const countryObj = countryCitiesArray.find(item=>item.id === scholarchip.country);
                const countryName = (scholarchip.country>0 && countryObj) ? countryObj.grandParentName : "";
                const cityObj = countryObj?.childs.find(item=>item.parameter_code === scholarchip.city);
                const cityName = (scholarchip.city>0 && cityObj) ? cityObj.name : "";
                return(
                    <Grid key={index} container item xs={12} direction="column">
                        <ApplicantCurriculumInfoSection cols={4} firstTitle="Nombre de la beca" firtsValue={scholarchip.name} secondTitle="Entidad que otorga la beca" secondValue={scholarchip.entity} thirdTitle="País" thirdValue={countryName} fourthTitle="Municipio" fourthValue={cityName} />
                        <ApplicantCurriculumInfoSection cols={2} firstTitle="Fecha de inicio" firtsValue={scholarchip.startDate ? moment(new Date(scholarchip.startDate)).format("DD/MM/YYYY") : ''} secondTitle="Fecha de finalización" secondValue={scholarchip.endDate ? moment(new Date(scholarchip.endDate)).format("DD/MM/YYYY") : ''} />
                        {academicInfo.scholarships && index !== academicInfo.scholarships.length-1 &&
                            <Grid className="line-section-divider" />
                        }
                    </Grid>
                )
            })}
            <PrincipalTitle principalTitle="Idiomas" titleSize={18} />
            <Grid container item xs={12} style={{marginBottom:"15px"}}>
                {academicInfo.languages.map((language: ILanguage, index:number)=>{
                    const languageObj = allLanguages.find(item=>item.parameter_code === language.language);
                    const languageName = (language.language>0 && languageObj) ? languageObj.name : "";
                    const levelObj = languageLevels.find(item=>item.parameter_code === language.level);
                    const levelName = (language.level>0 && levelObj) ? levelObj.name : "";
                    return(
                        <Grid key={index} container item xs={12} direction="row">
                            <Grid container item xs={12} className="subsection-container-100">
                                <ApplicantCurriculumInfoSection cols={3} firstTitle="Idioma" firtsValue={languageName} secondTitle="Nivel" secondValue={levelName} thirdTitle="Nativo" thirdValue={language.native ? "Si" : "No"}/>
                            </Grid>
                            {academicInfo.languages && index !== academicInfo.languages.length-1 &&
                                <Grid container item xs={12} className="subsection-container-100">
                                    <Grid className="line-section-divider" />
                                </Grid>
                            }
                        </Grid>
                    )
                })}
            </Grid>
            <SectionTitle title="Perfil profesional del egresado" titleSize={20} />
            <Grid container item xs={12} direction="column" style={{marginBottom:"15px"}}>
                <Grid container item xs={12} direction="row" className="subsection-container-100"  alignItems="center">
                    <Grid container item xs={12}>
                        <Typography variant="h5" className="bold-serif section-title-complete">
                            Perfil
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <Typography variant="h5" className="regular-serif section-value-complete">
                            {laboralInfo.laboralInformation.laboralProfile}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} direction="row" className="subsection-container-100"  alignItems="center">
                    <Grid container item xs={12}>
                        <Typography variant="h5" className="bold-serif section-title-complete">
                            Competencias
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <Typography variant="h5" className="regular-serif section-value-complete">
                            {laboralInfo.laboralInformation.competences}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <SectionTitle title="Experiencia laboral del egresado" titleSize={20} />
            <Grid container item xs={12} style={{marginBottom:"15px"}}>
                {laboralInfo.laboralExperiences.map((exp: IExperience, index: number)=>{
                    const employRelObj = employRelationArray.find(item=>item.parameter_code === exp.employmentRelationship);
                    const employRelName = (exp.employmentRelationship>0 && employRelObj) ? employRelObj.name : "";
                    const areaObJ = perfomanceAreaArray.find(item=>item.parameter_code === exp.performanceArea);
                    const areaName = (exp.performanceArea>0 && areaObJ) ? areaObJ.name : "";
                    const afinityObj = relationProffessionArray.find(item=>item.parameter_code === exp.employmentAndProfessionRelationship);
                    const afinityName = (exp.employmentAndProfessionRelationship>0 && afinityObj) ? afinityObj.name : "";
                    const countryObj = countryCitiesArray.find(item=>item.id === exp.country);
                    const countryName = (exp.country>0 && countryObj) ? countryObj.grandParentName : "";
                    const cityObj = countryObj?.childs.find(item=>item.parameter_code === exp.city);
                    const cityName = (exp.city>0 && cityObj) ? cityObj.name : "";
                    return(
                        <Grid key={index} container item xs={12} direction="row">
                            <Grid container item xs={12} className="subsection-container-100">
                                <ApplicantCurriculumInfoSection cols={4} firstTitle="Cargo" firtsValue={exp.position} secondTitle="Tipo de vinculación" secondValue={employRelName} thirdTitle="Área de desempeño" thirdValue={areaName} />
                            </Grid>
                            <Grid container item xs={12} className="subsection-container-100">
                                <Typography variant="h5" className="bold-serif section-title-complete">
                                    Responsabilidades y logros
                                </Typography>
                                <Typography variant="h5" className="regular-serif section-value-complete">
                                    {exp.achivements}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} className="subsection-container-100">
                                <ApplicantCurriculumInfoSection cols={4} firstTitle="Afinidad con labor o profesión" firtsValue={afinityName} secondTitle="Rango salarial" secondValue={exp.salaryRange} thirdTitle="Fecha de inicio" thirdValue={exp.startDate ? moment(new Date(exp.startDate)).format("DD/MM/YYYY") : ''} fourthTitle="Fecha de finalización" fourthValue={exp.endDate ? moment(new Date(exp.endDate)).format("DD/MM/YYYY") : ''} />
                            </Grid>
                            <Grid container item xs={12} className="subsection-container-100">
                                <ApplicantCurriculumInfoSection cols={3} firstTitle="Empresa" firtsValue={exp.companyName} secondTitle="Número telefónico" secondValue={exp.phoneNumber ? exp.phoneNumber.toString() : ""} thirdTitle="Ubicación de la empresa" thirdValue={`${countryName} - ${cityName}`}/>
                            </Grid>
                            <Grid container item xs={12} className="subsection-container-100">
                                <ApplicantCurriculumInfoSection cols={4} firstTitle="Nombre del jefe inmediato" firtsValue={exp.bossName} secondTitle="Cargo del jefe inmediato" secondValue={exp.bossPositon} thirdTitle="Número telefónico del jefe inmediato" thirdValue={exp.bossPhone ? exp.bossPhone.toString() : ""}/>
                            </Grid>
                            <Grid container item xs={12} className="subsection-container-100">
                                <Grid className="line-section-divider" />
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            <SectionTitle title="Referencias del egresado" titleSize={20}/>
            <PrincipalTitle principalTitle="Referencias personales" titleSize={18} />
            {personalReferentArray.map((ref: IReferent, index: number)=>(
                <Grid key={index} container item xs={12} direction="column">
                    <ApplicantCurriculumInfoSection cols={3} firstTitle="Nombre del referente" firtsValue={`${ref.names} ${ref.surname} ${ref.secondSurnmae}`} secondTitle="Número telefónico" secondValue={ref.phone} thirdTitle="Correo electrónico" thirdValue={ref.email}/>
                    {personalReferentArray && index !== personalReferentArray.length-1 &&
                        <Grid container item xs={12} className="subsection-container-100">
                            <Grid className="line-section-divider" />
                        </Grid>
                    }
                </Grid>
            ))}
            <PrincipalTitle principalTitle="Referencias laborales" titleSize={18} />
            {laboralReferentArray.map((ref: IReferent, index: number)=>(
                <Grid key={index} container item xs={12} direction="column">
                    <ApplicantCurriculumInfoSection cols={4} firstTitle="Nombre del referente" firtsValue={`${ref.names} ${ref.surname} ${ref.secondSurnmae}`} secondTitle="Número telefónico" secondValue={ref.phone} thirdTitle="Correo electrónico" thirdValue={ref.email} fourthTitle="Cargo" fourthValue={ref.position ? ref.position : ""} />
                    {laboralReferentArray && index !== laboralReferentArray.length-1 &&
                        <Grid container item xs={12} className="subsection-container-100">
                            <Grid className="line-section-divider" />
                        </Grid>
                    }
                </Grid>
            ))}
            <PrincipalTitle principalTitle="Referencias cercanas" titleSize={18} />
            {closeReferentArray.map((ref: IReferent, index: number)=>(
                <Grid key={index} container item xs={12} direction="column">
                    <ApplicantCurriculumInfoSection cols={3} firstTitle="Nombre del referente" firtsValue={`${ref.names} ${ref.surname} ${ref.secondSurnmae}`} secondTitle="Número telefónico" secondValue={ref.phone} thirdTitle="Correo electrónico" thirdValue={ref.email}/>
                    {closeReferentArray && index !== closeReferentArray.length-1 &&
                        <Grid container item xs={12} className="subsection-container-100">
                            <Grid className="line-section-divider" />
                        </Grid>
                    }
                </Grid>
            ))}
            <Grid container item xs={12} className="line-final-form" />
        </Grid>
      );
    }
}


interface ApplicantCurriculumVitaeProps{
    graduateId:number;
    goBack: any;
}

const ApplicantCurriculumVitae: React.FC<ApplicantCurriculumVitaeProps> = ({ graduateId, goBack }) => {
    const componentRef:any = useRef();
    const { promiseInProgress } = usePromiseTracker();
    const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [personalInfo, setPersonalInfo] = useState<IPersonalInfoCV>(new IPersonalInfoCV({}));
    const [ docTypeUser, setDocTypeUser ] = useState<string>("");
    const [ethnicGroupName, setEthnicGroupName] = useState<string>("");
    const [genderName, setGenderName] = useState<string>("");
    const [countryCitiesArray, setCountryCitiesArray] = useState<Array<IListParametersInfo>>([]);
    const [birthCountryName, setBirthCountryName ] = useState<string>("");
    const [birthCityName, setBirthCityName] = useState<string>("");
    const [countryName, setCountryName ] = useState<string>("");
    const [cityName, setCityName] = useState<string>("");
    const [academicInfo, setAcademicInfo] = useState<IAcademicInfoCV>(new IAcademicInfoCV({}));
    const [allLanguages, setAllLanguages] = useState<Array<IGeneralParameter>>([]);
    const [languageLevels, setLanguageLevels] = useState<Array<IGeneralParameter>>([]);
    const [laboralInfo, setLaboralInfo] = useState<ILaboralInfoCV>(new ILaboralInfoCV({}));
    const [employRelationArray, setEmployRelationArray] = useState<Array<IGeneralParameter>>([]);
    const [perfomanceAreaArray, setPerfomanceAreaArray] = useState<Array<IGeneralParameter>>([]);
    const [relationProffessionArray, setrelationProffessionArray] = useState<Array<IGeneralParameter>>([]);
    const [closeReferentArray, setCloseReferentArray] = useState<Array<IReferent>>([]);
    const [personalReferentArray, setPersonalReferentArray] = useState<Array<IReferent>>([]);
    const [laboralReferentArray, setLaboralReferentArray] = useState<Array<IReferent>>([]);

    useEffect(()=>{
        const getData  = async() => {
            if(graduateId>0){
                // personal info
                const responseUser = await getUserPersonalInfo(userToken, graduateId);
                if(responseUser.status === 200){
                    const resultUser = await responseUser.json();
                    const userInfo = new IPersonalInfoCV(resultUser);
                    if(userInfo.distinctions.length>0){
                        userInfo.distinctions.map(item=>item.yearDateFormat=new Date((item.year?.toString() + "-01-31")))
                    }
                    setPersonalInfo(userInfo);
                    if(personalInfo.personalIdentificationData.files?.length===0){
                        if(userInfo.personalIdentificationData.photo && userInfo.personalIdentificationData.photo.length>0){
                            const url = userInfo.personalIdentificationData.photo;
                            const fileName = 'Egresado_foto_perfil.jpg';
                            fetch(url)
                            .then(async response => {
                                const blob = await response.blob()
                                const file = new File([blob], fileName, {type: "image/jpeg"});
                                if(file){
                                    setPersonalInfo(prev => ({
                                        ...prev,
                                        personalIdentificationData: {
                                            ...prev.personalIdentificationData,
                                            files: [{file:file, previewUrl : URL.createObjectURL(file), description: ''}]
                                        }
                                    }));
                                }
                            })
                        }
                    }
                    if(userInfo.referents.length>0){
                        const closeArray = userInfo.referents.filter(item=>item.type === "Cercano");
                        setCloseReferentArray(closeArray);
                        const personalArray = userInfo.referents.filter(item=>item.type === "Personal");
                        setPersonalReferentArray(personalArray);
                        const laboralArray = userInfo.referents.filter(item=>item.type === "Laboral");
                        setLaboralReferentArray(laboralArray);
                    }
                    const dataDocType:Array<IGeneralParameter> = await getID_TYPE();
                    const docType = dataDocType.find(item=>item.parameter_code === userInfo.personalIdentificationData.documentType);
                    if(docType){
                      setDocTypeUser(docType.name);
                    }
                    const dataEthnic:Array<IGeneralParameter> = await getETHNIC_GROUP();
                    const ethnicType = dataEthnic.find(item=>item.parameter_code === userInfo.personalIdentificationData.ethnicgroup);
                    if(ethnicType){
                      setEthnicGroupName(ethnicType.name);
                    }
                    const dataGender:Array<IGeneralSIA> = GENDER;
                    const genderType = dataGender.find(item=>item.id === userInfo.personalIdentificationData.gender);
                    if(genderType){
                      setGenderName(genderType.name);
                    }
                    const dataCountryCities:Array<IListParametersInfo> = (await getCOUNTRIES_CITIES()).list;
                    setCountryCitiesArray(dataCountryCities);
                    //residence country
                    const countryObj = dataCountryCities.find(item=>item.id === userInfo.personalIdentificationData.residenceCountry);
                    if(countryObj){
                      setCountryName(countryObj.grandParentName ? countryObj.grandParentName : '');
                    }
                    //residence city
                    const citiesArray = countryObj?.childs;
                    if(citiesArray && citiesArray.length>0){
                        const cityObj = citiesArray.find(item=>item.parameter_code === userInfo.personalIdentificationData.residenceCity);
                        if(cityObj){
                            setCityName(cityObj.name ? cityObj.name : '');
                        }
                    }
                    const birthCountryObj = dataCountryCities.find(item=>item.id === userInfo.personalIdentificationData.birthCountry);
                    if(birthCountryObj){
                      setBirthCountryName(birthCountryObj.grandParentName ? birthCountryObj.grandParentName : '');
                    }
                    const birthCitiesArray = birthCountryObj?.childs;
                    if(birthCitiesArray && birthCitiesArray.length>0){
                        const birthCityObj = birthCitiesArray.find(item=>item.parameter_code === userInfo.personalIdentificationData.birthCity);
                        if(birthCityObj){
                            setBirthCityName(birthCityObj.name ? birthCityObj.name : '');
                        }
                    }
                }
                // academic info
                const dataAllCampus: Array<IListParametersInfo> = (await getCAMPUS_FACULTIES()).list;
                let schoolsArray: Array<IGeneralParameter> = [];
                const programsArray: Array<IGeneralParameter> = await getPROGRAMS();
                const academicArray: Array<IGeneralParameter> = await getACADEMIC_LEVEL();
                // const titleArray: Array<IGeneralParameter> = [{parameter_code: 1,
                // name: "Ingeniero temporal data", description:""}]
                const responseLanguages: Array<IGeneralParameter> = await getLANGUAGE();
                setAllLanguages(responseLanguages);
                const languageLevelData = await getLANGUAGELEVEL();
                setLanguageLevels(languageLevelData);
                const responseAcademic = await getUserAcademicInfo(userToken, graduateId);
                if(responseAcademic.status === 200){
                    const resultAcademicUser: IAcademicInfoCV = await responseAcademic.json();
                    const tempFormValues = new IAcademicInfoCV(resultAcademicUser);
                    if(tempFormValues.educationUnal.educations.length>0){
                        tempFormValues.educationUnal.educations.forEach(educationItem=>{
                            const campusObj = dataAllCampus.find(item=>item.id === educationItem.campus);
                            educationItem.campusName = campusObj ? campusObj.parentName : "";
                            if(campusObj){
                                schoolsArray = campusObj.childs;
                                const schoolObj = schoolsArray.find(item=> item.parameter_code === educationItem.school);
                                educationItem.schoolName = schoolObj ? schoolObj.name : "";
                            }
                            if(programsArray.length>0){
                                const programObj = programsArray.find(item=> item.parameter_code === educationItem.curriculum);
                                educationItem.curriculumName = programObj ? programObj.name : "";
                            }
                            const academicObj = academicArray.find(item => item.parameter_code === educationItem.academicLevel);
                            educationItem.academicLevelName = academicObj ? academicObj.name : "";
                            // const titleObj = titleArray.find(item => item.parameter_code === educationItem.title);
                            // educationItem.titleName = titleObj ? titleObj.name : "";
                        })
                    }
                    setAcademicInfo(tempFormValues);
                }
                // laboral info
                const responseEmployRel: Array<IGeneralParameter> = await getEMPLOYMENT_RELATIONSHIP();
                setEmployRelationArray(responseEmployRel);
                const responsePerformance: Array<IGeneralParameter> = await getPERFORMANCE_AREA();
                setPerfomanceAreaArray(responsePerformance);
                const responseRelProffessions: Array<IGeneralParameter> = await getEMPLOYMENT_AND_PROFESSION_RELATIONSHIP();
                setrelationProffessionArray(responseRelProffessions);
                const responseLaboral = await getUserLaboralInfo(userToken, graduateId);
                if(responseLaboral.status === 200){
                    const resultLaboralUser: ILaboralInfoCV = await responseLaboral.json();
                    const tempFormValues = new ILaboralInfoCV(resultLaboralUser);
                    setLaboralInfo(tempFormValues);
                }
            }
            setInitLoad(false);
        }
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[graduateId])

    return (
        <>
            <Grid container item xs={12} className="back-button-applicants-section">
                <Grid container item xs={12} className="main-container">
                    <Grid container item xs={12} className="back-btn-container">
                        <Button
                            variant="contained"
                            className="step-back-offers"
                            startIcon={<NavigateBeforeIcon />}
                            onClick={(e)=>{goBack('Back')}}
                        >
                        <Typography variant="h6" className="regular-serif">
                            Regresar a aspirantes
                        </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <div>
                <ApplicantCVView
                    ref={componentRef}
                    personalInfo={personalInfo}
                    docTypeUser={docTypeUser}
                    ethnicGroupName={ethnicGroupName}
                    genderName={genderName}
                    countryCitiesArray={countryCitiesArray}
                    countryName={countryName}
                    cityName={cityName}
                    birthCountryName={birthCountryName}
                    birthCityName={birthCityName}
                    academicInfo={academicInfo}
                    allLanguages={allLanguages}
                    languageLevels={languageLevels}
                    laboralInfo={laboralInfo}
                    employRelationArray={employRelationArray}
                    perfomanceAreaArray={perfomanceAreaArray}
                    relationProffessionArray={relationProffessionArray}
                    closeReferentArray={closeReferentArray}
                    personalReferentArray={personalReferentArray}
                    laboralReferentArray={laboralReferentArray}
                />
            </div>
            <Grid className="main-container-cv-actions">
                <Grid container item xs={12} className="action-curriculum-view-buttons">
                    <Button variant="contained" className="back-btn" startIcon={<NavigateBeforeIcon />} onClick={(e)=>{goBack('Back')}}>
                        <Typography  variant="h5" className="light-serif">
                            Regresar
                        </Typography>
                    </Button>
                    <ReactToPrint
                        trigger={() =>
                            <Button variant="contained" endIcon={<PrintIcon/>} className="print-btn">
                                <Typography  variant="h5" className="light-serif">
                                    Imprimir
                                </Typography>
                            </Button>
                        }
                        content={() => componentRef.current}
                    />
                </Grid>
            </Grid>
            <LoadingLockPage load={promiseInProgress}/>
            <LoadingLockPage load={initLoad}/>
        </>
    )
}

export default ApplicantCurriculumVitae
