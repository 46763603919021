import { IConfiguration } from "../interfaces/IConfiguration";

const baseUrl = process.env.REACT_APP_SIE_APP_PROXY_BASE_URL;
const TOKEN_KEY = "Bearer";


export const updateConfiguration = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }

    try {
        const response = await fetch(
            `${baseUrl}/catalog/configuration/`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const getConfiguration = async()=>{
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }

    try {
        const response = await fetch(
            `${baseUrl}/catalog/configuration/`,
            {
                method: 'GET',
                headers:{
                'sie_token': token
                }
            }
        );
        if (response.status === 200) {
            const result:IConfiguration = await response.json();
            return result;
        }
    } catch (error) {
        return error
    }
}

export const getEmailFrom = async()=>{
    try {
        const response = await fetch(
            `${baseUrl}/catalog/configuration/EMAIL_FROM`,
            {
                method: 'GET',
            }
        );
        if (response.status === 200) {
            const result:IConfiguration = await response.json();
            return result;
        }
    } catch (error) {
        return error
    }
}

export const getEmailHelpdesk = async()=>{
    try {
        const response = await fetch(
            `${baseUrl}/catalog/configuration/EMAIL_HELPDESK`,
            {
                method: 'GET',
            }
        );
        if (response.status === 200) {
            const result:IConfiguration = await response.json();
            return result;
        }
    } catch (error) {
        return error
    }
}

export const getSalaryMInimum = async()=>{
    try {
        const response = await fetch(
            `${baseUrl}/catalog/configuration/SALARY_MINIMUM`,
            {
                method: 'GET',
            }
        );
        if (response.status === 200) {
            const result:IConfiguration = await response.json();
            return result;
        }
    } catch (error) {
        return error
    }
}
