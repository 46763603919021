const baseUrl = process.env.REACT_APP_SIE_APP_PROXY_BASE_URL;
const TOKEN_KEY = "Bearer";


export const getUsersByCompany = async (nit:number) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
        token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/company/listUserC/${nit}`,
            {method: 'GET',
            headers:{
                'sie_token': token
            }
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const getCompanyDocuments = async (nit: number) => {
    try {
        const response = await fetch(
            `${baseUrl}/filesystem/get-user-documents/Empresa-${nit}`,
            {method: 'GET'}
        );
        return response;
    } catch (error) {
        return error
    }
}

export const RegisterCompanyProfiles = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
        token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/company/addUsers`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response
    }catch (error) {
        return error
    }
};

export const UpdateUserByCompany = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
        token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/company/updateUserChild`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response
    }catch(error){
        return error
    }
};

export const changeStateUserByCompany = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
        token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/company/updateStateChildU`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response
    }catch(error){
        return error
    }
};

export const getCompanyInfo = async (userToken: string, nit: number) => {
    try {
        const response = await fetch(
            `${baseUrl}/company/${nit}`,
            {method: 'GET',
            headers:{
                "sie_token": userToken
            }
            }
            
        );
        return response;
    } catch (error) {
        return error
    }
}

export const UpdateCompanyInfo = async (userToken: string, bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/company/update`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "sie_token": userToken
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response
    } catch (error) {
        return error
    }
};

export const AddHeadquarterCompany = async (userToken: string, bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/company/headquarter/register`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "sie_token": userToken
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response
    } catch (error) {
        return error
    }
};

export const UpdateHeadquarterCompany = async (userToken: string, bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/company/headquarter/update`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "sie_token": userToken
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response
    }catch (error) {
        return error
    }
};

export const deleteHeadquarterCompany = async (id:number) => {
    try {
        const response = await fetch(
            `${baseUrl}/company/headquarter/${id}`,
            {
                method: 'DELETE'
            }
        );
        return response
    } catch (error) {
        return error
    }
}
