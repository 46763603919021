import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { Redirect } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import WorkIcon from '@material-ui/icons/Work';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import CompanyOffersView from './CompanyOffersView/CompanyOffersView';
import CompanyProfilesView from './CompanyProfilesView/CompanyProfilesView';
import ProfileCompanyView from './ProfileCompanyView/ProfileCompanyView';
import { isLogin } from '../../../services/AuthService';
import UserContext from '../../../utils/UserContext';
import { IUserByCompany } from '../../../interfaces/IUserByCompany';
import { getCITY } from '../../../services/ParameterService';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import WelcomeBox from '../../../components/ShareComponents/WelcomeBox';
import LoadingLockPage from '../../../components/ShareComponents/LoadingLockPage';
import { containsAny, sortByKey, sortByKeyDesc } from '../../../helpers/sharedFunctions';
import { getCompanyInfo, getUsersByCompany } from '../../../services/CompanyService';
import { IHeadquarter, IProfileCompany } from '../../../interfaces/IProfileCompany';

import './company.scss';


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
      className="tab-panel-container"
    >
      {value === index && (
        <Box p={3}>
          <Grid container spacing={1}>
            {children}
          </Grid>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: any)=> {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

interface ITabsProps{
  location:any;
}

const DashboardCompany:React.FC<ITabsProps> = ({location}) => {
  const routes = ["/empresa/dashboard/ofertas","/empresa/dashboard/ofertas/convocatoria","/empresa/dashboard/ofertas/seleccion","/empresa/dashboard/ofertas/finalizadas","/empresa/dashboard/ofertas/canceladas", "/empresa/dashboard/perfiles", "/empresa/dashboard/perfil"];
  const userContext = React.useContext(UserContext);
  const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
  const { promiseInProgress } = usePromiseTracker();
  const [ready, setReady]= useState(false);
  const urlPath = location.pathname;
  const [value, setValue] = React.useState(0);
  const [activeUsers, setActiveUsers]=useState<Array<IUserByCompany>>([]);
  const [inactiveUsers, setInactiveUsers]=useState<Array<IUserByCompany>>([]);
  const [companyData, setCompanyData] = useState<IProfileCompany>(new IProfileCompany());
  const [headquartersCompany, setHeadquartersCompany] = useState<Array<IHeadquarter>>([]);
  const [cityArray, setCityArray] = useState<Array<IGeneralParameter>>([]);
  const [nameProfile, setNameProfile]= useState<string>('');


  useEffect(()=>{
    if(userContext.roleSelected > 0){
      userContext.setLogged(true);
    }
    const getUsersCompany = async()=>{
      if(userContext.userId){
        const nit = parseInt(userContext.userId, 10);
        const companyUsersResponse = await trackPromise(getUsersByCompany(nit));
        if(companyUsersResponse.status === 200){
          const companyUsers: Array<IUserByCompany> = (await companyUsersResponse.json()).result;
          if(companyUsers.length>0){
            let activeProfiles = companyUsers.filter(profile=>profile.status==='Activo');
            activeProfiles = sortByKey(activeProfiles, 'createdAt');
            setActiveUsers(activeProfiles);
            let profilesInactive = companyUsers.filter(profile=>profile.status ==='Inactivo');
            profilesInactive = sortByKeyDesc(profilesInactive, 'updatedAt');
            setInactiveUsers(profilesInactive);
          }
        }
      }
    }
    getUsersCompany();
    const getCompanyData = async()=>{
      if(userContext.userId){
        const nit = parseInt(userContext.userId, 10);
        const companyInfoRes = await trackPromise(getCompanyInfo(userToken, nit));
        if(companyInfoRes.status === 200){
          const companyInfoResult = await companyInfoRes.json();
          const target: IProfileCompany = new IProfileCompany();
          if(companyInfoResult && companyInfoResult.company){
            setNameProfile(companyInfoResult.company.name);
            Object.assign(target, companyInfoResult.company);
            if(target){
              setCompanyData(target);
            }
          }
          if(companyInfoResult && companyInfoResult.headquarter.length>0){
            let headquartesRes =  companyInfoResult.headquarter;
            headquartesRes = sortByKeyDesc(headquartesRes, 'updatedAt');
            setHeadquartersCompany(headquartesRes);
          }
        }
      }
      //data for cities
      let dataCity: Array<IGeneralParameter> = await getCITY();
      dataCity = sortByKey(dataCity, 'name');
      setCityArray(dataCity ? dataCity : []);
      if(urlPath==="/empresa/dashboard"){
        setValue(0)
      } else if(urlPath === routes[0]){
        setValue(0)
      } else if(urlPath === routes[1]){
        setValue(0)
      } else if(urlPath === routes[2]){
        setValue(0)
      } else if(urlPath === routes[3]){
        setValue(0)
      } else if(urlPath === routes[4]){
        setValue(0)
      } else if(urlPath === routes[5]){
        setValue(1)
      } else if(urlPath === routes[6]){
        setValue(2)
      }
    }
    getCompanyData();
    setReady(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[urlPath]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
    {ready &&
      <>
        {isLogin() && containsAny([2,3],[userContext.roleSelected]) ?
          !userContext.temporaryKey ?
            <div className="welcome-section">
              <Grid container className="welcome-margin">
                <WelcomeBox nameProfile={nameProfile} profile={2}/>
              </Grid>
              <div className="admin-tabs">
                <AppBar position="static" className="appbar-management">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                    className="tabs-management tabs-management-company"
                  >
                    <Tab icon={<WorkIcon />} component={Link} to={routes[0]} label="Ofertas laborales" {...a11yProps(0)} />
                    <Tab icon={<GroupIcon />} component={Link} to={routes[5]} label="Perfiles" {...a11yProps(1)} />
                    <Tab icon={<PersonIcon />} component={Link} to={routes[6]} label="Mi perfil" {...a11yProps(3)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <CompanyOffersView location={location} routes={routes}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <CompanyProfilesView activeUsers={activeUsers} inactiveUsers={inactiveUsers}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <ProfileCompanyView companyData={companyData} cityArray={cityArray} headquartersCompany={headquartersCompany}/>
                </TabPanel>
                <LoadingLockPage load={promiseInProgress}/>
              </div>
            </div>
          :
            <Redirect to="/empresa/cambio-contrasena"/>
        :
          <Redirect to="/"/>
        }
      </>
    }
  </>
  );
}

export default DashboardCompany;