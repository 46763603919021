import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import moment from 'moment';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { Link as LinkTo } from 'react-router-dom';
import WarningIcon from '@material-ui/icons/Warning';
import Link from '@material-ui/core/Link';
import SendIcon from '@material-ui/icons/Send';
import ReactQuill from 'react-quill';
import { Grid, TextField, Typography } from '@material-ui/core';
import { DateRange } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { StateByRole } from '../../interfaces/StateByRole';
import { sendMassiveEmail } from '../../services/AdminServices';
import { IGeneralParameter } from '../../interfaces/IGeneralParameter';
import SimpleFilterBox from '../../components/ManagementState/SimpleFilterBox';
import MoreFiltersCompany from '../../components/ManagementState/MoreFiltersCompany';
import Validations from '../../helpers/Validations';
import ShowFilters from '../../components/ManagementState/ShowFilters';
import { ChipData } from '../../interfaces/ChipData';
import { isLogin } from '../../services/AuthService';
import { containsAny, convertToBase64, sortByKey } from '../../helpers/sharedFunctions';
import LoadingLockPage from '../../components/ShareComponents/LoadingLockPage';
import { getEmailFrom } from '../../services/ConfigurationService';
import SuccessModal from '../ShareComponents/SuccessModal';
import ErrorModal from '../ShareComponents/ErrorModal';
import { ICompanyFilterForm } from '../../interfaces/ICompanyFilterForm';
import { ICompanyFilterObj } from '../../interfaces/ICompanyFilterObj';
import EmailIcon from '@material-ui/icons/Email';
import { getCITY, getCOMPANY_TYPE, getCOUNTRIES_CITIES, getDEPARTAMENT, getLABORAL_SECTOR } from '../../services/ParameterService';
import { permissionAdminSendMassMsgCompanies, permissionCompanyActiveRejectedState, permissionCompanyInactiveState } from '../../utils/PermissionsVariables';
import UserContext from '../../utils/UserContext';

import './MassMessage.scss';
import { IListParametersInfo } from '../../interfaces/IListParametersInfo';
import { AttachingFile } from '../../interfaces/IGraduateFilterObjMsg';
import { IGraduateAttach } from '../../interfaces/IGraduateAttach';
import ModalOffersAction from '../LaboralOffers/ModalOffersAction';


const stateOptionsCompany: Array<StateByRole> = [
    {id:1, label: 'Inscrito', value:'Inscrito', description: 'Cambiar estado a Inscrito', permission:[] },
    {id:2, label: 'Activo', value:'Activo', description: 'Cambiar estado a Activo', permission: permissionCompanyActiveRejectedState },
    {id:3, label: 'Rechazado', value:'Rechazado', description: 'Cambiar estado a Rechazado', permission: permissionCompanyActiveRejectedState },
    {id:4, label: 'Inactivo', value:'Inactivo', description: 'Cambiar estado a Inactivo', permission: permissionCompanyInactiveState }
];

const filterCompanyValidation = {
    name: {
      error: "",
      validate: "textNumber",
      maxLength: 40,
      required: false
    },
    nit: {
        error: "",
        validate: "number",
        minLength: 7,
        maxLength: 15,
        required: false
    },
    laboralSector: {
        validate: "select",
        required: false
    },
    typeOfCompany: {
        validate: "select",
        required: false
    },
    country: {
        validate: "select",
        required: false
    },
    department: {
        validate: "select",
        required: false
    },
    city: {
        validate: "select",
        required: false
    },
    state:{
        validate: "select",
        required: false,
    },
    extraEmail:{
        error: "",
        validate: "email",
        required: true
    },
    subject:{
        error: "",
        validate: "textNumber",
        maxLength: 500,
        required: true
    },
    html:{
        error: "",
        validate: "textNumber",
        maxLength: 400,
        required: true
    }
}

const initialCompanyFilterErrors ={
    name: "",
    nit: "",
    laboralSector: "",
    typeOfCompany: "",
    country: "",
    department: "",
    city: "",
    state: "",
    createdAtStart: "",
    createdAtEnd: "",
    subject: "",
    html: "",
    extraEmail:""
}

const MassMessageCompany:React.FC = ()=>{
    const urlCompany = "/admin/dashboard/empresas";
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
    const [filterValues, setFilterValues] = useState<ICompanyFilterForm>(new ICompanyFilterForm());
    const [filterValuesAttach, setFilterValuesAttach] = useState<IGraduateAttach>(new IGraduateAttach())
    const [formErrors, setFormErrors] = useState(initialCompanyFilterErrors);
    const [allLaboralSector, setAllLaboralSector] = useState<Array<IGeneralParameter>>([]);
    const [allCompanyType, setAllCompanyType] = useState<Array<IGeneralParameter>>([]);
    const [countryCitiesArray, setCountryCitiesArray] = useState<Array<IListParametersInfo>>([]);
    const [departmentArray, setDepartmentArray] = useState<Array<IGeneralParameter>>([]);
    const [cityArray, setCityArray] = useState<Array<IGeneralParameter>>([]);
    const [cities, setCities] = useState<Array<IGeneralParameter>>([]);
    const [chipData, setChipData] = React.useState<ChipData[]>([]);
    const [chipDataEmails, setChipDataEmails] = useState<ChipData[]>([]);
    const [recordsfound, setRecordsFound] = useState<number>(0);
    const [senderMail, setSenderMail] = useState<string>('');
    const [errorMessage, setErrorMessage]= useState('');
    const [successMessage, setSuccessMessage]= useState('');
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [openModalError, setOpenModalError] = useState(false);
    const [opeModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
    const [errorDocument, setErrorDocument] = useState<string>('');
    const [stateCheck, setStateCheck] = useState<any>({
        Inscrito: false,
        Activo: false,
        Rechazado: false,
        Inactivo: false
    });

    const isValidAddEmail =
        filterValues.extraEmail.length>0 &&
        !formErrors.extraEmail

    const isValidSend =
        filterValues.subject.length>0 &&
        !formErrors.subject &&
        filterValues.html.length>0 &&
        !formErrors.html &&
        (
            recordsfound>0 ||
            filterValues.aditionalEmails.length>0
        )

    const isValid =
        !formErrors.name &&
        !formErrors.nit &&
        !formErrors.createdAtStart &&
        !formErrors.createdAtEnd

    useEffect(()=>{
        window.scrollTo(0, 0);
        const getInitialData = async()=>{
            setStateCheck({ ...stateCheck, "Activo": true });
            const responseSender: any = await getEmailFrom();
            if(responseSender){
                const emailReceived = await responseSender.message.value;
                setSenderMail(emailReceived)
            }
            //  company type
            let responseCompanyType: any = await getCOMPANY_TYPE();
            responseCompanyType = sortByKey(responseCompanyType, 'name');
            setAllCompanyType(responseCompanyType)
            //  laboral sector
            let responseLaboralSector: any = await getLABORAL_SECTOR();
            responseLaboralSector = sortByKey(responseLaboralSector, 'name');
            setAllLaboralSector(responseLaboralSector)
            // data countries-cities
            let dataCountryCitiesRes: any = await getCOUNTRIES_CITIES();
            let dataCountryCities = dataCountryCitiesRes.list;
            dataCountryCities = sortByKey(dataCountryCities, 'grandParentName');
            setCountryCitiesArray(dataCountryCities);
            //data for departments field
            const dataDepartment:any = await getDEPARTAMENT();
            setDepartmentArray(dataDepartment || []);
            //data for cities
            const dataCity:any = await getCITY();
            setCityArray(dataCity || []);
        }
        getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if(filterValues.country === 0){
            setFilterValues(prev => ({
                ...prev,
                department:0,
                city:0
            }));
            setCities([]);
        }else {
            if(filterValues.country === 170){
                setCities([]);
            } else {
                setFilterValues(prev => ({
                    ...prev,
                    department:0,
                    city:0
                }));
                const countryObjSelected = countryCitiesArray.find(country=>country.id === filterValues.country);
                if(countryObjSelected){
                    setCities(countryObjSelected.childs);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filterValues.country])

    useEffect(()=>{
        setFilterValues(prev => ({
            ...prev,
            city:0
        }))
        if(filterValues.department>0){
            let citiesDep:Array<IGeneralParameter> = cityArray.filter(city=> city.parentId === filterValues.department);
            citiesDep = sortByKey(citiesDep, 'name');
            if(citiesDep.length>0){
                setCities(citiesDep);
            }
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filterValues.department])

    const setChips =()=>{
        let tempArray: Array<ChipData> = []
        let index = 0;
        const fieldNames=[
            {key: 'name', description: 'Razón social'},
            {key: 'nit', description: 'Nit de la empresa'},
            {key: 'laboralSector', description: 'Sector laboral'},
            {key: 'typeOfCompany', description: 'Tipo de empresa'},
            {key: 'country', description: 'País'},
            {key: 'department', description: 'Departamento'},
            {key: 'city', description: 'Municipio'},
            {key: 'rangeRegister', description: 'Fecha de inscripción'}
        ]
        for (const [key, value] of Object.entries(filterValues)) {
            if(value!==0 && value!=='' && value!==null && value.length!==0  && key !=="subject" && key !=="html" && key !=="extraEmail" && key !=="aditionalEmails"){
                if(value[0]!==null && value[1]!==null ){
                    let obj;
                    const fieldObj = fieldNames.find(field=>field.key === key);
                    if(key === 'laboralSector'){
                        obj = allLaboralSector.find(sector=>sector.parameter_code === value);
                    } else if (key === 'typeOfCompany'){
                        obj = allCompanyType.find(Tcompany=>Tcompany.parameter_code === value );
                    } else if (key === 'country' && value === 170){
                        obj = {name:'Colombia'}
                    } else if (key === 'department'){
                        obj = departmentArray.find(dep=>dep.parameter_code === value);
                    } else if (key === 'city'){
                        obj = cities.find(city=>city.parameter_code === value);
                    } else if (key === 'rangeRegister'){
                        const startDate: string = moment(value[0]).format('DD/MM/YYYY');
                        const endDate: string = moment(value[1]).format('DD/MM/YYYY');
                        obj = {name: `${startDate} hasta ${endDate}`}
                    }
                    if(obj){
                        tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + obj.name })
                    } else {
                        tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + value})
                    }
                    index++;
                }
            }
        }
        setChipData(tempArray)
    };

    const handleDeleteChip = (chipToDelete: ChipData) => () => {
        const name =chipToDelete.name;
        setChipData((chips) => chips.filter((chip) => chip.name !== chipToDelete.name));
        if(name === 'laboralSector' || name === 'typeOfCompany' || name === 'country' || name === 'department' || name === 'city'){
            setFilterValues(prev => ({
                ...prev,
                [chipToDelete.name]: 0
            }));
        } else if(name === 'rangeRegister'){
            setFilterValues(prev => ({
                ...prev,
                [name]: [null,null]
            }));
        } else {
            setFilterValues(prev => ({
                ...prev,
                [chipToDelete.name]: ''
            }));
        }
    };

    useEffect(()=>{
        if(isValid){
            setChips();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, isValid])

    const tranformDataCompany = (values: ICompanyFilterForm, preview:boolean)=>{
        const temporaryObject: ICompanyFilterObj = new ICompanyFilterObj();
        temporaryObject.type = "company";
        temporaryObject.preview = preview;
        if(values.name !== ""){
            temporaryObject.filters.name = values.name;
        } else { delete temporaryObject.filters.name }
        if(values.nit === null || values.nit === ""){
            delete temporaryObject.filters.nit
        } else { temporaryObject.filters.nit = parseInt(values.nit); }
        if(values.laboralSector >0){
            temporaryObject.filters.laboralSector = values.laboralSector;
        } else { delete temporaryObject.filters.laboralSector }
        if(values.typeOfCompany >0){
            temporaryObject.filters.typeOfCompany = values.typeOfCompany;
        } else { delete temporaryObject.filters.typeOfCompany }
        if(values.country >0){
            temporaryObject.filters.country = values.country;
        } else { delete temporaryObject.filters.country }
        if(values.department >0){
            temporaryObject.filters.department = values.department;
        } else { delete temporaryObject.filters.department }
        if(values.city >0){
            temporaryObject.filters.city = values.city;
        } else { delete temporaryObject.filters.city }
        if(values.rangeRegister[0] !== null && values.rangeRegister[1] !== null){
            if(temporaryObject.filters.rangeRegister){
                temporaryObject.filters.rangeRegister.sinceDate = moment(values.rangeRegister[0]).format("YYYY-MM-DD");
                temporaryObject.filters.rangeRegister.untilDate = moment(values.rangeRegister[1]).format("YYYY-MM-DD");
            }
        } else { delete temporaryObject.filters.rangeRegister }
        const foundSelected = Object.keys(stateCheck).filter(item=>stateCheck[item]===true);
        if(foundSelected.length>0){
            temporaryObject.filters.state = foundSelected
        } else { delete temporaryObject.filters.state }
        if(filterValues.subject !== ""){
            temporaryObject.subject = filterValues.subject;
        }
        if(filterValues.html === "" || filterValues.html ==="<p><br></p>"){
            temporaryObject.html = "";
        } else {
            temporaryObject.html = filterValues.html;
        }
        if(filterValues.aditionalEmails.length>0){
            temporaryObject.aditionalEmails = filterValues.aditionalEmails;
        }
        if(filterValues.attachingFiles.length>0){
            filterValues.attachingFiles.map(item=>delete item.previewUrl);
            temporaryObject.attachingFiles = filterValues.attachingFiles;
        }
        return temporaryObject;
    }

    const clickApplyFilters = async(value:any, action:string)=>{
        if(action === 'apply'){
            const objectToSend = tranformDataCompany(filterValues, true);
            const responseMassiveMsg: any = await trackPromise(sendMassiveEmail(
                userToken,
                objectToSend
            ))
            if(responseMassiveMsg.status === 200){
                const result = await responseMassiveMsg.json();
                setRecordsFound(result.total);
                setChips();
            } else {
                setOpenModalError(true);
                setErrorMessage("Hubo un error, intente de nuevo mas tarde, o contacte al administrador general")
            }
        } else if (action === 'clear'){
            setChipData([]);
            setFilterValues(new ICompanyFilterForm());
            setFormErrors(initialCompanyFilterErrors);
            setRecordsFound(0);
        }
    }

    const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStateCheck({ ...stateCheck, [event.target.name]: event.target.checked });
    };


    const changeFilterForm = (e: any) => {
        const {name,value} = e.target;
        setFilterValues(prev => ({
            ...prev,
            [name]: value
        }));
        const error: string = Validations(name, value, filterCompanyValidation) || "";
        setFormErrors(prev=>({
            ...prev,
            [name]: error
        }));
    }

    const changeFilterDates = (name: string, dateValue: DateRange<Date>)=>{
        setFilterValues(prev => ({
            ...prev,
            [name]: dateValue
        }));
        const startDate: string = moment(dateValue[0]).format('YYYY/MM/DD');
        const endDate: string = moment(dateValue[1]).format('YYYY/MM/DD');
        let labelStart: string = "";
        let labelEnd: string = "";
        const valueStart = startDate === "Fecha inválida" ? "Fecha inválida" : "";
        const valueEnd = endDate === "Fecha inválida" ? "Fecha inválida" : "";
        if(name === "rangeRegister"){
            labelStart = "createdAtStart";
            labelEnd = "createdAtEnd";
        }
        errorDates(labelStart, valueStart);
        errorDates(labelEnd, valueEnd);
    }

    const errorDates = (label: string, value: string) => {
        setFormErrors(prev => ({
            ...prev,
            [label]: value
        }));
    }

    const handleChangeEditor = (value: any) => {
        if(value !== ""){
            setFilterValues((prev:any) => ({
            ...prev,
            html: value
        }));
        } else{
            setFilterValues((prev:any) => ({
            ...prev,
            html: ""
        }));
        }
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'},
            { 'align': [] }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'align',
        'link', 'image', 'video'
    ];

    const addEmail = () => {
        let tempArrayEmails: Array<ChipData> = [];
        let i = 0;
        filterValues.aditionalEmails.forEach(email=>{
            tempArrayEmails.push({key: i, name:email, label: email })
            i++;
        })
        tempArrayEmails.push({key: filterValues.aditionalEmails.length, name:filterValues.extraEmail, label: filterValues.extraEmail })
        setFilterValues(prev=>({
            ...prev,
            aditionalEmails: [...filterValues.aditionalEmails, filterValues.extraEmail],
            extraEmail: ''
        }))
        setChipDataEmails(tempArrayEmails);
    }

    const handleDeleteEmail = (chipToDelete: ChipData) => () => {
        const name =chipToDelete.name;
        setChipDataEmails((chips) => chips.filter((chip) => chip.name !== chipToDelete.name));
        const newArrayEmails = filterValues.aditionalEmails.filter(chip=>name !==chip);
        setFilterValues(prev=>({
            ...prev,
            aditionalEmails: newArrayEmails
        }))
    }

    const confirmSend = async()=>{
        setOpenModalConfirm(true);
    }
    const closeModalConfirm = () =>{
        setOpenModalConfirm(false);
    }

    const sendEmail = async()=>{
        setOpenModalConfirm(false);
        filterValues.attachingFiles = filterValuesAttach.attachingFiles
        const objectToSend = tranformDataCompany(filterValues, false);
        const responseMassiveMsg: any = await trackPromise(sendMassiveEmail(
            userToken,
            objectToSend
        ))
        if(responseMassiveMsg.status === 200){
            setOpenModalSuccess(true);
            setSuccessMessage("Mensaje enviado");
            setChips();
        } else {
            setOpenModalError(true);
            setErrorMessage("Hubo un error, intente de nuevo mas tarde, o contacte al administrador general")
        }
    }

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
        window.location.reload();
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
        window.location.reload();
    };

    const handleUploadDocuments = async(e: any) => {
        setErrorDocument('');
        const { name } = e.target;
        if (name ===  "otherFiles"){
            if(e.target.files.length>0){
                if(e.target.files[0].type === 'application/pdf'){
                    if(e.target.files[0]){
                        const file = e.target.files[0];
                        const base64File: any = await convertToBase64(file);
                        if(base64File.length>0){
                            const newFileBase64:AttachingFile = {base64: base64File, type:"pdf", filename:file.name, previewUrl : URL.createObjectURL(e.target.files[0])}
                            setFilterValuesAttach(prev=>({
                                ...prev,
                                attachingFiles:[...filterValuesAttach.attachingFiles, newFileBase64]
                            }));
                        }
                    }
                } else{
                    setErrorDocument('Solo puede subir archivos que sean formato pdf');
                }
            }
        }
    }

    const handleDeleteFile = async(fileToDelete:AttachingFile) => {
        const newFiles = filterValuesAttach.attachingFiles && filterValuesAttach.attachingFiles.filter((obj: AttachingFile)=>obj.filename !== fileToDelete.filename);
        setFilterValuesAttach(prev=>({
            ...prev,
            attachingFiles:newFiles
        }))
    }


    return(
        <>
            {isLogin() ?
                <>
                    <Grid container className="main-container">
                        <Grid container item xs={12}>
                            <Grid item xs={12} md={4}>
                                <div className="title-section-line">
                                    <EmailIcon className="group-icon"/>
                                    <Typography variant="h2" className="message-title" >
                                        Mensajería masiva empresas
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={8} className="line" />
                            <Grid item xs={10} md={4} lg={10}>
                                <div className="title-section-line">
                                    <Typography variant="h3" className="message-title" >
                                        <b className="red-title">Importante:</b> Si necesita enviar un correo a todas las empresas activas en el SIE, se recomienda sobre la subsección de Estado de la empresa aplicar el estado “Activo” o los filtros que se requieren.
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <SimpleFilterBox title="Estado de la empresa" subtitle="Filtrar por estados:"  stateCheck={stateCheck} onChangeState={handleChangeState} stateOptions={stateOptionsCompany}/>
                        </Grid>
                        <Grid container item xs={12}>
                            <MoreFiltersCompany titleSection="Agregar otros filtros de búsqueda" formValues={filterValues} formErrors={formErrors} onChangeForm={changeFilterForm} changeFilterDates={changeFilterDates} allLaboralSector={allLaboralSector} allCompanyType={allCompanyType} countryCitiesArray={countryCitiesArray} departmentArray={departmentArray} cities={cities} reportForm={false} stateOptions={stateOptionsCompany} msgForm={true}/>
                        </Grid>
                        <Grid container item xs={12}>
                            <ShowFilters titleSection="Filtros seleccionados" filtersData={chipData} stateCheck={stateCheck} showButtons={true} showDelete={true} deleteFilter={handleDeleteChip} isValid={isValid} onApplyFilters={clickApplyFilters} />
                        </Grid>

                        <Grid container item xs={12} className="mass-message-container">
                            <Grid container item xs={12} className="message-container">
                                <Grid container item xs={12} className="title-container-message">
                                    <Typography variant="h2" className="title">
                                        Destinatarios
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} direction="row" className="message-content">
                                    {recordsfound>0 &&
                                        <Grid item xs={12} className="field-box-margin">
                                            <Typography variant="h4" className="field-box-title-company">
                                                Total de empresas encontradas: {recordsfound}
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid container item xs={12} className="line" />
                                    <Grid item xs={12} md={6} className="field-box-margin">
                                        <Typography variant="h4" className="field-box-title-company">
                                            Añadir correos manualmente
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            name="extraEmail"
                                            placeholder="Correo adicional"
                                            variant="outlined"
                                            value={filterValues.extraEmail || ""}
                                            onChange={changeFilterForm}
                                            error={!!formErrors.extraEmail}
                                            helperText={formErrors.extraEmail}
                                            margin="none"
                                        />
                                    </Grid>
                                    <Grid container item xs={12} md={6} className="field-box-margin" justify="flex-end" alignItems="flex-end">
                                        <div>
                                            <Button disabled={!isValidAddEmail} variant="contained" className="add-email-button" onClick={addEmail}>
                                                <Typography variant="h5" className="regular-serif">
                                                    Adicionar correo
                                                </Typography>
                                            </Button>
                                        </div>
                                    </Grid>
                                    <Grid container item xs={12} className="line" />
                                    {chipDataEmails.length>0 &&
                                        <Grid container item xs={12}>
                                            <ShowFilters titleSection="Correos agregados" filtersData={chipDataEmails} stateCheck={stateCheck} showButtons={false} showDelete={true} deleteFilter={handleDeleteEmail}  />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} className="message-container">
                                <Grid container item xs={12} className="title-container-message">
                                    <Typography variant="h2" className="title">
                                        Contenido
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} direction="row" className="message-content">
                                    <Grid item xs={12} sm={6} className="field-box-margin">
                                        <Typography variant="h4" className="field-box-title-company link-responsive">
                                            Remitente: {senderMail}
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={12} className="line" />
                                    <Grid item xs={12}  className="field-box-margin">
                                        <Typography variant="h4" className="field-box-title-company">
                                            Asunto*
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            name="subject"
                                            placeholder="Asunto"
                                            variant="outlined"
                                            value={filterValues.subject || ""}
                                            onChange={changeFilterForm}
                                            error={!!formErrors.subject}
                                            helperText={formErrors.subject}
                                            margin="none"
                                        />
                                    </Grid>
                                    <Grid item xs={12}  className="field-box-margin">
                                        <Typography variant="h4" className="field-box-title-company">
                                            Cuerpo del correo*
                                        </Typography>
                                        <Grid item xs={12}>
                                            <ReactQuill
                                                placeholder="Crea un descripcion detallada en este espacio"
                                                theme="snow"
                                                modules={modules}
                                                formats={formats}
                                                style={{
                                                    width: "100%",
                                                    height: "auto",
                                                    minHeight: "100px",
                                                }}
                                                value={filterValues.html}
                                                onChange={handleChangeEditor}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} className="line" />
                                    <Grid item xs={12}  className="documents-mass-message">
                                        <Typography variant="h5" className="description-documents-text regular-serif">En esta sección puede adjuntar documentos que desea enviar como adjuntos en el correo. Para adjuntar el documento de clic en el botón "Adjuntar archivo" y seleccione el documento que desea adjuntar. El documento debe estar en formato pdf.</Typography>
                                        {
                                            filterValuesAttach.attachingFiles.length>0 &&
                                            <Grid container item xs={12} justify="flex-start" direction="column">
                                                { filterValuesAttach.attachingFiles.map((file, index)=>(
                                                    <div key={index} className="list-file-element">
                                                        <Link target="_blank" rel="noopener" href={file.previewUrl}>
                                                            <Typography align="center" variant="h6" className="regular-serif">
                                                                {file.filename}
                                                            </Typography>
                                                        </Link>
                                                        <IconButton className="ico-delete" onClick={()=>handleDeleteFile(file)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                ))}
                                            </Grid>
                                        }
                                        <Typography variant="h6" className="Regular-serif primaryError">
                                            {errorDocument}
                                        </Typography>
                                        <Grid container item xs={12} justify="flex-start">
                                            <div className="upload-button-container">
                                                <Button variant="contained" component="label" className="upload-button">
                                                    <Typography variant="h5" className="regular-serif">
                                                        Adjuntar archivo
                                                    </Typography>
                                                    <div>
                                                        <input type="file" name="otherFiles" onChange={handleUploadDocuments} accept="application/pdf" hidden/>
                                                    </div>
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} className="line" />
                                    <Grid container item xs={12} className="container-buttons-message">
                                        <Button variant="contained" className="cancel-button" component={ LinkTo } to={urlCompany} startIcon={<ChevronLeftIcon />} >
                                            <Typography variant="h5" className="regular-serif">
                                                Cancelar
                                            </Typography>
                                        </Button>
                                        {containsAny(permissionAdminSendMassMsgCompanies, userContext.userPermissions) &&
                                            <Button disabled={!isValidSend} variant="contained" className="send-button" endIcon={<SendIcon />} onClick={confirmSend}>
                                                <Typography variant="h5" className="regular-serif">
                                                    Enviar
                                                </Typography>
                                            </Button>
                                        }
                                        <ModalOffersAction
                                            open={opeModalConfirm}
                                            handleClose={closeModalConfirm}
                                            handleContinue={sendEmail}
                                            iconModal={<WarningIcon className="big-info-icon" />}
                                            title="¿Está seguro de enviar el correo?"
                                            headerdescription=""
                                            description="Esta intentando enviar un correo a uno o mas destinatarios"
                                            backBtnText="Regresar"
                                            colorBtnBack= ""
                                            continueBtnText="Si, Continuar"
                                            colorBtnContinue="#14A5DC"
                                            valid={true}
                                        />
                                        <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
                                        <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <LoadingLockPage load={promiseInProgress}/>
                </>
            :
                <Redirect to ="/"/>
            }
        </>
    )
}

export default MassMessageCompany;