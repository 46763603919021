import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import RegisterModal from '../../ShareComponents/RegisterModal';
import './RegisterBox.scss';



const RegisterBox: React.FC = ()=>{
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

    return(
        <>
            <Grid container item className="register-container">
                <Grid container justify="center" className="register-text">
                    <Typography variant="h4" align="center" className="regular-serif-primaryColor">
                        <span>El Programa de Egresados de la Universidad Nacional de Colombia le da la bienvenida.</span>
                        <span className="bold-serif"> Si usted es egresado graduado antes del segundo semestre de 2003 y no ha realizado su inscripción en el SIE, debe registrarse aquí:</span>
                    </Typography>
                </Grid>
                <Grid container item justify="center" className="btn-container">
                    <Button variant="contained" className="button-register" size="small" onClick={(handleClickOpen)}>
                        <Typography variant="h6" className="regular-serif">
                            Registrarse
                        </Typography>
                    </Button>
                    <RegisterModal
                        open={open}
                        handleClose={handleClose}
                        title="Registro de egresados"
                        subtitle="Apreciado(a) egresado(a) graduado hasta el 2003:"
                        description="Bienvenido(a) al Sistema de Información para Egresados de la UNAL. Para ingresar a su registro, actualizar datos, imprimir su hoja de vida y acceder a ofertas de empleo, es indispensable registrarse diligenciando el siguiente formulario."
                        secondDescription="Al finalizar satisfactoriamente su proceso de registro se le creará un usuario que es su identidad digital para acceder a todos los sistemas."
                        thirdSubtitle="Importante:"
                        thirdDescription="el proceso para egresados graduados posterior al 2003 se realiza mediante migración automática del SIA al SIE, por lo que no es necesario que se registre."
                        backBtnText="REGRESAR"
                        colorBtnBack=""
                        continueBtnText="IR AL REGISTRO"
                        continueBtnColor=""
                        linkContinue="/registro"
                    />

                </Grid>
            </Grid>
        </>
    )
}

export default RegisterBox
