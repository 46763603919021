import React from 'react';
import Grid from "@material-ui/core/Grid";
import { Redirect } from 'react-router';
// import MassMessageCompany from '../../../../components/MassMessage/MassMessageCompany';
import { isLogin } from '../../../../services/AuthService';
import UserContext from '../../../../utils/UserContext';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import { containsAny } from '../../../../helpers/sharedFunctions';
import { permissionCompanyMassMessageView } from '../../../../utils/PermissionsVariables';
import MassMessageCompany from '../../../../components/MassMessage/MassMessageCompany';

const MassMessageManagementCompany:React.FC = () => {
  const userContext = React.useContext(UserContext);

  return (
    <>
    {isLogin() ?
      <>
        <Grid container className="main-container">
          {containsAny(permissionCompanyMassMessageView,userContext.userPermissions) ?
            <Grid container item xs={12}>
              <MassMessageCompany />
            </Grid>
          :
            <NotAuthorized/>
          }
        </Grid>
      </>
    :
      <Redirect to ="/"/>
    }
    </>
  )
}

export default MassMessageManagementCompany
