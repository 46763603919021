import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Validations from '../../../helpers/Validations';
import { IFile } from '../../../interfaces/IFile';
import { IRegisterCompany } from '../../../interfaces/IRegisterCompany';
import { IPresignedUrl, IPreSignedUrlReq } from '../../../interfaces/IPreSignedUrl';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import RegisterContext from '../../../utils/RegisterContext';
import FirstStepCompany from './FirstStepCompany';
import SecondStepCompany from './SecondStepCompany';
import ThirdStepCompany from './ThirdStepCompany';
import { getSignUrl } from '../../../services/s3Service';
import { sortByKey } from '../../../helpers/sharedFunctions';
import { getCITY, getCOMPANY_TYPE, getCOUNTRIES_CITIES, getDEPARTAMENT, getLABORAL_SECTOR, getTYPE_PERSON } from '../../../services/ParameterService';
import '../Stepper.scss';
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';


// Step titles
const labels = ["Información general", "Adjuntar documentos", "Confirmación del registro" ]

const useStyles = makeStyles({
    icon:{
      "&$activeIcon": {
        color: '#14A5DC',
        transform: 'scale(1.4)',
      },
      "&$completedIcon": {
        color: '#14A5DC',
        transform: 'scale(1.4)',
      }
    },
    activeIcon: {},
    completedIcon: {}
});

const fieldsValidationCompany = {

    name: {
      error: "",
      validate: "textNumber",
      minLength: 3,
      required: true
    },

    nit: {
      error: "",
      validate: "number",
      minLength: 7,
      maxLength: 15,
      required: true
    },

    verificationDigit: {
      error: "",
      validate: "number",
      minLength: 1,
      maxLength: 1,
      required: true
    },

    typeOfPerson:{
      error: "",
      validate: "select",
      required: true
    },

    typeOfCompany: {
      error: "",
      validate: "select",
      required: true
    },

    laboralSector: {
      validate: "select",
      required: true
    },

    country: {
      validate: "select",
      required: true
    },

    department: {
      validate: "select",
      required: true
    },

    city: {
      validate: "select",
      required: true
    },

    address: {
      error: "",
      validate: "textNumber",
      minLength: 5,
      maxLength: 40,
      required: true
    },

    cellphoneCompany: {
      error: "",
      validate: "number",
      minLength: 10,
      maxLength: 12,
      required: true
    },

    phone: {
      error: "",
      validate: "textNumber",
      minLength: 7,
      maxLength: 10,
      required: false
    },

    website: {
      error: "",
      validate: 'website',
      maxLength: 30,
      required: false
    },

    contactName: {
      error: "",
      validate: "text",
      minLength: 2,
      required: true
    },

    position: {
      error: "",
      validate: "textNumber",
      minLength: 3,
      required: true
    },

    email: {
      error: "",
      validate: "email",
      required: true
    },

    cellphoneContact: {
      error: "",
      validate: "number",
      minLength: 10,
      maxLength: 15,
      required: true
    },

    phoneContact: {
      error: "",
      validate: "number",
      minLength: 7,
      maxLength: 10,
      required: false
    },

    repLegalCountry: {
      validate: "select",
      required: true
    },

    repLegalDepartment: {
      validate: "select",
      required: true
    },

    repLegalCity: {
      validate: "select",
      required: true
    },

    description_document: {
      error: "",
      validate: "textNumber",
      minLength: 10,
      maxLength: 100,
      required: true
    },

    files : {
      error: "",
      required: true
    }

}

const CompanyStepper:React.FC = ()=>{
    const classes = useStyles();
    const preForm: IRegisterCompany = new IRegisterCompany();
    const initialErrors: IRegisterCompany = {...preForm};
    const [activeStep, setActiveStep] = useState(0);
    const [formValues, setFormValues] = useState(preForm);
    const [formErrors, setFormErrors] = useState(initialErrors);
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [firstAccorDone, setFisrtAccorDone] = useState(false);
    const [secondAccorDone, setSecondAccorDone] = useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [openJobModal, setOpenJobModal] = React.useState(false);
    //validation steps before send
    const [step1Completed, setStep1Completed] = React.useState(false);
    const [step2Completed, setStep2Completed] = React.useState(false);
    //Initial data
    const [allCompanyType, setAllCompanyType] = useState<Array<IGeneralParameter>>([]);
    const [allLaboralSector, setAllLaboralSector] = useState<Array<IGeneralParameter>>([]);
    const [departmentArray, setDepartmentArray] = useState<Array<IGeneralParameter>>([]);
    const [cityArray, setCityArray] = useState<Array<IGeneralParameter>>([]);
    const [cities, setCities] = useState<Array<IGeneralParameter>>([]);
    const [allTypePerson,setAllTypePerson] = useState<Array<IGeneralParameter>>([]);
    const [countryCitiesArray, setCountryCitiesArray] = useState<Array<IListParametersInfo>>([]);
    const [contactCitiesArray, setContactCitiesArray] = useState<Array<IGeneralParameter>>([]);

    useEffect(()=>{
      const getInitialData = async()=>{
        //person type
        let responsePersonType: Array<IGeneralParameter> = await getTYPE_PERSON();  
        setAllTypePerson(responsePersonType);
        //  company type
        let responseCompanyType: Array<IGeneralParameter> = await getCOMPANY_TYPE();
        responseCompanyType = sortByKey(responseCompanyType, 'name');
        setAllCompanyType(responseCompanyType)
        //  laboral sector
        let responseLaboralSector: Array<IGeneralParameter> = await getLABORAL_SECTOR();
        responseLaboralSector = sortByKey(responseLaboralSector, 'name');
        setAllLaboralSector(responseLaboralSector);
        // Country-cities
        let responseCountryCities: Array<IListParametersInfo> =  (await getCOUNTRIES_CITIES()).list;
        responseCountryCities = sortByKey(responseCountryCities, 'grandParentName');
        setCountryCitiesArray(responseCountryCities);
        //data for departments field
        let dataDepartment:Array<IGeneralParameter> = await getDEPARTAMENT();
        dataDepartment = sortByKey(dataDepartment, 'name');
        setDepartmentArray(dataDepartment ? dataDepartment : []);
        //data for city field
        const dataCity:Array<IGeneralParameter> = await getCITY();
        setCityArray(dataCity ? dataCity : []);
      }
      getInitialData();
    },[])

    useEffect(()=>{
      setFormValues(prev => ({
        ...prev,
        repLegalCity: 0,
        repLegalDepartment: 0
      }))
      if(formValues.repLegalCountry>0){
        const countryObjSelected = countryCitiesArray.find(country=>country.id === formValues.repLegalCountry);
        if(countryObjSelected){
          setContactCitiesArray(countryObjSelected.childs);
        }
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.repLegalCountry]);

  useEffect(()=>{
    setContactCitiesArray([]);
    setFormValues(prev => ({
      ...prev,
      repLegalCity: 0,
    }))
    if(formValues.repLegalDepartment>0){
        const citiesDep:Array<IGeneralParameter> = cityArray.filter(city=> city.parentId === formValues.repLegalDepartment);
        
        if(citiesDep.length>0){
            setContactCitiesArray(citiesDep)
        }
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[formValues.repLegalDepartment])


    useEffect(()=>{
      setCities([]);
      setFormValues(prev => ({
        ...prev,
        city:0
      }))
      if(formValues.department>0){
        const citiesDep:Array<IGeneralParameter> = cityArray.filter(city=> city.parentId === formValues.department);
        if(citiesDep.length>0){
          setCities(citiesDep)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formValues.department])


    // accordion controls
    const handleChangeAccordion = (panel: string, buttonExp?: boolean, infoValid?:string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if(buttonExp){
        newExpanded = buttonExp;
        if(infoValid === 'panel1'){
          setFisrtAccorDone(true)
        } else if (infoValid === 'panel2'){
          setSecondAccorDone(true)
          newExpanded = false;
        }
      }
      setExpanded(newExpanded ? panel : false);
    };

    const handleValidForm = (panel: string, formValid: boolean)=>{
      if(panel === 'panel1'){
        setFisrtAccorDone(formValid)
      } else if (panel === 'panel2'){
        setSecondAccorDone(formValid)
      }
    }

    // Proceed to next step
    const handleNext = () => {
      if(activeStep === 0 ){
        setOpenModal(false)
        handleCloseJobModal();
      }
      setActiveStep(prev => prev + 1)
    }

    // Go back to prev step
    const handleBack = () => {
      setOpenModal(false)
      handleCloseJobModal();
      setActiveStep(prev => prev - 1)
    }

    const handleMOpenModal = ()=> setOpenModal(true);

    const handleCloseModal = () =>setOpenModal(false);

    //JobModal
    const handleOpenJobModal = ()=> {
      setOpenModal(false);
      setOpenJobModal(true);
    }

    const handleCloseJobModal = () =>setOpenJobModal(false);

    const handleJobBank = (event: React.ChangeEvent<HTMLInputElement>)=>{
      const actualDate = new Date()
      setFormValues(prev => ({
        ...prev,
        inJobBank: (event.target.checked ? actualDate : null)
      }))
    }

    // Handle form change
    const handleChange = async(e: any) => {
      const {name, value} = e.target;
      // Set values
      if (name === 'files'){
        if(formValues.files.length<3 && e.target.files.length>0){
          if(e.target.files[0].type === 'application/pdf'){
            if (e.target.files[0].size < (4000000)) {
              setFormValues(prev => ({
                ...prev,
                [name]: [...formValues.files, {file:e.target.files[0], description:formValues.description_document, previewUrl : URL.createObjectURL(e.target.files[0])}],
                description_document: ''}
              ));
              setFormErrors(prev=>({
                ...prev,
                fileError: ''}
              ));
              if(e.target.files[0]){
                const fileToUpload: IPreSignedUrlReq = {
                  contentType: e.target.files[0].type,
                  fileKey: e.target.files[0].name.replace(/[^a-zA-Z0-9_. ]/g, "").split(' ').join('_'),
                  folderId:`Empresa-${formValues.nit}`,
                  description:formValues.description_document
                }
                const result:IPresignedUrl = await getSignUrl(fileToUpload);
                result.name = e.target.files[0].name;
                if(result){
                  setFormValues(prev => ({
                    ...prev,
                    signedUrllArr: [...formValues.signedUrllArr, result]
                  }))
                }
              }
            } else {
              setFormErrors(prev => ({
                ...prev,
                fileError: 'El archivo no debe superar 4MB'
              }));
            }
          } else {
            setFormErrors(prev=>({
              ...prev,
              fileError: 'Solo puede subir archivos en pdf'
            }));
          }
        } else {
            if(e.target.files.length>0 && e.target.files[0].type === 'application/pdf'){
            setFormErrors(prev=>({
              ...prev,
              fileError: 'No puede subir mas archivos, si desea cambiar o eliminar un archivo, utilice los botones en cada una de las tarjetas'
            }));
            }
        }
      } else {
        setFormValues(prev => ({
          ...prev,
          [name]: value
        }))
        const error: string = Validations(name, value, fieldsValidationCompany) || "";
        setFormErrors(prev=>({
          ...prev,
          [name]: error
        }));
      }
    }

    const handleDeleteFile = (fileToDelete:IFile)=>{
      const name = fileToDelete.file?.name;
      const newFiles = formValues.files.filter(obj=>obj.file?.name !== name);
      const newsignUrls = formValues.signedUrllArr.filter(obj=>obj.name !== name);
      setFormValues(prev => ({
        ...prev,
        files: newFiles,
        signedUrllArr:newsignUrls
      }));
    }

    const handleTerms = (event: React.ChangeEvent<HTMLInputElement>)=>{
      setFormValues(prev => ({
        ...prev,
        termsOfUse: event.target.checked
      }))
    }

    const changeStep = (step:number, panel?: string)=>{
      setActiveStep(step);
      if(panel !== undefined){
        setExpanded(panel);
      }
      if(openModal){
        setOpenModal(false)
      }
    }

    const handleSteps = (step: number) => {
      switch (step) {
        case 0:
          return (
            <FirstStepCompany/>
          )
        case 1:
          return (
            <SecondStepCompany/>
          )
        case 2:
          return (
            <ThirdStepCompany/>
          )
        case 3:
          sessionStorage.setItem('successRegister', 'company');
          return (
            <Redirect to="/exitoso"/>
          )
        default:
          break
      }
    }
    return(
      <RegisterContext.Provider value={{formValues, setFormValues,formErrors, setFormErrors,handleNext,handleBack,handleChange, expanded, 
      handleChangeAccordion, firstAccorDone, secondAccorDone, handleValidForm, handleDeleteFile, changeStep, handleMOpenModal, openModal, 
      handleCloseModal, handleTerms, step1Completed, setStep1Completed, step2Completed, setStep2Completed, allCompanyType, allLaboralSector, countryCitiesArray, contactCitiesArray,
      departmentArray, cities, handleJobBank,handleCloseJobModal,handleOpenJobModal,openJobModal, allTypePerson}}>
        <Grid container item xs={12}>
          <Grid container  className="stepper-container">
            <Stepper activeStep={activeStep}  alternativeLabel className="steps-header">
              {labels.map(label => (
                <Step key={label} className="step-header-container-company">
                  <StepLabel StepIconProps={{ classes:{ root: classes.icon, active: classes.activeIcon, completed: classes.completedIcon } }}><span className="step-label-text">{label}</span></StepLabel>
                </Step>
              ))}
            </Stepper>
            <Grid container item xs={12} className="steps-container">
              {handleSteps(activeStep)}
            </Grid>
          </Grid>
        </Grid>
      </RegisterContext.Provider>
    )
}

export default CompanyStepper;