import moment from "moment";

class leaveReasons {
    reason: string = "";
    description: string = "";
  }
export class IRegisterCompanyData{
    name: string;
    nit: string;
    verificationDigit: string;
    typeOfCompany: number | null;
    typeOfPerson:number | null;
    laboralSector: number | null;
    country: number | null;
    department: number | null;
    city: number | null;
    address: string;
    phone: string;
    cellphoneCompany: string;
    website: string;
    contactName: string;
    position: string;
    email: string;
    phoneContact: string;
    cellphoneContact: string;
    infoContactName: string;
    infoContactPhone: string;
    infoContactCellphone: string;
    infoContactMail: string;
    repLegalCountry: number;
    repLegalDepartment: number ;
    repLegalCity: number;
    termsOfUse: boolean;
    inJobBank:  string | null;
    sieRetirement: boolean;
    reasons: leaveReasons;
    constructor(def:any){
        this.name = def.name || "";
        this.nit = def.nit || "";
        this.verificationDigit = def.verificationDigit || "";
        this.typeOfCompany = def.typeOfCompany || null;
        this.typeOfPerson = def.typeOfPerson || null;
        this.laboralSector = def.laboralSector || null;
        this.country = def.country || null;
        this.department = def.department || null;
        this.city = def.city || null;
        this.address = def.address || "";
        this.phone = def.phone || "";
        this.cellphoneCompany = def.cellphoneCompany || "";
        this.website = def.website || "";
        this.contactName = def.contactName || "";
        this.position = def.position || "";
        this.email = def.email || "";
        this.phoneContact = def.phoneContact || "";
        this.cellphoneContact = def.cellphoneContact || "";
        this.infoContactName = def.infoContactName || "";
        this.infoContactCellphone = def.infoContactCellphone || "";
        this.infoContactPhone = def.infoContactPhone || "";
        this.infoContactMail = def.infoContactMail || "";
        this.repLegalCountry = def.repLegalCountry || null;
        this.repLegalDepartment = def.repLegalDepartment || null;
        this.repLegalCity = def.repLegalCity || null;
        this.termsOfUse = def.termsOfUse || false;
        this.inJobBank = def.inJobBank ? moment(def.inJobBank).format() : null;
        this.sieRetirement = def.sieRetirement || false;
        this.reasons = def.reasons || null;
    }
}