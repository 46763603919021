export class ITransactionalReport{
    preview: boolean;
    rangeDate: IRangeDate;
    user: string;
    check: boolean;
    constructor(def:any){
        this.preview = def.preview || false;
        this.rangeDate = def.rangeDate || new IRangeDate();
        this.user = def.user || null;
        this.check = def.check || false;
    }
}

export class IRangeDate{
    dateInit: string = "";
    dateEnd: string = "";
}