import React from "react";
import Grid from "@material-ui/core/Grid";
import WarningIcon from '@material-ui/icons/Warning';
import { IGetOffer } from "../../../interfaces/IGetOffer";
import DescriptionOfferBox from "../DescriptionOfferBox";
import NotificationBox from '../../ShareComponents/NotificationBox';
import "./EndLaboralOfferDescription.scss";
import moment from "moment";

interface EndLaboralProps{
  dataOffer: IGetOffer;
  typePosition: string;
  laboralSector: string;
  contract: string;
}

const EndLaboralOfferDescription: React.FC<EndLaboralProps> = ({dataOffer, typePosition, laboralSector, contract}) => {

  return (
    <Grid className="end-offer-info-container">
      <Grid container item xs={12} >
        <Grid container item xs={12} md={10} lg={10} className="description-end-offer-container" >
          <DescriptionOfferBox dataOffer={dataOffer} typePosition={typePosition} laboralSector={laboralSector} contract={contract} showButtons={false}/>
        </Grid>
        {
          (moment(new Date()).isBefore(dataOffer.jobOfferEndDate)) &&
          <Grid container item xs={12} lg={2} justify="center">
            <NotificationBox
              bgColor="#FFF4E5"
              title='Esta intentando cerrar una oferta antes de la  "fecha final de la convocatoria."'
              titleColor="#004B73"
              description=""
              descColor="#004B73"
              positionBox="sticky"
              icon={
                <WarningIcon style={{ color: "#FF9800" }} fontSize="large" />
              }
            />
          </Grid>
        }
      </Grid>
    </Grid>
  );
};

export default EndLaboralOfferDescription;
