import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Link}  from 'react-router-dom';
import './RegisterModal.scss'


const styles = (theme: Theme) =>
createStyles({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#004B73',
  },
})

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="register-dialog-header" {...other}>
      <Typography align="center" variant="h2" className="bold-serif-primaryColor" style={{marginRight:'16px'}}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface RegisterModalProps{
    open: any;
    handleClose: any;
    title: string;
    subtitle: string;
    description: string;
    secondSubtitle?: string;
    secondDescription?: string;
    thirdSubtitle?: string;
    thirdDescription?: string;
    backBtnText?: string;
    colorBtnBack?: string;
    continueBtnText?: string;
    continueBtnColor?: string;
    linkContinue: string;
}

const RegisterModal: React.FC<RegisterModalProps> = ({open, handleClose, title, subtitle, description,secondSubtitle,secondDescription,thirdSubtitle,thirdDescription, backBtnText, colorBtnBack, continueBtnText, continueBtnColor, linkContinue})=>{


    return(
      <>
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className="register-dialog-container"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {title}
          </DialogTitle>
          <DialogContent className="register-dialog-content">
            <Typography variant="h5" className="register-dialog-desc bold-serif">
              {subtitle}
            </Typography>
            <Typography variant="h5" className="text-content-dialog regular-serif">
              {description}
            </Typography>
            <br/>
            <Typography variant="h5" className="register-dialog-desc bold-serif">
              {secondSubtitle} <span className="regular-serif">{secondDescription}</span>
            </Typography>
            <br/>
            <Typography variant="h5" className="register-dialog-desc bold-serif">
              {thirdSubtitle} <span className="regular-serif">{thirdDescription}</span>
            </Typography>
          </DialogContent>
          <DialogActions className="register-dialog-actions">
            <Button className="back-button" variant="contained" size="small" startIcon={<ArrowBackIosIcon />} onClick={handleClose}>
              <Typography variant="h6" className="regular-serif">
                {backBtnText}
              </Typography>
            </Button>
            <Button className="continue-button" variant="contained"  size="small" component ={ Link } to={linkContinue}>
              <Typography variant="h6" className="regular-serif">
                {continueBtnText}
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
}

export default RegisterModal;