export class IAcademicInfoCV{
    doc: string;
    educationUnal: IEducations;
    otherStudies: Array<IOherStudies>;
    scholarships: Array<IScholarship>;
    languages: Array<ILanguage>;
    constructor(def:any){
        this.doc = def.doc || "";
        this.educationUnal = def.educationUnal || new IEducations();
        this.otherStudies = def.otherStudies || [];
        this.scholarships = def.scholarships || [];
        this.languages = def.languages || [];
    }
}

export class IEducations{
    educations:Array<IEducationInfo> = [];
    hotbeds: Array<IHotbeds> = [];
}

export class IEducationInfo{
    id?: number | null = null;
    campus: number = 0;
    campusName?: string = "";
    school: number = 0;
    schoolName?: string = "";
    curriculum: number = 0;
    curriculumName?: string = "";
    academicLevel: number | null = null;
    academicLevelName?: string = "";
    title?: string = "";
    titleName?: string = "";
    dateInitFormat?:  Date | null = null;
    dateInit: string | null = "";
    dateFormat?: Date | null = null;
    date: string | null = "";
    diplomaNumber?: string ="";
    visible?: boolean = false;
    professionalCardNumber?: string = "";
    country: number = 0;
    countryName?: string = "";
    department: number = 0;
    departmentName?: string = "";
    city: number = 0;
    cityName?: string = "";
    degreeWorkTitle?: string = "";
    degreeWorkDescription?: string = "";
    addByUser?: boolean = false;
    graduateId?: string ="";
    educationState: number = 0;
    typeStudy: number = 0;
    knowArea: number = 0;
    KnowAreaName?: string = "";
    profession?: string = "";
}

export class IHotbeds{
    id?:number | null = null;
    name?: string = "";
    description: string = "";
}

export class IOherStudies{
    id?:number | null = null;
    institution: string = "";
    faculty: string = "";
    program: string = "";
    title: string = "";
    dateInitFormat?: Date | null = null;
    dateInit: string = "";
    dateEndFormat?: Date | null = null;
    dateEnd: string = "";
    educationState: number = 0;
    typeStudy: number = 0;
    academicLevel: number = 0;
    hoursDuration?: number | null = null;
    diplomaNumber: string = "";
    professionalCardNumber: string = "";
    knowArea: number = 0;
    country: number = 0;
    departament: number = 0;
    city: number = 0;
    degreeWorkTitle?: string = "";
    degreeWorkDescription?: string = "";
    graduateId?: string ="";
}

export class IScholarship{
    id?:number | null = null;
    name: string = "";
    entity: string = "";
    country: number = 0;
    city: number = 0;
    startDateFormat?: Date | null = null;
    startDate: string = "";
    endDateFormat?: Date | null = null;
    endDate: string = "";
    visible: boolean = false;
    graduateId?: string ="";
}

export class ILanguage{
    id?:number | null = null;
    language: number = 0;
    level: number = 0;
    native: boolean = false;
    graduateId?: string ="";
}
