import React, { useEffect, useState } from "react";
import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DeleteIcon from '@material-ui/icons/Delete';
import PolicyModal from '../shareStepper/PolicyModal';
import RegisterContext from "../../../utils/RegisterContext";
import { IRegisterGraduate } from '../../../interfaces/IRegisterGraduate';
import { IFile } from '../../../interfaces/IFile';
import '../Stepper.scss';
import JobBankModal from "../shareStepper/JobBankModal";

const SecondStep: React.FC = () => {
  const registerContext = React.useContext(RegisterContext);
  const formValues: IRegisterGraduate = registerContext.formValues;
  const formErrors: IRegisterGraduate = registerContext.formErrors;
  const [disabledUpload, setDisableUpload]= useState(false);
  const [descResult,setDescResult] = useState(false);

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);

  useEffect(()=>{
    if(formValues.files.length === 3){
      setDisableUpload(true)
    } else {
      setDisableUpload(false)
    }
    if(formValues.files.length >= 1){
      registerContext.setStep2Completed(true);
    } else {
      registerContext.setStep2Completed(false);
    }
    if(formValues.description_document.length>0){
      setDescResult(true)
    } else{
      setDescResult(false)
    }
  },[formValues.files.length, registerContext, formValues.description_document])

  return (
    <>
      <Grid container className="second-step-container">
        <Grid container item xs={12} className="second-step-summary">
          <Typography variant="h2" className="bold-serif-primaryColor">
            2. Adjuntar documentos (opcional)
          </Typography>
          <Typography variant="h6" className="summary-description-documents">
            Por favor adjunte los siguientes documentos:
          </Typography>
          <Typography variant="h6" className="summary-description-documents">
            1. Copia del diploma o acta de grado en formato PDF
          </Typography>
          <Typography variant="h6" className="summary-description-documents">
            2. Copia de la cédula de ciudadanía
          </Typography>
        </Grid>

        <Grid container item xs={12} className="second-step-content">
          <Typography variant="h4" className="summary-title-documents">
            Documentos adjuntos
          </Typography>
          <Typography variant="h5" className="summary-description-documents">Por favor ingrese en la descripción del documento el título del documento como aparece en la parte superior Ejemplo: (Copia del diploma o acta de grado en formato PDF).</Typography>
          <Typography variant="h5" className="summary-description-documents">A continuación oprima el botón "ADJUNTAR ARCHIVO" para seleccionar el documento que desea adjuntar. El documento debe estar en formato PDF y tener un peso máximo de 1 mega.</Typography>
          <Typography variant="h6" className="Regular-serif primaryError">{formErrors.fileError}</Typography>
          <Grid container item xs={12} justify="center">
            {formValues.files.map((file:IFile, index)=>(
              <Grid container item xs={11} md={3} key={index} className="card-container">
                <div className="card-header-container">
                  <Typography variant="h5" className="regular-serif card-title-item">
                    {file.file?.name}
                  </Typography>
                  <IconButton style={{padding:0, height: '30px'}} onClick={()=>registerContext.handleDeleteFile(file)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
                <Grid container item xs={12} className="card-desc-container">
                  <Typography variant="h6" className="regular-serif card-desc-item">
                    {file.description !== '' ? file.description : 'sin descripción'}
                  </Typography>
                </Grid>
                <Grid container item xs={12} justify="flex-end" className="card-action">
                  <div className="card-action-btn">
                    <Link target="_blank" rel="noopener" href={file.previewUrl}>
                      <Typography align="center" variant="h6" className="regular-serif">
                        Ver documento
                      </Typography>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid container item xs={12} justify="flex-start">
            <TextField
              fullWidth
              label="Descripción del documento"
              name="description_document"
              placeholder="Descripción del documento"
              multiline
              rows={3}
              variant="outlined"
              value={formValues.description_document || ""}
              onChange={registerContext.handleChange}
              error={!!formErrors.description_document}
              helperText={formErrors.description_document}
              margin="normal"
              required
              disabled={disabledUpload}
            />
            <div className="upload-button-container">
              <Button variant="contained" component="label" className="upload-button" disabled={disabledUpload || !!formErrors.description_document || !descResult}>
                <Typography align="center" variant="h6" className="regular-serif">
                  ADJUNTAR ARCHIVO
                </Typography>
                <div>
                  <input type="file" name="files" onChange={registerContext.handleChange} accept="application/pdf" hidden/>
                </div>
              </Button>
            </div>
          </Grid>
        </Grid>

      </Grid>
      <div className="step-buttons">
        <div className="step-container-btn">
          <Button variant="contained" className="step-back" startIcon={<NavigateBeforeIcon />} onClick={registerContext.handleBack}>
            <Typography align="center" variant="h6" className="regular-serif">
              ATRAS
            </Typography>
          </Button>
        </div>
        <div className="step-container-btn">
          <Button variant="contained" endIcon={<NavigateNextIcon/>} className="step-btn" onClick={formValues.termsOfUse ? registerContext.handleNext : registerContext.handleMOpenModal}>
            <Typography align="center" variant="h6" className="regular-serif">
              SIGUIENTE
            </Typography>
          </Button>
        </div>
      </div>
      <PolicyModal role="graduate"/>
      <JobBankModal role="graduate" />
    </>
  )
}

export default SecondStep
