import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import '../GraduateCurriculum.scss';

interface GraduateCurriculumStateProps{
    selected: string;
    handleClick: any;
}

const GraduateStateFilters: React.FC <GraduateCurriculumStateProps> = ({selected, handleClick})=> {
    const buttons = [
        {id: 1, name: 'Datos personales', state: 'Datos personales'},
        {id: 2, name: 'Información académica', state: 'Información académica'},
        {id: 3, name: 'Información laboral', state: 'Información laboral'},
        {id: 4, name: 'Información adicional', state: 'Información adicional'},
        {id: 5, name: 'Documentos adjuntos', state: 'Documentos adjuntos'}

    ]
    return (
        <Grid item xs={12} className="state-filters-curriculum-container">
            <Grid container item xs={12} className="container-curriculum-buttons">
                {
                    buttons.map(item=>(
                        <Button key={item.id} className={selected === item.state ? 'button-curriculum-definition-active' : 'button-curriculum-definition' } onClick={(e)=>handleClick(e, item.state)}>
                            <Typography variant="h5" className="regular-serif">
                                {item.name}
                            </Typography>
                        </Button>
                    ))
                }
            </Grid>
        </Grid>
    )
}

export default GraduateStateFilters
