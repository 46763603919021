import React from 'react';
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import { IUserByCompany } from '../../interfaces/IUserByCompany';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import UserByCompanyForm from '../UserByCompanyForm';
import './CompanyProfiles.scss';

interface CompanyProfilesViewProps{
  activeUsers: Array<IUserByCompany>;
}

const CompanyProfilesView:React.FC<CompanyProfilesViewProps> = ({activeUsers}) => {

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const profile1: IUserByCompany = activeUsers[0]
  const profile2: IUserByCompany = activeUsers[1];
  const profile3: IUserByCompany = activeUsers[2];

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="profile-info-container">
    {
      activeUsers.length >= 1 &&
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="profile-accordion-container">
        <AccordionSummary
          expandIcon={!expanded? <AddIcon />: <RemoveIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="accordion-summary"
        >
          <PersonOutlineIcon className="personIcon"/>
          <Typography variant="h2" className="bold-serif">Perfil 1 - <span>{profile1['name']}</span> <span className="rol">(rol del perfil)</span></Typography>
        </AccordionSummary>
        <AccordionDetails className="profile-accordion-details">
          <UserByCompanyForm profile={profile1}/>
        </AccordionDetails>
      </Accordion>
    }

    {
      activeUsers.length >= 2 &&
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="profile-accordion-container">
        <AccordionSummary
          expandIcon={!expanded? <AddIcon />: <RemoveIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          className="accordion-summary"
        >
          <PersonOutlineIcon className="personIcon"/>
          <Typography variant="h2" className="bold-serif">Perfil 2 - <span>{profile2['name']}</span> <span className="rol">(rol del perfil)</span></Typography>
        </AccordionSummary>
        <AccordionDetails className="profile-accordion-details">
          <UserByCompanyForm profile={profile2}/>
        </AccordionDetails>
      </Accordion>
    }
    {
      activeUsers.length >= 3 &&
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="profile-accordion-container">
        <AccordionSummary
          expandIcon={!expanded? <AddIcon />: <RemoveIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          className="accordion-summary"
        >
          <PersonOutlineIcon className="personIcon"/>
          <Typography variant="h2" className="bold-serif">Perfil 3 - <span>{profile3['name']}</span> <span className="rol">(rol del perfil)</span></Typography>
        </AccordionSummary>
        <AccordionDetails className="profile-accordion-details">
          <UserByCompanyForm profile={profile3}/>
        </AccordionDetails>
      </Accordion>
    }
    </div>
  )
}

export default CompanyProfilesView
