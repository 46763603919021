import React, { useState, useEffect }  from 'react';
import Grid from '@material-ui/core/Grid';
import UserContext from '../../../../utils/UserContext';
import Attacments from '../../../../components/GraduateCurriculum/Attachments';
import AdditionalInformation from '../../../../components/GraduateCurriculum/AdditionalInformation';
import LaboralInformation from '../../../../components/GraduateCurriculum/LaboralInformation';
import AcademicInformation from '../../../../components/GraduateCurriculum/AcademicInformation';
import GraduateStateFilters from '../../../../components/GraduateCurriculum/GraduateStateFilters';
import AlertBox from '../../../../components/ShareComponents/AlertBox';
import PersonalInformation from '../../../../components/GraduateCurriculum/PersonalInformation';
import '../graduate.scss'


const GraduateCurriculumView: React.FC = () => {
    const userContext = React.useContext(UserContext);
    const [selectedSection, setSelectedSection] = useState<string>('');
    
    useEffect(()=>{
        if(userContext.stateCurriculumSection){
            setSelectedSection(userContext.stateCurriculumSection)
        } else {
            setSelectedSection('Datos personales')
        }
    },[userContext.stateCurriculumSection])


    const handleClick = async(e:any, state:string)=>{
        setSelectedSection(state);
        userContext.setStateCurriculumSection(state);
    }

    return (
    <>
         <Grid item xs={12} className="state-offers-section">
            <GraduateStateFilters selected={selectedSection} handleClick={handleClick}/>
            
        </Grid>
        <Grid container className="main-container">
                
                <Grid container item xs={12}>
                    <AlertBox
                        title="Información"
                        alert="info"
                        variantType="outlined"
                    >
                        {
                            <span className="alert-simple-text">
                                En esta sección usted puede ver y actualizar su curriculum, también puede adjuntar su hoja de vida.
                            </span>
                        }
                    </AlertBox>
                </Grid>
                
                
            
            {
                userContext.stateCurriculumSection=== "Datos personales" &&
                <>
                    <Grid container item xs={12}>
                        <PersonalInformation/> 
                    </Grid>
                </>
            }
            {
                userContext.stateCurriculumSection === "Información académica" &&
                <>
                    <Grid container item xs={12}>
                        <AcademicInformation />
                    </Grid>
                </>
            }
            {
                userContext.stateCurriculumSection === "Información laboral" &&
                <>
                    <Grid container item xs={12}>
                        <LaboralInformation />
                    </Grid>
                </>
            }
            {
                userContext.stateCurriculumSection === "Información adicional" &&
                <>
                    <Grid container item xs={12}>
                        <AdditionalInformation />
                    </Grid>
                </>
            }
            {
                userContext.stateCurriculumSection === "Documentos adjuntos" &&
                <>
                    <Grid container item xs={12}>
                        <Attacments />
                    </Grid>
                </>
            }

        </Grid>
    </>
    )
}

export default GraduateCurriculumView
