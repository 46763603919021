const baseUrl = process.env.REACT_APP_SIE_APP_PROXY_BASE_URL;
const TOKEN_KEY = "Bearer";

export const createPostulation = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
        token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/postulation/create`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}
export const statePostulationService = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/postulation/state`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const listPostulations = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/postulation/list`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}