export class IRegisterSurvey{
  id: number;
  state: string;
  name: string;
  description: string;
  url: string;
  user: string;
  constructor(def:any){
    this.id = def.id || null;
    this.state = def.state || "";
    this.name = def.name || "";
    this.description = def.description || "";
    this.url = def.url || "";
    this.user = def.user || "";
  }
}