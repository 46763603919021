import React from 'react';
import BounceLoader from "react-spinners/BounceLoader";
import Typography from '@material-ui/core/Typography';
import './LoadingLockPage.scss';

interface LoadingLockPageProps{
    load:boolean;
    area?:any;
}

const LoadingLockPage: React.FC<LoadingLockPageProps> = ({load, area}) => {

    return (
        <div className="darkBackground" style={{display: !load ? 'none' : 'block'}}>
            <div className="loading-lock-container">
                <BounceLoader color="#00B5C0" size={150}/>
                <Typography variant="h1" className="loading-lock-text">Por favor espere un momento...</Typography>
            </div>
        </div>
    )
};

export default LoadingLockPage;