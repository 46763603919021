import React, { useState, useEffect } from 'react';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import BeatLoader from 'react-spinners/BeatLoader';
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Validations from '../../helpers/Validations';
import { IRegisterCompanyProfileData } from '../../interfaces/IRegisterCompanyProfileData';
import LoadingArea from '../ShareComponents/LoadingArea';
import SuccessModal from '../ShareComponents/SuccessModal';
import ErrorModal from '../ShareComponents/ErrorModal';
import LoadingLockPage from '../ShareComponents/LoadingLockPage';
import { RegisterCompanyProfiles } from '../../services/CompanyService';
import UserContext from '../../utils/UserContext';

import './ProfileModal.scss'




const formValidations = {
  name: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: true
  },
  email: {
    error: "",
    validate: "email",
    required: true
  },
  phoneContact: {
    error: "",
    validate: "textNumber",
    minLength: 7,
    maxLength: 30,
    required: true
  },
}

const styles = (theme: Theme) =>
createStyles({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#004B73',
  },
})

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="profile-register-dialog-header" {...other}>
      <Typography variant="h2" className="bold-serif-primaryColor" style={{marginRight:'16px'}}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface ProfileModalProps{
    open: any;
    handleClose: any;
    title: string;
    backBtnText?: string;
    colorBtnBack?: string;
    continueBtnText?: string;
    continueBtnColor?: string;
}

const ProfileModal: React.FC<ProfileModalProps> = ({open, handleClose, title, backBtnText, colorBtnBack, continueBtnText, continueBtnColor, })=>{
  const userContext = React.useContext(UserContext);
  const { promiseInProgress } = usePromiseTracker();
  const [formValues, setFormValues] = useState(new IRegisterCompanyProfileData());
  const [formErrors, setFormErrors] = useState(new IRegisterCompanyProfileData());
  const [serviceSuccess, setServiceSuccess]= useState('');
  const [openModalSuccess, setOpenModalSuccess] = React.useState(false);
  const [serviceError, setServiceError]= useState('');
  const [openModalError, setOpenModalError] = React.useState(false);
  const [disabledSend, setDisableSend]= useState(false);

  const isValid =
    formValues.name.length > 0 &&
    !formErrors.name &&
    formValues.phoneContact.length > 0 &&
    !formErrors.phoneContact &&
    formValues.email.length > 0 &&
    !formErrors.email

  useEffect(()=>{
    if(isValid){
        setDisableSend(true)
    } else {
        setDisableSend(false)
    }
  },[isValid]);

  useEffect(()=>{
    if(userContext.userId){
      const nit = parseInt(userContext.userId, 10);
      setFormValues(prev => ({
        ...prev,
        nit: nit
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleSend = async()=>{
    const responseRCU:Response = await trackPromise(RegisterCompanyProfiles(
      {...formValues, modifyUser: userContext.userId}
    ));
    if(responseRCU.status === 200 ){
      const result = await responseRCU.json();
      setServiceSuccess(result.Observation);
      setOpenModalSuccess(true);
    } else if (responseRCU.status === 400){
      const result = await responseRCU.json();
      setServiceError(result.Observation);
      setOpenModalError(true);
    }else{
      setOpenModalSuccess(false)
      setServiceError('Hubo un error, intente de nuevo mas tarde');
      setOpenModalError(true);
    }
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    // Set values
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
    // set errors
    const error = Validations(name, value, formValidations) || "";
      setFormErrors(prev=>({
        ...prev,
        [name]: error
    }));
  }

  const handleCloseModalSuccess = ()=>{
    setOpenModalSuccess(false);
    handleClose();
    window.location.reload();
  };

  const handleCloseModalError = ()=>{
    setOpenModalError(false);
  };

  return(
    <>
      <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="profile-register-dialog-container"
          fullWidth
          maxWidth = "lg"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent className="profile-register-dialog-content">
          <Grid container item xs={12} direction="row">
            <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
              <Typography className="bold-serif-primaryColor">Nombre del perfil*</Typography>
              <TextField
                fullWidth
                name="name"
                margin="none"
                label={formValues.name === "" ? "Ingrese un nombre para el perfil": ""}
                value={formValues.name || ""}
                onChange={handleChange}
                error={!!formErrors.name}
                helperText={formErrors.name}
                InputLabelProps={{shrink: false}}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
              <Typography className="bold-serif-primaryColor">Correo electrónico*</Typography>
              <TextField
                fullWidth
                name="email"
                margin="none"
                label={formValues.email === "" ? "Ingrese un correo electrónico": ""}
                value={formValues.email || ""}
                onChange={handleChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
                InputLabelProps={{shrink: false}}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} direction="row">
            <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
              <Typography className="bold-serif-primaryColor">Número telefónico*</Typography>
              <TextField
                fullWidth
                name="phoneContact"
                margin="none"
                label={formValues.phoneContact === "" ? "Ingrese su número telefónico": ""}
                value={formValues.phoneContact || ""}
                onChange={handleChange}
                error={!!formErrors.phoneContact}
                helperText={formErrors.phoneContact}
                InputLabelProps={{shrink: false}}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
            </Grid>
            <Grid item xs={12}>
              <div style={{width:'100%'}}>
                <LoadingArea load={promiseInProgress} loadElement={<BeatLoader size={20} margin={2} color="#14A5DC"/>} awaitingText="Espere un momento por favor"/>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="profile-register-dialog-actions">
          <Button className="cancel-button" disabled={promiseInProgress} variant="contained"  size="small" onClick={handleClose}>
            <Typography variant="h5" className="bold-serif">
              {backBtnText}
            </Typography>
          </Button>
          <Button disabled={!disabledSend || promiseInProgress} className="add-button" variant="contained"  size="small" onClick={handleSend}>
            <Typography variant="h5" className="bold-serif">
              {continueBtnText}
            </Typography>
          </Button>
          <SuccessModal successMsg={serviceSuccess} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
          <ErrorModal errorMsg={serviceError} handleCloseModal={handleCloseModalError} openModalError={openModalError}/>
        </DialogActions>
      </Dialog>
      <LoadingLockPage load={promiseInProgress}/>
    </>
  )
}

export default ProfileModal;