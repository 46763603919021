import React from 'react';
import Typography from '@material-ui/core/Typography';
import EditButton from '../../ShareComponents/EditButton';
import IconButton from '@material-ui/core/IconButton';
import RegisterContext from '../../../utils/RegisterContext';
import '../Stepper.scss';

interface AccordionHeaderProps {
    title: string;
    description: string;
    accordionBox?: boolean;
    panel?: string;
    colorItem: string;
}

const AccordionHeader: React.FC<AccordionHeaderProps> = ({title, description, accordionBox, panel, colorItem})=>{
    const registerContext = React.useContext(RegisterContext);
    return(
        <div className="summary-content">
            <div className="summary-text">
                <Typography variant="h2" className="bold-serif-primaryColor">{title}</Typography>
                <Typography variant="h5" className="summary-description">{description}</Typography>
            </div>
            { accordionBox && registerContext.expanded !== panel ? <IconButton disableRipple className="accordion-icon" style={{color: colorItem}}><EditButton colorInside={colorItem}/></IconButton>: null}
        </div>
    )
}

export default AccordionHeader