export class FiltersRoles{
    username: string = "";
    stateUser: string = "";
    roleUser: number = 0;
}

export class FiltersRolesLimit{
    username?: string = "";
    status?: string = "";
    roleId?: Array<number> = [];
    limit: number = 0;
    offset: number = 0;
}