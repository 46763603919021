import React, { useState, useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Link from '@material-ui/core/Link';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AlertBox from '../ShareComponents/AlertBox';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IProfileCompany, leaveReasons } from '../../interfaces/IProfileCompany';
import Validations from '../../helpers/Validations';
import { IRegisterCompanyData } from '../../interfaces/IRegisterCompanyData';
import { IGeneralParameter } from '../../interfaces/IGeneralParameter';
import { getLABORAL_SECTOR, getCOMPANY_TYPE, getCOUNTRIES_CITIES, getTYPE_PERSON } from '../../services/ParameterService';
import { getDEPARTAMENT } from '../../services/ParameterService';
import { containsAny, sortByKey } from '../../helpers/sharedFunctions';
import { getCompanyDocuments, UpdateCompanyInfo } from '../../services/CompanyService';
import SuccessModal from '../ShareComponents/SuccessModal';
import ErrorModal from '../ShareComponents/ErrorModal';
import LoadingLockPage from '../ShareComponents/LoadingLockPage';
import { IFile } from '../../interfaces/IFile';
import { deleteFileS3, getSignUrl, uploadFileSignedUrl } from '../../services/s3Service';
import { IGetDocuments } from '../../interfaces/IGetDocuments';
import { IPresignedUrl, IPreSignedUrlReq } from '../../interfaces/IPreSignedUrl';
import { IAttachmentDocs } from '../../interfaces/IAttachmentDocs';
import UserContext from '../../utils/UserContext';
import { permissionAdminModifyCompanies, permissionCompanyModifyProfile } from '../../utils/PermissionsVariables';

import './EditProfileCompany.scss';
import { IListParametersInfo } from '../../interfaces/IListParametersInfo';
import { CompanyStateObject } from '../../interfaces/CompanyStateObject';
import { ICompanyTable } from '../../interfaces/ICompanyTable';
import SelectActionsBox2 from '../ManagementState/SelectActionsBox2';
import { CompanyStateTosendObj } from '../../pages/dashboard/admin/CompanyManagement';
import { Redirect, useParams } from 'react-router';
import { changeStateCompany } from '../../services/ChangeStateService';
import ModalOffersAction from '../LaboralOffers/ModalOffersAction';
import ModalConfirmedAction from '../ShareComponents/ModalConfirmedAction';
import { IUnenrollOptions } from '../../interfaces/IUnenrollOptions';
import UnenrollModal from '../ShareComponents/UnenrollModal';


const initialFormErrors = {
  name: "",
  nit: "",
  verificationDigit: "",
  typeOfCompany: "",
  typeOfPerson: "",
  laboralSector: "",
  country: "",
  department: "",
  city: "",
  address: "",
  cellphoneCompany: "",
  phone: "",
  website: "",
  contactName: "",
  position: "",
  email: "",
  cellphoneContact: "",
  phoneContact: "",
  infoContactName: "",
  infoContactPhone: "",
  infoContactCellphone: "",
  infoContactMail: "",
  repLegalCountry: "",
  repLegalDepartment: "",
  repLegalCity: ""
}

class InitialDocumentsErrors {
  fileError: string = "";
  descriptionDocument: string = "";
}

const formDocsValidations = {
  descriptionDocument: {
    error: "",
    validate: "textNumber",
    minLength: 10,
    maxLength: 100,
    required: true
  },
}

const formValidations = {
  name: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: true
  },

  nit: {
    error: "",
    validate: "number",
    minLength: 7,
    maxLength: 15,
    required: true
  },

  verificationDigit: {
    error: "",
    validate: "number",
    minLength: 1,
    maxLength: 1,
    required: true
  },

  typeOfCompany: {
    error: "",
    validate: "select",
    required: true
  },
  typeOfPerson: {
    error: "",
    validate: "select",
    required: true
  },

  laboralSector: {
    validate: "select",
    required: true
  },

  country: {
    validate: "select",
    required: true
  },

  department: {
    validate: "select",
    required: true
  },

  city: {
    validate: "select",
    required: true
  },

  address: {
    error: "",
    validate: "textNumber",
    minLength: 5,
    maxLength: 40,
    required: true
  },

  cellphoneCompany: {
    error: "",
    validate: "number",
    minLength: 10,
    maxLength: 12,
    required: false
  },

  phone: {
    error: "",
    validate: "textNumber",
    minLength: 7,
    maxLength: 30,
    required: false
  },

  website: {
    error: "",
    validate: 'website',
    maxLength: 30,
    required: false
  },

  contactName: {
    error: "",
    validate: "text",
    minLength: 2,
    required: true
  },

  position: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: true
  },

  email: {
    error: "",
    validate: "email",
    required: true
  },

  cellphoneContact: {
    error: "",
    validate: "number",
    minLength: 10,
    maxLength: 15,
    required: false
  },

  phoneContact: {
    error: "",
    validate: "textNumber",
    minLength: 7,
    maxLength: 30,
    required: false
  },

  repLegalCountry: {
    validate: "select",
    required: true
  },

  repLegalDepartment: {
    validate: "select",
    required: false
  },

  repLegalCity: {
    validate: "select",
    required: false
  },

  infoContactCellphone: {
    error: "",
    validate: "number",
    minLength: 10,
    maxLength: 12,
    required: false
  },

  infoContactPhone: {
    error: "",
    validate: "textNumber",
    minLength: 7,
    maxLength: 30,
    required: false
  },
  infoContactMail: {
    error: "",
    validate: "email",
    required: false
  },
  infoContactName: {
    error: "",
    validate: "text",
    minLength: 2,
    required: false
  }
}

const observationValidations = {
  observation: {
    validate: "select",
    required: false
  },
  otherObservation: {
    error: "",
    validate: "textNumber",
    minLength: 20,
    maxLength: 200,
    required: true
  },
}


const unenrollList:Array<IUnenrollOptions> = [
  { id: 1, label: 'La plataforma no es funcional', value: 1},
  { id: 2, label: 'No se encuentra los perfiles requeridos', value: 2},
  { id: 3, label: 'Las ofertas publicadas no han tenido postulaciones', value: 3},
  { id: 4, label: 'Solo requerimos practicantes', value: 4},
  { id: 5, label: 'La empresa cerró o cambió de razón social', value: 5},
  { id: 6, label: 'No hacemos convocatoria de ofertas laborales', value: 6},
  { id: 7, label: 'Otra causa', value: 7}
];
interface EditProfileCompanyProps {
  companyData: IProfileCompany;
  cityArray: Array<IGeneralParameter>;
}

const EditProfileCompany: React.FC<EditProfileCompanyProps> = ({ companyData, cityArray }) => {
  const { promiseInProgress } = usePromiseTracker();
  const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer') + "" : "";
  const userContext = React.useContext(UserContext);
  let params: any = useParams();
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<IProfileCompany>(new IProfileCompany());
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [formValuesDocs, setFormValuesDocs] = useState<IAttachmentDocs>(new IAttachmentDocs({}));
  const [formErrorsDocs, setFormErrorsDocs] = useState<InitialDocumentsErrors>(new InitialDocumentsErrors());
  const [disabledSend, setDisableSend] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openModalError, setOpenModalError] = React.useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [descResult, setDescResult] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = React.useState(false);
  const [openUnenrollModal,setOpenUnenrollModal ] = React.useState(false);
  const [myObjReasons, setMyObjReasons] = useState<leaveReasons>(new leaveReasons())
  //Initial data
  const [allCompanyType, setAllCompanyType] = useState<Array<IGeneralParameter>>([]);
  const [allLaboralSector, setAllLaboralSector] = useState<Array<IGeneralParameter>>([]);
  const [countryCitiesArray, setCountryCitiesArray] = useState<Array<IListParametersInfo>>([]);
  const [departmentArray, setDepartmentArray] = useState<Array<IGeneralParameter>>([]);
  const [cities, setCities] = useState<Array<IGeneralParameter>>([]);
  const [repreCities, setRepreCities] = useState<Array<IGeneralParameter>>([]);
  const [roleSelected, setRoleSelected] = useState<boolean>(false);
  const [actionSelected, setActionSelected] = useState(0);
  const [observationSelected, setObservationSelected] = useState(0);
  const [stateSelected, setStateSelected] = useState<string>('');
  const [otherObservation, setOtherObservation] = useState<string>('');
  const [companiesStateToSend, setCompaniesStateToSend] = useState(new CompanyStateTosendObj());
  const [stateChanged, setStateChanged] = useState<boolean>(false);
  const [serviceError, setServiceError] = useState('');
  const [opeModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [urlComp, setUrlComp] = useState<string | null>(null);
  const [typePersonArr, setTypePersonArr] = useState<Array<IGeneralParameter>>([]);

  const isValid =
    formValues.nit !== null && formValues.nit > 0 &&
    !formErrors.nit &&
    formValues.verificationDigit !== null && formValues.verificationDigit > 0 &&
    !formErrors.verificationDigit &&
    formValues.typeOfCompany > 0 &&
    !formErrors.typeOfCompany &&
    formValues.laboralSector !== null && formValues.laboralSector > 0 &&
    !formErrors.laboralSector &&
    formValues.country > 0 &&
    !formErrors.country &&
    formValues.department !== null && formValues.department > 0 &&
    !formErrors.department &&
    formValues.city > 0 &&
    !formErrors.city &&
    formValues.address.length > 0 &&
    !formErrors.address &&
    !formErrors.cellphoneCompany &&
    !formErrors.phone &&
    !formErrors.website &&
    formValues.contactName.length > 0 &&
    !formErrors.contactName &&
    formValues.position.length > 0 &&
    !formErrors.position &&
    formValues.email.length > 0 &&
    !formErrors.email &&
    !formErrors.cellphoneContact &&
    !formErrors.phoneContact


  useEffect(() => {
    if (formValuesDocs.descriptionDocument.length > 0) {
      setDescResult(true)
    } else {
      setDescResult(false)
    }
  }, [formValuesDocs.descriptionDocument.length])

  useEffect(() => {
    window.scrollTo(0, 0);
    const getInitialData = async () => {
      if (companyData) {
        for (let [key, value] of Object.entries(companyData)) {
          setFormValues(prev => ({
            ...prev,
            [key]: value
          }));
        }
      }
      //  company type
      let responseCompanyType: Array<IGeneralParameter> = await getCOMPANY_TYPE();
      responseCompanyType = sortByKey(responseCompanyType, 'name');
      setAllCompanyType(responseCompanyType)
      // Type person
      const responsePersonType: Array<IGeneralParameter> = await getTYPE_PERSON();
      setTypePersonArr(responsePersonType);
      //  laboral sector
      let responseLaboralSector: Array<IGeneralParameter> = await getLABORAL_SECTOR();
      responseLaboralSector = sortByKey(responseLaboralSector, 'name');
      setAllLaboralSector(responseLaboralSector)
      // data countries-cities
      let dataCountryCities: Array<IListParametersInfo> = (await getCOUNTRIES_CITIES()).list;
      dataCountryCities = sortByKey(dataCountryCities, 'grandParentName');
      setCountryCitiesArray(dataCountryCities);
      //data for department field
      let dataDepartment: Array<IGeneralParameter> = await getDEPARTAMENT();
      dataDepartment = sortByKey(dataDepartment, 'name');
      setDepartmentArray(dataDepartment ? dataDepartment : []);
    }
    if (userContext.roleSelected === 4 || userContext.roleSelected === 5 || userContext.roleSelected === 6) {
      setRoleSelected(true)
    }
    getInitialData();
    const getDocuments = async () => {
      if (companyData.nit && companyData.nit > 0) {
        const responseDocuments = await getCompanyDocuments(companyData.nit);
        if (responseDocuments.status === 200) {
          const resultDocuments: Array<IGetDocuments> = await responseDocuments.json();
          let newDocs: Array<IFile> = [];
          for (let i in resultDocuments) {
            const url = resultDocuments[i].signedUrl;
            const descriptionFile = resultDocuments[i].metadata.description;
            const fileName = resultDocuments[i].key.split("/")[1];
            const folderFileKey = resultDocuments[i].key;
            await fetch(url)
              .then(async response => {
                const blob = await response.blob()
                const file = new File([blob], fileName, { type: "application/pdf" });
                const previewUrl = URL.createObjectURL(file)
                if (file) {
                  newDocs.push(
                    { description: descriptionFile, file: file, previewUrl: previewUrl, folderFileKey: folderFileKey }
                  )
                }
              })
          }
          if (newDocs.length > 0) {
            setFormValuesDocs(prev => ({
              ...prev,
              files: newDocs
            }));
          }
        }
      }
      setInitLoad(false)
    }
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData])

  useEffect(() => {
    if (!initLoad) {
      setFormValues(prev => ({
        ...prev,
        department: 0,
        city: 0
      }));
      setCities([]);
    }
    if (formValues.country === 0) {
      if (!initLoad) {
        setFormValues(prev => ({
          ...prev,
          department: 0,
          city: 0
        }));
        setCities([]);
      }
    } else {
      if (formValues.country === 170) {
        setCities([]);
      } else {
        const countryObjSelected = countryCitiesArray.find(country => country.id === formValues.country);
        if (countryObjSelected) {
          setCities(countryObjSelected.childs);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.country])

  useEffect(() => {
    setCities([]);
    if (!initLoad) {
      setFormValues(prev => ({
        ...prev,
        city: 0
      }));
    }
    if (formValues.department > 0) {
      
      let citiesDep: Array<IGeneralParameter> = cityArray.filter(city => city.parentId === formValues.department);
      
      citiesDep = sortByKey(citiesDep, 'name');
      if (citiesDep.length > 0) {
      
        setCities(citiesDep)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.department])

  useEffect(() => {
    if (!initLoad) {
      setFormValues(prev => ({
        ...prev,
        repLegalDepartment: 0,
        repLegalCity: 0
      }));
      setRepreCities([]);
    }
    if (formValues.repLegalCountry === 0) {
      if (!initLoad) {
        setFormValues(prev => ({
          ...prev,
          repLegalDepartment: 0,
          repLegalCity: 0
        }));
        setRepreCities([]);
      }
    } else {
      if (formValues.repLegalCountry === 170) {
        setRepreCities([]);
      } else {
        const countryObjSelected = countryCitiesArray.find(country => country.id === formValues.country);
        if (countryObjSelected) {
          setRepreCities(countryObjSelected.childs);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.repLegalCountry])

  useEffect(() => {
    setRepreCities([]);
    if (!initLoad) {
      setFormValues(prev => ({
        ...prev,
        city: 0
      }));
    }
    if (formValues.repLegalDepartment > 0) {
      let citiesDep: Array<IGeneralParameter> = cityArray.filter(city => city.parentId === formValues.repLegalDepartment);
      citiesDep = sortByKey(citiesDep, 'name');
      if (citiesDep.length > 0) {
        setRepreCities(citiesDep)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.repLegalDepartment])

  useEffect(() => {
    if (isValid) {
      setDisableSend(true)
    } else {
      setDisableSend(false)
    }
  }, [isValid])

  const handleSend = async () => {
    const objtoUpdate = formValues;
    if(formValues.sieRetirement){
      objtoUpdate.reasons = myObjReasons
    }
    const dataToSend: IRegisterCompanyData = new IRegisterCompanyData(objtoUpdate);
    const responseRC: Response = await trackPromise(UpdateCompanyInfo(userToken,
      { ...dataToSend, modifyUser: userContext.userId }
    ));
    if (responseRC.status === 200) {
      setSuccessMessage('El perfil ha sido actualizado correctamente')
      setOpenModalSuccess(true);
      setOpenModalError(false);
    } else {
      setErrorMessage("Hubo un error, intente de nuevo mas tarde");
      setOpenModalError(true);
      setOpenModalSuccess(false);
    }
  }

  const handleCloseModalSuccess = () => {
    setOpenModalSuccess(false);
    // window.location.reload();
  };

  const handleCloseModalError = () => {
    setOpenModalError(false);
    window.location.reload();
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
    const error = Validations(name, value, formValidations) || "";
    setFormErrors(prev => ({
      ...prev,
      [name]: error
    }));
  }

  const handleDeleteFile = async (fileToDelete: IFile) => {
    const name = fileToDelete.file?.name;
    const newFiles = formValuesDocs.files && formValuesDocs.files.filter((obj: IFile) => obj.file?.name !== name);
    if (fileToDelete.folderFileKey) {
      const responseDeleteFile = await trackPromise(deleteFileS3(fileToDelete.folderFileKey));
      if (responseDeleteFile.status === 200) {
        setFormValuesDocs(prev => ({
          ...prev,
          files: newFiles
        }));
        setSuccessMessage("Se ha eliminado correctamente el archivo");
        setOpenModalSuccess(true);
        setOpenModalError(false);
      } else {
        setErrorMessage("Hubo un error, intente de nuevo mas tarde");
        setOpenModalError(true);
        setOpenModalSuccess(false);
      }
    } else {
      setFormValuesDocs(prev => ({
        ...prev,
        files: newFiles
      }));
    }
  }

  const handleChangeform = (e: any) => {
    const { name, value } = e.target;
    setFormValuesDocs(prev => ({
      ...prev,
      [name]: value
    }));
    const error: string = Validations(name, value, formDocsValidations) || "";
    setFormErrorsDocs(prev => ({
      ...prev,
      [name]: error
    }));
  }

  const handleChangeDocuments = async (e: any) => {
    const { name } = e.target;
    if (name === 'files') {
      if (e.target.files.length > 0) {
        if (e.target.files[0].type === 'application/pdf') {
          if (e.target.files[0].size < (1000000)) {
            if (e.target.files[0]) {
              setFormValuesDocs(prev => ({
                ...prev,
                [name]: [...formValuesDocs.files, { file: e.target.files[0], description: formValuesDocs.descriptionDocument, previewUrl: URL.createObjectURL(e.target.files[0]) }],
              }));
              setFormErrorsDocs(prev => ({
                ...prev,
                fileError: ''
              }));
            }
            setFormValuesDocs(prev => ({
              ...prev,
              descriptionDocument: ''
            }));
          } else {
            setFormErrors(prev => ({
              ...prev,
              fileError: 'El archivo no debe superar 1MB'
            }));
          }
        } else {
          setFormErrorsDocs(prev => ({
            ...prev,
            fileError: 'Solo puede subir archivos que sean formato pdf'
          }));
        }
      }
    }
  }

  const saveFiles = async () => {
    setInitLoad(true);
    let totalUrl: Array<IPresignedUrl> = [];
    let uploadDone: Array<boolean> = [];
    if (formValuesDocs.files.length > 0) {
      for (let i = 0; i < formValuesDocs.files.length; i++) {
        const fileToUpload: IPreSignedUrlReq = {
          contentType: formValuesDocs.files[i].file.type,
          fileKey: formValuesDocs.files[i].file.name.replace(/[^a-zA-Z0-9_. ]/g, "").split(' ').join('_'),
          folderId: `Empresa-${formValues.nit}`,
          description: formValuesDocs.files[i].description
        }
        const result: IPresignedUrl = await getSignUrl(fileToUpload);
        result.name = fileToUpload.fileKey;
        if (result) {
          totalUrl.push(result);
        }
      }
    }
    if (totalUrl.length > 0) {
      for (let j = 0; j < totalUrl.length; j++) {
        const urlPresigned = totalUrl[j].uploadURL;
        const file = formValuesDocs.files[j].file;
        if (file) {
          const resultUpdload: Response = await trackPromise(uploadFileSignedUrl(urlPresigned, file));
          if (resultUpdload) {
            uploadDone.push(true);
          } else {
            uploadDone.push(false);
          }
        }
      }
    }
    setInitLoad(false);
    if (uploadDone.includes(false)) {
      setErrorMessage("Hubo un error, intente de nuevo mas tarde");
      setOpenModalError(true);
      setOpenModalSuccess(false);
    } else {
      setSuccessMessage("Se ha guardado correctamente la información")
      setOpenModalSuccess(true);
      setOpenModalError(false);
    }
  }

  const handleClose = () => {
    setUrlComp(`/admin/dashboard/empresas`);
    setStateChanged(false);
  };


  const handleChangeAction = (e: any) => {
    setActionSelected(e.target.value)
  }

  const changeObservation = (e: any) => {
    setObservationSelected(e.target.value)
  }

  const changeOtherObservation = (e: any) => {
    setOtherObservation(e.target.value)
  }

  const handleChangeStateSelected = (e: any) => {
    setStateSelected(e.target.value)
  }

  const changeCheckBoxSIERet = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: any = e.target.checked;
    setFormValues(prev => ({
        ...prev,
        sieRetirement: value
    }));
};

const handleOpenUnenrollModal = () =>{
  if(formValues.sieRetirement){
      setOpenUnenrollModal(true)
  }else{
      handleSend()
  }
}
const handleOnChangeUnenroll = (value:string, formField:string) =>{
  setMyObjReasons(prev =>({
      ...prev,
      [formField]: value
  }));
}

const handleCloseModalEnrrollModal = () =>{
  setOpenUnenrollModal(false);
  handleSend()
}
const handleCancelModalEnrrollModal = () =>{
  setOpenUnenrollModal(false);
}
  const sendActionSelected = () => {
    let companyStructure: Array<CompanyStateObject> = [];
    if (params.nit !== undefined) {
      companyStructure.push({ nit: params.nit.toString(), state: stateSelected, observation: observationSelected.toString(), otherObservation: otherObservation })
      setCompaniesStateToSend({ companies: companyStructure })
      setOpenModalConfirm(true);
    }
  }

  const closeModalConfirm = () => {
    setOpenModalConfirm(false);
  }

  const sendChangeState = async () => {
    setOpenModalConfirm(false);
    const responseCSCompany = await trackPromise(changeStateCompany(
      userToken,
      { ...companiesStateToSend, modifyUser: userContext.userId }
    ));
    if (responseCSCompany.status === 200) {
      const resultCSCompany = await responseCSCompany.json();
      if (companiesStateToSend.companies.length > 1) {
        const checkChanged = resultCSCompany.messages.filter((respUser: any) => {
          let validator: boolean = false;
          if (respUser.State === "Issue") {
            validator = true;
          } else {
            validator = false;
          }
          return validator && respUser
        });
        if (checkChanged.length > 0) {
          const usersError = checkChanged.map((user: any) => user.User);
          setStateChanged(false);
          setErrorMessage(`Ocurrio un error cambiando de estado a los siguientes usuarios: ${usersError.toString()}`);
          setOpenModalError(true);
        } else {
          setStateChanged(true);
          setErrorMessage('');
          setOpenModalError(false);
        }
      } else {
        if (resultCSCompany.messages[0].State === "Issue") {
          setStateChanged(false);
          setErrorMessage(`Ocurrio un error cambiando el estado del usuario: ${resultCSCompany.messages[0].User.toString()} ${resultCSCompany.messages[0].Observation}`);
          setOpenModalError(true);
        } else {
          setStateChanged(true);
          setErrorMessage('');
          setOpenModalError(false);
        }
      }
    } else {
      setStateChanged(false);
      setErrorMessage('Hubo un error, intente de nuevo mas tarde')
      setOpenModalError(true);
    }
  };

  return (
    <>
      {
        urlComp !== null ?
          <Redirect to={urlComp} />
          :
          <Grid container>
            <Grid container item xs={12} className="profile-company-container">
              <Typography variant="h3" className="bold-serif-primaryColor">
                INFORMACIÓN GENERAL DE LA EMPRESA
              </Typography>
              <Grid container item xs={12} direction="row">
                
                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Razón social</Typography>
                  <TextField
                    fullWidth
                    name="name"
                    margin="none"
                    value={formValues.name}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                    className="fixed-text"
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Tipo de empresa*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-typeOfCompany"
                    select
                    name="typeOfCompany"
                    label={formValues.typeOfCompany === 0 ? "Seleccione" : ""}
                    value={formValues.typeOfCompany === 0 ? "" : formValues.typeOfCompany}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    margin="none"
                    variant="outlined"
                  >
                    {
                      allCompanyType && allCompanyType.length > 0 ?
                        allCompanyType.map((option: IGeneralParameter) => (
                          <MenuItem key={option.parameter_code} value={option.parameter_code}>
                            {option.name}
                          </MenuItem>
                        ))
                        :
                        <MenuItem value={formValues.typeOfCompany}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">NIT</Typography>
                  <TextField
                    fullWidth
                    name="nit"
                    margin="none"
                    onChange={handleChange}
                    value={formValues.nit === null ? "" : formValues.nit}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                    className="fixed-text"
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Dígito de verificación NIT</Typography>
                  <TextField
                    fullWidth
                    name="verificationDigit"
                    margin="none"
                    onChange={handleChange}
                    value={formValues.verificationDigit === null ? "" : formValues.verificationDigit}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                    className="fixed-text"
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Tipo de Persona*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-typeOfPerson"
                    select
                    name="typeOfPerson"
                    label={formValues.typeOfPerson === 0 ? "Seleccione" : ""}
                    value={formValues.typeOfPerson === 0 ? "" : formValues.typeOfPerson}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    margin="none"
                    variant="outlined"
                  >
                    {
                      typePersonArr && typePersonArr.length > 0 ?
                        typePersonArr.map((option: IGeneralParameter) => (
                          <MenuItem key={option.parameter_code} value={option.parameter_code}>
                            {option.name}
                          </MenuItem>
                        ))
                        :
                        <MenuItem value={formValues.typeOfPerson}></MenuItem>
                    }
                  </TextField>
                </Grid>
                <Grid container item xs={12} className="line" />
                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Sector laboral*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-laboralSector"
                    select
                    name="laboralSector"
                    label={formValues.laboralSector === 0 ? "Seleccione" : ""}
                    value={formValues.laboralSector === 0 ? "" : formValues.laboralSector}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    margin="none"
                    variant="outlined"
                  >
                    {
                      allLaboralSector && allLaboralSector.length > 0 ?
                        allLaboralSector.map((option: IGeneralParameter) => (
                          <MenuItem key={option.parameter_code} value={option.parameter_code}>
                            {option.name}
                          </MenuItem>
                        ))
                        :
                        <MenuItem value={formValues.laboralSector}></MenuItem>
                    }
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">País*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-country"
                    select
                    name="country"
                    label={formValues.country === 0 ? "Seleccione" : ""}
                    value={formValues.country === 0 ? "" : formValues.country}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    margin="none"
                    variant="outlined"
                  >
                    {
                      countryCitiesArray && countryCitiesArray.length > 0 &&
                      <MenuItem value={0}><em>Ninguno</em></MenuItem>
                    }
                    {countryCitiesArray && countryCitiesArray.length > 0 ?
                      countryCitiesArray.map((option: IListParametersInfo) => (
                        <MenuItem disabled={option.id === 170 ? false : true} key={option.id} value={option.id}>
                          {option.grandParentName}
                        </MenuItem>
                      ))
                      :
                      <MenuItem value={formValues.country}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Departamento*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-department"
                    select
                    name="department"
                    label={formValues.department === 0 ? "Seleccione" : ""}
                    value={formValues.department === 0 ? "" : formValues.department}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    margin="none"
                    variant="outlined"
                    disabled={(formValues.country > 0 && departmentArray.length > 0 && formValues.country === 170 ? false : true)}
                  >
                    {
                      departmentArray && departmentArray.length > 0 ?
                        departmentArray.map((option: IGeneralParameter) => (
                          <MenuItem key={option.parameter_code} value={option.parameter_code}>
                            {option.name}
                          </MenuItem>
                        ))
                        :
                        <MenuItem value={formValues.department}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Municipio*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-city"
                    select
                    name="city"
                    label={formValues.city === 0 ? "Seleccione" : ""}
                    value={formValues.city === 0 ? "" : formValues.city}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    margin="none"
                    variant="outlined"
                    disabled={(cities && cities.length > 0 && ((formValues.country === 170 && formValues.department > 0) || (formValues.country !== 170 && formValues.country > 0)) ? false : true)}
                  >
                    {
                      cities && cities.length > 0 ?
                        cities.map((option: IGeneralParameter) => (
                          <MenuItem key={option.parameter_code} value={option.parameter_code}>
                            {option.name}
                          </MenuItem>
                        ))
                        :
                        <MenuItem value={formValues.city}></MenuItem>
                    }
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} >
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Dirección*</Typography>
                  <TextField
                    fullWidth
                    name="address"
                    margin="none"
                    value={formValues.address}
                    onChange={handleChange}
                    error={!!formErrors.address}
                    helperText={formErrors.address}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Número de Celular*</Typography>
                  <TextField
                    fullWidth
                    name="cellphoneCompany"
                    margin="none"
                    value={formValues.cellphoneCompany}
                    onChange={handleChange}
                    error={!!formErrors.cellphoneCompany}
                    helperText={formErrors.cellphoneCompany}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Número de teléfono</Typography>
                  <TextField
                    fullWidth
                    name="phone"
                    margin="none"
                    value={formValues.phone}
                    onChange={handleChange}
                    error={!!formErrors.phone}
                    helperText={formErrors.phone}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Dirección sitio web de la empresa</Typography>
                  <TextField
                    fullWidth
                    name="website"
                    margin="none"
                    value={formValues.website}
                    onChange={handleChange}
                    error={!!formErrors.website}
                    helperText={formErrors.website}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} className="profile-company-container">
              <Typography variant="h3" className="bold-serif-primaryColor">
                REPRESENTANTE LEGAL
              </Typography>
              <Grid container item xs={12} direction="row">
                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Nombre completo*</Typography>
                  <TextField
                    fullWidth
                    name="contactName"
                    margin="none"
                    value={formValues.contactName}
                    onChange={handleChange}
                    error={!!formErrors.contactName}
                    helperText={formErrors.contactName}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Cargo en la empresa*</Typography>
                  <TextField
                    fullWidth
                    name="position"
                    margin="none"
                    value={formValues.position}
                    onChange={handleChange}
                    error={!!formErrors.position}
                    helperText={formErrors.position}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Correo electrónico*</Typography>
                  <TextField
                    fullWidth
                    name="email"
                    margin="none"
                    value={formValues.email}
                    onChange={handleChange}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Número de Celular*</Typography>
                  <TextField
                    fullWidth
                    name="cellphoneContact"
                    margin="none"
                    value={formValues.cellphoneContact}
                    onChange={handleChange}
                    error={!!formErrors.cellphoneContact}
                    helperText={formErrors.cellphoneContact}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Número de teléfono</Typography>
                  <TextField
                    fullWidth
                    name="phoneContact"
                    margin="none"
                    value={formValues.phoneContact}
                    onChange={handleChange}
                    error={!!formErrors.phoneContact}
                    helperText={formErrors.phoneContact}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                </Grid>
                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">País domicilio*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-country"
                    select
                    name="repLegalCountry"
                    label={formValues.repLegalCountry === 0 ? "Seleccione" : ""}
                    value={formValues.repLegalCountry === 0 ? "" : formValues.repLegalCountry}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    margin="none"
                    variant="outlined"
                  >
                    {
                      countryCitiesArray && countryCitiesArray.length > 0 &&
                      <MenuItem value={0}><em>Ninguno</em></MenuItem>
                    }
                    {countryCitiesArray && countryCitiesArray.length > 0 ?
                      countryCitiesArray.map((option: IListParametersInfo) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.grandParentName}
                        </MenuItem>
                      ))
                      :
                      <MenuItem value={formValues.repLegalCountry}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Departamento domicilio</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-department"
                    select
                    name="repLegalDepartment"
                    label={formValues.repLegalDepartment === 0 ? "Seleccione" : ""}
                    value={formValues.repLegalDepartment === 0 ? "" : formValues.repLegalDepartment}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    margin="none"
                    variant="outlined"
                    disabled={(formValues.repLegalCountry > 0 && departmentArray.length > 0 && formValues.repLegalCountry === 170 ? false : true)}
                  >
                    {
                      departmentArray && departmentArray.length > 0 ?
                        departmentArray.map((option: IGeneralParameter) => (
                          <MenuItem key={option.parameter_code} value={option.parameter_code}>
                            {option.name}
                          </MenuItem>
                        ))
                        :
                        <MenuItem value={formValues.repLegalDepartment}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Municipio domicilio</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-city"
                    select
                    name="repLegalCity"
                    label={formValues.repLegalCity === 0 ? "Seleccione" : ""}
                    value={formValues.repLegalCity === 0 ? "" : formValues.repLegalCity}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    margin="none"
                    variant="outlined"
                    disabled={repreCities && repreCities.length>0 ? false : true}
                  >
                    {
                      repreCities && repreCities.length > 0 ?
                      repreCities.map((option: IGeneralParameter) => (
                          <MenuItem key={option.parameter_code} value={option.parameter_code}>
                            {option.name}
                          </MenuItem>
                        ))
                        :
                        <MenuItem value={formValues.repLegalCity}></MenuItem>
                    }
                  </TextField>
                </Grid>
                
              </Grid>
            </Grid>

            <Grid container item xs={12} className="profile-company-container">
              <Typography variant="h3" className="bold-serif-primaryColor">
                INFORMACION DE CONTACTO
              </Typography>
              <Grid container item xs={12} direction="row">
                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Nombre completo*</Typography>
                  <TextField
                    fullWidth
                    name="infoContactName"
                    margin="none"
                    value={formValues.infoContactName}
                    onChange={handleChange}
                    error={!!formErrors.infoContactName}
                    helperText={formErrors.infoContactName}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Correo electrónico*</Typography>
                  <TextField
                    fullWidth
                    name="infoContactMail"
                    margin="none"
                    value={formValues.infoContactMail}
                    onChange={handleChange}
                    error={!!formErrors.infoContactMail}
                    helperText={formErrors.infoContactMail}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Número de Celular*</Typography>
                  <TextField
                    fullWidth
                    name="infoContactCellphone"
                    margin="none"
                    value={formValues.infoContactCellphone}
                    onChange={handleChange}
                    error={!!formErrors.infoContactCellphone}
                    helperText={formErrors.infoContactCellphone}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif-primaryColorCompany">Número de teléfono</Typography>
                  <TextField
                    fullWidth
                    name="infoContactPhone"
                    margin="none"
                    value={formValues.infoContactPhone}
                    onChange={handleChange}
                    error={!!formErrors.infoContactPhone}
                    helperText={formErrors.infoContactPhone}
                    InputLabelProps={{ shrink: false }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>

            

            <Grid container item xs={12} className="profile-company-container">
              <Typography variant="h6" className="Regular-serif primaryError">
                {formErrorsDocs.fileError}
              </Typography>
              <Typography variant="h3" className="bold-serif-primaryColor">
                DOCUMENTOS ADJUNTOS
              </Typography>
              <Grid container item xs={12} className="photography-content-container">
                <Grid container item xs={12} justify="space-around">
                  {formValuesDocs.files.map((file: IFile, index) => (
                    <Grid container item xs={11} md={3} key={index} className="card-container">
                      <div className="card-header-container">
                        <Typography variant="h5" className="regular-serif card-title-item">
                          {file.file?.name}
                        </Typography>
                        {(containsAny(permissionCompanyModifyProfile, userContext.userPermissions) || containsAny(permissionAdminModifyCompanies, userContext.userPermissions)) &&
                          <IconButton style={{ padding: 0, height: '30px' }} onClick={() => handleDeleteFile(file)}>
                            <DeleteIcon />
                          </IconButton>
                        }
                      </div>
                      <Grid container item xs={12} className="card-desc-container">
                        <Typography variant="h6" className="regular-serif card-desc-item">
                          {file.description !== '' ? file.description : 'sin descripción'}
                        </Typography>
                      </Grid>
                      <Grid container item xs={12} justify="flex-end" className="card-action">
                        <div className="card-action-btn">
                          <Link target="_blank" rel="noopener" href={file.previewUrl}>
                            <Typography align="center" variant="h6" className="regular-serif">
                              Ver documento
                            </Typography>
                          </Link>
                        </div>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid container item xs={12} justify="flex-start">
                  <TextField
                    fullWidth
                    label="Descripción del documento"
                    name="descriptionDocument"
                    value={formValuesDocs.descriptionDocument || ""}
                    onChange={handleChangeform}
                    error={!!formErrorsDocs.descriptionDocument}
                    helperText={formErrorsDocs.descriptionDocument}
                    placeholder="Descripción del documento"
                    variant="outlined"
                    margin="normal"
                  />
                  {(containsAny(permissionCompanyModifyProfile, userContext.userPermissions) || containsAny(permissionAdminModifyCompanies, userContext.userPermissions)) &&
                    <div className="upload-button-container">
                      <Button variant="contained" component="label" className="upload-button" disabled={!!formErrorsDocs.descriptionDocument || !descResult}>
                        <Typography variant="h5" className="regular-serif">
                          Adjuntar documento
                        </Typography>
                        <div>
                          <input type="file" name="files" onChange={handleChangeDocuments} accept="application/pdf" hidden />
                        </div>
                      </Button>
                    </div>
                  }
                </Grid>
                {(containsAny(permissionCompanyModifyProfile, userContext.userPermissions) || containsAny(permissionAdminModifyCompanies, userContext.userPermissions)) &&
                  <Grid container className="buttons-save-container">
                    <Button onClick={saveFiles} className="save-button" variant="contained" size="small">
                      <Typography variant="h5" className="regular-serif">Guardar documentos</Typography>
                    </Button>
                  </Grid>
                }
              </Grid>
            </Grid>

            <Grid container item xs={12} className="profile-company-container-danger">
              <Typography variant="h3" className="bold-serif primaryError">
                Zona de Peligro
              </Typography>
              <Grid container item xs={12} direction="row" alignItems='center'>
                <Grid item xs={12} sm={6} className="checkbox-dangerZone-container">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.sieRetirement}
                        onChange={changeCheckBoxSIERet}
                        name="sieRetirement"
                      />}
                    label="¿Desea retirarse del SIE?"
                    labelPlacement="start"
                    className="checkbox"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="checkbox-dangerZone-container">
                {/* !formValues.laboralInformation.inJobBank */}
                  { formValues.sieRetirement &&
                    <AlertBox
                      alert="warning"
                      variantType="outlined"
                    >
                      {
                        <span className="alert-simple-text">
                          !Alerta! Usted va a retirarse del SIE,
                          esto solo sucedera al guardar los cambios
                        </span>
                      }
                    </AlertBox>
                  }
                </Grid>
              </Grid>
            </Grid>

            {(containsAny(permissionCompanyModifyProfile, userContext.userPermissions) || containsAny(permissionAdminModifyCompanies, userContext.userPermissions)) &&
              <Grid container item xs={12} justify="flex-end" className="update-container">
                <Button endIcon={<ChevronRightIcon />} className="update-btn" variant="contained" disabled={!disabledSend} size="small" onClick={handleOpenUnenrollModal}>
                  <Typography variant="h6" className="regular-serif">
                    ACTUALIZAR
                  </Typography>
                </Button>
              </Grid>
            }

            {
              roleSelected ?
                <>
                  <Grid container item xs={12}>
                    <SelectActionsBox2 stateSelected={stateSelected} actionSelected={actionSelected} onChangeAction={handleChangeAction} onChangeStateSelected={handleChangeStateSelected} sendActionSelected={sendActionSelected} companyInfo={companiesStateToSend} formErrors={formErrors} onChangeForm={changeObservation} observationSelected={observationSelected} changeOtherObservation={changeOtherObservation} otherObservation={otherObservation} role="company" />
                  </Grid>

                </>
                :
                <>
                </>
            }
            <ModalOffersAction
              open={opeModalConfirm}
              handleClose={closeModalConfirm}
              handleContinue={sendChangeState}
              iconModal={<WarningIcon className="big-info-icon" />}
              title="¿Está seguro de cambiar el estado de la empresa??"
              headerdescription=""
              description="Esta intentando cambiar el estado de una empresa"
              backBtnText="Regresar"
              colorBtnBack=""
              continueBtnText="Si, Continuar"
              colorBtnContinue="#14A5DC"
              valid={true}
            />
            <ModalConfirmedAction
              open={stateChanged}
              handleClose={handleClose}
              icon={<CheckCircleOutlineIcon style={{ color: '#7DA52D', fontSize: '50px' }} />}
              description="Acción ejecutada exitosamente"
            />
            <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
            {/* <ErrorModal errorMsg={serviceError} handleCloseModal={handleCloseModalError} openModalError={openModalError} /> */}
            <UnenrollModal list={unenrollList} handleCloseModal={handleCloseModalEnrrollModal} handleCancelModal={handleCancelModalEnrrollModal} openModalUnenroll ={openUnenrollModal} onChangeUnenroll={handleOnChangeUnenroll}/>
            <LoadingLockPage load={promiseInProgress} />
            <LoadingLockPage load={initLoad} />
          </Grid>
      }
    </>
  )
}

export default EditProfileCompany
