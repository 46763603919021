import { IFile } from './IFile';
export class IAttachmentInfoCV{
    filesCV: Array<IFile>;
    otherFiles: Array<IFile>;
    descriptionDocument: string;
    descriptionOtherDocument: string;
    constructor(def:any){
        this.filesCV = def.filesCV || [];
        this.otherFiles = def.otherFiles || [];
        this.descriptionDocument = def.descriptionDocument || "";
        this.descriptionOtherDocument = def.descriptionOtherDocument || "";
    }
}