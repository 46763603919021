import { AttachingFile } from "./IGraduateFilterObjMsg";

export class RangeDateValues{
    sinceDate: string = "";
    untilDate: string = "";
}
export class ICompanyFilter{
    name?: string = "";
    nit?: number | null = null;
    laboralSector?: number = 0;
    typeOfCompany?: number = 0;
    country?: number = 0;
    department?: number = 0;
    city?: number = 0;
    state?: Array<string> = [];
    rangeRegister?: RangeDateValues = new RangeDateValues();
}
export class ICompanyFilterObj{
    type?: string = "";
    preview?: boolean = true; // true return number, false send email
    filters:ICompanyFilter = new ICompanyFilter();
    subject? : string = "";
    html?: string = "";
    aditionalEmails?: Array<string> = [];
    attachingFiles: Array<AttachingFile>=[];
}