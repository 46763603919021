import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import './ErrorModal.scss';



const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="error-dialog-header" {...other}>
      <Typography variant="h2" className="error-dialog-title" style={{ marginRight: '16px' }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface ErrorModalProps {
  errorMsg: string;
  handleCloseModal: any;
  openModalError: boolean;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ errorMsg, handleCloseModal, openModalError }) => {

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={openModalError} className="error-dialog-container">
      <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
        Error!
      </DialogTitle>
      <DialogContent className="error-dialog-content">
        <Typography variant="h5" className="text-content-dialog">{errorMsg}</Typography>
      </DialogContent>
      <DialogActions className="error-dialog-actions">
        <div style={{ width: '100%' }}>
          <Button onClick={handleCloseModal} className="accept-button">
            <Typography variant="h5" className="regular-serif">
              Aceptar
            </Typography>
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorModal;