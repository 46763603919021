import React from 'react';
import moment from "moment";
import {Link}  from 'react-router-dom';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import EventIcon from '@material-ui/icons/Event';
import { IGetNewEvent } from '../../interfaces/IGetNewEvent';
import imgNoticias from '../../assets/images/UN Somos Campus 2016-MEDIA-0432-2.jpg';
import imgEventos from '../../assets/images/UN Somos Campus 2016-MEDIA-0571.jpg';

import CardMedia from '@material-ui/core/CardMedia';

import'./TabPanelContent.scss';




const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 300,
    display: 'block',
    maxWidth: 'fitContent',
    overflow: 'hidden',
    width: '100%',
  },
}));

interface TabPanelContentProps{
    leftBtnText?: string;
    rightBtnText?: string;
    value: number;
    data: Array<IGetNewEvent>
    type: string;
}

const TabPanelContent: React.FC<TabPanelContentProps> = ({ leftBtnText, rightBtnText, data, value, type}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };


  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} className="carrusel-container">
        <div className="carrusel-content">
          <Grid container item>
            {
              data[activeStep] && data[activeStep].categoryName &&
              <>
                <Typography variant="h6" className={data[activeStep].type === "Noticia" ? "category-news" : "category-events"} >
                  <BookmarkIcon className="category-icon"/> {data[activeStep].categoryName}
                </Typography>
              </>
            }
            {
              type === "Noticia" && data[activeStep] && data[activeStep].publishDate &&
              <>
                <Typography variant="h6" className="date-news">
                  <EventIcon className="date-icon" />
                  <span style={{marginLeft:"8px"}}>
                    {moment(new Date(data[activeStep].publishDate+"")).locale('es').format("dddd, DD MMMM YYYY")}
                  </span>
                </Typography>
              </>
            }
          </Grid>
          {
            data[activeStep] && data[activeStep].name &&
            <Typography variant="h2" className="news-title">{data[activeStep].name}</Typography>
          }
          {
            type === "Evento" && data[activeStep] && data[activeStep].dateStart &&
            <>
              <Typography variant="h5" className="event-time-title">
                Fecha y hora de inicio
              </Typography>
              <Typography variant="h6" className="date-events">
                <EventIcon className="date-icon"/>
                <span style={{marginLeft:"8px"}}>
                  {moment(new Date(data[activeStep].dateStart+"")).locale('es').format("dddd, DD MMMM YYYY")}
                </span>
                <span style={{marginLeft:"8px"}}>
                  {moment(data[activeStep].timeStart, "hh:mm:ss").format("hh:mm a")}
                </span>
              </Typography>
            </>
          }
          {
            type === "Evento" && data[activeStep] && data[activeStep].dateFinish &&
            <>
              <Typography variant="h5" className="event-time-title">
                Fecha y hora de finalización
              </Typography>
              <Typography variant="h6" className="date-events">
                <EventIcon className="date-icon"/>
                <span style={{ marginLeft: "8px" }}>
                  {moment(new Date(data[activeStep].dateFinish+"")).locale('es').format("dddd, DD MMMM YYYY")}
                </span>
                <span style={{marginLeft:"8px"}}>
                  {moment(data[activeStep].timeFinish, "hh:mm:ss").format("hh:mm a")}
                </span>
              </Typography>
            </>
          }
          {
            data[activeStep] && data[activeStep].name &&
            <Typography variant="h4" className="news-summary">{data[activeStep].type === "Noticia" ? data[activeStep].shortDescription : ""}</Typography>
          }
          <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {data.map((step, index) => (
              <div key={step.id} className="img-container">
                {Math.abs(activeStep - index) <= 2 ? (
                  <CardMedia
                    component="img"
                    className={classes.img}
                    alt={step.name}
                    image={
                        step.image === null && step.type === "Noticia"? imgNoticias : step.image === null && step.type === "Evento" ? imgEventos : step.image
                    }
                    />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            variant="dots"
            steps={data.length}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button className="button" size="small" onClick={handleNext} disabled={activeStep === data.length - 1}>
                Siguiente
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button className="button" size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Atras
              </Button>
            }
          />
        </div>
      </Grid>
      <Grid container item  xs={12} className={value === 1? "container-button-left" : "container-button-right"}>
        <Button className={value === 1 ? "left-button" : "no-button"} variant="contained"  size="small" component={ Link } to="/eventos">
          <Typography variant="h5" className="bold-serif">
            {leftBtnText}
          </Typography>
        </Button>
        <Button className={value === 0 ? "right-button" : "no-button"} variant="contained"  size="small" component={ Link } to="/noticias">
          <Typography variant="h5" className="bold-serif">
            {rightBtnText}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  )
}

export default TabPanelContent
