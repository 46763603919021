import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { StateByRole } from '../../../interfaces/StateByRole';

import './AditionalOptions.scss';


interface AditionalOptionsProps{
    stateCheck:any
    onChangeState: any;
    stateOptions: Array<StateByRole>;
}

const AditionalOptions:React.FC<AditionalOptionsProps> = ({ stateCheck, onChangeState, stateOptions})=>{
    return(
      <Grid container item xs={12} className="select-experience-content">
          <Grid container item xs={12}  className="select-experience-options">
              <FormControl component="fieldset">
                  <FormGroup className="group-checkbox">
                      {stateOptions.map((option: StateByRole) => {
                          const checkedValue = stateCheck[option.value]
                          return (
                              <FormControlLabel key={option.id} className="label-checkbox"
                                  control={<Checkbox checked={checkedValue} onChange={onChangeState} name={option.value} />}
                                  label={option.label}
                              />
                          )
                      })}
                  </FormGroup>
              </FormControl>
          </Grid>
      </Grid>
    )
}

export default AditionalOptions;