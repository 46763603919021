import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { DataGrid, GridBaseComponentProps, GridCellParams, GridColDef, GridColParams, GridOverlay, GridPageChangeParams, GridSelectionModelChangeParams, GridSortModelParams } from '@material-ui/data-grid';
import Pagination from '@material-ui/lab/Pagination';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import SettingsIcon from '@material-ui/icons/Settings';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ICompanyTable } from '../../../interfaces/ICompanyTable';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { StateByRole } from '../../../interfaces/StateByRole';
import UserContext from '../../../utils/UserContext';
import { containsAny } from '../../../helpers/sharedFunctions';
import { permissionAdminSeeCompanyOffers, permissionAdminSeeInfoCompanies } from '../../../utils/PermissionsVariables';

import './DataTableCompany.scss';


const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 14,
      padding: '5px 6px 10px 12px',
      height: '30px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  }),
)(InputBase);

interface DataTableCompanyProps{
    checkRows:any;
    setCheckRows:any;
    rows: Array<ICompanyTable>;
    pageSize: number;
    onChanhePageSize: any;
    onSelectionRows: any;
    allLaboralSector: Array<IGeneralParameter>;
    cityArr: Array<IGeneralParameter>;
    handleRowButton: any;
    initPage: number;
    setInitPage:any;
    stateOptions: Array<StateByRole>;
    setCurrentPage: any;
    onSortedRows?: any;
}

const DataTableCompany: React.FC<DataTableCompanyProps> = ({checkRows, setCheckRows, rows, pageSize, onChanhePageSize, onSelectionRows, allLaboralSector, cityArr, handleRowButton, initPage, setInitPage, stateOptions, setCurrentPage, onSortedRows})=>{

    const userContext = React.useContext(UserContext);
    const [pageNumber, setPageNumber]= useState<number>(0);
    const [checkAllPage, setCheckAllPage] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [rowSelected, setRowSelected]= useState<ICompanyTable>(new ICompanyTable());
    const [nit, setNit] = useState<any>(null);

    const columnsCompany: GridColDef[] = [
        { field: 'name', headerName: 'Razón social', width: 250, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Razón social'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell" >{params.value}</span>
        )} },
        { field: 'laboralSector', headerName: 'Sector Laboral', width: 190, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    Sector Laboral
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) =>{
            if(allLaboralSector.length>0){
                const sectorObj = allLaboralSector.find(sector => sector.parameter_code === params.value);
                if(sectorObj){
                    return(
                        <span className="curriculum-cell" id="shortDescription-cell">{sectorObj.name}</span>
                    )
                }
            }
            return(
                <span></span>
            )
          }
        },
        { field: 'fullNit', headerName: 'Nit-Código de verificación', width: 180, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-break">
                Nit-Código<br/> de verificación
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <span className="doc-cell">{params.value}</span>
        ) },
        { field: 'state', headerName: 'Estado', width: 150, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Estado'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <strong>
                {params.value === 'Activo' && <div className="active-state state-button-cell">Activo</div>}
                {params.value === 'Inscrito' && <div className="signed-up-state state-button-cell">Inscrito</div>}
                {params.value === 'Inactivo' && <div className="inactive-state state-button-cell">Inactivo</div>}
                {params.value === 'Rechazado' && <div className="rejected-state state-button-cell">Rechazado</div>}
            </strong>
        ) },
        { field: 'city', headerName: 'Municipio', width: 160, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Municipio'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              if(cityArr.length>0){
                const cityObj = cityArr.find(city => city.parameter_code === params.value);
                if(cityObj){
                    return(
                        <span className="curriculum-cell">{cityObj.name}</span>
                    )
                }
              }
              return(
                <span></span>
              )
            }
        },
        { field: 'Acción', headerName: 'Acciones', width: 100, sortable: false ,renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Acciones'}
                </span>
            </strong>
          ), renderCell:(params: GridCellParams) => (
              <>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=>handleClick(e,params)}>
                    <SettingsIcon/>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {stateOptions.map((option: StateByRole)=>(
                        <MenuItem key={option.id} value={option.value} onClick={(e)=>{handleClose();handleRowButton(option.value,rowSelected)}}>
                            {option.description}
                        </MenuItem>
                    ))}
                    {containsAny(permissionAdminSeeInfoCompanies, userContext.userPermissions) &&
                        <MenuItem component={Link} to={`/admin/info-empresa/${nit}`}>
                            Ver información de la empresa
                        </MenuItem>
                    }
                    {rowSelected.state === "Activo" && containsAny(permissionAdminSeeCompanyOffers, userContext.userPermissions) &&
                        <MenuItem value="Ofertas" onClick={(e)=>{handleClose();handleRowButton("Ofertas",rowSelected)}}>
                            Ver ofertas laborales
                        </MenuItem>
                    }
                </Menu>
              </>
        ) },
    ];

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, params: GridCellParams) => {
        setAnchorEl(event.currentTarget);
        const company: ICompanyTable = Object.assign(params.row);
        setRowSelected(company);
        setNit(company.nit);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCheckAllPage = (colParams: GridColParams)=>{
        if(colParams.colDef.field === '__check__'){
            setCheckAllPage(!checkAllPage)
        }
    }

    const handlePage = (pageParams: GridPageChangeParams)=>{
        setCurrentPage(pageParams.page + 1)
        setPageNumber(pageParams.page)
        setInitPage(1)
    }

    useEffect(()=>{
        if(checkAllPage){
            const initialValue = pageNumber*pageSize;
            const finalValue = pageNumber*pageSize+pageSize < rows.length ? pageNumber*pageSize+pageSize : rows.length;
            let indexRange: Array<ICompanyTable> = rows.slice(initialValue, (finalValue))
            let list:Array<string> = [];
            for (let i = 0; i < indexRange.length; i++) {
                list.push((indexRange[i].id).toString(10));
            }
            setCheckRows(list)
        } else {
            setCheckRows([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkAllPage])

    return(
        <>
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid rowHeight={100} autoHeight className="data-grid-list" disableColumnMenu rows={rows} columns={columnsCompany} pageSize={pageSize} pagination checkboxSelection density="compact" disableSelectionOnClick selectionModel={checkRows} onColumnHeaderClick={(params: GridColParams)=>handleCheckAllPage(params)} onPageChange={(params:GridPageChangeParams)=>handlePage(params)} onSelectionModelChange={(params: GridSelectionModelChangeParams)=>onSelectionRows(params)} onSortModelChange={(params: GridSortModelParams)=>onSortedRows(params)}
                components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                        Footer:(props)=>CustomFooter(props, initPage, onChanhePageSize, pageSize)
                }}
                />
            </Grid>
        </Grid>
        </>
    )
}

const CustomFooter = (props: GridBaseComponentProps, initPage:number, onChanhePageSize?: any, valueRows?: number)=> {
    const { state, api } = props;
    const rowsSelected = Object.keys(state.selection).length;
    const rowsPerPageOptions = [5,10,25,50,100];

    useEffect(()=>{
        if(initPage === 0){
            api.current.setPage(0)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[initPage]);
    return (
        <>
            <div className="footer-container">
                <div className="rows-number-container">
                    <Typography variant="h5" className="rows-number-text">Mostrar</Typography>
                    <Select
                        id="demo-simple-select"
                        value={valueRows}
                        onChange={onChanhePageSize}
                        input={<BootstrapInput/>}
                    >
                        {rowsPerPageOptions.map(item=>(
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                    <Typography variant="h5" className="rows-number-text">Registros</Typography>
                </div>
                <div className="rows-selected-container">
                    <Typography variant="h4" className="rows-selected-number">{rowsSelected}</Typography>
                    <Typography variant="h4" className="rows-selected-text">registros seleccionados</Typography>
                </div>
                <Pagination
                    className="pagination-container"
                    color="primary"
                    page={state.pagination.page+1}
                    count={state.pagination.pageCount}
                    onChange={(event, value) =>api.current.setPage(value-1)}
                    showFirstButton
                    showLastButton
                />
            </div>
        </>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large"/>
            <div className="not-results-text">Sin registros</div>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps)=><KeyboardArrowUpIcon fontSize="small"/>
const customSortDesc = (props: GridBaseComponentProps)=><KeyboardArrowDownIcon fontSize="small"/>

export default DataTableCompany;