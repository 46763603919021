import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { DataGrid, GridColDef, GridOverlay, GridColParams, GridCellParams, GridPageChangeParams, GridSelectionModelChangeParams, GridBaseComponentProps, GridSortModelParams } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import { IGraduateTable } from '../../../interfaces/IGraduateTable';
import { StateByRole } from '../../../interfaces/StateByRole';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { containsAny } from '../../../helpers/sharedFunctions';
import { permissionAdminModifyGraduates, permissionAdminSeeInfoGraduates } from '../../../utils/PermissionsVariables';
import UserContext from '../../../utils/UserContext';

import './DataTableGraduate.scss'

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 14,
      padding: '5px 6px 10px 12px',
      height: '30px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  }),
)(InputBase);

interface DataTableGraduateProps{
    checkRows:any;
    setCheckRows:any;
    rows: Array<IGraduateTable>;
    pageSize: number;
    onChanhePageSize: any;
    onSelectionRows: any;
    allPrograms: Array<IGeneralParameter>;
    handleRowButton: any;
    initPage: number;
    setInitPage:any;
    stateOptions: Array<StateByRole>;
    handleGraduate?: any;
    setCurrentPage: any;
    onSortedRows?: any;
}

const DataTableGraduate:React.FC<DataTableGraduateProps> = ({checkRows, setCheckRows, rows, pageSize, onChanhePageSize, onSelectionRows, allPrograms, handleRowButton, initPage, setInitPage, stateOptions, handleGraduate, setCurrentPage, onSortedRows}) => {

    const userContext = React.useContext(UserContext);
    const [pageNumber, setPageNumber]= useState<number>(0);
    const [checkAllPage, setCheckAllPage] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [rowSelected, setRowSelected]= useState({});
    const [idGraduate, setIdGraduate] = useState<any>(null);
    const [state, setState] = useState<any>(null);
    
    const columnsGraduate: GridColDef[] = [
        { field: 'fullname', headerName: 'Nombres/Apellidos', width: 230, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Nombres/Apellidos'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(                 
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'date', headerName: 'Fecha de grado', width: 170, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-break">
                    Fecha de <br/> grado
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) =>{
              const valueDate = moment((params.value)?.toString()).format('YYYY/MM');
              return(
                  <span className="date-cell">{valueDate}</span>
                )
            }
        },
        { field: 'doc', headerName: 'Número identificación', width: 170, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-break">
                    Número de <br/> identificación
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <span className="doc-cell">{params.value}</span>
        ) },
        { field: 'updatedAt', headerName: 'Fecha de Actualización', width: 170, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-break">
                    Fecha de <br/> actualizacion
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) =>{
              const valueDate = moment((params.value)?.toString()).format('YYYY/MM');
              return(
                  <span className="date-cell">{valueDate}</span>
                )
            }
        },
        { field: 'state', headerName: 'Estado', width: 120, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Estado'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <strong>
                {params.value === 'Activo' && <div className="active-state state-button-cell">Activo</div>}
                {params.value === 'Inscrito' && <div className="signed-up-state state-button-cell">Inscrito</div>}
                {params.value === 'Verificado' && <div className="verified-state state-button-cell">Verificado</div>}
                {params.value === 'Inactivo' && <div className="inactive-state state-button-cell">Inactivo</div>}
                {params.value === 'Rechazado' && <div className="rejected-state state-button-cell">Rechazado</div>}
            </strong>
        ) },
        { field: 'curriculum', headerName: 'Programa', width: 130, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Programa'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              if(allPrograms.length>0){
                const programObj = allPrograms.find(program => program.parameter_code === params.value);
                if(programObj){
                    return(
                        <span className="curriculum-cell">{programObj.name}</span>
                    )
                }
              }
              return(
                <span></span>
              )
            }
        },
        { field: 'Acción', headerName: 'Acciones', width: 100, sortable: false ,renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Acciones'}
                </span>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return (
              <>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=>handleClick(e,params)}>
                    <SettingsIcon/>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {stateOptions.map((option: StateByRole)=>(
                        <MenuItem key={option.id} value={option.value} onClick={(e)=>{handleClose();handleRowButton(option.value,rowSelected)}}>
                            {option.description}
                        </MenuItem>
                    ))}

                    <MenuItem onClick={(e)=>{handleGraduate(true, rowSelected)}}>
                        Ver información de registro
                    </MenuItem>

                    <MenuItem component={Link} to={`/admin/curriculum-egresado/${idGraduate}`}>
                        Ver curriculum del egresado
                        </MenuItem>
                    
                </Menu>
              </>
        )} },
    ];

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, params:GridCellParams) => {
        setAnchorEl(event.currentTarget);
        setRowSelected(params.row)
        setIdGraduate(params.row.doc)
        setState(params.row.state)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCheckAllPage = (colParams: GridColParams)=>{
        if(colParams.colDef.field === '__check__'){
            setCheckAllPage(!checkAllPage)
        }
    }

    const handlePage = (pageParams: GridPageChangeParams)=>{
        setPageNumber(pageParams.page);
        setCurrentPage(pageParams.page + 1);
        setInitPage(1);
    }

    useEffect(()=>{
        if(checkAllPage){
            const initialValue = pageNumber*pageSize;
            const finalValue = pageNumber*pageSize+pageSize < rows.length ? pageNumber*pageSize+pageSize : rows.length;
            let indexRange: Array<IGraduateTable> = rows.slice(initialValue, (finalValue))
            let list:Array<string> = [];
            for (let i = 0; i < indexRange.length; i++) {
                list.push((indexRange[i].id));
            }
            setCheckRows(list)
        } else {
            setCheckRows([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkAllPage])


    return (
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid autoHeight className="data-grid-list" disableColumnMenu rows={rows} columns={columnsGraduate} pageSize={pageSize} pagination checkboxSelection density="compact" disableSelectionOnClick selectionModel={checkRows} onColumnHeaderClick={(params: GridColParams)=>handleCheckAllPage(params)} onPageChange={(params:GridPageChangeParams)=>handlePage(params)} onSelectionModelChange={(params: GridSelectionModelChangeParams)=>onSelectionRows(params)} onSortModelChange={(params: GridSortModelParams)=>onSortedRows(params)}
                components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                        Footer:(props)=>CustomFooter(props, initPage, onChanhePageSize, pageSize)
                }}
                />
            </Grid>
        </Grid>
    );
}

const CustomFooter = (props: GridBaseComponentProps, initPage:number, onChanhePageSize?: any, valueRows?: number)=> {
    const { state, api } = props;
    const rowsSelected = Object.keys(state.selection).length;
    const rowsPerPageOptions = [5,10,25,50,100];

    useEffect(()=>{
        if(initPage === 0){
            api.current.setPage(0)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[initPage]);
    return (
        <>
            <div className="footer-container">
                <div className="rows-number-container">
                    <Typography variant="h5" className="rows-number-text">Mostrar</Typography>

                        <Select
                            id="demo-simple-select"
                            value={valueRows}
                            onChange={onChanhePageSize}
                            input={<BootstrapInput/>}
                        >
                            {rowsPerPageOptions.map(item=>(
                                <MenuItem value={item}>{item}</MenuItem>
                            ))}
                        </Select>
                    <Typography variant="h5" className="rows-number-text">Registros</Typography>
                </div>
                <div className="rows-selected-container">
                    <Typography variant="h4" className="rows-selected-number">{rowsSelected}</Typography>
                    <Typography variant="h4" className="rows-selected-text">registros seleccionados</Typography>
                </div>
                <Pagination
                    className="pagination-container"
                    color="primary"
                    page={state.pagination.page+1}
                    count={state.pagination.pageCount}
                    onChange={(event, value) =>api.current.setPage(value-1)}
                    showFirstButton
                    showLastButton
                />
            </div>
        </>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large"/>
            <Typography variant="h4" className="not-results-text">
                Sin registros
            </Typography>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps)=><KeyboardArrowUpIcon fontSize="small"/>
const customSortDesc = (props: GridBaseComponentProps)=><KeyboardArrowDownIcon fontSize="small"/>

export default DataTableGraduate;