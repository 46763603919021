import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import SettingsIcon from '@material-ui/icons/Settings';
import { Alert, AlertTitle } from '@material-ui/lab';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Validations from '../../helpers/Validations';
import { IGetParameterSettings } from '../../interfaces/IGetParameterSettings';
import SuccessModal from '../ShareComponents/SuccessModal';
import ErrorModal from '../ShareComponents/ErrorModal';
import LoadingLockPage from '../ShareComponents/LoadingLockPage';
import { getConfiguration, updateConfiguration } from '../../services/ConfigurationService';

import './ParameterSettings.scss';

const initialFormValues ={
  EMAIL_HELPDESK: "",
  EMAIL_FROM: "",
  SALARY_MINIMUM: ""
}

const formValidations ={
  EMAIL_HELPDESK: {
    error: "",
    validate: "email",
    required: true
  },
  EMAIL_FROM: {
    error: "",
    validate: "email",
    required: true
  },
  SALARY_MINIMUM: {
    error: "",
    validate: "number",
    minLength: 6,
    required: true
  }
}


const ParameterSettings = () => {
  const { promiseInProgress } = usePromiseTracker();
  const [formValues, setFormValues] = useState<IGetParameterSettings>(new IGetParameterSettings({}));
  const [formErrors, setFormErrors] = useState(initialFormValues);
  const [errorMessage, setErrorMessage]= useState('');
  const [successMessage, setSuccessMessage]= useState('');
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [open, setOpen] = useState<boolean>(false);

  const isValid =
  formValues.EMAIL_FROM.length > 0 &&
  !formErrors.EMAIL_FROM &&
  formValues.EMAIL_HELPDESK.length > 0 &&
  !formErrors.EMAIL_HELPDESK &&
  formValues.SALARY_MINIMUM.length > 0 &&
  !formErrors.SALARY_MINIMUM

  useEffect(()=>{
    const data = async()=>{
      const responseParameters = await trackPromise(getConfiguration());
      const result = responseParameters.message;
      if(result.length>0){
        for(let i in result){
          setFormValues(prev=>({
            ...prev,
            [result[i].configuration_name]: result[i].value
          }))
          const error = Validations(result[i].configuration_name, result[i].value, formValidations) || "";
            setFormErrors(prev=>({
                ...prev,
                [result[i].configuration_name]: error
            }));
        }
      }
    }
    data();
  },[])

  const requestParameter = async()=>{
    const parametersToSend=[
    {
      "configuration_name":"EMAIL_HELPDESK",
      "value": formValues.EMAIL_HELPDESK
    },
    {
      "configuration_name":"EMAIL_FROM",
      "value":formValues.EMAIL_FROM
    },
    {
      "configuration_name":"SALARY_MINIMUM",
      "value":formValues.SALARY_MINIMUM
    }
    ]

    const responseUS: Response = await trackPromise(updateConfiguration(parametersToSend));
    if(responseUS.status === 200){
        setSuccessMessage("Se ha actualizado la información con éxito");
        setOpenModalSuccess(true);
      } else if (responseUS.status === 400){
        setErrorMessage("espacio para error del servicio");
        setOpenModalError(true);
      } else {
        setErrorMessage("Hubo un error, intente de nuevo mas tarde");
        setOpenModalError(true);
      }
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
    const error = Validations(name, value, formValidations) || "";
    setFormErrors(prev=>({
        ...prev,
        [name]: error
    }));
  }

  const goBack = ()=>{
    setOpen(true)
  }

  const handleCloseModalSuccess = ()=>{
    setOpenModalSuccess(false);
    window.location.reload();
  };

  const handleCloseModalError = ()=>{
    setOpenModalError(false);
    window.location.reload();
  };

  return (
    <>
      {!open?
        <Grid container className="main-container">
          <Grid container item xs={12} className="title-container">
            <Grid container item xs={12} md={5} lg={5}>
                <div className="title-section-line">
                    <SettingsIcon className="group-icon"/>
                    <Typography variant="h2" className="event-title" >
                      Configuraciones generales del sistema
                    </Typography>
                </div>
            </Grid>
            <Grid container item xs={12} md={7} lg={7} className="line" />
          </Grid>

          <Alert variant="outlined" severity="info" className="alert-container">
            <AlertTitle><span className="alert-title">Información</span></AlertTitle>
            <span className="alert-description">En esta sección podrá configurar los siguientes parámetros generales del sistema: correo electrónico remitente, correo electrónico de la Mesa de Ayuda y tope del salario mínimo.</span>
          </Alert>

          <Grid container item xs={12} className="parameter-container">
            <Grid container item xs={12} className="title-container">
              <Typography variant="h2" className="title">
                Correo electrónico remitente
              </Typography>
            </Grid>
            <Grid container item xs={12} direction="row" className="parameter-content">
              <Grid item xs={12}  className="field-box-margin">
                <Typography variant="h5" className="field-box-title">Correo electrónico</Typography>
                <TextField
                  fullWidth
                  name="EMAIL_FROM"
                  placeholder="Correo "
                  disabled
                  rows={1}
                  variant="standard"
                  value={formValues.EMAIL_FROM || ""}
                  onChange={handleChange}
                  error={!!formErrors.EMAIL_FROM}
                  helperText={formErrors.EMAIL_FROM}
                  margin="none"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} className="parameter-container">
            <Grid container item xs={12} className="title-container">
              <Typography variant="h2" className="title">
                Correo electrónico Mesa de Ayuda
              </Typography>
            </Grid>
            <Grid container item xs={12} direction="row" className="parameter-content">
              <Grid item xs={12}  className="field-box-margin">
                <Typography variant="h5" className="field-box-title">Correo electrónico*</Typography>
                <TextField
                  fullWidth
                  name="EMAIL_HELPDESK"
                  placeholder="Correo "
                  multiline
                  rows={1}
                  variant="outlined"
                  value={formValues.EMAIL_HELPDESK || ""}
                  onChange={handleChange}
                  error={!!formErrors.EMAIL_HELPDESK}
                  helperText={formErrors.EMAIL_HELPDESK}
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}  className="field-box-margin">
                <Typography variant="h5" className="text-info">
                  El correo electrónico que introduzca en este campo será el correo de soporte que recibirá las solicitudes de los usuarios del SIE. Por favor verifique que el correo seleccionado sea el correcto.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} className="parameter-container">
            <Grid container item xs={12} className="title-container">
              <Typography variant="h2" className="title">
                Salario mínimo legal mensual vigente
              </Typography>
            </Grid>
            <Grid container item xs={12} direction="row" className="parameter-content">
              <Grid item xs={12}  className="field-box-margin">
                <Typography variant="h5" className="field-box-title">Salario mínimo legal mensual vigente*</Typography>
                <TextField
                  fullWidth
                  name="SALARY_MINIMUM"
                  placeholder="Salario "
                  multiline
                  rows={1}
                  variant="outlined"
                  value={formValues.SALARY_MINIMUM || ""}
                  onChange={handleChange}
                  error={!!formErrors.SALARY_MINIMUM}
                  helperText={formErrors.SALARY_MINIMUM}
                  margin="none"
                />
              </Grid>
              <Grid item xs={12}  className="field-box-margin">
                <Typography variant="h5" className="text-info">
                  Por favor introduzca el valor del salario mínimo legal mensual vigente para este año.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} className="buttons-container-parameter">
            <Grid container item xs ={6} md={6} lg={6} className="left">
              <Button startIcon={<ChevronLeftIcon />} className="cancel-button" variant="contained" size="small" onClick={goBack}><Typography variant="h5" className="regular-serif">Cancelar</Typography> </Button>
            </Grid>

            <Grid container item xs ={6} md={6} lg={6} className="right">
              <Button disabled={!isValid} onClick={requestParameter}  className="confirm-button" variant="contained" size="small"><Typography variant="h5" className="regular-serif">Guardar</Typography></Button>
            </Grid>
            <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
          </Grid>
          <LoadingLockPage load={promiseInProgress}/>
        </Grid>
      :
        <Redirect to={'/admin/dashboard'} />
      }
    </>
  )
}

export default ParameterSettings
