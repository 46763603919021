import React from 'react';
import Grid from "@material-ui/core/Grid";
import { Redirect } from 'react-router';
import ReportFormGraduate from '../../../../components/Reports/ReportFormGraduate';
import { isLogin } from '../../../../services/AuthService';
import UserContext from '../../../../utils/UserContext';
import { containsAny } from '../../../../helpers/sharedFunctions';
import { permissionAdminGraduateReportsView } from '../../../../utils/PermissionsVariables';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';

const ReportsGraduate: React.FC = () => {
  const userContext = React.useContext(UserContext);

  return (
    <>
    {isLogin() ?
      <>
      {containsAny(permissionAdminGraduateReportsView, userContext.userPermissions) ?
        <Grid container>
          <ReportFormGraduate/>
        </Grid>
      :
        <NotAuthorized/>
      }
      </>
    :
      <Redirect to ="/"/>
    }
    </>
  )
}

export default ReportsGraduate;
