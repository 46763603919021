import React from 'react';
import {Link}  from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

interface CompanyStateBarProps{
    selected: string;
}

const CompanyStateBar: React.FC<CompanyStateBarProps> = ({selected})=>{

  const buttons = [
    {id: 1, name: 'Gestión de estados', state: 'Gestion', url: "/admin/dashboard/empresas"},
    {id: 2, name: 'Mensajería masiva', state: 'Mensajeria', url: "/admin/dashboard/mensajeria-empresas"},
    {id: 3, name: 'Reportes empresas', state: 'Reportes', url: "/admin/dashboard/reportes-empresas"},
    {id: 4, name: 'Reportes ofertas', state: 'Ofertas', url: "/admin/dashboard/reportes-ofertas"},
  ]

  return (
    <Grid item xs={12} className="state-bar-container">
      <Grid container item xs={12} className="container-buttons">
        {buttons.map(item=>(
          <Link key={item.id}  to={item.url} className="link-state-bar">
            <div className={selected === item.state ? 'button-definition-active' : 'button-definition'}>
              <Typography variant="h5" className="regular-serif">
                {item.name}
              </Typography>
            </div>
          </Link>
        ))}
      </Grid>
    </Grid>
  )
}

export default CompanyStateBar;
