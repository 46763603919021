import React, { useState, useEffect } from 'react';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { DataGrid, GridColDef, GridOverlay, GridColParams, GridCellParams, GridPageChangeParams, GridBaseComponentProps } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import { IUserByCompany } from '../../interfaces/IUserByCompany';
import ChangeStateModal from '../../components/ChangeStateModal';
import SuccessModal from '../ShareComponents/SuccessModal';
import ErrorModal from '../ShareComponents/ErrorModal';
import LoadingLockPage from '../ShareComponents/LoadingLockPage';
import { changeStateUserByCompany } from '../../services/CompanyService';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { containsAny } from '../../helpers/sharedFunctions';
import UserContext from '../../utils/UserContext';
import { permissionCompanyActiveInactiveCompanyProfiles } from '../../utils/PermissionsVariables';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 14,
      padding: '5px 6px 10px 12px',
      height: '30px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  }),
)(InputBase);

interface ProfilesListProps{
    activeUsers: Array<IUserByCompany>;
    rows: Array<IUserByCompany>;
    pageSize: number;
    onChanhePageSize: any;
    initPage: number;
    setInitPage:any;
}

const ProfilesList:React.FC<ProfilesListProps> = ({ activeUsers, rows, pageSize, onChanhePageSize,  initPage, setInitPage }) => {

    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [rowSelected, setRowSelected]= useState<IUserByCompany>(new IUserByCompany());
    const [errorMessage, setErrorMessage]= useState('');
    const [openModalError, setOpenModalError] = React.useState(false);
    const [successMessage, setSuccessMessage]= useState('');
    const [openModalSuccess, setOpenModalSuccess] = React.useState(false);

    const columnsProfiles: GridColDef[] = [
        { field: 'name', headerName: 'Nombre del perfil', width: 290, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Nombre del perfil'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'email', headerName: 'Correo electrónico', width: 290, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Correo electrónico'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'phone', headerName: 'Número telefónico', width: 250, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Número telefónico'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'status', headerName: 'Estado', width: 180, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Estado'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'Acción', headerName: 'Acciones', width: 140, sortable: false ,renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Acciones'}
                </span>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <>
                <Button disabled={!containsAny(permissionCompanyActiveInactiveCompanyProfiles, userContext.userPermissions)} aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=>handleClick(e,params)}>
                    <SettingsIcon/>
                </Button>

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={(e)=>{handleRowButton()}}>Activar usuario</MenuItem>
                </Menu>
                <ChangeStateModal
                    open={openEditModal}
                    handleClose={handleCloseModal}
                    title="Activar usuario"
                    backBtnText="CANCELAR"
                    colorBtnBack=""
                    continueBtnText="REEMPLAZAR"
                    continueBtnColor=""
                    userSelected={rowSelected}
                    activeUsers={activeUsers}
                />
            </>
        ) },
    ];

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, params:GridCellParams) => {
        const user: IUserByCompany = Object.assign(params.row)
        setAnchorEl(event.currentTarget);
        setRowSelected(user)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRowButton = () => {
        setAnchorEl(null);
        if(activeUsers.length<3){
            handleChangeState();
        } else {
            setOpenEditModal(true);
        }
    };

    const handleChangeState = async()=>{
        if(rowSelected){
            const userToEnable = {
                id: rowSelected.id,
                status: "Activo"
            }
            const responseSUC: Response = await trackPromise(changeStateUserByCompany(
                {...userToEnable, modifyUser: userContext.userId}
            ));
            if(responseSUC.status === 200){
                const result = await responseSUC.json();
                setSuccessMessage(result.message)
                setOpenModalSuccess(true);
                setOpenModalError(false);
            }else if (responseSUC.status === 400){
                const result = await responseSUC.json();
                setErrorMessage(result.message);
                setOpenModalError(true);
                setOpenModalSuccess(false);
            } else {
                setErrorMessage("Hubo un error, intente de nuevo mas tarde");
                setOpenModalError(true);
                setOpenModalSuccess(false);
            }
        }
    };

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
        window.location.reload();
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
        window.location.reload();
    };

    const handleCloseModal = () => {
        setOpenEditModal(false);
    };

    const handlePage = (pageParams: GridPageChangeParams)=>{
        setInitPage(1)
    }

    return (
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid autoHeight className="data-grid-list" disableColumnMenu rows={rows} columns={columnsProfiles} pageSize={pageSize} pagination  density="compact" disableSelectionOnClick  onPageChange={(params:GridPageChangeParams)=>handlePage(params)}
                components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                        Footer:(props)=>CustomFooter(props, initPage, onChanhePageSize, pageSize)
                }}
                />
            </Grid>
            <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
            <LoadingLockPage load={promiseInProgress}/>
        </Grid>
    );
}

const CustomFooter = (props: GridBaseComponentProps, initPage:number, onChanhePageSize?: any, valueRows?: number)=> {
    const { state, api } = props;
    const rowsPerPageOptions = [5,10,25,50,100];

    useEffect(()=>{
        if(initPage === 0){
            api.current.setPage(0)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[initPage]);

    return (
        <>
            <div className="footer-container">
                <div className="rows-number-container">
                    <span className="rows-number-text">Mostrar</span>
                    <Select
                        id="demo-simple-select"
                        value={valueRows}
                        onChange={onChanhePageSize}
                        input={<BootstrapInput/>}
                    >
                        {rowsPerPageOptions.map(item=>(
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                    <span className="rows-number-text">Registros</span>
                </div>
                <Pagination
                    className="pagination-container"
                    color="primary"
                    page={state.pagination.page+1}
                    count={state.pagination.pageCount}
                    onChange={(event, value) =>api.current.setPage(value-1)}
                    showFirstButton
                    showLastButton
                />
            </div>
        </>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large"/>
            <div className="not-results-text">Sin registros</div>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps)=><KeyboardArrowUpIcon fontSize="small"/>
const customSortDesc = (props: GridBaseComponentProps)=><KeyboardArrowDownIcon fontSize="small"/>

export default ProfilesList;