import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventIcon from '@material-ui/icons/Event';
import Typography from '@material-ui/core/Typography';
import { IGetNewEvent } from '../../../interfaces/IGetNewEvent';
import NewsAndEventsCard from '../NewsAndEventsCard';
import { IRegisterEvent } from '../../../interfaces/IRegisterEvent';

interface EventsListProps{
    data: Array<IGetNewEvent>;
}

const EventsList: React.FC<EventsListProps> = ({data}) => {
    const [url, setUrl] = useState<string | null>(null);
    const[dataAjusted, detDataAdjusted] = useState<Array<IRegisterEvent>>([]);

    const handleSeeEvent = (e: any, idEvent: number)=>{
        setUrl(`/eventos/${idEvent}`);
    }

    useEffect(()=>{
        const newdata: Array<IRegisterEvent> = data.map(obj => new IRegisterEvent(obj));
        detDataAdjusted(newdata);
    },[data])


    return(
        <>
            {
                url !== null ?
                <Redirect to={url} />
            :
                <>
                    {
                        dataAjusted.length>0 &&
                        dataAjusted.map((event: IRegisterEvent)=>(
                            <NewsAndEventsCard
                                key={event.id}
                                borderColor="#D7D7D7"
                                bgColor="#F9F9F9"
                                marginDef="10px 0"
                                icon={<LocationOnIcon/>}
                                secondIcon={<EventIcon/>}
                                type={event.type}
                                highlight ={event.highlight}
                                image = {event.image}
                                name = {event.name}
                                shortDescription = {event.shortDescription}
                                publishDate={event.publishDate}
                                dateStart= {moment(event.dateStart).locale('es').format("dddd, DD MMMM YYYY")}
                                timeStart= {moment(event.timeStart).locale('es').format("hh:mm a")}
                                dateFinish= {moment(event.dateFinish).locale('es').format("dddd, DD MMMM YYYY")}
                                timeFinish= {moment(event.timeFinish).locale('es').format("hh:mm a")}
                                place= {event.place}
                                state= {event.state}
                            >
                                <Button variant="contained" className="content-btn" onClick={(e)=>handleSeeEvent(e, event.id)}>
                                    <Typography variant="h5" className="regular-serif">
                                        Ver <span className="btn-type-text">{event.type}</span>
                                    </Typography>
                                </Button>
                            </NewsAndEventsCard>
                        ))
                    }
                </>
            }
        </>
    )
}

export default EventsList