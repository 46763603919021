import React, {useEffect} from 'react';
import Button from "@material-ui/core/Button";
import withStyles from '@material-ui/core/styles/withStyles';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RegisterContext from '../../../utils/RegisterContext';
import AccordionHeader from '../shareStepper/AccordionHeader';
import PersonalInfo from '../StepperForms/PersonalInfo';
import AcademicInfo from '../StepperForms/AcademicInfo';
import LaboralInfo from '../StepperForms/LaboralInfo';
import '../Stepper.scss';

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    marginBottom: '10px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const FirstStep: React.FC = () => {
  const registerContext = React.useContext(RegisterContext);
  const isValid =
  registerContext.firstAccorDone &&
  registerContext.secondAccorDone &&
  registerContext.thirdAccorDone

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);

  useEffect(()=>{
    if(isValid){
      registerContext.setStep1Completed(true);
    } else {
      registerContext.setStep1Completed(false);
    }
  });

  return (
    <>
      <div style={{width: '100%'}}>
        <Accordion square expanded={registerContext.expanded === 'panel1'} onChange={registerContext.handleChangeAccordion('panel1')}>
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            className={registerContext.firstAccorDone ? "accordion-summary accordion-done-graduate" : "accordion-summary accordion-edit-graduate"}
          >
            <AccordionHeader
              title="Información personal"
              description="Ingrese su información personal. Los campos marcados con asterisco (*) son de obligatorio diligenciamiento para completar el proceso de registro."
              accordionBox={registerContext.firstAccorDone ? true : false}
              panel="panel1"
              colorItem="#7DA52D"
            />
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <PersonalInfo/>
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={registerContext.expanded === 'panel2'} onChange={registerContext.handleChangeAccordion('panel2')}>
          <AccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            className={registerContext.secondAccorDone ? "accordion-summary accordion-done-graduate" : "accordion-summary accordion-edit-graduate"}
          >
            <AccordionHeader
              title="Información académica"
              description="Ingrese su información académica. Los campos marcados con asterisco (*) son de obligatorio diligenciamiento para completar el proceso de registro."
              accordionBox={registerContext.secondAccorDone ? true : false}
              panel="panel2"
              colorItem="#7DA52D"
            />
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <AcademicInfo/>
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={registerContext.expanded === 'panel3'} onChange={registerContext.handleChangeAccordion('panel3')}>
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          className={registerContext.thirdAccorDone ? "accordion-summary accordion-done-graduate" : "accordion-summary accordion-edit-graduate"}
          >
            <AccordionHeader
              title="Información laboral"
              description="Ingrese su información laboral. Los campos marcados con asterisco (*) son de obligatorio diligenciamiento para completar el proceso de registro."
              accordionBox={registerContext.thirdAccorDone ? true : false}
              panel="panel3"
              colorItem="#7DA52D"
            />
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <LaboralInfo/>
          </AccordionDetails>
        </Accordion>
        <div className="step-container-btn">
          <Button disabled={!isValid} variant="contained" endIcon={<NavigateNextIcon/>} className="step-btn" onClick={isValid ? registerContext.handleNext : null}>
            SIGUIENTE
          </Button>
        </div>
      </div>
    </>
  )
}

export default FirstStep