import React, { useState, useEffect } from "react";
import moment from "moment";
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ReCAPTCHA from "react-google-recaptcha";
import RegisterContext from "../../../utils/RegisterContext"
import { IRegisterGraduate } from '../../../interfaces/IRegisterGraduate';
import { IFile } from '../../../interfaces/IFile';
import { IRegisterGraduateData } from '../../../interfaces/IRegisterGraduateData';
import { registerGraduate } from "../../../services/AuthService";
import { uploadFileSignedUrl } from "../../../services/s3Service";
import SubPreview from "../../ShareComponents/SubPreview";
import SectionPreview from "../../ShareComponents/SectionPreview";
import LoadingLockPage from "../../ShareComponents/LoadingLockPage";
import ErrorModal from "../../ShareComponents/ErrorModal";

import '../Stepper.scss';



const ThirdStep: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker();
  const registerContext = React.useContext(RegisterContext);
  const SIEkey = process.env.REACT_APP_SIE_SITE_KEY_RECAPTCHA;
  let recaptchaRef: ReCAPTCHA | null;
  const formValues: IRegisterGraduate = registerContext.formValues;
  const [disabledSend, setDisableSend]= useState(false);
  const [dateGraduation, setDateGraduation] = useState<string>('');
  const [genderLabel, setGenderLabel] = useState<string>('');
  const [ethnicLabel, setEthnicLabel] = useState<string>('');
  const [docTypeLabel, setDocTypeLabel] = useState<string>('');
  const [campusName, setCampusName] = useState<string>('');
  const [schoolName, setSchoolName] = useState<string>('');
  const [programName, setProgramName] = useState<string>('');
  const [academicName, setAcademicName] = useState<string>('');
  const [situationName, setSituationName] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [sectorName, setSectorName] = useState<string>('');
  const [registerError, setRegisterError]= useState('');
  const [openModalError, setOpenModalError] = React.useState(false);
  const widthResolution = window.innerWidth;

  useEffect(()=>{
    window.scrollTo(0, 0);
    registerContext.setFormValues((prev:IRegisterGraduate) => ({
      ...prev,
      captchaVerification: false
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(formValues.captchaVerification && registerContext.step1Completed && formValues.termsOfUse){
      setDisableSend(true)
    } else {
      setDisableSend(false)
    }
  },[registerContext, formValues])

  useEffect(()=>{
    const getData = async()=>{
      //  get date format
      if(formValues.date !== null){
        const dateLabel = moment(formValues.date).format('MMMM-YYYY')
        setDateGraduation(dateLabel)
      }
      //get gender name
      if(registerContext.gendersType && registerContext.gendersType.length>0){
        const elemGender = registerContext.gendersType.find(item => item.id === formValues.gender);
        if(elemGender){
          setGenderLabel(elemGender.name);
        }
      }
      // get ethnic name
      if(registerContext.ethnicGroup && registerContext.ethnicGroup.length>0){
        const elemEthnic = registerContext.ethnicGroup.find(item => item.parameter_code === formValues.ethnicgroup);
        if(elemEthnic){
          setEthnicLabel(elemEthnic.name);
        }
      }
      //  get doctype name
      if(registerContext.documentsType && registerContext.documentsType.length>0){
        const elemDocType = registerContext.documentsType.find(item => item.parameter_code === formValues.documentType);
        if(elemDocType){
          setDocTypeLabel(elemDocType.name);
        }
      }
      //  get campus name
      if(registerContext.allCampus && registerContext.allCampus.length>0){
        const elemCampus = registerContext.allCampus.find(item => item.id === formValues.campus);
        if(elemCampus){
          // setCampusName(elemCampus.name);
          setCampusName(elemCampus.parentName);
        }
      }
      //  get school name
      if(registerContext.schools  && registerContext.schools.length>0){
        const elemSchool = registerContext.schools.find(item => item.parameter_code === formValues.school);
        if(elemSchool){
          setSchoolName(elemSchool.name);
        }
      }
      //  get curriculum name
      if(registerContext.programs && registerContext.programs.length>0){
        const elemProgram = registerContext.programs.find(item => item.parameter_code === formValues.curriculum);
        if(elemProgram){
          setProgramName(elemProgram.name);
        }
      }
      //  get academic name
      if(registerContext.allAcademic && registerContext.allAcademic.length>0){
        const elemAcademic = registerContext.allAcademic.find(item => item.parameter_code === formValues.academicLevel);
        if(elemAcademic){
          setAcademicName(elemAcademic.name);
        }
      }
      //  get employ situation name
      if(registerContext.allEmploySituation && registerContext.allEmploySituation.length>0){
        const elemEmploy = registerContext.allEmploySituation.find(item => item.parameter_code === formValues.currentJobSituation);
        if(elemEmploy){
          setSituationName(elemEmploy.name);
        }
      }
      //  get company name
      if(registerContext.allCompanyType && registerContext.allCompanyType.length>0){
        const elemCompany = registerContext.allCompanyType.find(item => item.parameter_code === formValues.typeOfCompany);
        if(elemCompany){
          setCompanyName(elemCompany.name);
        }
      }
      //  get laboral sector name
      if(registerContext.allLaboralSector && registerContext.allLaboralSector.length>0){
        const elemSector = registerContext.allLaboralSector.find(item => item.parameter_code === formValues.laboralSector);
        if(elemSector){
          setSectorName(elemSector.name);
        }
      }
    }
    getData();
  },[registerContext, formValues])

  const resetRecaptcha = () => {
    recaptchaRef?.reset();
    registerContext.setFormValues((prev:IRegisterGraduate) => ({
      ...prev,
      captchaVerification: false
    }))
  };

  const onChange =(response:any)=> {
    if(response){
      registerContext.setFormValues((prev:IRegisterGraduate) => ({
        ...prev,
        captchaVerification: true
      }))
    } else {
      registerContext.setFormValues((prev:IRegisterGraduate) => ({
        ...prev,
        captchaVerification: false
      }))
    }
  }

  const handleSend = async()=>{
    const dataToSend: IRegisterGraduateData = new IRegisterGraduateData(formValues);
    let uploadDone:Array<boolean> = [];
    if(formValues.signedUrllArr.length>0 && formValues.files.length>0){
      for(let i = 0; i< formValues.signedUrllArr.length ; i++){
        const urlPresigned = formValues.signedUrllArr[i].uploadURL;
        const file = formValues.files[i].file;
        if(file){
          const result:Response = await trackPromise(uploadFileSignedUrl(urlPresigned, file));
          if(result){
            uploadDone.push(true)
          } else {
            uploadDone.push(false)
          }
        }
      }
    }
    if(uploadDone.includes(false)){
        setRegisterError('Hubo un error subiendo los archivos');
        setOpenModalError(true);
    } else {
      const responseRC:Response = await trackPromise(registerGraduate(dataToSend));
      if(responseRC.status === 200){
          registerContext.changeStep(3)
          setRegisterError('');
          setOpenModalError(false);
      } else if (responseRC.status === 400){
        const result = await responseRC.json();
        setRegisterError(result.message);
        setOpenModalError(true);
      } else {
          setRegisterError('Hubo un error creando el registro');
          setOpenModalError(true);
      }
    }
  }

  const handleCloseModalError = ()=>{
    setOpenModalError(false);
  }

  return (
    <>
      <Grid container className="third-step-container">

        <Grid container item xs={12} className="third-step-content">
          <Typography variant="h2" className="third-title-graduate">
            3.CONFIRMACIÓN DE REGISTRO
          </Typography>
          <Typography variant="h5" className="summary-description">Por favor verifique la información suministrada antes de enviar el formulario de registro.</Typography>
          <SubPreview title="Datos personales del egresado" step={0} panel="panel1" classEdit="preview-edit-btn-graduate"/>
          <Grid container item xs={12}>
            <SectionPreview cols={3} firstTitle="Nombre" firtsValue={formValues.names} secondTitle="Apellido" secondValue={formValues.surname} thirdTitle="Segundo apellido" thirdValue={formValues.secondsurname}/>
            <SectionPreview cols={2} firstTitle="Género" firtsValue={genderLabel} secondTitle="Grupo étnico" secondValue={ethnicLabel}/>
            <SectionPreview cols={3} firstTitle="Número celular" firtsValue={formValues.cellphone+""} secondTitle="Número telefónico" secondValue={formValues.phone+""} thirdTitle="Dirección de residencia" thirdValue={formValues.address}/>
            <SectionPreview cols={2} firstTitle="Documento de identidad" firtsValue={docTypeLabel + '-' + formValues.doc} secondTitle="Correo electrónico" secondValue={formValues.email}/>
          </Grid>
          <SubPreview title="Datos académicos del egresado" step={0} panel="panel2" classEdit="preview-edit-btn-graduate"/>
          <Grid container item xs={12}>
            <SectionPreview cols={3} firstTitle="Sede" firtsValue={campusName} secondTitle="Facultad" secondValue={schoolName} thirdTitle="Programa" thirdValue={programName}/>
            <SectionPreview cols={1} firstTitle="Nivel educativo" firtsValue={academicName}/>
            <SectionPreview cols={2} firstTitle="Fecha de graduación" firtsValue={dateGraduation} secondTitle="Número de diploma" secondValue={formValues.diplomaNumber}/>
          </Grid>
          <SubPreview title="Datos laborales del egresado" step={0} panel="panel3" classEdit="preview-edit-btn-graduate"/>
          <Grid container item xs={12}>
            <SectionPreview cols={1} firstTitle="Situación laboral actual" firtsValue={situationName}/>
            <SectionPreview cols={2} firstTitle="Tipo de empresa" firtsValue={companyName} secondTitle="Sector laboral" secondValue={sectorName}/>
          </Grid>
          <SubPreview title="Documentos adjuntos" step={1} classEdit="preview-edit-btn-graduate"/>
          <Grid container item xs={12} justify="center">
            {formValues.files.map((file:IFile, index)=>(
              <Grid container item xs={11} md={3} key={index} className="card-container">
                <div className="card-header-container">
                    <Typography variant="h5" className="card-title-item">
                      {file.file?.name}
                    </Typography>
                </div>
                <Grid container item xs={12} className="card-desc-container">
                  <Typography variant="h6" className="card-desc-item">
                    {file.description !== '' ? file.description : 'sin descripción'}
                  </Typography>
                </Grid>
                <Grid container item xs={12} justify="flex-end" className="card-action">
                  <div className="card-action-btn">
                    <Link target="_blank" rel="noopener" href={file.previewUrl}>
                      <Typography align="center" variant="h6" className="regular-serif">
                        Ver documento
                      </Typography>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="recaptcha-container">
        <div className="recaptcha-widget">
          <ReCAPTCHA
            sitekey={SIEkey ? SIEkey : ''}
            onChange={onChange}
            ref={e => recaptchaRef = e}
            size={widthResolution >= 540 ? "normal" : "compact"}
          />
            <Button variant="contained" className="reset-recaptcha-btn" startIcon={<RotateLeftIcon />} onClick={resetRecaptcha}>
            REINTENTAR
          </Button>
        </div>
      </Grid>

      <div className="step-buttons">
        <div className="step-container-btn">
          <Button variant="contained" className="step-back" startIcon={<NavigateBeforeIcon />} onClick={registerContext.handleBack}>
            ATRAS
          </Button>
        </div>
        <div className="step-container-btn">
          <Button variant="contained" disabled={!disabledSend} className="step-btn" onClick={handleSend}>
            ENVIAR
          </Button>
        </div>
      </div>
      <ErrorModal errorMsg={registerError} handleCloseModal={handleCloseModalError} openModalError={openModalError}/>
      <LoadingLockPage load={promiseInProgress}/>
    </>
  )
}

export default ThirdStep
