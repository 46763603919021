import React from 'react';
import { Redirect } from 'react-router';
import ParamaterSettings from '../../../../components/ParameterSettings';
import Grid from '@material-ui/core/Grid';
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import { isLogin } from '../../../../services/AuthService';
import UserContext from '../../../../utils/UserContext';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';


const ParamaterManagament = () => {
  const userContext = React.useContext(UserContext);


  return (
    <>
      {isLogin() ?
        <>
          <Grid container item xs={12} className="back-offers-list-section">
              <ButtonBackListOffers url="/admin/dashboard/" text="Ir al dashboard"/>
          </Grid>
          {userContext.roleSelected === 5 ?
            <ParamaterSettings />
          :
            <NotAuthorized/>
          }
        </>
      :
        <Redirect to ="/"/>
      }
    </>
  )
}

export default ParamaterManagament
