import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { GridCellParams, GridColDef, GridColParams } from '@material-ui/data-grid';
import ConfirmActionBox from '../ConfirmActionBox';
import DataTableAction from '../DataTableAction';
import { ICompanyTable } from '../../../interfaces/ICompanyTable';
import { CompanyStateObject } from '../../../interfaces/CompanyStateObject';
import { changeStateCompany } from '../../../services/ChangeStateService';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import SelectRejectCompany from '../SelectRejectCompany';
import ModalConfirmedAction from '../../ShareComponents/ModalConfirmedAction';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import ErrorModal from '../../ShareComponents/ErrorModal';
import { ICompanyFilterForm } from '../../../interfaces/ICompanyFilterForm';
import CompanyManagement, { CompanyStateTosendObj } from '../../../pages/dashboard/admin/CompanyManagement/index';
import UserContext from '../../../utils/UserContext';


interface CompanyChangeActionProps{
    companiesObject: Array<ICompanyTable>;
    companiesStateToSend: CompanyStateTosendObj;
    formValues: ICompanyFilterForm;
    formErrors: any;
    onChangeForm: any;
    allLaboralSector: Array<IGeneralParameter>
    cityArr: Array<IGeneralParameter>;
}

const CompanyChangeAction: React.FC<CompanyChangeActionProps> = ({companiesObject, companiesStateToSend, formValues, formErrors, onChangeForm, allLaboralSector, cityArr })=>{
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
    const [cancelActive, setCancelActive] = useState<boolean>(false);
    const [stateChanged, setStateChanged] = useState<boolean>(false);
    const [serviceError, setServiceError]= useState('');
    const [openModalError, setOpenModalError] = React.useState(false);

    const columnsCompanyAction: GridColDef[] = [
        { field: 'name', headerName: 'Razón social', width: 280, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Razón social'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'laboralSector', headerName: 'Sector Laboral', width: 290, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    Sector Laboral
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) =>{
            if(allLaboralSector.length>0){
                const sectorObj = allLaboralSector.find(sector => sector.parameter_code === params.value);
                if(sectorObj){
                    return(
                        <span className="curriculum-cell">{sectorObj.name}</span>
                    )
                }
            }
            return(
                <span></span>
            )
          }
        },
        { field: 'fullNit', headerName: 'Nit-Código de verificación', width: 200, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-break">
                Nit-Código<br/> de verificación
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <span className="doc-cell">{params.value}</span>
        ) },
        { field: 'state', headerName: 'Estado', width: 170, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Estado'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <strong>
                {params.value === 'Activo' && <div className="active-state state-button-cell">Activo</div>}
                {params.value === 'Inscrito' && <div className="signed-up-state state-button-cell">Inscrito</div>}
                {params.value === 'Inactivo' && <div className="inactive-state state-button-cell">Inactivo</div>}
                {params.value === 'Rechazado' && <div className="rejected-state state-button-cell">Rechazado</div>}
            </strong>
        ) },
        { field: 'city', headerName: 'Municipio', width: 180, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Municipio'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              if(cityArr.length>0){
                const cityObj = cityArr.find(city => city.parameter_code === params.value);
                if(cityObj){
                    return(
                        <span className="curriculum-cell">{cityObj.name}</span>
                    )
                }
              }
              return(
                <span></span>
              )
            }
        },
    ];

    const isValid =
    companiesStateToSend.companies.length>0 &&
    (
        companiesStateToSend.companies[0].state === "Inscrito" ||
        companiesStateToSend.companies[0].state === "Activo" ||
        companiesStateToSend.companies[0].state === "Inactivo" ||
        (companiesStateToSend.companies[0].state === "Rechazado" &&
            (
                (formValues.observation>0 && formValues.observation<3) ||
                (   formValues.observation === 3 &&
                    formValues.otherObservation.length>0 &&
                    !formErrors.otherObservation
                )
            )
        )
    );

    const cancelClick = ()=>{
        setCancelActive(true)
    };

    const sendChangeState = async()=>{
        companiesStateToSend.companies.forEach((company: CompanyStateObject)=>{
            if(formValues.observation === 0){
                company.observation = "";
                company.otherObservation = ""
            } else if (formValues.observation>0 && formValues.observation <3){
                company.observation = formValues.observation+"";
                company.otherObservation = ""
            } else {
                company.observation = formValues.observation+"";
                company.otherObservation = formValues.otherObservation;
            }
        });
        const responseCSCompany: Response = await trackPromise(changeStateCompany(
            userToken,
            {...companiesStateToSend, modifyUser: userContext.userId}
        ));
        if(responseCSCompany.status === 200){
            const resultCSCompany = await responseCSCompany.json();
            if(companiesStateToSend.companies.length>1){
                const checkChanged = resultCSCompany.messages.filter((respUser: any) =>{
                    let validator: boolean = false;
                    if(respUser.State === "Issue"){
                        validator = true;
                    } else{
                        validator = false;
                    }
                    return validator && respUser
                });
                if(checkChanged.length>0){
                    const usersError = checkChanged.map((user: any)=>user.User);
                    setStateChanged(false);
                    setServiceError(`Ocurrio un error cambiando de estado a los siguientes usuarios: ${usersError.toString()}`);
                    setOpenModalError(true);
                } else {
                    setStateChanged(true);
                    setServiceError('');
                    setOpenModalError(false);
                }
            } else {
                if(resultCSCompany.messages[0].State === "Issue"){
                    setStateChanged(false);
                    setServiceError(`Ocurrio un error cambiando el estado del usuario: ${resultCSCompany.messages[0].User.toString()} ${resultCSCompany.messages[0].Observation}`);
                    setOpenModalError(true);
                } else {
                    setStateChanged(true);
                    setServiceError('');
                    setOpenModalError(false);
                }
            }
        } else {
            setStateChanged(false);
            setServiceError('Hubo un error, intente de nuevo mas tarde')
            setOpenModalError(true);
        }
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
    };

    const handleClose = () => {
        setCancelActive(true);
        setStateChanged(false);
    };

    return(
        <>
            {   cancelActive ?
                <CompanyManagement/>
            :
                <Grid container className="main-container">
                    <div className="graduate-change-action-container">
                        <Grid container item xs={12}>
                            <Typography variant="h1" className="change-action-main-title">
                                Ejecutar acción {companiesStateToSend.companies.length> 1  ?  " masiva" : " individual"}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Typography variant="h5" className="tab-description">
                                Antes de ejecutar la acción revise la tabla de datos que se muestra a continuación y la acción seleccionada:
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Typography variant="h4" className="table-results">
                                Empresas seleccionadas {companiesObject.length}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <DataTableAction rows={companiesObject} columns={columnsCompanyAction}/>
                        </Grid>
                        {
                            companiesStateToSend.companies.length && companiesStateToSend.companies[0].state === "Rechazado" &&
                            <Grid container item xs={12}>
                                <SelectRejectCompany formValues={formValues} formErrors={formErrors} onChangeForm = {onChangeForm}/>
                            </Grid>
                        }
                        <Grid container item xs={12} justify="center">
                            <ConfirmActionBox users={companiesStateToSend.companies} state={companiesStateToSend.companies[0].state} cancelClick={cancelClick} sendChangeState={sendChangeState} isValid={isValid}/>
                        </Grid>
                    </div>
                    <ModalConfirmedAction
                        open={stateChanged}
                        handleClose={handleClose}
                        icon={<CheckCircleOutlineIcon style={{color: '#7DA52D', fontSize:'50px'}}/>}
                        description="Acción ejecutada exitosamente"
                    />
                    <ErrorModal errorMsg={serviceError} handleCloseModal={handleCloseModalError} openModalError={openModalError}/>
                </Grid>
            }
            <LoadingLockPage load={promiseInProgress}/>
        </>
    )
}

export default CompanyChangeAction