import React from 'react';
import './App.scss';
// import { BrowserRouter as Router } from "react-router-dom";
import Routes from './routes';
import customTheme from './themeConfig';
import {ThemeProvider} from '@material-ui/core/styles';
import { HashRouter } from 'react-router-dom'

const App: React.FC  = ()=>{
  return(
    <ThemeProvider theme={customTheme}>
      <HashRouter>
        <Routes/>
      </HashRouter>
    </ThemeProvider>
  )
}

export default App;
