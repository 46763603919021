import React, { useState, useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { usePromiseTracker } from 'react-promise-tracker';
import AddIcon from '@material-ui/icons/Add';
import WarningIcon from "@material-ui/icons/Warning";
import UserContext from '../../../../utils/UserContext';
import { isLogin } from '../../../../services/AuthService';
import NewsEventsFilters from '../../../../components/ManagementState/NewsEventsFilters';
import SearchTable from '../../../../components/ManagementState/SearchTable';
import Validations from '../../../../helpers/Validations';
import NewsEventsTable from '../../../../components/NewsAndEvents/NewsEventsTable';
import {getEventsNews, updateEventsNews} from '../../../../services/NewsEventsService';
import { IGetNewEvent } from '../../../../interfaces/IGetNewEvent';
import { StateByRole } from '../../../../interfaces/StateByRole';
import { getCATEGORY } from '../../../../services/ParameterService';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import { IGeneralParameter } from '../../../../interfaces/IGeneralParameter';
import { containsAny, sortByKey, sortByKeyDesc } from '../../../../helpers/sharedFunctions';
import ModalOffersAction from '../../../../components/LaboralOffers/ModalOffersAction';
import SuccessModal from '../../../../components/ShareComponents/SuccessModal';
import ErrorModal from '../../../../components/ShareComponents/ErrorModal';
import { IFilterEvents } from '../../../../interfaces/IFilterEvents';
import ShowFiltersApplied from '../../../../components/ManagementState/ShowFiltersApplied';
import { ChipData } from '../../../../interfaces/ChipData';
import { permissionAdminCreateNewEvent, permissionsNewsView } from '../../../../utils/PermissionsVariables';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';

import './NewsEventsManagement.scss';


const stateOptions: Array<StateByRole> = [
    {id:1, label: 'Todos', value:'Todos', description: '', permission:[] },
    {id:2, label: 'Publicado', value:'Publicado', description: '', permission:[] },
    {id:3, label: 'Pendiente de publicación', value:'Pendiente de publicación', description: '', permission:[]},
    {id:4, label: 'Finalizado', value:'Finalizado', description: '', permission:[] },
];
const typeOptions: Array<StateByRole> = [
  {id:1, label: 'Todos', value:'Todos', description: '', permission:[] },
  {id:2, label: 'Noticias', value:'Noticia', description: '', permission:[] },
  {id:3, label: 'Eventos', value:'Evento', description: '', permission:[] },
]

const fieldsValidation = {
    searched: {
      error: "",
      validate: "",
      required: true
    }
};
const initialErrorsSearch={
    searched:''
}

const NewsEventsManagement: React.FC = () => {
  const userContext = React.useContext(UserContext);
  const { promiseInProgress } = usePromiseTracker();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [initPage, setInitPage] = useState<number>(0);
  const [searched, setSearched] = useState('');
  const [formErrorSearch, setFormErrorSearch] = useState(initialErrorsSearch);
  const [searchActive, setSearchActive] = useState(false);
  const [data, setData] = useState<Array<IGetNewEvent>>([]);
  const [dataFiltered, setDataFiltered] = useState<Array<IGetNewEvent>>([]);
  const [chipData, setChipData] = useState<ChipData[]>([]);
  const [stateToChange, setStateToChange] = useState<string>("");
  const [idSelected, setIdSelected] = useState<number | null>(null);
  const [openChangeModal, setOpenChangeModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage]= useState<string>('');
  const [openModalError, setOpenModalError] = useState<boolean>(false);
  const [successMessage, setSuccessMessage]= useState<string>('');
  const [openModalSuccess, setOpenModalSuccess] = useState<boolean>(false);
  const [updateReady, setUpdateReady] = useState<string>('');
  const [filterValues, setFilterValues] = useState<IFilterEvents>(new IFilterEvents());
  const [categoryArray, setCategoryArray] = useState<Array<IGeneralParameter>>([]);
  const [newsSelected, setNewsSelected] = useState<IGetNewEvent>(new IGetNewEvent());
  const [initLoad, setInitLoad] = useState<boolean>(true);

  useEffect(()=>{
    window.scrollTo(0, 0);
    const isEvent = sessionStorage.getItem('eventAnonimous');
    if(isEvent){
      sessionStorage.removeItem('eventAnonimous');
    }
    const isSurvey = sessionStorage.getItem('surveyAnonimous');
    if(isSurvey){
      sessionStorage.removeItem('surveyAnonimous');
    }
    const getInitialdata = async() => {
      let responseCategory:Array<IGeneralParameter> = await getCATEGORY();
      responseCategory = sortByKey(responseCategory, 'name');
      setCategoryArray(responseCategory);
    }
    getInitialdata();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleUpdate = (value: string, newsSelected: IGetNewEvent) => {
    setNewsSelected(newsSelected)
    setUpdateReady(value)
  }

  const onChanhePageSize = (e: any)=>{
    setRowsPerPage(e.target.value)
  }

  const applyFilters = ()=>{
    setChips('apply');
    filterData(searched);
  }

  const filterData = (keyWord: string) => {
    let resultTypes: Array<IGetNewEvent> = [];
    let resultState: Array<IGetNewEvent> = [];
    let resultCategory: Array<IGetNewEvent> = [];
    let resultKeyword: Array<IGetNewEvent> = [];
    if(filterValues.type.length>0){
      if(filterValues.type === "Todos"){
        resultTypes = data;
      } else{
        resultTypes = data.filter(item=>item.type === filterValues.type)
      }
    } else {
      resultTypes = data;
    }
    if(filterValues.state.length>0){
      if(filterValues.state === "Todos"){
        resultState = resultTypes;
      } else {
        resultState = resultTypes.filter(item=>item.state === filterValues.state);
      }
    }else {
      resultState = resultTypes;
    }
    if(filterValues.category > 0){
      resultCategory = resultState.filter(item=>item.category === filterValues.category);
    } else {
      resultCategory = resultState;
    }
    if(keyWord.length>0){
      resultKeyword = resultCategory.filter(item=>item.name.includes(keyWord));
    } else{
      resultKeyword = resultCategory;
    }
    setInitPage(0);
    setDataFiltered(resultKeyword);
  }

  const requestSearch = (name: string, searchVal:string)=>{
    const error: string = searchVal !== '' ? Validations(name, searchVal, fieldsValidation) || "" : '';
    setFormErrorSearch(prev=>({
      ...prev,
      [name]: error
    }));
    setSearched(searchVal);
    if(searchVal !== ''){
      setSearchActive(true);
      filterData(searchVal)
    } else {
      setSearchActive(false);
      filterData('');
    }
  }

  const cancelSearch = ()=>{
    setSearched('')
    requestSearch('searched', '');
    setSearchActive(false)
  }

  const clearFilters = () =>{
    setFilterValues(prev => ({
      ...prev,
      type: "Todos",
      state: "Todos",
      category: 0
    }));
    setChips('clear');
    setSearched("");
    setDataFiltered(data);
  }

  const setChips = (action: string) =>{
    let tempArray: Array<ChipData> = [];
    let index = 0;
    const fieldNames=[
      {key: 'type', description: 'Tipo'},
      {key: 'state', description: 'Estado'},
      {key: 'category', description: 'Categoria'},
    ];
    if(action === "clear"){
        setChipData([]);
    } else {
      for (const [key, value] of Object.entries(filterValues)) {
        if(value!==0 && value!=='' && value!==null ){
          let obj;
          const fieldObj = fieldNames.find(field=>field.key === key);
          if (key === 'category'){
            obj = categoryArray.find(category=>category.parameter_code === value);
          }
          if(obj){
            tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + obj.name })
          } else {
            tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + value})
          }
          index++;
        }
      }
      setChipData(tempArray);
    }
  }

  useEffect(() => {
    const getListEventsNews = async() =>{
      const getInfo: Response = await getEventsNews({});
      if (getInfo.status === 200){
        let resultList = (await getInfo.json()).publishings;
        resultList = sortByKeyDesc(resultList, 'updatedAt');
        setData(resultList);
        setDataFiltered(resultList);
      }
      setInitLoad(false);
    }
    getListEventsNews();
  }, [])

  const changeStateModal = (e:any, selectedId: number, state: string)=>{
    setStateToChange(state);
    setOpenChangeModal(true);
    setIdSelected(selectedId);
  }

  const closeModalChange = () => {
    setOpenChangeModal(false);
  }

  const changeStateRequest = async() =>{
    setOpenChangeModal(false);
    const responseCSNE = await trackPromise(updateEventsNews(
      {id: idSelected, state: stateToChange, modifyUser: userContext.userId}
    ));
    validateModals(responseCSNE)
  }

  const validateModals = async(responseService: Response)=>{
    if(responseService.status === 200){
      setSuccessMessage('Acción exitosa');
      setOpenModalSuccess(true);
      setOpenModalError(false);
    } else {
      setErrorMessage("Hubo un error, intente de nuevo mas tarde");
      setOpenModalError(true);
      setOpenModalSuccess(false);
    }
  }

  const handleCloseModalSuccess = ()=>{
    setOpenModalSuccess(false);
    window.location.reload();
  };

  const handleCloseModalError = ()=>{
      setOpenModalError(false);
  };

  return (
    <>
      {isLogin() ?
        <>
        {containsAny(permissionsNewsView,userContext.userPermissions) ?
          <>
          {updateReady.length > 0 ?
            <Grid container >
              {
                updateReady === "See" &&
                <Redirect to={`/admin/dashboard/detalle-evento-noticia/${newsSelected.id}`}/>
              }
              {
                updateReady ==="Edit" &&
                <Redirect to={`/admin/dashboard/editar-evento-noticia/${newsSelected.id}`}/>
              }
            </Grid>
          :
            <>
              <Grid container className="main-container">
                {containsAny(permissionAdminCreateNewEvent,userContext.userPermissions) &&
                  <Grid container item xs={12} className="news-events-create-container">
                    <Button disabled={!containsAny(permissionAdminCreateNewEvent,userContext.userPermissions)} className="create-button" variant="contained" component ={ Link } to="/admin/dashboard/crear-evento-noticia" endIcon={<AddIcon />}>
                      <Typography variant="h6" className="regular-serif">
                        Adicionar nueva noticia o evento
                      </Typography>
                    </Button>
                  </Grid>
                }
                <Grid container item xs={12}>
                  <NewsEventsFilters title="Filtros noticias o eventos" typeOptions={typeOptions} stateOptions={stateOptions} categoryArray={categoryArray} filterValues={filterValues} setFilterValues={setFilterValues} applyFilters={applyFilters}/>
                </Grid>
                <Grid container item xs={12}>
                  <ShowFiltersApplied chipData={chipData}/>
                </Grid>
                {chipData.length>0 &&
                  <Grid container item xs={12} className="show-filters-chips">
                    <Button className="clear-btn" onClick={clearFilters}>
                      <Typography variant="h4" className="regular-serif">Limpiar filtros</Typography>
                    </Button>
                  </Grid>
                }
              </Grid>

              <Grid container item xs={12} className="news-events-list-container">
                <Grid container className="main-container">
                  <Grid container className="list-table-container">
                    <Grid container item xs={12}>
                      <Typography variant="h2" className="title-news-events-table-management">Noticias y eventos </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                      <div className="header-table-container">
                        <div className="search-event-container">
                          <SearchTable textPlaceHolder="Buscar por palabra clave" title="" searched={searched} requestSearch={requestSearch} formErrorSearch={formErrorSearch} searchActive={searchActive} cancelSearch={cancelSearch}/>
                        </div>
                      </div>
                    </Grid>
                    <Grid container item xs={12} className="list-container">
                      <Grid container item xs={12} className="list-view">
                        <NewsEventsTable onChanhePageSize={onChanhePageSize} data={dataFiltered} pageSize={rowsPerPage} initPage={initPage} setInitPage={setInitPage} handleUpdate={handleUpdate} categoryArr={categoryArray} changeStateModal={changeStateModal}/>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <ModalOffersAction
                open={openChangeModal}
                handleClose={closeModalChange}
                handleContinue={changeStateRequest}
                iconModal={<WarningIcon className="big-warning-icon" />}
                title={`¿Está seguro de cambiar el estado a ${stateToChange}?`}
                headerdescription={`Esta intentando cambiar el estado de una noticia o evento a ${stateToChange}`}
                description=""
                backBtnText="Regresar"
                colorBtnBack= ""
                continueBtnText="Cambiar"
                colorBtnContinue="#f44336"
                valid={true}
              />
              <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
              <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
              <LoadingLockPage load={promiseInProgress}/>
            </>
          }
          </>
        :
          <NotAuthorized/>
        }
        <LoadingLockPage load={initLoad}/>
        </>
      :
        <Redirect to ="/"/>
      }
    </>
  )
}

export default NewsEventsManagement
