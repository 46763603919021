import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search';
import './SearchBox.scss';


interface SearchBoxProps{
    username: string;
    changeUsername: any;
    searchUserLdap: any;
}

const SearchBox:React.FC<SearchBoxProps> = ({ username, changeUsername, searchUserLdap }) =>{
    return(
        <Grid container item xs={12} className="search-box-container">
            <Grid item xs={9}>
                <Grid item xs={12} sm={10} className="field-box-margin special-box-left">
                  <Typography variant="h5" className="bold-serif-primaryColor">Nombre de usuario</Typography>
                  <TextField
                    fullWidth
                    name="username"
                    margin="none"
                    label={username === "" ? "Ingrese nombre de usuario": ""}
                    value={username}
                    onChange={changeUsername}
                    InputLabelProps={{shrink: false}}
                    variant="outlined"
                  />
                </Grid>
            </Grid>
            <Grid item xs={3} className="search-username-container">
                <Button disabled={username.length===0} variant="contained" className="search-username-btn" endIcon={<SearchIcon />} onClick={searchUserLdap} >
                    <Typography variant="h4" className="regular-serif">
                        Buscar
                    </Typography>
                  </Button>
            </Grid>
        </Grid>
    )
}

export default SearchBox