import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from "@material-ui/core/MenuItem";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import NotificationBox from '../../ShareComponents/NotificationBox';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import { makeStyles, createStyles, Theme, withStyles} from '@material-ui/core/styles';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import UserContext from '../../../utils/UserContext';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import Validations from '../../../helpers/Validations';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import { sortByKey } from '../../../helpers/sharedFunctions';
import { getUserAdditionalInfo, updateAdditionalInfo } from '../../../services/GraduateService';
import { IAdditionalInfoCV, IAssociation, IRecognition, IResearchProject, IStudyInterest, IVenture } from '../../../interfaces/IAdditionalInfoCV';
import { getTYPEOFRECOGNITION, tempGROUPCLASSIFICATION, getLABORAL_SECTOR, getVENTURE_STATE, getTYPE_OF_ASSOCIATION, getASSOCIATION, getENTITY_TYPE, getINSTITUTION, getGROUP_CLASSIFICATION } from '../../../services/ParameterService';

import '../GraduateCurriculum.scss';
import CircularProgressDet from '../../ShareComponents/CircularProgressDet';
import { IUserPercentage } from '../../../interfaces/IUserPercentage';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: theme.spacing(2, 0),
    },
  }),
);
const BlueSwitch = withStyles({
  switchBase: {
    color:'#ffffff',
    '&$checked': {
      color:'#004B73',
    },
    '&$checked + $track': {
      backgroundColor: '#004B73',
    },
  },
  checked: {},
  track: {},
})(Switch);

class InitialAdditionalErrors {
    // association
    typeOfAssociation: string =  "";
    association: string =  "";
    // recognition
    recognitionName: string =  "";
    typeOfRecognition: string =  "";
    recognitionDescription: string =  "";
    recognitionInstitution: string =  "";
    dateFormat: string = "";
    // study of interest
    area: string =  "";
    study: string =  "";
    // projects
    projectName: string =  "";
    role: string =  "";
    groupClassification: string =  "";
    startDateFormat: string =  "";
    endDateFormat: string =  "";
    entityDescription: string =  "";
    typeOfEntity: string =  "";
    projectInstitution: string =  "";
    // venture
    ventureName: string =  "";
    ventureSector: string =  "";
    ventureDescription: string =  "";
    ventureStartDateFormat: string =  "";
    ventureActualState: string =  "";
}

const formValidations = {
    typeOfAssociation: {
        error: "",
        validate: "select",
        required: false
    },
    association: {
        error: "",
        validate: "select",
        required: false
    },
    recognitionName: {
        error: "",
        validate: "textNumber",
        required: true
    },
    typeOfRecognition: {
        error: "",
        validate: "select",
        required: true
    },
    recognitionDescription: {
        error: "",
        validate: "textNumber",
        required: false
    },
    recognitionInstitution: {
        error: "",
        validate: "text",
        required: true
    },
    dateFormat: {
        error: "",
        validate: "normalDate",
        maxDate: new Date(),
        required: true
    },
    area: {
        error: "",
        validate: "text",
        required: true
    },
    study: {
        error: "",
        validate: "text",
        required: true
    },
    projectName: {
        error: "",
        validate: "textNumber",
        required: true
    },
    role: {
        error: "",
        validate: "textNumber",
        required: true
    },
    groupClassification: {
        error: "",
        validate: "textNumber",
        required: true
    },
    startDateFormat: {
        error: "",
        validate: "normalDate",
        maxDate: new Date(),
        required: true
    },
    endDateFormat: {
        error: "",
        validate: "normalDate",
        minDate: new Date(),
        required: true
    },
    entityDescription: {
        error: "",
        validate: "textNumber",
        maxLength: 200,
        required: false
    },
    typeOfEntity: {
        error: "",
        validate: "select",
        required: false
    },
    projectInstitution: {
        error: "",
        validate: "select",
        required: true
    },
    ventureName: {
        error: "",
        validate: "textNumber",
        required: true
    },
    ventureSector: {
        error: "",
        validate: "select",
        required: true
    },
    ventureDescription: {
        error: "",
        validate: "textNumber",
        maxLength: 200,
        required: false
    },
    ventureStartDateFormat: {
        error: "",
        validate: "normalDate",
        maxDate: new Date(),
        required: true
    },
    ventureActualState: {
        error: "",
        validate: "select",
        required: true
    },
}


interface PInfoProps {
    children?: any
    data?: IUserPercentage
}
const PercentageInfo: React.FC<PInfoProps> = ({children, data}) =>{
    const associationsData =  data?.aditionalData.missing.associations || [];
    const recognitionsData =  data?.aditionalData.missing.recognitions || [];
    const studiesData =  data?.aditionalData.missing.studiesInterests || [];
    const proyectsData =  data?.aditionalData.missing.proyects || [];
    const venturesData =  data?.aditionalData.missing.ventures || [];

    return(
        <>
            { associationsData.length > 0 &&
                <Typography variant="h6" className="regular-serif" style={{color: '#055160', width: '100%'}}>Seccion Asociaciónes
                <ul>
                {associationsData.map((item)=>{
                    return <li>{item}</li>
                })}
                </ul>
                </Typography>
            }
            { recognitionsData.length > 0 &&
                <Typography variant="h6" className="regular-serif" style={{color: '#055160', width: '100%'}}>Seccion Reconocimientos
                <ul>
                {recognitionsData.map((item)=>{
                    return <li>{item}</li>
                })}
                </ul>
                </Typography>
            }
            { studiesData.length > 0 &&
                <Typography variant="h6" className="regular-serif" style={{color: '#055160', width: '100%'}}>Seccion Estudios de Interes
                <ul>
                {studiesData.map((item)=>{
                    return <li>{item}</li>
                })}
                </ul>
                </Typography>
            }
            { proyectsData.length > 0 &&
                <Typography variant="h6" className="regular-serif" style={{color: '#055160', width: '100%'}}>Seccion Proyectos
                <ul>
                {proyectsData.map((item)=>{
                    return <li>{item}</li>
                })}
                </ul>
                </Typography>
            }
            { venturesData.length > 0 &&
                <Typography variant="h6" className="regular-serif" style={{color: '#055160', width: '100%'}}>Seccion Emprendimientos
                <ul>
                {venturesData.map((item)=>{
                    return <li>{item}</li>
                })}
                </ul>
                </Typography>
            }
        </>
        
    );
}

const AdditionalInformation: React.FC = () => {
    let params: any = useParams();
    const userContext = React.useContext(UserContext);
    const { promiseInProgress } = usePromiseTracker();
    const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [expanded, setExpanded] = useState<string | boolean>('panel1');
    const [formValues, setFormValues] = useState<IAdditionalInfoCV>(new IAdditionalInfoCV({}));
    const [associationForm, setAssociationForm] = useState<IAssociation>(new IAssociation());
    const [recognitionForm, setRecognitionForm] = useState<IRecognition>(new IRecognition());
    const [interestForm, setInterestForm] = useState<IStudyInterest>(new IStudyInterest());
    const [projectsForm, setProjectsForm] = useState<IResearchProject>(new IResearchProject());
    const [ventureForm, setVentureForm] = useState<IVenture>(new IVenture());
    const [formErrors, setFormErrors] = useState<InitialAdditionalErrors>(new InitialAdditionalErrors());
    // data
    const [associationTypeArray, setAssociationTypeArray] = useState<Array<IGeneralParameter>>([]);
    const [associationArray, setAssociationArray] = useState<Array<IGeneralParameter>>([]);
    const [recognitionTypeArray, setRecognitionTypeArray] = useState<Array<IGeneralParameter>>([]);
    const [entityArray, setEntityArray] = useState<Array<IGeneralParameter>>([]);
    const [institutionArray, setInstitutionArray] = useState<Array<IGeneralParameter>>([]);
    const [allLaboralSector, setAllLaboralSector] = useState<Array<IGeneralParameter>>([]);
    const [ventureStateArray, setVentureStateArray] = useState<Array<IGeneralParameter>>([]);
    // modals and errors
    const [errorMessage, setErrorMessage]= useState('');
    const [openModalError, setOpenModalError] = React.useState(false);
    const [successMessage, setSuccessMessage]= useState('');
    const [openModalSuccess, setOpenModalSuccess] = React.useState(false);

    const isValidAddAssociation =
        associationForm.typeOfAssociation > 0 &&
        !formErrors.typeOfAssociation &&
        associationForm.association>0 &&
        !formErrors.association

    const isValidAddRecognition =
        recognitionForm.name.length>0 &&
        !formErrors.recognitionName &&
        recognitionForm.typeOfRecognition>0 &&
        !formErrors.typeOfRecognition &&
        !formErrors.recognitionDescription &&
        recognitionForm.institution.length>0 &&
        !formErrors.recognitionInstitution &&
        moment(recognitionForm.dateFormat).isValid() &&
        !formErrors.dateFormat

    const isValidInterest =
        interestForm.area.length>0 &&
        !formErrors.area &&
        interestForm.study.length>0 &&
        !formErrors.study

    const isValidProject =
        projectsForm.name.length>0 &&
        !formErrors.projectName &&
        projectsForm.role.length>0 &&
        !formErrors.role &&
        projectsForm.groupClassification.length>0 &&
        !formErrors.groupClassification &&
        !formErrors.entityDescription &&
        moment(projectsForm.startDateFormat).isValid() &&
        !formErrors.startDateFormat &&
        moment(projectsForm.endDateFormat).isValid() &&
        !formErrors.endDateFormat &&
        !formErrors.typeOfEntity &&
        projectsForm.institution>0 &&
        !formErrors.projectInstitution

    const isValidVenture =
        ventureForm.name.length>0 &&
        !formErrors.ventureName &&
        ventureForm.sector>0 &&
        !formErrors.ventureSector &&
        !formErrors.ventureDescription &&
        moment(ventureForm.startDateFormat).isValid() &&
        !formErrors.ventureStartDateFormat &&
        ventureForm.actualState>0 &&
        !formErrors.ventureActualState

    const classes = useStyles();

    useEffect(()=>{
        const getInitialData = async()=>{
            window.scrollTo(0, 0);
            let resAssociationType: Array<IGeneralParameter> = await getTYPE_OF_ASSOCIATION();
            setAssociationTypeArray(resAssociationType);
            let resAssociation: Array<IGeneralParameter> = await getASSOCIATION();
            resAssociation = sortByKey(resAssociation, 'name');
            setAssociationArray(resAssociation);
            let resRecognition: Array<IGeneralParameter> = await getTYPEOFRECOGNITION();
            setRecognitionTypeArray(resRecognition);
            let resEntity: Array<IGeneralParameter> = await getENTITY_TYPE();
            setEntityArray(resEntity);
            let resInstitution: Array<IGeneralParameter> = await getINSTITUTION();
            setInstitutionArray(resInstitution);
            let responseLaboralSector: Array<IGeneralParameter> = await getLABORAL_SECTOR();
            responseLaboralSector = sortByKey(responseLaboralSector, 'name');
            setAllLaboralSector(responseLaboralSector);
            let responseVentureState: Array<IGeneralParameter> = await getVENTURE_STATE();
            setVentureStateArray(responseVentureState);
            if(userContext.userId){
                const responseUser = await getUserAdditionalInfo(userToken, parseInt(userContext.roleSelected === 1 ? userContext.userId : params.id));
                if(responseUser.status === 200){
                    const resultAdditionalUser: IAdditionalInfoCV = await responseUser.json();
                    const tempFormValues = new IAdditionalInfoCV(resultAdditionalUser);
                    tempFormValues.doc = userContext.roleSelected === 1 ? userContext.userId : params.id;
                    setFormValues(tempFormValues);
                }
            }
            else if(params.id){
                const responseUser = await getUserAdditionalInfo(userToken,params.id);
                if(responseUser.status === 200){
                    const resultAdditionalUser: IAdditionalInfoCV = await responseUser.json();
                    const tempFormValues = new IAdditionalInfoCV(resultAdditionalUser);
                    tempFormValues.doc = params.id;
                    setFormValues(tempFormValues);
                }
            }
            setInitLoad(false);
        }
        getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleChangeAccoridon = (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false);
    };

    const changeSpecialForm = (e: any, pattern: string, nameError: string) => {
        const { name, value } = e.target;
        if(pattern === "associations"){
            setAssociationForm(prev => ({
                ...prev,
                [name]: value
            }));
        } else if(pattern === "recognitions"){
            setRecognitionForm(prev => ({
                ...prev,
                [name]: value
            }));
        } else if (pattern === "interest"){
            setInterestForm(prev => ({
                ...prev,
                [name]: value
            }));
        } else if (pattern === "projects"){
            setProjectsForm(prev => ({
                ...prev,
                [name]: value
            }));
        } else if (pattern === "ventures"){
            setVentureForm(prev => ({
                ...prev,
                [name]: value
            }));
        }
        const error: string = Validations(nameError, value, formValidations) || "";
        setFormErrors((prev:any)=>({
          ...prev,
          [nameError]: error
        }));
    }

    const changeRecognitionDate = (dateValue: Date | null, nameField: string) => {
        const value =dateValue ? dateValue?.toString() : '';
        setRecognitionForm(prev => ({
            ...prev,
            [nameField]: value
        }));
        const error: string = Validations(nameField, value, formValidations) || "";
        setFormErrors((prev:any)=>({
          ...prev,
          [nameField]: error
        }));
    }

    const changeProjectDate = (dateValue: Date | null, nameField: string) => {
        const value =dateValue ? dateValue?.toString() : '';
        setProjectsForm(prev => ({
            ...prev,
            [nameField]:value
        }));
        if(nameField === "startDateFormat"){
            setProjectsForm(prev => ({
                ...prev,
                endDateFormat:null
            }));
        }
        if(nameField === "endDateFormat"){
            formValidations.endDateFormat.minDate = projectsForm.startDateFormat ? moment(new Date(projectsForm.startDateFormat)).toDate() : moment(new Date()).toDate();
        }
        const error: string = Validations(nameField, value, formValidations) || "";
        setFormErrors((prev:any)=>({
          ...prev,
          [nameField]: error
        }));
    }

    const changeVentureDate = (dateValue: Date | null, nameField: string, nameError: string) => {
        const value =dateValue ? dateValue?.toString() : '';
        setVentureForm(prev => ({
            ...prev,
            [nameField]:value
        }));
        const error: string = Validations(nameError, value, formValidations) || "";
        setFormErrors(prev=>({
          ...prev,
          [nameError]: error
        }));
    }

    const addAssociation = () =>{
        associationForm.id=formValues.associations.length+1;
        setFormValues(prev => ({
            ...prev,
            associations:[...prev.associations, associationForm]
        }));
        setAssociationForm(new IAssociation());
    }

    const deleteAssociation = (e:any, idToDelete: number | null) => {
        const newArrayAssociation = formValues.associations.filter(item=>item.id !== idToDelete);
        setFormValues(prev => ({
            ...prev,
            associations: newArrayAssociation
        }));
    }

    const addRecognition = () =>{
        recognitionForm.id=formValues.recognitions.length+1;
        recognitionForm.date = recognitionForm.dateFormat ? moment(new Date(recognitionForm.dateFormat+"")).format() : "";
        setFormValues(prev => ({
            ...prev,
            recognitions:[...prev.recognitions, recognitionForm]
        }));
        setRecognitionForm(new IRecognition());
    }

    const deleteRecognition = (e:any, idToDelete: number | null) => {
        const newArrayrecognitions = formValues.recognitions.filter(item=>item.id !== idToDelete);
        setFormValues(prev => ({
            ...prev,
            recognitions: newArrayrecognitions
        }));
    }

    const changeCheckBoxRecognition = (e: React.ChangeEvent<HTMLInputElement>, idRec:number | null) => {
        const recognitionsAdjusted = formValues.recognitions.map(recognition => {
            if(recognition.id === idRec){
                recognition.visible = !recognition.visible
            }
            return recognition;
        });
        setFormValues(prev => ({
            ...prev,
            recognitions: recognitionsAdjusted
        }));
    }

    const addInterest = () =>{
        interestForm.id=formValues.studiesOfInterest.length+1;
        setFormValues(prev => ({
            ...prev,
            studiesOfInterest:[...prev.studiesOfInterest, interestForm]
        }));
        setInterestForm(new IStudyInterest());
    }

    const deleteInterest = (e:any, idToDelete: number | null) => {
        const newArrayInterest = formValues.studiesOfInterest.filter(item=>item.id !== idToDelete);
        setFormValues(prev => ({
            ...prev,
            studiesOfInterest: newArrayInterest
        }));
    }

    const addProject = () =>{
        projectsForm.id=formValues.researchProjects.length+1;
        projectsForm.startDate = projectsForm.startDateFormat ? moment(new Date(projectsForm.startDateFormat+"")).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD");
        projectsForm.endDate = projectsForm.endDateFormat ? moment(new Date(projectsForm.endDateFormat+"")).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD");
        setFormValues(prev => ({
            ...prev,
            researchProjects:[...prev.researchProjects, projectsForm]
        }));
        setProjectsForm(new IResearchProject());
    }

    const deleteProject = (e:any, idToDelete: number | null) => {
        const newArrayProjects = formValues.researchProjects.filter(item=>item.id !== idToDelete);
        setFormValues(prev => ({
            ...prev,
            researchProjects: newArrayProjects
        }));
    }

    const changeCheckBoxProject = (e: React.ChangeEvent<HTMLInputElement>, idProject:number | null) => {
        const projectsAdjusted = formValues.researchProjects.map(project => {
            if(project.id === idProject){
                project.visible = !project.visible
            }
            return project;
        });
        setFormValues(prev => ({
            ...prev,
            researchProjects: projectsAdjusted
        }));
    }

    const addVenture = () =>{
        ventureForm.id=formValues.ventures.length+1;
        ventureForm.startDate = ventureForm.startDateFormat ? moment(new Date(ventureForm.startDateFormat+"")).format() : "";
        setFormValues(prev => ({
            ...prev,
            ventures:[...prev.ventures, ventureForm]
        }));
        setVentureForm(new IVenture());
    }

    const deleteVenture = (e:any, idToDelete: number | null) => {
        const newArrayVentures = formValues.ventures.filter(item=>item.id !== idToDelete);
        setFormValues(prev => ({
            ...prev,
            ventures: newArrayVentures
        }));
    }

    const changeCheckBoxVenture = (e: React.ChangeEvent<HTMLInputElement>, idVenture:number | null) => {
        const venturesAdjusted = formValues.ventures.map(venture => {
            if(venture.id === idVenture){
                venture.visible = !venture.visible
            }
            return venture;
        });
        setFormValues(prev => ({
            ...prev,
            ventures: venturesAdjusted
        }));
    }

    const saveAdditionalInfo = async() => {
        formValues.associations.forEach(association=>{
            delete association.id;
        });
        formValues.recognitions.forEach(recognition=>{
            delete recognition.id;
            delete recognition.graduateId;
            delete recognition.createdAt;
            delete recognition.updatedAt;
        });
        formValues.studiesOfInterest.forEach(interest=>{
            delete interest.id;
        });
        formValues.researchProjects.forEach(project=>{
            delete project.id;
            delete project.graduateId;
            delete project.createdAt;
            delete project.updatedAt;
            delete project.startDateFormat;
            delete project.endDateFormat;
        });
        formValues.ventures.forEach(venture=>{
            delete venture.id;
        });
        const objToUpdate = formValues;
        const responseAdditionalInfo = await trackPromise(updateAdditionalInfo(
            userToken,
            {...objToUpdate, modifyUser: userContext.userId}
        ));
        if(responseAdditionalInfo.status === 200){
            setSuccessMessage("Se ha guardado correctamente la información")
            if(userContext.userId && params.id === undefined){
                await trackPromise(userContext.handleSetUserPercentage(userContext.userId));
            }else if(params.id){
                await trackPromise(userContext.handleSetUserPercentage(params.id));
            }
            setOpenModalSuccess(true);
            setOpenModalError(false);
        } else{
            setErrorMessage("Hubo un error, intente de nuevo mas tarde");
            setOpenModalError(true);
            setOpenModalSuccess(false);
        }
    }

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
    };

    const nextBtnAction = () =>{
        userContext.setStateCurriculumSection("Documentos adjuntos");
    }

    const backBtnAction = () =>{
        userContext.setStateCurriculumSection("Información laboral");
    }

    return (
        <>
            <Grid item xs={12} lg={10} className="curriculum-form-info-container">
                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccoridon('panel1')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                            >
                            <Typography variant="h2" className="bold-serif">Asociaciones</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row" className="information-box-margin">
                                <Grid container item xs={12} className="complementary-text-container">
                                    <Typography variant="h5" className="regular-serif">
                                        En está sección puede adicionar o eliminar asociaciones, luego de adicionar debe Guardar los cambios.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-right">
                                    <Typography variant="h5" className="bold-serif title-box-text">Tipo de asociación</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-typeOfAssociation"
                                        select
                                        name="typeOfAssociation"
                                        label={associationForm.typeOfAssociation === 0 ? "Seleccione": ""}
                                        value={associationForm.typeOfAssociation === 0 ? "" : associationForm.typeOfAssociation}
                                        onChange={(e)=>changeSpecialForm(e, "associations","typeOfAssociation")}
                                        error={!!formErrors.typeOfAssociation}
                                        helperText={formErrors.typeOfAssociation}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                        {
                                            associationTypeArray && associationTypeArray.length> 0 &&
                                            <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                        }
                                        {associationTypeArray && associationTypeArray.length>0 ?
                                            associationTypeArray.map((option: IGeneralParameter) => (
                                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                    {option.name}
                                                </MenuItem>
                                            ))
                                        :
                                            <MenuItem value={associationForm.typeOfAssociation}></MenuItem>
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-left">
                                    <Typography variant="h5" className="bold-serif title-box-text">Asociación</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-association"
                                        select
                                        name="association"
                                        label={associationForm.association === 0 ? "Seleccione": ""}
                                        value={associationForm.association === 0 ? "" : associationForm.association}
                                        onChange={(e)=>changeSpecialForm(e, "associations","association")}
                                        error={!!formErrors.association}
                                        helperText={formErrors.association}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                        {
                                            associationArray && associationArray.length> 0 &&
                                            <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                        }
                                        {associationArray && associationArray.length>0 ?
                                            associationArray.map((option: IGeneralParameter) => (
                                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                    {option.name}
                                                </MenuItem>
                                            ))
                                        :
                                            <MenuItem value={associationForm.association}></MenuItem>
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} className="information-box-section special-box-left add-container">
                                    <Button disabled={!isValidAddAssociation} onClick={addAssociation} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                                        <Typography variant="h6" className="regular-serif">
                                            Adicionar
                                        </Typography>
                                    </Button>
                                </Grid>

                                {formValues.associations.length>0 &&
                                    formValues.associations.map((association, index)=>{
                                        const typeObj = associationTypeArray.find(item=>item.parameter_code === association.typeOfAssociation);
                                        const TypeName = (association.typeOfAssociation>0 && typeObj) ? typeObj.name : "";
                                        const associationObj = associationArray.find(item=>item.parameter_code === association.association);
                                        const associationName = (association.association>0 && associationObj) ? associationObj.name : "";
                                        return(
                                            <Grid container item xs={12} className="information-box-section special-box-right detail-add-container" key={index}>
                                                <Grid item xs={12} md={8} lg={9}>
                                                    <div className="detail-add-description">
                                                        <Typography variant="h5" className="regular-serif">
                                                            {TypeName} - {associationName}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4} lg={3}>
                                                    <div className="delete-container">
                                                        <Button onClick={(e)=>deleteAssociation(e, association.id ? association.id : null)} variant="contained" className="delete-btn" endIcon={<DeleteIcon/>} >
                                                            <Typography variant="h6" className="regular-serif">
                                                                Eliminar
                                                            </Typography>
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccoridon('panel2')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                            >
                            <Typography variant="h2" className="bold-serif">Reconocimientos</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row" className="information-box-margin">
                                <Grid container item xs={12} className="complementary-text-container">
                                    <Typography variant="h5" className="regular-serif">
                                        En está sección puede adicionar o eliminar sus reconocimientos, luego de adicionar debe Guardar los cambios.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-right">
                                    <Typography variant="h5" className="bold-serif title-box-text">Nombre del reconocimiento*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-recognition-name"
                                        name="name"
                                        label={recognitionForm.name === "" ? "Ingrese nombre del reconocimiento": ""}
                                        value={recognitionForm.name === "" ? "" : recognitionForm.name}
                                        onChange={(e)=>changeSpecialForm(e, "recognitions","recognitionName")}
                                        error={!!formErrors.recognitionName}
                                        helperText={formErrors.recognitionName}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-left">
                                    <Typography variant="h5" className="bold-serif title-box-text">Tipo de reconocimiento*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-recognition-typeOfRecognition"
                                        select
                                        name="typeOfRecognition"
                                        label={recognitionForm.typeOfRecognition === 0 ? "Seleccione": ""}
                                        value={recognitionForm.typeOfRecognition === 0 ? "" : recognitionForm.typeOfRecognition}
                                        onChange={(e)=>changeSpecialForm(e, "recognitions","typeOfRecognition")}
                                        error={!!formErrors.typeOfRecognition}
                                        helperText={formErrors.typeOfRecognition}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                        {recognitionTypeArray && recognitionTypeArray.length>0 ?
                                            recognitionTypeArray.map((option: IGeneralParameter) => (
                                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                    {option.name}
                                                </MenuItem>
                                            ))
                                        :
                                            <MenuItem value={recognitionForm.typeOfRecognition}></MenuItem>
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                    <Typography variant="h5" className="bold-serif title-box-text">Fecha del reconocimiento*</Typography>
                                    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                        <DatePicker
                                            showToolbar={false}
                                            disableFuture
                                            openTo="year"
                                            views={['year', 'month', 'date']}
                                            maxDate={new Date()}
                                            value={recognitionForm.dateFormat || null}
                                            onChange={(value)=>changeRecognitionDate(value, 'dateFormat')}
                                            renderInput={(params) => (
                                                <TextField
                                                disabled
                                                {...params}
                                                fullWidth
                                                name="dateFormat"
                                                margin="none"
                                                label={(recognitionForm.dateFormat && recognitionForm.dateFormat !== null )? "": "Seleccione Fecha dd/mm/aaaa"}
                                                error={!!formErrors.dateFormat}
                                                helperText={formErrors.dateFormat}
                                                InputLabelProps={{shrink: false}}
                                                variant="outlined"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} className="information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Descripción del reconocimiento</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-recognition-description"
                                        name="description"
                                        label={recognitionForm.description === "" ? "Ingrese una descripción": ""}
                                        value={recognitionForm.description === "" ? "" : recognitionForm.description}
                                        onChange={(e)=>changeSpecialForm(e, "recognitions","recognitionDescription")}
                                        error={!!formErrors.recognitionDescription}
                                        helperText={formErrors.recognitionDescription}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} className="information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Institución*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-recognition-institution"
                                        name="institution"
                                        label={recognitionForm.institution === "" ? "Institución que otorga": ""}
                                        value={recognitionForm.institution === "" ? "" : recognitionForm.institution}
                                        onChange={(e)=>changeSpecialForm(e, "recognitions","recognitionInstitution")}
                                        error={!!formErrors.recognitionInstitution}
                                        helperText={formErrors.recognitionInstitution}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} className="information-box-section special-box-left add-container">
                                    <Button disabled={!isValidAddRecognition} onClick={addRecognition} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                                        <Typography variant="h6" className="regular-serif">
                                            Adicionar
                                        </Typography>
                                    </Button>
                                </Grid>

                                {formValues.recognitions.length>0 &&
                                    formValues.recognitions.map((recognition, index)=>{
                                        const typeObj = recognitionTypeArray.find(item=>item.parameter_code === recognition.typeOfRecognition);
                                        const typeName = (recognition.typeOfRecognition>0 && typeObj) ? typeObj.name : "";
                                        return(
                                            <Grid container item xs={12} className="information-box-section special-box-right detail-add-container" key={index}>
                                                <Grid item xs={12} md={8} lg={9}>
                                                    <div className="detail-add-description">
                                                        <Typography variant="h5" className="regular-serif">
                                                            {recognition.name} - {typeName} {moment(recognition.date).format("DD/MM/YYYY") === "Fecha inválida" ? "" : " - " + moment(recognition.date).format("DD/MM/YYYY")}  - {recognition.description}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4} lg={3}>
                                                    <div className="delete-container">
                                                        <Button onClick={(e)=>deleteRecognition(e, recognition.id ? recognition.id : null)} variant="contained" className="delete-btn" endIcon={<DeleteIcon/>} >
                                                            <Typography variant="h6" className="regular-serif">
                                                                Eliminar
                                                            </Typography>
                                                        </Button>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} className="special-box-both">
                                                    <Divider className={classes.divider} />
                                                </Grid>
                                                <Grid container item xs={12} alignItems="center" className="special-box-both
                                                visibility-curriculum-container">
                                                    <VisibilityIcon/>
                                                    <FormControlLabel
                                                        value={recognition.visible}
                                                        control={
                                                            <BlueSwitch
                                                                size="small"
                                                                checked={recognition.visible}
                                                                onChange={(e)=>changeCheckBoxRecognition(e,recognition.id ? recognition.id : null)}
                                                                name="visible"
                                                            />
                                                        }
                                                        label="Mostrar esta información en su hoja de vida"
                                                        labelPlacement="start"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccoridon('panel3')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                            >
                            <Typography variant="h2" className="bold-serif">Estudios de interés</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row" className="information-box-margin">
                                <Grid container item xs={12} className="complementary-text-container">
                                    <Typography variant="h5" className="regular-serif">
                                        En está sección puede adicionar o eliminar estudios de su interés, luego de adicionar debe Guardar los cambios.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-right">
                                    <Typography variant="h5" className="bold-serif title-box-text">Área*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-interest-area"
                                        name="area"
                                        label={interestForm.area === "" ? "Ingrese nombre del área": ""}
                                        value={interestForm.area === "" ? "" : interestForm.area}
                                        onChange={(e)=>changeSpecialForm(e, "interest","area")}
                                        error={!!formErrors.area}
                                        helperText={formErrors.area}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-left">
                                    <Typography variant="h5" className="bold-serif title-box-text">Estudio*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-interest-study"
                                        name="study"
                                        label={interestForm.study === "" ? "Ingrese nombre del curso": ""}
                                        value={interestForm.study === "" ? "" : interestForm.study}
                                        onChange={(e)=>changeSpecialForm(e, "interest","study")}
                                        error={!!formErrors.study}
                                        helperText={formErrors.study}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12} className="information-box-section special-box-left add-container">
                                    <Button disabled={!isValidInterest} onClick={addInterest} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                                        <Typography variant="h6" className="regular-serif">
                                            Adicionar
                                        </Typography>
                                    </Button>
                                </Grid>

                                {formValues.studiesOfInterest.length>0 &&
                                    formValues.studiesOfInterest.map((interest, index)=>(
                                        <Grid container item xs={12} className="information-box-section special-box-right detail-add-container" key={index}>
                                            <Grid item xs={12} md={8} lg={9}>
                                                <div className="detail-add-description">
                                                    <Typography variant="h5" className="regular-serif">
                                                        {interest.area} - {interest.study}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={3}>
                                                <div className="delete-container">
                                                    <Button onClick={(e)=>deleteInterest(e, interest.id ? interest.id : null)}  variant="contained" className="delete-btn" endIcon={<DeleteIcon/>} >
                                                        <Typography variant="h6" className="regular-serif">
                                                            Eliminar
                                                        </Typography>
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChangeAccoridon('panel4')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                            >
                            <Typography variant="h2" className="bold-serif">Proyecto de investigación</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row" className="information-box-margin">
                                <Grid container item xs={12} className="complementary-text-container">
                                    <Typography variant="h5" className="regular-serif">
                                        En está sección puede adicionar o eliminar sus proyectos de investigación, luego de adicionar debe Guardar los cambios.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-right">
                                    <Typography variant="h5" className="bold-serif title-box-text">Nombre de grupo de investigación*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-project-name"
                                        name="name"
                                        label={projectsForm.name === "" ? "Ingrese nombre del proyecto": ""}
                                        value={projectsForm.name === "" ? "" : projectsForm.name}
                                        onChange={(e)=>changeSpecialForm(e, "projects","projectName")}
                                        error={!!formErrors.projectName}
                                        helperText={formErrors.projectName}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-left">
                                    <Typography variant="h5" className="bold-serif title-box-text">Rol en el grupo*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-project-role"
                                        name="role"
                                        label={projectsForm.role === "" ? "Ingrese su rol en el proyecto": ""}
                                        value={projectsForm.role === "" ? "" : projectsForm.role}
                                        onChange={(e)=>changeSpecialForm(e, "projects","role")}
                                        error={!!formErrors.role}
                                        helperText={formErrors.role}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-right">
                                    <Typography variant="h5" className="bold-serif title-box-text">Clasificación del grupo*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-projects-groupClassification"
                                        name="groupClassification"
                                        label={projectsForm.groupClassification === "" ? "Ingrese grupo de clasificación" : ""}
                                        value={projectsForm.groupClassification === "" ? "" : projectsForm.groupClassification}
                                        onChange={(e)=>changeSpecialForm(e, "projects","groupClassification")}
                                        error={!!formErrors.groupClassification}
                                        helperText={formErrors.groupClassification}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12} className="information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Descripción de la entidad</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={2}
                                        id="outlined-select-project-"
                                        name="entityDescription"
                                        label={projectsForm.entityDescription === "" ? "Ingrese una breve descripción": ""}
                                        value={projectsForm.entityDescription === "" ? "" : projectsForm.entityDescription}
                                        onChange={(e)=>changeSpecialForm(e, "projects","entityDescription")}
                                        error={!!formErrors.entityDescription}
                                        helperText={formErrors.entityDescription}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-right">
                                    <Typography variant="h5" className="bold-serif title-box-text">Tipo de entidad</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-project-typeOfEntity"
                                        select
                                        name="typeOfEntity"
                                        label={projectsForm.typeOfEntity === 0 ? "Seleccione": ""}
                                        value={projectsForm.typeOfEntity === 0 ? "" : projectsForm.typeOfEntity}
                                        onChange={(e)=>changeSpecialForm(e, "projects","typeOfEntity")}
                                        error={!!formErrors.typeOfEntity}
                                        helperText={formErrors.typeOfEntity}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                        {
                                            entityArray && entityArray.length> 0 &&
                                            <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                        }
                                        {entityArray && entityArray.length>0 ?
                                            entityArray.map((option: IGeneralParameter) => (
                                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                    {option.name}
                                                </MenuItem>
                                            ))
                                        :
                                            <MenuItem value={projectsForm.typeOfEntity}></MenuItem>
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-left">
                                    <Typography variant="h5" className="bold-serif title-box-text">Institución*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-project-institution"
                                        select
                                        name="institution"
                                        label={projectsForm.institution === 0 ? "Seleccione": ""}
                                        value={projectsForm.institution === 0 ? "" : projectsForm.institution}
                                        onChange={(e)=>changeSpecialForm(e, "projects","projectInstitution")}
                                        error={!!formErrors.projectInstitution}
                                        helperText={formErrors.projectInstitution}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                        {
                                            institutionArray && institutionArray.length> 0 &&
                                            <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                        }
                                        {institutionArray && institutionArray.length>0 ?
                                            institutionArray.map((option: IGeneralParameter) => (
                                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                    {option.name}
                                                </MenuItem>
                                            ))
                                        :
                                            <MenuItem value={projectsForm.institution}></MenuItem>
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                    <Typography variant="h5" className="bold-serif title-box-text">Fecha de inicio*</Typography>
                                    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                        <DatePicker
                                            showToolbar={false}
                                            disableFuture
                                            openTo="year"
                                            views={['year', 'month', 'date']}
                                            maxDate={new Date()}
                                            value={projectsForm.startDateFormat || null}
                                            onChange={(value)=>changeProjectDate(value, 'startDateFormat')}
                                            renderInput={(params) => (
                                                <TextField
                                                disabled
                                                {...params}
                                                fullWidth
                                                name="startDateFormat"
                                                margin="none"
                                                label={(projectsForm.startDateFormat && projectsForm.startDateFormat !== null )? "": "Fecha dd/mm/aaaa"}
                                                error={!!formErrors.startDateFormat}
                                                helperText={formErrors.startDateFormat}
                                                InputLabelProps={{shrink: false}}
                                                variant="outlined"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-both">
                                    <Typography variant="h5" className="bold-serif title-box-text">Fecha de finalización*</Typography>
                                    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                        <DatePicker
                                            showToolbar={false}
                                            disabled={projectsForm.startDateFormat ? false: true}
                                            openTo="year"
                                            views={['year', 'month', 'date']}
                                            value={projectsForm.endDateFormat || null}
                                            onChange={(value)=>changeProjectDate(value, 'endDateFormat')}
                                            renderInput={(params) => (
                                                <TextField
                                                disabled
                                                {...params}
                                                fullWidth
                                                name="endDateFormat"
                                                margin="none"
                                                label={(projectsForm.endDateFormat && projectsForm.endDateFormat !== null )? "": "Fecha dd/mm/aaaa"}
                                                error={!!formErrors.endDateFormat}
                                                helperText={formErrors.endDateFormat}
                                                InputLabelProps={{shrink: false}}
                                                variant="outlined"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} className="information-box-section special-box-left add-container">
                                    <Button disabled={!isValidProject} onClick={addProject} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                                        <Typography variant="h6" className="regular-serif">
                                            Adicionar
                                        </Typography>
                                    </Button>
                                </Grid>

                                {formValues.researchProjects.length>0 &&
                                    formValues.researchProjects.map((project, index)=>{
                                        return(
                                            <Grid container item xs={12} className="information-box-section  detail-add-container" key={index}>
                                                <div className="detail-add-description">
                                                    <Typography variant="h5" className="regular-serif">
                                                        {project.name} - {project.role} - {project.groupClassification}
                                                    </Typography>
                                                    <Typography variant="h5" className="regular-serif">
                                                        {project.entityDescription}
                                                    </Typography>
                                                </div>
                                                <div className="delete-container">
                                                    <Button onClick={(e)=>deleteProject(e, project.id ? project.id : null)} variant="contained" className="delete-btn" endIcon={<DeleteIcon/>} >
                                                        <Typography variant="h6" className="regular-serif">
                                                            Eliminar
                                                        </Typography>
                                                    </Button>
                                                </div>
                                                <Grid item xs={12} className="special-box-both">
                                                    <Divider className={classes.divider} />
                                                </Grid>
                                                <Grid container item xs={12} alignItems="center" className="special-box-both visibility-curriculum-container">
                                                    <VisibilityIcon/>
                                                    <FormControlLabel
                                                        value={project.visible}
                                                        control={
                                                            <BlueSwitch
                                                                size="small"
                                                                checked={project.visible}
                                                                onChange={(e)=>changeCheckBoxProject(e,project.id ? project.id : null)}
                                                                name="visible"
                                                            />
                                                        }
                                                        label="Mostrar esta información en su hoja de vida"
                                                        labelPlacement="start"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChangeAccoridon('panel5')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                            >
                            <Typography variant="h2" className="bold-serif">Emprendimiento</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row" className="information-box-margin">
                                <Grid container item xs={12} className="complementary-text-container">
                                    <Typography variant="h5" className="regular-serif">
                                        En está sección puede adicionar o eliminar sus emprendimientos, luego de adicionar debe Guardar los cambios.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-right">
                                    <Typography variant="h5" className="bold-serif title-box-text">Nombre del emprendimiento*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-venture-name"
                                        name="name"
                                        label={ventureForm.name === "" ? "Seleccione": ""}
                                        value={ventureForm.name === "" ? "" : ventureForm.name}
                                        onChange={(e)=>changeSpecialForm(e, "ventures","ventureName")}
                                        error={!!formErrors.ventureName}
                                        helperText={formErrors.ventureName}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-left">
                                    <Typography variant="h5" className="bold-serif title-box-text">Sector económico*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-venture-sector"
                                        select
                                        name="sector"
                                        label={ventureForm.sector === 0 ? "Seleccione": ""}
                                        value={ventureForm.sector === 0 ? "" : ventureForm.sector}
                                        onChange={(e)=>changeSpecialForm(e, "ventures","ventureSector")}
                                        error={!!formErrors.ventureSector}
                                        helperText={formErrors.ventureSector}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                        {
                                            allLaboralSector && allLaboralSector.length> 0 &&
                                            <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                        }
                                        {allLaboralSector && allLaboralSector.length>0 ?
                                            allLaboralSector.map((option: IGeneralParameter) => (
                                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                    {option.name}
                                                </MenuItem>
                                            ))
                                        :
                                            <MenuItem value={ventureForm.sector}></MenuItem>
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}  className="information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Descripción del emprendimiento</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        id="outlined-select-venture-description"
                                        name="description"
                                        label={ventureForm.description === "" ? "Ingrese una descripción breve": ""}
                                        value={ventureForm.description === "" ? "" : ventureForm.description}
                                        onChange={(e)=>changeSpecialForm(e, "ventures","ventureDescription")}
                                        error={!!formErrors.ventureDescription}
                                        helperText={formErrors.ventureDescription}
                                        InputLabelProps={{shrink: false}}
                                        variant="outlined"
                                        margin="none"
                                    ></TextField>
                                </Grid>
                                <Grid container item xs={12} alignItems="flex-end">
                                    <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                        <Typography variant="h5" className="bold-serif title-box-text">Fecha de inicio emprendimiento*</Typography>
                                        <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                            <DatePicker
                                                showToolbar={false}
                                                disableFuture
                                                openTo="year"
                                                views={['year', 'month', 'date']}
                                                maxDate={new Date()}
                                                value={ventureForm.startDateFormat || null}
                                                onChange={(value)=>changeVentureDate(value, 'startDateFormat', 'ventureStartDateFormat')}
                                                renderInput={(params) => (
                                                    <TextField
                                                    disabled
                                                    {...params}
                                                    fullWidth
                                                    name="startDateFormat"
                                                    margin="none"
                                                    label={(ventureForm.startDateFormat && ventureForm.startDateFormat !== null )? "": "Fecha dd/mm/aaaa"}
                                                    error={!!formErrors.ventureStartDateFormat}
                                                    helperText={formErrors.ventureStartDateFormat}
                                                    InputLabelProps={{shrink: false}}
                                                    variant="outlined"
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-both">
                                        <Typography variant="h5" className="bold-serif title-box-text">Estado actual emprendimiento*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-venture-state"
                                            select
                                            name="actualState"
                                            label={ventureForm.actualState === 0 ? "Seleccione": ""}
                                            value={ventureForm.actualState === 0 ? "" : ventureForm.actualState}
                                            onChange={(e)=>changeSpecialForm(e, "ventures","ventureActualState")}
                                            error={!!formErrors.ventureActualState}
                                            helperText={formErrors.ventureActualState}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {
                                                ventureStateArray && ventureStateArray.length> 0 &&
                                                <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                            }
                                            {ventureStateArray && ventureStateArray.length>0 ?
                                                ventureStateArray.map((option: IGeneralParameter) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                            :
                                                <MenuItem value={ventureForm.actualState}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-left add-container">
                                        <Button disabled={!isValidVenture} onClick={addVenture} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                                            <Typography variant="h6" className="regular-serif">
                                                Adicionar
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>

                                {formValues.ventures.length>0 &&
                                    formValues.ventures.map((venture, index)=>{
                                        const sectorObj = allLaboralSector.find(item=>item.parameter_code === venture.sector);
                                        const sectorName = (venture.sector>0 && sectorObj) ? sectorObj.name : "";
                                        return(
                                            <Grid container item xs={12} className="information-box-section detail-add-container" key={index}>
                                                <Grid item xs={12} md={9} lg={10}>
                                                    <div className="detail-add-description">
                                                        <Typography variant="h5" className="regular-serif">
                                                            {venture.name} - {sectorName}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={3} lg={2}>
                                                    <div className="delete-container">
                                                        <Button onClick={(e)=>deleteVenture(e, venture.id ? venture.id : null)} variant="contained" className="delete-btn" endIcon={<DeleteIcon/>} >
                                                            <Typography variant="h6" className="regular-serif">
                                                                Eliminar
                                                            </Typography>
                                                        </Button>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} className="special-box-both">
                                                    <Divider className={classes.divider} />
                                                </Grid>
                                                <Grid container item xs={12} alignItems="center" className="special-box-both visibility-curriculum-container">
                                                    <VisibilityIcon/>
                                                    <FormControlLabel
                                                        value={venture.visible}
                                                        control={
                                                            <BlueSwitch
                                                                size="small"
                                                                checked={venture.visible}
                                                                onChange={(e)=>changeCheckBoxVenture(e,venture.id ? venture.id : null)}
                                                                name="visible"
                                                            />
                                                        }
                                                        label="Mostrar esta información en su hoja de vida"
                                                        labelPlacement="start"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }

                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <>
                {
                    userContext.roleSelected === 9 ?
                    <></>
                    :
                    <>
                        <Grid container item xs={12}>
                            <Grid container item xs={12} md={3} lg={6} className="step-curriculum-buttons">
                                <Button onClick={backBtnAction} className="back-button" variant="contained" size="small">
                                    <Typography variant="h5" className="regular-serif">Regresar</Typography>
                                </Button>
                            </Grid>
                            <Grid container item xs={12} md={9} lg={6} className="buttons-curriculum-form-container">
                                <Button onClick={saveAdditionalInfo} className="save-button" variant="contained" size="small">
                                    <Typography variant="h5" className="regular-serif">Guardar</Typography>
                                </Button>
                                <Button onClick={nextBtnAction} className="next-button" variant="contained" size="small">
                                    <Typography variant="h5" className="regular-serif">Siguiente</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
                </>

            </Grid>
            <Grid container item xs={12} lg={2} justify="center" style={{height:'fit-content'}}>\
                <NotificationBox 
                    bgColor="#CFF4FC"
                    title="Porcentaje de Completitud"
                    titleColor="#055160"
                    description="Este es el % de los campos obligatorios necesarios para entrar a la bolsa de empleo"
                    descColor="#055160"
                    extra={<CircularProgressDet value={userContext.userPercentage?.total ? userContext.userPercentage.total : 0} size={90} descColor ={"#055160"}/>}
                    extra2={<PercentageInfo data={userContext.userPercentage}/>}
                />
                <div className='space-notification-boxes'> </div>
                <NotificationBox
                    bgColor="#CFF4FC"
                    title="Información adicional"
                    titleColor="#055160"
                    description="Por favor actualice su información adicional. Los campos marcados con asterisco (*) son obligatorios. En algunos campos puede seleccionar si desea que la información sea visible en su Hoja de vida."
                    descColor="#055160"
                    icon={<ErrorOutlineIcon style={{color:'#055160'}} fontSize="large"/>}
                />
            </Grid>
            <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
            <LoadingLockPage load={promiseInProgress}/>
            <LoadingLockPage load={initLoad}/>
        </>
    )
}

export default AdditionalInformation