import { StateByRole } from "./StateByRole";
import { DateRange } from '@material-ui/pickers';

export class IFilterOfferForm{
    jobTitle: string = "";
    laboralSector: number = 0;
    typePosition: number = 0;
    state: Array<StateByRole> = [];
    country: number = 0;
    department: number = 0
    city: number = 0;
    rangeStartDate: DateRange<Date> = [null,null];
    rangeEndDate: DateRange<Date> = [null,null];
    name: string = "";
    nit: string | null = null;
    laboralSectorCompany: number = 0;
}