export class IGraduateDinamicReport{
    names?: string = "";
    surname?: string = "";
    gender?: number = 0;
    doc?: string = "";
    state?: Array<string> = [];
    updateAtInitDate?: string = "";
    updateAtEndDate?: string = "";
    inJobBankInit?: string = "";
    inJobBankEnd?: string = "";
}
export class IStudiesDinamicReport{
    campus?: Array<number> = [];
    school?: Array<number> = [];
    curriculum?: Array<number> = [];
    academicLevel?: number = 0;
    graduationInitDate?: string = "";
    graduationEndDate?: string = "";
}
export class IAssociationDinamicReport{
    typeOfAssociation?: number = 0;
    association?: number = 0;
}

export class IGraduateFilterObjDinamic{
    preview?: boolean = true; // true return number, false send email
    graduate?:{
        fields?: Array<string>;
        data?: IGraduateDinamicReport;
    } = {
        fields: [],
        data: new IGraduateDinamicReport()
    };
    studies?:{
        fields?: Array<string>;
        data?: IStudiesDinamicReport;
    } = {
        fields: [],
        data: new IStudiesDinamicReport()
    };
    associations?:{
        fields?: Array<string>;
        data?: IAssociationDinamicReport;
    } = {
        fields: [],
        data: new IAssociationDinamicReport()
    };
    laboralExperience?:{
        fields?: Array<string>;
        data?: Object;
    } = {
        fields: [],
        data: {}
    };
    languages?:{
        fields?: Array<string>;
        data?: Object;
    } = {
        fields: [],
        data: {}
    };
    limit?: number = 0;
    offset?: number = 0;
    userReporter?:string = "";
}
