import moment from 'moment';
export class IRegisterEventData{
  type: string;
  category: number;
  highlight: boolean = false;
  fileType: string;
  name: string;
  description: string;
  shortDescription: string;
  place: string;
  dateStart: string;
  timeStart: string;
  dateFinish: string;
  timeFinish: string;
  publish: boolean = false;
  constructor(def:any){
    this.type = def.type || "";
    this.category = def.category || 0;
    this.highlight = def.highlight || false;
    this.fileType = def.fileType || "";
    this.name = def.name || "";
    this.description = def.description || "";
    this.shortDescription = def.shortDescription || "";
    this.place = def.place || "";
    this.dateStart = def.dateStart ? moment(def.dateStart).format() : "";
    this.timeStart = def.timeStart ? moment(def.timeStart).format("HH:mm:ss") : "";
    this.dateFinish = def.dateFinish ? moment(def.dateFinish).format() : "";
    this.timeFinish = def.timeFinish ? moment(def.timeFinish).format("HH:mm:ss") : "";
    this.publish = def.publish || false;
  }
}