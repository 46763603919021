import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import esLocale from "date-fns/locale/es";
import WarningIcon from '@material-ui/icons/Warning';
import moment from 'moment';
import 'moment/locale/es';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { Redirect } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from "@material-ui/core/MenuItem";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import InputBase from '@material-ui/core/InputBase';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import NotificationBox from '../../ShareComponents/NotificationBox';
import Validations from '../../../helpers/Validations';
import { IOfferForm } from '../../../interfaces/IOfferForm';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { getACADEMIC_LEVEL, getAREA, getCITY, getCOMPETENCE, getCOUNTRIES_CITIES, getDEPARTAMENT, getLABORAL_SECTOR, getLANGUAGE, getOccupationType, getRangeMinimun, getSubArea, getTypeOfContract,  getOCCUPATION, getPROGRAMS} from '../../../services/ParameterService';
import { ICreateOffer } from '../../../interfaces/ICreateOffer';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import OfferCard from '../OfferCard';
import UserContext from '../../../utils/UserContext';
import { createLaboralOffer, getlistOffers, updateLaboralOffers } from '../../../services/LaboralOfferService';
import { IGetOffer } from '../../../interfaces/IGetOffer';
import { convertCurrency, sortByKey } from '../../../helpers/sharedFunctions';
import './OfferForm.scss';
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';
import ModalOffersAction from '../ModalOffersAction';
import AlertBox from '../../ShareComponents/AlertBox';


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 220,
      width: 250,
    },
  },
};

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      border: '1px solid #0000003b',
      padding: '18.5px 32px 18.5px 14px',
      '&:focus': {
        borderRadius: 4,
        borderWidth: 2,
        borderColor: '#3f51b5',
      },
    },
  }),
)(InputBase);

const initialFormValues ={
  jobTitle: "",
  typePosition: "",
  numberVacancy: "",
  description: "",
  country: "",
  department: "",
  city: "",
  laboralSector: "",
  typeContract: "",
  rangeSalary: "",
  jobOfferStartDate: "",
  jobOfferEndDate: "",
  estimatedDateCompletion: "",
  descriptionVariableSalary: "",
  knowlegeArea: "",
  profession: "",
  experienceYears: "",
  experienceMonths: "",
  competencies: "",
  other_skill: "",
  academicLevel: "",
  languajes: "",
  keyword: ""
}

const formValidations = {
  jobTitle: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: true
  },
  typePosition: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: true
  },
  numberVacancy:{
    validate: "select",
    required: true
  },
  description: {
    error: "",
    validate: "textNumber",
    minLength: 10,
    required: true
  },
  country: {
    validate: "select",
    required: true
  },
  department: {
    validate: "select",
    required: true
  },
  city: {
    validate: "select",
    required: true
  },
  laboralSector: {
    validate: "select",
    required: true
  },
  typeContract: {
    validate: "select",
    required: true
  },
  rangeSalary: {
    validate: "select",
    required: true
  },
  jobOfferStartDate: {
    error: "",
    validate: "normalDate",
    minDate: new Date(),
    required: true
  },
  jobOfferEndDate: {
    error: "",
    validate: "normalDate",
    minDate: new Date(),
    required: true
  },
  estimatedDateCompletion: {
    error: "",
    validate: "normalDate",
    minDate: new Date(),
    maxDate: new Date(),
    required: true
  },
  descriptionVariableSalary: {
    error: "",
    validate: "textNumber",
    minLength: 10,
    required: true
  },
  knowlegeArea: {
    validate: "select",
    required: true
  },
  profession: {
    validate: "select",
    required: false
  },
  experienceYears: {
    validate: "select",
    required: true
  },
  experienceMonths: {
    validate: "select",
    required: true
  },
  skill: {
    error: "",
    validate: "select",
    required: false
  },
  other_skill: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: false
  },
  academicLevel: {
    validate: "select",
    required: false
  },
  languageSelected: {
    validate: "select",
    required: false
  },
  keyword: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: false
  },
  occupation: {
    error: "",
    validate: "select",
    required: false
  },
  program: {
    error: "",
    validate: "select",
    required: true
  }
}

interface OfferFormProps{
  companyName:string;
  offerId:number;
}

const OfferForm:React.FC<OfferFormProps> = ({companyName, offerId}) => {
  const userContext = React.useContext(UserContext);
  let params: any = useParams();
  const { promiseInProgress } = usePromiseTracker();
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<string | boolean>('panel1');
  const [expanded2, setExpanded2] = useState<string | boolean>('panel3');
  const [formValues, setFormValues] = useState(new IOfferForm({}));
  const [offerResponse, setOfferResponse] = useState<IGetOffer>(new IGetOffer());
  const [formErrors, setFormErrors] = useState(initialFormValues);
  const [errorMessage, setErrorMessage]= useState('');
  const [openModalError, setOpenModalError] = useState(false);
  const [successMessage, setSuccessMessage]= useState('');
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [opeModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [url, setUrl] = useState<string | null>(null);
  const [action, setAction] = useState<string>("");
  const [checkState, setCheckState] = useState({
    showName: true,
    showSalary: false,
    showVariableSalary: false,
    experience: false,
    disability: false,
    telework: false
  });
  const { showName, showVariableSalary, showSalary, experience, disability, telework } = checkState;
  // select fields options
  const [allTypePositions, setAllTypePositions] = useState<Array<IGeneralParameter>>([]);
  const [ArrayVacancies, setArrayVacancies] = useState<Array<number>>([]);
  const [countryCitiesArray, setCountryCitiesArray] = useState<Array<IListParametersInfo>>([]);
  const [departmentArray, setDepartmentArray] = useState<Array<IGeneralParameter>>([]);
  const [cityArray, setCityArray] = useState<Array<IGeneralParameter>>([]);
  const [cities, setCities] = useState<Array<IGeneralParameter>>([]);
  const [allLaboralSector, setAllLaboralSector] = useState<Array<IGeneralParameter>>([]);
  const [allTypeContract, setAllTypeContract] = useState<Array<IGeneralParameter>>([]);
  const [allOccupations, setAllOccupations] = useState<Array<IGeneralParameter>>([]);
  const [allPrograms, setAllPrograms] = useState<Array<IGeneralParameter>>([]);
  const minimumSalary = 1000000;
  const [salaryRange, setSalaryRange] = useState<Array<string>>([]);
  const [allKnowledgeArea, setAllKnowledgeArea] = useState<Array<IGeneralParameter>>([]);
  const [arrayProfessions, setArrayProfessions] = useState<Array<IGeneralParameter>>([]);
  const [ArrayYears, setArrayYears] = useState<Array<number>>([]);
  const [ArrayMonths, setArrayMonths] = useState<Array<number>>([]);
  const [ArrayDays, setArrayDays] = useState<Array<number>>([]);
  const [AllSkills, setAllSkills] = useState<Array<IGeneralParameter>>([]);
  const [allAcademic, setAllAcademic] = useState<Array<IGeneralParameter>>([]);
  const [allLanguages, setAllLanguages] = useState<Array<IGeneralParameter>>([]);
  const [reopened, setReopened] = useState<boolean>(false);
  const [disableInput, setDisableInput] = useState(true);
  //validation rquired fields
  const isValid =
    formValues.jobTitle.length > 0 &&
    !formErrors.jobTitle &&
    formValues.typePosition > 0 &&
    formValues.numberVacancy > 0 &&
    formValues.description.length > 0 &&
    !formErrors.description &&
    formValues.country > 0 &&
    formValues.department > 0 &&
    formValues.city > 0 &&
    formValues.laboralSector > 0 &&
    formValues.typeContract > 0 &&
    formValues.rangeSalary.length > 0 &&
    moment(formValues.jobOfferStartDate).isValid() &&
    !formErrors.jobOfferStartDate &&
    moment(formValues.jobOfferEndDate).isValid() &&
    !formErrors.jobOfferEndDate &&
    moment(formValues.estimatedDateCompletion).isValid() &&
    !formErrors.estimatedDateCompletion &&
    formValues.knowlegeArea.length > 0 &&
    formValues.professions.length>0 &&
    (formValues.experience || (formValues.experienceYears> 0 || formValues.experienceMonths> 0)) &&
    (
      (showVariableSalary &&
      formValues.descriptionVariableSalary.length > 0 &&
      !formErrors.descriptionVariableSalary) ||
      (
        !showVariableSalary &&
        !formErrors.descriptionVariableSalary
      )
    ) &&
    formValues.programs.length > 0;
    

  useEffect(()=>{
    const getInitialdata = async()=>{
      if(userContext.action && userContext.action.length > 0){
        setAction(userContext.action)
      }
      if(userContext.userId){
        const nit = parseInt(userContext.userId, 10);
        setFormValues(prev => ({
          ...prev,
          company: nit
        }))
      }
      // type position field
      let responsePostion: Array<IGeneralParameter> = await getOccupationType();
      responsePostion = sortByKey(responsePostion, 'name');
      setAllTypePositions(responsePostion);
      // vacancies field
      const ArrVacancies = new Array(99).join().split(',').map((item, index)=> ++index)
      setArrayVacancies(ArrVacancies);
      // data countries-cities
      let dataCountryCities:Array<IListParametersInfo> = (await getCOUNTRIES_CITIES()).list;
      dataCountryCities = sortByKey(dataCountryCities, 'grandParentName');
      setCountryCitiesArray(dataCountryCities);
      // department field
      let responseDepartment:Array<IGeneralParameter> = await getDEPARTAMENT();
      responseDepartment = sortByKey(responseDepartment, 'name');
      setDepartmentArray(responseDepartment);
      // data for cities
      const dataCity:Array<IGeneralParameter> = await getCITY();
      if(dataCity){
        setCityArray(dataCity);
      }
      // laboral sector
      let responseLaboralSector: Array<IGeneralParameter> = await getLABORAL_SECTOR();
      responseLaboralSector = sortByKey(responseLaboralSector, 'name');
      setAllLaboralSector(responseLaboralSector);
      // contract field
      let responseContract: Array<IGeneralParameter> = await getTypeOfContract();
      responseContract = sortByKey(responseContract, 'name');
      setAllTypeContract(responseContract);
      //occupations
      let responseOccu: Array<IGeneralParameter> = await getOCCUPATION();
      responseOccu = sortByKey(responseOccu, 'name');
      setAllOccupations(responseOccu);
      //programs
      let responseProgram: Array<IGeneralParameter> = await getPROGRAMS();
      const uniqueNames:Array<String> = [];
      responseProgram = responseProgram.filter(element => {
        const isDuplicate = uniqueNames.includes(element.name);
      
        if (!isDuplicate) {
          uniqueNames.push(element.name);
      
          return true;
        }
      
        return false;
      });
      responseProgram = sortByKey(responseProgram, 'name');
      setAllPrograms(responseProgram);
      // salary range field
      const responseSalary = await getRangeMinimun();
      let minValSalary: number = 0;
      if(responseSalary.status === 200){
        const result = await responseSalary.json();
        minValSalary = parseInt(result.message.value);
      } else {
        minValSalary =  minimumSalary;
      }
      const maxSalary = (minValSalary)+(500000*24);
      let arrSalary: Array<string> = [];
      for(let i = minValSalary; i<=maxSalary ; i+=500000){
          if(i === maxSalary){
          arrSalary.push(`${convertCurrency(i)} o mas`)
        }
        else{
          arrSalary.push(`${convertCurrency(i)} a ${convertCurrency(i+500000)}`)
        }
      }
      if(arrSalary.length>0){
        setSalaryRange(arrSalary);
      }
      // knowledge area field
      let responseKnowledgeArea: Array<IGeneralParameter> = await getAREA();
      responseKnowledgeArea = sortByKey(responseKnowledgeArea, 'name');
      setAllKnowledgeArea(responseKnowledgeArea);
      // experience fields
      setArrayYears(Array.from(Array(100).keys()));
      setArrayMonths(Array.from(Array(12).keys()));
      setArrayDays(Array.from(Array(32).keys()));
      // skills field
      const responseSkills: Array<IGeneralParameter> = await getCOMPETENCE();
      setAllSkills(responseSkills);
      // academic level field
      let responseAcademic: Array<IGeneralParameter> = await getACADEMIC_LEVEL();
      responseAcademic = sortByKey(responseAcademic, 'name');
      setAllAcademic(responseAcademic);
      // languages field
      let responseLanguages: Array<IGeneralParameter> = await getLANGUAGE();
      responseLanguages = sortByKey(responseLanguages, 'name');
      setAllLanguages(responseLanguages);
      if(params.id >1 || offerId>1){
        let offerSelectedId;
        if(offerId>1){
          offerSelectedId = offerId
          userContext.setAction('Modify');
        } else {
          offerSelectedId = params.id;
        }
        const responseOffer: Response = await getlistOffers({id: offerSelectedId});
        if(responseOffer.status === 200){
          let resultOffer: IGetOffer = (await responseOffer.json()).jobOffers[0];
          let convertedArea: Array<IGeneralParameter> = [];
          let convertedSkills: Array<IGeneralParameter> = [];
          let convertedOccupations: Array<IGeneralParameter> = [];
          let convertedPrograms: Array<IGeneralParameter> = [];
          let convertedOtherSkills: Array<{name:string}> = [];
          let convertedKeywords: Array<{name:string}> = [];
          let convertedLanguage: Array<IGeneralParameter> = [];
          if(resultOffer.reopened === "Reabierta"){
            setReopened(true);
          }
          if(resultOffer.jobOfferStartDate.length>0){
            const value =new Date(resultOffer.jobOfferStartDate) ? new Date(resultOffer.jobOfferStartDate)?.toString() : '';
            const error: string = Validations('jobOfferStartDate', value, formValidations) || "";
            setFormErrors(prev=>({
              ...prev,
              jobOfferStartDate: error
            }));
            if(resultOffer.jobOfferEndDate !== null){
              const valueFinal =resultOffer.jobOfferEndDate ? resultOffer.jobOfferEndDate?.toString() : '';
              formValidations.jobOfferEndDate.minDate = moment(new Date(value)).add(10, 'days').toDate();
              const error: string = Validations('jobOfferEndDate', valueFinal , formValidations) || "";
              setFormErrors(prev=>({
                ...prev,
                jobOfferEndDate: error
              }));
            }
            if(resultOffer.estimatedDateCompletion !== null){
              const valueEstimate =resultOffer.estimatedDateCompletion ? resultOffer.estimatedDateCompletion?.toString() : '';
              formValidations.estimatedDateCompletion.minDate = moment(new Date(value)).add(10, 'days').toDate();
              formValidations.estimatedDateCompletion.maxDate = moment(resultOffer.jobOfferEndDate).add(4, 'months').toDate();
              const error: string = Validations('estimatedDateCompletion', valueEstimate , formValidations) || "";
              setFormErrors(prev=>({
                ...prev,
                estimatedDateCompletion: error
              }));
            }
          }
          if(resultOffer.knowlegeArea.length>0){
            resultOffer.knowlegeArea.forEach(area=>{
              const objFind = responseKnowledgeArea.find(obj=>obj.parameter_code === area.idParameter);
              if(objFind){
                convertedArea.push(objFind)
              }
            })
          }
          if(convertedArea.length>0){
            resultOffer.knowlegeAreaParams = convertedArea;
          }
          //occupations
          if(resultOffer.occupations.length>0){
            resultOffer.occupations.forEach(area=>{
              const objFind = responseOccu.find(obj=>obj.parameter_code === area.idParameter);
              if(objFind){
                convertedOccupations.push(objFind)
              }
            })
          }
          if(convertedOccupations.length>0){
            resultOffer.occupationsParams = convertedOccupations;
          }
          //programs
          if(resultOffer.programs.length>0){
            resultOffer.programs.forEach(area=>{
              const objFind = responseProgram.find(obj=>obj.parameter_code === area.idParameter);
              if(objFind){
                convertedPrograms.push(objFind)
              }
            })
          }
          if(convertedPrograms.length>0){
            resultOffer.programsParams = convertedPrograms;
          }

          if(resultOffer.skills.length>0){
            resultOffer.skills.forEach(skill=>{
              const objFind = responseSkills.find(obj=>obj.parameter_code === skill.idParameter);
              if(objFind){
                convertedSkills.push(objFind)
              }
            })
          }
          if(convertedSkills.length>0){
            resultOffer.skillsParams = convertedSkills;
          }
          if(resultOffer.otherSkills){
            const otherArr = resultOffer.otherSkills.split('|');
            otherArr.forEach(item=>{
              convertedOtherSkills.push({name:item});
            })
          }
          if(convertedOtherSkills.length>0){
            resultOffer.otherSkillsParams = convertedOtherSkills;
          }

          if(resultOffer.keywords){
            const keywordsArr = resultOffer.keywords.split('|');
            keywordsArr.forEach(item=>{
              convertedKeywords.push({name:item});
            })
          }
          if(convertedKeywords.length>0){
            resultOffer.keywordsParams = convertedKeywords;
          }

          if(resultOffer.language.length>0){
            resultOffer.language.forEach(lang=>{
              const objFind = responseLanguages.find(obj=>obj.parameter_code === lang.idParameter);
              if(objFind){
                convertedLanguage.push(objFind)
              }
            })
          }
          if(convertedLanguage.length>0){
            resultOffer.languageParams = convertedLanguage;
          }
          setOfferResponse(resultOffer);
          const offerValues = new IOfferForm(resultOffer);
          setCheckState(checkState=>({
            ...checkState,
            showName: offerValues.nameCompanyVisible,
            showSalary: offerValues.salaryVisible,
            showVariableSalary: offerValues.variableSalary,
            experience: offerValues.experience,
            disability: offerValues.disability,
            telework: offerValues.telework
          }));
          setFormValues(prev => ({
            ...prev,
            ...offerValues
          }));
        }
      }
      setInitLoad(false);
    }
    getInitialdata();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    if(((userContext.roleSelected === 2 || userContext.roleSelected === 3) && action !== 'reuse' && reopened) || userContext.roleSelected === 4 ){
      setDisableInput(true)
    }else{
      setDisableInput(false)
    }
  },[action, reopened, userContext.roleSelected])

  useEffect(()=>{
    if (!initLoad) {
      setFormValues(prev => ({
        ...prev,
        city: 0
      }));
      setCities([]);
    }
    if(formValues.country === 0){
      if (!initLoad) {
        setFormValues(prev => ({
          ...prev,
          department:0,
          city:0
        }));
        setCities([]);
      }
    }else {
      if(formValues.country === 170){
        setCities([]);
      } else {
        const countryObjSelected = countryCitiesArray.find(country=>country.id === formValues.country);
        if(countryObjSelected){
          setCities(countryObjSelected.childs);
        }
      }
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[formValues.country])

  useEffect(()=>{
    setCities([]);
    if(formValues.department>0){
      let citiesDep:Array<IGeneralParameter> = cityArray.filter(city=> city.parentId === formValues.department);
      citiesDep = sortByKey(citiesDep, 'name');
      if(citiesDep.length>0){
        setCities(citiesDep)
      }
    }
    if(offerResponse.city>0){
      setOfferResponse(prev => ({
        ...prev,
        city:0
      }))
    } else {
      setFormValues(prev => ({
        ...prev,
        city:0
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.department])

  useEffect(()=>{
    const getPrograms = async()=>{
      if(formValues.knowlegeArea.length>0){
        let subareasArr: Array<IGeneralParameter>  = [];
        const responseSubArea: Array<IGeneralParameter> = await getSubArea();
        formValues.knowlegeArea.forEach(obj=>{
          const elementsFound = responseSubArea.filter(item=>item.parentId === obj.parameter_code);
          if(elementsFound.length>0){
            elementsFound.forEach(elm=>{
              subareasArr.push(elm);
            })
          }
        });
        if (subareasArr.length>0){
          subareasArr = sortByKey(subareasArr, 'name');
          setArrayProfessions(subareasArr);
          let convertedProfessions: Array<IGeneralParameter> = [];
          if(offerResponse.professions.length>0){
            offerResponse.professions.forEach(profession=>{
              const objFind = subareasArr.find(obj=>obj.parameter_code === profession.idParameter);
              if(objFind){
                convertedProfessions.push(objFind)
              }
            })
            if(convertedProfessions.length>0){
              setFormValues(prev => ({
                ...prev,
                professions: convertedProfessions
              }));
            }
            setOfferResponse(prev => ({
              ...prev,
              professions: []
            }));
          } else{
            if(formValues.professions.length>0){
              let tempArray:Array<IGeneralParameter> = [];
              formValues.professions.forEach(obj=>{
                const proffesionFound = subareasArr.find(item =>item.parameter_code === obj.parameter_code);
                if(proffesionFound){
                  tempArray.push(proffesionFound)
                }
              });
              setFormValues(prev => ({
                ...prev,
                professions: tempArray
              }));
            }
          }
        }
      }
    }
    getPrograms();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.knowlegeArea])

  useEffect(()=>{
    setFormValues(prev => ({
      ...prev,
      nameCompanyVisible: showName,
      salaryVisible: showSalary,
      variableSalary: showVariableSalary,
      experience: experience,
      disability: disability,
      telework: telework
    }));
  },[showName, showSalary, showVariableSalary, experience, disability, telework])

  const handleChangeAccoridon = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeAccoridon2 = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded2(isExpanded ? panel : false);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
    const error = Validations(name, value, formValidations) || "";
    setFormErrors(prev=>({
        ...prev,
        [name]: error
    }));
  }

  const handleCloseModalSuccess = ()=>{
    setOpenModalSuccess(false);
    window.location.reload();
  };

  const handleCloseModalError = ()=>{
    setOpenModalError(false);
    window.location.reload();
  };
  
  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCheckState({ ...checkState, [event.target.name]: event.target.checked });
    if(name === 'showVariableSalary' && !checked){
      setFormValues(prev => ({
        ...prev,
        descriptionVariableSalary: ''
      }));
    }
    if(name === 'experience' && checked){
      setFormValues(prev => ({
        ...prev,
        experience: true,
        experienceYears: 0,
        experienceMonths: 0
      }));
    }
  };

  // Handle form date change
  const handleChangeDate = (dateValue: Date | null, nameField: string) => {
    const value =dateValue ? dateValue?.toString() : '';
    setFormValues(prev => ({
      ...prev,
      [nameField]: dateValue
    }));
    if(nameField === 'jobOfferEndDate'){
      formValidations.jobOfferEndDate.minDate = formValues.jobOfferStartDate ? moment(new Date(formValues.jobOfferStartDate)).add(10, 'days').toDate() : moment(new Date()).add(10, 'days').toDate();
      if(formValues.estimatedDateCompletion !== null){
        formValidations.estimatedDateCompletion.maxDate = moment(formValues.jobOfferEndDate).add(4, 'months').toDate();
        const valueEstimate = formValues.estimatedDateCompletion ? formValues.estimatedDateCompletion?.toString() : '';
        const error: string = Validations('estimatedDateCompletion', valueEstimate , formValidations) || "";
        setFormErrors(prev=>({
          ...prev,
          estimatedDateCompletion: error
        }));
      }
    }
    if(nameField === 'estimatedDateCompletion'){
      formValidations.estimatedDateCompletion.minDate = formValues.jobOfferStartDate ? moment(new Date(formValues.jobOfferStartDate)).add(10, 'days').toDate() : moment(new Date()).add(10, 'days').toDate();
      formValidations.estimatedDateCompletion.maxDate = formValues.jobOfferEndDate ? moment(formValues.jobOfferEndDate).add(4, 'months').toDate() : new Date();
    }
    const error: string = Validations(nameField, value, formValidations) || "";
    setFormErrors(prev=>({
      ...prev,
      [nameField]: error
    }));
    if(nameField === 'jobOfferStartDate'){
      if(formValues.jobOfferEndDate !== null){
        const valueFinal =formValues.jobOfferEndDate ? formValues.jobOfferEndDate?.toString() : '';
        formValidations.jobOfferEndDate.minDate = moment(new Date(value)).add(10, 'days').toDate();
        const error: string = Validations('jobOfferEndDate', valueFinal , formValidations) || "";
        setFormErrors(prev=>({
          ...prev,
          jobOfferEndDate: error
        }));
      }
      if(formValues.estimatedDateCompletion !== null){
        const valueEstimate =formValues.estimatedDateCompletion ? formValues.estimatedDateCompletion?.toString() : '';
        formValidations.estimatedDateCompletion.minDate = moment(new Date(value)).add(10, 'days').toDate();
        formValidations.estimatedDateCompletion.maxDate = moment(formValues.jobOfferEndDate).add(4, 'months').toDate();
        const error: string = Validations('estimatedDateCompletion', valueEstimate , formValidations) || "";
        setFormErrors(prev=>({
          ...prev,
          estimatedDateCompletion: error
        }));
      }
    }
  }

  const handleAddProffesion = ()=>{
    if(formValues.profession>0){
      const proffesionObj = arrayProfessions.find(item => item.parameter_code === formValues.profession);
      if(proffesionObj){
        const alreadyAdded = formValues.professions.find(obj => obj.parameter_code === proffesionObj.parameter_code);
        if(!alreadyAdded){
          setFormValues(prev => ({
            ...prev,
            professions: [...formValues.professions, proffesionObj]
          }));
        }
      }
    }
    setFormValues(prev => ({
      ...prev,
      profession: 0
    }));
  };

  const deleteProffesion = (e:any, idProffesion: number)=>{
    const newArray = formValues.professions.filter(proffesion=>proffesion.parameter_code !== idProffesion);
    setFormValues(prev => ({
      ...prev,
      professions: newArray
    }));
  };

  const handleAddSkill = ()=>{
    if(formValues.skill>0){
      const skillObj = AllSkills.find(item => item.parameter_code === formValues.skill);
      if(skillObj){
        const alreadyAdded = formValues.skills.find(obj => obj.parameter_code === skillObj.parameter_code);
        if(!alreadyAdded){
          setFormValues(prev => ({
            ...prev,
            skills: [...formValues.skills, skillObj]
          }));
        }
      }
    }
    setFormValues(prev => ({
      ...prev,
      skill: 0
    }));
  };

  const deleteSkill = (e:any, idSkill: number)=>{
    const newArray = formValues.skills.filter(skill=>skill.parameter_code !== idSkill);
    setFormValues(prev => ({
      ...prev,
      skills: newArray
    }));
  };

  const AddOtherSkill = ()=>{
    const otherSkillObj = {
      name: formValues.other_skill
    }
    setFormValues(prev => ({
      ...prev,
      otherSkills: [...formValues.otherSkills, otherSkillObj]
    }));
    setFormValues(prev => ({
      ...prev,
      other_skill: ""
    }));
  }

  const deleteOtherSkill = (e:any, skillName: number)=>{
    const newArray = formValues.otherSkills.filter(other=>other.name !== skillName);
    setFormValues(prev => ({
      ...prev,
      otherSkills: newArray
    }));
  }

  const AddKeyword = ()=>{
    const keyWordObj = {
      name: formValues.keyword
    }
    setFormValues(prev => ({
      ...prev,
      keywords: [...formValues.keywords, keyWordObj]
    }));
    setFormValues(prev => ({
      ...prev,
      keyword: ""
    }));
  }

  const deleteKeyword = (e:any, keyword: number)=>{
    const newArray = formValues.keywords.filter(key=>key.name !== keyword);
    setFormValues(prev => ({
      ...prev,
      keywords: newArray
    }));
  }

  const handleAddOccupation = ()=>{
    if(formValues.occupation>0){
      const ocupationObj = allOccupations.find(item => item.parameter_code === formValues.occupation);
      if(ocupationObj){
        const alreadyAdded = formValues.occupations.find(obj => obj.parameter_code === ocupationObj.parameter_code);
        if(!alreadyAdded){
          setFormValues(prev => ({
            ...prev,
            occupations: [...formValues.occupations, ocupationObj]
          }));
        }
      }
    }
    setFormValues(prev => ({
      ...prev,
      occupation: 0
    }));
  };

  const deleteOccupation = (e:any, idOccupation: number)=>{
    const newArray = formValues.occupations.filter(occu=>occu.parameter_code !== idOccupation);
    setFormValues(prev => ({
      ...prev,
      occupations: newArray
    }));
  };

  const handleAddProgram = ()=>{
    if(formValues.program>0){
      const programObj = allPrograms.find(item => item.parameter_code === formValues.program);
      if(programObj){
        const alreadyAdded = formValues.programs.find(obj => obj.parameter_code === programObj.parameter_code);
        if(!alreadyAdded){
          setFormValues(prev => ({
            ...prev,
            programs: [...formValues.programs, programObj]
          }));
        }
      }
    }
    setFormValues(prev => ({
      ...prev,
      program: 0
    }));
  };

  const deleteProgram = (e:any, idProgram: number)=>{
    const newArray = formValues.programs.filter(prog=>prog.parameter_code !== idProgram);
    setFormValues(prev => ({
      ...prev,
      programs: newArray
    }));
  };

  const addLanguage = ()=>{
    if(formValues.languageSelected>0){
      const languageObj = allLanguages.find(item => item.parameter_code === formValues.languageSelected);
      if(languageObj){
        const alreadyAdded = formValues.language.find(obj => obj.parameter_code === languageObj.parameter_code);
        if(!alreadyAdded){
          setFormValues(prev => ({
            ...prev,
            language: [...formValues.language, languageObj]
          }));
        }
      }
    }
    setFormValues(prev => ({
      ...prev,
      languageSelected: 0
    }));
  }

  const deleteLanguage = (e:any, idLanguage: number)=>{
    const newArray = formValues.language.filter(languageItem=>languageItem.parameter_code !== idLanguage);
    setFormValues(prev => ({
      ...prev,
      language: newArray
    }));
  };

  const confirmSend = async()=>{
      setOpenModalConfirm(true);
  }
  const closeModalConfirm = () =>{
      setOpenModalConfirm(false);
  }

  const requestOffer = async()=>{
    setOpenModalConfirm(false);
    console.dir(formValues)
    const offerToSend: ICreateOffer = new ICreateOffer(formValues);
    if(userContext.action && userContext.action === 'Add'){
      delete offerToSend.id;
      console.dir(offerToSend);

      const responseCOF: Response = await trackPromise(createLaboralOffer(
        {...offerToSend, modifyUser: userContext.userId}
      ));
      if(responseCOF.status === 200){
        setSuccessMessage("La oferta se ha creado con exito. Al cumplirse el plazo establecido por “Fecha final de convocatoria abierta”, se cambiará automáticamente al siguiente estado “En selección”, si requiere que se amplíe el plazo debe entrar con anticipación y correr la “Fecha final de convocatoria abierta”");
        setOpenModalSuccess(true);
      } else if (responseCOF.status === 400){
        setErrorMessage("espacio para error del servicio");
        setOpenModalError(true);
      } else {
        setErrorMessage("Hubo un error, intente de nuevo mas tarde");
        setOpenModalError(true);
      }
    } else if (userContext.action && userContext.action === 'Modify'){
      const responseULO: Response = await trackPromise(updateLaboralOffers(
        {...offerToSend, modifyUser: userContext.userId}
      ));
      if(responseULO.status === 200){
        setSuccessMessage("La oferta se ha actualizado con exito");
        setOpenModalSuccess(true);
      } else if (responseULO.status === 400){
        setErrorMessage("espacio para error del servicio");
        setOpenModalError(true);
      } else {
        setErrorMessage("Hubo un error, intente de nuevo mas tarde");
        setOpenModalError(true);
      }
    } else if (userContext.action && userContext.action === 'Reuse'){
      delete offerToSend.id;
      const responseCOR: Response = await trackPromise(createLaboralOffer(
        {...offerToSend, modifyUser: userContext.userId}
      ));
      if(responseCOR.status === 200){
        setSuccessMessage("Se ha creado una nueva oferta");
        setOpenModalSuccess(true);
      } else {
        setErrorMessage("Hubo un error, intente de nuevo mas tarde");
        setOpenModalError(true);
      }
    }
  }

  const goBack = ()=>{
    if(offerId>0){
      window.location.reload();
    }else{
      setUrl('/empresa/dashboard');
    }
  }


  return (
    <>
    {
      url !== null ?
      <Redirect to={url} />
    :
    <>
      <Grid item xs={12} lg={10} className="offer-form-info-container">
        <Grid item xs={12} style={{margin:'0 10px'}}>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccoridon('panel1')} className="summary-offer-accordion-container">
            <AccordionSummary
              expandIcon={ <ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className="accordion-summary"
            >
              <Typography variant="h2" className="bold-serif">Resumen de la oferta</Typography>
            </AccordionSummary>
            <AccordionDetails className="summary-offer-accordion-details">
              <Grid container item xs={12} direction="row">
                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif field-title-typePosition">Tipo de cargo*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-typePosition"
                    select
                    name="typePosition"
                    label={formValues.typePosition === 0 ? "Seleccione": ""}
                    value={formValues.typePosition === 0 ? "" : formValues.typePosition}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    margin="none"
                    variant="outlined"
                    disabled={disableInput}
                    // disabled={((userContext.roleSelected === 2 || userContext.roleSelected === 2) && (action !== "Reuse" && reopened)) || userContext.roleSelected === 4 ? true : false}
                  >
                    {
                      allTypePositions && allTypePositions.length>0 ?
                      allTypePositions.map((option: IGeneralParameter) => (
                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                          {option.name}
                        </MenuItem>
                      ))
                      :
                        <MenuItem value={formValues.typePosition}></MenuItem>
                    }
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                  <Typography variant="h5" className="bold-serif">Nombre del cargo*</Typography>
                  <TextField
                    fullWidth
                    name="jobTitle"
                    margin="none"
                    label={formValues.jobTitle === "" ? "Ingrese Nombre del cargo": ""}
                    value={formValues.jobTitle }
                    onChange={handleChange}
                    error={!!formErrors.jobTitle}
                    helperText={formErrors.jobTitle}
                    InputLabelProps={{shrink: false}}
                    variant="outlined"
                    disabled={action !== "Reuse" && reopened}
                  />
                </Grid>

                <Grid item xs={12} sm={4} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif">Cantidad de vacantes*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-numberVacancy"
                    select
                    name="numberVacancy"
                    value={formValues.numberVacancy === 0 ? "" : formValues.numberVacancy}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    margin="none"
                    variant="outlined"
                    className="numberVacancy-box"
                    disabled={disableInput}
                  >
                    {
                      ArrayVacancies && ArrayVacancies.length>0 ?
                      ArrayVacancies.map((option: number) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))
                      :
                        <MenuItem value={formValues.numberVacancy}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={4} className="field-box-margin">
                  <Grid item xs={12} className="checkbox-container">
                    <FormControlLabel
                      control={
                      <Checkbox
                        checked={showName}
                        onChange={handleChangeCheckBox}
                        name="showName"
                        disabled={disableInput}
                      />}
                      label="Mostrar nombre de la empresa"
                      className="checkbox"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} className="field-box-margin">
                </Grid>
                <Grid item xs={12} sm={4} className="field-box-margin special-box-right">
                  <FormGroup row className=" checkbox-container">
                    <FormControlLabel
                      control={
                      <Checkbox
                        checked={disability}
                        onChange={handleChangeCheckBox}
                        name="disability"
                        disabled={disableInput}
                      />}
                      label="Discapacidad"
                      className="checkbox"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={4} className="field-box-margin special-box-right">
                  <FormGroup row className=" checkbox-container">
                    <FormControlLabel
                      control={
                      <Checkbox
                        checked={telework}
                        onChange={handleChangeCheckBox}
                        name="telework"
                        disabled={disableInput}
                      />}
                      label="Teletrabajo"
                      className="checkbox"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={4} className="field-box-margin">
                </Grid>
                <Grid item xs={12}  className="field-box-margin">
                  <Typography variant="h5" className="bold-serif">Descripción de la oferta*</Typography>
                  <TextField
                    fullWidth
                    name="description"
                    placeholder="Descripción "
                    multiline
                    rows={3}
                    variant="outlined"
                    value={formValues.description || ""}
                    onChange={handleChange}
                    error={!!formErrors.description}
                    helperText={formErrors.description}
                    margin="none"
                    disabled={action !== "Reuse" && reopened}
                  />
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif">País*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-country"
                    select
                    name="country"
                    label={formValues.country === 0 ? "Seleccione": ""}
                    value={formValues.country === 0 ? "" : formValues.country}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    margin="none"
                    variant="outlined"
                    disabled={disableInput}
                  >
                    {
                      countryCitiesArray && countryCitiesArray.length> 0 &&
                      <MenuItem value={0}><em>Ninguno</em></MenuItem>
                    }
                    {countryCitiesArray && countryCitiesArray.length>0 ?
                      countryCitiesArray.map((option: IListParametersInfo) => (
                        <MenuItem disabled={option.id === 170 ? false : true} key={option.id} value={option.id}>
                          {option.grandParentName}
                        </MenuItem>
                      ))
                    :
                      <MenuItem value={formValues.country}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                  <Typography variant="h5" className="bold-serif">Departamento*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-department"
                    select
                    name="department"
                    label={formValues.department === 0 ? "Seleccione": ""}
                    value={formValues.department === 0 ? "" : formValues.department}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    disabled={(formValues.country>0 && departmentArray.length>0 && formValues.country === 170 ? false : true) || disableInput}
                    margin="none"
                    variant="outlined"
                  >
                    {
                      departmentArray.length> 0 ?
                      departmentArray.map((option: IGeneralParameter) => (
                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                          {option.name}
                        </MenuItem>
                      ))
                      :
                        <MenuItem value={formValues.department}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif">Municipio*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-city"
                    select
                    name="city"
                    label={formValues.city === 0 ? "Seleccione": ""}
                    value={formValues.city === 0 ? "" : formValues.city}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    disabled={(cities && ((formValues.country === 170 && formValues.department >0) || (formValues.country !== 170 && formValues.country > 0)) ? false : true) || disableInput}
                    margin="none"
                    variant="outlined"
                  >
                    {
                      cities.length> 0 ?
                      cities.map((option: IGeneralParameter) => (
                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                          {option.name}
                        </MenuItem>
                      ))
                      :
                        <MenuItem value={formValues.city}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin">

                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif">Sector laboral*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-laboralSector"
                    select
                    name="laboralSector"
                    label={formValues.laboralSector === 0 ? "Seleccione": ""}
                    value={formValues.laboralSector === 0 ? "" : formValues.laboralSector}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    disabled={(allLaboralSector.length>0 ? false : true) || disableInput}
                    margin="none"
                    variant="outlined"
                  >
                    {
                      allLaboralSector.length> 0 ?
                      allLaboralSector.map((option: IGeneralParameter) => (
                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                          {option.name}
                        </MenuItem>
                      ))
                      :
                        <MenuItem value={formValues.laboralSector}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                  <Typography variant="h5" className="bold-serif">Tipo de contrato*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-typeContract"
                    select
                    name="typeContract"
                    label={formValues.typeContract === 0 ? "Seleccione": ""}
                    value={formValues.typeContract === 0 ? "" : formValues.typeContract}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    margin="none"
                    variant="outlined"
                    disabled={disableInput}
                  >
                    {
                      allTypeContract && allTypeContract.length>0 ?
                      allTypeContract.map((option: IGeneralParameter) => (
                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                          {option.name}
                        </MenuItem>
                      ))
                      :
                        <MenuItem value={formValues.typeContract}></MenuItem>
                    }
                  </TextField>
                </Grid>
                <Grid container item xs={12} alignItems="center" className="salary-container">
                  <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                    <FormGroup row className=" checkbox-container">
                      <FormControlLabel
                        control={
                        <Checkbox
                          checked={showSalary}
                          onChange={handleChangeCheckBox}
                          name="showSalary"
                          disabled={disableInput}
                        />}
                        label="Mostrar rango salarial"
                        className="checkbox"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                    <Typography variant="h5" className="bold-serif">Salario (Mensual)*</Typography>
                    <TextField
                      fullWidth
                      id="outlined-select-rangeSalary"
                      select
                      name="rangeSalary"
                      label={formValues.rangeSalary === "" ? "Seleccione": ""}
                      value={formValues.rangeSalary === "" ? "" : formValues.rangeSalary }
                      onChange={handleChange}
                      InputLabelProps={{shrink: false}}
                      margin="none"
                      variant="outlined"
                      disabled={disableInput}
                    >
                      {
                        salaryRange && salaryRange.length>0 ?
                        salaryRange.map((option: string, index: number) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))
                        :
                          <MenuItem value={formValues.rangeSalary}></MenuItem>

                      }
                    </TextField>
                  </Grid>

                  <Grid item xs={12} className="special-variable-salary-container">
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                      <FormGroup row className="checkbox-container">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={showVariableSalary}
                              onChange={handleChangeCheckBox}
                              name="showVariableSalary"
                              disabled={disableInput}
                            />}
                            label="Salario variable"
                            className="checkbox"
                        />
                      </FormGroup>
                  </Grid>

                  <Grid item xs={12} sm={6} >
                  </Grid>

                    {showVariableSalary ?
                      <>
                        <Grid item xs={12} className="field-box-margin special-box-both">
                          <Typography variant="h5" className="bold-serif">Descripción del salario variable*
                          </Typography>
                          <TextField
                            fullWidth
                            name="descriptionVariableSalary"
                            placeholder="Descripción "
                            multiline
                            rows={3}
                            variant="outlined"
                            value={formValues.descriptionVariableSalary || ""}
                            onChange={handleChange}
                            error={!!formErrors.descriptionVariableSalary}
                            helperText={formErrors.descriptionVariableSalary}
                            margin="normal"
                            disabled={disableInput}
                          />
                        </Grid>
                      </>
                    :
                      <Grid item xs={12}></Grid>
                    }
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} lg={4} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="field-box-title-special">Fecha inicio de convocatoria abierta*</Typography>
                  <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                    <DatePicker
                      openTo="year"
                      views={['year', 'month', 'date']}
                      value={formValues.jobOfferStartDate || null}
                      onChange={(value)=>handleChangeDate(value, 'jobOfferStartDate')}
                      renderInput={(params) => (
                        <TextField
                          disabled
                          {...params}
                          fullWidth
                          name="jobOfferStartDate"
                          margin="none"
                          label={(formValues.jobOfferStartDate && formValues.jobOfferStartDate !== null )? "": "Seleccione Fecha dd/mm/aaaa"}
                          error={!!formErrors.jobOfferStartDate}
                          helperText={formErrors.jobOfferStartDate}
                          InputLabelProps={{shrink: false}}
                          variant="outlined"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} lg={4} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="field-box-title-special">Fecha final de convocatoria abierta*</Typography>
                  <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                    <DatePicker
                      openTo="year"
                      views={['year', 'month', 'date']}
                      value={formValues.jobOfferEndDate || null}
                      onChange={(value)=>handleChangeDate(value, 'jobOfferEndDate')}
                      renderInput={(params) => (
                        <TextField
                          disabled
                          {...params}
                          fullWidth
                          name="jobOfferEndDate"
                          margin="none"
                          label={formValues.jobOfferEndDate !== null ? "": "Seleccione Fecha dd/mm/aaaa"}
                          error={!!formErrors.jobOfferEndDate}
                          helperText={formErrors.jobOfferEndDate}
                          InputLabelProps={{shrink: false}}
                          variant="outlined"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} lg={4} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif">Fecha estimada de finalización del proceso de selección*</Typography>
                  <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                    <DatePicker
                      openTo="year"
                      views={['year', 'month', 'date']}
                      value={formValues.estimatedDateCompletion || null}
                      onChange={(value)=>handleChangeDate(value, 'estimatedDateCompletion')}
                      renderInput={(params) => (
                        <TextField
                          disabled
                          {...params}
                          fullWidth
                          name="estimatedDateCompletion"
                          margin="none"
                          label={formValues.estimatedDateCompletion !== null ? "": "Seleccione Fecha dd/mm/aaaa"}
                          error={!!formErrors.estimatedDateCompletion}
                          helperText={formErrors.estimatedDateCompletion}
                          InputLabelProps={{shrink: false}}
                          variant="outlined"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid container item xs={12} className="inside-accordion-container">
                  <Accordion expanded={expanded2 === 'panel3'} onChange={handleChangeAccoridon2('panel3')} className="summary-inside-accordion-container">
                    <AccordionSummary
                      expandIcon={!expanded2? <AddIcon />: <RemoveIcon />}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                      className="accordion-summary"
                    >
                      <Typography variant="h4" className="bold-serif">Resumen de la oferta</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="summary-inside-accordion-details">
                      <OfferCard
                        companyName={companyName}
                        nameCompanyVisible={formValues.nameCompanyVisible}
                        cityId={formValues.city}
                        departmentId={formValues.department}
                        initialDate={formValues.jobOfferStartDate}
                        offerName={formValues.jobTitle}
                        offerDescription={formValues.description}
                        vacancies={formValues.numberVacancy}
                        positionId={formValues.typePosition}
                        contractId={formValues.typeContract}
                        salaryVisible={formValues.salaryVisible}
                        salary={formValues.rangeSalary}
                        description_salary={formValues.descriptionVariableSalary}
                        finalDate={formValues.jobOfferEndDate}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccoridon('panel2')} className="summary-offer-accordion-container">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              className="accordion-summary"
            >
              <Typography variant="h2" className="bold-serif">Detalle de la oferta</Typography>
            </AccordionSummary>
            <AccordionDetails className="summary-offer-accordion-details">
              <Grid container item xs={12} direction="row">
                <Grid item xs={12} className="field-box-margin special-box-right">
                  <FormControl fullWidth>
                    <Typography variant="h5" className="bold-serif">Area de conocimientos*</Typography>
                    <Select
                      id="demo-mutiple-checkbox-knowlegeArea"
                      multiple
                      name="knowlegeArea"
                      value={formValues.knowlegeArea}
                      onChange={handleChange}
                      disabled={(allKnowledgeArea.length>0 ? false : true) || disableInput}
                      input={<BootstrapInput />}
                      renderValue={(selected) => {
                        return ((selected as IGeneralParameter[]).map(elm => elm.name)).join(', ')
                      }}
                      MenuProps={MenuProps}
                      variant="outlined"
                    >
                      {allKnowledgeArea.map((option) => (
                        <MenuItem
                          key={option.parameter_code}
                          // @ts-ignore [1]
                          value={option}
                        >
                          <Checkbox checked={(formValues.knowlegeArea.findIndex(item=>item.name === option.name)) > -1} />
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} >
                </Grid>

                <Grid item xs={12} sm={10} className="field-box-margin special-box-right">
                  <Typography variant="h4" className="bold-serif">Profesiones [NBC]*</Typography>
                    <span className="warning-alert-text">
                      <b className="red-title">Importante:</b> Para agregar las profesiones por favor de clic al botón de "Agregar"
                    </span>
                  <TextField
                    fullWidth
                    id="outlined-select-profession"
                    select
                    name="profession"
                    label={formValues.profession === 0 ? "Seleccione": ""}
                    value={formValues.profession === 0 ? "" : formValues.profession}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    margin="none"
                    variant="outlined"
                    disabled={(arrayProfessions.length>0 ? false : true) || disableInput}
                  >
                    {
                      arrayProfessions && arrayProfessions.length>0 ?
                      arrayProfessions.map((option: IGeneralParameter) => (
                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                          {option.name}
                        </MenuItem>
                      ))
                      :
                        <MenuItem value={formValues.profession}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={2} className="add-container">
                  <Button disabled={formValues.profession === 0} variant="contained" className="add-btn" endIcon={<AddIcon />} onClick={handleAddProffesion}>
                    <Typography variant="h6" className="regular-serif">
                      Agregar
                    </Typography>
                  </Button>
                </Grid>

                {
                  formValues.professions && formValues.professions.length>0 &&
                  formValues.professions.map((proffesionSelected: IGeneralParameter)=>(
                    <Grid key={proffesionSelected.parameter_code} container item xs={12} className="detail-container">
                      <div className="detail-description">
                        <Typography variant="h5" className="regular-serif">
                          {proffesionSelected.name}
                        </Typography>
                      </div>
                      <Button disabled={disableInput} onClick={(e)=>deleteProffesion(e,proffesionSelected.parameter_code)} variant="contained" className="del-btn" endIcon={<DeleteIcon/>} >
                        <Typography variant="h6" className="regular-serif">
                          Eliminar
                        </Typography>
                      </Button>
                    </Grid>
                  ))
                }
                <Grid container item xs={12}>
                  <hr className="divider-accordion"/>
                </Grid>

                <Grid item xs={12} sm={10} className="field-box-margin special-box-right">
                  <Typography variant="h4" className="bold-serif">Ocupaciones</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-occupation"
                    select
                    name="occupation"
                    label={formValues.occupation === 0 ? "Seleccione": ""}
                    value={formValues.occupation === 0 ? "" : formValues.occupation}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    margin="none"
                    variant="outlined"
                    disabled={(allOccupations.length>0 ? false : true) || disableInput}
                  >
                    {
                      allOccupations && allOccupations.length>0 ?
                      allOccupations.map((option: IGeneralParameter) => (
                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                          {option.name}
                        </MenuItem>
                      ))
                      :
                        <MenuItem value={formValues.occupation}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={2} className="add-container">
                  <Button disabled={formValues.occupation === 0} onClick={handleAddOccupation} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                    <Typography variant="h6" className="regular-serif">
                      Adicionar
                    </Typography>
                  </Button>
                </Grid>

                {
                  formValues.occupations && formValues.occupations.length>0 &&
                  formValues.occupations.map((occupationSelected: IGeneralParameter)=>(
                    <Grid key={occupationSelected.parameter_code} container item xs={12} className="detail-container">
                      <div className="detail-description">
                        <Typography variant="h5" className="regular-serif">
                          {occupationSelected.name}
                        </Typography>
                      </div>
                      <Button disabled={disableInput} onClick={(e)=>deleteOccupation(e,occupationSelected.parameter_code)} variant="contained" className="del-btn" endIcon={<DeleteIcon/>} >
                        <Typography variant="h6" className="regular-serif">
                          Eliminar
                        </Typography>
                      </Button>
                    </Grid>
                  ))
                }

                <Grid item xs={12} sm={10} className="field-box-margin special-box-right">
                  <Typography variant="h4" className="bold-serif">Programas*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-program"
                    select
                    name="program"
                    label={formValues.program === 0 ? "Seleccione": ""}
                    value={formValues.program === 0 ? "" : formValues.program}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    margin="none"
                    variant="outlined"
                    disabled={(allPrograms.length>0 ? false : true) || disableInput}
                  >
                    {
                      allPrograms && allPrograms.length>0 ?
                      allPrograms.map((option: IGeneralParameter) => (
                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                          {option.name}
                        </MenuItem>
                      ))
                      :
                        <MenuItem value={formValues.program}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={2} className="add-container">
                  <Button disabled={formValues.program === 0} onClick={handleAddProgram} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                    <Typography variant="h6" className="regular-serif">
                      Adicionar
                    </Typography>
                  </Button>
                </Grid>

                {
                  formValues.programs && formValues.programs.length>0 &&
                  formValues.programs.map((programSelected: IGeneralParameter)=>(
                    <Grid key={programSelected.parameter_code} container item xs={12} className="detail-container">
                      <div className="detail-description">
                        <Typography variant="h5" className="regular-serif">
                          {programSelected.name}
                        </Typography>
                      </div>
                      <Button disabled={disableInput} onClick={(e)=>deleteProgram(e,programSelected.parameter_code)} variant="contained" className="del-btn" endIcon={<DeleteIcon/>} >
                        <Typography variant="h6" className="regular-serif">
                          Eliminar
                        </Typography>
                      </Button>
                    </Grid>
                  ))
                }

                <Grid item xs={12} sm={10} className="field-box-margin special-box-right">
                  <Typography variant="h4" className="bold-serif">Palabras Clave</Typography>
                  <TextField
                    fullWidth
                    name="keyword"
                    margin="none"
                    label={formValues.keyword === "" ? "Ingrese otra palabra clave": ""}
                    value={formValues.keyword }
                    onChange={handleChange}
                    error={!!formErrors.keyword}
                    helperText={formErrors.keyword}
                    InputLabelProps={{shrink: false}}
                    variant="outlined"
                    disabled={disableInput}
                  />
                </Grid>

                <Grid item xs={12} sm={2} className="add-container">
                  <Button disabled={formErrors.keyword.length > 0 || formValues.keyword === ""} onClick={AddKeyword} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                    <Typography variant="h6" className="regular-serif">
                      Adicionar
                    </Typography>
                  </Button>
                </Grid>

                {
                  formValues.keywords && formValues.keywords.length>0 &&
                  formValues.keywords.map((keywordSelected: any, index)=>(
                    <Grid key={index} container item xs={12} className="detail-container">
                      <div className="detail-description">
                        <Typography variant="h5" className="regular-serif">
                          {keywordSelected.name}
                        </Typography>
                      </div>
                      <Button disabled={disableInput} onClick={(e)=>deleteKeyword(e,keywordSelected.name)} variant="contained" className="del-btn" endIcon={<DeleteIcon/>} >
                        <Typography variant="h6" className="regular-serif">
                          Eliminar
                        </Typography>
                      </Button>
                    </Grid>
                  ))
                }

                <Grid container item xs={12}>
                  <hr className="divider-accordion"/>
                </Grid>

                <Grid item xs={12} className="field-box-margin ">
                  <Typography variant="h4" className="bold-serif">
                    Tiempo de experiencia*
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={4} className="bold-serif title-box-text special-box-right checkbox-top-curriculum-container">
                    <FormControlLabel
                        control={
                            <Checkbox
                            checked={experience}
                            onChange={handleChangeCheckBox}
                            name="experience"
                            />}
                        label="Sin experiencia"
                        labelPlacement="top"
                        className="checkbox-top"
                    />
                </Grid>

                <Grid item xs={12} sm={4} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif">Años</Typography>
                  <TextField
                    fullWidth
                    disabled={formValues.experience ? true : false}
                    id="outlined-select-experienceYears"
                    select
                    name="experienceYears"
                    value={formValues.experienceYears}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    margin="none"
                    variant="outlined"
                  >
                    {
                      ArrayYears && ArrayYears.length>0 ?
                      ArrayYears.map((option: number) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))
                      :
                        <MenuItem value={formValues.experienceYears}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={4} className="field-box-margin special-box-left">
                  <Typography variant="h5" className="bold-serif">Meses</Typography>
                  <TextField
                    fullWidth
                    disabled={formValues.experience ? true : false}
                    id="outlined-select-experienceMonths"
                    select
                    name="experienceMonths"
                    value={formValues.experienceMonths}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    margin="none"
                    variant="outlined"
                  >
                    {
                      ArrayMonths && ArrayMonths.length>0 ?
                      ArrayMonths.map((option: number) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))
                      :
                        <MenuItem value={formValues.experienceMonths}></MenuItem>
                    }
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={10} className="field-box-margin special-box-right">
                  <Typography variant="h4" className="bold-serif">Competencias</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-skill"
                    select
                    name="skill"
                    label={formValues.skill === 0 ? "Seleccione": ""}
                    value={formValues.skill === 0 ? "" : formValues.skill}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    margin="none"
                    variant="outlined"
                    disabled={(AllSkills.length>0 ? false : true) || disableInput}
                  >
                    {
                      AllSkills && AllSkills.length>0 ?
                      AllSkills.map((option: IGeneralParameter) => (
                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                          {option.name}
                        </MenuItem>
                      ))
                      :
                        <MenuItem value={formValues.skill}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={2} className="add-container">
                  <Button disabled={formValues.skill === 0} onClick={handleAddSkill} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                    <Typography variant="h6" className="regular-serif">
                      Adicionar
                    </Typography>
                  </Button>
                </Grid>

                {
                  formValues.skills && formValues.skills.length>0 &&
                  formValues.skills.map((skillSelected: IGeneralParameter)=>(
                    <Grid key={skillSelected.parameter_code} container item xs={12} className="detail-container">
                      <div className="detail-description">
                        <Typography variant="h5" className="regular-serif">
                          {skillSelected.name}
                        </Typography>
                      </div>
                      <Button disabled={disableInput} onClick={(e)=>deleteSkill(e,skillSelected.parameter_code)} variant="contained" className="del-btn" endIcon={<DeleteIcon/>} >
                        <Typography variant="h6" className="regular-serif">
                          Eliminar
                        </Typography>
                      </Button>
                    </Grid>
                  ))
                }

                <Grid container item xs={12} >
                  <hr className="divider-accordion"/>
                </Grid>

                <Grid item xs={12} sm={10} className="field-box-margin special-box-right">
                  <Typography variant="h4" className="bold-serif">Otras competencias</Typography>
                  <TextField
                    fullWidth
                    name="other_skill"
                    margin="none"
                    label={formValues.other_skill === "" ? "Ingrese otra competencia": ""}
                    value={formValues.other_skill }
                    onChange={handleChange}
                    error={!!formErrors.other_skill}
                    helperText={formErrors.other_skill}
                    InputLabelProps={{shrink: false}}
                    variant="outlined"
                    disabled={disableInput}
                  />
                </Grid>

                <Grid item xs={12} sm={2} className="add-container">
                  <Button disabled={formValues.other_skill === ""} onClick={AddOtherSkill} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                    <Typography variant="h6" className="regular-serif">
                      Adicionar
                    </Typography>
                  </Button>
                </Grid>

                {
                  formValues.otherSkills && formValues.otherSkills.length>0 &&
                  formValues.otherSkills.map((otherSkillSelected: any, index)=>(
                    <Grid key={index} container item xs={12} className="detail-container">
                      <div className="detail-description">
                        <Typography variant="h5" className="regular-serif">
                          {otherSkillSelected.name}
                        </Typography>
                      </div>
                      <Button disabled={disableInput} onClick={(e)=>deleteOtherSkill(e,otherSkillSelected.name)} variant="contained" className="del-btn" endIcon={<DeleteIcon/>} >
                        <Typography variant="h6" className="regular-serif">
                          Eliminar
                        </Typography>
                      </Button>
                    </Grid>
                  ))
                }

                <Grid container item xs={12}>
                  <hr className="divider-accordion"/>
                </Grid>

                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                  <Typography variant="h5" className="bold-serif">Nivel Educativo Exigido</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-academicLevel"
                    select
                    name="academicLevel"
                    label={formValues.academicLevel === 0 ? "Seleccione": ""}
                    value={formValues.academicLevel === 0 ? "" : formValues.academicLevel}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    margin="none"
                    variant="outlined"
                    disabled={(allAcademic.length>0 ? false : true) || disableInput}
                  >
                    {
                      allAcademic && allAcademic.length>0 ?
                      allAcademic.map((option: IGeneralParameter) => (
                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                          {option.name}
                        </MenuItem>
                      ))
                      :
                        <MenuItem value={formValues.academicLevel}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={10} className="field-box-margin special-box-right">
                  <Typography variant="h4" className="bold-serif">Idiomas exigidos</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-languageSelected"
                    select
                    name="languageSelected"
                    label={formValues.languageSelected === 0 ? "Seleccione": ""}
                    value={formValues.languageSelected === 0 ? "" : formValues.languageSelected}
                    onChange={handleChange}
                    InputLabelProps={{shrink: false}}
                    margin="none"
                    variant="outlined"
                    disabled={(AllSkills.length>0 ? false : true) || disableInput}
                  >
                    {
                      allLanguages && allLanguages.length>0 ?
                      allLanguages.map((option: IGeneralParameter) => (
                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                          {option.name}
                        </MenuItem>
                      ))
                      :
                        <MenuItem value={formValues.languageSelected}></MenuItem>
                    }
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={2} className="add-container">
                  <Button disabled={formValues.languageSelected === 0} onClick={addLanguage} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                    <Typography variant="h6" className="regular-serif">
                      Adicionar
                    </Typography>
                  </Button>
                </Grid>

                {
                  formValues.language && formValues.language.length>0 &&
                  formValues.language.map((languageSelect: IGeneralParameter)=>(
                    <Grid key={languageSelect.parameter_code} container item xs={12} className="detail-container">
                      <div className="detail-description">
                        <Typography variant="h5" className="regular-serif">
                          {languageSelect.name}
                        </Typography>
                      </div>
                      <Button disabled={disableInput} onClick={(e)=>deleteLanguage(e,languageSelect.parameter_code)} variant="contained" className="del-btn" endIcon={<DeleteIcon/>} >
                        <Typography variant="h6" className="regular-serif">
                          Eliminar
                        </Typography>
                      </Button>
                    </Grid>
                  ))
                }

              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Grid container item xs={12} lg={2} justify="center" style={{height:'fit-content'}}>
        <NotificationBox
          bgColor="#CFF4FC"
          title="Fecha estimada de finalización del proceso de selección de la oferta"
          titleColor="#055160"
          description="Una vez cumplida la “Fecha estimada de finalización del proceso de selección de la oferta”, la empresa contará con un plazo de 4 meses para actualizar el estado y cerrar la oferta posterior a este plazo, la oferta pasará automáticamente a estado “Finalizado”, con la causal “Cerrado por el sistema”."
          descColor="#055160"
          icon={<ErrorOutlineIcon style={{color:'#055160'}} fontSize="large"/>}
        />
        <div className="space-notification-boxes">
          <NotificationBox
            bgColor="#CFF4FC"
            title="Fecha final de convocatoria abierta"
            titleColor="#055160"
            description="El campo “Fecha final de convocatoria abierta” no podrá ser menor a 10 días calendario iniciando desde la Fecha inicio de convocatoria abierta”."
            descColor="#055160"
            icon={<ErrorOutlineIcon style={{color:'#055160'}} fontSize="large"/>}
          />
        </div>
        <div className="space-notification-boxes">
          <NotificationBox
            bgColor="#CFF4FC"
            title="Mensaje informativo"
            titleColor="#055160"
            description="Una vez termine la definición de su oferta, la Universidad comienza un proceso de revisión para publicación. En caso de cualquier requerimiento se le estará informando."
            descColor="#055160"
            icon={<ErrorOutlineIcon style={{color:'#055160'}} fontSize="large"/>}
          />
        </div>
      </Grid>
      <Grid container item xs={12} className="buttons-container-offer">
        <Button startIcon={<ChevronLeftIcon />} className="cancel-button" variant="contained" size="small" onClick={goBack}>
          <Typography variant="h5" className="regular-serif">
            Cancelar
          </Typography>
        </Button>
        <Button disabled={!isValid} endIcon={<ChevronRightIcon />} onClick={confirmSend} className="confirm-button" variant="contained" size="small">
          <Typography variant="h5" className="regular-serif">
            Confirmar
          </Typography>
        </Button>
      </Grid>
      <ModalOffersAction
          open={opeModalConfirm}
          handleClose={closeModalConfirm}
          handleContinue={requestOffer}
          iconModal={<WarningIcon className="big-info-icon" />}
          title="¿Está seguro de crear la oferta?"
          headerdescription=""
          description="Esta intentando crear una oferta"
          backBtnText="Regresar"
          colorBtnBack= ""
          continueBtnText="Si, Continuar"
          colorBtnContinue="#14A5DC"
          valid={true}
      />
      <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
      <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
      <LoadingLockPage load={initLoad}/>
      <LoadingLockPage load={promiseInProgress}/>
      </>
    }
    </>
  )
}

export default OfferForm

/*
[1]:
Type 'ITicketTopicOption' is not assignable to type 'string | number | string[] | undefined'.
  Type 'ITicketTopicOption' is missing the following properties from type 'string[]': length, pop, push, concat, and 28 more.
*/