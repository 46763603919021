import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { Grid } from '@material-ui/core';
import UserContext from '../../../../utils/UserContext';
import { isLogin } from '../../../../services/AuthService';
import RolesOptions from '../../../../components/RolesAdmin/RolesOptions';
import UsersManagement from '../UsersManagement';
import RolesManagement from '../RolesManagement';
import TransactionalReports from '../ReportsManagement/TransactionalReports';


const GeneralRolesManagement: React.FC = () => {
  const userContext = React.useContext(UserContext);
  const [selectedSection, setSelectedSection] = useState<string>('');

  useEffect(()=>{
    window.scrollTo(0, 0);
    const urlPath = window.location.href;
    if(urlPath.includes('usuarios')){
        setSelectedSection('Usuarios administradores');
        userContext.setRoleOption('Usuarios administradores')
    } else if(urlPath.includes('gestion-roles')) {
        setSelectedSection('Gestionar roles');
        userContext.setRoleOption('Gestionar roles');
    } else if(urlPath.includes('reportes-transacciones')) {
        setSelectedSection('Reportes');
        userContext.setRoleOption('Reportes');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[window.location.href])


  return (
    <>
      {isLogin() ?
        <>
          <Grid item xs={12} className="state-offers-section">
            <RolesOptions selected={selectedSection} />
          </Grid>
          {selectedSection=== "Usuarios administradores" &&
              <Grid container item xs={12}>
                  <UsersManagement/>
              </Grid>
          }
          {selectedSection=== "Gestionar roles" &&
              <Grid container item xs={12}>
                  <RolesManagement/>
              </Grid>
          }
          {selectedSection=== "Reportes" &&
              <Grid container item xs={12}>
                  <TransactionalReports/>
              </Grid>
          }
        </>
      :
        <Redirect to ="/"/>
      }
    </>
  )
}

export default GeneralRolesManagement;