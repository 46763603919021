import React, { useState, useEffect } from 'react';
import moment from 'moment';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventIcon from '@material-ui/icons/Event';
import { getCATEGORY } from '../../../services/ParameterService';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';

import './NewsCard.scss';


interface NewsCardProps{
    type: string;
    categoryId?: number;
    name?: string;
    publishDate?: string;
    shortDescription?: string;
    image?: string;
    dateStart?: string;
    timeStart?: string;
    dateFinish?: string;
    timeFinish?: string;
    place?: string;
    children?: any;
}

const NewsCard: React.FC<NewsCardProps> = ({type, categoryId, name, publishDate, shortDescription, image,dateStart, timeStart, dateFinish, timeFinish, place, children}) => {
  const [categoryName, setCategoryName] = useState<string>('');

  useEffect(() => {
    const getData = async()=>{
      let responseCategories:Array<IGeneralParameter> = await getCATEGORY();
      const catFound = responseCategories.find(item=> item.parameter_code === categoryId);
      setCategoryName(catFound?.name ? catFound?.name : '');
    }
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <Grid container item xs={12} md={12} lg={12} className="card-related-column">
        <Grid className="card-related-box">
          <Grid item xs={12} className="card-related-box-info">
            <CardMedia
              component="img"
              alt={name}
              height="180"
              image={image}
              title={name}
            />
            <Typography variant="h6" className="type-description">
              <BookmarkIcon className="category-icon"/>
              <span>{categoryName}</span>
            </Typography>
            <Grid className="card-related-content">
              <Grid container>
                <Typography variant="h2" className="card-title">
                  {name}
                </Typography>
              </Grid>
              {type === "Noticia" && publishDate && publishDate.length>0 &&
                <Grid container>
                  <Typography variant="h6" className="date-section">
                  <EventIcon className="date-icon"/>
                    {moment(new Date(publishDate+"")).locale('es').format("dddd, DD MMMM YYYY")}
                  </Typography>
                </Grid>
              }
              {type === "Evento" &&
                <>
                  {
                    dateStart && dateStart.length>0 &&
                    <Grid container>
                      <Typography variant="h6" className="date-section">
                      <EventIcon className="icon-class"/>
                        {moment(new Date(dateStart+"")).locale('es').format("dddd, DD MMMM YYYY")}
                        {timeStart && <span>{timeStart}</span>}
                      </Typography>
                    </Grid>
                  }
                  {
                    dateFinish && dateFinish.length>0 &&
                    <Grid container>
                      <Typography variant="h6" className="date-section">
                      <EventIcon className="icon-class" />
                        {moment(new Date(dateFinish+"")).locale('es').format("dddd, DD MMMM YYYY")}
                        {timeFinish && <span>{timeFinish}</span>}
                      </Typography>
                    </Grid>
                  }
                  {
                    place && place.length >0 &&
                    <Grid container>
                      <Typography variant="h6" className="date-section">
                      <LocationOnIcon className="icon-class"/>
                        {place}
                      </Typography>
                    </Grid>
                  }
                </>
              }
              <Grid container className="summary-container">
                <Typography variant="h4" className="card-summary-news">
                  {shortDescription}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <div className="card-related-box-actions">
            {children &&
              <div className="card-related-button">
                {children}
              </div>
            }
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default NewsCard
