import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import './ConfirmActionBox.scss';

interface ConfirmActionBoxProps{
    users:Array<any>;
    state:string;
    cancelClick: any;
    sendChangeState: any;
    isValid: boolean;
}

const ConfirmActionBox: React.FC<ConfirmActionBoxProps> = ({users, state, cancelClick, sendChangeState, isValid})=>{

    return(
        <Grid container item xs={12} className="confirm-actions-container">
            <Grid item xs={12} lg={6} xl={8} className="confirm-text-container">
                <Grid container item xs={2} sm={1} justify="center">
                    <EmojiObjectsIcon className="confirm-icon"/>
                </Grid>
                <Grid item xs={10} sm={11} className="confirm-description-container">
                    <Typography variant="h2" className="bold-serif-primaryColor">
                    Acción a Ejecutar:
                    </Typography>
                    <Typography variant="h3" className="bold-serif-primaryColor">
                    Cambiar estado a <span className="confirm-state-text">"{users.length>0 ? state : null}"</span>
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={6} xl={4} className="confirm-actions-buttons">
                <Button variant="contained" className="cancel-action-button" onClick={cancelClick}>
                    <Typography variant="h5" className="regular-serif">
                        Cancelar
                    </Typography>
                </Button>
                <Button variant="contained" disabled={!isValid} className="confirm-action-button" onClick={sendChangeState}>
                    <Typography variant="h5" className="regular-serif">
                        Confirmar y ejecutar
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

export default ConfirmActionBox;