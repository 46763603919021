import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SecurityIcon from "@material-ui/icons/Security";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import UserContext from '../../utils/UserContext';

import "./ChangePasswordBox.scss";

const ChangePasswordBox: React.FC = () => {
  const userContext = React.useContext(UserContext);

  useEffect(()=>{
    userContext.setTemporaryKey(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <Grid container>
        <Grid container className="title-container" alignItems="center">
          <Grid item>
            <SecurityIcon className="security-icon" />
          </Grid>
          <Grid item>
            <Typography variant="h3" className="title-text">
              Actualizar mi contraseña
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <hr className="line-title" />
          </Grid>
        </Grid>
        <Grid container item xs={12} className="password-container">
          <Grid item xs={12} className="success-message-box">
            <CheckCircleOutlineIcon className="check-Outlined-icon" />
            <span className="check-Outlined-text">
              Su contraseña ha sido actualizada exitosamente
            </span>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Button
            variant="contained"
            className="step-change-password-back"
            startIcon={<NavigateBeforeIcon />}
            component={ Link } to="/empresa/dashboard"
          >
            Continuar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ChangePasswordBox;
