export class IApplicantInfo{
    id: number | null;
    name: string;
    state: string;
    graduateId: number | null;
    salaryAspiration: number | null;
    eqKeywords: string;
    eqOccuptations: string;
    constructor(def:any){
        this.id = def.postulationId || null;
        this.name = def.name || "";
        this.state = def.state || "";
        this.graduateId = def.graduateId || null;
        this.salaryAspiration = def.salaryAspiration || null;
        this.eqKeywords = def.eqKeywords || "";
        this.eqOccuptations = def.eqOccuptations || "";
    }
}

