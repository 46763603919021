import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import './DownloadModal.scss';

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="download-dialog-header" {...other}>
      <Typography variant="h2" className="download-dialog-title" style={{ marginRight: '16px' }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface DownloadModalProps {
  downloadMsg: string;
  downloadMsg2: string;
  handleCloseModal: any;
  openModalDownload: boolean;
  urlRedirect?: string;
  urlReport: string;
  total: string;
}

const DownloadModal: React.FC<DownloadModalProps> = ({ downloadMsg, downloadMsg2, handleCloseModal, openModalDownload, urlRedirect, urlReport, total }) => {

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={openModalDownload} className="download-dialog-container">
      <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
        Exito!
      </DialogTitle>
      <DialogContent className="download-dialog-content">
        <Typography variant="h5" className="text-content-dialog">{downloadMsg}</Typography>
        <Typography variant="h5" className="text-content-dialog2">{downloadMsg2}</Typography>
        <Typography variant="h5" className="text-content-dialog3">Total de registros encontrados: <span className="bold-serif">{total}</span></Typography>
      </DialogContent>
      <DialogActions className="download-dialog-actions">
        <div style={{ width: '100%' }}>
          <a className="download-list-button" href={urlReport}>
            <Button className="download-btn" variant="contained" size="small">
              <Typography variant="h5" className="regular-serif">Descargar</Typography>
            </Button>
          </a>
        </div>
      </DialogActions>
      <DialogActions className="download-dialog-actions">
        <div style={{ width: '100%' }}>
          {
            urlRedirect ?
              <Button component={Link} to={urlRedirect} className="accept-button">
                <Typography variant="h5" className="regular-serif">
                  Aceptar
                </Typography>
              </Button>
              :
              <Button onClick={handleCloseModal} className="accept-button">
                <Typography variant="h5" className="regular-serif">
                  Aceptar
                </Typography>
              </Button>
          }
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default DownloadModal;