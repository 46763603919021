export class IRole{
    idAssign?: number = 0;
    id?:number = 0;
    roleId?: number = 0;
    name?: string = "";
    school?: number = 0;
    schoolName?: string = "";
    campus?: number = 0;
    campusName?: string = "";
    createdAt?: string;
    updatedAt?: string;
}