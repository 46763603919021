import React, { useState, useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { DataGrid, GridColDef, GridOverlay, GridColParams, GridCellParams, GridPageChangeParams, GridBaseComponentProps } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import { IApplicant } from '../../../interfaces/IApplicant';
import ModalStateAction from '../ModalStateAction'
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import { IApplicantInfo } from '../../../interfaces/IApplicantInfo';
import { statePostulationService } from '../../../services/PostulationService';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import UserContext from '../../../utils/UserContext';
import { getGraduateDocuments } from '../../../services/GraduateService';
import { IGetDocuments } from '../../../interfaces/IGetDocuments';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { getSALARY_ASPIRATION } from '../../../services/ParameterService';

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 14,
            padding: '5px 6px 10px 12px',
            height: '30px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
        },
    }),
)(InputBase);

interface ApplicantListProps {
    action: string;
    rows: Array<IApplicantInfo>;
    pageSize: number;
    onChanhePageSize: any;
    initPage: number;
    setInitPage: any;
    handleView?: any;
    status?: string;
}

const ApplicantList: React.FC<ApplicantListProps> = ({ action, rows, pageSize, onChanhePageSize, initPage, setInitPage, handleView }) => {

    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [rowSelected, setRowSelected] = useState<IApplicant>(new IApplicant());
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [state, setState] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [openModalError, setOpenModalError] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [salaryAspArr, setSalaryAspArr] = useState<Array<IGeneralParameter>>([]);
    const states = [
        { id: 1, name: 'Cambiar estado a Seleccionado', state: 'Seleccionado' },
        { id: 2, name: 'Cambiar estado a Preseleccionado', state: 'Preseleccionado' },
        { id: 3, name: 'Cambiar estado a En espera', state: 'En espera' },
    ]

    const columnsApplicant: GridColDef[] = [
        {
            field: 'name', headerName: 'Aspirante', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Aspirante'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell">{params.value}</span>
                )
            }
        },
        {
            field: 'salaryAspiration', headerName: 'Aspiracion Salarial', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Aspiracion Salarial'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                const valueSalary = salaryAspArr.find(element => {
                    if (params.value === element.parameter_code) return true
                    else return false
                })
                return (
                    <span className="name-cell" id="shortDescription-cell">{valueSalary ? valueSalary.name : ""}</span>
                )
            }
        },
        {
            field: 'eqKeywords', headerName: 'Palabras Claves ', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Palabras Claves'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell">{params.value}</span>
                )
            }
        },
        {
            field: 'eqOccuptations', headerName: 'Ocupaciones ', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Ocupaciones '}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'state', headerName: 'Estado', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Estado'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => (
                <strong>
                    {params.value === 'Preseleccionado' && <div className="verified-state state-applicant-button-cell">Preseleccionado</div>}
                    {params.value === 'Seleccionado' && <div className="active-state state-applicant-button-cell">Seleccionado</div>}
                    {params.value === 'En espera' && <div className="inactive-state state-applicant-button-cell">En espera</div>}
                    {params.value === 'Retirado' && <div className="rejected-state state-applicant-button-cell">Retirado</div>}
                </strong>
            )
        },

        {
            field: 'Acción', headerName: 'Acciones', width: 150, sortable: false, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Acciones'}
                    </span>
                </strong>
            ), renderCell: (params: GridCellParams) => (
                <>
                    <Button disabled={(action !== 'Select')} aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleClick(e, params)}>
                        <SettingsIcon />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        {
                            states.map(item => (
                                <MenuItem key={item.id} value={item.state} onClick={(e) => { handleCloseMenu(); handleRowButton(item.state) }}>{item.name}</MenuItem>
                            ))}
                        <MenuItem onClick={(e) => { handleCloseMenu(); handleView(rowSelected) }}>
                            Ver hoja de vida UNAL
                        </MenuItem>
                        <MenuItem onClick={(e) => { handleCloseMenu(); handleViewCV(rowSelected) }}>
                            Ver hoja de vida Personal
                        </MenuItem>
                    </Menu>
                    <ModalStateAction
                        open={openModal}
                        handleClose={handleCloseModal}
                        handleContinue={handleConfirm}
                        title="Cambiar estado."
                        backBtnText="Cancelar"
                        colorBtnBack=""
                        continueBtnText="Confirmar"
                        continueBtnColor="#00B5C0"
                        userSelected={rowSelected}
                        state={state}
                    />
                </>
            )
        },
    ];

    useEffect(() => {
        const getInitialData = async () => {
            const reqArray: Array<IGeneralParameter> = await getSALARY_ASPIRATION();
            reqArray.sort((a, b) => a.parameter_code - b.parameter_code)
            setSalaryAspArr(reqArray);
        }
        getInitialData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleViewCV = async (rowSelected: IApplicant) => {
        const graduateId = rowSelected.graduateId ? rowSelected.graduateId : null;
        if (graduateId) {
            const responseDocuments = await getGraduateDocuments(graduateId);
            if (responseDocuments.status === 200) {
                const resultDocuments: Array<IGetDocuments> = await responseDocuments.json();
                const cvDoc = resultDocuments.find(doc => doc.key.includes("Egresado_hoja_de_vida.pdf"));
                if (cvDoc) {
                    const cvLink = cvDoc.signedUrl;
                    window.open(cvLink);
                } else {
                    setErrorMessage('El aspirante no ha subido su hoja de vida personal')
                    setOpenModalError(true);
                }
            } else {
                setErrorMessage('El aspirante no ha subido su hoja de vida personal')
                setOpenModalError(true);
            }
        }
    }

    const handleRowButton = (state: string) => {
        setState(state);
        setOpenModal(true);
    }

    const handleConfirm = async () => {
        setOpenModal(false);
        const responseSP = await trackPromise(statePostulationService(
            { postulationId: rowSelected.id, state: state, modifyUser: userContext.userId }
        ));
        validateModals(responseSP);
    }

    const handleCloseModalSuccess = () => {
        setOpenModalSuccess(false);
        window.location.reload();
    };

    const handleCloseModalError = () => {
        setOpenModalError(false);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, params: GridCellParams) => {
        setAnchorEl(event.currentTarget);
        const user: IApplicant = Object.assign(params.row);
        setRowSelected(user)
    };

    const handlePage = (pageParams: GridPageChangeParams) => {
        setInitPage(1)
    }

    const validateModals = async (responseService: Response) => {
        if (responseService.status === 200) {
            const result = await responseService.json();
            setSuccessMessage(result.message);
            setOpenModalSuccess(true);
            setOpenModalError(false);
        } else if (responseService.status === 400) {
            const result = await responseService.json();
            setErrorMessage(result.message);
            setOpenModalError(true);
            setOpenModalSuccess(false);
        } else {
            setErrorMessage("Hubo un error, intente de nuevo mas tarde");
            setOpenModalError(true);
            setOpenModalSuccess(false);
        }
    }

    return (
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid autoHeight className="data-grid-list" disableColumnMenu rows={rows} columns={columnsApplicant} pageSize={pageSize} pagination density="compact" disableSelectionOnClick onPageChange={(params: GridPageChangeParams) => handlePage(params)}
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                        Footer: (props) => CustomFooter(props, initPage, onChanhePageSize, pageSize)
                    }}
                />
            </Grid>
            <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
            <LoadingLockPage load={promiseInProgress} />
        </Grid>
    );
}

const CustomFooter = (props: GridBaseComponentProps, initPage: number, onChanhePageSize?: any, valueRows?: number) => {
    const { state, api } = props;
    const rowsPerPageOptions = [5, 10, 25, 50, 100];

    useEffect(() => {
        if (initPage === 0) {
            api.current.setPage(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initPage]);

    return (
        <>
            <div className="footer-container">
                <div className="rows-number-container">
                    <span className="rows-number-text">Mostrar</span>
                    <Select
                        id="demo-simple-select"
                        value={valueRows}
                        onChange={onChanhePageSize}
                        input={<BootstrapInput />}
                    >
                        {rowsPerPageOptions.map(item => (
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                    <span className="rows-number-text">Registros</span>
                </div>
                <Pagination
                    className="pagination-container"
                    color="primary"
                    page={state.pagination.page + 1}
                    count={state.pagination.pageCount}
                    onChange={(event, value) => api.current.setPage(value - 1)}
                    showFirstButton
                    showLastButton
                />
            </div>
        </>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large" />
            <div className="not-results-text">Sin registros</div>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps) => <KeyboardArrowUpIcon fontSize="small" />
const customSortDesc = (props: GridBaseComponentProps) => <KeyboardArrowDownIcon fontSize="small" />

export default ApplicantList;