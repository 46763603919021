import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden';

import './ApplicantCurriculumInfoSection.scss'

interface SectionPreviewProps{
    cols: number;
    firstTitle?:string;
    firtsValue?:string;
    secondTitle?:string;
    secondValue?: string;
    thirdTitle?: string;
    thirdValue?: string;
    fourthTitle?: string;
    fourthValue?: string;
    children?:any;
}

const ApplicantCurriculumInfoSection:React.FC<SectionPreviewProps> = ({cols, firstTitle,firtsValue,secondTitle,secondValue,thirdTitle,thirdValue,fourthTitle,fourthValue, children})=> {
    return (
        <>
            <Grid container item xs={12} direction="row">
                <Grid container item xs={12} alignItems="flex-start">
                    <Grid container className="subsection-container">
                        <Grid container item xs={12}>
                            <Typography variant="h5" className="bold-serif section-title-complete">
                                {firstTitle}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Typography variant="h5" className="regular-serif section-value-complete">
                                {firtsValue}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Hidden only={cols>=2 ? [] : ['xs','sm']}>
                        <Grid className="subsection-container">
                            <Grid item xs={12}>
                                <Typography variant="h5" className="bold-serif section-title-complete">{cols>=2 ? secondTitle : ''}</Typography>
                            </Grid>
                            <Grid item xs={12} className="section-value-complete">
                                <Typography variant="h5" className="regular-serif">{cols>=2 ? secondValue : ''}</Typography>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden only={cols>=3 ? [] : ['xs','sm', 'md']}>
                        <Grid className="subsection-container">
                            <Grid item xs={12}>
                                <Typography variant="h5" className="bold-serif section-title-complete">{cols>=2 ? thirdTitle : ''}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" className="regular-serif section-value-complete">{cols>=2 ? thirdValue : ''}</Typography>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden only={cols>=4 ? [] : ['xs','sm', 'md']}>
                        <Grid className="subsection-container">
                            <Grid item xs={12}>
                                <Typography variant="h5" className="bold-serif section-title-complete">{cols>=2 ? fourthTitle : ''}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" className="regular-serif section-value-complete">{cols>=2 ? children ? children : fourthValue : ''}</Typography>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid>
        </>
    )
}

export default ApplicantCurriculumInfoSection
