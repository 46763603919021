import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from "react-router-dom";
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Grid from '@material-ui/core/Grid';
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import { isLogin } from '../../../../services/AuthService';
import { IGetSurveys } from '../../../../interfaces/IGetSurveys';
import PreviewSurvey from '../../../../components/Surveys/PreviewSurvey';
import { getSurveys } from '../../../../services/SurveyServices';
import UserContext from '../../../../utils/UserContext';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import { containsAny } from '../../../../helpers/sharedFunctions';


const PreviewSurveyPage:React.FC = () =>{
    const userContext = React.useContext(UserContext);
    const { promiseInProgress } = usePromiseTracker();
    let params: any = useParams();
    const [detailSelected, setDetailSelected] = useState<IGetSurveys>(new IGetSurveys());

    useEffect(()=>{
        window.scrollTo(0, 0);
        sessionStorage.removeItem('surveyAnonimous');
        sessionStorage.removeItem('eventAnonimous');
        sessionStorage.removeItem('offerAnonimous');
        const getDetailData = async()=>{
            if(params.id){
                const responseDetail: Response = await trackPromise(getSurveys({id:parseInt(params.id)}));
                if(responseDetail.status === 200){
                    const resultDetail = (await responseDetail.json()).message[0];
                    setDetailSelected(resultDetail);
                }
            }
        }
        getDetailData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return(
        <>
            {isLogin() ?
                <>
                    {userContext.roleSelected === 5 &&
                        <Grid container item xs={12} className="back-offers-list-section">
                            <ButtonBackListOffers url="/admin/dashboard/encuestas" text="Regresar al listado de encuestas"/>
                        </Grid>
                    }
                    {userContext.roleSelected === 1 &&
                        <Grid container item xs={12} className="back-offers-list-section">
                            <ButtonBackListOffers url="/egresado/dashboard" text="Regresar al dashboard"/>
                        </Grid>
                    }
                    {userContext.roleSelected !== 1 && userContext.roleSelected !== 5 &&
                        <Grid container item xs={12} className="back-offers-list-section">
                            <ButtonBackListOffers url="/" text="Regresar al inicio"/>
                        </Grid>
                    }
                    {containsAny([1,5],[userContext.roleSelected]) ?
                        <>
                            {detailSelected.name.length>0 &&
                                <PreviewSurvey
                                    surveySelected={detailSelected}
                                />
                            }
                        </>
                    :
                        <Redirect to="/" />
                    }
                </>
            :
                <>
                    <Grid container item xs={12} className="back-offers-list-section">
                        <ButtonBackListOffers url="/" text="Regresar al inicio"/>
                    </Grid>
                    {detailSelected.name.length>0 &&
                        <PreviewSurvey
                            surveySelected={detailSelected}
                        />
                    }
                </>
            }
            <LoadingLockPage load={promiseInProgress}/>
        </>
    )
}

export default PreviewSurveyPage;