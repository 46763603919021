import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Link, useParams } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import ProfileCompanyView from '../../company/ProfileCompanyView/ProfileCompanyView';
import { IHeadquarter, IProfileCompany } from '../../../../interfaces/IProfileCompany';
import { IGeneralParameter } from '../../../../interfaces/IGeneralParameter';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { getCompanyInfo } from '../../../../services/CompanyService';
import { sortByKey, sortByKeyDesc } from '../../../../helpers/sharedFunctions';
import { getCITY } from '../../../../services/ParameterService';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import UserContext from '../../../../utils/UserContext';


const CompanyInformation = () => {
  let params: any = useParams();
  const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
  const userContext = React.useContext(UserContext);
  const { promiseInProgress } = usePromiseTracker();
  const [companyData, setCompanyData] = useState<IProfileCompany>(new IProfileCompany());
  const [headquartersCompany, setHeadquartersCompany] = useState<Array<IHeadquarter>>([]);
  const [cityArray, setCityArray] = useState<Array<IGeneralParameter>>([]);

  useEffect(()=>{
    const getCompanyData = async()=>{
      if(params.nit){
        const nit = parseInt(params.nit, 10);
        const companyInfoRes = await trackPromise(getCompanyInfo(userToken, nit));
        if(companyInfoRes.status === 200){
          const companyInfoResult = await companyInfoRes.json();
          const target: IProfileCompany = new IProfileCompany();
          if(companyInfoResult && companyInfoResult.company){
            Object.assign(target, companyInfoResult.company);
            if(target){
              setCompanyData(target);
            }
          }
          if(companyInfoResult && companyInfoResult.headquarter.length>0){
            let headquartesRes =  companyInfoResult.headquarter;
            headquartesRes = sortByKeyDesc(headquartesRes, 'updatedAt');
            setHeadquartersCompany(headquartesRes);
          }
        }
      }
      //data for cities
      let dataCity: Array<IGeneralParameter> = await getCITY();
      dataCity = sortByKey(dataCity, 'name');
      setCityArray(dataCity ? dataCity : []);
    }
    getCompanyData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <>
      <Grid container item xs={12} className="back-button-applicants-section">
        <Grid container item xs={12} className="main-container">
          <Grid container item xs={12} className="back-btn-container">
            <Button
              variant="contained"
              className="step-back-offers"
              startIcon={<NavigateBeforeIcon />}
              component={Link}
              to={'/admin/dashboard/empresas'}
            >
              <Typography variant="h6" className="regular-serif">
                Regresar a listado de empresas
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <div>
        {cityArray.length>0 &&
          <ProfileCompanyView companyData={companyData} cityArray={cityArray} headquartersCompany={headquartersCompany} action="Admin"/>
        }
      </div>
      <LoadingLockPage load={promiseInProgress} />
    </>
  )
}

export default CompanyInformation
