import React from 'react';
import HeaderDashboard from '../HeaderDashboard';
import UnalFooter from '../UnalFooter/UnalFooter';

const DashboardLayout: React.FC = ({children})=>{
    return(
        <>
            <HeaderDashboard/>
            <div className="general-container">
                {children}
            </div>
            <UnalFooter/>
        </>
    )
}

export default DashboardLayout;