import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { IRole } from '../../../../interfaces/IRole';
import { sortByKey, sortByKeyDesc } from '../../../../helpers/sharedFunctions';
import { IUserRole } from '../../../../interfaces/IUserRole';
import { FiltersRoles, FiltersRolesLimit } from '../../../../interfaces/FiltersRoles';
import { ChipData } from '../../../../interfaces/ChipData';
import { IAssociateRole } from '../../../../interfaces/IAssociateRole';
import MoreFiltersRoles from '../../../../components/RolesAdmin/MoreFiltersRoles';
import ShowFiltersApplied from '../../../../components/ManagementState/ShowFiltersApplied';
import SearchTable from '../../../../components/ManagementState/SearchTable';
import UsersSIETable from '../../../../components/RolesAdmin/UsersSIETable';
import ActivateUserModal from '../../../../components/RolesAdmin/ActivateUserModal';
import AssignRoleModal from '../../../../components/RolesAdmin/AssignRoleModal';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import ErrorModal from '../../../../components/ShareComponents/ErrorModal';
import SuccessModal from '../../../../components/ShareComponents/SuccessModal';
import { IGeneralParameter } from '../../../../interfaces/IGeneralParameter';
import { IListParametersInfo } from '../../../../interfaces/IListParametersInfo';
import { getCAMPUS_FACULTIES } from '../../../../services/ParameterService';
import { associateRoles, changestatusUser, getlistRoles, getUsersWithRoles } from '../../../../services/AdminServices';
import UserContext from '../../../../utils/UserContext';

import './UsersManagement.scss';


const initialErrorsSearch={
    searched:''
}

const UsersManagement: React.FC = () =>{
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [url, setUrl] = useState<string>("");
    const [rowsPerPage, setRowsPerPage]= useState(5);
    const [usersRoleList, setUsersRoleList] = useState<Array<IUserRole>>([]);
    const [usersRoleListFiltered, setUsersRoleListFiltered] = useState<Array<IUserRole>>([]);
    const [rolesArray, setRolesArray] = useState<Array<IRole>>([]);
    const [allCampus, setAllCampus] = useState<Array<IListParametersInfo>>([]);
    const [schools, setSchools] = useState<Array<IGeneralParameter>>([]);
    const [formValues, setFormValues]= useState<IRole>(new IRole());
    const [formFilters, setFormFilters] = useState<FiltersRoles>(new FiltersRoles());
    const [ buttonFilter, setButtonFilter ] = useState<boolean>(false);
    const [chipData, setChipData] = useState<ChipData[]>([]);
    const [searched, setSearched] = useState('');
    const [searchActive, setSearchActive] = useState(false);
    const [initPage, setInitPage]= useState<number>(0);
    const [assignArray, setAssignArray] = useState<Array<IRole>>([]);
    const [rowSelected, setRowSelected] = useState<IUserRole>(new IUserRole());
    const [actionLabel, setActionLabel] = useState<string>("");
    const [action, setAction] = useState<string>("");
    const [openModalActivate, setOpenModalActivate] = useState<boolean>(false);
    const [openModalAssign, setOpenModalAssign] = useState<boolean>(false);
    const [errorLabel, setErrorLabel] = useState<string>("");
    const [errorMessage, setErrorMessage]= useState('');
    const [openModalError, setOpenModalError] = useState(false);
    const [successMessage, setSuccessMessage]= useState('');
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const formErrorSearch = initialErrorsSearch;
    const [currrentPage, setCurrentPage] = useState<number>(0);
    const limitNumber = 100;

    const isValidAdd =
        formValues.id && formValues.id > 0 &&
        (
            (formValues.id ===4 && formValues.campus && formValues.campus > 0) ||
            (
                formValues.id ===6 && formValues.campus && formValues.campus > 0 &&
                formValues.school && formValues.school > 0
            ) ||
            ( formValues.id !== 4 && formValues.id !== 6 )
        )

    useEffect(()=>{
        window.scrollTo(0, 0);
        const getInitData = async()=>{
            const responseRoles = await getlistRoles();
            let resultListRoles: Array<IRole> = [];
            if(responseRoles.status === 200){
                resultListRoles = (await responseRoles.json()).rolesInfo;
                resultListRoles = sortByKey(resultListRoles, 'name');
                setRolesArray(resultListRoles);
            }
            const responseUsersWithRol = await getUsersWithRoles(
                userToken,
                {
                    limit: limitNumber === rowsPerPage ? limitNumber+25 : limitNumber,
                    offset: 0
                }
            );
            if(responseUsersWithRol.status === 200){
                let usersWithRol = (await responseUsersWithRol.json()).usersShow;
                usersWithRol.forEach((userRole: IUserRole) =>{
                    const rolesItem: Array<string> = [];
                    const rolesIds: Array<number> = [];
                    userRole.roles.forEach(item => {
                        const roleFound = resultListRoles.find(role=>role.id === item.roleId);
                        if(roleFound && roleFound.name && roleFound.id){
                            rolesItem.push(roleFound.name);
                            rolesIds.push(roleFound.id);
                        }
                    });
                    userRole.rolesString = rolesItem.length>0 ? rolesItem.toString() : "";
                    userRole.rolesIds = rolesIds;
                    userRole.id = userRole.userId;
                });
                usersWithRol = sortByKeyDesc(usersWithRol, 'userId');
                setUsersRoleList(usersWithRol);
                setUsersRoleListFiltered(usersWithRol);
            }
            // data for campus field
            let dataAllCampus: Array<IListParametersInfo> = (await getCAMPUS_FACULTIES()).list;
            dataAllCampus = sortByKey(dataAllCampus, 'parentName');
            setAllCampus(dataAllCampus ? dataAllCampus : []);
            setInitLoad(false);
        }
        getInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        setSchools([]);
        setFormValues(prev => ({
            ...prev,
            school: 0,
        }))
        if(formValues.campus && formValues.campus>0){
            const campusFound = allCampus.find(campus=> campus.id === formValues.campus);
            if(campusFound){
              const campus: IListParametersInfo = campusFound;
              let schools = campus.childs;
              schools = sortByKey(schools, 'name');
              setSchools(schools);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formValues.campus])

    useEffect(()=>{
        if(formFilters.username.length > 0 || formFilters.stateUser.length>0 || formFilters.roleUser>0){
            setButtonFilter(true)
        } else {
            setButtonFilter(false)
        }
    },[formFilters])

    useEffect(()=>{
        const checkForNewData = async() => {
            if(usersRoleList.length>0){
                const totalRows = usersRoleList.length;
                const totalPages = Math.ceil(totalRows/rowsPerPage);
                let lastPage = false;
                if(totalPages-currrentPage === 0){
                    lastPage = true;
                } else {
                    lastPage = false;
                }
                const objectToSend = transformDataRolesUser(formFilters, limitNumber === rowsPerPage ? limitNumber+25 : limitNumber, totalRows);
                if(lastPage){
                    updateDataRolesUser(objectToSend, usersRoleList);
                }
            }
        }
        checkForNewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currrentPage, rowsPerPage])

    const transformDataRolesUser = (values: FiltersRoles, limit: number, offset: number)=>{
        const temporaryObject: FiltersRolesLimit = new FiltersRolesLimit();
        if(values.username !== ""){
            temporaryObject.username = values.username;
        } else { delete temporaryObject.username }
        if(values.stateUser !== ""){
            temporaryObject.status = values.stateUser;
        } else { delete temporaryObject.status }
        if(values.stateUser !== ""){
            temporaryObject.status = values.stateUser;
        } else { delete temporaryObject.status }
        if(values.roleUser >0){
            temporaryObject.roleId = [values.roleUser];
        } else { delete temporaryObject.roleId }
        temporaryObject.limit = limit;
        temporaryObject.offset = offset;
        return temporaryObject;
    }

    const updateDataRolesUser = async(objectToSend: FiltersRolesLimit, currentData: Array<any>) => {
        const responseUsersWithRol = await trackPromise(getUsersWithRoles(
            userToken,
            objectToSend
        ));
        if(responseUsersWithRol.status === 200){
            let newData = (await responseUsersWithRol.json()).usersShow;
            if(newData && newData.length>0){
                newData.forEach((userRole: IUserRole) =>{
                    const rolesItem: Array<string> = [];
                    const rolesIds: Array<number> = [];
                    userRole.roles.forEach(item => {
                        const roleFound = rolesArray.find(role=>role.id === item.roleId);
                        if(roleFound && roleFound.name && roleFound.id){
                            rolesItem.push(roleFound.name);
                            rolesIds.push(roleFound.id);
                        }
                    });
                    userRole.rolesString = rolesItem.length>0 ? rolesItem.toString() : "";
                    userRole.rolesIds = rolesIds;
                    userRole.id = userRole.userId;
                });
                newData = sortByKeyDesc(newData, 'userId');
                setUsersRoleList([...currentData, ...newData]);
                setUsersRoleListFiltered([...currentData, ...newData]);
            }
            if(objectToSend.offset === 0 && newData.length === 0){
                setUsersRoleList([]);
                setUsersRoleListFiltered([]);
            }
        }
    }

    const changeFilters = (e:any) => {
        const { name, value } = e.target;
        setFormFilters(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const clearFilters = () => {
        setFormFilters(new FiltersRoles());
        setChips('clear');
        setSearched('');
        const objectToSend = transformDataRolesUser(new FiltersRoles(), limitNumber === rowsPerPage ? limitNumber+25 : limitNumber, 0);
        updateDataRolesUser(objectToSend, []);
        setInitPage(0);
        setFormFilters(new FiltersRoles());
    }

    const applyFilters = () => {
        setChips('apply');
        setSearched('');
        const objectToSend = transformDataRolesUser(formFilters, limitNumber === rowsPerPage ? limitNumber+25 : limitNumber, 0);
        updateDataRolesUser(objectToSend, []);
        setInitPage(0);
    }

    const setChips = (action: string) =>{
        let tempArray: Array<ChipData> = []
        let index = 0;
        const fieldNames=[
          {key: 'username', description: 'Nombre usuario'},
          {key: 'stateUser', description: 'Estado'},
          {key: 'roleUser', description: 'Tipo de rol'},
        ];
        if(action === "clear"){
            setChipData([]);
        } else {
            for (const [key, value] of Object.entries(formFilters)) {
                if(value!==0 && value!=='' && value!==null ){
                  let obj;
                  const fieldObj = fieldNames.find(field=>field.key === key);
                  if (key === 'roleUser'){
                    obj = rolesArray.find(role=>role.id === value);
                  }
                  if(obj){
                    tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + obj.name })
                  } else {
                    tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + value})
                  }
                  index++;
                }
            }
            setChipData(tempArray);
        }
    }

    const requestSearch = (name: string, searchVal:string)=>{
        setSearched(searchVal);
        if(searchVal !== ''){
            setSearchActive(true);
            const filteredData = usersRoleList.filter(user=>user.documentId.includes(searchVal));
            setUsersRoleListFiltered(filteredData);
        } else {
            setSearchActive(false);
            setUsersRoleListFiltered(usersRoleList);
        }
        setInitPage(0);
    }

    const cancelSearch = ()=>{
        setSearched('');
        requestSearch('searched', '');
        setSearchActive(false);
    }

    const onChanhePageSize = (e: any)=>{
        setRowsPerPage(e.target.value)
    }

    const handleRowUser = (action: string, name: string, userSelected: IUserRole)=>{
        setRowSelected(userSelected);
        setActionLabel(name);
        if(action === "AssignRoles"){
            const rolesUserSelected:Array<IRole> = [];
            if(userSelected.roles.length>0){
                userSelected.roles.forEach(role=>{
                    const newArr: IRole = new IRole();
                    if(role.roleId && role.roleId>0){
                        newArr.id = role.roleId;
                        const nameRoleFound = rolesArray.find(elm=>elm.id === role.roleId);
                        newArr.name = nameRoleFound ? nameRoleFound.name : '';
                    }
                    if(role.campus && role.campus>0){
                        newArr.campus = role.campus;
                        const campusFound = allCampus.find(elm=>elm.id === role.campus);
                        newArr.campusName = campusFound ? campusFound.parentName : '';
                        if(campusFound){
                            if(role.school && role.school>0){
                                newArr.school = role.school;
                                const schoolFound = campusFound.childs.find(elm=>elm.parameter_code === role.school);
                                newArr.schoolName = schoolFound ? schoolFound.name : '';
                            }
                        }
                    }
                    newArr.idAssign = rolesUserSelected.length + 1;
                    delete newArr.createdAt;
                    delete newArr.updatedAt;
                    rolesUserSelected.push(newArr)
                })
            }
            if(rolesUserSelected.length>0){
                setAssignArray(rolesUserSelected)
            }
            setAction("");
            setOpenModalAssign(true);
        } else {
            setOpenModalActivate(true);
            if(action === "ActivateUser"){
                setAction('Activo');
            } else if (action === "InactivateUser"){
                setAction('Inactivo');
            }
        }
    }

    const closeModalActivate = () =>{
        setOpenModalActivate(false);
    }

    const confirmActivate = async() => {
        setOpenModalActivate(false);
        const responseAIUSIE = await trackPromise(changestatusUser({id: rowSelected.userId, state: action, modifyUser: userContext.userId}));
        validateModals(responseAIUSIE);
    }

    const closeModalAssign = () =>{
        setOpenModalAssign(false);
        setAssignArray([]);
    }

    const confirmAssign = async() => {
        setOpenModalAssign(false);
        const saveRolesObj: IAssociateRole = {
            userId: rowSelected.userId,
            roles: assignArray
        }
        saveRolesObj.roles.forEach(obj=>{
            if(obj.campus === 0){
                delete obj.campus;
            }
            if(obj.school === 0){
                delete obj.school;
            }
            obj.roleId = obj.id;
            delete obj.id;
            delete obj.idAssign;
            delete obj.campusName;
            delete obj.schoolName;
        });
        const responseARU = await trackPromise(associateRoles({...saveRolesObj, modifyUser: userContext.userId}));
        validateModals(responseARU);
    }

    const changeAssignForm = (e: any) => {
        setErrorLabel("");
        const { name, value } = e.target;
        let nameRole:any = formValues.name ? formValues.name : '';
        let nameCampus:any = formValues.campusName ? formValues.campusName : '';
        let nameSchool:any = formValues.schoolName ? formValues.schoolName : '';
        if(name === "id"){
            const nameRoleFound = rolesArray.find(elm=>elm.id === value);
            nameRole = nameRoleFound ? nameRoleFound.name : '';
            nameCampus="";
            nameSchool="";
            setFormValues(prev => ({
                ...prev,
                campus: 0,
                school: 0,
            }))
        }
        if(name === "campus"){
            const campusFound = allCampus.find(elm=>elm.id === value);
            nameCampus = campusFound ? campusFound.parentName : '';
        }
        if(name === "school"){
            const schoolFound = schools.find(elm=>elm.parameter_code === value);
            nameSchool = schoolFound ? schoolFound.name : '';
        }
        setFormValues(prev => ({
            ...prev,
            [name]: value,
            name: nameRole,
            campusName: nameCampus,
            schoolName: nameSchool,
        }))
    }

    const addRole = ()=>{
        const newAssign: IRole = formValues;
        const existInArray = assignArray.find(item=>item.id === newAssign.id);
        if(!existInArray){
            newAssign.idAssign = newAssign.id;
            delete newAssign.createdAt;
            delete newAssign.updatedAt;
            setAssignArray([
                ...assignArray,
                newAssign
            ])
        } else{
            setErrorLabel("Solo se permite asignar un role de este tipo")
        }
        setFormValues(prev => ({
            ...prev,
            idAssign:0,
            id: 0,
            name: "",
            campus: 0,
            campusName: "",
            school: 0,
            schoolName: ""
        }))
    }

    const deleteRole = (e:any, idAssign: number)=>{
        const newArray = assignArray.filter(assign=>assign.idAssign !== idAssign);
        setAssignArray(newArray);
        setErrorLabel("");
    }

    const validateModals = async(responseService: Response)=>{
        if(responseService.status === 200){
          const result = await responseService.json();
          setSuccessMessage(result.message);
          setOpenModalSuccess(true);
          setOpenModalError(false);
        } else if (responseService.status === 400){
          const result = await responseService.json();
          setErrorMessage(result.message);
          setOpenModalError(true);
          setOpenModalSuccess(false);
        } else {
          setErrorMessage("Hubo un error, intente de nuevo mas tarde");
          setOpenModalError(true);
          setOpenModalSuccess(false);
        }
    }

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
        window.location.reload();
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
    };


    return(
        <>
            {
                url.length>0 ?
                <Redirect to={url}/>
            :
                <>
                    <Grid container className="main-container">
                        <Grid item xs={12} className="roles-management-container-filters">
                            <Grid container item xs={12}>
                                <Typography variant="h5" className="tab-description">
                                    En esta interfaz puede administrar los usuarios administradores, hacer búsquedas específicas, entre otras.
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} className="register-user-btn-container">
                                <Button variant="text" color="default" onClick={(e)=>{setUrl('/admin/dashboard/busqueda-ldap')}} className="register-user-btn">
                                    <Typography variant="h4" className="regular-serif">
                                        Registrar usuario <span style={{marginLeft:'5px'}}> + </span>
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid container item xs={12}>
                                <MoreFiltersRoles rolesArray={rolesArray} formFilters={formFilters} changeFilters={changeFilters}/>
                            </Grid>
                            <Grid container item xs={12} className="roles-container-btn-search">
                                <Button disabled={!buttonFilter} className="clear-btn" onClick={clearFilters}>
                                    <Typography variant="h4" className="regular-serif">Limpiar filtros</Typography>
                                </Button>
                                <Button disabled={!buttonFilter} variant="contained" className="search-btn" onClick={applyFilters}>
                                    <SearchIcon style={{ color: 'white' }}/>
                                    <Typography variant="h4" className="regular-serif">Buscar</Typography>
                                </Button>
                            </Grid>
                            <Grid container item xs={12}>
                                <ShowFiltersApplied chipData={chipData}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="roles-container-list">
                        <Grid container className="main-container">
                            <Grid container item xs={12} className="title-container">
                                <Typography variant="h2" className="bold-serif-primaryColor" >
                                    Usuarios administradores
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} className="roles-table-container">
                                <Grid container item xs={12}>
                                    <div className="header-table-container">
                                        <div className="search-container-outer">
                                            <SearchTable textPlaceHolder="Ingresa número de documento" title="Buscar por número de documento" formErrorSearch={formErrorSearch} searched={searched} requestSearch={requestSearch} searchActive={searchActive} cancelSearch={cancelSearch}/>
                                        </div>
                                        <div className="results-found-container">
                                            <Typography variant="h4" className="bold-serif">
                                                <span>resultados encontrados {usersRoleListFiltered.length}</span>
                                            </Typography>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid container item xs={12}>
                                    <UsersSIETable onChanhePageSize={onChanhePageSize} rows={usersRoleListFiltered} pageSize={rowsPerPage} initPage={initPage} setInitPage={setInitPage} handleRowUser={handleRowUser} setCurrentPage={setCurrentPage}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <ActivateUserModal
                            open={openModalActivate}
                            handleClose={closeModalActivate}
                            handleContinue={confirmActivate}
                            backBtnText="Cancelar"
                            colorBtnBack=""
                            continueBtnText="Confirmar"
                            continueBtnColor="#00B5C0"
                            userSelected={rowSelected}
                            action={actionLabel}
                        />
                        {
                            openModalAssign &&
                            <AssignRoleModal
                                open={openModalAssign}
                                title="Asociar y desasociar roles"
                                handleClose={closeModalAssign}
                                handleContinue={confirmAssign}
                                backBtnText="Regresar"
                                continueBtnText="Guardar"
                                colorBtnContinue="#00B5C0"
                                userSelected={rowSelected}
                                rolesArray={rolesArray}
                                formValues={formValues}
                                changeAssignForm={changeAssignForm}
                                allCampus={allCampus}
                                schools={schools}
                                addRole={addRole}
                                errorLabel={errorLabel}
                                isValidAdd={isValidAdd ? isValidAdd : false}
                                assignArray={assignArray}
                                deleteRole={deleteRole}
                            />
                        }
                    </Grid>
                </>
            }
            <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess}/>
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError}/>
            <LoadingLockPage load={initLoad}/>
            <LoadingLockPage load={promiseInProgress}/>
        </>
    )
}

export default UsersManagement;