import React from 'react';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import './ShowFiltersApplied.scss';

interface ShowFiltersAppliedProps{
    chipData: Array<any>;
    showDelete?: boolean;
    deleteFilter?: any;
}

const ShowFiltersApplied:React.FC<ShowFiltersAppliedProps> = ({chipData, showDelete, deleteFilter})=>{

    return(
        <Grid container item xs={12} className="show-filters-applied-container">
            {
                chipData.length > 0 &&
                <>
                    <Typography variant="h3" className="bold-serif-primaryColor">Filtros aplicados</Typography>
                    <Paper component="ul" style={{backgroundColor: 'transparent'}}>
                    {chipData.map((data) => {
                        return (
                            <li key={data.key}>
                                <Chip
                                label={data.label}
                                onDelete={showDelete ? deleteFilter(data) : null}
                                className="chip-styles"
                                />
                            </li>
                        );
                        })}
                    </Paper>
                </>
            }
        </Grid>
    )
}

export default ShowFiltersApplied;