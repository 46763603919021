import React, {useState, useEffect} from 'react';
import jwt from 'jsonwebtoken';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import BeatLoader from 'react-spinners/BeatLoader';
import {Link, Redirect } from 'react-router-dom';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MenuItem from '@material-ui/core/MenuItem';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import LoadingArea from '../../../components/ShareComponents/LoadingArea';
import { IAuth } from '../../../interfaces/IAuth';
import Validations from '../../../helpers/Validations';
import { login } from '../../../services/AuthService';
import ButtonColors from '../../../components/ShareComponents/ButtonColors';
import { getListPermissions, getListPermissionsByRole, getlistRoles } from '../../../services/AdminServices';
import ModalOffersAction from '../../../components/LaboralOffers/ModalOffersAction';
import { IRole } from '../../../interfaces/IRole';
import { containsAny, logoutAndReset } from '../../../helpers/sharedFunctions';
import UserContext from '../../../utils/UserContext';

import './SignInAdmin.scss';


const initialLoginValues = {
    username: "",
    password: ""
}

const loginValidations = {
    username: {
      error: "",
      required: true
    },
    password: {
        error: "",
        required: true,
    },
}
const SignInAdmin:React.FC = ()=>{
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [formValues, setFormValues] = useState(initialLoginValues);
    const [formErrors, setFormErrors] = useState(initialLoginValues)
    const [showPass, setShowPass]= useState(false);
    const [logged, setLogged] = useState(false);
    const [roles, setRoles] = useState<Array<number>>([]);
    const [ready,setReady] = useState(false);
    const [loginError, setLoginError]= useState('');
    const [roleList, setRoleList] = useState<Array<IRole>>([]);
    const [roleListUser, setRoleListUser] = useState<Array<IRole>>([]);
    const [openModalRoles, setOpenModalRoles] = useState<boolean>(false);
    const [roleSelected, setRoleSelected] = useState<number>(0);
    const [urlRole, setUrlRole] = useState<string>("");

    useEffect(()=>{
        window.scrollTo(0, 0);
        const getInitData = async()=> {
            const responseRoleList = await getlistRoles();
            if(responseRoleList.status === 200){
                const resultList: Array<IRole> = (await responseRoleList.json()).rolesInfo;
                setRoleList(resultList);
            }
        }
        getInitData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setLoginError('')
        setFormValues(prev => ({
            ...prev,
            [name]: value
        }));
        const error = Validations(name, value, loginValidations) || "";
        setFormErrors(prev=>({
            ...prev,
            [name]: error
        }));
    }

    const handleClickShowPassword = () => {
        setShowPass(!showPass);
    };

    const makeLogin = async()=>{
        if(userContext.permissions.length === 0 && userContext.permissionsRoles.length === 0){
            const repsonsePermissions = await getListPermissions();
            if(repsonsePermissions.status === 200){
              const resultPermissions = (await repsonsePermissions.json()).permissionsInfo;
              userContext.setPermissions(resultPermissions)
              const resPermissionsRoles = await getListPermissionsByRole();
              if(resPermissionsRoles.status === 200){
                const resultPermissionsRoles = (await resPermissionsRoles.json()).permissionsByRole;
                userContext.setPermissionsRoles(resultPermissionsRoles);
              }
            }
        }
        const response: IAuth = await trackPromise(login(formValues));
        if(response !== undefined){
            if(response.state === true ){
                if(sessionStorage.getItem('Bearer')){
                    const itemProfile:string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
                    if(jwt.decode(itemProfile)){
                        const resDecoded:any = jwt.decode(itemProfile);
                        if(resDecoded){
                            const profileDecoded = resDecoded.message;
                            const getRoles = profileDecoded.roles;
                            setRoles(getRoles);
                            if(getRoles.length >1){
                                let newRoleList: Array<IRole> = [];
                                getRoles.forEach((role: number) => {
                                    const roleElm = roleList.find(item=>item.id === role);
                                    if(roleElm){newRoleList.push(roleElm);}
                                });
                                setRoleListUser(newRoleList);
                                setOpenModalRoles(true)
                                setReady(true);
                                setLogged(true);
                            } else if(getRoles.length === 1) {
                                const temp = jwt.sign({ roleSelected: getRoles[0], temp: userContext.temporaryKey || false}, '1234@#');
                                sessionStorage.setItem('temp', temp)
                                setReady(true);
                                setLogged(true);
                            } else {
                                setLoginError('Se produjo una falla en el servidor, intente de nuevo mas tarde');
                                logoutAndReset(userContext);
                                setReady(true);
                                setLogged(true);
                            }
                        }
                    }
                }
            } else {
                setLogged(false);
                logoutAndReset(userContext);
                setRoles([]);
                setLoginError(response.message);
                setReady(true);
            }
        } else{
            setLogged(false);
            logoutAndReset(userContext);
            setRoles([]);
            setReady(true);
            setLoginError('Hubo un problema, confirma el usuario y contraseña ingresados')
        }
    }

    const closeModalRoles = ()=>{
        setOpenModalRoles(false);
        setRoleListUser([]);
        setRoleSelected(0);
        setLogged(false);
        logoutAndReset(userContext);
    };

    const changeRoleSelected = (e:any)=>{
        const {value} = e.target;
        const temp = jwt.sign({ roleSelected: value, temp: userContext.temporaryKey || false}, '1234@#');
        sessionStorage.setItem('temp', temp)
        setRoleSelected(value)
        let urlRoute = ""
        if(value>0){
            if(value === 1){
                urlRoute = "/egresado/dashboard";
            } else if (value === 2 || value === 3){
                urlRoute = "/empresa/dashboard";
            } else {
                urlRoute = "/admin/dashboard";
            }
        }
        setUrlRole(urlRoute)
    }


    return(
        <>
            { !logged &&
                <Grid container component="main" className="login-admin-container">
                    <Grid item xs={false} sm={4} md={7} className="login-admin-container-img" />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <Grid item xs={12} className="login-admin-container-box">
                            <Grid container item xs={12} direction="column" className="login-admin-header">
                                <Typography variant="h2" className="title-login-admin">
                                    Ingreso de administradores
                                </Typography>
                                <Grid container item xs={12} direction="row" className="subtitle">
                                    <Typography variant="h5" className="subtitle-login-admin">
                                        SIE
                                    </Typography>
                                    <Typography variant="h5" className="subtitle-login-admin-info">
                                        Sistema de información programa de egresados
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ButtonColors/>
                                </Grid>
                            </Grid>
                            <Grid item xs={11} className="login-admin-container-form">
                                <form noValidate autoComplete="off">
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Usuario"
                                            name="username"
                                            placeholder="Ingresa tu correo"
                                            margin="normal"
                                            value={formValues.username}
                                            variant="outlined"
                                            onChange={handleChange}
                                            error={!!formErrors.username}
                                            helperText={formErrors.username}
                                            inputProps={{ style: { textTransform: "lowercase" } }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Contraseña"
                                            name="password"
                                            placeholder="Ingresa tu contraseña"
                                            margin="normal"
                                            type={showPass ? 'text' : 'password'}
                                            value={formValues.password}
                                            variant="outlined"
                                            onChange={handleChange}
                                            error={!!formErrors.password}
                                            helperText={formErrors.password}
                                            required
                                            InputProps={{
                                                endAdornment:
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {showPass ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="h7-regular-primaryError">
                                        {loginError}
                                    </Grid>
                                    <Grid container item xs={12} justify="flex-start">
                                        <div style={{width:'100%'}}>
                                            <LoadingArea load={promiseInProgress} loadElement={<BeatLoader size={20} margin={2} color="#14A5DC"/>} awaitingText="Espere un momento por favor"/>
                                        </div>
                                        <Button disabled={promiseInProgress} variant="contained" className="button-login-admin" size="small" onClick={makeLogin}>
                                            <Typography variant="h6" className="regular-serif">
                                                Iniciar sesión
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </form>
                            </Grid>
                            <Grid container item xs={12} className="login-admin-container-terms">
                                <ErrorOutlineIcon className="policies-icon"/>
                                <Link className="policies-link" to="/">
                                    <Typography variant="h6" className="regular-serif">
                                        Mesa de ayuda universidad nacional
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {logged && ready && roles.length>1 ?
                <ModalOffersAction
                    open={openModalRoles}
                    handleClose={closeModalRoles}
                    iconModal={<InfoIcon className="big-info-icon" />}
                    title="Seleccione un rol"
                    headerdescription="Usted tiene varios roles, por favor seleccione un rol para inciar sesión"
                    description=""
                    backBtnText="Regresar"
                    colorBtnBack= ""
                    continueBtnText="Ingresar"
                    linkContinue={urlRole}
                    colorBtnContinue="#f44336"
                    valid={roleSelected>0 ? true : false}
                >
                    <Grid container item xs={12} justify="center">
                        <Grid item xs={12} className="field-box-margin">
                            <Typography variant="h5" className="bold-serif">Seleccione el rol</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-estateValue"
                                select
                                name="roleSelected"
                                label={roleSelected === 0 ? "Seleccione": ""}
                                value={roleSelected === 0 ? "" : roleSelected}
                                onChange={changeRoleSelected}
                                InputLabelProps={{shrink: false}}
                                margin="none"
                                variant="outlined"
                                disabled={roleListUser.length>0 ? false : true}
                            >
                                {
                                    roleListUser && roleListUser.length>0 ?
                                    roleListUser.map((option: IRole) => (
                                        <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={roleSelected}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                    </Grid>
                </ModalOffersAction>
            :
                logged && ready && containsAny(roles,[4,5,6,7,8,9]) &&
                <Redirect to="/admin/dashboard"/>
            }
        </>
    )
}

export default SignInAdmin