import React, { useState, useEffect } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import ApplicantList from '../../../../components/LaboralOffers/ApplicantList';
import { IApplicantInfo } from '../../../../interfaces/IApplicantInfo';
import LaboralOfferSummary from '../../../../components/LaboralOffers/LaboralOfferSummary';
import ApplicantCurriculumVitae from '../../../../components/LaboralOffers/ApplicantCurriculumVitae';
import { getlistOffers } from '../../../../services/LaboralOfferService';
import { IGetOffer } from '../../../../interfaces/IGetOffer';
import PeopleIcon from '@material-ui/icons/People';
import { IApplicant } from '../../../../interfaces/IApplicant';
import { getLABORAL_SECTOR, getOccupationType, getTypeOfContract } from '../../../../services/ParameterService';
import { IGeneralParameter } from '../../../../interfaces/IGeneralParameter';
import { listPostulations } from '../../../../services/PostulationService';
import { IGetPostulation } from '../../../../interfaces/IGetPostulation';
import AlertBox from '../../../../components/ShareComponents/AlertBox';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';

import './ApplicantsOfferView.scss';
import { Button } from '@material-ui/core';


const ApplicantsOfferView: React.FC = ()=>{
    const { promiseInProgress } = usePromiseTracker();
    const [initLoad, setInitLoad] = useState<boolean>(true);
    let params: any = useParams();
    const [dataOffer, setDataOffer] = useState<any>({});
    const [applicantsData, setApplicantsData] = useState<IApplicantInfo[]>([]);
    const [graduateId, setGraduateId] = useState<number | null>(null);
    const [openCV, setOpenCV] = useState<boolean>(false);
    const [rowsPerPage, setRowsPerPage]= useState(5);
    const [initPage, setInitPage]= useState<number>(0);
    const [typePosition, setTypePosition] = useState<string>('');
    const [laboralSector, setLaboralSector]= useState<string>('');
    const [contract, setContract]= useState<string>('');

    useEffect(()=>{
        window.scrollTo(0, 0);
        const getDataOffer = async()=>{
            if(params.id){
                const applicantRes: Response = await listPostulations({offerId:params.id});
                if(applicantRes.status === 200){
                    const result: Array<IGetPostulation> = (await applicantRes.json()).postulates;
                    let target: Array<IApplicantInfo> = [];
                    result.forEach(item=>{
                        console.dir(item)
                        target.push(new IApplicantInfo(item))
                    })
                    setApplicantsData(target)
                }
                const responseOffer: Response = await getlistOffers({id: params.id});
                if(responseOffer.status === 200){
                    const resultOffer: IGetOffer = (await responseOffer.json()).jobOffers[0];
                    if(resultOffer){
                    setDataOffer(resultOffer);
                    let responsePostion: Array<IGeneralParameter> = await getOccupationType();
                    const positionSelected = responsePostion.find(item=>item.parameter_code === resultOffer.typePosition);
                    setTypePosition(positionSelected?.name ? positionSelected.name : '');
                    let respLaboralSector: Array<IGeneralParameter> = await getLABORAL_SECTOR();
                    const laboralSelected = respLaboralSector.find(item=>item.parameter_code === resultOffer.laboralSector);
                    setLaboralSector(laboralSelected?.name ? laboralSelected?.name : '');
                    let respTypeContrat: Array<IGeneralParameter> = await getTypeOfContract();
                    const contractSelected = respTypeContrat.find(item=>item.parameter_code === resultOffer.typeContract);
                    setContract(contractSelected?.name ? contractSelected?.name : '');
                    }
                }
            }
            setInitLoad(false);
        }
        getDataOffer();
    },[params.id])

    const onChanhePageSize = (e: any)=>{
        setRowsPerPage(e.target.value)
    }

    const handleView = (rowSelected: IApplicant) => {
        setGraduateId(rowSelected.graduateId ? rowSelected.graduateId : null)
        setOpenCV(true);
    }

    const goBack = (value: string) => {
        if (value === 'Back') {
            setOpenCV(false);
        }
    }


    return(
        <>
        {
            openCV && graduateId && graduateId > 0 ?
                <ApplicantCurriculumVitae graduateId={graduateId } goBack={goBack}/>
            :
            <Grid container className="applicants-offer-view">
                <Grid container item xs={12} className="back-offers-list-section">
                    <ButtonBackListOffers url="/empresa/dashboard/ofertas/seleccion" text="Regresar al listado de ofertas"/>
                </Grid>
                <Button onClick={()=>{console.dir()}}>

                </Button>
                <Grid container className="main-container">
                    <Grid container item xs={12}>
                        <Grid container item xs={12} className="title-select-container">
                            <PersonAddIcon className="person-select-icon"/>
                            <Typography variant="h2" className="bold-serif" >
                                Seleccionar aspirantes
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <LaboralOfferSummary dataOffer={dataOffer} typePosition={typePosition} laboralSector={laboralSector} contract={contract}/>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid container item xs={12} md={3} lg={2}>
                                <div className="title-section-line">
                                <PeopleIcon className="group-icon"/>
                                <Typography variant="h2" className="bold-serif-primaryColor" >
                                    Aspirantes
                                </Typography>
                                </div>
                            </Grid>
                            <Grid container item xs={12} md={9} lg={10} className="line" />
                        </Grid>
                        <Grid container item xs={12}>
                            <AlertBox
                                title="Ver información del aspirante"
                                alert="info"
                                variantType="standard"
                            >
                                <span className="alert-simple-text">Para ver la información del aspirante,</span><SettingsIcon/><span className="alert-simple-text">desde aca podrá descargar la hoja de vida del aspirante o ver en línea la información</span>
                            </AlertBox>
                        </Grid>
                        <Grid container item xs={12}>
                            <ApplicantList action="Select" onChanhePageSize={onChanhePageSize} rows={applicantsData} pageSize={rowsPerPage} initPage={initPage} setInitPage={setInitPage} handleView={handleView}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            }
            <LoadingLockPage load={promiseInProgress}/>
            <LoadingLockPage load={initLoad}/>
        </>
    )
}

export default ApplicantsOfferView