export class IGetTrainingEvents{
    id: number = 0;
    name: string = "";
    typeEvent: number = 0;
    description: string = "";
    initDate: string = "";
    endDate: string = "";
    createdAt?: string = "";
    updatedAt?: string = "";
    file: boolean = false;
}