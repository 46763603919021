import { IGeneralSIA } from '../interfaces/IGeneralSIA';

export const GENDER: Array<IGeneralSIA> = [
    {
        id: 1,
        name: "Masculino"
    },
    {
        id: 2,
        name: "Femenino"
    },
]

export const SOCIALNETWORK: Array<IGeneralSIA> = [
    {
        id: 1,
        name: "Linkedin"
    },
    {
        id: 2,
        name: "Facebook"
    },
    {
        id: 3,
        name: "Twitter"
    },
    {
        id: 4,
        name: "Otra"
    },
]

export const CONTACTTYPE: Array<IGeneralSIA> = [
    {
        id: 1,
        name: "Cercano"
    },
    {
        id: 2,
        name: "Personal"
    },
    {
        id: 3,
        name: "Laboral"
    },
]
