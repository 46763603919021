import React, { useState } from 'react';
import { DataGrid, GridOverlay, GridColDef, GridBaseComponentProps } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { IGraduateTable } from '../../../interfaces/IGraduateTable';
import './DataTableAction.scss'

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 14,
      padding: '5px 6px 10px 12px',
      height: '30px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  }),
)(InputBase);

interface DataTableActionProps{
    rows: Array<any>;
    columns: GridColDef[];
}

const DataTableAction:React.FC<DataTableActionProps> = ({rows, columns}) => {
    const [rowsPerPage, setRowsPerPage]= useState<number>(5);
    
    const onChanhePageSize = (e: any)=>{
        setRowsPerPage(e.target.value)
    }

    return (
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid autoHeight className="data-grid-list" disableColumnMenu rows={rows} columns={columns} pageSize={rowsPerPage} pagination density="compact"
                components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                        Footer:(props)=>CustomFooter(props, rows, onChanhePageSize, rowsPerPage)
                }}
                />
            </Grid>
        </Grid>
    );
}

const CustomFooter = (props: GridBaseComponentProps, rows: Array<IGraduateTable>, onChanhePageSize?: any, valueRows?: number)=> {
    const { state, api } = props;
    const rowsPerPageOptions = [5,10,25,50,100];

    return (
        <>
            {
                rows.length>5 &&
                <div className="footer-container">
                    <div className="rows-number-container">
                        <Typography variant="h5" className="rows-number-text">Mostrar</Typography>
                        <Select
                            id="demo-simple-select"
                            value={valueRows}
                            onChange={onChanhePageSize}
                            input={<BootstrapInput/>}
                        >
                            {rowsPerPageOptions.map(item=>(
                                <MenuItem value={item}>{item}</MenuItem>
                            ))}
                        </Select>
                        <Typography variant="h5" className="rows-number-text">Registros</Typography>
                    </div>
                    <Pagination
                        className="pagination-container"
                        color="primary"
                        page={state.pagination.page+1}
                        count={state.pagination.pageCount}
                        onChange={(event, value) => api.current.setPage(value-1)}
                        showFirstButton
                        showLastButton
                    />
                </div>
            }
        </>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large"/>
            <Typography variant="h4" className="not-results-text">
                Sin registros
            </Typography>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps)=><KeyboardArrowUpIcon fontSize="small"/>
const customSortDesc = (props: GridBaseComponentProps)=><KeyboardArrowDownIcon fontSize="small"/>

export default DataTableAction;