import { IFile } from "./IFile";

export class IPersonalInfoCV{
    personalIdentificationData: IPersonalIdentificationData;
    distinctions: Array<IDistinctions>;
    familyInformation: IFamilyInformation;
    referents: Array<IReferent>;
    constructor(def:any){
        this.personalIdentificationData = def.personalIdentificationData ? new IPersonalIdentificationData(def.personalIdentificationData) : new IPersonalIdentificationData({});
        this.distinctions = def.distinctions || [];
        this.familyInformation = def.familyInformation || new IFamilyInformation();
        this.referents = def.referents || [];
    }
}

export class IPersonalIdentificationData{
    id:string;
    names?: string;
    surname?: string;
    secondsurname?: string;
    documentType?: number;
    doc?: string;
    files?: Array<IFile>;
    photo?: string;
    state?: string;
    termsOfUse?: boolean;
    disability?: boolean;
    laboralProfile?: string;
    currentlyWorking?: boolean;
    competences?: string;
    createdAt?: string;
    updatedAt?: string;
    gender: number;
    stratum: number;
    deceased: boolean;
    ethnicgroup: number;
    birthCountry: number ;
    birthDepartment: number ;
    birthCity: number;
    // birthplace: string;
    // birthplaceVisible: boolean;
    birthdate: Date | null;
    birthdateVisible: boolean;
    maritalStatus: number;
    maritalStatusVisible: boolean;
    impairment: number;
    residenceCountry: number;
    residenceDepartment: number;
    residenceCity: number;
    address: string;
    phone: string;
    cellphone: string;
    additionalPhone: string;
    additionalPhoneVisible: boolean;
    email: string;
    additionalEmail: string;
    socialnetwork: number;
    urlSocialnetwork: string;
    urlSocialnetworkVisible: boolean;
    specialAdmissionProgram: boolean;
    conflictVictim:boolean;
    nameSpecialAdmissionProgram: number;
    paesProgram?: number;
    initialCampusPeama: number;
    finalCampusPeama: number;
    sonsAndDaughters: boolean;
    numSonsAndDaughters: number;
    familyInformationVisible: boolean;
    inJobBank: Date | null;
    constructor(def:any){
        this.id = def.doc || 0;
        this.names = def.names || "";
        this.surname = def.surname || "";
        this.secondsurname = def.secondsurname || "";
        this.gender = def.gender || 0;
        this.documentType = def.documentType || 0;
        this.doc = def.doc || "";
        this.files = def.files || [];
        this.photo = def.photo || "";
        this.state = def.state || "";
        this.termsOfUse = def.termsOfUse || false;
        this.disability = def.disability || false;
        this.conflictVictim = def.conflictVictim || false;
        this.laboralProfile = def.laboralProfile || "";
        this.currentlyWorking = def.currentlyWorking || false;
        this.competences = def.competences || "";
        this.createdAt = def.createdAt || "";
        this.updatedAt = def.updatedAt || "";
        this.stratum = def.stratum || 0;
        this.deceased = def.deceased || false;
        this.ethnicgroup = def.ethnicgroup || 0;
        this.birthCountry = def.birthCountry || 0;
        this.birthDepartment = def.birthDepartment || 0;
        this.birthCity = def.birthCity || 0;
        // this.birthplace = def.birthplace || "";
        // this.birthplaceVisible = def.birthplaceVisible || false;
        this.birthdate = def.birthdate ?  new Date(def.birthdate) : null;
        this.birthdateVisible = def.birthdateVisible || false;
        this.maritalStatus = def.maritalStatus || 0;
        this.maritalStatusVisible = def.maritalStatusVisible || false;
        this.impairment = def.impairment || 0;
        this.residenceCountry = def.residenceCountry || 0;
        this.residenceDepartment = def.residenceDepartment || 0;
        this.residenceCity = def.residenceCity || 0;
        this.address = def.address || "";
        this.phone = def.phone || "";
        this.cellphone = def.cellphone || "";
        this.additionalPhone = def.additionalPhone || "";
        this.additionalPhoneVisible = def.additionalPhoneVisible || false;
        this.email = def.email || "";
        this.additionalEmail = def.additionalEmail || "";
        this.socialnetwork = def.socialnetwork || 0;
        this.urlSocialnetwork = def.urlSocialnetwork || "";
        this.urlSocialnetworkVisible = def.urlSocialnetworkVisible || false;
        this.specialAdmissionProgram = def.specialAdmissionProgram || false;
        this.nameSpecialAdmissionProgram = def.nameSpecialAdmissionProgram ? parseInt(def.nameSpecialAdmissionProgram) : 0;
        this.paesProgram = def.paesProgram || 0;
        this.initialCampusPeama = def.initialCampusPeama || 0;
        this.finalCampusPeama = def.finalCampusPeama || 0;
        this.sonsAndDaughters = def.sonsAndDaughters || false;
        this.numSonsAndDaughters = def.numSonsAndDaughters || 0;
        this.familyInformationVisible = def.familyInformationVisible || false;
        this.inJobBank= def.inJobBank ?  new Date(def.inJobBank) : null;
    }
}

export class IDistinctions{
    id?:number | null = null;
    graduateId?: number | null = null;
    category: number = 0;
    yearDateFormat?: Date | null = null;
    year: number | null = null;
    description: string = "";
}

export class IFamilyInformation{
    childrens:Array<IChildrenInfo> = [];
}

export class IChildrenInfo{
    id?:number | null = null;
    graduateId?: number | null = null;
    names: string = "";
    surname: string = "";
    secondSurname: string = "";
    birthdayYearDateFormat?: Date | null = null;
    birthdayYear: string = "";
    address: string = "";
}

export class IReferent{
    id?:number | null = null;
    graduateId?: number | null = null;
    type: string = "";
    names: string = "";
    surname: string = "";
    secondSurnmae: string = "";
    phone: string = "";
    email: string = "";
    visble: boolean = false;
    position: string = "";
}