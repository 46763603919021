import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import GroupIcon from '@material-ui/icons/Group';
import ProfileModal from '../../../../components/ProfileModal/ProfileModal';
import { IUserByCompany } from '../../../../interfaces/IUserByCompany';
import CompanyProfiles from '../../../../components/CompanyProfiles';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import ProfilesList from '../../../../components/ProfilesList';
import UserContext from '../../../../utils/UserContext';
import { containsAny } from '../../../../helpers/sharedFunctions';
import { permissionCompanyProfilesView, permissionCreateCompanyProfiles } from '../../../../utils/PermissionsVariables';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';

import '../company.scss';


interface CompanyProfilesViewProps{
  activeUsers: Array<IUserByCompany>;
  inactiveUsers: Array<IUserByCompany>;
}

const CompanyProfilesView: React.FC<CompanyProfilesViewProps> = ({activeUsers, inactiveUsers}) => {
  const userContext = React.useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [disabledAddButton, setDisableAddButton]= useState(false);
  const [rowsPerPage, setRowsPerPage]= useState(5);
  const [initPage, setInitPage]= useState<number>(0);

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  useEffect(()=>{
    if(activeUsers.length<3){
      setDisableAddButton(true);
    } else {
      setDisableAddButton(false);
    }
  },[activeUsers])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChanhePageSize = (e: any)=>{
      setRowsPerPage(e.target.value)
  };


  return (
    <Grid container className="main-container">
      {containsAny(permissionCompanyProfilesView,userContext.userPermissions) ?
        <Grid container item xs={12} className="profiles-container">
          <Grid container item xs={12} md={5} lg={3} direction="row">
            <div className="title-section-line">
              <GroupIcon className="group-icon"/>
              <Typography variant="h2" className="bold-serif-primaryColor" >
                Perfiles de mi empresa
              </Typography>
            </div>
          </Grid>
          <Grid container item xs={12} md={7} lg={9} className="line"/>
          <Grid container >
            <Typography variant="h5" className="profile-text">
              Como administrador puede añadir, eliminar, asignar permisos de publicación o editar distintos perfiles de su empresa.
            </Typography>
          </Grid>
          <Grid container item xs={12} className="btn-container">
            <CompanyProfiles activeUsers={activeUsers}/>
          </Grid>
          {containsAny(permissionCreateCompanyProfiles, userContext.userPermissions) &&
            <Grid container item xs={12} className="btn-container">
              <Button disabled={!disabledAddButton} variant="contained" className="add-profile-btn" endIcon={<AddIcon />} onClick={(handleClickOpen)}>
                <Typography variant="h5" className="bold-serif">
                  AÑADIR PERFIL
                </Typography>
              </Button>
              <ProfileModal
                open={open}
                handleClose={handleClose}
                title="Añadir perfil"
                backBtnText="CANCELAR"
                colorBtnBack=""
                continueBtnText="AÑADIR"
                continueBtnColor=""
              />
            </Grid>
          }
          <Grid container item xs={12} md={6} lg={5}>
            <div className="title-section-line">
              <PersonAddDisabledIcon className="group-icon"/>
                <Typography variant="h2" className="bold-serif-primaryColor" >
                  Usuarios inactivos de mi empresa
                </Typography>
            </div>
          </Grid>
          <Grid container item xs={12} md={6} lg={7} className="line"></Grid>
          <ProfilesList onChanhePageSize={onChanhePageSize} activeUsers={activeUsers} rows={inactiveUsers} pageSize={rowsPerPage} initPage={initPage} setInitPage={setInitPage}/>
        </Grid>
      :
        <NotAuthorized/>
      }
    </Grid>
  )
}

export default CompanyProfilesView
