export class IUserByCompany{
    nit:number | null = null;
    id: number | null = null;
    name: string = "";
    phone: string = "";
    email: string = "";
    status: string = "";
    createdAt: string = "";
    updatedAt: string = "";
}

