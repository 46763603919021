import React, { useState, useEffect } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { DateRange, DateRangePicker, LocalizationProvider } from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";
import moment from 'moment';
import 'moment/locale/es'
import DateFnsUtils from '@date-io/date-fns';
import { ITransactionalReport } from '../../../interfaces/ITransactionalReport';
import Validations from '../../../helpers/Validations';
import './ReportFormTransactional.scss';
import DownloadModal from '../DownloadModal';
import ErrorModal from '../ErrorModal';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import { transactions } from '../../../services/ReportsService';

const initialFormValues = {
  user: "",
  dateInit: "",
  dateEnd: ""
}

const formValidations = {
  user: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: false
  },
}

const ReportFormTransactional = () => {
  const { promiseInProgress } = usePromiseTracker();
  const [formValuesReports, setFormValuesReports] = useState<ITransactionalReport>(new ITransactionalReport({}));
  const [formErrorsReport, setFormErrorsReport] = useState(initialFormValues);
  const [valueD, setValueD] = React.useState<DateRange<Date>>([null, null]);
  const [urlFileReportLog, setUrlFileReportLog] = useState('');
  const [quantityReportLog, setQuantityReportLog] = useState('');
  const [openModalDownload, setOpenModalDownload] = React.useState(false);
  const [openModalError, setOpenModalError] = React.useState(false);
  const [openModalError2, setOpenModalError2] = React.useState(false);

  const changeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.target;
      setFormValuesReports(prev => ({
          ...prev,
          [name]:checked
      }));
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValuesReports(prev => ({
      ...prev,
      [name]: value
    }));
    const error = Validations(name, value, formValidations) || "";
    setFormErrorsReport(prev => ({
      ...prev,
      [name]: error
    }));
  }

  useEffect(() => {
    if (valueD !== null) {
      const startDate: string = moment(valueD[0]).format('YYYY/MM/DD');
      const endDate: string = moment(valueD[1]).format('YYYY/MM/DD');
      setFormValuesReports(prev => ({
        ...prev,
        rangeDate:{
          ...prev.rangeDate,
          dateInit: startDate,
          dateEnd: endDate
        }
      }));
    }
  }, [valueD])

  const makeReport = async () => {
    if (formValuesReports.user === null && formValuesReports.rangeDate.dateEnd === "Fecha inválida" && formValuesReports.rangeDate.dateInit === "Fecha inválida" && formValuesReports.check === false ){
      setOpenModalError(true);
    }else if (((formValuesReports.rangeDate.dateInit !== "Fecha inválida" && formValuesReports.rangeDate.dateEnd !== "Fecha inválida") && formValuesReports.user !== "") || formValuesReports.check === true) {
      const response = await trackPromise(transactions(formValuesReports));
      if (response.status === 200) {
        const resultFile = (await response.json());
        setUrlFileReportLog(resultFile.url)
        setQuantityReportLog(resultFile.quantity)
        setOpenModalDownload(true);
      }
    } 
    else
    setOpenModalError2(true);
  }
  
  const handleCloseModalDownload = () => {
    setOpenModalDownload(false);
    window.location.reload();
  };

  const handleCloseModalError = () => {
    setOpenModalError(false);
    // window.location.reload();
  };  

  const handleCloseModalError2 = () => {
    setOpenModalError2(false);
    // window.location.reload();
  };  

  return (
    <>
    <Grid container className="main-container">
      <Grid container item xs={12} className="main-report-container">
        <Grid container item xs={12} className="report-container">
            <Grid container item xs={12} className="title-container-report">
                <Typography variant="h2" className="title">
                    Seleccione filtros del reporte
                </Typography>
            </Grid>
            <Grid container className="report-content">
              <Grid item xs={12} md={6} xl={6} className="field-box-margin">
                <Typography variant="h5" className="field-box-title">Nombre del usuario</Typography>
                <TextField
                  fullWidth
                  name="user"
                  margin="none"
                  label={formValuesReports.user === "" ? "Ingrese el nombre del usuario": ""}
                  value={formValuesReports.user || ""}
                  onChange={handleChange}
                  error={!!formErrorsReport.user}
                  helperText={formErrorsReport.user}
                  InputLabelProps={{shrink: false}}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid container className="report-content">
              <Grid item xs={12} md={6} xl={4} className="field-box-margin">
                  <Typography variant="h5" className="field-box-title">Rango de fechas</Typography>
                  <Grid container item xs={12}>
                      <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale} >
                          <DateRangePicker
                              disableFuture
                              calendars={1}
                              PopperProps={{
                                  placement: "bottom"
                              }}
                              value={valueD || null}
                              onChange={(valueD) => {
                                  setValueD(valueD);
                              }}
                              renderInput={(startProps, endProps) => (
                                  <Grid container item xs={12} alignItems="center">
                                      <Grid item xs={12} sm={5}>
                                          <TextField
                                              {...startProps}
                                              autoComplete="off"
                                              fullWidth
                                              name="dateInit"
                                              margin="none"
                                              label={valueD[0] !== null ? "" : "AAAA/MM/DD"}
                                              error={!!formErrorsReport.dateInit}
                                              helperText={formErrorsReport.dateInit}
                                              InputLabelProps={{ shrink: false }}
                                              variant="outlined"
                                          />
                                      </Grid>
                                      <Grid item xs={12} sm={2}>
                                          <Typography variant="h5" className="field-box-title" style={{ textAlign: 'center' }}>
                                              Hasta
                                          </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={5}>
                                          <TextField
                                              {...endProps}
                                              fullWidth
                                              autoComplete="off"
                                              name="dateEnd"
                                              margin="none"
                                              label={valueD[1] !== null ? "" : "AAAA/MM/DD"}
                                              error={!!formErrorsReport.dateEnd}
                                              helperText={formErrorsReport.dateEnd}
                                              InputLabelProps={{ shrink: false }}
                                              variant="outlined"
                                          />
                                      </Grid>
                                  </Grid>
                              )}
                          />
                      </LocalizationProvider>
                  </Grid>
              </Grid>
            </Grid>

            <Grid container className="report-content">
              <Grid item xs={12} md={6} xl={6} className="bold-serif field-box-margin">
                <FormControlLabel
                  control={
                      <Checkbox
                      checked={formValuesReports.check}
                      onChange={changeCheckBox}
                      name="check"
                  />}
                  label="Información disponible"
                  labelPlacement="start"
                  className="checkbox"
              />
              </Grid>
            </Grid>
          <LoadingLockPage load={promiseInProgress} />
        </Grid>
      </Grid>
      <Grid container item xs={12} className="button-download-report-container">
        <Button className="generate-report-btn" variant="contained" size="small" onClick={makeReport}>
          <Typography variant="h5" className="regular-serif">Generar reporte</Typography>
        </Button>
      </Grid>
      <ErrorModal errorMsg="Por favor seleccione algun filtro para el reporte"  handleCloseModal={handleCloseModalError} openModalError={openModalError} />
      <ErrorModal errorMsg="Por seleccione un rango de fechas para el reporte"  handleCloseModal={handleCloseModalError2} openModalError={openModalError2} />
      <DownloadModal downloadMsg="El reporte se ha generado con éxito" downloadMsg2="¿Desea descargarlo?" handleCloseModal={handleCloseModalDownload} openModalDownload={openModalDownload} urlReport={urlFileReportLog} total={quantityReportLog} />

    </Grid>
    </>
  )
}

export default ReportFormTransactional
