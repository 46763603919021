import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { StateByRole } from '../../../interfaces/StateByRole';
import './SurveysFilters.scss';

interface SurveysFiltersProps{
    title: string;
    typeSurveyFilter:string
    typeSurveyOptions: Array<StateByRole>;
    radioChange: any;
    applyFilters: any;
}

const SurveysFilters:React.FC<SurveysFiltersProps> = ({title, typeSurveyFilter, typeSurveyOptions,radioChange, applyFilters})=>{

    return(
      <Grid container item xs={12} className="surveys-filter-container">
        <Grid container item xs={12}>
            <Typography variant="h3" className="surveys-title surveys-filter-text">
                {title}
            </Typography>
        </Grid>
        <Grid container item xs={12}>
            <Grid container item xs={12} md={2}>
                <Typography variant="h4" className="surveys-filter-text">
                    Filtrar por estados:
                </Typography>
            </Grid>
            <Grid container item xs={12} md={10} className="surveys-filter-options">
              <FormControl component="fieldset">
                <RadioGroup row className="radio-container" aria-label="state" name="state" value={typeSurveyFilter} onChange={radioChange}>
                  {typeSurveyOptions.map(option=>(
                    <FormControlLabel key={option.id} value={option.value} className="radio-btn" control={<Radio className="color-btn" />} label={option.label} labelPlacement="start"/>
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          <Grid container item className="btn-container">
            <Button onClick={applyFilters} variant="contained" className="search-btn">
                <SearchIcon style={{ color: 'white' }}/>
                <Typography variant="h4" className="regular-serif">Buscar</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
}

export default SurveysFilters;