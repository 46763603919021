import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BusinessIcon from '@material-ui/icons/Business';
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import CompanyInfo from '../../../../components/CompanyInfo';
import AnnouncementList from '../../../../components/LaboralOffers/AnnouncementList';
import { IGeneralParameter } from '../../../../interfaces/IGeneralParameter';
import { IProfileCompany } from '../../../../interfaces/IProfileCompany';
import { getlistOffers } from '../../../../services/LaboralOfferService'
import { IGetOffer } from '../../../../interfaces/IGetOffer';
import { OfferStateObject } from '../../../../interfaces/OfferStateObject';
import { IAnnouncement } from '../../../../interfaces/IAnnouncement';
import OffersChangeAction from '../../../../components/LaboralOffers/OffersChangeAction';
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import AnnouncementFilters from '../../../../components/LaboralOffers/AnnouncementFilters';
import { IFilterOfferForm } from '../../../../interfaces/IFilterOfferForm';
import { ChipData } from '../../../../interfaces/ChipData';
import ShowFilters from '../../../../components/ManagementState/ShowFilters';
import OfferDetailAdmin from '../../../../components/LaboralOffers/OfferDetailAdmin';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import { containsAny, sortByKey, sortByKeyDesc } from '../../../../helpers/sharedFunctions';
import { getCompanyInfo } from '../../../../services/CompanyService';
import Validations from '../../../../helpers/Validations';
import UserContext from '../../../../utils/UserContext';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import { permissionAdminSeeCompanyOffers } from '../../../../utils/PermissionsVariables';
import SearchTable from '../../../../components/ManagementState/SearchTable';
import { IListParametersInfo } from '../../../../interfaces/IListParametersInfo';
import OfferForm from '../../../../components/LaboralOffers/OfferForm';
import { getCITY, getCOMPANY_TYPE, getCOUNTRIES_CITIES, getDEPARTAMENT, getLABORAL_SECTOR } from '../../../../services/ParameterService';

import './CompanyInfoView.scss';


export class OfferStateTosendObj{
  offers: Array<OfferStateObject> = [];
}

const filterOffersValidation = {
  country:{
    validate: "select",
    required: false
  },
  department: {
    validate: "select",
    required: false
  },
  city: {
    validate: "select",
    required: false
  }
}

const initialOffersFilterErrors = {
  department: "",
  city: "",
}

const fieldSearchValidation = {
  searched: {
    error: "",
    validate: "textNumber",
    required: false
  }
};

const initialErrorsSearch={
  searched:''
}

const CompanyInfoView: React.FC = () => {
  const urlBack = "/admin/dashboard/empresas";
  const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
  const { promiseInProgress } = usePromiseTracker();
  const userContext = React.useContext(UserContext);
  let params: any = useParams();
  const [rowsPerPage, setRowsPerPage]= useState(5);
  const [initPage, setInitPage]= useState<number>(0);
  const [companyData, setCompanyData] = useState<IProfileCompany>(new IProfileCompany());
  const [offersList, setOffersList] = useState<Array<IGetOffer>>([]);
  const [offersFiltered, setOffersFiltered] = useState<Array<IGetOffer>>([]);
  const [countryCitiesArray, setCountryCitiesArray] = useState<Array<IListParametersInfo>>([]);
  const [departmentArray, setDepartmentArray] = useState<Array<IGeneralParameter>>([]);
  const [cityArray, setCityArray] = useState<Array<IGeneralParameter>>([]);
  const [cities, setCities] = useState<Array<IGeneralParameter>>([]);
  const [department, setDepartment] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [companyType, setCompanyType] = useState<string>('');
  const [laboralSector, setLaboralSector] = useState<string>('');
  const [offersStateToSend, setOffersStateToSend] = useState(new OfferStateTosendObj());
  const [offersToChangeTable, setOffersToChangeTable] = useState<Array<IAnnouncement>>([]);
  const [formValues, setFormValues] = useState<IFilterOfferForm>(new IFilterOfferForm());
  const [formErrors, setFormErrors] = useState(initialOffersFilterErrors);
  const [chipData, setChipData] = React.useState<ChipData[]>([]);
  const [updateReady, setUpdateReady] = useState<string>('');
  const [offerSelected, setOfferSelected] = useState<IGetOffer>(new IGetOffer());
  const [searched, setSearched] = useState('');
  const [formErrorSearch, setFormErrorSearch] = useState(initialErrorsSearch);
  const [searchActive, setSearchActive] = useState(false);

  const isValid = formValues.department > 0

  useEffect(()=>{
    window.scrollTo(0, 0);
    const getCompanyData = async()=>{
      // data countries-cities
      let dataCountryCitiesRes: any = await getCOUNTRIES_CITIES();
      let dataCountryCities: any = dataCountryCitiesRes.list;
      dataCountryCities = sortByKey(dataCountryCities, 'grandParentName');
      setCountryCitiesArray(dataCountryCities);
      if(params.nit){
        const nit = parseInt(params.nit, 10);
        const companyInfoResponse: any = await trackPromise(getCompanyInfo(userToken, nit));
        if(companyInfoResponse.status === 200){
          const companyInfoResult = await companyInfoResponse.json();
          const target: IProfileCompany = new IProfileCompany();
          Object.assign(target, companyInfoResult.company);
          if(target){
            setCompanyData(target);
            let dataDepartment: any = await trackPromise(getDEPARTAMENT());
            dataDepartment = sortByKey(dataDepartment, 'name');
            if(dataDepartment){
              const depElm = dataDepartment.find((item: IGeneralParameter)=>item.parameter_code === target.department);
              setDepartment(depElm?.name ? depElm.name : '');
              setDepartmentArray(dataDepartment);
            }
            const dataCity: any = await trackPromise(getCITY());
            if(dataCity){
              const cityElm = dataCity.find((item: IGeneralParameter)=>item.parameter_code === target.city);
              setCity(cityElm?.name ? cityElm.name : '');
              setCityArray(dataCity);
            }
            const dataCompanyType: any = await trackPromise(getCOMPANY_TYPE());
            if(dataCompanyType){
              const companyTypeElm = dataCompanyType.find((item: IGeneralParameter)=>item.parameter_code === target.typeOfCompany);
              setCompanyType(companyTypeElm?.name ? companyTypeElm.name : '');
            }
            const dataLaboralSector: any = await trackPromise(getLABORAL_SECTOR());
            if(dataLaboralSector){
              const labSectorElm = dataLaboralSector.find((item: IGeneralParameter)=>item.parameter_code === target.laboralSector);
              setLaboralSector(labSectorElm?.name ? labSectorElm.name : '');
            }
          }
        }
        const resOffersCompany: any = await trackPromise(getlistOffers({company: params.nit}));
        if(resOffersCompany.status === 200){
            let resultOffersCompany = (await resOffersCompany.json()).jobOffers;
            resultOffersCompany = sortByKeyDesc(resultOffersCompany, 'updatedAt');
            setOffersList(resultOffersCompany);
            setOffersFiltered(resultOffersCompany);
        }
      }
    }
    getCompanyData();
  },[params.nit]);

  useEffect(()=>{
    if(formValues.country === 0){
      setFormValues(prev => ({
        ...prev,
        department:0,
        city:0
      }));
      setCities([]);
    }else {
      if(formValues.country === 170){
        setCities([]);
      } else {
        setFormValues(prev => ({
          ...prev,
          department:0,
          city:0
        }));
        const countryObjSelected = countryCitiesArray.find(country=>country.id === formValues.country);
        if(countryObjSelected){
          setCities(countryObjSelected.childs);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.country])

  useEffect(()=>{
    setCities([]);
    setFormValues(prev => ({
      ...prev,
      city: 0
    }));
    if(formValues.department>0){
        let citiesDep:Array<IGeneralParameter> = cityArray.filter(city=> city.parentId === formValues.department);
        citiesDep = sortByKey(citiesDep, 'name');
        if(citiesDep.length>0){
            setCities(citiesDep)
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.department])

  const onChanhePageSize = (e: any)=>{
    setRowsPerPage(e.target.value)
  }

  const handleRowOffer = (action: string, offerSelected: IAnnouncement)=>{
    const offerId = offerSelected.id;
    const stateSelected = action;
    if(offerId && stateSelected.length > 0){
      let offersObject:Array<IAnnouncement> = [offerSelected];
      let offerStructure:Array<OfferStateObject>=[];
      offerStructure.push({id: offerId, state: stateSelected, causal: ""})
      setOffersToChangeTable(offersObject)
      setOffersStateToSend({offers: offerStructure})
    }
  }

  const changeFilterForm = (e: any) => {
    const {name,value} = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
    const error: string = Validations(name, value, filterOffersValidation) || "";
    setFormErrors(prev => ({
      ...prev,
      [name]: error
    }));
  }

  useEffect(()=>{
    setChips();
    if(!isValid){
      filterData('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, isValid])

  const setChips =()=>{
    let tempArray: Array<ChipData> = []
    let index = 0;
    const fieldNames=[
      {key: 'department', description: 'Departamento'},
      {key: 'city', description: 'Municipio'}
    ];
    for (const [key, value] of Object.entries(formValues)) {
      if(value!==0 && value!=='' && value!==null && (key === "department" || key === "city")){
        let obj;
        const fieldObj = fieldNames.find(field=>field.key === key);
        if (key === 'department'){
          obj = departmentArray.find(dep=>dep.parameter_code === value);
        } else if (key === 'city'){
          obj = cityArray.find(city=>city.parameter_code === value);
        }
        if(obj){
          tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + obj.name })
        } else {
          tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + value})
        }
        index++;
      }
    }
    setChipData(tempArray);
  }

  const clickApplyFilters = (value:any, action:string)=>{
    if(action === 'apply'){
      filterData(searched);
    } else if (action === 'clear'){
      setSearched('')
      setSearchActive(false);
      setFormValues(prev => ({
        ...prev,
        department: 0,
        city: 0
      }));
      setChipData([])
    }
  }

  const filterData = (searchValue: string)=>{
    let resultDep:Array<any> = [];
    let resultCity:Array<any> = [];
    let resultSearch:Array<any> = [];
    if(formValues.department > 0){
      const newArray = offersList.filter(offer=>offer.department === formValues.department);
      if(newArray.length>0){
        resultDep = newArray;
      } else {
        resultDep = [];
      }
    } else {
      resultDep = offersList;
    }
    if(formValues.city > 0){
      const newArray = resultDep.filter(offer=>offer.city === formValues.city);
      if(newArray.length>0){
        resultCity = newArray;
      } else {
        resultCity = [];
      }
    } else {
      resultCity = resultDep;
    }
    if(searchValue !== ""){
      const newArray = resultCity.filter(item=>(item.jobTitle.toString()).toLowerCase().includes(searchValue.toLowerCase()));
      if(newArray.length>0){
        resultSearch = newArray;
      } else {
        resultSearch = [];
      }
    } else {
      resultSearch = resultCity
    }
    setInitPage(0);
    if(resultSearch.length>0){
      setOffersFiltered(resultSearch);
    } else {
      setOffersFiltered([]);
    }
  }

  const handleDeleteChip = (chipToDelete: ChipData) => () => {
    const name =chipToDelete.name;
    setChipData((chips) => chips.filter((chip) => chip.name !== chipToDelete.name));
    if(name === 'department' || name === 'city'){
      setFormValues(prev => ({
          ...prev,
          [chipToDelete.name]: 0
      }));
    }
  }

  const handleUpdate = (value: string, offerSelected: IGetOffer) => {
    setUpdateReady(value)
    setOfferSelected(offerSelected)
  }
  const closeUpdate = () => {
    setUpdateReady('');
  };

  const requestSearch = (name: string, searchVal:string)=>{
    const error: string = searchVal !== '' ? Validations(name, searchVal, fieldSearchValidation) || "" : '';
    setFormErrorSearch(prev=>({
      ...prev,
      [name]: error
    }));
    setSearched(searchVal);
    if(searchVal !== ''){
      setSearchActive(true);
      filterData(searchVal);
    } else {
      setSearchActive(false);
      filterData('');
    }
    setInitPage(0);
  }

  const cancelSearch = ()=>{
    setSearched('')
    requestSearch('searched', '');
    setSearchActive(false)
  }

  return (
    <>
    {containsAny(permissionAdminSeeCompanyOffers, userContext.userPermissions) ?
      <>
      {updateReady !== "" ?
        <Grid container className="company-info-container">
          {updateReady === "Edit" &&
            <>
              <Grid container item xs={12} className="back-offers-list-section">
                <Grid container item xs={12} className="main-container">
                  <Grid container item xs={12} className="back-offers-btn-container">
                    <Button
                      variant="contained"
                      className="step-back-offers"
                      startIcon={<NavigateBeforeIcon />}
                      onClick={closeUpdate}
                    >
                      Regresar a información de la empresa
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className="main-container">
                <OfferForm companyName={companyData.name} offerId={offerSelected.id}/>
              </Grid>
            </>
          }
          {updateReady === "See" &&
            <>
              <Grid container item xs={12} className="back-offers-list-section">
                <Grid container item xs={12} className="main-container">
                  <Grid container item xs={12} className="back-offers-btn-container">
                    <Button
                      variant="contained"
                      className="step-back-offers"
                      startIcon={<NavigateBeforeIcon />}
                      onClick={closeUpdate}
                    >
                      Regresar a información de la empresa
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <OfferDetailAdmin
                offerSelected={offerSelected}
                department={department}
                city={city}
              />
            </>
          }
        </Grid>
      :
        offersStateToSend.offers.length>0 ?
          <OffersChangeAction offersObject={offersToChangeTable} offersStateToSend={offersStateToSend} backComponent={<CompanyInfoView/>} />
        :
        <>
          <Grid container item xs={12} className="back-offers-list-section">
            <ButtonBackListOffers url={urlBack} text="Regresar al listado de empresas"/>
          </Grid>
          <Grid container className="company-info-container">
            <Grid container className="main-container">
              <Grid container item xs={12} className="title-container">
                <BusinessIcon className="business-icon"/>
                <Typography variant="h2" className="bold-serif" >
                  {companyData.name}
                </Typography>
              </Grid>
              <Grid container>
                <Typography variant="h5" className="summary-text">
                  En esta interfaz puede administrar las características de la empresa <span className="summary-span">{companyData.name}</span>
                </Typography>
              </Grid>

              <Grid container item xs={12}>
                <CompanyInfo companyData={companyData} department={department} city={city} companyType={companyType} laboralSector={laboralSector}/>
              </Grid>
            </Grid>
            <Grid container item xs={12} className="offer-list-container">
              <Grid container className="main-container">
                <Grid container item xs={12} className="title-container">
                  <BusinessIcon className="business-icon"/>
                  <Typography variant="h2" className="bold-serif" >
                    Ofertas laborales
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  <AnnouncementFilters title="Filtros de búsqueda" formValues={formValues} formErrors={formErrors} onChangeForm={changeFilterForm} countryCitiesArray={countryCitiesArray} departmentArray={departmentArray} cities={cities} reportForm={false} />
                </Grid>
                <Grid container item xs={12}>
                  <ShowFilters titleSection="Filtros seleccionados" filtersData={chipData} stateCheck={false} showButtons={true} deleteFilter={handleDeleteChip} isValid={isValid} onApplyFilters={clickApplyFilters} />
                </Grid>
                <Grid container item xs={12} className="list-container">
                  <Grid container item xs={12} className="list-view">
                    <Grid item xs={12}>
                      <div className="header-table-container">
                        <div className="search-container-outer">
                          <SearchTable textPlaceHolder="Ingresa un nombre de oferta" title="Buscar por nombre del cargo" searched={searched} requestSearch={requestSearch} formErrorSearch={formErrorSearch} searchActive={searchActive} cancelSearch={cancelSearch}/>
                        </div>
                      </div>
                    </Grid>
                    <AnnouncementList onChanhePageSize={onChanhePageSize} rows={offersFiltered} pageSize={rowsPerPage} initPage={initPage} setInitPage={setInitPage} handleRowOffer={handleRowOffer} handleUpdate={handleUpdate}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <LoadingLockPage load={promiseInProgress}/>
        </>
      }
      </>
    :
      <NotAuthorized/>
    }
    </>
  )
}
export default CompanyInfoView;
