import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TabPanelContent from '../TabPanelContent';
import { IGetNewEvent } from '../../interfaces/IGetNewEvent';
import { getOutstanding } from '../../services/NewsEventsService';
import { IGeneralParameter } from '../../interfaces/IGeneralParameter';
import { getCATEGORY } from '../../services/ParameterService';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
      className="tab-panel-container"
    >
      {value === index && (
        <Box p={2}>
          <Grid container spacing={1}>
            {children}
          </Grid>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: any)=> {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default function TabPanelNewsEvents() {
  const [value, setValue] = React.useState(0);
  const [news, setNews] = useState<Array<IGetNewEvent>>([]);
  const [events, setEvents] = useState<Array<IGetNewEvent>>([]);

  useEffect(()=>{
    const getInitialData = async()=>{
      let responseCategory:Array<IGeneralParameter> = await getCATEGORY();
      const getNews: Response = await getOutstanding({id: null, type: "Noticia", state:"Publicado" });
      if (getNews.status === 200){
        let resultNews: Array<IGetNewEvent> = (await getNews.json()).relational;
        resultNews = getArrayWithCategory(resultNews, responseCategory);
        setNews(resultNews)
      }
      const getEvents: Response = await getOutstanding({id: null, type: "Evento", state:"Publicado" });
      if (getEvents.status === 200){
        let resultEvents = (await getEvents.json()).relational;
        resultEvents = getArrayWithCategory(resultEvents, responseCategory);
        setEvents(resultEvents)
      }
    }
    getInitialData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const getArrayWithCategory = (resultNews: Array<IGetNewEvent>, responseCategory: Array<IGeneralParameter>) => {
    resultNews.forEach((item: IGetNewEvent) => {
      const catFound = responseCategory.find(cat=>cat.parameter_code === item.category);
      if(catFound){
        item.categoryName = catFound.name;
      }
    });
    return resultNews;
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="admin-tabs">
      <AppBar position="static" className="appbar-management">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          className="tabs-management tabs-management-admin"
        >
          <Tab  label="Noticias" {...a11yProps(0)} />
          <Tab  label="Eventos" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {news && news.length>0 &&
          <TabPanelContent
            rightBtnText="Ver todas las noticias"
            data={news}
            value={value}
            type = "Noticia"
          />
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
        {events && events.length>0 &&
          <TabPanelContent
            leftBtnText="Ver todos los eventos"
            data={events}
            value={value}
            type = "Evento"
          />
        }
      </TabPanel>
    </div>
  );
}
