import { IFile } from './IFile';
import { IPresignedUrl } from './IPreSignedUrl';
import moment from 'moment';
export class IRegisterEvent{
  id: number;
  type: string;
  category: number;
  highlight: boolean;
  fileType: string;
  name: string;
  files: Array<IFile>;
  signedUrllArr: Array<IPresignedUrl>;
  description: string;
  shortDescription: string;
  place: string;
  dateStart: Date | null;
  timeStart: Date | null;
  dateFinish: Date | null;
  timeFinish: Date | null;
  publishDate: string;
  publish: boolean;
  state: string;
  image: string;
  createdAt: string;
  updatedAt: string;
  constructor(def:any){
    this.id = def.id || null;
    this.type = def.type || "";
    this.category = def.category || 0;
    this.highlight = def.highlight || false;
    this.fileType = def.fileType || "";
    this.name = def.name || "";
    this.files = def.files || [];
    this.signedUrllArr  = def.signedUrllArr || [];
    this.description = def.description || "";
    this.shortDescription = def.shortDescription || "";
    this.place = def.place || "";
    this.dateStart = def.dateStart ? new Date(moment(def.dateStart).format()) : null;
    this.timeStart = def.timeStart ? new Date(moment(def.dateStart).format("YYYY-MM-DD") + " " + def.timeStart) : null;
    this.dateFinish = def.dateFinish ? new Date(moment(def.dateFinish).format()) : null;
    this.timeFinish = def.timeFinish ? new Date(moment(def.dateFinish).format("YYYY-MM-DD") + " " + def.timeFinish) : null;
    this.publishDate = def.publishDate ? def.publishDate: "";
    this.publish = def.publish || false;
    this.state = def.state || "";
    this.image = def.image ? def.image : "";
    this.createdAt = def.createdAt || "";
    this.updatedAt = def.updatedAt || "";
  }
}