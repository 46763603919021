import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import './PrincipalTitle.scss';

interface PrincipalTitleProps{
    principalTitle?: string;
    titleSize?: number;
}

const PrincipalTitle:React.FC<PrincipalTitleProps> = ({principalTitle, titleSize})=> {
    return (
        <Grid container item xs={12} className="principal-title-container" alignItems="center">
            <Typography style={{fontSize: titleSize? titleSize: 24}} className="principal-title-text">
                {principalTitle}
            </Typography>
        </Grid>
    )
}

export default PrincipalTitle
