/* eslint-disable no-const-assign */
/* eslint-disable no-loop-func */
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import moment from 'moment';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { GridSelectionModelChangeParams, GridSortModelParams } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { DateRange } from '@material-ui/pickers';
import UserContext from "../../../../utils/UserContext";
import Validations from '../../../../helpers/Validations';
import { ChipData } from '../../../../interfaces/ChipData';
import { UserStateObject } from '../../../../interfaces/UserStateObject';
import MoreFiltersGraduate from '../../../../components/ManagementState/MoreFiltersGraduate';
import SimpleFilterBox from '../../../../components/ManagementState/SimpleFilterBox';
import ShowFilters from '../../../../components/ManagementState/ShowFilters';
import SearchTable from '../../../../components/ManagementState/SearchTable';
import ErrorModal from '../../../../components/ShareComponents/ErrorModal';
import SelectActionsBox from '../../../../components/ManagementState/SelectActionsBox';
import { IListGraduates } from '../../../../interfaces/IListGraduates';
import { getlistFilterGraduates, listCampusCurriculum } from '../../../../services/AdminServices';
import { StateByRole } from '../../../../interfaces/StateByRole';
import { IGeneralSIA } from '../../../../interfaces/IGeneralSIA';
import { IGeneralParameter } from '../../../../interfaces/IGeneralParameter';
import { IGraduateTable } from '../../../../interfaces/IGraduateTable';
import DataTableGraduate from '../../../../components/ManagementState/DataTableGraduate';
import { isLogin } from '../../../../services/AuthService';
import GraduateChangeAction from '../../../../components/ManagementState/GraduateChangeAction';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import { checkValuesSelected, containsAny, resetStatusCheckArray, sortByKey, sortByKeyDesc } from '../../../../helpers/sharedFunctions';
import { IListParametersInfo } from '../../../../interfaces/IListParametersInfo';
import { GENDER } from '../../../../services/SIAService';
import { getACADEMIC_LEVEL, getASSOCIATION, getCAMPUS_FACULTIES, getPROGRAMS, getTYPE_OF_ASSOCIATION } from '../../../../services/ParameterService';
import { IGraduateData } from '../../../../interfaces/IGraduateData';
import GraduateInformation from '../../../../components/GraduateInformation';
import { IGraduateInformation } from '../../../../interfaces/IGraduateInformation';
import { permissionAdminSeeInfoGraduates, permissionGraduateActiveInactiveState, permissionGraduateVerifyRejectState, permissionGraduateView } from '../../../../utils/PermissionsVariables';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import { IGraduateFilterForm } from '../../../../interfaces/IGraduateFilterForm';
import { IGraduateFilterObjDinamic } from '../../../../interfaces/IGraduateFilterObjDinamic';

import './GraduateManagement.scss';

export class UsersStateTosendObj{
    graduates: Array<UserStateObject> = []
}

const filterGraduateValidation = {

    names: {
      error: "",
      validate: "text",
      maxLength: 40,
      required: false
    },
    surname: {
        error: "",
        validate: "text",
        maxLength: 40,
        required: false
    },
    gender: {
        validate: "select",
        required: false
    },
    doc: {
        error: "",
        validate: "textNumber",
        minLength: 6,
        maxLength: 11,
        required: false
    },
    state:{
        validate: "select",
        required: false
    },
    campus: {
        validate: "select",
        required: false
    },
    school: {
        validate: "select",
        required: false
    },
    curriculum: {
        validate: "select",
        required: false
    },
    academicLevel: {
        validate: "select",
        required: false
    },
    typeOfAssociation:{
        validate: "select",
        required: false
    },
    association:{
        validate: "select",
        required: false
    },
    observation: {
        validate: "select",
        required: false
    },
    otherObservation: {
        error: "",
        validate: "textNumber",
        minLength: 20,
        maxLength: 200,
        required: true
    }
}
const initialGraduateFilterErrors ={
    names: "",
    surname: "",
    gender: "",
    doc: "",
    state: "",
    updatedAtStart: "",
    updatedAtEnd: "",
    campus: "",
    school: "",
    curriculum: "",
    academicLevel: "",
    graduationStart: "",
    graduationEnd: "",
    typeOfAssociation: "",
    asociation: "",
}

const fieldsValidation = {
    searched: {
      error: "",
      validate: "number",
      required: false
    }
};

const initialErrorsSearch={
    searched:''
}

const actionsForGraduates:Array<IGeneralSIA> = [
    {id:1, name:'Cambiar estado a...'},
]

const stateOptionsByRole: Array<StateByRole> = [
    {id:1, label: 'Inscrito', value:'Inscrito', description: 'Cambiar estado a Inscrito', permission:[] },
    {id:2, label: 'Verificado', value:'Verificado', description: 'Cambiar estado a Verificado', permission:  permissionGraduateVerifyRejectState },
    {id:3, label: 'Activo', value:'Activo', description: 'Cambiar estado a Activo', permission: permissionGraduateActiveInactiveState },
    {id:4, label: 'Inactivo', value:'Inactivo', description: 'Cambiar estado a Inactivo', permission: permissionGraduateActiveInactiveState },
    {id:5, label: 'Rechazado', value:'Rechazado', description: 'Cambiar estado a Rechazado', permission: permissionGraduateVerifyRejectState }
];

export const transformDataGraduate = (values: IGraduateFilterForm, preview:boolean)=>{
    const temporaryObject: IGraduateFilterObjDinamic = new IGraduateFilterObjDinamic();
    temporaryObject.preview = preview;
    // graduate info filters
    if(temporaryObject.graduate && temporaryObject.graduate.data){
        if(values.names !== ""){
            temporaryObject.graduate.data.names = values.names;
        } else { delete temporaryObject.graduate.data.names }
        if(values.surname !== ""){
            temporaryObject.graduate.data.surname = values.surname;
        } else { delete temporaryObject.graduate.data.surname }
        if(values.gender >0){
            temporaryObject.graduate.data.gender = values.gender;
        } else { delete temporaryObject.graduate.data.gender }
        if(values.doc !== ""){
            temporaryObject.graduate.data.doc = values.doc;
        } else { delete temporaryObject.graduate.data.doc }
        if(values.state.length>0){
            temporaryObject.graduate.data.state = values.state.map(item=>item.value);
        } else { delete temporaryObject.graduate.data.state }
        if(values.updateDate[0] !== null && values.updateDate[1] !== null){
            temporaryObject.graduate.data.updateAtInitDate = moment(values.updateDate[0]).format();
            temporaryObject.graduate.data.updateAtEndDate = moment(values.updateDate[1]).format();
        } else {
            delete temporaryObject.graduate.data.updateAtInitDate;
            delete temporaryObject.graduate.data.updateAtEndDate
        }
        if(values.inJobBankDate[0] !== null && values.inJobBankDate[1] !== null){
            temporaryObject.graduate.data.inJobBankInit = moment(values.inJobBankDate[0]).format();
            temporaryObject.graduate.data.inJobBankEnd = moment(values.inJobBankDate[1]).format();
        } else {
            delete temporaryObject.graduate.data.inJobBankInit;
            delete temporaryObject.graduate.data.inJobBankEnd;
        }
    }
    // studies filters
    if(temporaryObject.studies && temporaryObject.studies.data){
        if(values.campus >0){
            temporaryObject.studies.data.campus = [values.campus];
        } else { delete temporaryObject.studies.data.campus }
        if(values.school.length > 0){
            temporaryObject.studies.data.school = values.school.map(item=>item.parameter_code);
        } else { delete temporaryObject.studies.data.school }
        if(values.curriculum.length > 0){
            temporaryObject.studies.data.curriculum = values.curriculum.map(item=>item.parameter_code);
        } else { delete temporaryObject.studies.data.curriculum }
        if(values.academicLevel >0){
            temporaryObject.studies.data.academicLevel = values.academicLevel;
        } else { delete temporaryObject.studies.data.academicLevel }
        if(values.graduationDate[0] !== null && values.graduationDate[1] !== null){
            temporaryObject.studies.data.graduationInitDate = moment(values.graduationDate[0]).format("YYYY-MM-DD");
            temporaryObject.studies.data.graduationEndDate = moment(values.graduationDate[1]).format("YYYY-MM-DD");
        } else {
            delete temporaryObject.studies.data.graduationInitDate;
            delete temporaryObject.studies.data.graduationEndDate
        }
    }
    // association filters
    if(temporaryObject.associations && temporaryObject.associations.data){
        if(values.typeOfAssociation >0){
            temporaryObject.associations.data.typeOfAssociation = values.typeOfAssociation;
        } else { delete temporaryObject.associations.data.typeOfAssociation }
        if(values.association >0){
            temporaryObject.associations.data.association = values.association;
        } else { delete temporaryObject.associations.data.association }
    }
    return temporaryObject;
}

const GraduateManagement: React.FC = ()=>{
    const { promiseInProgress } = usePromiseTracker();
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const userContext = React.useContext(UserContext);
    const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
    const [checkRows, setCheckRows] = useState<Array<string>>([]);
    const [rowsPerPage, setRowsPerPage]= useState(5);
    const [initPage, setInitPage]= useState<number>(0);
    const [searched, setSearched] = useState('');
    const [originData, setOriginData] = useState<IGraduateTable[]>([]);
    const [dataFiltered, setDataFiltered] = useState<IGraduateTable[]>([]);
    const [tempDataFiltered, setTempDataFiltered] = useState<IGraduateTable[]>([]);
    const [formErrorSearch, setFormErrorSearch] = useState(initialErrorsSearch)
    const [searchActive, setSearchActive] = useState(false);
    const [filterValues, setFilterValues] = useState<IGraduateFilterForm>(new IGraduateFilterForm())
    const [formErrors, setFormErrors] = useState(initialGraduateFilterErrors);
    const [chipData, setChipData] = React.useState<ChipData[]>([]);
    const [actionSelected, setActionSelected]= useState(0);
    const [stateOptions, setStateOptions] = useState<Array<StateByRole>>([]);
    const [gendersType, setGendersType] = useState<Array<IGeneralSIA>>([]);
    const [allCampus, setAllCampus] = useState<Array<IListParametersInfo>>([]);
    const [allPrograms, setAllPrograms] = useState<Array<IGeneralParameter>>([]);
    const [schools, setSchools] = useState<Array<IGeneralParameter>>([]);
    const [programs, setPrograms] = useState<Array<IGeneralParameter>>([]);
    const [allAcademic, setAllAcademic] = useState<Array<IGeneralParameter>>([]);
    const [associationTypeArray, setAssociationTypeArray] = useState<Array<IGeneralParameter>>([]);
    const [associationArray, setAssociationArray] = useState<Array<IGeneralParameter>>([]);
    const [usersStateToSend, setUsersStateToSend] = useState(new UsersStateTosendObj());
    const [usersToChange, setUsersToChange] = useState<Array<string>>([]);
    const [usersToChangeTable, setUsersToChangeTable] = useState<Array<IGraduateTable>>([]);
    const [stateSelected, setStateSelected] = useState<string>('');
    const [graduateInfoStatus, setGraduateInfoStatus] = useState<boolean>(false)
    const [validIs, setValidIs] = useState<boolean>(false)
    const [graduateInfo, setGraduateInfo] = useState<IGraduateInformation>(new IGraduateInformation());
    const [currrentPage, setCurrentPage] = useState<number>(0);
    const [campusSelected, setCampusSelected] = useState<number>(0);
    const [schoolSelected, setSchoolSelected] = useState<number>(0);
    const [aux, setAux] = useState<boolean>(false);
    const [aux2, setAux2] = useState<boolean>(false);
    const [openModalError, setOpenModalError] = useState(false);
    const [errorMessage, setErrorMessage]= useState('');
    const limitNumber = 100;
    const [deleteChipActive, setDeleteChipActive] = useState<boolean>(false);
    const [applyFilterActive, setApplyFilterActive] = useState<boolean>(false);
    const [total, setTotal] = useState(0);

    const [stateCheck, setStateCheck] = useState<any>({
        Inscrito: false,
        Verificado: false,
        Activo: false,
        Inactivo: false,
        Rechazado: false
    });

    const isValid =
        !formErrors.names &&
        !formErrors.surname &&
        !formErrors.doc &&
        !formErrors.updatedAtStart &&
        !formErrors.updatedAtEnd &&
        !formErrors.graduationStart &&
        !formErrors.graduationEnd

    useEffect(()=>{
        window.scrollTo(0, 0);
        const getInitialData = async()=>{
            const optionsInit = [""];
            let originData: IListGraduates;
            //data for table
            setStateCheck({ ...stateCheck, "Inscrito": false });
            if(dataFiltered.length === 0){
                const responseGraduatesList = await getlistFilterGraduates(
                    userToken,
                    {
                        graduate: {
                            data: {
                              state: optionsInit
                            }
                        },
                        limit: limitNumber === rowsPerPage ? limitNumber+25 : limitNumber,
                        offset: 0
                    }
                );
                if(responseGraduatesList.status === 200){                    
                    originData = await responseGraduatesList.json();
                    let tempArr:Array<IGraduateTable> = [];
                    if(originData && originData.graduates.length>0){
                        originData.graduates.forEach((item: IGraduateData)=>{
                            const target: IGraduateTable = new IGraduateTable();
                            if(item.graduate && item.educationUnal[0]){
                                item.educationUnal[0].updatedAt = item.graduate.updatedAt;
                            }
                            const result = Object.assign(target, {...item.graduate, ...item.educationUnal[0]});
                            // result.id=parseInt(result.doc)
                            result.fullname = result.names + ' ' + result.surname + ' ' + result.secondsurname;
                            tempArr.push(result)
                        })
                        setOriginData(tempArr);
                        setDataFiltered(tempArr);
                        setTempDataFiltered(tempArr);
                    }
                }
            }

            const userToConsult = {
                userId: userContext.userId,
                roleId: userContext.roleSelected
            }
            if(userContext.roleSelected === 4 || userContext.roleSelected === 6){
                const responseConsult = await (listCampusCurriculum(
                    {...userToConsult}
                    ))
                if(responseConsult.status === 200){
                    const result = await responseConsult.json();
                    setCampusSelected(result.campus)
                    setSchoolSelected(result.school)
                    if(userContext.roleSelected === 4){
                        setAux(true)
                    }
                    if(userContext.roleSelected === 6){
                        setAux(true)
                        setAux2(true)
                    }
                    
                } else {
                    setOpenModalError(true);
                    setErrorMessage("Hubo un error, intente de nuevo mas tarde, o contacte al administrador general")
                }
            }

            // data for campus field
            let dataAllCampus: Array<IListParametersInfo> = (await getCAMPUS_FACULTIES()).list;
            dataAllCampus = sortByKey(dataAllCampus, 'parentName');
            setAllCampus(dataAllCampus ? dataAllCampus : []);
            // data for programs
            let dataAllPrograms = await getPROGRAMS();
            dataAllPrograms = sortByKey(dataAllPrograms, 'name')
            setAllPrograms(dataAllPrograms ? dataAllPrograms : []);
            // data for all academic levels
            let responseAcademic: Array<IGeneralParameter> = await getACADEMIC_LEVEL();
            responseAcademic = sortByKey(responseAcademic, 'name')
            setAllAcademic(responseAcademic);
            //data for gender field
            const dataGender:Array<IGeneralSIA> = GENDER;
            setGendersType(dataGender || []);
            // data for association type
            let resAssociationType: Array<IGeneralParameter> = await getTYPE_OF_ASSOCIATION();
            setAssociationTypeArray(resAssociationType);
            // data for association
            let resAssociation: Array<IGeneralParameter> = await getASSOCIATION();
            resAssociation = sortByKey(resAssociation, 'name');
            setAssociationArray(resAssociation);
            if(userContext.userPermissions.length>0){
                let optionsRole: Array<StateByRole> =  stateOptionsByRole.filter((option)=> {
                    return containsAny(option.permission,userContext.userPermissions);
                });
                optionsRole = sortByKey(optionsRole, 'id');
                setStateOptions(optionsRole);
            }
            setInitLoad(false);
        }
        if(containsAny(permissionGraduateView,userContext.userPermissions)){
            getInitialData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        setSchools([]);
        setPrograms([]);
        setFilterValues(prev => ({
            ...prev,
            school: [],
            curriculum: []
        }))
        if(filterValues.campus>0){
            const campusFound = allCampus.find(campus=> campus.id === filterValues.campus);
            if(campusFound){
                const campus: IListParametersInfo = campusFound;
                let schools = campus.childs;
                schools = sortByKey(schools, 'name');
                setSchools(schools);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filterValues.campus])

    useEffect(()=>{
        const getPrograms = async()=>{
            if(filterValues.school.length>0){
                let programsArr: IGeneralParameter[] = []
                filterValues.school.forEach(obj=>{
                    const schoolPrograms: IGeneralParameter[] = allPrograms.filter(item=>item.parentId === obj.parameter_code);
                    if(schoolPrograms.length>0){
                        programsArr.push(...schoolPrograms);
                    }
                })
                if (programsArr.length>0){
                    programsArr = sortByKey(programsArr, 'name')
                    setPrograms(programsArr)
                    let newCurriculum: Array<IGeneralParameter> = [];
                    filterValues.curriculum.forEach(option=>{
                        const found = programsArr.find(item=>item.name === option.name);
                        if(found){
                            newCurriculum.push(found)
                        }
                    })
                    setFilterValues(prev => ({
                        ...prev,
                        curriculum: newCurriculum
                    }));
                }else {
                    setPrograms(programsArr)
                    setFilterValues(prev => ({
                        ...prev,
                        curriculum: []
                    }));
                }
            } else {
                setPrograms([])
                setFilterValues(prev => ({
                    ...prev,
                    curriculum: []
                }));
            }
        }
        getPrograms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filterValues.school])

    useEffect(()=>{
        if(isValid){
            setChips();
        }
        if(deleteChipActive && applyFilterActive){
            const prevObjectToSend = transformDataGraduate(filterValues, true);
            const objectToSend = transformGraduateFields(prevObjectToSend, limitNumber === rowsPerPage ? limitNumber+25 : limitNumber, 0);
            updateDataGraduates(objectToSend, []);
            setInitPage(0);
            setDeleteChipActive(false);
        }
        if(chipData.length === 0 ){
            setApplyFilterActive(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, isValid])

    useEffect(()=>{
        const checkForNewData = async() => {
            if(originData.length>0){
                const totalRows = originData.length;
                const totalPages = Math.ceil(totalRows/rowsPerPage);
                let lastPage = false;
                if(totalPages-currrentPage === 0){
                    lastPage = true;
                } else {
                    lastPage = false;
                }
                const prevObjectToSend = transformDataGraduate(filterValues, true);
                const objectToSend = transformGraduateFields(prevObjectToSend, limitNumber === rowsPerPage ? limitNumber+25 : limitNumber, totalRows);
                if(lastPage){
                    updateDataGraduates(objectToSend, originData);
                }
            }
        }
        checkForNewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currrentPage,rowsPerPage])

    const updateDataGraduates = async(objectToSend: IGraduateFilterObjDinamic, currentData: Array<any>) => {
        const responseGraduatesList = await trackPromise(getlistFilterGraduates(
            userToken,
            objectToSend
        ));
        if(responseGraduatesList.status === 200){       
            const newData = await responseGraduatesList.json();
            const auxTotal = newData.total;
            setTotal(auxTotal);
            let tempArr:Array<IGraduateTable> = [];
            if(newData && newData.graduates.length>0){
                newData.graduates.forEach((item: IGraduateData)=>{
                    const target: IGraduateTable = new IGraduateTable();
                    if(item.graduate && item.educationUnal[0]){
                        item.educationUnal[0].updatedAt = item.graduate.updatedAt;
                    }
                    const result = Object.assign(target, {...item.graduate, ...item.educationUnal[0]});
                    // result.id=parseInt(result.doc)
                    result.fullname = result.names + ' ' + result.surname + ' ' + result.secondsurname;
                    tempArr.push(result)
                })
                setOriginData([...currentData, ...tempArr]);
                setDataFiltered([...currentData, ...tempArr]);
                setTempDataFiltered([...currentData, ...tempArr]);
            }
            if(objectToSend.offset === 0 && newData.graduates.length === 0){
                setOriginData([]);
                setDataFiltered([]);
                setTempDataFiltered([]);
            }
        }
    }

    const handleRowButton = (action:string, rowSelected: IGraduateTable)=>{
        const userSelected: IGraduateTable = rowSelected;
        const userId = rowSelected.doc;
        const stateSelected = action;
        let usersObject:Array<IGraduateTable> = [userSelected];
        let userStructure:Array<UserStateObject>=[];
        if(userId.length>0){
            userStructure.push({documentId: userId, state: stateSelected, observation: "", otherObservation:''})
        }
        setUsersToChangeTable(usersObject)
        setUsersStateToSend({graduates: userStructure})
    }

    const handleGraduate = (value: boolean, rowSelected: IGraduateInformation) => {
        setGraduateInfo(rowSelected);
        setGraduateInfoStatus(value);
    };

    const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
        setApplyFilterActive(false);
        setStateCheck({ ...stateCheck, [event.target.name]: event.target.checked });
    };

    const onChanhePageSize = (e: any)=>{
        setRowsPerPage(e.target.value);
        setInitPage(0);
    }

    const requestSearch = (name: string, searchVal:string)=>{
        const error: string = searchVal !== '' ? Validations(name, searchVal, fieldsValidation) || "" : '';
        setFormErrorSearch(prev=>({
            ...prev,
            [name]: error
        }));
        setSearched(searchVal);
        if(searchVal !== ''){
            setSearchActive(true);
            const filteredData = originData.filter(item=>item.doc.toString().includes(searchVal));
            setDataFiltered(filteredData)
            setTempDataFiltered(filteredData);
        } else {
            setSearchActive(false);
            setDataFiltered(originData);
            setTempDataFiltered(originData);
        }
        setInitPage(0);
    }

    const cancelSearch = ()=>{
        setSearched('')
        requestSearch('searched', '');
        setSearchActive(false)
    }

    const changeFilterForm = (e: any) => {
        const {name,value} = e.target;
        setFilterValues(prev => ({
            ...prev,
            [name]: value
        }));
        const error: string = Validations(name, value, filterGraduateValidation) || "";
        setFormErrors(prev=>({
            ...prev,
            [name]: error
        }));
    }

    const changeFilterDates = (name: string, dateValue: DateRange<Date>)=>{
        setFilterValues(prev => ({
            ...prev,
            [name]: dateValue
        }));
        const startDate: string = moment(dateValue[0]).format('YYYY/MM/DD');
        const endDate: string = moment(dateValue[1]).format('YYYY/MM/DD');
        let labelStart: string = "";
        let labelEnd: string = "";
        const valueStart = startDate === "Fecha inválida" ? "Fecha inválida" : "";
        const valueEnd = endDate === "Fecha inválida" ? "Fecha inválida" : "";
        if(name === "updateDate"){
            labelStart = "updatedAtStart";
            labelEnd = "updatedAtEnd";
        }
        if(name === "graduationDate"){
            labelStart = "graduationStart";
            labelEnd = "graduationEnd";
        }
        errorDates(labelStart, valueStart);
        errorDates(labelEnd, valueEnd);
    }

    const errorDates = (label: string, value: string) => {
        setFormErrors(prev => ({
            ...prev,
            [label]: value
        }));
    }

    const handleClickAll = (allSelect:boolean)=>{
        if(allSelect){
            let list = [];
            for (let i = 0; i < dataFiltered.length; i++) {
                list.push((dataFiltered[i].id).toString());
            }
            setCheckRows(list)
        } else {
            setCheckRows([])
        }
    }

    const handleSortedRows = (params: any)=>{
        if(params.sortModel.length>0){
            let datasorted: IGraduateTable[] = [...dataFiltered];
            datasorted.forEach(item =>{
                if(item.fullname){
                    item.fullname = (item.fullname.charAt(0).toUpperCase() + item.fullname.slice(1).toLowerCase()).trim();
                }
            });
            const fieldToSort = params.sortModel[0].field;
            const sortOption = params.sortModel[0].sort;
            if(sortOption === "asc"){
                datasorted = sortByKey(datasorted, fieldToSort);
            } else if (sortOption === "desc") {
                datasorted = sortByKeyDesc(datasorted, fieldToSort);
            }
            setDataFiltered(datasorted);
        } else {
            setDataFiltered(tempDataFiltered);
        }
    }

    const transformGraduateFields = (prevObjectToSend: IGraduateFilterObjDinamic, limit: number, offset: number) => {
        if(prevObjectToSend.graduate && prevObjectToSend.graduate.data){
            delete prevObjectToSend.graduate.fields;
            const stateFieldsSelected = checkValuesSelected(stateCheck);
            prevObjectToSend.graduate.data.state = stateFieldsSelected || [];
        }
        if(prevObjectToSend.studies && prevObjectToSend.studies.data){
            delete prevObjectToSend.studies.fields;
            if(Object.keys(prevObjectToSend.studies.data).length === 0){
                delete prevObjectToSend.studies;
            }
        }
        if(prevObjectToSend.associations && prevObjectToSend.associations.data){
            delete prevObjectToSend.associations.fields;
            if(Object.keys(prevObjectToSend.associations.data).length === 0){
                delete prevObjectToSend.associations;
            }
        }
        delete prevObjectToSend.laboralExperience;
        delete prevObjectToSend.preview;
        prevObjectToSend.limit = limit;
        prevObjectToSend.offset = offset;
        return prevObjectToSend;
    }

    const clickApplyFilters = (value:any, action:string)=>{
        if(action === 'apply'){
            requestSearch('searched', '');
            const prevObjectToSend = transformDataGraduate(filterValues, true);
            const objectToSend = transformGraduateFields(prevObjectToSend, limitNumber === rowsPerPage ? limitNumber+25 : limitNumber, 0);
            updateDataGraduates(objectToSend, []);
            setInitPage(0);
            setChips();
            setApplyFilterActive(true);
        } else if (action === 'clear'){
            setSearched('');
            setSearchActive(false);
            setChipData([]);
            setFormErrors(initialGraduateFilterErrors);
            setApplyFilterActive(false);
            const newState = resetStatusCheckArray(stateCheck);
            setStateCheck({ ...newState, "Inscrito": false });
            const prevObjectToSend = transformDataGraduate(new IGraduateFilterForm(), false);
            const objectToSend = transformGraduateFields(prevObjectToSend, limitNumber === rowsPerPage ? limitNumber+25 : limitNumber, 0);
            if(objectToSend.graduate && objectToSend.graduate.data){
                objectToSend.graduate.data.state = [""];
                updateDataGraduates(objectToSend, []);
                setInitPage(0);
            }
            setFilterValues(new IGraduateFilterForm());
        }
    }

    const setChips =()=>{
        let tempArray: Array<ChipData> = []
        let index = 0;
        let subIndex =0;
        const fieldNames=[
            {key: 'names', description: 'Nombres'},
            {key: 'surname', description: 'Apellidos'},
            {key: 'gender', description: 'Género'},
            {key: 'doc', description: 'No de documento'},
            {key: 'campus', description: 'Sede'},
            {key: 'school', description: 'Facultad'},
            {key: 'curriculum', description: 'Programa'},
            {key: 'academicLevel', description: 'Nivel educativo'},
            {key: 'updateDate', description: 'Fecha de actualización'},
            {key: 'graduationDate', description: 'Fecha de graduación'},
            {key: 'typeOfAssociation', description: 'Tipo de asociación'},
            {key: 'association', description: 'Asociación'}
        ]
        for (const [key, value] of Object.entries(filterValues)) {
            if(value!==0 && value!=='' && value!==null && value!==undefined && value.length!==0 ){
                if(value[0]!==null && value[1]!==null ){
                    let obj;
                    const fieldObj = fieldNames.find(field=>field.key === key);
                    if(key === 'gender'){
                        obj = gendersType.find(gender=>gender.id === value);
                    } else if (key === 'campus'){
                        subIndex = index;
                        const tempCampus = allCampus.find(campus=>campus.id === value);
                        if(tempCampus){
                            tempArray.push({key: subIndex, name:tempCampus.parentName + ' campus', label: fieldObj?.description + ': ' + tempCampus?.parentName });
                            subIndex++;
                        }
                    } else if (key === 'school'){
                        subIndex = index;
                        (value as IGeneralParameter[]).forEach(schoolItem=>{
                            tempArray.push({key: subIndex, name:schoolItem.name + ' school', label: fieldObj?.description + ': ' + schoolItem.name });
                            subIndex++;
                        });
                    } else if (key === 'curriculum'){
                        subIndex = index;
                        (value as IGeneralParameter[]).forEach(programItem=>{
                            tempArray.push({key: subIndex, name:programItem.name + ' curriculum', label: fieldObj?.description + ': ' + programItem.name });
                            subIndex++;
                        });
                    } else if (key === 'academicLevel'){
                        obj = allAcademic.find(academic=>academic.parameter_code === value);
                    } else if (key === 'typeOfAssociation'){
                        obj = associationTypeArray.find(assoType=>assoType.parameter_code === value);
                    } else if (key === 'association'){
                        obj = associationArray.find(asso=>asso.parameter_code === value);
                    } else if (key === 'updateDate'){
                        const startDate: string = moment(value[0]).format('DD/MM/YYYY');
                        const endDate: string = moment(value[1]).format('DD/MM/YYYY');
                        obj = {name: `${startDate} hasta ${endDate}`}
                    } else if (key === 'graduationDate'){
                        const startDate: string = moment(value[0]).format('DD/MM/YYYY');
                        const endDate: string = moment(value[1]).format('DD/MM/YYYY');
                        obj = {name: `${startDate} hasta ${endDate}`}
                    }
                    if(subIndex !==0){
                        index = subIndex
                        subIndex = 0;
                    }
                    if(obj){
                        tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + obj.name })
                    } else {
                        if(key !== 'campus' && key !== 'school' && key !== 'curriculum'){
                            tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + value})
                        }
                    }
                    index++;
                }
            }
        }
        setChipData(tempArray)      
        if(userContext.roleSelected === 4){
            if(filterValues.campus>0){
            setValidIs(true)
            }else{
                setValidIs(false)
            }
        }else if (userContext.roleSelected === 6){
            if(filterValues.campus>0 && filterValues.school.length>0){
                setValidIs(true)
            }else{
                setValidIs(false)
            }
        }
        else if (userContext.roleSelected === 9){
            setValidIs(true)
        }
        
        
    };

    const handleDeleteChip = (chipToDelete: ChipData) => () => {
        setDeleteChipActive(true);
        const name =chipToDelete.name;
        setChipData((chips) => chips.filter((chip) => chip.name !== chipToDelete.name));
        if(name === 'gender' || name === 'academicLevel'){
            setFilterValues(prev => ({
                ...prev,
                [chipToDelete.name]: 0
            }));
        } else if (name.includes('campus')){
            setFilterValues(prev => ({
                ...prev,
                campus: 0
            }));
        } else if(name === 'updateDate' || name === "graduationDate"){
            setFilterValues(prev => ({
                ...prev,
                [name]: [null,null]
            }));
        } else if(name.includes('school')){
            const newSchools = filterValues.school.filter(item=>item.name !== chipToDelete.label.split(': ')[1]);
            setFilterValues(prev => ({
                ...prev,
                school: newSchools
            }));
        } else if(name.includes('curriculum')){
            const newCurriculum = filterValues.curriculum.filter(item=>item.name !== chipToDelete.label.split(': ')[1]);
            setFilterValues(prev => ({
                ...prev,
                curriculum: newCurriculum
            }));
        } else {
            setFilterValues(prev => ({
                ...prev,
                [chipToDelete.name]: ''
            }));
        }
    };

    const handleChangeAction = (e: any)=>{
        setActionSelected(e.target.value)
    }

    const handleChangeStateSelected = (e:any)=>{
        setStateSelected(e.target.value)
    }

    const sendActionSelected =()=>{
        let userStructure:Array<UserStateObject>=[];
        let usersObject:Array<IGraduateTable> = [];
        if(usersToChange.length>0){
            usersToChange.forEach((userId:string)=>{
                userStructure.push({documentId: userId, state: stateSelected, observation: "", otherObservation:''})
                const usersFound = dataFiltered.find(user=>user.id === userId);
                if(usersFound){
                    usersObject.push(usersFound)
                }
            })
        }
        setUsersToChangeTable(usersObject)
        setUsersStateToSend({graduates: userStructure})
    }

    const handleSelectionRows = (params:any)=>{
        setUsersToChange(params.selectionModel)
    }

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
        window.location.reload();
    };

    useEffect(() => {
        if(userContext.roleSelected === 5){
            setValidIs(true)
        }
    }, [])

    return(
        <>
        {isLogin() ?
            <>
            {
                containsAny(permissionGraduateView,userContext.userPermissions) ?
                <>
                {!graduateInfoStatus ?
                    <>
                    {
                        usersStateToSend.graduates.length>0 ?
                            <GraduateChangeAction usersObject={usersToChangeTable} usersStateToSend={usersStateToSend} allPrograms={allPrograms} formValues={filterValues} formErrors={formErrors} onChangeForm = {changeFilterForm}/>
                    :
                        <Grid container className="main-container">
                            <Grid container item xs={12}>
                                <Typography variant="h5" className="tab-description">
                                    En esta interfaz puede administrar los usuarios egresados, hacer búsquedas específicas, realizar acciones masivas, entre otras.
                                </Typography>
                                <>
                                {
                                    userContext.roleSelected === 4?
                                    <>
                                    <div className="title-section-line">
                                        <Typography variant="h3" className="message-title" >
                                            <b className="red-title">Importante:</b> Señor administrador(a): Para poder filtrar su población de egresados debe seleccionar su sede
                                        </Typography>
                                    </div>
                                    </>
                                    :
                                    <>
                                    </>
                                }
                                </>
                                <>
                                {
                                    userContext.roleSelected === 6?
                                    <>
                                    <div className="title-section-line">
                                        <Typography variant="h3" className="message-title" >
                                            <b className="red-title">Importante:</b> Señor administrador(a): Para poder filtrar su población de egresados debe seleccionar su sede y facultad
                                        </Typography>
                                    </div>
                                    </>
                                    :
                                    <>
                                    </>
                                }
                                </>
                            </Grid>
                            <Grid container item xs={12}>
                                <SimpleFilterBox title="Estado del registro del egresado" subtitle="Filtrar por estados:" stateCheck={stateCheck} onChangeState={handleChangeState} stateOptions={stateOptionsByRole}/>
                            </Grid>
                            <Grid container item xs={12}>
                                <MoreFiltersGraduate titleSection="Agregar otros filtros de búsqueda" formValues={filterValues} formErrors={formErrors} onChangeForm={changeFilterForm} changeFilterDates={changeFilterDates} gendersType={gendersType} allCampus={allCampus} schools={schools} programs={programs} allAcademic={allAcademic} associationTypeArray={associationTypeArray} associationArray={associationArray} reportForm={false} stateOptions={stateOptionsByRole} campusSelected={campusSelected} aux={aux} aux2={aux2} schoolSelected={schoolSelected}/>
                            </Grid>
                            
                            <Grid container item xs={12}>
                                <ShowFilters titleSection="Filtros seleccionados" filtersData={chipData} stateCheck={stateCheck} showButtons={true} showDelete={true} deleteFilter={handleDeleteChip} isValid={validIs} onApplyFilters={clickApplyFilters} />
                            </Grid>
                            <Grid container item xs={12}>
                                <Typography variant="h2" className="bold-serif-primaryColor">Egresados</Typography>
                            </Grid>
                            <Grid container item xs={12}>
                                <div className="header-table-container">
                                    <div className="search-container-outer">
                                        {/* <SearchTable textPlaceHolder="Ingresa número de documento" title="Buscar por número de identificación" searched={searched} requestSearch={requestSearch} formErrorSearch={formErrorSearch} searchActive={searchActive} cancelSearch={cancelSearch}/> */}
                                    </div>
                                    <div className="results-found-container">
                                        <Typography variant="h5" className="bold-serif">
                                            Total egresados encontrados {total}
                                        </Typography>
                                    </div>
                                    <div className="select-all-btns">
                                        <Button style={{display: checkRows.length>0 ? 'none' : 'initial'}} variant="outlined" className="select-all-check" size="small" onClick={(e)=>handleClickAll(true)}>
                                            <Typography variant="h6" className="regular-serif">
                                                Seleccionar todos los registros
                                            </Typography>
                                        </Button>
                                        <Button style={{display: checkRows.length>0 ? 'initial' : 'none'}} variant="outlined" className="select-any-check" size="small" onClick={(e)=>handleClickAll(false)}>
                                            <Typography variant="h6" className="regular-serif">
                                                No seleccionar ninguno
                                            </Typography>
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                            <Grid container item xs={12}>
                                <DataTableGraduate checkRows={checkRows} setCheckRows={setCheckRows} allPrograms={allPrograms} onChanhePageSize={onChanhePageSize} rows={dataFiltered} pageSize={rowsPerPage} onSelectionRows={(params: GridSelectionModelChangeParams)=>handleSelectionRows(params)} handleRowButton={handleRowButton} initPage={initPage} setInitPage={setInitPage} stateOptions={stateOptions} handleGraduate={handleGraduate}setCurrentPage={setCurrentPage} onSortedRows={(params: GridSortModelParams)=>handleSortedRows(params)}/>
                            </Grid>
                            {stateOptions.length>0 &&
                                <Grid container item xs={12}>
                                    <SelectActionsBox usersToChange={usersToChange} sendActionSelected={sendActionSelected} stateOptions={stateOptions} stateSelected={stateSelected} actionOptions={actionsForGraduates} actionSelected={actionSelected} onChangeAction={handleChangeAction} onChangeStateSelected={handleChangeStateSelected} />
                                </Grid>
                            }
                            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
                        </Grid>
                    }
                    </>
                :
                    <>
                    {containsAny(permissionAdminSeeInfoGraduates,userContext.userPermissions) ?
                        <GraduateInformation graduateInfo={graduateInfo} handleGraduate={handleGraduate}/>
                    :
                        <NotAuthorized/>
                    }
                    </>
                }
                <LoadingLockPage load={promiseInProgress}/>
                <LoadingLockPage load={initLoad}/>
                </>
            :
                <NotAuthorized/>
            }
            </>
        :
            <Redirect to ="/"/>
        }
        </>
    )
}

export default GraduateManagement;