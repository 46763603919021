import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Link } from "react-router-dom";
import "./ButtonBackListOffers.scss";
import Typography from '@material-ui/core/Typography'

interface ButtonBackListOfferProps{
  url: string;
  text: string;
}

const ButtonBackListOffers: React.FC<ButtonBackListOfferProps> = ({url, text}) => {
  return (
    <Grid container item xs={12} className="main-container">
      <Grid container item xs={12} className="back-offers-btn-container">
        <Button
          variant="contained"
          className="step-back-offers"
          startIcon={<NavigateBeforeIcon />}
          component={Link}
          to={url}
        >
          <Typography variant="h6" className="regular-serif">
            {text}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ButtonBackListOffers;
