import React, { useState, useEffect } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import moment from 'moment';
import 'moment/locale/es'
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import { DateRange } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import { StateByRole } from '../../../interfaces/StateByRole';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import MoreFiltersCompany from '../../../components/ManagementState/MoreFiltersCompany';
import Validations from '../../../helpers/Validations';
import ShowFilters from '../../../components/ManagementState/ShowFilters';
import { ChipData } from '../../../interfaces/ChipData';
import { checkValuesSelected, containsAny, resetStatusCheckArray, sortByKey } from '../../../helpers/sharedFunctions';
import AditionalData from '../AditionalData';
import AditionalOptions from '../AditionalOptions';
import LoadingLockPage from '../../../components/ShareComponents/LoadingLockPage';
import DownloadModal from '../DownloadModal';
import ErrorModal from '../ErrorModal';
import { ICompanyFilterForm } from '../../../interfaces/ICompanyFilterForm';
import { ICompanyFilterObjDinamic } from '../../../interfaces/ICompanyFilterObjDinamic';
import { dinamicReportCompanies } from '../../../services/ReportsService';
import { getCITY, getCOMPANY_TYPE, getCOUNTRIES_CITIES, getDEPARTAMENT, getLABORAL_SECTOR } from '../../../services/ParameterService';
import { tranformDataCompany } from '../../../pages/dashboard/admin/CompanyManagement';
import UserContext from '../../../utils/UserContext';
import NotAuthorized from '../../ShareComponents/NotAuthorized/NotAuthorized';
import { permissionAdminDinamicReportsCompanies } from '../../../utils/PermissionsVariables';

import './ReportFormCompany.scss';
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';

const ReportOption: Array<StateByRole> = [
    { id: 1, label: 'Reporte dinámico', value: 'Dinamic', description: '', permission: [] },
];

const stateOptionsCompany: Array<StateByRole> = [
    {id:1, label: 'Inscrito', value:'Inscrito', description: 'Cambiar estado a Inscrito', permission:[] },
    {id:2, label: 'Activo', value:'Activo', description: 'Cambiar estado a Activo', permission: [] },
    {id:3, label: 'Rechazado', value:'Rechazado', description: 'Cambiar estado a Rechazado', permission: [] },
    {id:4, label: 'Inactivo', value:'Inactivo', description: 'Cambiar estado a Inactivo', permission: [] }
];

const AditionalComponent: Array<StateByRole> = [
    { id: 1, label: 'Ninguno', value: 'None', description: '', permission: [] },
    { id: 2, label: 'Información de contacto', value: 'ContactInfo', description: '', permission: [] },
    { id: 3, label: 'Información de las sedes', value: 'CampusInfo', description: '', permission: [] },
];

const CompanyDataOption: Array<StateByRole> = [
    { id: 1, label: 'Nombre', value: 'name', description: '', permission: [] },
    { id: 2, label: 'NIT', value: 'nit', description: '', permission: [] },
    { id: 3, label: 'Digito de verificación NIT', value: 'verificationDigit', description: '', permission: [] },
    { id: 4, label: 'Dirección de la empresa', value: 'address', description: '', permission: [] },
    { id: 5, label: 'Teléfono', value: 'phone', description: '', permission: [] },
    { id: 6, label: 'Celular', value: 'cellphoneCompany', description: '', permission: [] },
    { id: 7, label: 'País', value: 'country', description: '', permission: [] },
    { id: 8, label: 'Departamento', value: 'department', description: '', permission: [] },
    { id: 9, label: 'Municipio', value: 'city', description: '', permission: [] },
    { id: 10, label: 'Dirección del sitio web', value: 'website', description: '', permission: [] },
    { id: 11, label: 'Sector laboral', value: 'laboralSector', description: '', permission: [] },
    { id: 12, label: 'Tipo de empresa', value: 'typeOfCompany', description: '', permission: [] },
    { id: 13, label: 'Estado de la empresa', value: 'state', description: '', permission: [] },
    { id: 14, label: 'Nombre contacto principal', value: 'contactName', description: '', permission: [] },
    { id: 15, label: 'Cargo del contacto principal', value: 'position', description: '', permission: [] },
    { id: 16, label: 'Correo del contacto principal', value: 'email', description: '', permission: [] },
    { id: 17, label: 'Telefono del contacto principal', value: 'phoneContact', description: '', permission: [] },
    { id: 18, label: 'Celular del contacto principal', value: 'cellphoneContact', description: '', permission: [] },
    { id: 19, label: 'Fecha de activacion en la bolsa de empleos', value: 'inJobBank', description: '', permission: [] }
];

const ContactInfoOption: Array<StateByRole> = [
    { id: 1, label: 'Nombre del contacto', value: 'name', description: '', permission: [] },
    { id: 2, label: 'Teléfono de contacto', value: 'phone', description: '', permission: [] },
    { id: 3, label: 'Correo electrónico', value: 'email', description: '', permission: [] },
];

const CampusInfoOption: Array<StateByRole> = [
    { id: 1, label: 'Nombre de la sede', value: 'name', description: '', permission: [] },
    { id: 2, label: 'Teléfono de la sede', value: 'phone', description: '', permission: [] },
    { id: 3, label: 'Dirección de la sede', value: 'address', description: '', permission: [] },
    { id: 4, label: 'País de la sede', value: 'country', description: '', permission: [] },
    { id: 5, label: 'Departamento de la sede', value: 'department', description: '', permission: [] },
    { id: 6, label: 'Municipio de la sede', value: 'city', description: '', permission: [] },
];

const filterCompanyValidation = {
    name: {
      error: "",
      validate: "textNumber",
      maxLength: 40,
      required: false
    },
    nit: {
        error: "",
        validate: "number",
        minLength: 7,
        maxLength: 15,
        required: false
    },
    laboralSector: {
        validate: "select",
        required: false
    },
    typeOfCompany: {
        validate: "select",
        required: false
    },
    country: {
        validate: "select",
        required: false
    },
    department: {
        validate: "select",
        required: false
    },
    city: {
        validate: "select",
        required: false
    },
    state:{
        validate: "select",
        required: false,
    },
    extraEmail:{
        error: "",
        validate: "email",
        required: true
    },
    subject:{
        error: "",
        validate: "textNumber",
        maxLength: 100,
        required: true
    },
    html:{
        error: "",
        validate: "textNumber",
        maxLength: 400,
        required: true
    }
}
const initialCompanyFilterErrors ={
    name: "",
    nit: "",
    laboralSector: "",
    typeOfCompany: "",
    country: "",
    department: "",
    city: "",
    state: "",
    createdAtStart: "",
    createdAtEnd: "",
    subject: "",
    html: "",
    extraEmail:"",
    inJobBankStart: "",
    inJobBankEnd: "",
}

const ReportFormCompany: React.FC = () => {
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [filterValues, setFilterValues] = useState<ICompanyFilterForm>(new ICompanyFilterForm());
    const [formErrors, setFormErrors] = useState(initialCompanyFilterErrors);
    const [reportSelected, setReportSelected] = useState<string>("Dinamic");
    const [recordsfound, setRecordsFound] = useState<number>(0);
    const [urlFileReportLog, setUrlFileReportLog] = useState<string>('');
    const [allLaboralSector, setAllLaboralSector] = useState<Array<IGeneralParameter>>([]);
    const [allCompanyType, setAllCompanyType] = useState<Array<IGeneralParameter>>([]);
    const [countryCitiesArray, setCountryCitiesArray] = useState<Array<IListParametersInfo>>([]);
    const [departmentArray, setDepartmentArray] = useState<Array<IGeneralParameter>>([]);
    const [cityArray, setCityArray] = useState<Array<IGeneralParameter>>([]);
    const [cities, setCities] = useState<Array<IGeneralParameter>>([]);
    const [chipData, setChipData] = useState<ChipData[]>([]);
    const [aditionalComponent, setAditionalComponent] = useState<string>("None");
    const [openModalDownload, setOpenModalDownload] = useState<boolean>(false);
    const [openModalError, setOpenModalError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage]= useState('');
    const [mainFieldsCheck, setMainFieldsCheck] = useState<any>({
        name: false,
        nit: false,
        verificationDigit: false,
        address: false,
        phone: false,
        cellphoneCompany: false,
        country: false,
        department: false,
        city: false,
        website: false,
        laboralSector: false,
        typeOfCompany: false,
        state: false,
        contactName: false,
        position: false,
        email: false,
        phoneContact: false,
        cellphoneContact: false,
        inJobBank: false
    });

    const [contactInfoFields, setContactInfoFields] = useState<any>({
        name: false,
        phone: false,
        email: false,
    });

    const [campusInfoFields, setCampusInfoFields] = useState<any>({
        name: false,
        phone: false,
        address: false,
        country: false,
        department: false,
        city: false,
    });

    const isValid =
        !formErrors.name &&
        !formErrors.nit &&
        !formErrors.createdAtStart &&
        !formErrors.createdAtEnd &&
        !formErrors.inJobBankStart &&
        !formErrors.inJobBankEnd

    useEffect(() => {
        window.scrollTo(0, 0);
        const getInitialData = async () => {
            //  company type
            let responseCompanyType: Array<IGeneralParameter> = await getCOMPANY_TYPE();
            responseCompanyType = sortByKey(responseCompanyType, 'name');
            setAllCompanyType(responseCompanyType);
            //  laboral sector
            let responseLaboralSector: Array<IGeneralParameter> = await getLABORAL_SECTOR();
            responseLaboralSector = sortByKey(responseLaboralSector, 'name');
            setAllLaboralSector(responseLaboralSector);
            // data countries-cities
            let dataCountryCities:Array<IListParametersInfo> = (await getCOUNTRIES_CITIES()).list;
            dataCountryCities = sortByKey(dataCountryCities, 'grandParentName');
            setCountryCitiesArray(dataCountryCities);
            //data for departments field
            let dataDepartment: Array<IGeneralParameter> = await getDEPARTAMENT();
            dataDepartment = sortByKey(dataDepartment, 'name');
            setDepartmentArray(dataDepartment ? dataDepartment : []);
            //data for cities
            const dataCity: Array<IGeneralParameter> = await getCITY();
            setCityArray(dataCity ? dataCity : []);
            setInitLoad(false);
        }
        getInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if(filterValues.country === 0){
            setFilterValues(prev => ({
                ...prev,
                department:0,
                city:0
            }));
            setCities([]);
        }else {
            if(filterValues.country === 170){
                setCities([]);
            } else {
                setFilterValues(prev => ({
                    ...prev,
                    department:0,
                    city:0
                }));
                const countryObjSelected = countryCitiesArray.find(country=>country.id === filterValues.country);
                if(countryObjSelected){
                    setCities(countryObjSelected.childs);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filterValues.country])

    useEffect(() => {
        setFilterValues(prev => ({
            ...prev,
            city: 0
        }))
        if (filterValues.department > 0) {
            let citiesDep: Array<IGeneralParameter> = cityArray.filter(city => city.parentId === filterValues.department);
            citiesDep = sortByKey(citiesDep, 'name');
            if (citiesDep.length > 0) {
                setCities(citiesDep)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues.department])

    const setChips =()=>{
        let tempArray: Array<ChipData> = []
        let index = 0;
        let subIndex = 0;
        const fieldNames=[
            {key: 'name', description: 'Razón social'},
            {key: 'nit', description: 'Nit de la empresa'},
            {key: 'laboralSector', description: 'Sector laboral'},
            {key: 'typeOfCompany', description: 'Tipo de empresa'},
            {key: 'state', description: 'Estado'},
            {key: 'country', description: 'País'},
            {key: 'department', description: 'Departamento'},
            {key: 'city', description: 'Municipio'},
            {key: 'rangeRegister', description: 'Fecha de inscripción'},
            {key: 'inJobBankDate',description: 'Fecha de activacion en la bolsa'}
        ]
        for (const [key, value] of Object.entries(filterValues)) {
            if(value!==0 && value!=='' && value!==null && value.length!==0){
                if(value[0]!==null && value[1]!==null ){
                    let obj;
                    const fieldObj = fieldNames.find(field=>field.key === key);
                    if(key === 'laboralSector'){
                        obj = allLaboralSector.find(sector=>sector.parameter_code === value);
                    } else if (key === 'typeOfCompany'){
                        obj = allCompanyType.find(Tcompany=>Tcompany.parameter_code === value );
                    } else if (key === 'country' && value === 170){
                        obj = {name:'Colombia'}
                    } else if (key === 'department'){
                        obj = departmentArray.find(dep=>dep.parameter_code === value);
                    } else if (key === 'city'){
                        obj = cities.find(city=>city.parameter_code === value);
                    } else if (key === 'state'){
                        subIndex = index;
                        // eslint-disable-next-line no-loop-func
                        (value as StateByRole[]).forEach(stateItem=>{
                            tempArray.push({key: subIndex, name:stateItem.label + ' state', label: fieldObj?.description + ': ' + stateItem.label });
                            subIndex++;
                        });
                    } else if (key === 'rangeRegister'){
                        const startDate: string = moment(value[0]).format('DD/MM/YYYY');
                        const endDate: string = moment(value[1]).format('DD/MM/YYYY');
                        obj = {name: `${startDate} hasta ${endDate}`}
                    } else if (key === 'inJobBankDate'){
                        const startDate: string = moment(value[0]).format('DD/MM/YYYY');
                        const endDate: string = moment(value[1]).format('DD/MM/YYYY');
                        obj = {name: `${startDate} hasta ${endDate}`}
                    }

                    if(subIndex !==0){
                        index = subIndex
                        subIndex = 0;
                    }
                    if(obj){
                        tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + obj.name })
                    } else {
                        if(key !== 'state'){
                            tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + value})
                        }
                    }
                    index++;
                }
            }
        }
        setChipData(tempArray)
    };

    const handleDeleteChip = (chipToDelete: ChipData) => () => {
        const name =chipToDelete.name;
        setChipData((chips) => chips.filter((chip) => chip.name !== chipToDelete.name));
        if(name === 'laboralSector' || name === 'typeOfCompany' || name === 'country' || name === 'department' || name === 'city'){
            setFilterValues(prev => ({
                ...prev,
                [chipToDelete.name]: 0
            }));
        } else if(name === 'rangeRegister' || name === "inJobBankDate"){
            setFilterValues(prev => ({
                ...prev,
                [name]: [null,null]
            }));
        } else if(name.includes('state')){
            const newState = filterValues.state.filter(item=>item.label !== chipToDelete.label.split(': ')[1]);
            setFilterValues(prev => ({
              ...prev,
              state: newState
            }));
        } else {
            setFilterValues(prev => ({
                ...prev,
                [chipToDelete.name]: ''
            }));
        }
    };

    useEffect(() => {
        if (isValid) {
            setChips();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, isValid])

    const transformCompanyFields = (prevObjectToSend: ICompanyFilterObjDinamic) => {
        if(prevObjectToSend.company){
            const mainFieldsSelected = checkValuesSelected(mainFieldsCheck);
            prevObjectToSend.company.fields = mainFieldsSelected || [];
        }
        if(prevObjectToSend.contact){
            const contactFieldsSelected = checkValuesSelected(contactInfoFields);
            prevObjectToSend.contact.fields = contactFieldsSelected || [];
        }
        if(prevObjectToSend.headquarter){
            const headquarterFieldsSelected = checkValuesSelected(campusInfoFields);
            prevObjectToSend.headquarter.fields = headquarterFieldsSelected || [];
        }
        delete prevObjectToSend.limit;
        delete prevObjectToSend.offset;
        return prevObjectToSend;
    }

    const clickApplyFilters = async(value: any, action: string) => {
        if (action === 'apply') {
            const objectToSend = tranformDataCompany(filterValues, true);
            const responseDinamicReportCompanies = await trackPromise(dinamicReportCompanies(
                objectToSend
            ))
            if(responseDinamicReportCompanies.status === 200){
                const result = await responseDinamicReportCompanies.json();
                setRecordsFound(result.quantity || 0 );
                setChips();
            } else {
                setOpenModalError(true);
                setErrorMessage("Hubo un error, intente de nuevo mas tarde, o contacte al administrador general");
            }
        } else if (action === 'clear') {
            setChipData([]);
            setFilterValues(new ICompanyFilterForm());
            setFormErrors(initialCompanyFilterErrors);
            setRecordsFound(0);
        }
    }

    const changeFilterForm = (e: any) => {
        const { name, value } = e.target;
        setFilterValues(prev => ({
            ...prev,
            [name]: value
        }));
        const error: string = Validations(name, value, filterCompanyValidation) || "";
        setFormErrors(prev => ({
            ...prev,
            [name]: error
        }));
    }

    const changeFilterDates = (name: string, dateValue: DateRange<Date>)=>{
        setFilterValues(prev => ({
            ...prev,
            [name]: dateValue
        }));
        const startDate: string = moment(dateValue[0]).format('YYYY/MM/DD');
        const endDate: string = moment(dateValue[1]).format('YYYY/MM/DD');

        let labelStart: string = "";
        let labelEnd: string = "";
        const valueStart = startDate === "Fecha inválida" ? "Fecha inválida" : "";
        const valueEnd = endDate === "Fecha inválida" ? "Fecha inválida" : "";
        if(name === "rangeRegister"){
            labelStart = "createdAtStart";
            labelEnd = "createdAtEnd";
        }
        if(name === "inJobBankDate"){
            labelStart = "inJobBankStart";
            labelEnd = "inJobBankEnd";
        }
        errorDates(labelStart, valueStart);
        errorDates(labelEnd, valueEnd);
    }

    const errorDates = (label: string, value: string) => {
        setFormErrors(prev => ({
            ...prev,
            [label]: value
        }));
    }

    const changeReportType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReportSelected((event.target as HTMLInputElement).value);
    };

    const changeAdditionalComponent = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAditionalComponent((event.target as HTMLInputElement).value);
        if((event.target as HTMLInputElement).value === "None"){
            const newContactInfo = resetStatusCheckArray(contactInfoFields);
            setContactInfoFields(newContactInfo);
            const newCampusInfo = resetStatusCheckArray(campusInfoFields);
            setCampusInfoFields(newCampusInfo);
        } else if ((event.target as HTMLInputElement).value === "ContactInfo"){
            const newCampusInfo = resetStatusCheckArray(campusInfoFields);
            setCampusInfoFields(newCampusInfo);
        } else if((event.target as HTMLInputElement).value === "CampusInfo"){
            const newContactInfo = resetStatusCheckArray(contactInfoFields);
            setContactInfoFields(newContactInfo);
        }
    };

    const changeMainReportFields = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMainFieldsCheck({ ...mainFieldsCheck, [event.target.name]: event.target.checked });
    };

    const changeContactInfoFields = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContactInfoFields({ ...contactInfoFields, [event.target.name]: event.target.checked });
    };

    const changeCampusInfoFields = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCampusInfoFields({ ...campusInfoFields, [event.target.name]: event.target.checked });
    };

    const updateDataStaticReport = async(responseStatic: Response, showModal: boolean) =>{
        if (responseStatic.status === 200 ) {
            const resultFile = await responseStatic.json();
            if(showModal){
                setUrlFileReportLog(reportSelected === "Dinamic" ? resultFile.urlFile : resultFile.url);
                setRecordsFound(parseInt(resultFile.quantity));
                setOpenModalDownload(true);
            } else{
                setRecordsFound(resultFile.quantity);
            }
        } else {
            setOpenModalError(true);
            setErrorMessage("Hubo un error, intente de nuevo mas tarde, o contacte al administrador general");
        }
    }

    const generateReport = async () => {
        if(reportSelected === "Dinamic"){
            const prevObjectToSend = tranformDataCompany(filterValues, false);
            const objectToSend = transformCompanyFields(prevObjectToSend);
            if((objectToSend.company && objectToSend.company.fields && objectToSend.company.fields.length>0) && aditionalComponent === "None"){
                delete objectToSend.contact;
                delete objectToSend.headquarter;
                const responseDinamicReportCompany = await trackPromise(dinamicReportCompanies(
                    objectToSend
                ))
                updateDataStaticReport(responseDinamicReportCompany, true);
            }
            else if((objectToSend.company && objectToSend.company.fields && objectToSend.company.fields.length>0) && ((objectToSend.contact && objectToSend.contact.fields && objectToSend.contact.fields.length>0))){
                delete objectToSend.headquarter;
                const responseDinamicReportCompany = await trackPromise(dinamicReportCompanies(
                    objectToSend
                ))
                updateDataStaticReport(responseDinamicReportCompany, true);
            } 
            else if((objectToSend.company && objectToSend.company.fields && objectToSend.company.fields.length>0) && (objectToSend.headquarter && objectToSend.headquarter.fields && objectToSend.headquarter.fields.length>0)){
                delete objectToSend.contact;
                const responseDinamicReportCompany = await trackPromise(dinamicReportCompanies(
                    objectToSend
                ))
                updateDataStaticReport(responseDinamicReportCompany, true);
            } 
            else {
                setOpenModalError(true);
                setErrorMessage("Por favor seleccione algun campo para el reporte");
            }
        }
    }

    const handleCloseModalDownload = () => {
        setOpenModalDownload(false);
        window.location.reload();
    };

    const handleCloseModalError = () => {
        setOpenModalError(false);
        // window.location.reload();
    };

    return (
        <Grid container className="main-container">
            <Grid container item xs={12} className="company-report-container">
                <Grid container item xs={12} className="report-container">
                    <Grid container item xs={12} className="title-container-report">
                        <Typography variant="h2" className="title">
                            Seleccione el tipo de reporte
                        </Typography>
                    </Grid>
                    <Grid container className="report-content">
                        <Grid container item xs={12} md={2}>
                            <Typography variant="h4" className="report-state-text">
                                Tipo de reporte:
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} md={10} className="report-state-options">
                            <FormControl component="fieldset">
                                <RadioGroup row className="radio-container" aria-label="state" name="type" value={reportSelected} onChange={changeReportType}>
                                    {ReportOption.map(option => (
                                        <FormControlLabel key={option.id} value={option.value} className="radio-btn" control={<Radio className="color-btn" />} label={option.label} labelPlacement="start" />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {reportSelected === "Dinamic" ?
                <>
                {containsAny(permissionAdminDinamicReportsCompanies, userContext.userPermissions) ?
                    <>
                        <Grid container item xs={12}>
                            <MoreFiltersCompany titleSection="Agregar filtros de búsqueda" formValues={filterValues} formErrors={formErrors} onChangeForm={changeFilterForm} changeFilterDates={changeFilterDates} allLaboralSector={allLaboralSector} allCompanyType={allCompanyType} countryCitiesArray={countryCitiesArray} departmentArray={departmentArray} cities={cities} reportForm={true} stateOptions={stateOptionsCompany} />
                        </Grid>
                        <Grid container item xs={12}>
                            <ShowFilters titleSection="Filtros seleccionados" filtersData={chipData} showButtons={true} showDelete={true} deleteFilter={handleDeleteChip} isValid={isValid} onApplyFilters={clickApplyFilters} />
                        </Grid>
                    </>
                :
                    <NotAuthorized/>
                }
                </>
            :
                null
            }
            {containsAny(permissionAdminDinamicReportsCompanies, userContext.userPermissions) &&
                <Grid container item xs={12} className="information-result-report-section">
                    <Typography variant="h4" className="bold-serif">Total de empresas encontradas: </Typography><span className="total-results-section">{recordsfound} resultados</span>
                </Grid>
            }
            {reportSelected === "Dinamic" && containsAny(permissionAdminDinamicReportsCompanies, userContext.userPermissions) &&
                <>
                    <Grid container className="description-container">
                        <Typography variant="h4" className="description-title">
                            1. Por favor seleccione los datos que desea se listen en el reporte.
                        </Typography>
                    </Grid>

                    <Grid container item xs={12}>
                        <AditionalData title="Campos principales del reporte" stateCheck={mainFieldsCheck} onChangeState={changeMainReportFields} stateOptions={CompanyDataOption} />
                    </Grid>

                    <Grid container className="description-container">
                        <Typography variant="h4" className="description-title">
                            2. Por favor seleccione los componentes adicionales que desea se listen en el reporte.
                        </Typography>
                    </Grid>

                    <Grid container item xs={12} className="graduate-report-container2" >
                        <Grid container item xs={12} className="report-container2">
                            <Grid container item xs={12} className="title-container-report2">
                                <Typography variant="h2" className="title">
                                    Componentes adiciones
                                </Typography>
                            </Grid>
                            <Grid container className="report-content2">

                                <Grid container item xs={12} md={10} className="report-state-options2" >
                                    <FormControl component="fieldset">
                                        <RadioGroup row className="radio-container" aria-label="aditionalInfo" name="aditionalInfo" value={aditionalComponent} onChange={changeAdditionalComponent}>
                                            {AditionalComponent.map(option => (
                                                <FormControlLabel key={option.id} value={option.value} className="radio-btn" control={<Radio className="color-btn" />} label={option.label} labelPlacement="start" />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={12} className={aditionalComponent === "" ? "no-container" : "line"} />
                                {aditionalComponent !== "None" &&
                                    <>
                                    {aditionalComponent === "ContactInfo" &&
                                        <Grid container >
                                            <AditionalOptions stateCheck={contactInfoFields} onChangeState={changeContactInfoFields} stateOptions={ContactInfoOption} />
                                        </Grid>
                                    }
                                    {aditionalComponent === "CampusInfo" &&
                                        <Grid container >
                                            <AditionalOptions stateCheck={campusInfoFields} onChangeState={changeCampusInfoFields} stateOptions={CampusInfoOption} />
                                        </Grid>
                                    }
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
            {containsAny(permissionAdminDinamicReportsCompanies, userContext.userPermissions) &&
                <Grid container item xs={12} className="button-download-report-container">
                    <Button disabled={recordsfound>0 ? false : true} className="generate-report-btn" variant="contained" size="small" onClick={generateReport}>
                        <Typography variant="h5" className="regular-serif">Generar reporte</Typography>
                    </Button>
                </Grid>
            }
            <LoadingLockPage load={promiseInProgress} />
            <LoadingLockPage load={initLoad} />
            <ErrorModal errorMsg={errorMessage}  handleCloseModal={handleCloseModalError} openModalError={openModalError} />
            <DownloadModal downloadMsg="El reporte se ha generado con éxito" downloadMsg2="¿Desea descargarlo?" handleCloseModal={handleCloseModalDownload} openModalDownload={openModalDownload} urlReport={urlFileReportLog} total={recordsfound.toString()} />
        </Grid>
    )
}

export default ReportFormCompany;