export class ILaboralInfoCV{
    doc: string;
    laboralInformation: ILaboralInfo;
    laboralExperiences: Array<IExperience>;
    constructor(def:any){
        this.doc = def.doc || "";
        this.laboralInformation = def.laboralInformation || new ILaboralInfo();
        this.laboralExperiences = def.laboralExperiences || [];
    }
}
export class leaveReasons {
    reason: string = "";
    description: string = "";
}
export class ILaboralInfo{
    currentlyWorking: boolean = false;
    laboralProfile: string = "";
    competences: string = "";
    salaryAspiration: number = 0;
    inJobBank: Date | null = null;
    reasons: leaveReasons | null = null;
    keyword: string = "";
    tempKeywords: Array<any> = [] 
    keywords: string= "";
}

export class IExperience{
    id?:number | null = null;
    position: string = "";
    ocupation: number = 0;
    employmentRelationship: number = 0;
    performanceArea: number = 0;
    achivements: string = "";
    employmentAndProfessionRelationship: number = 0;
    salaryRange: string = "";
    startDateFormat?: Date | null = null;
    startDate: string = "";
    endDateFormat?: Date | null = null;
    endDate: string = "";
    actualWork: boolean = false;
    companyName: string = "";
    phoneNumber: number | null = null;
    country: number = 0;
    departament: number = 0;
    city: number = 0;
    bossName: string = "";
    bossPositon: string = "";
    companyType: number = 0;
    bossPhone: number | null = null;
    visible: boolean = false;
}