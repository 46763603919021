import React from 'react';
import Typography from '@material-ui/core/Typography';
import './LoadingArea.scss';

interface LoadingAreaProps{
    load:boolean;
    area?: any;
    loadElement: any;
    awaitingText?: string;
    textSize?: string;
    textColor?: string;
}

const LoadingArea: React.FC<LoadingAreaProps> = ({load, area, loadElement, awaitingText, textSize, textColor}) => {

    return (
        <div className="load-area-container" style={{display: !load ? 'none' : 'block'}}>
            <div className="load-area-icon">
                {loadElement}
            </div>
            {
                awaitingText !== null &&
                <Typography className="loading-area-text" style={{fontSize:textSize? textSize : '14px', color:textColor ? textColor : 'black'}}>{awaitingText}</Typography>
            }
        </div>
    )
};

export default LoadingArea;