import React, { useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IRegisterCompany } from '../../../interfaces/IRegisterCompany';
import RegisterContext from '../../../utils/RegisterContext';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';

const CompanyForm: React.FC = ()=>{
    const registerContext = React.useContext(RegisterContext);
    const formValues: IRegisterCompany = registerContext.formValues;
    const formErrors: IRegisterCompany = registerContext.formErrors;
    const isValidFirstStep =
        registerContext.firstAccorDone &&
        registerContext.secondAccorDone
    // Check if values are not empty or if there are some error
    const isValid =
        formValues.name.length > 0 &&
        !formErrors.name &&
        formValues.nit.length > 0 &&
        !formErrors.nit &&
        formValues.verificationDigit.length > 0 &&
        !formErrors.verificationDigit &&
        formValues.typeOfCompany > 0 &&
        formValues.typeOfPerson > 0 &&
        formValues.laboralSector > 0 &&
        formValues.country > 0 &&
        formValues.department > 0 &&
        formValues.city > 0 &&
        formValues.address.length > 0 &&
        !formErrors.address &&
        formValues.cellphoneCompany.length > 0 &&
        !formErrors.cellphoneCompany &&
        !formErrors.phone &&
        !formErrors.website

    useEffect(()=>{
        registerContext.handleValidForm('panel1', isValid);
    })


    return(
        <>
            <Grid container spacing={2}>
                <Grid container item xs={12} direction="row">
                    <Grid item xs={12} className="field-box-margin special-box-right">
                        <Typography variant="h6" className="field-box-title-company">Razón social*</Typography>
                        <TextField
                            fullWidth
                            name="name"
                            margin="none"
                            label={formValues.name === "" ? "Ingrese su razón social": ""}
                            value={formValues.name || ""}
                            onChange={registerContext.handleChange}
                            error={!!formErrors.name}
                            helperText={formErrors.name}
                            InputLabelProps={{shrink: false}}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} direction="row">
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                        <Typography variant="h6" className="field-box-title-company">NIT*</Typography>
                        <TextField
                            fullWidth
                            name="nit"
                            margin="none"
                            label={formValues.nit === "" ? "Ingrese su número de NIT": ""}
                            value={formValues.nit === "" ? "" : formValues.nit}
                            onChange={registerContext.handleChange}
                            error={!!formErrors.nit}
                            helperText={formErrors.nit}
                            InputLabelProps={{shrink: false}}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} className="field-box-margin special-box-left">
                        <Typography variant="h6" className="field-box-title-company">Dígito de verificación*</Typography>
                        <TextField
                            className="digit_verification-box"
                            name="verificationDigit"
                            margin="none"
                            value={formValues.verificationDigit || ""}
                            onChange={registerContext.handleChange}
                            error={!!formErrors.verificationDigit}
                            helperText={formErrors.verificationDigit}
                            InputLabelProps={{shrink: false}}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} direction="row">
                    <Grid item xs={12} className="field-box-margin special-box-right">
                        <Typography variant="h6" className="field-box-title-company">Tipo de Persona*</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-typeOfPerson"
                            select
                            name="typeOfPerson"
                            label={formValues.typeOfPerson === 0 ? "Seleccione": ""}
                            value={formValues.typeOfPerson === 0 ? "" : formValues.typeOfPerson}
                            onChange={registerContext.handleChange}
                            InputLabelProps={{shrink: false}}
                            margin="none"
                            variant="outlined"
                        >
                            {
                                registerContext.allTypePerson && registerContext.allTypePerson.length>0 ?
                                registerContext.allTypePerson.map((option: IGeneralParameter) => (
                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                        {option.name}
                                    </MenuItem>
                                ))
                            :
                                <MenuItem value={formValues.typeOfPerson}></MenuItem>
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <hr className="divider-accordion"/>
                </Grid>
                <Grid container item xs={12} direction="row">
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                        <Typography variant="h6" className="field-box-title-company">Tipo de empresa*</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-typeOfCompany"
                            select
                            name="typeOfCompany"
                            label={formValues.typeOfCompany === 0 ? "Seleccione": ""}
                            value={formValues.typeOfCompany === 0 ? "" : formValues.typeOfCompany}
                            onChange={registerContext.handleChange}
                            InputLabelProps={{shrink: false}}
                            margin="none"
                            variant="outlined"
                        >
                            {
                                registerContext.allCompanyType && registerContext.allCompanyType.length>0 ?
                                registerContext.allCompanyType.map((option: IGeneralParameter) => (
                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                        {option.name}
                                    </MenuItem>
                                ))
                            :
                                <MenuItem value={formValues.typeOfCompany}></MenuItem>
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                        <Typography variant="h6" className="field-box-title-company">Sector laboral*</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-laboralSector"
                            select
                            name="laboralSector"
                            label={formValues.laboralSector === 0 ? "Seleccione": ""}
                            value={formValues.laboralSector === 0 ? "" : formValues.laboralSector}
                            onChange={registerContext.handleChange}
                            InputLabelProps={{shrink: false}}
                            margin="none"
                            variant="outlined"
                        >
                            {
                                registerContext.allLaboralSector && registerContext.allLaboralSector.length>0 ?
                                registerContext.allLaboralSector.map((option: IGeneralParameter) => (
                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                        {option.name}
                                    </MenuItem>
                                ))
                            :
                                <MenuItem value={formValues.laboralSector}></MenuItem>
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container item xs={12} direction="row">
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                        <Typography variant="h6" className="field-box-title-company">País*</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-country"
                            select
                            name="country"
                            label={formValues.country === 0 ? "Seleccione": ""}
                            value={formValues.country === 0 ? "" : formValues.country}
                            onChange={registerContext.handleChange}
                            InputLabelProps={{shrink: false}}
                            margin="none"
                            variant="outlined"
                            disabled
                        >
                            <MenuItem value={170}>Colombia</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                        <Typography variant="h6" className="field-box-title-company">Departamento*</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-department"
                            select
                            name="department"
                            label={formValues.department === 0 ? "Seleccione": ""}
                            value={formValues.department === 0 ? "" : formValues.department}
                            onChange={registerContext.handleChange}
                            InputLabelProps={{shrink: false}}
                            margin="none"
                            variant="outlined"
                            disabled={registerContext.departmentArray && registerContext.departmentArray.length>0 ? false : true}
                        >
                            {
                                registerContext.departmentArray && registerContext.departmentArray.length>0 ?
                                registerContext.departmentArray.map((option: IGeneralParameter) => (
                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                        {option.name}
                                    </MenuItem>
                                ))
                            :
                                <MenuItem value={formValues.department}></MenuItem>
                            }
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container item xs={12} direction="row">
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                        <Typography variant="h6" className="field-box-title-company">Municipio*</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-city"
                            select
                            name="city"
                            label={formValues.city === 0 ? "Seleccione": ""}
                            value={formValues.city === 0 ? "" : formValues.city}
                            onChange={registerContext.handleChange}
                            InputLabelProps={{shrink: false}}
                            margin="none"
                            variant="outlined"
                            disabled={registerContext.cities && registerContext.cities.length>0 ? false : true}
                        >
                            {
                                registerContext.cities && registerContext.cities.length>0 ?
                                registerContext.cities.map((option: IGeneralParameter) => (
                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                        {option.name}
                                    </MenuItem>
                                ))
                            :
                                <MenuItem value={formValues.city}></MenuItem>
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                        <Typography variant="h6" className="field-box-title-company">Dirección*</Typography>
                        <TextField
                            fullWidth
                            name="address"
                            margin="none"
                            label={formValues.address === "" ? "Ingrese dirección empresa": ""}
                            value={formValues.address || ""}
                            onChange={registerContext.handleChange}
                            error={!!formErrors.address}
                            helperText={formErrors.address}
                            InputLabelProps={{shrink: false}}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} direction="row">
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                        <Typography variant="h6" className="field-box-title-company">Número de Celular*</Typography>
                        <TextField
                            fullWidth
                            name="cellphoneCompany"
                            margin="none"
                            label={formValues.cellphoneCompany === "" ? "Ingrese celular empresa": ""}
                            value={formValues.cellphoneCompany || ""}
                            onChange={registerContext.handleChange}
                            error={!!formErrors.cellphoneCompany}
                            helperText={formErrors.cellphoneCompany}
                            InputLabelProps={{shrink: false}}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                        <Typography variant="h6" className="field-box-title-company">Número de teléfono</Typography>
                        <TextField
                            fullWidth
                            name="phone"
                            margin="none"
                            label={formValues.phone === "" ? "Ingrese teléfono empresa": ""}
                            value={formValues.phone || ""}
                            onChange={registerContext.handleChange}
                            error={!!formErrors.phone}
                            helperText={formErrors.phone}
                            InputLabelProps={{shrink: false}}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} direction="row">
                    <Grid item xs={12} className="field-box-margin special-box-right">
                        <Typography variant="h6" className="field-box-title-company">Sitio web de la empresa</Typography>
                        <TextField
                            fullWidth
                            name="website"
                            margin="none"
                            label={formValues.website === "" ? "Ingrese dirección sitio web de la empresa": ""}
                            value={formValues.website || ""}
                            onChange={registerContext.handleChange}
                            error={!!formErrors.website}
                            helperText={formErrors.website}
                            InputLabelProps={{shrink: false}}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>

            </Grid>
            <div style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                className="accordion-btn"
                endIcon={<ExpandMoreIcon/>}
                disabled={!isValid}
                onClick={isValid ? (isValidFirstStep ? registerContext.handleChangeAccordion('panel2',false, 'panel1') :registerContext.handleChangeAccordion('panel2',true, 'panel1')) : null}
              >
                Siguiente
              </Button>
            </div>
        </>
    )
}

export default CompanyForm;