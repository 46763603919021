import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ButtonColors from "../ShareComponents/ButtonColors";
import { Link } from "react-router-dom";
import SecurityIcon from "@material-ui/icons/Security";
import WarningIcon from '@material-ui/icons/Warning';
import "./RecoverPasswordBox.scss";


interface ShowProps{
  value: string;
}

const RecoverPasswordBox: React.FC<ShowProps> = ({value}) => {
  return (
      <Grid container item xs={12} className="recover-msg-container">
        <Grid item xs={12}>
          <SecurityIcon className="security-icon"/>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1" className="recover-title">
            RECUPERACIÓN DE CONTRASEÑA
          </Typography>
        </Grid>
        <Grid item xs={12} className="buttons-Colors">
          <ButtonColors />
        </Grid>

        <Grid container item xs={12} className="box-recover-container">
          <Grid item>
            <Typography variant="h4" className="box-text"> Recuperación de contraseña para empresas</Typography>
          </Grid>
          <Grid container item className="description-recover-container">
            <WarningIcon className="warning-recovery-icon"/>
            <Typography variant="h5" className="regular-serif">
              Se ha enviado una contraseña temporal al siguiente correo electrónico
            </Typography>
          </Grid>
          <Grid container item className="email-response-text">
            <Typography align="center" variant="h4" className="bold-serif">{value}</Typography>
          </Grid>
          <Grid container item xs={12} className="submit-button">
            <Button variant="contained" className="sub-btn" component={ Link } to="/login">
              <Typography variant="h6" className="regular-serif">Aceptar</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
  );
};

export default RecoverPasswordBox;