import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import '../../GraduateProfile.scss';

interface AdditionalInfoCardProps{
    firstTitle?: string;
    firtsValue?: string;
    secondTitle?: string;
    secondValue?: string;
    subsecondValue?: string;
    thirdTitle?: string;
    thirdValue?: string;
    titleDescription?: string;
    descriptionText?: string;
    fourthTitle?: string;
    fourthValue?: string;
    fifthTitle?: string;
    fifthValue?: string;
    sixthTitle?: string;
    sixthValue?: string;
    italicFirstValue?: string;
    italicSecondValue?: string;
    italicThirdValue?: string;
}


const AdditionalInfoCard:React.FC<AdditionalInfoCardProps> = ({firstTitle,firtsValue,secondTitle,secondValue, subsecondValue,titleDescription,descriptionText,thirdTitle,thirdValue,fourthTitle,fourthValue,fifthTitle,fifthValue,sixthTitle,sixthValue, italicFirstValue,italicSecondValue,italicThirdValue}) => {
    return (
        <>
            <Grid container item xs={12} direction="row" className="additional-info-card-container">
                <Grid item xs={12} className="additional-info-card-body-content">
                    <Grid item xs={12} className="title-section-text">
                        <Typography variant="h5" className="bold-serif">
                            {firstTitle}: <span>{firtsValue}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="title-section-text">
                        <Typography variant="h5" className="bold-serif">
                            <span>{secondTitle}</span>:
                            {
                                secondValue &&
                                <>
                                <span>{secondValue}</span> - 
                                </>
                            }
                            {
                                subsecondValue &&
                                <span> {subsecondValue}</span>
                            }
                        </Typography>
                    </Grid>
                        {
                            thirdTitle &&
                            <Grid item xs={12} className="title-section-text">
                                <Typography variant="h5" className="bold-serif">
                                    <span>{thirdTitle}</span>: <span>{thirdValue}</span>
                                </Typography>
                            </Grid>
                        }
                        {
                            titleDescription &&
                            <>
                                <Grid item xs={12} className="title-section-text">
                                    <Typography variant="h5" className="bold-serif">
                                        {titleDescription}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className="title-section-text">
                                    <Typography variant="h5" className="light-serif description-text">
                                        {descriptionText}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {   
                            fourthTitle &&
                            <Grid item xs={12} className="title-section-text">
                                <Typography variant="h5" className="bold-serif">
                                    <span>{fourthTitle}</span>: <span>{fourthValue}</span>
                                </Typography>
                            </Grid>
                        }
                        {   
                            fifthTitle &&
                            <Grid item xs={12} className="title-section-text">
                                <Typography variant="h5" className="bold-serif">
                                    <span>{fifthTitle}</span>: <span>{fifthValue}</span>
                                </Typography>
                            </Grid>
                        }
                        {   
                            sixthTitle &&
                            <Grid item xs={12} className="title-section-text">
                                <Typography variant="h5" className="bold-serif">
                                    <span>{sixthTitle}</span>: <span>{sixthValue}</span>
                                </Typography>
                            </Grid>
                        }
                        {
                        italicFirstValue &&
                        <Grid item xs={12} className="title-section-text">
                            <Typography variant="h5" className="location-text">
                                <span className="summary-description-bold">{italicFirstValue}</span> - <span className="summary-description-bold">{italicSecondValue}</span> - <span className="summary-description-bold">{italicThirdValue}</span>
                            </Typography>
                        </Grid>
                        }
                </Grid>
            </Grid>  
        </>
    )
}

export default AdditionalInfoCard
