import React, { useState, useEffect } from 'react';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import BeatLoader from 'react-spinners/BeatLoader';
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { AddHeadquarterCompany, UpdateHeadquarterCompany } from '../../services/CompanyService';
import { IGeneralParameter } from '../../interfaces/IGeneralParameter';
import Validations from '../../helpers/Validations';
import { getDEPARTAMENT, getCITY, getCOUNTRIES_CITIES } from '../../services/ParameterService';
import UserContext from '../../utils/UserContext';
import { ICompanyHeadquarters } from '../../interfaces/ICompanyHeadquarters';
import LoadingArea from '../ShareComponents/LoadingArea';
import SuccessModal from '../ShareComponents/SuccessModal';
import ErrorModal from '../ShareComponents/ErrorModal';
import { sortByKey } from '../../helpers/sharedFunctions';
import './HeadquarterModal.scss';
import { IListParametersInfo } from '../../interfaces/IListParametersInfo';


const formValidations = {
  name: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: true
  },
  description: {
    error: "",
    validate: "textNumber",
    minLength: 10,
    required: true
  },
  country: {
    validate: "select",
    required: true
  },

  department: {
    validate: "select",
    required: true
  },

  city: {
    validate: "select",
    required: true
  },

  address: {
    error: "",
    validate: "textNumber",
    minLength: 5,
    maxLength: 40,
    required: true
  },
  phone: {
    error: "",
    validate: "textNumber",
    minLength: 7,
    maxLength: 30,
    required: true
  },
}

const styles = (theme: Theme) =>
createStyles({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#004B73',
  },
})

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="headquarter-register-dialog-header" {...other}>
      <Typography variant="h2" className="bold-serif-primaryColor" style={{marginRight:'16px'}}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface HeadquarterModalProps{
    open: any;
    handleClose: any;
    title: string;
    backBtnText?: string;
    continueBtnText?: string;
    userSelected?: any;
    action: string;
}

const HeadquarterModal: React.FC<HeadquarterModalProps> = ({open, handleClose, title, backBtnText, continueBtnText, userSelected, action})=>{
  const { promiseInProgress } = usePromiseTracker();
  const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
  const userContext = React.useContext(UserContext);
  const [formValues, setFormValues] = useState<ICompanyHeadquarters>(new ICompanyHeadquarters());
  const [formErrors, setFormErrors] = useState<ICompanyHeadquarters>(new ICompanyHeadquarters());
  const [disabledSend, setDisableSend]= useState<boolean>(false);
  const [errorMessage, setErrorMessage]= useState('');
  const [openModalError, setOpenModalError] = React.useState(false);
  const [successMessage, setSuccessMessage]= useState('');
  const [openModalSuccess, setOpenModalSuccess] = React.useState(false);
  //Initial data
  const [initData, setInitData] = useState<boolean>(true);
  const [countryCitiesArray, setCountryCitiesArray] = useState<Array<IListParametersInfo>>([]);
  const [departmentArray, setDepartmentArray] = useState<Array<IGeneralParameter>>([]);
  const [cityArray, setCityArray] = useState<Array<IGeneralParameter>>([]);
  const [cities, setCities] = useState<Array<IGeneralParameter>>([]);

  const isValid =
    formValues.name.length > 0 &&
    !formErrors.name &&
    formValues.description.length > 0 &&
    !formErrors.description &&
    formValues.country > 0 &&
    formValues.department > 0 &&
    formValues.city > 0 &&
    formValues.address.length > 0 &&
    !formErrors.address &&
    formValues.phone.length > 0 &&
    !formErrors.phone

  useEffect(()=>{
    if(isValid){
        setDisableSend(true)
    } else {
        setDisableSend(false)
    }
  },[isValid])

  useEffect(()=>{
    const getInitialData = async()=>{
      // data countries-cities
      let dataCountryCities:Array<IListParametersInfo> = (await getCOUNTRIES_CITIES()).list;
      dataCountryCities = sortByKey(dataCountryCities, 'grandParentName');
      setCountryCitiesArray(dataCountryCities);
      // department field
      let dataDepartment:Array<IGeneralParameter> = await getDEPARTAMENT();
      dataDepartment = sortByKey(dataDepartment, 'name');
      setDepartmentArray(dataDepartment ? dataDepartment : []);
      // data for cities
      let dataCity:Array<IGeneralParameter> = await getCITY();
      dataCity = sortByKey(dataCity, 'name');
      setCityArray(dataCity ? dataCity : []);
      setInitData(false);
    }
    getInitialData();
  },[])

  useEffect(()=>{
    if(formValues.country === 0){
      // if (!initLoad) {
        setFormValues(prev => ({
          ...prev,
          department:0,
          city:0
        }));
        setCities([]);
      // }
    }else {
      if(formValues.country === 170){
        setFormValues(prev => ({
          ...prev,
          department:0,
          city:0
        }));
        setCities([]);
      } else {
        setFormValues(prev => ({
          ...prev,
          department:0,
          city:0
        }));
        const countryObjSelected = countryCitiesArray.find(country=>country.id === formValues.country);
        if(countryObjSelected){
          setCities(countryObjSelected.childs);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.country])

  useEffect(()=>{
    setCities([]);
    if(open){
      setFormValues(prev => ({
        ...prev,
        city: 0
      }));
    }
    if(formValues.department>0){
      let citiesDep:Array<IGeneralParameter> = cityArray.filter(city=> city.parentId === formValues.department);
      citiesDep = sortByKey(citiesDep, 'name');
      if(citiesDep.length>0){
        setCities(citiesDep)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.department])

  useEffect(()=>{
    if(action === "Edit"){
      const formData = async()=>{
        const target: ICompanyHeadquarters = new ICompanyHeadquarters();
        Object.assign(target, userSelected)
        for (let [key, value] of Object.entries(target)) {
          setFormValues(prev => ({
            ...prev,
            [key]: value
          }));
        }
      }
      formData();
    }
  },[action, userSelected])

  const handleAction = async()=>{
    const dataToChange: ICompanyHeadquarters = Object.assign(formValues);
    dataToChange.nit = parseInt(userContext.userId? userContext.userId : '', 10);
    if(action === "Edit"){
      const responseUHC: Response = await trackPromise(UpdateHeadquarterCompany(userToken, 
        {...dataToChange, modifyUser: userContext.userId}
      ));
      validateModals(responseUHC);
    } else if (action === "Add"){
      delete dataToChange.id;
      delete dataToChange.companyId;
      const responseCHC: Response = await trackPromise(AddHeadquarterCompany(userToken, 
        {...dataToChange, modifyUser: userContext.userId}
      ));
      validateModals(responseCHC);
    }
  }

  const validateModals = async(responseService: Response)=>{
    if(responseService.status === 200){
      setSuccessMessage('Acción exitosa');
      setOpenModalSuccess(true);
      setOpenModalError(false);
    } else {
      setErrorMessage("Hubo un error, intente de nuevo mas tarde");
      setOpenModalError(true);
      setOpenModalSuccess(false);
    }
  }

  const handleCloseModalSuccess = ()=>{
    setOpenModalSuccess(false);
    handleClose();
    window.location.reload();
  };

  const handleCloseModalError = ()=>{
    setOpenModalError(false);
    handleClose();
    window.location.reload();
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
    const error = Validations(name, value, formValidations) || "";
    setFormErrors(prev=>({
        ...prev,
        [name]: error
    }));
  }

  return(
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="headquarter-register-dialog-container"
        fullWidth
        maxWidth = "lg"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent className="headquarter-register-dialog-content">
          <Grid container item xs={12} direction="row">
            <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
              <Typography variant="h5" className="field-box-title">Nombre de la sede*</Typography>
              <TextField
                fullWidth
                name="name"
                margin="none"
                label={formValues.name === "" ? "Ingrese un nombre para la sede": ""}
                value={formValues.name}
                onChange={handleChange}
                error={!!formErrors.name}
                helperText={formErrors.name}
                InputLabelProps={{shrink: false}}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} direction="row">
            <Grid item xs={12} sm={12} className="field-box-margin special-box-right">
              <Typography variant="h5" className="field-box-title">Descripción de la sede*</Typography>
              <TextField
                fullWidth
                name="description"
                margin="none"
                label={formValues.description === "" ? "Ingrese una descripción": ""}
                value={formValues.description}
                onChange={handleChange}
                error={!!formErrors.description}
                helperText={formErrors.description}
                InputLabelProps={{shrink: false}}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} direction="row">
            <Grid item xs={12} sm={4} className="field-box-margin special-box-right">
              <Typography variant="h5" className="field-box-title">País*</Typography>
              <TextField
                fullWidth
                id="outlined-select-country"
                select
                name="country"
                label={formValues.country === 0 ? "Seleccione": ""}
                value={formValues.country === 0 ? "" : formValues.country}
                onChange={handleChange}
                InputLabelProps={{shrink: false}}
                margin="none"
                variant="outlined"
              >
                {
                  countryCitiesArray && countryCitiesArray.length> 0 &&
                  <MenuItem value={0}><em>Ninguno</em></MenuItem>
                }
                {countryCitiesArray && countryCitiesArray.length>0 ?
                  countryCitiesArray.map((option: IListParametersInfo) => (
                    <MenuItem disabled={option.id === 170 ? false : true} key={option.id} value={option.id}>
                      {option.grandParentName}
                    </MenuItem>
                  ))
                :
                  <MenuItem value={formValues.country}></MenuItem>
                }
              </TextField>
            </Grid>

            <Grid item xs={12} sm={4} className="field-box-margin special-box-right">
              <Typography variant="h5" className="field-box-title">Departamento*</Typography>
              <TextField
                fullWidth
                id="outlined-select-department"
                select
                name="department"
                label={formValues.department === 0 ? "Seleccione": ""}
                value={formValues.department === 0 ? "" : formValues.department}
                onChange={handleChange}
                InputLabelProps={{shrink: false}}
                margin="none"
                variant="outlined"
                disabled={(formValues.country>0 && departmentArray.length>0 && formValues.country === 170 ? false : true)}
              >
                {
                  departmentArray && departmentArray.length>0 ?
                  departmentArray.map((option: IGeneralParameter) => (
                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                      {option.name}
                    </MenuItem>
                  ))
                  :
                  <MenuItem value={formValues.department}></MenuItem>
                }
              </TextField>
            </Grid>

            <Grid item xs={12} sm={4} className="field-box-margin special-box-right">
              <Typography variant="h5" className="field-box-title">Municipio*</Typography>
              <TextField
                fullWidth
                id="outlined-select-city"
                select
                name="city"
                label={formValues.city === 0 ? "Seleccione": ""}
                value={formValues.city === 0 ? "" : formValues.city}
                onChange={handleChange}
                InputLabelProps={{shrink: false}}
                margin="none"
                variant="outlined"
                disabled={(cities && cities.length>0 && ((formValues.country === 170 && formValues.department >0) || (formValues.country !== 170 && formValues.country > 0)) ? false : true)}
              >
                {
                  cities && cities.length>0 ?
                  cities.map((option: IGeneralParameter) => (
                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                      {option.name}
                    </MenuItem>
                  ))
                :
                  <MenuItem value={formValues.city}></MenuItem>
                }
              </TextField>
            </Grid>
          </Grid>
          <Grid container item xs={12} direction="row">
            <Grid item xs={12} sm={4} className="field-box-margin special-box-right">
              <Typography variant="h5" className="field-box-title">Dirección*</Typography>
                <TextField
                  fullWidth
                  name="address"
                  margin="none"
                  label={formValues.address === "" ? "Ingrese dirección empresa": ""}
                  value={formValues.address}
                  onChange={handleChange}
                  error={!!formErrors.address}
                  helperText={formErrors.address}
                  InputLabelProps={{shrink: false}}
                  variant="outlined"
                />
            </Grid>

            <Grid item xs={12} sm={4} className="field-box-margin special-box-right">
              <Typography variant="h5" className="field-box-title">Número telefónico de la sede*</Typography>
                <TextField
                  fullWidth
                  name="phone"
                  margin="none"
                  label={formValues.phone === "" ? "Ingrese teléfono de la empresa": ""}
                  value={formValues.phone}
                  onChange={handleChange}
                  error={!!formErrors.phone}
                  helperText={formErrors.phone}
                  InputLabelProps={{shrink: false}}
                  variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
              <div style={{width:'100%'}}>
                <LoadingArea load={promiseInProgress} loadElement={<BeatLoader size={20} margin={2} color="#14A5DC"/>} awaitingText="Espere un momento por favor"/>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="headquarter-register-dialog-actions">
          <Button className="cancel-button" disabled={promiseInProgress} variant="contained"  size="small" onClick={handleClose}>
            <Typography variant="h5" className="bold-serif">
              {backBtnText}
            </Typography>
          </Button>
          <Button  className="add-button" variant="contained"  size="small" onClick={handleAction}>
            <Typography variant="h5" className="bold-serif">
              {continueBtnText}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess}/>
      <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError}/>
    </>
  )
}

export default HeadquarterModal;