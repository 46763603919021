import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import PreviewNews from '../../../../components/NewsAndEvents/PreviewNews';
import { getEventsNews } from '../../../../services/NewsEventsService';
import { IRegisterEvent } from '../../../../interfaces/IRegisterEvent';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';


const PreviewNewsPage:React.FC = () =>{
    let params: any = useParams();
    const [detailSelected, setDetailSelected] = useState<IRegisterEvent>(new IRegisterEvent({}));
    const [initLoad, setInitLoad] = useState<boolean>(true);

    useEffect(()=>{
        window.scrollTo(0, 0);
        const getDetailData = async()=>{
            if(params.id){
                const responseDetail: Response = await getEventsNews({id:parseInt(params.id)});
                if(responseDetail.status === 200){
                    const resultDetail = (await responseDetail.json()).publishings[0];
                    const detailObject: IRegisterEvent = new IRegisterEvent(resultDetail);
                    setDetailSelected(detailObject);
                }
            }
            setInitLoad(false);
        }
        getDetailData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>
            <Grid container item xs={12} className="back-offers-list-section">
                <ButtonBackListOffers url="/admin/dashboard/noticias" text="Regresar al listado de noticias"/>
            </Grid>
            {
                detailSelected.name.length>0 &&
                <PreviewNews
                    detailSelected={detailSelected}
                    previewPage="Noticias"
                />
            }
            <LoadingLockPage load={initLoad}/>
        </>
    )
}

export default PreviewNewsPage;