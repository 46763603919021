import React from 'react';
import Grid from "@material-ui/core/Grid";
import NotificationBox from '../../../components/ShareComponents/NotificationBox';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CompanyStepper from '../../../components/Stepper/CompanyStepper';

const SignUpCompany:React.FC = ()=>{
    return(
        <Grid container className="signup-container">
            <Grid container className="main-container">
                <Grid container item xs={12}>
                    <Grid container item xs={12} md={10}>
                        <CompanyStepper/>
                    </Grid>
                    <Grid container item xs={12} md={2} justify="center">
                        <NotificationBox
                            bgColor="#56A4E2"
                            title="Registro empresas"
                            titleColor="#fff"
                            description=""
                            descColor="#fff"
                            positionBox="sticky"
                            icon={<ErrorOutlineIcon style={{color:'#fff'}} fontSize="large"/>}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SignUpCompany