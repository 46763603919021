import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import {Link}  from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventIcon from '@material-ui/icons/Event';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReactHtmlParser from 'react-html-parser';
import { getCATEGORY } from '../../../services/ParameterService';
import { IRegisterEvent } from '../../../interfaces/IRegisterEvent';
import imgNoticias from '../../../assets/images/UN Somos Campus 2016-MEDIA-0432-2.jpg';
import imgEventos from '../../../assets/images/UN Somos Campus 2016-MEDIA-0571.jpg';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { getRegisteredNumberEvent, fileEventLog } from '../../../services/NewsEventsService';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';

import './PreviewNews.scss';

interface PreviewNewsProps{
    detailSelected: IRegisterEvent;
    previewPage: string;
    handleClickBack?:any
}

const PreviewNews: React.FC<PreviewNewsProps> = ({detailSelected, previewPage, handleClickBack}) => {
  const [categorySelected, setCategorySelected] = useState<string>('');
  const [registeredNumber, setRegisteredNumber] = useState<number>(0);
  const [urlFileEventLog, setUrlFileEventLog] = useState('');
  const [initLoad, setInitLoad] = useState<boolean>(true);

  useEffect(() => {
    const getData = async()=>{
      let responseCategory:Array<IGeneralParameter> = await getCATEGORY();
      const catSelected = responseCategory.find(item=> item.parameter_code === detailSelected?.category);
      setCategorySelected(catSelected?.name ? catSelected?.name : '');
      const responseRegistered = await getRegisteredNumberEvent({event:detailSelected.id});
      if(responseRegistered.status === 200){
        const resultNumber = (await responseRegistered.json()).eventLog;
        setRegisteredNumber(resultNumber);
      } else {
        setRegisteredNumber(0);
      }

      const responseFileEventLog = await fileEventLog({event:detailSelected.id});
      if (responseFileEventLog.status === 200) {
        const resultFile = (await responseFileEventLog.json()).urlFile;
        setUrlFileEventLog(resultFile)
      }
      setInitLoad(false);
    }
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid container className="main-container">
        <Grid container item className="title-container">
          <Grid container item xs={12} md={3} lg={3}>
            <div className="title-section-line">
              <VisibilityIcon className="group-icon"/>
              <Typography variant="h5" className="event-title" >
                  Previsualización
              </Typography>
            </div>
          </Grid>
          <Grid container item xs={12} md={9} lg={9} className="lines" />
        </Grid>
        <Grid container className="preview-detail-container">
          <div className="img-container">
            {previewPage === "Noticias" ?
              <>
                {detailSelected.image === "" && detailSelected.type === "Noticia" ?
                  <img className="img" src={imgNoticias} alt={detailSelected.name}/>
                :detailSelected.image === "" && detailSelected.type === "Evento" ?
                  <img className="img" src={imgEventos} alt={detailSelected.name}/>
                :
                  <img className="img" src={detailSelected.image} alt={detailSelected.name}/>
                }
              </>
            :
              <>
                {detailSelected.files.length === 0  && detailSelected.type === "Noticia" ?
                  <img className="img" src={imgNoticias} alt={detailSelected.name}/>
                :detailSelected.files.length === 0 && detailSelected.type === "Evento" ?
                  <img className="img" src={imgEventos} alt={detailSelected.name}/>
                :
                  <img className="img" src={detailSelected.files[0].previewUrl} alt={detailSelected.files[0].file?.name}/>
                }
              </>
            }
          </div>
          <Grid container item xs={12} className="preview-summary-container">
            {categorySelected.length>0 &&
              <Grid container item xs={12}>
                <Typography variant="h4" className="preview-category">
                  <BookmarkIcon className="category-icon"/> {categorySelected}
                </Typography>
              </Grid>
            }
            {detailSelected && detailSelected.type &&
              <Grid container item xs={12} md={12} lg={detailSelected?.type === 'Noticia' ? 12 : 9}>
                {detailSelected.name.length>0 &&
                  <Grid container>
                    <Typography variant="h1" className="preview-title">
                      {detailSelected.name}
                    </Typography>
                  </Grid>
                }
                {detailSelected.shortDescription.length>0 &&
                  <Grid container>
                    <Typography variant="h2" className="preview-short-description">
                    {detailSelected.shortDescription}
                    </Typography>
                  </Grid>
                }
                {detailSelected.description.length>0 &&
                  <Grid container item xs={12}>
                    <div className={detailSelected?.type === 'Noticia' ? "preview-description-html" : "preview-event-description-html"}>
                      {ReactHtmlParser(detailSelected.description)}
                    </div>
                  </Grid>
                }
              </Grid>
            }
            <Grid container item xs={12} md={12} lg={3} className="info-container">
            {detailSelected && detailSelected.type &&
              <Grid container item className={detailSelected?.type === 'Noticia' ? "no-container" : "info-event-container"}>
                <Grid container className="event-summary-content">
                  <Grid container>
                    <Typography variant="h5" className="event-title">
                      Lugar del evento
                    </Typography>
                  </Grid>
                  {detailSelected?.place.length>0 &&
                    <Grid container>
                      <Typography variant="h5" className="event-label">
                        <LocationOnIcon className="date-icon"/> {detailSelected.place}
                      </Typography>
                    </Grid>
                  }
                  <Grid container>
                    <Typography variant="h5" className="event-title">
                      Fecha y hora de inicio del evento
                    </Typography>
                  </Grid>
                  {detailSelected?.dateStart &&
                    <Grid container>
                      <Typography variant="h5" className="event-label">
                        <EventIcon className="date-icon"/>
                        {moment(detailSelected.dateStart).locale('es').format("dddd, DD MMMM YYYY")}
                      </Typography>
                    </Grid>
                  }
                  {detailSelected?.timeStart &&
                    <Grid container>
                      <Typography variant="h5" className="event-label">
                        <AccessTimeIcon className="date-icon"/>
                        {moment(detailSelected.timeStart).locale('es').format("hh:mm a")}
                      </Typography>
                    </Grid>
                  }
                  <Grid container>
                    <Typography variant="h5" className="event-title">
                      Fecha y hora de fin del evento
                    </Typography>
                  </Grid>
                  {detailSelected?.dateFinish &&
                    <Grid container>
                      <Typography variant="h5" className="event-label">
                        <EventIcon className="date-icon"/>
                        {moment(detailSelected.dateFinish).locale('es').format("dddd, DD MMMM YYYY")}
                      </Typography>
                    </Grid>
                  }
                  {detailSelected?.timeFinish &&
                    <Grid container>
                      <Typography variant="h5" className="event-label">
                        <AccessTimeIcon className="date-icon"/>
                        {moment(detailSelected.timeFinish).locale('es').format("hh:mm a")}
                      </Typography>
                    </Grid>
                  }
                </Grid>
                <Grid container className="enroll-container">
                  <Button disabled className="enroll-btn">
                    <Typography variant="h5" className="regular-serif">
                      Inscribirse al evento
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            }
            </Grid>
          </Grid>
          <Grid container item xs={12} className="line" />
          <Grid container item xs={12} className="registered-number-event">
            <Grid item>
              <Typography variant="h3" className="bold-serif">
                Número de Inscritos: {registeredNumber}
              </Typography>
            </Grid>
            <Grid item>
              <a className="download-list-button" href={urlFileEventLog}>
                <Button  className="download-list-btn" variant="contained" size="small">
                    <Typography variant="h5" className="regular-serif">Descargar listado de asistentes</Typography>
                </Button>
              </a>
            </Grid>
          </Grid>
          <Grid container item xs={12} className="line" />
        </Grid>
        <Grid container className='btn-detail-container'>
          {previewPage === "Noticias" ?
            <Button component={ Link } to="/admin/dashboard/noticias" className='continue-button'>
              <Typography variant="h5" className="regular-serif">
                Aceptar
              </Typography>
            </Button>
          :
            <Button onClick={handleClickBack} className='continue-button'>
              <Typography variant="h5" className="regular-serif">
                Aceptar
              </Typography>
            </Button>
          }
        </Grid>
      </Grid>
      <LoadingLockPage load={initLoad}/>
    </>
  )
}

export default PreviewNews
