import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { IGetOffer } from "../../../interfaces/IGetOffer";
import UserContext from "../../../utils/UserContext";
import "./SummaryDescriptionOffer.scss";
import { containsAny } from "../../../helpers/sharedFunctions";
import { isLogin } from "../../../services/AuthService";


interface SummOfferProps{
  dataOffer: IGetOffer;
  department: string;
  city: string;
  companyName: string;
  typePosition: string;
  laboralSector: string;
  contract: string;
  showButtons: boolean;
  handleApply?: any;
  userApply?: boolean;
}

const SummaryDescriptionOffer: React.FC<SummOfferProps> = ({dataOffer, department, city, companyName, typePosition, laboralSector, contract, showButtons, handleApply, userApply}) => {
  const userContext = React.useContext(UserContext);
  const isLogg = isLogin();

  return (
    <>
      <Grid container className="summary-description-container">
        <Grid className="summary-description-content">
          <Grid item xs={12}>
            <Typography variant="h2" className="light-serif primaryColor">
              Resumen de la oferta
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <hr className="line-title"/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className="title-detail-text">
              Cargo:
            </Typography>
            <Typography variant="h4" className="title-text">
              {dataOffer.jobTitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className="title-detail-text">
            Profesión del aspirante:
            </Typography>
            <Typography className="text-details-text">
              {typePosition}
            </Typography>
          </Grid>
          {containsAny([1,2,3,5],[userContext.roleSelected]) && dataOffer.nameCompanyVisible &&
            <Grid item xs={12}>
              <Typography variant="h5" className="title-detail-text" >
                Empresa:
              </Typography>
              <Typography className="text-details-text">
                {companyName}
              </Typography>
            </Grid>
          }
          {department && department.length>0 &&
            <Grid item xs={12}>
              <Typography variant="h5" className="title-detail-text" >
                Ubicación de la oferta:
              </Typography>
              <Grid item xs={12}>
                <Typography className="text-details-text">
                  {department} {city ? `- ${city}` : ''}
                </Typography>
              </Grid>
            </Grid>
          }
          {
            laboralSector && laboralSector.length>0 &&
            <Grid item xs={12}>
              <Typography variant="h5" className="title-detail-text">Sector laboral:</Typography>
              <Typography className="text-details-text"> {laboralSector}</Typography>
            </Grid>
          }
          {
            contract && contract.length > 0 &&
            <Grid item xs={12}>
              <Typography variant="h5" className="title-detail-text">Tipo de contrato:</Typography>
              <Typography className="text-details-text">{contract}</Typography>
            </Grid>
          }
          {
            dataOffer.salaryVisible && dataOffer.rangeSalary.length > 0 &&
            <Grid item xs={12}>
              <Typography variant="h5" className="title-detail-text">Rango salarial:</Typography>
              <Typography className="text-details-text"> {dataOffer.rangeSalary}</Typography>
            </Grid>
          }
          {
              showButtons &&
              <Grid item xs={12} className="submit-button">
                {

                  (userContext.userPercentage &&
                    userContext.userPercentage.total < 100 &&
                    isLogg) ?
                    <>
                      <Button
                        variant="contained"
                        className={"disabled-offer-btn"}
                        disabled={true}
                      >
                        <Typography variant="h5" className="bold-serif">
                          <span>Aplicar a la oferta</span>
                        </Typography>
                      </Button>
                      <Typography variant="h5" className="bold-serif primaryError">
                        No puedes aplicar sin completar los campos obligatorios de tu Hoja de Vida
                      </Typography>
                    </>
                    :
                    <Button
                      variant="contained"
                      className={userApply ? "retry-offer-btn" : "apply-offer-btn"}
                      onClick={handleApply}
                    >
                      <Typography variant="h5" className="bold-serif">
                        {
                          userApply ?
                            <span>Retirarse de la oferta</span>
                            :
                            <span>Aplicar a la oferta</span>
                        }
                      </Typography>
                    </Button>
                }
              </Grid>
            }
        </Grid>
      </Grid>
    </>
  );
};

export default SummaryDescriptionOffer;