import React, { useState, useEffect } from 'react';
import Link from '@material-ui/core/Link';
import ReCAPTCHA from "react-google-recaptcha";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { uploadFileSignedUrl } from '../../../services/s3Service';
import RegisterContext from '../../../utils/RegisterContext';
import { IFile } from '../../../interfaces/IFile';
import { IRegisterCompany } from '../../../interfaces/IRegisterCompany';
import { IRegisterCompanyData } from '../../../interfaces/IRegisterCompanyData';
import { registerCompany } from '../../../services/AuthService';
import SubPreview from '../../ShareComponents/SubPreview';
import SectionPreview from '../../ShareComponents/SectionPreview';
import ErrorModal from '../../ShareComponents/ErrorModal';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';


const ThirdStepCompany: React.FC = ()=>{
    const { promiseInProgress } = usePromiseTracker();
    const registerContext = React.useContext(RegisterContext);
    const SIEkey = process.env.REACT_APP_SIE_SITE_KEY_RECAPTCHA;
    let recaptchaRef: ReCAPTCHA | null;
    const formValues: IRegisterCompany = registerContext.formValues;
    const [disabledSend, setDisableSend]= useState(false);
    const [companyName, setCompanyName] = useState<string>('');
    const [sectorName, setSectorName] = useState<string>('');
    const [departmentName, setDepartmentName] = useState<string>('');
    const [cityName, setCityName] = useState<string>('');
    const [registerError, setRegisterError]= useState('');
    const [openModalError, setOpenModalError] = React.useState(false);
    const widthResolution = window.innerWidth;

    useEffect(()=>{
        window.scrollTo(0, 0);
        registerContext.setFormValues((prev:IRegisterCompany) => ({
            ...prev,
            captchaVerification: false
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if(formValues.captchaVerification && registerContext.step1Completed && registerContext.step2Completed && formValues.termsOfUse){
            setDisableSend(true)
        } else {
            setDisableSend(false)
        }
        const getData = async()=>{
            //  get company name
            if(registerContext.allCompanyType && registerContext.allCompanyType.length>0){
                const elemCompany = registerContext.allCompanyType.find(item => item.parameter_code === formValues.typeOfCompany);
                if(elemCompany){
                    setCompanyName(elemCompany.name);
                }
            }
            //  get laboral sector name
            if(registerContext.allLaboralSector && registerContext.allLaboralSector.length>0){
                const elemSector = registerContext.allLaboralSector.find(item => item.parameter_code === formValues.laboralSector);
                if(elemSector){
                    setSectorName(elemSector.name);
                }
            }
            //  get department name
            if(registerContext.departmentArray && registerContext.departmentArray.length>0){
                const elemDepartment = registerContext.departmentArray.find(item => item.parameter_code === formValues.department);
                if(elemDepartment){
                    setDepartmentName(elemDepartment.name);
                }
            }
            //  get city name
            if(registerContext.cities && registerContext.cities.length>0){
                const elemCity = registerContext.cities.find(item => item.parameter_code === formValues.city);
                if(elemCity){
                    setCityName(elemCity.name);
                }
            }
        }
        getData();
    },[formValues, registerContext])

    const resetRecaptcha = () => {
        recaptchaRef?.reset();
        registerContext.setFormValues((prev:IRegisterCompany) => ({
          ...prev,
          captchaVerification: false
        }))
    };

    const onChange =(response:any)=> {
        if(response){
          registerContext.setFormValues((prev:IRegisterCompany) => ({
            ...prev,
            captchaVerification: true
          }))
        } else {
          registerContext.setFormValues((prev:IRegisterCompany) => ({
            ...prev,
            captchaVerification: false
          }))
        }
    }

    const handleSend = async ()=>{
        const dataToSend: IRegisterCompanyData = new IRegisterCompanyData(formValues);
        let uploadDone:Array<boolean> = [];
        if(formValues.signedUrllArr.length>0 && formValues.files.length>0){
            for(let i = 0; i< formValues.signedUrllArr.length ; i++){
                const urlPresigned = formValues.signedUrllArr[i].uploadURL;
                const file = formValues.files[i].file;
                if(file){
                    const result:Response = await trackPromise(uploadFileSignedUrl(urlPresigned, file));
                    if(result){
                        uploadDone.push(true)
                    } else {
                        uploadDone.push(false)
                    }
                }
            }
        }
        if(uploadDone.includes(false)){
            setRegisterError('Hubo un error subiendo los archivos');
            setOpenModalError(true);
        } else {
            const responseRC:Response = await trackPromise(registerCompany(dataToSend));
            if(responseRC.status === 200){
                registerContext.changeStep(3)
                setRegisterError('');
                setOpenModalError(false);
            } else if (responseRC.status === 400){
                const result = await responseRC.json();
                setRegisterError(result.message);
                setOpenModalError(true);
            } else {
                setRegisterError('Hubo un error creando el registro');
                setOpenModalError(true);
            }
        }
    }

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
    }

    return(
        <>
            <Grid container className="third-step-container">
                <Grid container item xs={12} className="third-step-content">
                    <Typography variant="h2" className="bold-serif-primaryColor">
                        3.CONFIRMACIÓN DE REGISTRO
                    </Typography>
                    <Typography variant="h5" className="summary-description">Por favor verifique la información suministrada antes de enviar el formulario de registro.</Typography>
                    <SubPreview title="Información general de la empresa" step={0} panel="panel1" classEdit="preview-edit-btn-company"/>
                    <Grid container item xs={12}>
                        <SectionPreview cols={1} firstTitle="Razón social" firtsValue={formValues.name}/>
                        <SectionPreview cols={2} firstTitle="NIT" firtsValue={formValues.nit} secondTitle="Dígito de verificación del NIT" secondValue={formValues.verificationDigit}/>
                        <SectionPreview cols={2} firstTitle="Tipo de empresa" firtsValue={companyName} secondTitle="Sector laboral" secondValue={sectorName}/>
                        <SectionPreview cols={3} firstTitle="País" firtsValue="Colombia" secondTitle="Departamento" secondValue={departmentName} thirdTitle="Municipio" thirdValue={cityName}/>
                        <SectionPreview cols={3} firstTitle="Celular" firtsValue={formValues.cellphoneCompany} secondTitle="Teléfono" secondValue={formValues.phone} thirdTitle="Sitio web de la empresa" thirdValue={formValues.website}/>
                    </Grid>
                    <SubPreview title="Informaciónde contacto de representante" step={0} panel="panel2" classEdit="preview-edit-btn-company"/>
                    <Grid container item xs={12}>
                        <SectionPreview cols={2} firstTitle="Nombre completo" firtsValue={formValues.contactName} secondTitle="Cargo em la empresa" secondValue={formValues.position}/>
                        <SectionPreview cols={3} firstTitle="Correo electrónico" firtsValue={formValues.email} secondTitle="Celular del representante" secondValue={formValues.cellphoneContact} thirdTitle="Teléfono del representante" thirdValue={formValues.phoneContact}/>
                    </Grid>
                    <SubPreview title="Documentos adjuntos" step={1} classEdit="preview-edit-btn-company"/>
                    <Grid container item xs={12} justify="center">
                        {formValues.files.map((file:IFile, index)=>(
                        <Grid container item xs={11} sm={3} key={index} className="card-container">
                            <div className="card-header-container">
                                <Typography variant="h5" className="card-title-item">
                                {file.file?.name}
                                </Typography>
                            </div>
                            <Grid container item xs={12} className="card-desc-container">
                                <Typography variant="h6" className="card-desc-item">
                                    {file.description !== '' ? file.description : 'sin descripción'}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} justify="flex-end" className="card-action">
                                <div className="card-action-btn">
                                    <Link target="_blank" className="see-document" rel="noopener" href={file.previewUrl}>
                                        <Typography variant="h6" className="regular-serif">
                                            Ver documento
                                        </Typography>
                                    </Link>
                                </div>
                            </Grid>
                        </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className="recaptcha-container">
                <div className="recaptcha-widget">
                    <ReCAPTCHA
                        sitekey={SIEkey ? SIEkey : ''}
                        onChange={onChange}
                        ref={e => recaptchaRef = e}
                        size={widthResolution >= 540 ? "normal" : "compact"}
                    />
                    <Button variant="contained" className="reset-recaptcha-btn" startIcon={<RotateLeftIcon />} onClick={resetRecaptcha}>
                        REINTENTAR
                    </Button>
                </div>
            </Grid>

            <div className="step-buttons">
                <div className="step-container-btn">
                    <Button variant="contained" className="step-back" startIcon={<NavigateBeforeIcon />} onClick={registerContext.handleBack}>
                        ATRAS
                    </Button>
                </div>
                <div className="step-container-btn">
                    <Button variant="contained" disabled={!disabledSend} className="step-btn" onClick={handleSend}>
                        ENVIAR
                    </Button>
                </div>
            </div>
            <ErrorModal errorMsg={registerError} handleCloseModal={handleCloseModalError} openModalError={openModalError}/>
            <LoadingLockPage load={promiseInProgress}/>
        </>
    )
}

export default ThirdStepCompany