import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import 'moment/locale/es';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { Link, Redirect } from 'react-router-dom';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Validations from '../../../helpers/Validations';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import WarningIcon from '@material-ui/icons/Warning';
import TextField from '@material-ui/core/TextField';
import UserContext from '../../../utils/UserContext';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import InfoIcon from '@material-ui/icons/Info';
import ModalOffersAction from '../../../components/LaboralOffers/ModalOffersAction';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import { createSurvey, updateSurvey } from '../../../services/SurveyServices';
import NotificationBox from '../../ShareComponents/NotificationBox';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import { IGetSurveys } from '../../../interfaces/IGetSurveys';
import { IRegisterSurvey } from '../../../interfaces/IRegisterSurvey';
import { IRegisterSurveysData } from '../../../interfaces/IRegisterSurveysData';
import './SurveyForm.scss';

const initialFormValues ={
  state:"",
  name: "",
  description: "",
  url: "",
  user: "",
}

const formValidations ={
  state: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: true
  },
  name: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: true
  },
  description: {
    error: "",
    validate: "textNumber",
    maxLength: 400,
    minLength: 10,
    required: false
  },
  url: {
    error: "",
    validate: "website",
    minLength: 3,
    required: false
  },
  user: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: false
  }
}

interface SurveyFormProps{
    action?: string;
    surveySelected?: IGetSurveys;
}

const SurveyForm: React.FC<SurveyFormProps> = ({action, surveySelected}) => {
  const urlPath = window.location.href;
  const userContext = React.useContext(UserContext);
  const domain = urlPath.split('#')[0]
  const urlForm = "https://docs.google.com/forms/u/0/?tgif=d";
  const urlSelected =`${domain}#/encuestas/${surveySelected?.id}`
  const { promiseInProgress } = usePromiseTracker();
  const [formValues, setFormValues] = useState(new IRegisterSurvey({}));
  const [formErrors, setFormErrors] = useState(initialFormValues);
  const [url, setUrlSurvey]= useState('');
  const [errorMessage, setErrorMessage]= useState('');
  const [successMessage, setSuccessMessage]= useState('');
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [openChangeModal, setOpenChangeModal] = useState<boolean>(false);
  const [aux, setAux] = useState<boolean>(false);

  const isValid =
    formValues.name.length > 0 &&
    !formErrors.name &&
    !formErrors.description &&
    formValues.url.length > 0 &&
    !formErrors.url

  useEffect(() => {
    if(formValues.user === ""){
      const userName = userContext.username;
      if(userName && userName.length > 0){
        setFormValues(prev => ({
          ...prev,
          user: userName
        }))
      }
    }
    if(formValues.state === ""){
      setFormValues(prev => ({
        ...prev,
        state: "Publicada"
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    if(action === "Edit"){
      const formData = async()=>{
        const target: IGetSurveys = new IGetSurveys();
        Object.assign(target, surveySelected)
        for (let [key, value] of Object.entries(target)) {
          setFormValues(prev => ({
            ...prev,
            [key]: value
          }));
        }
      }
      formData();
    }
  },[action, surveySelected])

  const handleCloseModalSuccess = ()=>{
    setOpenModalSuccess(false);
  };

  const handleCloseModalError = ()=>{
    setOpenModalError(false);
    window.location.reload();
  };


  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
    const error = Validations(name, value, formValidations) || "";
    setFormErrors(prev=>({
        ...prev,
        [name]: error
    }));
  }

  const requestSurveys = async()=>{
    const surveysToSend: IRegisterSurveysData = new IRegisterSurveysData(formValues);
    if(action === "Edit" ){
      const responseCOF: Response = await trackPromise(updateSurvey(formValues));
      if(responseCOF.status === 200){
      setSuccessMessage("La encuesta se ha modificado con exito");
      setOpenModalSuccess(true);
      setAux(true);

    } else {
      setErrorMessage("Hubo un error, intente de nuevo mas tarde");
      setOpenModalError(true);
    }
    } else{
      const responseCOF: Response = await trackPromise(createSurvey(surveysToSend));
      if(responseCOF.status === 200){
        const result = await responseCOF.json();
        setUrlSurvey(`${domain}#/encuestas/${result.message.id}`)
        setSuccessMessage("La encuesta se ha creado con exito");
        setOpenModalSuccess(true);
        setAux(true);
      } else {
        setErrorMessage("Hubo un error, intente de nuevo mas tarde");
        setOpenModalError(true);
      }
    }
  }

  const redirectTo =()=>{
    window.open(urlForm, '_blank')
  }

  const handleChangeStateInactive = ()=>{
      setOpenChangeModal(true);
  }

  const changeStateInactive = async()=>{
    if(surveySelected?.id){
      const responseCSF = await trackPromise(updateSurvey({id: surveySelected?.id, state: "Inactivo", user: surveySelected?.user}));
      if(responseCSF.status === 200){
        const result = await responseCSF.json();
        setSuccessMessage(result.message)
        setOpenChangeModal(false);
        setOpenModalSuccess(true);
        setOpenModalError(false);
      } else {
        setErrorMessage("Hubo un error, intente de nuevo mas tarde");
        setOpenModalError(true);
        setOpenModalSuccess(false);
      }
    }
  }

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const closeModalState = ()=>{
    setOpenChangeModal(false);
  }

  return (
    <>
      {!aux ?
      <>
      <Grid container item xs={12} lg={10} className="surveys-form">

        <Grid container item xs={12} className="surveys-container2">
          <Grid container item xs={12} className="title-container">
            <Typography variant="h2" className="title">
              Contenido
            </Typography>
          </Grid>
          <Grid container item xs={12} direction="row" className="surveys-content2">
            <Grid item xs={12}  className="field-box-margin">
              <Typography variant="h5" className="field-box-title">Título de la encuesta*</Typography>
              <TextField
                fullWidth
                name="name"
                placeholder="Título "
                multiline
                rows={1}
                variant="outlined"
                value={formValues.name || ""}
                onChange={handleChange}
                error={!!formErrors.name}
                helperText={formErrors.name}
                margin="none"
              />
            </Grid>

            <Grid item xs={12}  className="field-box-margin">
              <Typography variant="h5" className="field-box-title">Descripción</Typography>
              <TextField
                fullWidth
                name="description"
                placeholder="Descripción "
                multiline
                rows={5}
                variant="outlined"
                value={formValues.description || ""}
                onChange={handleChange}
                error={!!formErrors.description}
                helperText={formErrors.description}
                margin="none"
              />
            </Grid>
            <Grid item xs={12}  className="field-box-margin">
              <Typography variant="h5" className="field-box-title">Enlace embebido de la encuesta*</Typography>
              <Grid container item xs={12}  className="form-button-container">
              <Button  className="form-button" variant="contained" size="small" onClick={redirectTo} > Ir a Google Forms</Button>
            </Grid>
              <Typography variant="h5" className="field-box-text">Por favor pegue en este campo el enlace corto de la encuesta que le brinda Google Forms.</Typography>
              <TextField
                fullWidth
                name="url"
                placeholder=" "
                multiline
                rows={1}
                variant="outlined"
                value={formValues.url || ""}
                onChange={handleChange}
                error={!!formErrors.url}
                helperText={formErrors.url}
                margin="none"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} lg={2} justify="center" style={{height:'fit-content'}}>
        <NotificationBox
          bgColor="#CFF4FC"
          title="Encuestas"
          titleColor="#055160"
          description="En esta sección puede crear una nueva encuesta. Todos los campos marcados con asterisco (*) son obligatorios. Para enviar la encuesta debe copiar el enlace, ir a Administración de egresados o Administración de empresas, seleccionar los usuarios destinatarios y enviar un mensaje masivo con el enlace a la encuesta."
          descColor="#055160"
          icon={<InfoIcon style={{color:'#055160'}} fontSize="large"/>}
        />
      </Grid>

      <Grid container item xs={12} lg={10} className="buttons-container-survey">
        <Grid container item xs ={12} md={action === "Edit" ? 7 : 6} className="left-buttons">
          <Button startIcon={<ChevronLeftIcon />} className="cancel-button" variant="contained" size="small" component ={ Link } to="/admin/dashboard/encuestas">Cancelar</Button>
          <Button className={action !== "Edit"? "no-button" : "delete-button"} variant="contained" size="small" onClick={handleChangeStateInactive}>Eliminar encuesta</Button>
        </Grid>
        <Grid container item xs ={12} md={action === "Edit"? 5 : 6} className="right-buttons">
          <Button  disabled={!isValid || url.length>0 } onClick={requestSurveys} className="save-button" variant="contained" size="small">Guardar</Button>
        </Grid>
      </Grid>

      <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
      <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
      {/* <LoadingLockPage load={initLoad}/> */}
      <LoadingLockPage load={promiseInProgress}/>

      {url ?
        <Grid container item xs={12} lg={10}   className={url === '' ? "no-container" : "link-container"}>
          <Grid container item xs={12} className="copy-container">
            <Grid container item xs={12} md={9} lg={9}>
              <Typography  variant="h3" className="copy-title">Enlace de la encuesta*</Typography>
                <TextField
                  fullWidth
                  name="url"
                  placeholder=" "
                  multiline
                  rows={1}
                  disabled={true}
                  variant="outlined"
                  value={url}
                  margin="none"
                  className="copy-text"
                />
            </Grid>
            <Grid container item xs={12} md={3} lg={3} className=" button-cont">
              <CopyToClipboard text={url} onCopy={onCopyText}>
                <Grid container className="copy-area">
                  <Button endIcon={<LinkIcon />} className="copy-btn">Copiar enlace</Button>
                  <Grid container className="copy-text-cont">
                    <span className={`copy-feedback ${isCopied ? "active" : ""}`}>
                      Copiado!
                    </span>
                  </Grid>
                </Grid>
              </CopyToClipboard>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Typography variant='h5' className="alert-text">
              <span > <WarningIcon /> </span>
              Este es el enlace de la encuesta que debe enviar a los usuarios. Al hacer clic en el botón "Copiar enlace" quedará copiado en el portapapeles.
            </Typography>
          </Grid>
        </Grid>
      :
        <Grid container item xs={12} lg={10}   className={surveySelected?.id === undefined ? "no-container" : "link-container"}>
          <Grid container item xs={12} className="copy-container">
            <Grid container item xs={12} md={9} lg={9}>
              <Typography  variant="h3" className="copy-title">Enlace de la encuesta*</Typography>
                <TextField
                  fullWidth
                  name="url"
                  placeholder=" "
                  multiline
                  rows={1}
                  disabled={true}
                  variant="outlined"
                  value={urlSelected }
                  margin="none"
                  className="copy-text"
                />
            </Grid>
            <Grid container item xs={12} md={3} lg={3} className=" button-cont">
              <CopyToClipboard text={urlSelected} onCopy={onCopyText}>
                <Grid container className="copy-area">
                  <Button endIcon={<LinkIcon />} className="copy-btn">Copiar enlace</Button>
                  <Grid container className="copy-text-cont">
                    <span className={`copy-feedback ${isCopied ? "active" : ""}`}>
                      Copiado!
                    </span>
                  </Grid>
                </Grid>
              </CopyToClipboard>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Typography variant='h5' className="alert-text">
              <span > <WarningIcon /> </span>
              Este es el enlace de la encuesta que debe enviar a los usuarios. Al hacer clic en el botón "Copiar enlace" quedará copiado en el portapapeles.
            </Typography>
          </Grid>
        </Grid>
      }
      <ModalOffersAction
        open={openChangeModal}
        handleClose={closeModalState}
        handleContinue={changeStateInactive}
        iconModal={<WarningIcon className="big-warning-icon" />}
        title="¿Está seguro de eliminar la encuesta?"
        headerdescription="Esta intentando eliminar una encuesta"
        description=""
        backBtnText="Regresar"
        colorBtnBack= ""
        continueBtnText="Eliminar"
        colorBtnContinue="#f44336"
        valid={true}
      />
      </>
      :
          <Redirect to="/admin/dashboard/encuestas"/>
      }
    </>
  )
}

export default SurveyForm
