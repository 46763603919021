import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { DataGrid, GridColDef, GridOverlay, GridColParams, GridCellParams, GridPageChangeParams, GridBaseComponentProps } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import ModalOffersAction from '../../LaboralOffers/ModalOffersAction';
import WarningIcon from "@material-ui/icons/Warning";
import GetAppIcon from '@material-ui/icons/GetApp';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import { IGetTrainingEvents } from '../../../interfaces/IGetTrainingEvents';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import './TrainingEventsList.scss';
import UserContext from '../../../utils/UserContext';
import { getTYPE_EVENT } from '../../../services/ParameterService';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { deleteTrainingEvent, downloadTrainGuestFile } from '../../../services/TrainingEventServices';

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 14,
            padding: '5px 6px 10px 12px',
            height: '30px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
        },
    }),
)(InputBase);

interface TrainingEventsListProps {
    events: Array<IGetTrainingEvents>;
    pageSize: number;
    onChanhePageSize: any;
    initPage: number;
    setInitPage: any;
    handleUpdate: any;
}

const TrainingEventsList: React.FC<TrainingEventsListProps> = ({ events, pageSize, onChanhePageSize, initPage, setInitPage, handleUpdate }) => {
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [SurveySelected, setSurveySelected] = useState<IGetTrainingEvents>(new IGetTrainingEvents());
    const [errorMessage, setErrorMessage] = useState('');
    const [openModalError, setOpenModalError] = React.useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [openModalSuccess, setOpenModalSuccess] = React.useState(false);
    const [openChangeModal, setOpenChangeModal] = useState<boolean>(false);
    const [openChangeModal2, setOpenChangeModal2] = useState<boolean>(false);
    const [typeEventArray, setTypeEventArray] = useState<Array<IGeneralParameter>>([]);
    
    const columnsAnnouncement: GridColDef[] = [
        {
            field: 'name', headerName: 'Nombre', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Nombre'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'description', headerName: 'Descripción', width: 300, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Descripción'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" id="shortDescription-cell">{params.value}</span>
                )
            }
        },
        {
            field: 'typeEvent', headerName: 'Descripción', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Tipo de evento'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                const valueTypeEvent = typeEventArray.find(element =>{
                    if (params.value === element.parameter_code) return true
                    else return false
                })
                return (
                    <span className="name-cell" id="shortDescription-cell">{valueTypeEvent ? valueTypeEvent.name : params.value}</span>
                )
            }
        },
        {
            field: 'initDate', headerName: 'Fecha de inicio', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Fecha de Inicio'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                const valueDate = moment((params.value)?.toString()).format('YYYY/MM/DD');
                return (
                    <span className="date-cell">{valueDate}</span>
                )
            }
        },
        {
            field: 'endDate', headerName: 'Fecha de finalizacion', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Fecha de Finalizacion'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                const valueDate = moment((params.value)?.toString()).format('YYYY/MM/DD');
                return (
                    <span className="date-cell">{valueDate}</span>
                )
            }
        },
        {
            field: 'Acción', headerName: 'Acciones', width: 100, sortable: false, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Acciones'}
                    </span>
                </strong>
            ), renderCell: (params: GridCellParams) => (
                <>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleClick(e, params)}>
                        <SettingsIcon />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={(e) => { handleCloseMenu(); handleUpdate('Edit', SurveySelected) }}>
                            Ver y editar
                        </MenuItem>
                        {SurveySelected.file ?
                        <MenuItem onClick={(e) => { handleCloseMenu(); handleOpenModalState()}}>
                            Descargar
                        </MenuItem> : <></>
                        }
                        <MenuItem onClick={(e) => { handleCloseMenu(); handleOpenModalState2() }}>
                            Eliminar
                        </MenuItem>
                    </Menu>
                </>
            )
        },
    ];

    useEffect(() => {
        const getInitialData = async () => {
            const reqArray: Array<IGeneralParameter> = await getTYPE_EVENT();
            reqArray.sort((a, b) => a.parameter_code - b.parameter_code)
            setTypeEventArray(reqArray);
        }
        getInitialData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleClick = (survey: React.MouseEvent<HTMLButtonElement>, params: GridCellParams) => {
        setAnchorEl(survey.currentTarget);
        const surveyObj: IGetTrainingEvents = Object.assign(params.row);
        setSurveySelected(surveyObj);
    };

    const handleOpenModalState = () => {
        setOpenChangeModal(true);
    }
    const closeModalState = () => {
        setOpenChangeModal(false);
    }

    const handleOpenModalState2 = () => {
        setOpenChangeModal2(true);
    }
    const closeModalState2 = () => {
        setOpenChangeModal2(false);
    }

    const handleDownload = async () => {
        if (SurveySelected.id) {
            setOpenChangeModal(false);
            const responseCSF = await trackPromise(downloadTrainGuestFile({ id: SurveySelected.id}));
            if (responseCSF.status === 200) {
                const result = await responseCSF.json();
                const buffer = Buffer.from(result.file);
                const blob = new Blob([buffer]);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = `${SurveySelected.name}_Participantes.xlsx`;
                a.click();
                window.URL.revokeObjectURL(url);
                setSuccessMessage("Reporte descargado exitosamente")
                setOpenModalSuccess(true);
            } else {
                setErrorMessage("Hubo un error, intente de nuevo mas tarde");
                setOpenModalError(true);
                setOpenModalSuccess(false);
            }
        }
    }

    const handleDelete = async () => {
        if (SurveySelected.id) {
            const responseCSF = await trackPromise(deleteTrainingEvent({ id: SurveySelected.id}));
            if (responseCSF.status === 200) {
                const result = await responseCSF.json();
                setSuccessMessage(result.message)
                setOpenChangeModal2(false);
                setOpenModalSuccess(true);
            } else {
                setErrorMessage("Hubo un error, intente de nuevo mas tarde");
                setOpenModalError(true);
                setOpenModalSuccess(false);
            }
        }
    }

    const handleCloseModalSuccess = () => {
        setOpenModalSuccess(false);
        window.location.reload();
    };

    const handleCloseModalError = () => {
        setOpenModalError(false);
    };


    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handlePage = (pageParams: GridPageChangeParams) => {
        setInitPage(1)
    }

    return (
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid rowHeight={100} autoHeight className="data-grid-list" disableColumnMenu rows={events} columns={columnsAnnouncement} pageSize={pageSize} pagination density="compact" disableSelectionOnClick onPageChange={(params: GridPageChangeParams) => handlePage(params)}
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                        Footer: (props) => CustomFooter(props, initPage, onChanhePageSize, pageSize)
                    }}
                />
                <ModalOffersAction
                    open={openChangeModal}
                    handleClose={closeModalState}
                    handleContinue={handleDownload}
                    iconModal={<GetAppIcon className="big-info-icon"/>}
                    title="¿Está seguro de realizar la descarga?"
                    headerdescription="Usted va a descargar el reporte de impactados por el evento"
                    description=""
                    backBtnText="Regresar"
                    colorBtnBack=""
                    continueBtnText="Descargar"
                    colorBtnContinue="#14A5DC"
                    valid={true}
                />
                <ModalOffersAction
                    open={openChangeModal2}
                    handleClose={closeModalState2}
                    handleContinue={handleDelete}
                    iconModal={<WarningIcon className="big-warning-icon" />}
                    title="¿Está seguro de eliminar la encuesta?"
                    headerdescription="Esta intentando eliminar una encuesta"
                    description=""
                    backBtnText="Regresar"
                    colorBtnBack=""
                    continueBtnText="Eliminar"
                    colorBtnContinue="#f44336"
                    valid={true}
                />
                <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
                <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
                <LoadingLockPage load={promiseInProgress} />
            </Grid>
        </Grid>
    );
}

const CustomFooter = (props: GridBaseComponentProps, initPage: number, onChanhePageSize?: any, valueRows?: number) => {
    const { state, api } = props;
    const rowsPerPageOptions = [5, 10, 25, 50, 100];

    useEffect(() => {
        if (initPage === 0) {
            api.current.setPage(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initPage]);

    return (
        <>
            <div className="footer-container">
                <div className="rows-number-container">
                    <span className="rows-number-text">Mostrar</span>
                    <Select
                        id="demo-simple-select"
                        value={valueRows}
                        onChange={onChanhePageSize}
                        input={<BootstrapInput />}
                    >
                        {rowsPerPageOptions.map(item => (
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                    <span className="rows-number-text">Registros</span>
                </div>
                <Pagination
                    className="pagination-container"
                    color="primary"
                    page={state.pagination.page + 1}
                    count={state.pagination.pageCount}
                    onChange={(event, value) => api.current.setPage(value - 1)}
                    showFirstButton
                    showLastButton
                />
            </div>
        </>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large" />
            <div className="not-results-text">Sin registros</div>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps) => <KeyboardArrowUpIcon fontSize="small" />
const customSortDesc = (props: GridBaseComponentProps) => <KeyboardArrowDownIcon fontSize="small" />

export default TrainingEventsList;