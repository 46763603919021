import { DateRange } from '@material-ui/pickers';
import { AttachingFile } from './IGraduateFilterObjMsg';
import { StateByRole } from './StateByRole';

export class ICompanyFilterForm{
    name: string = "";
    nit: string | null = null;
    laboralSector: number = 0;
    typeOfCompany: number = 0;
    state: Array<StateByRole> = [];
    country: number = 0;
    department: number = 0
    city: number = 0;
    rangeRegister: DateRange<Date> = [null,null];
    inJobBankDate: DateRange<Date> = [null,null];
    observation: number = 0;
    otherObservation: string = "";
    extraEmail: string = "";
    aditionalEmails: Array<string> = [];
    attachingFiles: Array<AttachingFile> = [];
    subject: string = "";
    html: string = "";
}