import React, { useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import './UnenrollModal.scss';
import { Grid } from '@material-ui/core';
import { IUnenrollOptions } from '../../../interfaces/IUnenrollOptions';

const styles = (theme: Theme) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(2),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className="unenroll-dialog-header" {...other}>
            <Typography variant="h2" className="bold-serif" style={{ marginRight: '16px' }}>{children}</Typography>
        </MuiDialogTitle>
    );
});


interface UnenrollModalProps {
    list: Array<IUnenrollOptions>;
    handleCloseModal: any;
    handleCancelModal:any;
    openModalUnenroll: boolean;
    onChangeUnenroll:any;
    urlRedirect?: string;
}

const UnenrollModal: React.FC<UnenrollModalProps> = ({ list, handleCloseModal,handleCancelModal, openModalUnenroll,onChangeUnenroll, urlRedirect }) => {

    const [selectedOption, setSelectedOption] = useState<IUnenrollOptions>();
    const [moreInfo, setMoreInfo] = useState<string>("");

    const isValid = selectedOption !== undefined && moreInfo.length > 3;

    return (
        <Dialog aria-labelledby="customized-dialog-title" open={openModalUnenroll} className="unenroll-dialog-container">
            <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
                Por favor ingresa el motivo de tu retiro
            </DialogTitle>
            <DialogContent className="unenroll-dialog-content">
                <Typography variant="h5" className='text-content-dialog'>
                    Es importante que nos otorges las razones de tu retiro, abajo puedes ampliar la informacion en cuanto al motivo que elijas
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}> 
                        <Typography variant="h5" className="bold-serif-primaryColor ">Motivo de Retiro*</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-unenroll"
                            select
                            name="unenroll"
                            label={selectedOption?.label !== undefined ? "": "Ingrese el motivo de su retiro" }
                            value={selectedOption?.value}
                            onChange={(e: any) => {
                                onChangeUnenroll(list.find(element => element.id === e.target.value)?.label, 'reason')
                                setSelectedOption(list.find(element => element.id === e.target.value))
                            }}
                            // error={!!formErrors.language}
                            // helperText={formErrors.language}
                            InputLabelProps={{ shrink: false }}
                            margin="none"
                            variant="outlined">
                            {list.map((option: IUnenrollOptions) => (
                                <MenuItem key={option.id} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" className="bold-serif-primaryColor ">Ampliar la informacion</Typography>
                        <TextField
                            id="outlined-multiline-unenroll"
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            value={moreInfo}
                            onChange={(e) => {
                                onChangeUnenroll(e.target.value, 'description')
                                setMoreInfo(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions className="unenroll-dialog-actions">
                <Grid container justify='center'>
                    <Grid item xs={6}>
                        <Button
                            className="back-button"
                            variant="contained"
                            size="small"
                            onClick={handleCancelModal}
                            >
                            <Typography variant="h6" className="regular-serif">
                                Cancelar
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                    {
                        urlRedirect ?
                            <Button component={Link} to={urlRedirect} className="accept-button">
                                <Typography variant="h5" className="regular-serif">
                                    Aceptar
                                </Typography>
                            </Button>
                            :
                            <Button disabled={!isValid} onClick={() => {
                                handleCloseModal()
                                setSelectedOption(undefined);
                                setMoreInfo("");
                            }} className="accept-button">
                                <Typography variant="h5" className="regular-serif">
                                    Aceptar
                                </Typography>
                            </Button>
                    }
                    </Grid>

                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default UnenrollModal;