export class IGetParameterSettings{
    EMAIL_HELPDESK:string;
    EMAIL_FROM:string;
    SALARY_MINIMUM:string;
    constructor(def: any){
        this.EMAIL_HELPDESK = def.EMAIL_HELPDESK || "";
        this.EMAIL_FROM = def.EMAIL_FROM || "";
        this.SALARY_MINIMUM = def.SALARY_MINIMUM || "";

    }
}