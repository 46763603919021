import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import './ModalConfirmedAction.scss';


const styles = (theme: Theme) =>
createStyles({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#004B73',
  },
})

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography {...other}>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

interface ModalConfirmedActionProps{
    open: any;
    handleClose: any;
    icon?: any;
    description:string;
}

const ModalConfirmedAction: React.FC<ModalConfirmedActionProps> = ({open, handleClose, icon, description})=>{
    return(
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className="confirmed-dialog-container"
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            </DialogTitle>
            <DialogContent className="confirmed-dialog-content">
              {icon}
              <Typography align="center" variant="h2" className="regular-serif primaryColor">
                  {description}
              </Typography>
            </DialogContent>
            <DialogActions className="confirmed-dialog-actions">
              <Button className="back-button" variant="contained" size="small" onClick={handleClose} >
                <Typography variant="h5" className="regular-serif">
                  Regresar
                </Typography>
              </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalConfirmedAction