import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import EditButton from '../../ShareComponents/EditButton';
import RegisterContext from '../../../utils/RegisterContext';
import CompanyForm from '../StepperForms/CompanyForm';
import RepresentativeForm from '../StepperForms/RepresentativeForm';

const Accordion = withStyles({
    root: {
      boxShadow: 'none',
      marginBottom: '10px',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
})(MuiAccordion);

const FirstStepCompany: React.FC = ()=>{
    const registerContext = React.useContext(RegisterContext);
    const isValid =
    registerContext.firstAccorDone &&
    registerContext.secondAccorDone

    useEffect(()=>{
      window.scrollTo(0, 0);
    },[]);

    useEffect(()=>{
      if(isValid){
        registerContext.setStep1Completed(true);
      } else {
        registerContext.setStep1Completed(false);
      }
    });

    return(
        <>
        <div style={{width: '100%'}}>
          <Accordion square expanded={registerContext.expanded === 'panel1'} onChange={registerContext.handleChangeAccordion('panel1')}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              className={registerContext.firstAccorDone ? "accordion-summary accordion-done-company" : "accordion-summary accordion-edit-company"}
              >
                <div className="summary-content">
                  <div className="summary-text">
                    <Typography variant="h2" className="bold-serif-primaryColor">INFORMACIÓN GENERAL DE LA EMPRESA</Typography>
                    <Typography variant="h5" className="summary-description">Ingrese sus datos e información general de su empresa. Los campos marcados con el asterisco (*) son obligatorios y debe diligenciarlos para completar el proceso de registro.</Typography>
                  </div>
                  { registerContext.firstAccorDone && registerContext.expanded !== 'panel1' ? <IconButton disableRipple className="accordion-icon"><EditButton colorInside='#14A5DC'/></IconButton>: null}
                </div>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <CompanyForm/>
            </AccordionDetails>
          </Accordion>
          <Accordion square expanded={registerContext.expanded === 'panel2'} onChange={registerContext.handleChangeAccordion('panel2')}>
          <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
              className={registerContext.secondAccorDone ? "accordion-summary accordion-done-company" : "accordion-summary accordion-edit-company"}
              >
                <div className="summary-content">
                  <div className="summary-text">
                    <Typography variant="h2" className="bold-serif-primaryColor">INFORMACIÓN DE CONTACTO DEL REPRESENTANTE</Typography>
                    <Typography variant="h5" className="summary-description">A continuación ingrese su información de contacto como representante de la empresa. Los campos marcados con el asterisco (*) son obligatorios y debe diligenciarlos para completar el proceso de registro.</Typography>
                  </div>
                  { registerContext.secondAccorDone && registerContext.expanded !== 'panel2' ? <IconButton disableRipple className="accordion-icon"><EditButton colorInside='#14A5DC'/></IconButton>: null}
                </div>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <RepresentativeForm/>
            </AccordionDetails>
          </Accordion>
          <div className="step-container-btn">
            <Button disabled={!isValid} variant="contained" endIcon={<NavigateNextIcon/>} className="step-btn" onClick={isValid ? registerContext.handleNext : null}>
              SIGUIENTE
            </Button>
          </div>
        </div>
      </>
    )
}

export default FirstStepCompany;