import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import '../LaboralOffers.scss';


interface OffersGraduateStateProps{
    selected: string;
    handleClick: any;
    offersNumber: number;
}

const OffersGraduateStateFilters: React.FC<OffersGraduateStateProps> = ({selected, handleClick, offersNumber})=>{

    const buttons = [
        {id: 1, name: 'Ofertas en convocatoria abierta', state: 'Convocatoria abierta'},
        {id: 2, name: 'Mis postulaciones', state: 'Mis postulaciones'},
    ]


    return(
        <Grid item xs={12} className="state-bar-container">
            <Grid container item xs={12} className="container-graduate-buttons">
                {
                    buttons.map(item=>(
                        <Button key={item.id} className={selected === item.state ? 'button-graduate-definition-active' : 'button-graduate-definition'} onClick={(e)=>handleClick(e, item.state)}>
                            <Typography variant="h5" className="regular-serif">
                                {item.name}
                            </Typography>
                            {selected === item.state &&
                                <Typography variant="h6" className="button-graduate-number-active">
                                    {offersNumber}
                                </Typography>
                            }
                        </Button>
                    ))
                }
            </Grid>
        </Grid>
    )
}

export default OffersGraduateStateFilters;