export class IAdditionalInfoCV{
    doc: string;
    associations: Array<IAssociation>;
    recognitions: Array<IRecognition>;
    studiesOfInterest: Array<IStudyInterest>;
    researchProjects: Array<IResearchProject>;
    ventures: Array<IVenture>;
    constructor(def:any){
        this.doc = def.doc || "";
        this.associations = def.associations || [];
        this.recognitions = def.recognitions || [];
        this.studiesOfInterest = def.studiesOfInterest || [];
        this.researchProjects = def.researchProjects || [];
        this.ventures = def.ventures || [];
    }
}

export class IAssociation{
    id?:number | null = null;
    typeOfAssociation: number = 0;
    association: number = 0;
}

export class IRecognition{
    id?:number | null = null;
    graduateId?: number | null = null;
    name: string = "";
    typeOfRecognition: number = 0;
    description: string = "";
    institution: string = "";
    visible: boolean = false;
    dateFormat?: Date | null = null;
    date: string = "";
    createdAt?: string = "";
    updatedAt?: string = "";
}

export class IStudyInterest{
    id?:number | null = null;
    area: string = "";
    study: string = "";
}

export class IResearchProject{
    id?:number | null = null;
    graduateId?: number | null = null;
    name: string = "";
    role: string = "";
    groupClassification: string = "";
    startDateFormat?: Date | null = null;
    startDate: string = "";
    endDateFormat?: Date | null = null;
    endDate: string = "";
    entityDescription: string = "";
    typeOfEntity: number = 0;
    institution: number = 0;
    visible: boolean = false;
    createdAt?: string = "";
    updatedAt?: string = "";
}

export class IVenture{
    id?:number | null = null;
    name: string = "";
    sector: number = 0;
    description: string = "";
    startDateFormat?: Date | null = null;
    startDate: string = "";
    actualState: number = 0;
    visible: boolean = false;
}
