import React, { useState } from 'react';
import moment from 'moment';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { Grid, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { IGraduateTable } from '../../../interfaces/IGraduateTable';
import { GridCellParams, GridColDef, GridColParams } from '@material-ui/data-grid';
import DataTableAction from '../DataTableAction';
import ConfirmActionBox from '../ConfirmActionBox';
import ModalConfirmedAction from '../../ShareComponents/ModalConfirmedAction';
import { changeStateGraduate } from '../../../services/ChangeStateService';
import SelectRejectBox from '../SelectRejectBox';
import { UserStateObject } from '../../../interfaces/UserStateObject';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import ErrorModal from '../../ShareComponents/ErrorModal';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { IGraduateFilterForm } from '../../../interfaces/IGraduateFilterForm';
import GraduateManagementState, { UsersStateTosendObj } from '../../../pages/dashboard/admin/GraduateManagement';
import UserContext from '../../../utils/UserContext';

import './GraduateChangeAction.scss';

interface GraduateChangeActionProps{
    usersObject: Array<IGraduateTable>;
    usersStateToSend: UsersStateTosendObj;
    allPrograms: Array<IGeneralParameter>;
    formValues: IGraduateFilterForm;
    formErrors: any;
    onChangeForm: any;
}

const GraduateChangeAction: React.FC<GraduateChangeActionProps> = ({usersObject, usersStateToSend, allPrograms, formValues, formErrors, onChangeForm })=>{
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
    const [cancelActive, setCancelActive] = useState<boolean>(false);
    const [stateChanged, setStateChanged] = useState<boolean>(false);
    const [serviceError, setServiceError]= useState('');
    const [openModalError, setOpenModalError] = React.useState(false);

    const columnsGraduateAction: GridColDef[] = [
        { field: 'fullname', headerName: 'Nombres/Apellidos', width: 290, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Nombres/Apellidos'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'date', headerName: 'Fecha de grado', width: 210, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    Fecha de grado
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) =>{
              const valueDate = moment((params.value)?.toString()).format('YYYY/MM');
              return(
                  <span className="date-cell">{valueDate}</span>
                )
            }
        },
        { field: 'doc', headerName: 'Número identificación', width: 220, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    Número de identificación
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <span className="doc-cell">{params.value}</span>
        ) },
        { field: 'state', headerName: 'Estado', width: 180, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Estado'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <strong>
                {params.value === 'Activo' && <div className="active-state state-button-cell">Activo</div>}
                {params.value === 'Inscrito' && <div className="signed-up-state state-button-cell">Inscrito</div>}
                {params.value === 'Verificado' && <div className="verified-state state-button-cell">Verificado</div>}
                {params.value === 'Inactivo' && <div className="inactive-state state-button-cell">Inactivo</div>}
                {params.value === 'Rechazado' && <div className="rejected-state state-button-cell">Rechazado</div>}
            </strong>
        ) },
        { field: 'curriculum', headerName: 'Programa', width: 230, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Programa'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              if(allPrograms.length>0){
                const programObj = allPrograms.find(program => program.id === params.value);
                if(programObj){
                    return(
                        <span className="curriculum-cell">{programObj.name}</span>
                    )
                }
              }
              return(
                <span></span>
              )
            }
        },
    ];

    const isValid =
    usersStateToSend.graduates.length>0 &&
    (
        usersStateToSend.graduates[0].state === "Inscrito" ||
        usersStateToSend.graduates[0].state === "Verificado" ||
        usersStateToSend.graduates[0].state === "Activo" ||
        usersStateToSend.graduates[0].state === "Inactivo" ||
        (usersStateToSend.graduates[0].state === "Rechazado" &&
            (
                (formValues.observation>0 && formValues.observation<8) ||
                (   formValues.observation === 8 &&
                    formValues.otherObservation.length>0 &&
                    !formErrors.otherObservation
                )
            )
        )
    );

    const cancelClick = ()=>{
        setCancelActive(true)
    };

    const sendChangeState = async()=>{
        usersStateToSend.graduates.forEach((user: UserStateObject)=>{
            if(formValues.observation === 0){
                user.observation = "";
                user.otherObservation = ""
            } else if (formValues.observation>0 && formValues.observation <8){
                user.observation = formValues.observation+"";
                user.otherObservation = ""
            } else {
                user.observation = formValues.observation+"";
                user.otherObservation = formValues.otherObservation;
            }
        });
        const responseCSGraduate: Response = await trackPromise(changeStateGraduate(
            userToken,
            {...usersStateToSend, modifyUser: userContext.userId}
        ));
        if(responseCSGraduate.status === 200){
            const resultCSGraduate = await responseCSGraduate.json();
            if(usersStateToSend.graduates.length>1){
                const checkChanged = resultCSGraduate.messages.filter((respUser: any) =>{
                    let validator: boolean = false;
                    if(respUser.State === "Issue"){
                        validator = true;
                    } else{
                        validator = false;
                    }
                    return validator && respUser
                });
                if(checkChanged.length>0){
                    const usersError = checkChanged.map((user: any)=>user.User);
                    setStateChanged(false);
                    setServiceError(`Ocurrio un error cambiando de estado a los siguientes usuarios: ${usersError.toString()}`);
                    setOpenModalError(true);
                } else {
                    setStateChanged(true);
                    setServiceError('');
                    setOpenModalError(false);
                }
            } else {
                if(resultCSGraduate.messages[0].State === "Issue"){
                    if(resultCSGraduate.messages[0].Observation === "Not found in LDAP"){
                        setStateChanged(false);
                        setServiceError(`Ocurrio un error cambiando el estado del usuario: ${resultCSGraduate.messages[0].User.toString()} porque no se encuentra en el LDAP`);
                        setOpenModalError(true);
                    } else {
                        setStateChanged(false);
                        setServiceError(`Ocurrio un error cambiando el estado del usuario: ${resultCSGraduate.messages[0].User.toString()} ${resultCSGraduate.messages[0].Observation}`);
                        setOpenModalError(true);
                    }
                } else {
                    setStateChanged(true);
                    setServiceError('');
                    setOpenModalError(false);
                }
            }
        } else {
            setStateChanged(false);
            setServiceError('Hubo un error, intente de nuevo mas tarde')
            setOpenModalError(true);
        }
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
    };

    const handleClose = () => {
        setCancelActive(true);
        setStateChanged(false);
    };

    return(
        <>
            {   cancelActive ?
                <GraduateManagementState/>
            :
                <Grid container className="main-container">
                    <div className="graduate-change-action-container">
                        <Grid container item xs={12}>
                            <Typography variant="h1" className="change-action-main-title">
                                Ejecutar acción {usersStateToSend.graduates.length> 1  ?  " masiva" : " individual"}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Typography variant="h5" className="tab-description">
                                Antes de ejecutar la acción revise la tabla de datos que se muestra a continuación y la acción seleccionada:
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Typography variant="h4" className="table-results">
                                Egresados seleccionados {usersObject.length}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <DataTableAction rows={usersObject} columns={columnsGraduateAction}/>
                        </Grid>
                        {
                            usersStateToSend.graduates.length && usersStateToSend.graduates[0].state === "Rechazado" &&
                            <Grid container item xs={12}>
                                <SelectRejectBox formValues={formValues} formErrors={formErrors} onChangeForm = {onChangeForm}/>
                            </Grid>
                        }
                        <Grid container item xs={12} justify="center">
                            <ConfirmActionBox users={usersStateToSend.graduates} state={usersStateToSend.graduates[0].state} cancelClick={cancelClick} sendChangeState={sendChangeState} isValid={isValid}/>
                        </Grid>
                    </div>
                    <ModalConfirmedAction
                        open={stateChanged}
                        handleClose={handleClose}
                        icon={<CheckCircleOutlineIcon style={{color: '#7DA52D', fontSize:'50px'}}/>}
                        description="Acción ejecutada exitosamente"
                    />
                    <ErrorModal errorMsg={serviceError} handleCloseModal={handleCloseModalError} openModalError={openModalError}/>
                </Grid>
            }
            <LoadingLockPage load={promiseInProgress}/>
        </>
    )
}

export default GraduateChangeAction;