import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Landing from '../pages/landing';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import LandingLayout from '../layouts/LandingLayout';
import DashboardLayout from '../layouts/DashboardLayout/index';
import SignIn from '../pages/auth/SignIn';
import SignInAdmin from '../pages/auth/SignInAdmin'
import ConsultState from '../pages/auth/ConsultState';
import SuccessRegister from '../pages/auth/SuccessRegister';
import DashboardAdmin from '../pages/dashboard/admin';
import SignUpCompany from '../pages/auth/SignUpCompany/index';
import RecoverPassword from '../pages/auth/RecoverPassword';
import UserContext from '../utils/UserContext';
import DashboardCompany from '../pages/dashboard/company';
import EditOfferFormView from '../pages/dashboard/company/EditOfferFormView/EditOfferFormView';
import CompanyInfoView from '../pages/dashboard/admin/CompanyInfoView';
import ApplicantsOfferView from '../pages/dashboard/company/ApplicantsOfferView/index';
import EndLaboralOfferView from '../pages/dashboard/company/EndLaboralOfferView';
import OfferDetailView from '../pages/publicPages/OfferDetailView';
import ChangePassword from '../pages/auth/ChangePassword';
import SignUpGraduate from '../pages/auth/SignUpGraduate/index';
import JobOffersView from '../pages/publicPages/JobOffersView/index';
import DashboardGraduate from '../pages/dashboard/graduate';
import OffersAdminView from '../pages/dashboard/admin/OffersAdminView';
import EventDetailView from '../pages/newsAndEvents/EventDetailView';
import PermissionsView from '../pages/dashboard/admin/PermissionsView';
import SearchLDAPView from '../pages/dashboard/admin/SearchLDAPView';
import NotFound from '../pages/NotFound/index';
import NewsPageView from '../pages/newsAndEvents/NewsPageView';
import EventsPageView from '../pages/newsAndEvents/EventsPageView';
import NewEventFormPage from '../pages/dashboard/admin/NewEventFormPage';
import PreviewNewsPage from '../pages/dashboard/admin/PreviewNewsPage';
import ParameterManagement from '../pages/dashboard/admin/ParamaterManagament';
import SurveyFormPage from '../pages/dashboard/admin/SurveyFormPage';
import PreviewSurveyPage from '../pages/dashboard/admin/PreviewSurveyPage';
import TrainingEventFormPage from '../pages/dashboard/admin/TrainingEventFormPage';
import CurriculumManagement from '../pages/dashboard/admin/CurriculumManagement';
import CompanyInformation from '../pages/dashboard/admin/CompanyInformation';
import { IPermissionRole } from '../interfaces/IPermissionRole';
import { IPermission } from '../interfaces/IPermission';
import { IUserPercentage } from '../interfaces/IUserPercentage';
import { getUserPercentage } from '../services/GraduateService';
import { trackPromise } from 'react-promise-tracker';



export default function Routes() {
  const [logged, setLogged] = useState<boolean>(false);
  const [rolesUser, setRolesUser] = useState<Array<number>>([]);
  const [userId, setUserId] = useState('');
  const [name, setName] = useState('');
  const [roleSelected, setRoleSelected] = useState<number>(0);
  const [username, setUsername] = useState('');
  const [temporaryKey, setTemporaryKey] = useState(false);
  const [idOffer, setIdOffer] = useState<number | null>(null);
  const [action, setAction] = useState<string>('');
  const [stateOffer, setStateOffer] = useState<string>('');
  const [roleOption, setRoleOption] = useState('Usuarios administradores');
  const [statePostulation, setStatePostulation] = useState<string>('Convocatoria abierta');
  const [stateCurriculumSection, setStateCurriculumSection] = useState<string>('Datos personales');
  const [stateProfileSection, setStateProfileSection] = useState<string>('Mi perfil');
  const [stateSurveysSection, setStateSurveysSection] = useState<string>('');
  const [stateGraduateSection, setStateGraduateSection] = useState<string>('');
  const [stateCompanySection, setStateCompanySection] = useState<string>('');
  const [permissionsRoles, setPermissionsRoles] = useState<Array<IPermissionRole>>([]);
  const [permissions, setPermissions] = useState<Array<IPermission>>([]);
  const [rolePermissions, setRolePermissions] = useState<IPermissionRole>(new IPermissionRole());
  const [userPermissions, setUserPermissions] = useState<Array<number>>([]);
  const [userPercentage, setUserPercentage] = useState<IUserPercentage>(new IUserPercentage({}));

  

  const handleSetUserPercentage = async(idUser:any) => {
    const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
    const reponsePercUser:any = await getUserPercentage(userToken, idUser); 
    if (reponsePercUser.status === 200){
      const tempValues = await reponsePercUser.json()
      const percentageInfo = new IUserPercentage(tempValues)
      setUserPercentage(percentageInfo)
    }
    
  }
  return (
    <UserContext.Provider value={{logged, setLogged, username, setUsername, name, setName, rolesUser, setRolesUser, roleSelected, setRoleSelected, temporaryKey, setTemporaryKey, 
    userId, setUserId, stateOffer, setStateOffer, roleOption, setRoleOption, idOffer, setIdOffer, action, setAction, statePostulation, setStatePostulation, stateCurriculumSection, 
    setStateCurriculumSection, stateProfileSection, setStateProfileSection, stateSurveysSection, setStateSurveysSection, stateGraduateSection, setStateGraduateSection, stateCompanySection, 
    setStateCompanySection, permissions, setPermissions, permissionsRoles, setPermissionsRoles, rolePermissions, setRolePermissions, userPermissions, setUserPermissions, userPercentage, handleSetUserPercentage}}>

      <Switch>
        {/* public routes */}
        <PublicRoute exact path="/" restricted={false} component={Landing} layout={LandingLayout}/>
        <PublicRoute exact path="/login" restricted={true} component={SignIn} layout={LandingLayout} />
        <PublicRoute exact path="/recuperacion-contrasena" restricted={true} component={RecoverPassword} layout={LandingLayout} />
        <PublicRoute exact path="/consulta-estado" restricted={false} component={ConsultState} layout={LandingLayout}/>
        <PublicRoute exact path="/exitoso" restricted={true} component={SuccessRegister} layout={LandingLayout}/>
        <PublicRoute exact path="/ofertas" restricted={false} component={JobOffersView} layout={LandingLayout}/>
        <PublicRoute exact path="/noticias" restricted={false} component={NewsPageView} layout={LandingLayout}/>
        <PublicRoute exact path="/eventos" restricted={false} component={EventsPageView} layout={LandingLayout}/>
        <PublicRoute exact path="/detalle/:id" restricted={false} component={EventDetailView} layout={LandingLayout}/>
        <PublicRoute exact path="/encuestas/:id" restricted={false} component={PreviewSurveyPage} layout={LandingLayout}/>
        <PublicRoute exact path="/noticias/:id" restricted={false} component={EventDetailView} layout={LandingLayout}/>
        <PublicRoute exact path="/eventos/:id" restricted={false} component={EventDetailView} layout={LandingLayout}/>
        <PublicRoute exact path="/ofertas/detalle-oferta/:id" restricted={false} component={OfferDetailView} layout={LandingLayout}/>

        {/* company routes */}
        <PublicRoute exact path="/empresa/registro" restricted={true} component={SignUpCompany} layout={LandingLayout}/>
        <PrivateRoute exact path="/empresa/finalizar-oferta/:id/:state" restricted={false} roleRoute={[2,3]} component={EndLaboralOfferView} layout={DashboardLayout}/>
        <PrivateRoute exact path="/empresa/detalle-oferta/:id" roleRoute={[2,3]} restricted={false} component={OfferDetailView} layout={DashboardLayout}/>
        <PrivateRoute exact path="/empresa/dashboard" roleRoute={[2,3]} restricted={false} component={DashboardCompany} layout={DashboardLayout}/>
        <PrivateRoute exact path="/empresa/dashboard/ofertas" roleRoute={[2,3]} restricted={false} component={DashboardCompany} layout={DashboardLayout}/>
        <PrivateRoute exact path="/empresa/dashboard/ofertas/convocatoria" roleRoute={[2,3]} restricted={false} component={DashboardCompany} layout={DashboardLayout}/>
        <PrivateRoute exact path="/empresa/dashboard/ofertas/seleccion" roleRoute={[2,3]} restricted={false} component={DashboardCompany} layout={DashboardLayout}/>
        <PrivateRoute exact path="/empresa/dashboard/ofertas/finalizadas" roleRoute={[2,3]} restricted={false} component={DashboardCompany} layout={DashboardLayout}/>
        <PrivateRoute exact path="/empresa/dashboard/ofertas/canceladas" roleRoute={[2,3]} restricted={false} component={DashboardCompany} layout={DashboardLayout}/>
        <PrivateRoute exact path="/empresa/dashboard/perfiles" roleRoute={[2,3]} restricted={false} component={DashboardCompany} layout={DashboardLayout}/>
        <PrivateRoute exact path="/empresa/dashboard/perfil" roleRoute={[2,3]} restricted={false} component={DashboardCompany} layout={DashboardLayout}/>
        <PrivateRoute exact path="/empresa/cambio-contrasena" roleRoute={[2,3]} restricted={false} component={ChangePassword} layout={DashboardLayout} />
        <PrivateRoute exact path="/empresa/dashboard/formulario-oferta/:id" roleRoute={[2,3]} restricted={false} component={EditOfferFormView} layout={DashboardLayout}/>
        <PrivateRoute exact path="/empresa/aplicantes-oferta/:id" roleRoute={[2,3]} restricted={false} component={ApplicantsOfferView} layout={DashboardLayout}/>
        <PrivateRoute exact path="/empresa/aplicantes-oferta/:id/:idGraduate" roleRoute={[2,3]} restricted={false} component={ApplicantsOfferView} layout={DashboardLayout}/>

        {/* graduate routes */}
        <PublicRoute exact path="/registro" restricted={true} component={SignUpGraduate} layout={LandingLayout}/>
        <PrivateRoute exact path="/egresado/dashboard" roleRoute={[1]} restricted={false} component={DashboardGraduate} layout={DashboardLayout}/>
        <PrivateRoute exact path="/egresado/dashboard/ofertas" roleRoute={[1]} restricted={false} component={DashboardGraduate} layout={DashboardLayout}/>
        <PrivateRoute exact path="/egresado/dashboard/curriculum" roleRoute={[1]} restricted={false} component={DashboardGraduate} layout={DashboardLayout}/>
        <PrivateRoute exact path="/egresado/dashboard/perfil" roleRoute={[1]} restricted={false} component={DashboardGraduate} layout={DashboardLayout}/>
        <PrivateRoute exact path="/egresado/detalle-oferta/:id" roleRoute={[1]} restricted={false} component={OfferDetailView} layout={DashboardLayout}/>
        <PrivateRoute exact path="/egresado/eventos" restricted={false} roleRoute={[1]} component={EventsPageView} layout={DashboardLayout}/>
        <PrivateRoute exact path="/egresado/eventos/:id" restricted={false} roleRoute={[1]} component={EventDetailView} layout={DashboardLayout}/>
        <PrivateRoute exact path="/egresado/noticias" restricted={false} roleRoute={[1]} component={NewsPageView} layout={DashboardLayout}/>
        <PrivateRoute exact path="/egresado/noticias/:id" restricted={false} roleRoute={[1]} component={EventDetailView} layout={DashboardLayout}/>

        {/* admin routes */}
        <PublicRoute exact path="/admin/login" restricted={true} component={SignInAdmin} layout={LandingLayout}/>
        <PrivateRoute exact path="/admin/dashboard" roleRoute={[4,5,6,7,8,9]} restricted={false} component={DashboardAdmin} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/egresados" roleRoute={[4,5,6,7,8,9]} restricted={false} component={DashboardAdmin} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/mensajeria-egresados" roleRoute={[4,5,6,7,8,9]} restricted={false} component={DashboardAdmin} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/reportes-egresados" roleRoute={[4,5,6,7,8,9]} restricted={false} component={DashboardAdmin} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/curriculum-egresado/:id" roleRoute={[4,5,6,7,8,9]} restricted={false} component={CurriculumManagement} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/info-empresa/:nit" roleRoute={[4,5,6,7,8,9]} restricted={false} component={CompanyInformation} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/empresas" roleRoute={[4,5,6,7,8,9]} restricted={false} component={DashboardAdmin} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/mensajeria-empresas" roleRoute={[4,5,6,7,8,9]} restricted={false} component={DashboardAdmin} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/reportes-empresas" roleRoute={[4,5,6,7,8,9]} restricted={false} component={DashboardAdmin} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/noticias" roleRoute={[4,5,6,7,8,9]} restricted={false} component={DashboardAdmin} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/encuestas" roleRoute={[4,5,6,7,8,9]} restricted={false} component={DashboardAdmin} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/capacitaciones" roleRoute={[4,5,6,7,8,9]} restricted={false} component={DashboardAdmin} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/empresa-info/:nit" restricted={false} roleRoute={[4,5,6,7,8,9]} component={CompanyInfoView} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/ofertas" restricted={false} roleRoute={[4,5,6,7,8,9]} component={OffersAdminView} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/crear-evento-noticia" restricted={false} roleRoute={[4,5,6,7,8,9]} component={NewEventFormPage} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/detalle-evento-noticia/:id" restricted={false} roleRoute={[4,5,6,7,8,9]} component={PreviewNewsPage} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/crear-encuesta" restricted={false} roleRoute={[4,5,6,7,8,9]} component={SurveyFormPage} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/editar-encuesta/:id" restricted={false} roleRoute={[4,5,6,7,8,9]} component={SurveyFormPage} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/crear-capacitacion" restricted={false} roleRoute={[4,5,6,7,8,9]} component={TrainingEventFormPage} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/editar-capacitacion/:id" restricted={false} roleRoute={[4,5,6,7,8,9]} component={TrainingEventFormPage} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/editar-evento-noticia/:id" restricted={false} roleRoute={[4,5,6,7,8,9]} component={NewEventFormPage} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/reportes-ofertas" roleRoute={[4, 5, 6, 7, 8, 9]} restricted={false} component={DashboardAdmin} layout={DashboardLayout} />
        <PrivateRoute exact path="/admin/dashboard/permisos/:idRole" restricted={false} roleRoute={[5]} component={PermissionsView} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/busqueda-ldap" restricted={false} roleRoute={[5]} component={SearchLDAPView} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/usuarios" roleRoute={[5]} restricted={false} component={DashboardAdmin} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/gestion-roles" roleRoute={[5]} restricted={false} component={DashboardAdmin} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/reportes-transacciones" roleRoute={[5]} restricted={false} component={DashboardAdmin} layout={DashboardLayout}/>
        <PrivateRoute exact path="/admin/dashboard/configuracion-parametros" restricted={false} roleRoute={[5]} component={ParameterManagement} layout={DashboardLayout}/>

        <Route component={NotFound} />
      </Switch>
    </UserContext.Provider>
  );
}