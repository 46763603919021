import moment from 'moment';
import { IGeneralParameter } from './IGeneralParameter';

export class ICreateOffer{
    id?: number | null;
    company: number | null;
    jobTitle: string;
    nameCompanyVisible: boolean;
    typePosition: number;
    jobPosition: number;
    numberVacancy: number;
    description: string;
    country: number;
    department: number;
    city: number;
    laboralSector: number;
    typeContract: number;
    salaryVisible: boolean;
    rangeSalary: string;
    jobOfferStartDate: string | null;
    jobOfferEndDate: string | null;
    estimatedDateCompletion: string | null;
    variableSalary: boolean;
    descriptionVariableSalary: string;
    knowlegeArea: Array<number>;
    professions: Array<number>;
    experienceYears: number;
    experienceMonths: number;
    experienceDays: number;
    disability: boolean = false;
    telework: boolean = false;
    skills: Array<number>;
    otherSkills: string;
    academicLevel: number;
    language: Array<number>;
    occupations: Array<number>;
    programs: Array<number>;
    keywords: string;
    constructor(def:any){
        this.id = def.id || null;
        this.company = def.company || null;
        this.jobTitle = def.jobTitle || "";
        this.nameCompanyVisible = def.nameCompanyVisible || false;
        this.typePosition = def.typePosition || 0;
        this.jobPosition = def.jobPosition || 0;
        this.numberVacancy = def.numberVacancy || 0;
        this.description = def.description || "";
        this.country = def.country || 1;
        this.department = def.department || 0;
        this.city = def.city || 0;
        this.laboralSector = def.laboralSector || 0;
        this.typeContract = def.typeContract || 0;
        this.salaryVisible = def.salaryVisible || false;
        this.rangeSalary = def.rangeSalary || "";
        this.disability = def.disability || false;
        this.telework = def.telework || false;
        this.jobOfferStartDate = moment(def.jobOfferStartDate).format() || null;
        this.jobOfferEndDate = moment(def.jobOfferEndDate).format() || null;
        this.estimatedDateCompletion = moment(def.estimatedDateCompletion).format() || null;
        this.variableSalary = def.variableSalary || false;
        this.descriptionVariableSalary = def.descriptionVariableSalary || "";
        this.knowlegeArea = def.knowlegeArea.length>0 ? this.convertObject(def.knowlegeArea) : [];
        this.professions = def.professions.length>0 ? this.convertObject(def.professions) : [];
        this.experienceYears = def.experienceYears || 0;
        this.experienceMonths = def.experienceMonths || 0;
        this.experienceDays = def.experienceDays || 0;
        this.skills = def.skills.length>0 ? this.convertObject(def.skills) : [];
        this.occupations = def.occupations.length>0 ? this.convertObject(def.occupations) : [];
        this.programs = def.programs.length>0 ? this.convertObject(def.programs) : [];
        this.otherSkills = def.otherSkills.length>0 ? this.convertObjectString(def.otherSkills) : "";
        this.keywords = def.keywords.length>0 ? this.convertObjectString(def.keywords) : "";
        this.academicLevel = def.academicLevel || 0;
        this.language = def.language.length>0 ? this.convertObject(def.language) : [];
    }

    convertObject(arrObj: Array<IGeneralParameter>){
        return arrObj.map(obj=>obj.parameter_code)
    }

    convertObjectString(arrObj:Array<any>){
        return (arrObj.map(obj=>obj.name)).toString().split(",").join("|");
    }
}