import React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function CircularProgressDet(props: CircularProgressProps & { value: number, size: number, descColor: string} ) {
    return (
      <Box position="relative" display="inline-flex" m={1}>
        <CircularProgress variant="determinate" style={{color: props.descColor}} {...props}  />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h3"  component="div" className="regular-serif" style={{color: props.descColor}}>
            {`${Math.round(props.value !== undefined ? props.value : 0)}%`}
          </Typography>
        </Box>
      </Box>
    );
}

export default CircularProgressDet;