import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SecurityIcon from "@material-ui/icons/Security";
import ShowRecoverPasswordBox from '../../../components/ShowRecoverPasswordBox';
import Validations from "../../../helpers/Validations";
import { recoverPassword } from "../../../services/AuthService";
import ErrorModal from "../../../components/ShareComponents/ErrorModal";
import LoadingLockPage from "../../../components/ShareComponents/LoadingLockPage";
import ButtonColors from "../../../components/ShareComponents/ButtonColors";
import "./RecoverPassword.scss";


const initialEmailValues = {
  email: ""
}

const emailValidations = {
    email: {
      error: "",
      validate: "email",
      required: true
    }
}

const RecoverPassword: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker();
  const [formValues, setFormValues] = useState(initialEmailValues);
  const [formErrors, setFormErrors] = useState(initialEmailValues);
  const [sended, setSended] = useState(false);
  const [value, setValue] = useState('');
  const [openModalError, setOpenModalError] = React.useState(false);
  const [registerError, setRegisterError]= useState('');

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    // Set values
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
    // set errors
    const error = Validations(name, value, emailValidations) || "";
    setFormErrors(prev=>({
        ...prev,
        [name]: error
    }));
  }

  const makePassword = async () => {
    const response: string = await trackPromise(recoverPassword(formValues));
    if (response !== undefined) {
      setValue(formValues.email)
      setSended(true)
    } else {
      setSended(false)
      setRegisterError('Usuario no encontrado')
      setOpenModalError(true);
    }
  }

  const handleCloseModalError = () => {
    setOpenModalError(false);
  }

  return (
    <>
      <Grid container className="recover-container">
        <Grid container className="main-container">
          { !sended ?
            <Grid container item xs={12} className="recover-box-container">
              <Grid item xs={12}>
                <SecurityIcon className="security-icon" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h1" className="recover-title">
                  RECUPERACIÓN DE CONTRASEÑA
                </Typography>
              </Grid>
              <Grid item xs={12} className="buttonsColors">
                <ButtonColors />
              </Grid>
              <Grid container item xs={12} className="box-recover-container">
                <Typography variant="h5" className="box-text" > Recuperación de contraseña para egresados ingrese <a href="https://cuenta.unal.edu.co/" target="_blank" rel="noreferrer" className="url"  >aquí</a></Typography>
              </Grid>
              <Grid container item xs={12} className="box-recover-container">
                <Grid item>
                  <Typography variant="h5" className="box-text"> Recuperación de contraseña para empresas</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" className="description-text"> Ingrese su correo electrónico a continuación. Se le enviará una
                contraseña temporal que tendrá una vigencia de 2 horas</Typography>
                </Grid>
                <Grid container item xs={10} lg={6}>
                  <TextField
                    fullWidth
                    name="email"
                    margin="none"
                    placeholder = "Correo electrónico"
                    value={formValues.email }
                    onChange={handleChange}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid container item xs={12} className="submit-button">
                  <Button variant="contained" className="sub-btn" onClick={makePassword}>
                    ENVIAR
                  </Button>
                </Grid>
              </Grid>
              <ErrorModal errorMsg={registerError} handleCloseModal={handleCloseModalError} openModalError={openModalError}/>
              <LoadingLockPage load={promiseInProgress}/>
            </Grid>
          :
            <Grid item xs={12}>
              <ShowRecoverPasswordBox value={value} />
            </Grid>
          }
        </Grid>
      </Grid>
    </>
  );
};

export default RecoverPassword;
