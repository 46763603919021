import React, { useEffect, useState } from 'react';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import UserContext from "../../../utils/UserContext";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { StateByRole } from '../../../interfaces/StateByRole';
import { IGeneralSIA } from '../../../interfaces/IGeneralSIA';
import { permissionCompanyActiveRejectedState, permissionCompanyInactiveState, permissionCompanyView, permissionGraduateActiveInactiveState, permissionGraduateVerifyRejectState } from '../../../utils/PermissionsVariables';
import './SelectActions.scss';
import SelectRejectBox from '../SelectRejectBox';
import { getREJECTION_COMPANY, getREJECTION_GRADUATE } from '../../../services/ParameterService';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';

const stateOptions: Array<StateByRole> = [
    {id:2, label: 'Activo', value:'Activo', description: 'Cambiar estado a Activo', permission: permissionCompanyActiveRejectedState },
    {id:3, label: 'Rechazado', value:'Rechazado', description: 'Cambiar estado a Rechazado', permission:  permissionCompanyActiveRejectedState },
    {id:4, label: 'Inactivo', value:'Inactivo', description: 'Cambiar estado a Inactivo', permission: permissionCompanyInactiveState }
];

const stateOptionsByRole: Array<StateByRole> = [
    {id:2, label: 'Verificado', value:'Verificado', description: 'Cambiar estado a Verificado', permission: permissionGraduateVerifyRejectState },
    {id:3, label: 'Activo', value:'Activo', description: 'Cambiar estado a Activo', permission: permissionGraduateActiveInactiveState },
    {id:4, label: 'Inactivo', value:'Inactivo', description: 'Cambiar estado a Inactivo', permission: permissionGraduateActiveInactiveState },
    {id:5, label: 'Rechazado', value:'Rechazado', description: 'Cambiar estado a Rechazado', permission: permissionGraduateVerifyRejectState }
];
const stateOptionsByRole2: Array<StateByRole> = [
    {id:2, label: 'Verificado', value:'Verificado', description: 'Cambiar estado a Verificado', permission: permissionGraduateVerifyRejectState },
    {id:4, label: 'Inactivo', value:'Inactivo', description: 'Cambiar estado a Inactivo', permission: permissionGraduateActiveInactiveState },
    {id:5, label: 'Rechazado', value:'Rechazado', description: 'Cambiar estado a Rechazado', permission: permissionGraduateVerifyRejectState }
];

const actionOptions:Array<IGeneralSIA> = [
    {id:1, name:'Cambiar estado a...'},
]

interface SelectActionsBox2Props{
    stateSelected: string;
    onChangeStateSelected: any;
    observationSelected?: number;
    actionSelected: number;
    onChangeAction: any;
    sendActionSelected?: any;
    formErrors?: any;
    onChangeForm?: any;
    companyInfo?: any;
    changeOtherObservation?: any;
    otherObservation?: any;
    role?: any;
}

const SelectActionsBox2:React.FC<SelectActionsBox2Props> = ({  actionSelected, onChangeAction, stateSelected, onChangeStateSelected, sendActionSelected, observationSelected, onChangeForm, companyInfo, changeOtherObservation, otherObservation, role})=>{

    const [rejectObs, setRejectObs]= useState<Array<IGeneralParameter>>([]);
    const [rejectObsGraduate, setRejectObsGraduate]= useState<Array<IGeneralParameter>>([]);
    const userContext = React.useContext(UserContext);
    
    useEffect(()=>{
        const getObservations = async()=>{
            const responseEO: Array<IGeneralParameter> = await getREJECTION_COMPANY();
            if(responseEO.length>0){
                setRejectObs(responseEO)
            }
        }
        getObservations();
        const getObservations2 = async()=>{
            const responseEO2: Array<IGeneralParameter> = await getREJECTION_GRADUATE();
            if(responseEO2.length>0){
                setRejectObsGraduate(responseEO2)
            }
        }
        getObservations2();
    },[])

    return(
        <Grid container item xs={12} className="select-actions-container">
            
            <Grid item xs={12} md={3}>
                <div className="select-actions-title">
                    <Typography variant="h4" className="bold-serif-primaryColor">Seleccione una acción:</Typography>
                </div>
                <div className="select-actions-control">
                    <TextField
                        fullWidth
                        id="outlined-select-action"
                        select
                        name="actionSelected"
                        label={actionSelected === 0 ? "seleccione" : ""}
                        value={actionSelected === 0 ? "" : actionSelected}
                        onChange={onChangeAction}
                        InputLabelProps={{shrink: false}}
                        margin="none"
                        variant="outlined"
                    >
                        {
                            actionOptions.length> 0 ?
                            actionOptions.map((option: IGeneralSIA)=>(
                                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                            ))
                            :
                            <MenuItem></MenuItem>
                        }
                    </TextField>
                </div>
            </Grid>
            
            {
                actionSelected === 1 &&
                <Grid item xs={12} md={3}>
                    <div className="select-actions-title">
                        <Typography variant="h4" className="bold-serif-primaryColor">Seleccione un estado:</Typography>
                    </div>
                    <div className="select-actions-control">
                        <TextField
                            fullWidth
                            id="outlined-select-action"
                            select
                            name="stateSelected"
                            label={stateSelected === "" ? "seleccione" : ""}
                            value={stateSelected === "" ? "" : stateSelected}
                            onChange={onChangeStateSelected}
                            InputLabelProps={{shrink: false}}
                            margin="none"
                            variant="outlined"
                        >
                            {role === "company" ? 
                                stateOptions.map((option: StateByRole)=>(
                                <MenuItem key={option.id} value={option.value}>{option.description}</MenuItem>
                                ))
                                : userContext.roleSelected === 5 ?
                                stateOptionsByRole.map((option: StateByRole)=>(
                                <MenuItem key={option.id} value={option.value}>{option.description}</MenuItem>
                                ))
                                :
                                stateOptionsByRole2.map((option: StateByRole)=>(
                                <MenuItem key={option.id} value={option.value}>{option.description}</MenuItem>
                                ))
                            }
                        </TextField>
                    </div>
                </Grid>
            }

            {
                stateSelected === "Rechazado" && role ==="company" &&
                <>
                    <Grid item xs={12} sm={5} className="field-box-margin">
                        <Typography className="field-box-title">Seleccione la causal de rechazo*:</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-reject_observation"
                            select
                            name="observation"
                            label={observationSelected === 0 ? "Seleccione": ""}
                            value={observationSelected === 0 ? "" : observationSelected}
                            onChange={onChangeForm}
                            InputLabelProps={{shrink: false}}
                            margin="none"
                            variant="outlined"
                        >
                            {
                            rejectObs.length>0 ?
                            rejectObs.map((option: IGeneralParameter) => (
                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                    {option.name}
                                </MenuItem>
                            ))
                            :
                            <MenuItem></MenuItem>
                            }
                        </TextField>
                    </Grid>
                    {
                        observationSelected === 3 &&
                        <Grid item xs={12} className="field-box-margin">
                            <Typography className="field-box-title">Por favor especifique la causal de rechazo*:</Typography>
                            <TextField
                                fullWidth
                                name="otherObservation"
                                multiline
                                rows={3}
                                variant="outlined"
                                value={otherObservation || ""}
                                onChange={changeOtherObservation}
                                margin="none"
                                InputLabelProps={{shrink: false}}
                                required
                            />
                        </Grid>
                    }
                </>
            }

            {
                stateSelected === "Rechazado" && role ==="graduate" &&
                <>
                    <Grid item xs={12} sm={5} className="field-box-margin">
                        <Typography className="field-box-title">Seleccione la causal de rechazo*:</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-reject_observation"
                            select
                            name="observation"
                            label={observationSelected === 0 ? "Seleccione": ""}
                            value={observationSelected === 0 ? "" : observationSelected}
                            onChange={onChangeForm}
                            InputLabelProps={{shrink: false}}
                            margin="none"
                            variant="outlined"
                        >
                            {
                            rejectObsGraduate.length>0 ?
                            rejectObsGraduate.map((option: IGeneralParameter) => (
                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                    {option.name}
                                </MenuItem>
                            ))
                            :
                            <MenuItem></MenuItem>
                            }
                        </TextField>
                    </Grid>
                    {
                        observationSelected === 8 &&
                        <Grid item xs={12} className="field-box-margin">
                            <Typography className="field-box-title">Por favor especifique la causal de rechazo*:</Typography>
                            <TextField
                                fullWidth
                                name="otherObservation"
                                multiline
                                rows={3}
                                variant="outlined"
                                value={otherObservation || ""}
                                onChange={changeOtherObservation}
                                margin="none"
                                InputLabelProps={{shrink: false}}
                                required
                            />
                        </Grid>
                    }
                </>
            }

            <Grid container item xs={12} md={6} justify="flex-end">
                <Button variant="contained" disabled={(actionSelected > 1 || stateSelected !=="" ) ? false : true} className="select-actions-button" onClick={sendActionSelected}>
                    <Typography variant="h4" className="regular-serif">
                        Ejecutar
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

export default SelectActionsBox2;
