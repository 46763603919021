import React from 'react';
import Grid from '@material-ui/core/Grid';
import './JobOffersSearch.scss';
import Typography from '@material-ui/core/Typography'
import { IFilterJobOffers } from '../../../interfaces/IFilterJobOffers';
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem"
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';

interface JobOffersSearchProps {
    formValues: IFilterJobOffers;
    formErrors: IFilterJobOffers;
    onChangeForm: any;
    departmentArray: Array<IGeneralParameter>;
    cities: Array<IGeneralParameter>;
    requestSearch: any;
    searchActive?: boolean;
    cancelSearch: any;
    isValid: boolean;
}

const JobOffersSearch:React.FC<JobOffersSearchProps> = ({formValues, formErrors,  onChangeForm, departmentArray, cities, requestSearch, searchActive, cancelSearch, isValid})=>{

    return(
        <Grid container item xs={12} className="job-offers-search-container">
            <Grid item xs={12} className="offers-search-title-box">
                <Typography variant="h3" className="regular-serif">
                    Buscar ofertas laborales
                </Typography>
            </Grid>
            <Grid container item xs={12} className="offers-search-boxes">
                <Grid item xs={12} md={6} lg={4} className="field-box-margin special-column-right">
                    <TextField
                        fullWidth
                        name="keyWord"
                        margin="none"
                        autoComplete ="off"
                        placeholder="Palabra clave, cargo, profesión"
                        value={formValues.keyWord || ""}
                        onChange={onChangeForm}
                        error={!!formErrors.keyWord}
                        helperText={formErrors.keyWord}
                        variant="outlined"
                        InputLabelProps={{shrink: false}}
                        InputProps={{
                            style: {
                                background: "white"
                            },
                            startAdornment: (
                            <InputAdornment position="start">
                                    <SearchIcon fontSize="small" color="disabled"/>
                            </InputAdornment>
                            ),
                            endAdornment: searchActive ? (
                                <InputAdornment position="end">
                                    <IconButton size="small" onClick={cancelSearch}>
                                        <CloseIcon fontSize="small"/>
                                    </IconButton>
                                </InputAdornment>
                            ) : null
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4} className="field-box-margin special-column-left">
                    <TextField
                        fullWidth
                        id="outlined-select-department"
                        select
                        name="department"
                        label={formValues.department === 0 ? "Departamento": ""}
                        value={formValues.department === 0 ? "" : formValues.department}
                        onChange={onChangeForm}
                        InputLabelProps={{shrink: false}}
                        margin="none"
                        variant="outlined"
                        InputProps={{
                            style: {
                                background: "white"
                            }
                        }}
                    >
                        {
                            departmentArray && departmentArray.length> 0 &&
                            <MenuItem value={0}><em>Ninguno</em></MenuItem>
                        }
                        {
                        departmentArray && departmentArray.length>0 ?
                        departmentArray.map((option: IGeneralParameter) => (
                            <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                {option.name}
                            </MenuItem>
                        ))
                        :
                        <MenuItem value={formValues.department}></MenuItem>
                        }
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={4} className="field-box-margin special-column-left">
                    <TextField
                        fullWidth
                        id="outlined-select-city"
                        select
                        name="city"
                        label={formValues.city === 0 ? "Municipio": ""}
                        value={formValues.city === 0 ? "" : formValues.city}
                        onChange={onChangeForm}
                        InputLabelProps={{shrink: false}}
                        margin="none"
                        variant="outlined"
                        disabled={cities && cities.length>0 ? false : true}
                        InputProps={{
                            style: {
                                background: "white"
                            }
                        }}
                    >
                        {
                            cities && cities.length> 0 &&
                            <MenuItem value={0}><em>Ninguno</em></MenuItem>
                        }
                        {
                        cities && cities.length>0 ?
                        cities.map((option: IGeneralParameter) => (
                            <MenuItem key={option.parameter_code} value={option.parameter_code}>
                            {option.name}
                            </MenuItem>
                        ))
                        :
                        <MenuItem value={formValues.city}></MenuItem>
                        }
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={12} className="btn-container">
                    <Button disabled={!isValid} variant="contained" className="search-btn" onClick={requestSearch}>
                        <Typography variant="h4" className="regular-serif">Buscar</Typography>
                        <SearchIcon/>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default JobOffersSearch;