import React from 'react';
import Grid from "@material-ui/core/Grid";
import SectionTitle from '../../ShareComponents/SectionTitle';
import AdditionalInfoCard from '../ShareProfileComponents/AdditionalInfoCard';


import '../GraduateProfile.scss';


const AdditionalInformationProfile = () => {
    return (
        <>
            <Grid container item xs={12} md={10}>
                <SectionTitle title="Publicación"/>
                <Grid container item xs={12} direction="column">
                    <AdditionalInfoCard
                        firstTitle="Nombre de la publicación"
                        firtsValue= "Lorem ipsum dolor sit amet."
                        secondTitle="Registro"
                        secondValue="ISBN"
                        subsecondValue="Número de registro"
                        thirdTitle= "Tipo"
                        thirdValue= "Lorem ipsum dolor sit amet."
                        titleDescription= "Descripción"
                        descriptionText= "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Praesentium est sint delectus magnam corrupti consequuntur, ab nemo et voluptatum, nostrum exercitationem deserunt quaerat rem eius odit facere! Libero, enim autem."
                        fourthTitle= "Nombre del comité editorial"
                        fourthValue= "sector comité"
                        italicFirstValue= "Categoría"
                        italicSecondValue= "Editorial"
                        italicThirdValue= "Año de edición"
                    />
                </Grid>
                <SectionTitle title="Eventos"  />
                <Grid container item xs={12} direction="column">
                    <AdditionalInfoCard
                        firstTitle="Nombre del evento"
                        firtsValue= "Lorem ipsum dolor sit amet."
                        secondTitle="Título del trabajo presentado"
                        subsecondValue="Lorem ipsum dolor sit amet."
                        italicFirstValue= "País"
                        italicSecondValue= "Municipio"
                        italicThirdValue= "Fecha"
                    />
                    <AdditionalInfoCard
                        firstTitle="Nombre del evento"
                        firtsValue= "Lorem ipsum dolor sit amet."
                        secondTitle="Título del trabajo presentado"
                        subsecondValue="Lorem ipsum dolor sit amet."
                        italicFirstValue= "País"
                        italicSecondValue= "Municipio"
                        italicThirdValue= "Fecha"
                    />
                </Grid>
                <SectionTitle title="Obras / Exposiciones"  />
                <Grid container item xs={12}  direction="column">
                    <AdditionalInfoCard
                        firstTitle="Nombre de la obra"
                        firtsValue= "Lorem ipsum dolor sit amet."
                        secondTitle="Fecha de finalización"
                        secondValue="10/08/2016"
                        thirdTitle= "Institución organizadora de la exposición"
                        thirdValue= "Lorem ipsum dolor sit amet."
                        fourthTitle= "Nombre de la exposición"
                        fourthValue= "Lorem ipsum dolor sit amet."
                        italicFirstValue= "País"
                        italicSecondValue= "Municipio"
                        italicThirdValue= "Fecha"
                    />
                </Grid>
                <SectionTitle title="Proyecto de extensión"/>
                <Grid container item xs={12}  direction="column">
                    <AdditionalInfoCard
                        firstTitle="Nombre del proyecto de extensión"
                        firtsValue= "Lorem ipsum dolor sit amet."
                        secondTitle="Modalidad"
                        subsecondValue="Lorem ipsum dolor sit amet."
                        thirdTitle= "Rol en el proyecto de extensión"
                        thirdValue= "Lorem ipsum dolor sit amet."
                        titleDescription= "Descripción"
                        descriptionText= "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Praesentium est sint delectus magnam corrupti consequuntur, ab nemo et voluptatum, nostrum exercitationem deserunt quaerat rem eius odit facere! Libero, enim autem."
                        fourthTitle= "Fecha de inicio del proyecto"
                        fourthValue= "23/10/2019"
                        fifthTitle= "Fecha de finalización del proyecto"
                        fifthValue= "30/03/2021"
                        sixthTitle="Intensidad horaria"
                        sixthValue="Lorem ipsum dolor sit amet."
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default AdditionalInformationProfile
