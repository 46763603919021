import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { getREJECTION_GRADUATE } from '../../../services/ParameterService';
import { IGraduateFilterForm } from '../../../interfaces/IGraduateFilterForm';

import './SelectRejectBox.scss';

interface SelectRejectBoxProps{
    formValues: IGraduateFilterForm;
    formErrors: IGraduateFilterForm;
    onChangeForm: any;
}

const SelectRejectBox:React.FC<SelectRejectBoxProps> = ({ formValues, formErrors,  onChangeForm})=>{
    const [rejectObs, setRejectObs]= useState<Array<IGeneralParameter>>([]);

    useEffect(()=>{
        const getObservations = async()=>{
            const responseEO: Array<IGeneralParameter> = await getREJECTION_GRADUATE();
            if(responseEO.length>0){
                setRejectObs(responseEO)
            }
        }
        getObservations();
    },[])

    return(
        <>
            <Grid item xs={12} sm={6} className="field-box-margin">
                <Typography className="field-box-title">Seleccione la causal de rechazo*:</Typography>
                <TextField
                    fullWidth
                    id="outlined-select-reject_observation"
                    select
                    name="observation"
                    label={formValues.observation === 0 ? "Seleccione": ""}
                    value={formValues.observation === 0 ? "" : formValues.observation}
                    onChange={onChangeForm}
                    InputLabelProps={{shrink: false}}
                    margin="none"
                    variant="outlined"
                >
                    {
                    rejectObs.length>0 ?
                    rejectObs.map((option: IGeneralParameter) => (
                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                            {option.name}
                        </MenuItem>
                    ))
                    :
                    <MenuItem></MenuItem>
                    }
                </TextField>
            </Grid>
            {
                formValues.observation === 8 &&
                <Grid item xs={12} className="field-box-margin">
                    <Typography className="field-box-title">Por favor especifique la causal de rechazo*:</Typography>
                    <TextField
                        fullWidth
                        name="otherObservation"
                        multiline
                        rows={3}
                        variant="outlined"
                        value={formValues.otherObservation || ""}
                        onChange={onChangeForm}
                        error={!!formErrors.otherObservation}
                        helperText={formErrors.otherObservation}
                        margin="none"
                        InputLabelProps={{shrink: false}}
                        required
                    />
                </Grid>
            }
        </>
    )
}

export default SelectRejectBox;