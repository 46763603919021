import { IFile } from './IFile';
import { IPresignedUrl } from './IPreSignedUrl';
export class IRegisterCompany{
    name: string = "";
    nit: string = "";
    verificationDigit: string = "";
    typeOfCompany: number = 0;
    typeOfPerson: number = 0;
    laboralSector: number = 0;
    country: number = 170;
    department: number = 0;
    city: number = 0;
    address: string = "";
    phone: string = "";
    cellphoneCompany: string = "";
    website: string = "";
    contactName: string = "";
    position: string = "";
    email: string = "";
    phoneContact: string = "";
    cellphoneContact: string = "";
    repLegalCountry: number = 0;
    repLegalDepartment: number = 0;
    repLegalCity: number = 0;
    description_document: string = "";
    fileError: string = "";
    files: Array<IFile> = [];
    signedUrllArr: Array<IPresignedUrl> = [];
    termsOfUse: boolean = false;
    captchaVerification: boolean = false;
    state: string = "Inscrito";
    inJobBank: Date | null = null;
}