import React, { useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import { IRegisterCompany } from '../../../interfaces/IRegisterCompany';
import RegisterContext from '../../../utils/RegisterContext';
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';
import MenuItem from "@material-ui/core/MenuItem";
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';

const RepresentativeForm: React.FC = () => {
    const registerContext = React.useContext(RegisterContext);
    const formValues: IRegisterCompany = registerContext.formValues;
    const formErrors: IRegisterCompany = registerContext.formErrors;
    const isValidFirstStep =
        registerContext.firstAccorDone &&
        registerContext.secondAccorDone
    // Check if all values are not empty or if there are some error
    const isValid =
        formValues.contactName.length > 0 &&
        !formErrors.contactName &&
        formValues.position.length > 0 &&
        !formErrors.position &&
        formValues.email.length > 0 &&
        !formErrors.email &&
        formValues.cellphoneContact.length > 0 &&
        !formErrors.cellphoneContact &&
        !formErrors.phoneContact &&
        formValues.repLegalCountry > 0;
    useEffect(() => {
        registerContext.handleValidForm('panel2', isValid);
    })


    return (
        <>
            <Grid container spacing={2}>

                <Grid container item xs={12} direction="row">
                    <Grid item xs={12} className="field-box-margin special-box-right">
                        <Typography variant="h6" className="field-box-title-company">Nombre Completo*</Typography>
                        <TextField
                            fullWidth
                            name="contactName"
                            margin="none"
                            label={formValues.contactName === "" ? "Ingrese nombre del representante" : ""}
                            value={formValues.contactName || ""}
                            onChange={registerContext.handleChange}
                            error={!!formErrors.contactName}
                            helperText={formErrors.contactName}
                            InputLabelProps={{ shrink: false }}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} direction="row">
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                        <Typography variant="h6" className="field-box-title-company">Cargo en la empresa*</Typography>
                        <TextField
                            fullWidth
                            name="position"
                            margin="none"
                            label={formValues.position === "" ? "Ingrese cargo del representante" : ""}
                            value={formValues.position || ""}
                            onChange={registerContext.handleChange}
                            error={!!formErrors.position}
                            helperText={formErrors.position}
                            InputLabelProps={{ shrink: false }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                        <Typography variant="h6" className="field-box-title-company">Correo electrónico*</Typography>
                        <TextField
                            fullWidth
                            name="email"
                            margin="none"
                            label={formValues.email === "" ? "Ingrese correo del representante" : ""}
                            value={formValues.email || ""}
                            onChange={registerContext.handleChange}
                            error={!!formErrors.email}
                            helperText={formErrors.email}
                            InputLabelProps={{ shrink: false }}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} direction="row">
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                        <Typography variant="h6" className="field-box-title-company">Número de Celular*</Typography>
                        <TextField
                            fullWidth
                            name="cellphoneContact"
                            margin="none"
                            label={formValues.cellphoneContact === "" ? "Ingrese celular del representante" : ""}
                            value={formValues.cellphoneContact || ""}
                            onChange={registerContext.handleChange}
                            error={!!formErrors.cellphoneContact}
                            helperText={formErrors.cellphoneContact}
                            InputLabelProps={{ shrink: false }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                        <Typography variant="h6" className="field-box-title-company">Número de teléfono</Typography>
                        <TextField
                            fullWidth
                            name="phoneContact"
                            margin="none"
                            label={formValues.phoneContact === "" ? "Ingrese el telégono del representante" : ""}
                            value={formValues.phoneContact || ""}
                            onChange={registerContext.handleChange}
                            error={!!formErrors.phoneContact}
                            helperText={formErrors.phoneContact}
                            InputLabelProps={{ shrink: false }}
                            variant="outlined"
                        />
                    </Grid>


                    <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                        <Typography variant="h6" className="field-box-title-company">País*</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-repLegalCountry"
                            select
                            name="repLegalCountry"
                            label={formValues.repLegalCountry === 0 ? "Seleccione" : ""}
                            value={formValues.repLegalCountry === 0 ? "" : formValues.repLegalCountry}
                            onChange={registerContext.handleChange}
                            InputLabelProps={{ shrink: false }}
                            margin="none"
                            variant="outlined"
                        >
                            {registerContext.countryCitiesArray && registerContext.countryCitiesArray.length > 0 ?
                                registerContext.countryCitiesArray.map((option: IListParametersInfo) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.grandParentName}
                                    </MenuItem>
                                ))
                                :
                                <MenuItem value={formValues.repLegalCountry}></MenuItem>
                            }
                        </TextField>

                    </Grid>
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                        <Typography variant="h6" className="field-box-title-company">Departamento</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-repLegalDepartment"
                            select
                            name="repLegalDepartment"
                            label={formValues.repLegalDepartment === 0 ? "Seleccione" : ""}
                            value={formValues.repLegalDepartment === 0 ? "" : formValues.repLegalDepartment}
                            onChange={registerContext.handleChange}
                            InputLabelProps={{ shrink: false }}
                            margin="none"
                            variant="outlined"
                            disabled={(registerContext.departmentArray && formValues.repLegalCountry > 0 && formValues.repLegalCountry === 170 ? false : true)}
                        >
                            {registerContext.departmentArray && registerContext.departmentArray.length > 0 ?
                                registerContext.departmentArray.map((option: IGeneralParameter) => (
                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                        {option.name}
                                    </MenuItem>
                                ))
                                :
                                <MenuItem value={formValues.repLegalDepartment}></MenuItem>
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                        <Typography variant="h6" className="field-box-title-company">Municipio</Typography>
                        <TextField
                            fullWidth
                            id="outlined-select-repLegalCity"
                            select
                            name="repLegalCity"
                            label={formValues.repLegalCity === 0 ? "Seleccione" : ""}
                            value={formValues.repLegalCity === 0 ? "" : formValues.repLegalCity}
                            onChange={registerContext.handleChange}                            
                            InputLabelProps={{ shrink: false }}
                            margin="none"
                            variant="outlined"
                            disabled={registerContext.contactCitiesArray && registerContext.contactCitiesArray.length>0 ? false : true}
                        >
                            {registerContext.contactCitiesArray && registerContext.contactCitiesArray.length > 0 ?
                                registerContext.contactCitiesArray.map((option: IGeneralParameter) => (
                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                        {option.name}
                                    </MenuItem>
                                ))
                                :
                                <MenuItem value={formValues.repLegalCity}></MenuItem>
                            }
                        </TextField>
                    </Grid>
                </Grid>

            </Grid>
            <div style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}>
                <Button
                    variant="contained"
                    className="accordion-btn"
                    endIcon={<ExpandMoreIcon />}
                    disabled={!isValid}
                    onClick={isValid ? (isValidFirstStep ? registerContext.handleChangeAccordion('panel2', false, 'panel2') : registerContext.handleChangeAccordion('panel2', true, 'panel2')) : null}
                >
                    Terminar
                </Button>
            </div>
        </>
    )
}

export default RepresentativeForm;