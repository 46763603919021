import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import UserContext from '../../../utils/UserContext';
import SearchTable from '../../ManagementState/SearchTable';
import { containsAny } from '../../../helpers/sharedFunctions';

import './OfferListFilters.scss';


const initialErrorsSearch={
    searched:''
}

interface OffersListFiltersProps{
    filterOrder:any;
    searched?: string;
    requestSearch?:any;
    cancelSearch?: any;
    searchActive?:boolean;
    bgColor: string;
    colorText?: string;
}

const OffersListFilters: React.FC<OffersListFiltersProps> = ({filterOrder, searched, requestSearch, cancelSearch, searchActive, bgColor, colorText})=>{
    const userContext = React.useContext(UserContext);
    const formErrorSearch = initialErrorsSearch;
    const [orderSalary,setOrderSalary]=useState<number>(1);
    const [orderDate,setOrderDate]=useState<number>(1);
    const [icon, setIcon] = useState(<UnfoldMoreIcon />);
    const [iconReciente, setIconReciente] = useState(<UnfoldMoreIcon />);

    const changeIcon = (e:any, action:string)=>{
        if(action === 'minValueSalary'){
            setIconReciente(<UnfoldMoreIcon/>);
            if(orderSalary === 1){
                setOrderSalary(2);
                setIcon(<ExpandLessIcon/>);
                filterOrder(2, action);
            } else if (orderSalary === 2){
                setOrderSalary(3);
                filterOrder(3, action);
                setIcon(<ExpandMoreIcon/>)
            } else if (orderSalary === 3){
                setOrderSalary(1);
                filterOrder(1, action);
                setIcon(<UnfoldMoreIcon/>);
            }
        } else if (action === 'jobOfferStartDate'){
            setIcon(<UnfoldMoreIcon/>);
            if(orderDate === 1){
                setOrderDate(2);
                filterOrder(2, action);
                setIconReciente(<ExpandLessIcon/>);
            } else if (orderDate === 2){
                setOrderDate(3);
                filterOrder(3, action);
                setIconReciente(<ExpandMoreIcon/>)
            } else if (orderDate === 3){
                setOrderDate(1);
                filterOrder(1, action);
                setIconReciente(<UnfoldMoreIcon/>);
            }
        }
    }

    return(
        <Grid container item xs={12} className="filter-box" style={{backgroundColor:bgColor?bgColor:'#004B73'}}>

            <div className="container-buttons">
                <span className="order-text" style={{color:colorText?colorText:'white'}}>
                    Ordenar por:
                </span>
                <div className="order-buttons-container">
                    <Button className="order-btn" onClick={(e)=>changeIcon(e, 'minValueSalary')} startIcon={<AttachMoneyIcon />} endIcon={icon}>
                        <Typography variant="h6" className="light-serif">
                            Salario
                        </Typography>
                    </Button>
                    <Button className="order-btn" onClick={(e)=>changeIcon(e, 'jobOfferStartDate')} startIcon={<CalendarTodayIcon />} endIcon={iconReciente}>
                        <Typography variant="h6" className="light-serif">
                            Reciente
                        </Typography>
                    </Button>
                </div>
            </div>
            {containsAny([2,3],[userContext.roleSelected]) &&
                <div className="search-box">
                    <SearchTable textPlaceHolder="Buscar por cargo o profesión" formErrorSearch={formErrorSearch} searched={searched} title="" requestSearch={requestSearch} searchActive={searchActive} cancelSearch={cancelSearch}/>
                </div>
            }

        </Grid>
    )
}

export default OffersListFilters