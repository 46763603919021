import React from 'react';
import RegisterContext from '../../../utils/RegisterContext';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Grid from "@material-ui/core/Grid"
import { IRegisterGraduate } from '../../../interfaces/IRegisterGraduate';
import Link from '@material-ui/core/Link';


const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className="policy-dialog-header" {...other}>
        <Typography variant="h2" className="bold-serif-primaryColor" style={{marginRight:'16px'}}>{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

const BlueCheckbox = withStyles({
    root: {
      '&$checked': {
        color: '#14A5DC',
      },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface JobBankModalProps{
  role: string;
}

const JobBankModal: React.FC<JobBankModalProps> = ({role})=>{
    const registerContext = React.useContext(role === 'graduate' ? RegisterContext : RegisterContext);
    const formValues: IRegisterGraduate = registerContext.formValues;
    return(
        <Dialog onClose={registerContext.handleCloseJobModal} aria-labelledby="customized-dialog-title" open={registerContext.openJobModal} className="policy-dialog-container">
            <DialogTitle id="customized-dialog-title" onClose={registerContext.handleCloseJobModal}>
              Bolsa de empleo SIE
            </DialogTitle>
            <DialogContent className="policy-dialog-content">
              <Typography variant="h3" className="bold-serif" style={{marginRight:'16px'}}>Terminos de Uso de la Bolsa de Empleo</Typography>
                <div className="policy-text-contentJobBank">
                  <div>
                  <Typography variant="h6" className="text-content-dialog">
                    El acceso y uso del aplicativo del Sistema de Informacion del Programa de Egresados (SIE) está sujeto a los siguientes "Términos y Condiciones de Uso". Para hacer uso de este servicio, esnecesario leer atentamente estas condiciones y aceptarlas diligenciando la casilla "He leído y acepto los términos y condiciones de la bolsa de empleo”, y haciendo clic en el botón "Acepto”. El ingreso a la bolsa de empleo es {role === 'graduate' ? 'opcional': 'obligatorio'}.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      Al utilizar este sitio web, también se declara la aceptación del tratamiento de la información cargada en él con el propósito de que sea compartida y circulada para fines de intermediación laboral, tal como se detalla más adelante en los alcances de dicha intermediación.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      El Programa de Egresados se reserva el derecho de revisar y actualizar los Términos y Condiciones de Uso en cualquier momento. Por lo tanto, se recomienda visitar esta página cada vez que se acceda al sitio para revisar los Términos y Condiciones de Uso, ya que estos son vinculantes.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog text-dialog-title'>
                      1. DEFINICIONES PREVIAS.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>1.1. SIE.</span> Es el Sistema de Información de Egresados de la Universidad Nacional de Colombia, donde se realiza el registro de egresados y empresas, se administra la información de los egresados de la Universidad, se generan reportes de las empresas y los egresados y se administra el módulo de la bolsa de empleo para los egresados de la Universidad.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>1.2.	SPE.</span> Es el Servicio Público de Empleo de Colombia, el cual tiene por función esencial lograr la mejor organización posible del mercado de trabajo, para lo cual ayudará a los trabajadores a encontrar un empleo conveniente, y a los empleadores a contratar trabajadores apropiados a las necesidades de las empresas. Está regulado por el Ministerio del Trabajo y administrado por la Unidad Administrativa Especial del Servicio Público de Empleo. El SPE está conformado por todos los prestadores públicos y privados que desarrollen actividades de gestión y colocación de empleo, que hayan sido autorizados previamente.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>1.3.	Usuario.</span> Se refiere a todas las personas naturales o jurídicas o entidades activos en el SIE, que por cualquier razón accedan o usen este aplicativo.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>1.4.	Buscador de empleo.</span> Comunidad egresada de la Universidad Nacional de Colombia que se encuentra en condiciones de trabajar y está en un proceso de búsqueda de empleo (desempleados o trabajadores en búsqueda de mejores condiciones laborales).
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>1.5. Demandante.</span> Personas naturales o jurídicas que se encuentran en el proceso de búsqueda o demanda de fuerza de trabajo.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>1.6. Vacantes.</span> Puesto de trabajo no ocupado, para el cual el empleador toma medidas activas, con el objetivo de encontrar el candidato idóneo para cubrirlo.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>1.7. Candidatos.</span> Son los buscadores de empleo que cumplen con el perfil de una vacante y se han postulado directamente a la misma o que han sido preseleccionados por un prestador para ser remitidos a optar por dicha vacante.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>1.8. Prestador.</span> Personas jurídicas de derecho público o privado autorizadas por la autoridad competente para prestar servicios de gestión y colocación. La prestación del servicio puede ser de manera presencial o virtual o ambas. Son prestadores del Servicio Público de Empleo la Agencia Pública de Empleo a cargo del Servicio Nacional de Aprendizaje SENA, las Agencias Públicas y Privadas de Gestión y Colocación de Empleo, incluidas las constituidas por las Cajas de Compensación Familiar y las Bolsas de Empleo.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog text-dialog-title'>
                      2. NORMAS DE USO GENERAL
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>2.1. Inscripción.</span> Corresponde al proceso mediante el cual el buscador de empleo o el demandante de mano de obra, a través del SIE, registran su información en el Sistema de Información, con el fin de acceder a los servicios de gestión y colocación de empleo.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>2.2. Postulación.</span> Es el proceso que queda registrado en el SIE mediante el cual los buscadores de empleo se postulan a vacantes que se ajustan a sus perfiles y mediante el proceso de preselección automática realizada por el SIE, se realiza una búsqueda entre los buscadores inscritos, de aquellos que cumplan con el perfil requerido por el empleador y proceden a la remisión de su hoja de vida, para que sea el empleador quien decida el proceso de reclutamiento.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>2.3. Vinculación laboral.</span> Los candidatos deben tener en cuenta que el hecho de postularse o ser remitidos a un proceso de preselección, no garantiza su contratación. Dicha decisión le corresponde exclusivamente al empresario.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog text-dialog-title'>
                      3. OBLIGACIONES DEL USUARIO
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>3.1. Responsabilidad.</span> Los usuarios en general son responsables de mantener la confidencialidad, custodia y buen uso de sus datos, de su contraseña, así como de toda la información que conozcan a través del aplicativo del SIE. Serán así mismo, responsables de todos los usos de su registro. En caso de olvido, manipulación, inactivación o sospecha de fraude o utilización indebida, los usuarios deberán notificar inmediatamente al Programa de Egresados cualquier uso sin autorización de su registro o contraseña. Igualmente, serán responsables de todos los envíos transmitidos a través de su cuenta y clave privada de acceso, los cuales se supondrán emitidos por el usuario.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>3.2. Veracidad y confiabilidad de la información.</span> Será responsabilidad de los usuarios registrar información veraz, seria y verificable en los campos correspondientes del aplicativo, bien sea información personal, empresarial o referente a las vacantes, según sea el caso.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>3.3. Actualización de la información.</span> Los usuarios deberán mantener actualizada la información de sus registros y transacciones correspondientes.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>3.4. Racionalidad.</span> La plataforma informática -SIE, corresponde a un bien público, por esta razón todos los usuarios se comprometen a darle un uso racional a la página y los servicios que de allí se deriven, observando la ley y las buenas costumbres, el orden público y la moral.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>3.5. Uso ilegal.</span> Los usuarios no podrán utilizar ésta página web para la transmisión, publicación o comunicación de cualquier tipo de información diferente a la requerida en este portal, ilegal, dañina u objetable, que contraríe la Constitución Política de Colombia, las leyes y las buenas costumbres. El Programa de Egresados se reserva el derecho de determinar el uso ilegal u objetable que del aplicativo se haga y, en consecuencia, podrá tomar las medidas legales y administrativas que considere convenientes.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>3.6. Publicidad.</span> La presente página constituye un bien público, por lo tanto, está prohibido utilizar esta página para realizar cualquier tipo de publicidad comercial.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>3.7. Daños y perjuicios.</span> Cada usuario deberá responder y asumir los daños y perjuicios de toda naturaleza que la página de Internet del Programa de Egresados pueda sufrir, directa o indirectamente, como consecuencia del incumplimiento de cualquiera de las obligaciones derivadas de los presentes términos y condiciones o acciones contrarias a las leyes en relación con la utilización de la misma. Ningún usuario podrá tomar acciones que entrañen riesgo, daño o inutilización de esta página o de los Servicios y/o del contenido que de ella se deriven.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>3.8. Derechos reservados.</span> Los usuarios deberán respetar los emblemas, marcas comerciales registradas, y demás objetos de propiedad intelectual pertenecientes al Programa de Egresados. Los derechos de autor sobre las páginas, las imágenes y en general todo lo que aparece en pantalla, así como sobre la información y el material contenidos en las mismas, son propiedad del Programa de Egresados. En este sentido, queda prohibido a todos los usuarios que por sí mismos o por interpuesta persona accedan al sistema con el fin de reproducir, retransmitir, distribuir, vender, publicar, divulgar, circular o comercializar, y en general, disponer por cualquier medio de la información allí contenida, total o parcialmente.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>3.9. Ingreso ilegal.</span> Todo usuario y el público en general, deberán abstenerse de ingresar ilegalmente a la página, hackear o piratear alguno de sus contenidos, así como intentar obtener información, mensajes, gráficos, dibujos y/o imágenes, fotografías, grabaciones, software y, en general, cualquier clase de material accesible a través de ésta página.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>3.10. Seguridad.</span> Se prohíbe a los usuarios violaro intentar violar la seguridad del aplicativo SIE, incluyendo pero no limitándose a:
                    </Typography>
                    <ul className='text-content-dialog'>
                        <li>a) Acceder a datos, cuentas o servidores no autorizados.</li>
                        <li>b) Evaluar o probar la vulnerabilidad de un sistema o red.</li>
                        <li>c) Intentar impedir el servicio a cualquier usuario, anfitrión o red, mediante el envío de virus al Sitio Web, o mediante saturación, envíos masivos ("flooding"), "spamming"  bombardeo de correo o bloqueos del sistema ("crashing").</li>
                        <li>d) Enviar correos no pedidos, incluyendo promociones y/o publicidad de productos o servicios.</li>
                        <li>e) Utilizar la información consignada en las bases de datos del sistema para fines diferentes a procesos de intermediación laboral, así como intentar tomar información personal de los usuarios para contactarlos, suplantar el nombre del Programa de Egresados.</li>
                        <li>f) Utilizar el Sistema para obtener cualquier clase de beneficio, sin seguir los protocolos establecidos para la gestión y colocación de empleo.</li>
                        <li>g) Falsificar cualquier cabecera de paquete TCP/IP o cualquier parte de la información de la cabecera de cualquier correo electrónico o en mensajes de foros de debate.</li>
                        <li>h) Borrar, adulterar, filtrar, deshabilitar o revisar cualquier material e información anunciado por otra persona o entidad.</li>
                        <li>i) El Programa de Egresados se reserva el derecho de retirar o solicitar que sea excluida información que no cumpla con las obligaciones de los usuarios, así como podrá decidir la suspensión o la des-habilitación definitiva del usuario infractor, siendo siempre obligación del Programa de poner en conocimiento de las autoridades judiciales y administrativas competentes los hechos considerados como posibles faltas disciplinarias o punibles penalmente.</li>
                    </ul>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>3.11. Habeas Data o Protección de datos personales.</span> Cuando usted se registra en este aplicativo se le solicitará que suministre al Programa de Egresados información para efectos de prestar servicios de gestión y colocación de empleo. En adición a los términos y condiciones que puedan ser previstos en las políticas de privacidad que se refieren en este aplicativo, usted entiende y acepta que el Programa de Egresados comparta y permita consultar sus datos personales a terceras personas con el fin de prestar los servicios de gestión y colocación de empleo.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      Los empleadores podrán preservar su confidencialidad en los términos previstos en el parágrafo del artículo 3 0 de la Resolución 2605 de 2014 del Ministerio del Trabajo.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>3.12. Reserva estadística.</span> Además del fin previsto en el anterior numeral, los datos consignados en este aplicativo solo podrán ser usados para realizar análisis del mercado laboral, conservando el respectivo anonimato del usuario o empresa. No podrán ser usados para fines tributarios, fiscales o afines.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog text-dialog-title'>
                      4. CONDICIONES ESPECIALES DE USO
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>4.1. Títulos.</span> Los títulos, encabezados o similares que se utilizan en la página de Internet, tienen como único propósito el servir de guía o ayuda al usuario.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>4.2. Modificación de condiciones.</span> El Programa de Egresados podrá actualizar o modificar las condiciones y avisos de los servicios de su página web periódicamente, para reflejar cambios en el servicio, cambios en la normativa que puedan influir en la prestación de los servicios o por cualquier otro motivo. Es responsabilidad del usuario comprobar las condiciones y avisos periódicamente. El uso de los servicios a través de la clave privada de acceso, después de que se haya llevado a cabo cualquier modificación, implicará la aceptación de dicho cambio. A menos que se indique explícitamente lo contrario, cualquier característica nueva que modifique o mejore los servicios web estará sujeta a las condiciones de uso y a sus modificaciones.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>4.3. Interrupción del servicio</span> El Programa de Egresados podrá interrumpir discrecionalmente, en cualquier momento y sin necesidad de previo aviso, el acceso a los servicios de la página web o a una parte de los mismos.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog text-dialog-title'>
                      5. LIMITACIÓN Y EXONERACIÓN DE RESPONSABILIDAD DEL SERVICIOPÚBLICO DE EMPLEO
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>5.1. Conectividad y uso.</span> En ningún caso el Programa de Egresados será responsable por daños directos, indirectos, especiales, o accidentales, ni por las pérdidas o gastos que pudiesen surgir por conectarse a la página web o a partir del uso que se haga del sitio, o por la incapacidad para hacer uso del sitio por parte de cualquier persona, o por cualquier falla en la ejecución, error, omisión, interrupción, defecto, demora en la transmisión o en la conexión, o por la existencia de un virus en la computadora o por fallas del sistema o la línea, o por su suspensión o terminación, aun cuando El Programa de Egresados o sus representantes se encuentren notificados sobre la posibilidad de tales daños, pérdidas o gastos.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>5.2. Interconectividad con otras páginas.</span> Esta página contiene links o puede generar conexiones a otras direcciones en Internet. El Programa de Egresados no tiene ningún tipo de control sobre el contenido de estas páginas, por lo tanto no es responsable del contenido o políticas que contengan, ni de los daños que su acceso ocasione al usuario.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>5.3. Daños externos</span> El Programa de Egresados se declara ajena a cualquier responsabilidad por daños que llegare a sufrir el hardware y el software, o cualquier aparato electrónico a través del cual se accede al sistema de operación. Igualmente, no tendrá ninguna responsabilidad por las interrupciones en la transmisión, errores técnicos o de fallas o demoras en el desempeño de la página, cualquier clase de virus o demoras en el proceso transaccional.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>5.4. Presencia de virus.</span> El Programa de Egresados no controla ni garantiza la ausencia de virus ni de otros elementos en los contenidos que puedan producir alteraciones en el sistema informático (software y hardware) o en los documentos electrónicos y archivos almacenados en el sistema informático del usuario. El Programa de Egresados no asume responsabilidad alguna por los daños y perjuicios de cualquier naturaleza que puedan derivarse de la presencia de virus o la presencia de otros elementos en los contenidos que puedan producir alteraciones en el sistema informático, documentos electrónicos o archivos del usuario.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      <span className='text-dialog-title'>5.5. Otras exoneraciones.</span> El Programa de Egresados no asume responsabilidad alguna por los daños y perjuicios de cualquier naturaleza que puedan derivarse de la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso al contenido de la página internet, y en particular, aunque no de modo exclusivo, por los daños y perjuicios que puedan ocasionarse por:
                    </Typography>
                    <ul className='text-content-dialog'>
                        <li>a) El incumplimiento de la ley, la moral y las buenas costumbres generalmente aceptadas o el orden público como consecuencia de la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso al contenido de la página.</li>
                        <li>b) La infracción de los derechos de propiedad intelectual e industrial, de los secretos empresariales, de compromisos contractuales de cualquier índole, de los derechos al honor, a la intimidad personal y familiar y a la imagen de las personas, de los derechos de propiedad y de toda otra naturaleza pertenecientes a un tercero como consecuencia de la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso al contenido de la página.</li>
                        <li>c) La realización de actos de competencia desleal y publicidad ilícita como consecuencia de la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso al contenido de la página.</li>
                        <li>d) La falta de exhaustividad, pertinencia y/o actualidad del contenido de la página.</li>
                        <li>e) El hecho de que las expectativas generadas por el contenido de la página no se consideren adecuadas para algún propósito.</li>
                        <li>f) El incumplimiento, retraso en el cumplimiento, cumplimiento defectuoso o terminación por cualquier causa de las obligaciones contraídas con terceros a través de o con motivo del acceso al contenido de la página.</li>
                    </ul>
                    <Typography variant='h6' className='text-content-dialog text-dialog-title'>
                      6. DISPOSICIONES FINALES
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      La violación de cualquiera de los términos y condiciones de uso de esta plataforma, puede acarrear responsabilidades civiles, penales y/o disciplinarias según corresponda. El Programa de Egresados pondrá en conocimiento de las autoridades competentes cualquier irregularidad que ponga en riesgo el funcionamiento de esta plataforma, con el fin que se adelanten las investigaciones y acciones legales del caso. Los presentes Términos y Condiciones se rigen por las leyes colombianas. La jurisdicción para cualquier reclamación que surja de estos Términos y Condiciones será exclusivamente la de los tribunales y jueces de la República de Colombia. Si algún aparte de estos Términos y Condiciones es declarada nula o inválida o ineficaz, ello no afectará la validez del contenido restante de estos Términos y Condiciones.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog text-dialog-title'>
                      7. AVISO DE AUTORIZACIÓN PARA TRATAMIENTO DE DATOS PERSONALES
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      Debido a que la prestación de los servicios de gestión y colocación requieren del suministro de información, de conformidad con lo dispuesto por la Ley 1636 de 2013 y el Decreto 2852 de 2013, de manera voluntaria, previa, explícita, informada e inequívoca, otorgo consentimiento en los términos establecidos por la Ley Estatutaria 1581 de 2012 y reglamentada por el Decreto 1377 de 2013 para que se efectúe el tratamiento de la información suministrada para el fin exclusivo de realizar las labores de intermediación laboral, análisis ocupacional en mi favor y demás servicios a los que pueda tener acceso, de conformidad con los criterios definidos por el Programa de Egresados. El manejo de mi información igualmente lo autorizo para que se realice según los términos y exigencias de la Ley 1636 y el Decreto 2852 de 2013.
                    </Typography>
                    <Typography variant='h6' className='text-content-dialog'>
                      De esta forma, reconozco que la información por mi suministrada estará a disposición, para los mismos fines y con las mismas restricciones, teniendo derecho entre otros a conocer, actualizar, rectificar o revocar la autorización de la información o datos proporcionados para esta actividad conforme a la Ley Estatutaria antes señalada. 
                    </Typography>
                  </div>
                </div>
            </DialogContent>
            <DialogActions className="policy-dialog-actions">
                <Grid container item xs={12} justify="space-between">
                    <Grid container item xs={12} sm={9}>
                        <FormControlLabel
                            control={<BlueCheckbox checked={formValues.inJobBank ? true : false} onChange={registerContext.handleJobBank} name="checked" />}
                            label={<Typography className="check-dialog">He leído las políticas y deseo estar en la bolsa de empleo</Typography>}
                        />
                    </Grid>
                    <Grid container item xs={12} sm={3}>
                        <div style={{width: '100%'}}>
                            <Button disabled={ role === 'company' && !formValues.inJobBank} onClick={registerContext.handleNext} className="policy-button">
                              <Typography variant="h4" className="regular-serif">
                                Continuar
                              </Typography>
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default JobBankModal;