import React from 'react';
import Grid from "@material-ui/core/Grid";
import { Redirect } from 'react-router';
import ReportFormCompany from '../../../../components/Reports/ReportFormCompany';
import { isLogin } from '../../../../services/AuthService';
import UserContext from '../../../../utils/UserContext';
import { containsAny } from '../../../../helpers/sharedFunctions';
import { permissionAdminCompanyReportsView } from '../../../../utils/PermissionsVariables';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';

const ReportsCompany: React.FC = () => {
  const userContext = React.useContext(UserContext);

  return (
    <>
    {isLogin() ?
      <>
      {containsAny(permissionAdminCompanyReportsView, userContext.userPermissions) ?
        <Grid container>
          <ReportFormCompany/>
        </Grid>
      :
        <NotAuthorized/>
      }
      </>
    :
      <Redirect to ="/"/>
    }
    </>
  )
}

export default ReportsCompany
