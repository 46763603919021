import React from 'react';
import HeaderBox from '../../components/ShareComponents/HeaderBox';
import UnalFooter from '../UnalFooter/UnalFooter';

const LandingLayout: React.FC = ({children})=>{
    return(
        <>
            <HeaderBox/>
            <div className="general-container">
                {children}
            </div>
            <UnalFooter />
        </>
    )
}

export default LandingLayout