import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import DescriptionIcon from '@material-ui/icons/Description';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import { IGetSurveys } from '../../../../interfaces/IGetSurveys';
import SurveyForm from '../../../../components/Surveys/SurveyForm';
import { getSurveys } from '../../../../services/SurveyServices';
import './SurveyFormPage.scss';
import UserContext from '../../../../utils/UserContext';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import { permissionAdminCreateSurvey, permissionAdminModifySurvey } from '../../../../utils/PermissionsVariables';
import { containsAny } from '../../../../helpers/sharedFunctions';


const SurveyFormPage: React.FC = () => {
  let params: any = useParams();
  const userContext = React.useContext(UserContext);
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [action, setAction] = useState<string>();
  const [surveySelected, setSurveySelected] = useState<IGetSurveys>(new IGetSurveys());

  useEffect(()=>{
    window.scrollTo(0, 0);
    const urlPath = window.location.href;
    if(urlPath.includes('crear')){
        setAction("Create")
    } else if (urlPath.includes('editar')){
        setAction("Edit")
    }
    const getDetailData = async()=>{
      if(params.id){
          const responseDetail: Response = await getSurveys({id:parseInt(params.id)});
          if(responseDetail.status === 200){
              const resultDetail = (await responseDetail.json()).message[0];
              setSurveySelected(resultDetail);
          }
      }
      setInitLoad(false)
    }
    getDetailData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <>
      <Grid container item xs={12} className="back-offers-list-section">
        <ButtonBackListOffers url="/admin/dashboard/encuestas" text="Regresar al listado de encuestas"/>
      </Grid>
      <Grid container className="main-container">
        <Grid container item xs={12} className="surveys-container">
          <Grid container item xs={12} md={3} lg={3}>
            <div className="title-section-line">
              <DescriptionIcon className="group-icon"/>
              <Typography variant="h2" className="surveys-title" >
                  {action !== "Edit"? "Nueva encuesta" : "Editar encuesta"}
              </Typography>
            </div>
          </Grid>
          <Grid container item xs={12} md={9} lg={9} className="line" />
          <Grid container >
            {action === "Edit"?
              <>
              {containsAny(permissionAdminModifySurvey,userContext.userPermissions) ?
                <SurveyForm
                  action={action}
                  surveySelected={surveySelected}
                />
              :
                <NotAuthorized/>
              }
              </>
            :
              <>
              {containsAny(permissionAdminCreateSurvey,userContext.userPermissions) ?
                <SurveyForm
                  action="Create"
                />
              :
                <NotAuthorized/>
              }
              </>
            }
          </Grid>
        </Grid>
      </Grid>
      <LoadingLockPage load={initLoad}/>
    </>
  )
}

export default SurveyFormPage
