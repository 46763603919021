import jwt from 'jsonwebtoken';
import { IAuth } from "../interfaces/IAuth";
import { IChangePassword } from '../interfaces/IChangePassword';

const baseUrl = process.env.REACT_APP_SIE_APP_PROXY_BASE_URL;
const TOKEN_KEY = "Bearer";

export const login = async (bodyRequest: object) => {
  try {
    const response = await fetch(`${baseUrl}/security/login/`, {
      method: "POST",
      body: JSON.stringify(bodyRequest),
    });
    if (response.status === 200) {
      const result: IAuth = await response.json();
      if(result.token){
        let token ='';
        token = result ? result.token : '';
        const temp = jwt.sign({ roleSelected: 0, temp: result.temporaryKey ? result.temporaryKey : false}, '1234@#');
        sessionStorage.setItem('temp', temp)
        sessionStorage.setItem(TOKEN_KEY, token);
        return result;
      }
    }
     else if (response.status === 404){
      const resultError: IAuth = await response.json();
      return resultError;
    } else if (response.status === 400){
      const result = await response.json();
      if(result){
        return result;
      }
    }
  } catch (error) {
    return error
  }
};

export const logout = () => {
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem('temp');
  sessionStorage.removeItem('Bearer');
  sessionStorage.removeItem('eventAnonimous');
  sessionStorage.removeItem('offerAnonimous');
  sessionStorage.removeItem('surveyAnonimous');
};

export const isLogin = () => {
  if (sessionStorage.getItem(TOKEN_KEY)) {
    return true;
  }
  return false;
};

export const registerGraduate = async (bodyRequest?: object) => {
  try {
    const response = await fetch(
      `${baseUrl}/graduate/register`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify(bodyRequest)
      }
    )
    return response
  } catch (error) {
    return error
  }
};

export const registerCompany = async (bodyRequest?: object) => {
  try {
    const response = await fetch(
      `${baseUrl}/company/register`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify(bodyRequest)
      }
    )
    return response
  } catch (error) {
    return error
  }
};

interface sendPassword{
  message:string
}

export const recoverPassword = async (bodyRequest?: object) => {
  try {
    const response = await fetch(
      `${baseUrl}/security/sendPass`, {
        method: 'POST',
        body:JSON.stringify(bodyRequest)
      }
    )
    if (response.status === 200) {
      const result:sendPassword = await response.json();
      return result
    }
  } catch (error) {
      return error
  }
}

export const changePassword = async (bodyRequest?: object) => {
  let token = "";
  if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
  }
  try {
    const response = await fetch(
      `${baseUrl}/security/changePass`, {
        method: 'POST',
        body:JSON.stringify(bodyRequest),
        headers:{
          'sie_token': token
        }
      }
    )
    if (response.status === 200) {
      const result: IChangePassword = await response.json();
      return result
    }
  } catch (error) {
      return error
  }
}