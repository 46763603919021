import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import { IRole } from '../../../../interfaces/IRole';
import RolesTable from '../../../../components/RolesAdmin/RolesTable';
import { containsAny, sortByKey } from '../../../../helpers/sharedFunctions';
import { getlistRoles } from '../../../../services/AdminServices';
import UserContext from '../../../../utils/UserContext';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import { permissionAdminRolesPermissionsView } from '../../../../utils/PermissionsVariables';

const RolesManagement:React.FC = () =>{
    const userContext = React.useContext(UserContext);
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [rolesArray, setRolesArray] = useState<Array<IRole>>([]);
    const [url, setUrl] = useState<string>("");

    useEffect(()=>{
        window.scrollTo(0, 0);
        const getInitData = async()=>{
            const responseRoles = await getlistRoles();
            let resultListRoles: Array<IRole> = [];
            if(responseRoles.status === 200){
                resultListRoles = (await responseRoles.json()).rolesInfo;
                resultListRoles = sortByKey(resultListRoles, 'name');
                setRolesArray(resultListRoles);
            }
            setInitLoad(false);
        }
        getInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleRowRole = (rolSelected: IRole) => {
        setUrl(`/admin/dashboard/permisos/${rolSelected.id}`)
    }

    return(
        <>
            {
                url.length>0 ?
                <Redirect to={url}/>
            :
                <>
                    <Grid container className="main-container">
                        <Grid item xs={12} className="roles-management-container-filters">
                            <Grid container item xs={12}>
                                <Typography variant="h5" className="tab-description">
                                    En esta interfaz podra gestionar los permisos de acuerdo al rol seleccionado.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className="main-container">
                        <RolesTable rows={rolesArray} handleRowRole={handleRowRole}/>
                    </Grid>
                    <LoadingLockPage load={initLoad}/>
                </>
            }
        </>
    )
}

export default RolesManagement