import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import WorkIcon from '@material-ui/icons/Work';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { IFilterNewsEvents } from '../../../interfaces/IFilterNewsEvents';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import './NewsEventsSearch.scss';

interface NewsEventsSearchProps {
    type?: string;
    filterValues: IFilterNewsEvents;
    formErrors: any;
    onChangeFilters: any;
    handleChangeDate: any;
    searchActive?: boolean;
    cancelSearch?: any;
    categoryArray: Array<IGeneralParameter>;
    isValid?: boolean;
    applyFilters: any;
    clearFilter: any;
}

const NewsEventsSearch:React.FC<NewsEventsSearchProps> = ({type, filterValues, formErrors, onChangeFilters, handleChangeDate, searchActive, cancelSearch, categoryArray, isValid, clearFilter, applyFilters}) => {
    return (
        <Grid container item xs={12} className="news-events-search-container">
            <Grid item xs={12}>
                <Typography variant="h2" className="news-events-search-title">
                    Buscar <span>{type}</span>
                </Typography>
            </Grid>
            <Grid container item xs={12} className="news-events-search-boxes">
             <Grid container item xs={12} md={12} className="news-events-boxes">
                <Grid item xs={12} md={5} className="special-column-right">
                    <TextField
                        fullWidth
                        name="keyWord"
                        margin="none"
                        autoComplete ="off"
                        placeholder={type==="eventos"?"Palabra clave, nombre del evento": "Palabra clave, nombre de la noticia"}
                        value={filterValues.keyWord || ""}
                        onChange={onChangeFilters}
                        error={!!formErrors.keyWord}
                        helperText={formErrors.keyWord}
                        variant="outlined"
                        InputLabelProps={{shrink: false}}
                        InputProps={{
                            style: {
                                background: "white"
                            },
                            startAdornment: (
                            <InputAdornment position="start">
                                    <WorkIcon fontSize="small" color="disabled"/>
                            </InputAdornment>
                            ),
                            endAdornment: searchActive ? (
                                <InputAdornment position="end">
                                    <IconButton size="small" onClick={cancelSearch}>
                                        <CloseIcon fontSize="small"/>
                                    </IconButton>
                                </InputAdornment>
                            ) : null
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4} className="search-box-margin">
                    <TextField
                        fullWidth
                        id="outlined-select-category"
                        select
                        name="category"
                        label={filterValues.category === 0 ? "Categoria": ""}
                        value={filterValues.category === 0 ? "" : filterValues.category}
                        onChange={onChangeFilters}
                        InputLabelProps={{shrink: false}}
                        margin="none"
                        variant="outlined"
                        InputProps={{
                            style: {
                                background: "white"
                            }
                        }}
                    >
                        {
                            categoryArray && categoryArray.length> 0 &&
                            <MenuItem value={0}><em>Ninguno</em></MenuItem>
                        }
                        {categoryArray && categoryArray.length>0 ?
                            categoryArray.map((option: IGeneralParameter) => (
                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                    {option.name}
                                </MenuItem>
                            ))
                        :
                            <MenuItem value={filterValues.category}></MenuItem>
                        }
                    </TextField>
                </Grid>
                <Grid item xs={12} md={3} className="special-column-left">
                    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                        <DatePicker
                            openTo="year"
                            views={['year', 'month', 'date']}
                            value={filterValues.date || null}
                            onChange={(value)=>handleChangeDate(value, 'date')}
                            InputProps={{
                                style:{
                                    background: "white"
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    disabled
                                    {...params}
                                    fullWidth
                                    name="jobOfferStartDate"
                                    margin="none"
                                    label={(filterValues.date === null || filterValues.date === "") ? "dd/mm/aaaa" : ""}
                                    error={!!formErrors.date}
                                    helperText={formErrors.date}
                                    InputLabelProps={{shrink: false}}
                                    variant="outlined"
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                </Grid>
                <Grid container item xs={12} className="filter-buttons-offers">
                    <Button disabled={!isValid} className="search-button" onClick={applyFilters}>
                        <Typography variant="h4" className="regular-serif">
                            Buscar
                        </Typography>
                        <SearchIcon/>
                    </Button>
                    <Button disabled={!isValid} className="clear-filter-button" onClick={clearFilter}>
                        <Typography variant="h4" className="regular-serif">
                            Borrar filtros
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NewsEventsSearch;
