import React from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import './CauseOffers.scss';

interface CauseOffersProps{
    causeSelected:number;
    causes:Array<IGeneralParameter>;
    handleCause:any;
}

const CauseOffers: React.FC<CauseOffersProps> = ({causeSelected, causes, handleCause}) =>{

    return(
        <Grid container item xs={12} className="Cause-offers-container">
            {
                causes.length > 0 &&
                <Grid item xs={12} sm={6} className="field-box-margin">
                    <Typography className="field-box-title">Seleccione una causal de finalización*:</Typography>
                    <TextField
                        fullWidth
                        id="outlined-select-causeSelected"
                        select
                        name="causeSelected"
                        label={causeSelected === 0 ? "Seleccione": ""}
                        value={causeSelected === 0 ? "" : causeSelected}
                        onChange={handleCause}
                        InputLabelProps={{shrink: false}}
                        margin="none"
                        variant="outlined"
                    >
                        {
                        causes.length>0 ?
                        causes.map((option: IGeneralParameter) => (
                            <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                {option.name}
                            </MenuItem>
                        ))
                        :
                        <MenuItem></MenuItem>
                        }
                    </TextField>
                </Grid>
            }
        </Grid>
    )
}

export default CauseOffers;