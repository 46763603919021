const baseUrl = process.env.REACT_APP_SIE_APP_PROXY_BASE_URL;
const TOKEN_KEY = "Bearer";
// test url

//OFertas
export const reportNBC = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/offers-with-most-requested-nbc' )
}

export const reportComparativeJobOffers = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/comparative_jobOffers_report' )
}

export const reportTotalJobOfferByPosition = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/total_jobOffer_according_to_the_position_report' )
}

export const reportTotalOffersBySalary = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/total_jobOffers_by_salary_report' )
}

export const reportOfferTransmition = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/offer_transmition' )
}

export const reportOfferTransmitionSem = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/offer_transmition_sem' )
}
export const reportVacancies = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/vacancies_transmission' )
}
export const reportGeneralVacancies = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/general_vacancies' )
}

//Egresados
export const reportSalaryByProgram = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/salary-earned-by-program' )
}

export const reportGraduatesByProgramAndGender = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/graduates-by-program-and-gender' )
}

export const reportGraduatesAssociationsByProgram = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/graduates_who_belongs_to_associations_report' )
}

export const reportLinkedByJobOffer = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/graduate_linked_by_job_offers_report' )
}

export const reportCurrentWorkLocation = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/job-placement-by-program' )
}

export const reportAgeRangeAndGender = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/graduates_by_age_gender_group_by_curriculum' )
}

export const reportEmploymentModality  = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/graduates_by_type_contract_group_by_curriculum' )
}

export const reportPerformanceArea  = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/graduate_by_performance_area_group_by_curriculum' )
}

export const reportCompanyType  = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/graduate_by_company_type_group_by_curriculum' )
}

export const reportGraduateAcceptedAndRejected  = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/graduate_accepted_and_rejected' )
}

// Ofertas dinamicos
export const dinamicReportOffers = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/jobOffer_dynamic_report' )
}

// egresados dinamicos
export const dinamicReportGraduates = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/graduate_dynamic_report' )
}

// empresas dinamicos
export const dinamicReportCompanies = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/company_dynamic_report' )
}


//roles
export const transactions = async (bodyRequest: object) => {
  return await consumeService(bodyRequest, '/report/transactions' )
}


const getToken = () =>{
  let token = "";
  if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
  }
  return token;
}

const consumeService = async (bodyRequest: object, service: string) => {
  try {
    const response = await fetch(
      `${baseUrl}${service}`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'sie_token': getToken()
        },
        body: JSON.stringify(bodyRequest)
      }
    )
    return response;
  } catch (error) {
    return error
  }
}