import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import './SimpleFilterBox.scss';
import { StateByRole } from '../../../interfaces/StateByRole';

interface SimpleFilterBoxProps{
    title: string;
    subtitle: string;
    stateCheck:any
    onChangeState: any;
    stateOptions: Array<StateByRole>;
}

const SimpleFilterBox:React.FC<SimpleFilterBoxProps> = ({title, subtitle, stateCheck, onChangeState, stateOptions})=>{
    return(
        <Grid container item xs={12} className="simple-filter-container">
            <Grid container item xs={12}>
                <Typography variant="h4" className="bold-serif-primaryColor">
                    {title}
                </Typography>
            </Grid>
            <Grid container item xs={12} md={2} className="simple-filter-text">
                <Typography variant="h5" className="bold-serif-primarycolor">
                    {subtitle}
                </Typography>
            </Grid>
            <Grid container item xs={12} md={10} className="simple-filter-options">
                <FormControl component="fieldset">
                    <FormGroup className="group-checkbox">
                        {stateOptions.map((option: StateByRole) => {
                            const checkedValue = stateCheck[option.value]
                            return (
                                option.value !== 'Ofertas' &&
                                <FormControlLabel key={option.id} className="label-checkbox"
                                    control={<Checkbox checked={checkedValue} onChange={onChangeState} name={option.value} />}
                                    label={option.label}
                                />
                            )
                        })}
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default SimpleFilterBox;