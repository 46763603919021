import React from 'react';
import {Link}  from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import '../LaboralOffers.scss';


interface OffersStateFiltersProps{
    selected: string;
    offersNumber: number;
}

const OffersStateFilters: React.FC<OffersStateFiltersProps> = ({selected, offersNumber})=>{

    const buttons = [
        {id: 1, name: 'En definición', state: 'En definición', url: "/empresa/dashboard"},
        {id: 2, name: 'Convocatoria abierta', state: 'Convocatoria abierta', url: "/empresa/dashboard/ofertas/convocatoria"},
        {id: 3, name: 'En Selección', state: 'En Selección', url: "/empresa/dashboard/ofertas/seleccion"},
        {id: 4, name: 'Finalizado', state: 'Finalizado', url: "/empresa/dashboard/ofertas/finalizadas"},
        {id: 5, name: 'Cancelada', state: 'Cancelada', url: "/empresa/dashboard/ofertas/canceladas"},
    ]

    return(
        <Grid item xs={12} className="state-bar-container">
            <Grid container item xs={12} className="container-buttons">
                {buttons.map(item=>(
                <Link key={item.id}  to={item.url} className="link-state-bar">
                    <div className={selected === item.state ? 'button-definition-active' : 'button-definition'}>
                        <Typography variant="h5" className="regular-serif">
                            {item.name}
                        </Typography>
                        {selected === item.state &&
                            <Typography variant="h6" className="button-number-active">
                                {offersNumber}
                            </Typography>
                        }
                    </div>
                </Link>
                ))}
            </Grid>
        </Grid>
    )
}

export default OffersStateFilters