import React, { useState, useEffect } from 'react';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Grid from "@material-ui/core/Grid";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from '@material-ui/icons/Warning';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { IUserByCompany } from '../../interfaces/IUserByCompany';
import SuccessModal from '../ShareComponents/SuccessModal';
import ErrorModal from '../ShareComponents/ErrorModal';
import LoadingLockPage from '../ShareComponents/LoadingLockPage';
import { changeStateUserByCompany } from '../../services/CompanyService';
import UserContext from '../../utils/UserContext';

import './ChangeStateModal.scss';


const styles = (theme: Theme) =>
createStyles({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#004B73',
  },
})

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="state-dialog-header" {...other}>
      <Typography variant="h2" className="bold-serif-primaryColor" style={{marginRight:'16px'}}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface ChangeStateModalProps{
  open: any;
  handleClose: any;
  title: string;
  backBtnText?: string;
  colorBtnBack?: string;
  continueBtnText?: string;
  continueBtnColor?: string;
  userSelected?: any;
  activeUsers: Array<IUserByCompany>;
}

const ChangeStateModal: React.FC<ChangeStateModalProps> = ({open, handleClose, title, backBtnText, colorBtnBack, continueBtnText, continueBtnColor, userSelected, activeUsers })=>{

  const { promiseInProgress } = usePromiseTracker();
  const userContext = React.useContext(UserContext);
  const [idToReplace, setIdToReplace] = useState<string>('');
  const [replaceBtnDisabled, setReplaceBtnDisabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage]= useState('');
  const [openModalError, setOpenModalError] = React.useState(false);
  const [successMessage, setSuccessMessage]= useState('');
  const [openModalSuccess, setOpenModalSuccess] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIdToReplace((event.target as HTMLInputElement).value);
  };

  const changeStateUser = async() =>{
    if(idToReplace){
      handleClose()
      const userToDisable = {
        id: parseInt(idToReplace),
        status: "Inactivo"
      }
      const response1CUC: Response = await trackPromise(changeStateUserByCompany(
        {...userToDisable, modifyUser: userContext.userId}
      ));
      if(response1CUC.status === 200){
        if(userSelected){
          const userToEnable = {
            id: userSelected.id,
            status: "Activo"
          }
          const response2CUC: Response = await trackPromise(changeStateUserByCompany(
            {...userToEnable, modifyUser: userContext.userId}
          ));
          if(response2CUC.status === 200){
            const result = await response2CUC.json();
            setSuccessMessage(result.message)
            setOpenModalSuccess(true);
            setOpenModalError(false);
          } else if (response2CUC.status === 400){
            const result = await response2CUC.json();
            setErrorMessage(result.message);
            setOpenModalError(true);
            setOpenModalSuccess(false);
          } else {
            setErrorMessage("Hubo un error, intente de nuevo mas tarde");
            setOpenModalError(true);
            setOpenModalSuccess(false);
          }
        }
      } else if (response1CUC.status === 400){
          const result = await response1CUC.json();
          setErrorMessage(result.message);
          setOpenModalError(true);
          setOpenModalSuccess(false);
      } else {
          setErrorMessage("Hubo un error, intente de nuevo mas tarde");
          setOpenModalError(true);
          setOpenModalSuccess(false);
      }
    }
  };

  const handleCloseModalSuccess = ()=>{
    setOpenModalSuccess(false);
    window.location.reload();
  };

  const handleCloseModalError = ()=>{
    setOpenModalError(false);
    window.location.reload();
  };

  useEffect(()=>{
    if(idToReplace !== ""){
      setReplaceBtnDisabled(true);
    } else{
      setReplaceBtnDisabled(false);
    }
  },[idToReplace])

  return(
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="state-dialog-container"
        fullWidth
        maxWidth = "lg"
      >

        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>

        <DialogContent className="state-dialog-content">
          <Grid container item xs={12} direction="row">
              <WarningIcon  className="warning-icon"/>
              <Typography variant="h5" className="bold-serif warning-text">Ya ha alcanzado el límite de usuarios activos.</Typography>
          </Grid>
          <Grid container item xs={12} direction="row">
              <Typography variant="h5" className="regular-serif info-text">Para poder activar un nuevo usuario usted debe escoger un usuario activo para reemplazarlo:</Typography>
          </Grid>
          <Grid container item xs={12} direction="row" className="active-user-container">
            <Typography variant="h5" className="bold-serif-primaryColor">¿Qué usuario desea inactivar y reemplazar?</Typography>
            <Grid  container item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup className="radio-container" aria-label="username" name="username1" onChange={handleChange}>
                  {
                    activeUsers.length>0 && activeUsers.map(user=>(
                      <FormControlLabel key={user.id} value={user.id+""} className="radio-btn" control={<Radio className="color-btn"/>} label={user.name} />
                    ))
                  }
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className="state-dialog-actions">
          <Button className="cancel-button" variant="contained"  size="small" onClick={handleClose}>
            <Typography variant="h5" className="bold-serif">
              {backBtnText}
            </Typography>
          </Button>
          <Button disabled={!replaceBtnDisabled} onClick={changeStateUser} className="add-button" variant="contained" size="small" >
            <Typography variant="h5" className="bold-serif">
              {continueBtnText}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
      <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
      <LoadingLockPage load={promiseInProgress}/>
    </>
  )
}

export default ChangeStateModal;