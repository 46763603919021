import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import PeopleIcon from '@material-ui/icons/People';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Button from '@material-ui/core/Button';
import EndLaboralOfferDescription from '../../../../components/LaboralOffers/EndLaboralOfferDescription';
import { getlistOffers } from '../../../../services/LaboralOfferService';
import { IGetOffer } from '../../../../interfaces/IGetOffer';
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import { IApplicantInfo } from '../../../../interfaces/IApplicantInfo';
import ApplicantList from '../../../../components/LaboralOffers/ApplicantList';
import ModalOffersAction from '../../../../components/LaboralOffers/ModalOffersAction';
import { changeStateLaboralOffer } from '../../../../services/ChangeStateService';
import CauseOffers from '../../../../components/LaboralOffers/CauseOffers';
import { IGeneralParameter } from '../../../../interfaces/IGeneralParameter';
import { listPostulations } from '../../../../services/PostulationService';
import { IGetPostulation } from '../../../../interfaces/IGetPostulation';
import AlertBox from '../../../../components/ShareComponents/AlertBox';
import SuccessModal from '../../../../components/ShareComponents/SuccessModal';
import ErrorModal from '../../../../components/ShareComponents/ErrorModal';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import { getFinishedObservation, getLABORAL_SECTOR, getOccupationType, getTypeOfContract } from '../../../../services/ParameterService';
import UserContext from '../../../../utils/UserContext';
import { permissionCompanyChangeStateOffer } from '../../../../utils/PermissionsVariables';
import { containsAny } from '../../../../helpers/sharedFunctions';

import './EndLaboralOfferView.scss';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';


const EndLaboralOfferView: React.FC = () => {
  const userContext = React.useContext(UserContext);
  const { promiseInProgress } = usePromiseTracker();
  const [url, setUrl] = useState<string | null>(null);
  const [dataOffer, setDataOffer] = useState<any>({});
  const [typePosition, setTypePosition] = useState<string>('');
  const [laboralSector, setLaboralSector]= useState<string>('');
  const [contract, setContract]= useState<string>('');
  const [applicantsData, setApplicantsData] = useState<IApplicantInfo[]>([]);
  const [applicantsSelected, setApplicantsSelected] = useState<IApplicantInfo[]>([]);
  const [rowsPerPage, setRowsPerPage]= useState(5);
  const [initPage, setInitPage]= useState<number>(0);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [causes, setCauses] = useState<Array<IGeneralParameter>>([]);
  const [causeSelected, setCauseSelected] = useState<number>(0);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage]= useState('');
  const [openModalError, setOpenModalError] = useState(false);
  const [successMessage, setSuccessMessage]= useState('');
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  let params: any = useParams();

  useEffect(()=>{
    window.scrollTo(0, 0);
    const getInitialData = async()=>{
      const finishedObsResp = await getFinishedObservation();
      setCauses(finishedObsResp);
    }
    getInitialData();
  },[])

  useEffect(()=>{
    const getDataOffer = async()=>{
      if(params.state === 'Finalizado' && params.id){
        const applicantRes: Response = await listPostulations({offerId:params.id});
        if(applicantRes.status === 200){
            const result: Array<IGetPostulation> = (await applicantRes.json()).postulates;
            let target: Array<IApplicantInfo> = [];
            result.forEach(item=>{
                target.push(new IApplicantInfo(item))
            })
            setApplicantsData(target)
            const appsSelected = target.filter(item => item.state === "Seleccionado");
            if(appsSelected.length > 0){
              setApplicantsSelected(appsSelected)
            }
        }
        const responseOffer: Response = await trackPromise(getlistOffers({id: params.id}));
        if(responseOffer.status === 200){
          const resultOffer: IGetOffer = (await responseOffer.json()).jobOffers[0];
          if(resultOffer){
            setDataOffer(resultOffer);
            let responsePostion: Array<IGeneralParameter> = await trackPromise(getOccupationType());
            const positionSelected = responsePostion.find(item=>item.parameter_code === resultOffer.typePosition);
            setTypePosition(positionSelected?.name ? positionSelected.name : '');
            let respLaboralSector: Array<IGeneralParameter> = await trackPromise(getLABORAL_SECTOR());
            const laboralSelected = respLaboralSector.find(item=>item.parameter_code === resultOffer.laboralSector);
            setLaboralSector(laboralSelected?.name ? laboralSelected?.name : '');
            let respTypeContrat: Array<IGeneralParameter> = await trackPromise(getTypeOfContract());
            const contractSelected = respTypeContrat.find(item=>item.parameter_code === resultOffer.typeContract);
            setContract(contractSelected?.name ? contractSelected?.name : '');
          }
        }
      } else {
        setUrl(`/empresa/dashboard`);
      }
    }
    if(containsAny(permissionCompanyChangeStateOffer,userContext.userPermissions)){
      getDataOffer();
    }
  },[params.id, params.state, params, userContext.userPermissions]);

  useEffect(()=>{
    if(causeSelected>0 && dataOffer){
      if(causeSelected === 1){
        if(applicantsSelected.length>0){
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } else if (causeSelected > 1) {
        setIsValid(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dataOffer, causeSelected])

  const onChanhePageSize = (e: any)=>{
    setRowsPerPage(e.target.value)
  }

  const handleCause = (e: any) => {
    const { value } = e.target;
    setCauseSelected(value);
  }

  const handleConfirmModal = ()=>{
    setOpenConfirmModal(true);
  }

  const handleCloseConfirm = ()=>{
    setOpenConfirmModal(false);
  }

  const closeOffer = async()=>{
    setOpenConfirmModal(false);
    if(params.state === 'Finalizado' && params.id && causeSelected>0){
      const responseCSFO = await trackPromise(changeStateLaboralOffer(
        {id: params.id, state: 'Finalizado', causal: causeSelected, modifyUser: userContext.userId}
      ));
      if(responseCSFO.status === 200){
        const result = await responseCSFO.json();
        setSuccessMessage(result.message);
        setOpenModalSuccess(true);
        setOpenModalError(false);
      } else if (responseCSFO.status === 400){
        const result = await responseCSFO.json();
        setErrorMessage(result.message);
        setOpenModalError(true);
        setOpenModalSuccess(false);
      } else {
        setErrorMessage("Hubo un error, intente de nuevo mas tarde");
        setOpenModalError(true);
        setOpenModalSuccess(false);
      }
    }
  }

  const handleCloseModalSuccess = ()=>{
    setOpenModalSuccess(false);
    setUrl('/empresa/dashboard')
  };

  const handleCloseModalError = ()=>{
    setOpenModalError(false);
  };

  return (
    <>
      {
          url !== null ?
          <Redirect to={url} />
      :
        <Grid container className="end-offer-container">
          <Grid container item xs={12} className="back-offers-list-section">
            <ButtonBackListOffers url="/empresa/dashboard/ofertas" text="Regresar al listado de ofertas"/>
          </Grid>
          {containsAny(permissionCompanyChangeStateOffer,userContext.userPermissions) ?
            <>
              <Grid container className="main-container">
                <Grid container item xs={12} className="title-container">
                  <WarningIcon className="warning-icon"/>
                  <Typography variant="h2" className="bold-serif" >
                    Finalizar Oferta
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  <EndLaboralOfferDescription dataOffer={dataOffer} typePosition={typePosition} laboralSector={laboralSector} contract={contract}/>
                </Grid>
                {
                  applicantsData.length > 0 &&
                  <>
                    {
                      applicantsSelected.length === 0 &&
                      <Grid container item xs={12}>
                        <AlertBox
                            title="No ha seleccionado ningún aspirante"
                            alert="warning"
                            variantType="standard"
                        >
                          <span className="alert-simple-text">
                            No ha seleccionado ningún aspirante. En está interfaz tendrá la última posibilidad de seleccionar un egresado de la Universidad Nacional.
                          </span>
                        </AlertBox>
                      </Grid>
                    }
                    <Grid container item xs={12}>
                      <Grid container item xs={12} md={3} lg={2}>
                        <div className="title-section-line">
                          <PeopleIcon className="group-icon"/>
                          <Typography variant="h2" className="bold-serif-primaryColor" >
                            Aspirantes
                          </Typography>
                        </div>
                      </Grid>
                      <Grid container item xs={12} md={9} lg={10} className="line" />
                    </Grid>
                    <Grid container item xs={12}>
                      <ApplicantList action="Select" onChanhePageSize={onChanhePageSize} rows={applicantsData} pageSize={rowsPerPage} initPage={initPage} setInitPage={setInitPage}/>
                    </Grid>
                  </>
                }
                <Grid container item xs={12}>
                  <CauseOffers causeSelected={causeSelected} causes={causes} handleCause={handleCause}/>
                </Grid>
                {
                  causeSelected === 1 && applicantsSelected.length === 0 &&
                  <Grid item xs={12} style={{marginTop: '8px'}}>
                    <Alert severity="error">
                      <span className="alert-simple-title">Candiadato de la UNAL seleccionado: </span><span className="alert-simple-text">Si selecciona esta causal debe seleccionar un candidato para poder finalizar la oferta</span>
                    </Alert>
                  </Grid>
                }
                <Grid container item xs={12} className="buttons-container-offer">
                  <Button startIcon={<ChevronLeftIcon />} className="cancel-button" variant="contained" size="small" component={Link} to="/empresa/dashboard" >
                    <Typography variant="h5" className="regular-serif">
                      Cancelar
                    </Typography>
                  </Button>
                  <Button disabled={!isValid} endIcon={<ChevronRightIcon />} onClick={handleConfirmModal} className="confirm-button" variant="contained" size="small">
                    <Typography variant="h5" className="regular-serif">
                      Confirmar
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid container item>
                <ModalOffersAction
                    open={openConfirmModal}
                    handleClose={handleCloseConfirm}
                    handleContinue={closeOffer}
                    iconModal={<WarningIcon className="big-warning-icon" />}
                    title="¿Está seguro de finalizar la oferta?"
                    headerdescription="Esta intentando finalizar una oferta laboral,"
                    description=" si cierra la oferta los egresados no seleccionados se perderán."
                    backBtnText="Regresar"
                    colorBtnBack= ""
                    continueBtnText="Si, cerrar oferta"
                    colorBtnContinue="#f44336"
                    valid={true}
                />
              </Grid>
              <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
              <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
              <LoadingLockPage load={promiseInProgress}/>
            </>
          :
            <NotAuthorized/>
          }
        </Grid>
      }
    </>
  )
}
export default EndLaboralOfferView;