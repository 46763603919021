import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditProfileCompany from '../../../../components/EditProfileCompany';
import { IHeadquarter, IProfileCompany } from '../../../../interfaces/IProfileCompany';
import { IGeneralParameter } from '../../../../interfaces/IGeneralParameter';
import HeadquartersList from '../../../../components/HeadquartersList';
import HeadquarterModal from '../../../../components/HeadquarterModal';
import UserContext from '../../../../utils/UserContext';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import { containsAny } from '../../../../helpers/sharedFunctions';
import { permissionAdminModifyCompanies, permissionAdminSeeInfoCompanies, permissionCompanyCreateHeadquarter, permissionCompanyHeadquartersView, permissionCompanyModifyProfile } from '../../../../utils/PermissionsVariables';

import '../company.scss';

interface ProfileCompanyViewProps {
  companyData: IProfileCompany;
  cityArray: Array<IGeneralParameter>;
  headquartersCompany: Array<IHeadquarter>;
  action?: string;
}

const ProfileCompanyView: React.FC<ProfileCompanyViewProps> = ({ companyData, cityArray, headquartersCompany, action }) => {
  const userContext = React.useContext(UserContext);
  const [open, setOpen] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [initPage, setInitPage] = useState<number>(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChanhePageSize = (e: any) => {
    setRowsPerPage(e.target.value)
  }

  return (
    <Grid container className="main-container">
      {containsAny(permissionAdminSeeInfoCompanies, userContext.userPermissions) ?
        <Grid container item xs={12} className="profiles-container">
          <Grid container item xs={12} md={3} lg={2}>
            <div className="title-section-line">
              <AccountBoxIcon className="group-icon" />
              <Typography variant="h2" className="bold-serif-primaryColor" >
                {action === "Admin"? "Perfil empresa" : "Mi perfil"}
              </Typography>
            </div>
          </Grid>
          <Grid container item xs={12} md={9} lg={10} className="line" />
          <EditProfileCompany companyData={companyData} cityArray={cityArray} />
          {
            action ==="Admin" ?
            <>
            </>
          :
            <>
              <Grid container item xs={12} md={3} lg={2}>
                <div className="title-section-line">
                  <LocationOnIcon className="group-icon" />
                  <Typography variant="h2" className="bold-serif-primaryColor" >
                    Mis sedes
                  </Typography>
                </div>
              </Grid>
              <Grid container item xs={12} md={9} lg={10} className="line" />
              {containsAny(permissionCompanyHeadquartersView, userContext.userPermissions) ?
                <>
                  <HeadquartersList onChanhePageSize={onChanhePageSize} cityArray={cityArray} rows={headquartersCompany} pageSize={rowsPerPage} initPage={initPage} setInitPage={setInitPage} />
                  {containsAny(permissionCompanyCreateHeadquarter, userContext.userPermissions) &&
                    <Grid container item xs={12} className="btn-container">
                      <Button disabled={!containsAny(permissionCompanyCreateHeadquarter, userContext.userPermissions)} variant="contained" className="add-profile-btn" endIcon={<AddIcon />} onClick={(handleClickOpen)}>
                        <Typography variant="h5" className="bold-serif">
                          AÑADIR SEDE
                        </Typography>
                      </Button>
                      <HeadquarterModal
                        open={open}
                        handleClose={handleClose}
                        title="Añadir sede"
                        backBtnText="CANCELAR"
                        continueBtnText="AÑADIR"
                        action="Add"
                      />
                    </Grid>
                  }
                </>
              :
                <NotAuthorized />
              }
          </>
          }
        </Grid>
      :
        <NotAuthorized />
      }
    </Grid>
  )
}

export default ProfileCompanyView
