import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import DescriptionIcon from '@material-ui/icons/Description';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import { IGetSurveys } from '../../../../interfaces/IGetSurveys';
import './TrainingEventFormPage.scss';
import UserContext from '../../../../utils/UserContext';
import TrainingEventForm from '../../../../components/TrainingEvents/TrainingEventForm';
import { getTrainingEvents } from '../../../../services/TrainingEventServices';
import { IGetTrainingEvents } from '../../../../interfaces/IGetTrainingEvents';

const TrainingEventFormPage: React.FC = () => {
  let params: any = useParams();
  const userContext = React.useContext(UserContext);
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [action, setAction] = useState<string>();
  const [surveySelected, setSurveySelected] = useState<IGetTrainingEvents>(new IGetTrainingEvents());

  useEffect(()=>{
    window.scrollTo(0, 0);
    const urlPath = window.location.href;
    if(urlPath.includes('crear')){
        setAction("Create")
    } else if (urlPath.includes('editar')){
        setAction("Edit")
    }
    const getDetailData = async()=>{
      if(params.id){
          const responseDetail: Response = await getTrainingEvents({id:parseInt(params.id)});
          if(responseDetail.status === 200){
              const resultDetail = ((await responseDetail.json()).trainingevents)[0]
              setSurveySelected(resultDetail);
          }
      }
      setInitLoad(false)
    }
    getDetailData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <>
      <Grid container item xs={12} className="back-offers-list-section">
        <ButtonBackListOffers url="/admin/dashboard/capacitaciones" text="Regresar al listado de capacitaciones"/>
      </Grid>
      <Grid container className="main-container">
        <Grid container item xs={12} className="trainingevents-container">
          <Grid container item xs={12} md={3} lg={3}>
            <div className="title-section-line">
              <DescriptionIcon className="group-icon"/>
              <Typography variant="h2" className="trainingevents-title" >
                  {action !== "Edit"? "Nueva capacitacion" : "Editar capacitacion"}
              </Typography>
            </div>
          </Grid>
          <Grid container item xs={12} md={9} lg={9} className="line" />
          <Grid container >
            {action === "Edit"?
              <>
                <TrainingEventForm
                action={action}
                surveySelected={surveySelected}
                />
              </>
            :
              <>
                <TrainingEventForm
                action="Create"
                />
              </>
            }
          </Grid>
        </Grid>
      </Grid>
      <LoadingLockPage load={initLoad}/>
    </>
  )
}

export default TrainingEventFormPage;
