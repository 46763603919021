import { createMuiTheme } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';

const customTheme = createMuiTheme({
    breakpoints:{
        values:{
            xs:0,
            sm:540,
            md:720,
            lg:960,
            xl:1125
        }
    },
    typography:{
        h1:{
            fontSize: "32px"
        },
        h2: {
            fontSize: "24px"
        },
        h3:{
            fontSize: "20px"
        },
        h4: {
            fontSize: "18px"
        },
        h5:{
            fontSize: "16px"
        },
        h6:{
            fontSize: "14px"
        },
        h7:{
            fontSize: "12px"
        }
    }
}, esES);

export default customTheme;