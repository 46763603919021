import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import MenuItem from "@material-ui/core/MenuItem";
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { StateByRole } from '../../../interfaces/StateByRole';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { IFilterEvents } from '../../../interfaces/IFilterEvents';
import './NewsEventsFilters.scss';

interface NewsEventsFilterBoxProps{
    title: string;
    typeOptions: Array<StateByRole>;
    stateOptions: Array<StateByRole>;
    categoryArray: Array<IGeneralParameter>;
    filterValues: IFilterEvents;
    setFilterValues: any;
    applyFilters: any;
}

const NewsEventsFilters:React.FC<NewsEventsFilterBoxProps> = ({title, typeOptions, stateOptions, categoryArray, filterValues, setFilterValues, applyFilters })=>{

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFilterValues((prev: any) => ({
            ...prev,
            [name]: value
        }));
    }

    const radioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target as HTMLInputElement;
        setFilterValues((prev: any) => ({
            ...prev,
            [name]: value
        }));
    };


    return(
        <Grid container item xs={12} className="news-events-filter-container">
                <Grid container item xs={12}>
                    <Typography variant="h3" className="news-events-title news-events-filter-text">
                        {title}
                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    <Grid container item xs={12} md={2}>
                        <Typography variant="h4" className="news-events-filter-text">
                            Filtrar por tipo:
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} md={10} className="news-events-filter-options">
                        <FormControl component="fieldset">
                            <RadioGroup row className="radio-container" aria-label="type" name="type" value={filterValues.type} onChange={radioChange}>
                                {typeOptions.map(option=>(
                                    <FormControlLabel key={option.id} value={option.value} className="radio-btn" control={<Radio className="color-btn" />} label={option.label} labelPlacement="start"/>
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid container item xs={12} md={2}>
                            <Typography variant="h4" className="news-events-filter-text">
                                Filtrar por estados:
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} md={10} className="news-events-filter-options">
                        <FormControl component="fieldset">
                            <RadioGroup row className="radio-container" aria-label="state" name="state" value={filterValues.state} onChange={radioChange}>
                                {stateOptions.map(option=>(
                                    <FormControlLabel key={option.id} value={option.value} className="radio-btn" control={<Radio className="color-btn" />} label={option.label} labelPlacement="start"/>
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid container item xs={12} md={2}>
                            <Typography variant="h4" className="news-events-filter-text">
                                Filtrar por categoría:
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} md={4}>
                            <TextField
                                fullWidth
                                id="outlined-select-category"
                                select
                                name="category"
                                label={filterValues.category === 0 ? "Seleccione": ""}
                                value={filterValues.category === 0 ? "" : filterValues.category}
                                onChange={handleChange}
                                InputLabelProps={{shrink: false}}
                                margin="none"
                                variant="outlined"
                            >
                                {
                                    categoryArray && categoryArray.length> 0 &&
                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                }
                                {
                                categoryArray.length> 0 ?
                                categoryArray.map((option: IGeneralParameter) => (
                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                    {option.name}
                                    </MenuItem>
                                ))
                                :
                                    <MenuItem value={filterValues.category}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6} lg={12} className="btn-container">
                            <Button onClick={applyFilters} variant="contained" className="search-btn">
                                <SearchIcon style={{ color: 'white' }}/>
                                <Typography variant="h4" className="btn-text">Buscar</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NewsEventsFilters;