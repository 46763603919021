import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridColDef, GridOverlay, GridColParams, GridCellParams, GridPageChangeParams, GridBaseComponentProps } from '@material-ui/data-grid';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import { IRole } from '../../../interfaces/IRole';

interface RolesTableProps{
    rows: Array<IRole>;
    handleRowRole: any;
}

const RolesTable: React.FC<RolesTableProps> = ({ rows, handleRowRole}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [rolSelected, setRolSelected] = useState<IRole>(new IRole());
    const pageSize = 10;

    const columnsAnnouncement: GridColDef[] = [
        { field: 'name', headerName: 'Rol', width: 500, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Rol'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'Acción', headerName: 'Acciones', width: 100, sortable: false ,renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Acciones'}
                </span>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=>handleClick(e,params)}>
                    <SettingsIcon/>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem  value={1} onClick={(e)=>{handleCloseMenu();handleRowRole(rolSelected)}}>Configurar permisos</MenuItem>
                </Menu>
            </>
        ) },
    ];

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, params:GridCellParams) => {
        setAnchorEl(event.currentTarget);
        const rol: IRole = Object.assign(params.row);
        setRolSelected(rol)
    };

    return(
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid autoHeight className="data-grid-list" disableColumnMenu rows={rows} columns={columnsAnnouncement} pageSize={pageSize} density="compact" disableSelectionOnClick
                components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                }}
                />
            </Grid>
        </Grid>
    )
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large"/>
            <div className="not-results-text">Sin registros</div>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps)=><KeyboardArrowUpIcon fontSize="small"/>
const customSortDesc = (props: GridBaseComponentProps)=><KeyboardArrowDownIcon fontSize="small"/>

export default RolesTable