export class RangeDateValues{
    sinceDate: string = "";
    untilDate: string = "";
}
export class IGraduateFilter{
    names?: string = "";
    surname?: string = "";
    gender?: number = 0;
    doc?: string = "";
    state?: Array<string> = [];
    rangeUpdate?: RangeDateValues = new RangeDateValues();
    inJobBank?: boolean = false;
}
export class Associations{
    typeOfAssociation?: number = 0;
    association?: number = 0;
}
export class EducationUnal{
    campus?: number = 0;
    school?: Array<number>=[];
    curriculum?: Array<number>=[];
    academicLevel?: number = 0;
    rangeGrade?:RangeDateValues = new RangeDateValues();
}

export class AttachingFile{
    base64: string = "";
    type: string = "";
    filename: string = "";
    previewUrl?: any;
}

export class IGraduateFilterObjMsg{
    type?: string = "";
    preview?: boolean = true; // true return number, false send email
    filters:IGraduateFilter = new IGraduateFilter();
    associations: Associations = new Associations();
    educationUnal: EducationUnal = new EducationUnal();
    subject? : string = "";
    html?: string = "";
    aditionalEmails?: Array<string> = [];
    attachingFiles: Array<AttachingFile>=[];
}
