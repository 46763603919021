import React from "react";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DescriptionOfferBox from "../DescriptionOfferBox";
import { IGetOffer } from "../../../interfaces/IGetOffer";
import "./LaboralOfferSummary.scss";

interface LaboralOfferSummaryProps{
    dataOffer: IGetOffer;
    typePosition: string;
    laboralSector: string;
    contract: string;
}

const LaboralOfferSummary: React.FC<LaboralOfferSummaryProps> = ({dataOffer, typePosition, laboralSector, contract}) => {
  const [expanded, setExpanded] = React.useState<string | boolean>('panel');

  const handleChangeAccoridon = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid container className="laboral-offer-info-container">
      <Grid container item xs={12}>
        <Accordion
          expanded={expanded === "panel"}
          onChange={handleChangeAccoridon("panel")}
          className="summary-offer-accordion-container"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="accordion-summary"
          >
            <Typography variant="h2" className="bold-serif">
              Resumen de la oferta
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              item
              xs={12}
              className="description-summary-container"
            >
              <DescriptionOfferBox dataOffer={dataOffer} typePosition={typePosition} laboralSector={laboralSector} contract={contract} showButtons={false}/>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default LaboralOfferSummary;
