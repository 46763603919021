const baseUrl = process.env.REACT_APP_SIE_APP_PROXY_BASE_URL;

export const getUserPersonalInfo = async (userToken: string, userId:number) => {
    try {
        const response = await fetch(
            `${baseUrl}/graduate/cv/PERSONAL_INFORMATION/${userId}`,
            {
                method: 'GET',
                headers:{
                    "sie_token": userToken
                }
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const getUserAcademicInfo = async (userToken: string, userId:number) => {
    try {
        const response = await fetch(
            `${baseUrl}/graduate/cv/ACADEMIC_INFORMATION/${userId}`,            
            {
                method: 'GET',
                headers:{
                    "sie_token": userToken
                }
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const getUserLaboralInfo = async (userToken: string, userId:number) => {
    try {
        const response = await fetch(
            `${baseUrl}/graduate/cv/LABORAL_INFORMATION/${userId}`,
            {
                method: 'GET',
                headers:{
                    "sie_token": userToken
                }
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const getUserAdditionalInfo = async (userToken: string, userId:number) => {
    try {
        const response = await fetch(
            `${baseUrl}/graduate/cv/ADITIONAL_INFORMATION/${userId}`,
            {
                method: 'GET',
                headers:{
                    "sie_token": userToken
                }
            }
        );
        return response;
    } catch (error) {
        return error
    }
}

export const getGraduateDocuments = async (userId:number) => {
    try {
        const response = await fetch(
            `${baseUrl}/filesystem/get-user-documents/Egresado-${userId}`,
            {method: 'GET'}
        );
        return response;
    } catch (error) {
        return error
    }
}

export const getGraduateReports = async () => {
    try {
        const response = await fetch(
            `${baseUrl}/filesystem/get-user-documents/reports/`,
            {method: 'GET'}
        );
        return response;
    } catch (error) {
        return error
    }
}

export const updatePersonalInfo = async (userToken: string, bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/graduate/cv/PERSONAL_INFORMATION`,
            {
                method: 'POST',
                body: JSON.stringify(bodyRequest),
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "sie_token": userToken
                },
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const updateAcademicInfo = async (userToken: string, bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/graduate/cv/ACADEMIC_INFORMATION`,
            {
                method: 'POST',
                body: JSON.stringify(bodyRequest),
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "sie_token": userToken
                },
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const updateLaboralInfo = async (userToken: string, bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/graduate/cv/LABORAL_INFORMATION`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "sie_token": userToken
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const updateAdditionalInfo = async (userToken: string, bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/graduate/cv/ADITIONAL_INFORMATION`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "sie_token": userToken
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const getUserPercentage = async (userToken: string, userId:number) => {
    try {
        const response = await fetch(
            `${baseUrl}/graduate/cv/USER_PERCENTAGE/${userId}`,
            {
                method: 'GET',
                headers:{
                    "sie_token": userToken
                }
            }
        );
        return response;
    } catch (error) {
        return error
    }
}