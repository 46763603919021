import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import NotificationBox from '../../ShareComponents/NotificationBox';
import UserContext from '../../../utils/UserContext';
import { IPresignedUrl, IPreSignedUrlReq } from '../../../interfaces/IPreSignedUrl';
import { deleteFileS3, getSignUrl, uploadFileSignedUrl } from '../../../services/s3Service';
import Validations from '../../../helpers/Validations';
import { IFile } from '../../../interfaces/IFile';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import { getGraduateDocuments } from '../../../services/GraduateService';
import { IAttachmentInfoCV } from '../../../interfaces/IAttachmentInfoCV';
import { IGetDocuments } from '../../../interfaces/IGetDocuments';

import '../GraduateCurriculum.scss';

class InitialDocumentsErrors {
    fileError: string =  "";
    descriptionDocument: string = "";
    descriptionOtherDocument: string = "";
}

const formDocsValidations = {
    descriptionDocument: {
        error: "",
        validate: "textNumber",
        minLength: 10,
        maxLength: 100,
        required: true
    },
    descriptionOtherDocument: {
        error: "",
        validate: "textNumber",
        minLength: 10,
        maxLength: 100,
        required: true
    },
}

const Attacments: React.FC = () => {
    let params: any = useParams();
    const userContext = React.useContext(UserContext);
    const { promiseInProgress } = usePromiseTracker();
    const [expanded, setExpanded] = useState<string | boolean>('panel1');
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [formValues, setFormValues] = useState<IAttachmentInfoCV>(new IAttachmentInfoCV({}));
    const [formErrors, setFormErrors] = useState<InitialDocumentsErrors>(new InitialDocumentsErrors());
    const [descResult, setDescResult] = useState(false);
    const [disabledUploadCV, setDisableUploadCV]= useState(false);
    const [descOtherResult, setDescOtherResult] = useState(false);
    // modals and errors
    const [errorMessage, setErrorMessage]= useState('');
    const [openModalError, setOpenModalError] = React.useState(false);
    const [successMessage, setSuccessMessage]= useState('');
    const [openModalSuccess, setOpenModalSuccess] = React.useState(false);

    useEffect(()=>{
        const getDocuments = async() =>{
            window.scrollTo(0, 0);
            if(userContext.userId && userContext.userId.length>0){
                const responseDocuments = await getGraduateDocuments(parseInt(userContext.userId));
                if(responseDocuments.status === 200){
                    const resultDocuments: Array<IGetDocuments> = await responseDocuments.json();
                    const cvDoc = resultDocuments.find(doc=>doc.key.includes("Egresado_hoja_de_vida.pdf"));
                    if(cvDoc){
                        const url = cvDoc.signedUrl;
                        const descriptionFile = decodeURI(cvDoc.metadata.description);
                        const fileName = 'Egresado_hoja_de_vida.pdf';
                        const folderFileKey = cvDoc.key;
                        fetch(url)
                        .then(async response => {
                            const blob = await response.blob()
                            const file = new File([blob], fileName, {type: "application/pdf"});
                            const previewUrl = URL.createObjectURL(file)
                            if(file){
                                setFormValues(prev => ({
                                    ...prev,
                                    filesCV: [{description: descriptionFile, file:file, previewUrl : previewUrl, folderFileKey: folderFileKey ? folderFileKey : ""}]
                                }));
                            }
                        })
                    }
                    const otherDocs = resultDocuments.filter(doc=>!doc.key.includes("Egresado_hoja_de_vida.pdf") && doc.key.includes(".pdf"));
                    let newOtherDocs: Array<IFile> = [];
                    for (let i in otherDocs){
                        const url = otherDocs[i].signedUrl;
                        const descriptionFile = decodeURI(otherDocs[i].metadata.description);
                        const fileName = otherDocs[i].key.split("/")[1];
                        const folderFileKey = otherDocs[i].key;
                        await fetch(url)
                        .then(async response => {
                            const blob = await response.blob()
                            const file = new File([blob], fileName, {type: "application/pdf"});
                            const previewUrl = URL.createObjectURL(file)
                            if(file){
                                newOtherDocs.push(
                                    {description: descriptionFile, file:file, previewUrl : previewUrl, folderFileKey: folderFileKey}
                                )
                            }
                        })
                    }
                    if(newOtherDocs.length>0){
                        setFormValues(prev => ({
                            ...prev,
                            otherFiles: newOtherDocs
                        }));
                    }
                }
            }
            if(params.id && params.id.length>0){
                const responseDocuments = await getGraduateDocuments(params.id);
                if(responseDocuments.status === 200){
                    const resultDocuments: Array<IGetDocuments> = await responseDocuments.json();
                    const cvDoc = resultDocuments.find(doc=>doc.key.includes("Egresado_hoja_de_vida.pdf"));
                    if(cvDoc){
                        const url = cvDoc.signedUrl;
                        const descriptionFile = decodeURI(cvDoc.metadata.description);
                        const fileName = 'Egresado_hoja_de_vida.pdf';
                        const folderFileKey = cvDoc.key;
                        fetch(url)
                        .then(async response => {
                            const blob = await response.blob()
                            const file = new File([blob], fileName, {type: "application/pdf"});
                            const previewUrl = URL.createObjectURL(file)
                            if(file){
                                setFormValues(prev => ({
                                    ...prev,
                                    filesCV: [{description: descriptionFile, file:file, previewUrl : previewUrl, folderFileKey: folderFileKey ? folderFileKey : ""}]
                                }));
                            }
                        })
                    }
                    const otherDocs = resultDocuments.filter(doc=>!doc.key.includes("Egresado_hoja_de_vida.pdf") && doc.key.includes(".pdf"));
                    let newOtherDocs: Array<IFile> = [];
                    for (let i in otherDocs){
                        const url = otherDocs[i].signedUrl;
                        const descriptionFile = decodeURI(otherDocs[i].metadata.description);
                        const fileName = otherDocs[i].key.split("/")[1];
                        const folderFileKey = otherDocs[i].key;
                        await fetch(url)
                        .then(async response => {
                            const blob = await response.blob()
                            const file = new File([blob], fileName, {type: "application/pdf"});
                            const previewUrl = URL.createObjectURL(file)
                            if(file){
                                newOtherDocs.push(
                                    {description: descriptionFile, file:file, previewUrl : previewUrl, folderFileKey: folderFileKey}
                                )
                            }
                        })
                    }
                    if(newOtherDocs.length>0){
                        setFormValues(prev => ({
                            ...prev,
                            otherFiles: newOtherDocs
                        }));
                    }
                }
            }
            setInitLoad(false);
        }
        getDocuments();
    },[userContext.userId, params.id])

    const handleChangeAccoridon = (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChangeDocuments = async(e: any) => {
        const { name } = e.target;
        if (name === 'filesCV'){
            if(formValues.filesCV && formValues.filesCV.length<1 && e.target.files.length>0){
                if(e.target.files[0].type === 'application/pdf'){
                    if(e.target.files[0]){
                        setFormValues(prev => ({
                            ...prev,
                            [name]: [...formValues.filesCV, {file:e.target.files[0], description:formValues.descriptionDocument, previewUrl : URL.createObjectURL(e.target.files[0])}],
                        }));
                        setFormErrors(prev=>({
                            ...prev,
                            fileError: ''
                        }));
                    }
                    setFormValues(prev => ({
                        ...prev,
                        descriptionDocument: ''
                    }));
                } else {
                    setFormErrors(prev=>({
                      ...prev,
                      fileError: 'Solo puede subir archivos que sean formato pdf'
                    }));
                }
            } else {
                if(e.target.files.length>0){
                    setFormErrors(prev=>({
                        ...prev,
                        fileError: 'No puede subir mas archivos en esta sección, si desea cambiar o eliminar el documento, utilice el botón eliminar ubicado en la parte superior izquierda del archivo y cargue de nuevo otro documento'
                    }));
                }
            }
        } else if (name ===  "otherFiles"){
            if(e.target.files.length>0){
                if(e.target.files[0].type === 'application/pdf'){
                    if(e.target.files[0]){
                        setFormValues(prev => ({
                            ...prev,
                            [name]: [...formValues.otherFiles, {file:e.target.files[0], description:formValues.descriptionOtherDocument, previewUrl : URL.createObjectURL(e.target.files[0])}],
                        }));
                        setFormErrors(prev=>({
                            ...prev,
                            fileError: ''
                        }));
                    }
                    setFormValues(prev => ({
                        ...prev,
                        descriptionOtherDocument: ''
                    }));
                } else {
                    setFormErrors(prev=>({
                        ...prev,
                        fileError: 'Solo puede subir archivos que sean formato pdf'
                      }));
                }
            }
        }
    }

    const handleChangeform = (e: any) => {
        const { name, value } = e.target;
        setFormValues(prev => ({
            ...prev,
            [name]: value
        }));
        const error: string = Validations(name, value, formDocsValidations) || "";
        setFormErrors(prev=>({
            ...prev,
            [name]: error
        }));
    }

    const handleDeleteFile = async(fileToDelete:IFile, type: string) => {
        const name = fileToDelete.file?.name;
        let newFilesCV: Array<IFile> = formValues.filesCV;
        let newOtherFiles: Array<IFile> = formValues.otherFiles;
        if(type==="cv"){
            newFilesCV = formValues.filesCV && formValues.filesCV.filter((obj: IFile)=>obj.file?.name !== name);
        } else {
            newOtherFiles = formValues.otherFiles && formValues.otherFiles.filter((obj: IFile)=>obj.file?.name !== name);
        }
        if(fileToDelete.folderFileKey){
            const responseDeleteFile = await trackPromise(deleteFileS3(fileToDelete.folderFileKey));
            if(responseDeleteFile.status === 200){
                setFormValues(prev => ({
                    ...prev,
                    filesCV: newFilesCV,
                    otherFiles: newOtherFiles
                }));
                setSuccessMessage("Se ha eliminado correctamente el archivo")
                setOpenModalSuccess(true);
                setOpenModalError(false);
            } else {
                setErrorMessage("Hubo un error, intente de nuevo mas tarde");
                setOpenModalError(true);
                setOpenModalSuccess(false);
            }
        } else {
            setFormValues(prev => ({
                ...prev,
                filesCV: newFilesCV,
                otherFiles: newOtherFiles
            }));
        }
    }

    useEffect(()=>{
        if(formValues.filesCV.length === 1){
        setDisableUploadCV(true)
        } else {
        setDisableUploadCV(false)
        }
        if(formValues.descriptionDocument.length>0){
          setDescResult(true)
        } else{
          setDescResult(false)
        }
        if(formValues.descriptionOtherDocument.length>0){
            setDescOtherResult(true)
          } else{
            setDescOtherResult(false)
          }
    },[formValues.descriptionDocument, formValues.descriptionOtherDocument.length, formValues.filesCV.length])

    const saveFiles = async() =>{
        setInitLoad(true);
        let totalUrl: Array<IPresignedUrl> = [];
        const totalFiles = [...formValues.filesCV, ...formValues.otherFiles];
        let uploadDone: Array<boolean> = [];
        if(formValues.filesCV.length>0){
            const fileToUpload: IPreSignedUrlReq = {
                contentType: formValues.filesCV[0].file.type,
                fileKey: 'Egresado_hoja_de_vida.pdf',
                folderId: `Egresado-${userContext.userId || params.id}`,
                description: encodeURI(formValues.filesCV[0].description)
            }
            const result:IPresignedUrl = await getSignUrl(fileToUpload);
            result.name = fileToUpload.fileKey;
            if(result){
                totalUrl.push(result);
            }
        }
        if(formValues.otherFiles.length>0){
            for(let i = 0; i< formValues.otherFiles.length ; i++){
                const fileToUpload: IPreSignedUrlReq = {
                    contentType: formValues.otherFiles[i].file.type,
                    fileKey: formValues.otherFiles[i].file.name.replace(/[^a-zA-Z0-9_. ]/g, "").split(' ').join('_'),
                    folderId: `Egresado-${ userContext.roleSelected !== 1 ? params.id : userContext.userId}`,
                    description: encodeURI(formValues.otherFiles[i].description)
                }
                const result:IPresignedUrl = await getSignUrl(fileToUpload);
                result.name = fileToUpload.fileKey;
                if(result){
                    totalUrl.push(result);
                }
            }
        }
        if(totalUrl.length>0){
            for(let j=0; j<totalUrl.length; j++){
                const urlPresigned = totalUrl[j].uploadURL;
                const file = totalFiles[j].file;
                if(file){
                    const resultUpdload:Response = await trackPromise(uploadFileSignedUrl(urlPresigned, file));
                    if(resultUpdload){
                        uploadDone.push(true)
                    } else {
                        uploadDone.push(false)
                    }
                }
            }
        }
        setInitLoad(false);
        if(uploadDone.includes(false)){
            setErrorMessage("Hubo un error, intente de nuevo mas tarde");
            setOpenModalError(true);
            setOpenModalSuccess(false);
        } else {
            setSuccessMessage("Se ha guardado correctamente la información")
            setOpenModalSuccess(true);
            setOpenModalError(false);
        }
    }

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
    };

    const backBtnAction = () =>{
        userContext.setStateCurriculumSection("Información adicional");
    }


    return (
        <>
            <Grid item xs={12} lg={10} className="curriculum-form-info-container">
                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccoridon('panel1')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                            >
                            <Typography variant="h2" className="bold-serif">Adjuntar hoja de vida</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row">
                                <Grid container item xs={12} className="photography-content-container">
                                    <Typography variant="h5" className="description-documents-text regular-serif">Para adjuntar su hoja de vida de clic en el botón "Adjuntar archivo" y seleccione el documento que desea adjuntar. El documento debe estar en formato pdf y tener un peso máximo de XX Mb.</Typography>
                                    <Grid container item xs={12}>
                                        {formValues.filesCV.map((file:IFile, index:number)=>(
                                            <Grid container item xs={11} md={3} key={index} className="card-container">
                                                <div className="card-header-container">
                                                    <Typography variant="h5" className="regular-serif card-title-item">
                                                        Hoja de vida.pdf
                                                    </Typography>
                                                    <IconButton style={{padding:0, height: '30px'}} onClick={()=>handleDeleteFile(file,"cv")}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                                <Grid container item xs={12} className="card-desc-container">
                                                    <Typography variant="h6" className="regular-serif card-desc-item">
                                                    {file.description !== '' ? file.description : 'sin descripción'}
                                                    </Typography>
                                                </Grid>
                                                <Grid container item xs={12} justify="flex-end" className="card-action">
                                                    <div className="card-action-btn">
                                                    <Link target="_blank" rel="noopener" href={file.previewUrl}>
                                                        <Typography align="center" variant="h6" className="regular-serif">
                                                        Ver documento
                                                        </Typography>
                                                    </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Grid container item xs={12} justify="flex-start">
                                        <TextField
                                            fullWidth
                                            disabled={disabledUploadCV}
                                            label="Descripción del documento"
                                            name="descriptionDocument"
                                            value={formValues.descriptionDocument || ""}
                                            onChange={handleChangeform}
                                            error={!!formErrors.descriptionDocument}
                                            helperText={formErrors.descriptionDocument}
                                            placeholder="Descripción del documento"
                                            variant="outlined"
                                            margin="normal"
                                        />
                                        <div className="upload-button-container">
                                            <Button variant="contained" component="label" className="upload-button" disabled={disabledUploadCV || !!formErrors.descriptionDocument || !descResult}>
                                                <Typography variant="h5" className="regular-serif">
                                                    Adjuntar documento
                                                </Typography>
                                                <div>
                                                    <input type="file" name="filesCV" onChange={handleChangeDocuments} accept="application/pdf" hidden/>
                                                </div>
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccoridon('panel2')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                            >
                            <Typography variant="h2" className="bold-serif">Otros documentos</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row">
                                <Grid container item xs={12} className="photography-content-container">
                                    <Typography variant="h5" className="description-documents-text regular-serif">En esta sección puede adjuntar otros documentos como soportes y certificados laborales. Para adjuntar el documento de clic en el botón "Adjuntar archivo" y seleccione el documento que desea adjuntar. El documento debe estar en formato pdf y tener un peso máximo de XX Mb.</Typography>
                                    <Grid container item xs={12} justify="space-around">
                                        {formValues.otherFiles.map((otherFile: IFile, index: number)=>(
                                            <Grid container item xs={11} md={3} key={index} className="card-container">
                                                <div className="card-header-container">
                                                    <Typography variant="h5" className="regular-serif card-title-item">
                                                        {otherFile.file?.name}
                                                    </Typography>
                                                    <IconButton style={{padding:0, height: '30px'}} onClick={()=>handleDeleteFile(otherFile,"other")}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                                <Grid container item xs={12} className="card-desc-container">
                                                    <Typography variant="h6" className="regular-serif card-desc-item">
                                                    {otherFile.description !== '' ? otherFile.description : 'sin descripción'}
                                                    </Typography>
                                                </Grid>
                                                <Grid container item xs={12} justify="flex-end" className="card-action">
                                                    <div className="card-action-btn">
                                                    <Link target="_blank" rel="noopener" href={otherFile.previewUrl}>
                                                        <Typography align="center" variant="h6" className="regular-serif">
                                                        Ver documento
                                                        </Typography>
                                                    </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Grid container item xs={12} justify="flex-start">
                                        <TextField
                                            fullWidth
                                            label="Descripción del documento"
                                            name="descriptionOtherDocument"
                                            value={formValues.descriptionOtherDocument || ""}
                                            onChange={handleChangeform}
                                            error={!!formErrors.descriptionOtherDocument}
                                            helperText={formErrors.descriptionOtherDocument}
                                            placeholder="Descripción del documento"
                                            variant="outlined"
                                            margin="normal"
                                        />
                                        <div className="upload-button-container">
                                            <Button variant="contained" component="label" className="upload-button" disabled={!!formErrors.descriptionOtherDocument || !descOtherResult}>
                                                <Typography variant="h5" className="regular-serif">
                                                    Adjuntar documento
                                                </Typography>
                                                <div>
                                                    <input type="file" name="otherFiles" onChange={handleChangeDocuments} accept="application/pdf" hidden/>
                                                </div>
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <>
                {
                    userContext.roleSelected === 9?
                    <></>
                    :
                    <>
                        <Grid container item xs={12}>
                            <Grid container item xs={12} md={3} lg={6} className="step-curriculum-buttons">
                                <Button onClick={backBtnAction} className="back-button" variant="contained" size="small">
                                    <Typography variant="h5" className="regular-serif">Regresar</Typography>
                                </Button>
                            </Grid>
                            <Grid container item xs={12} md={9} lg={6} className="buttons-curriculum-form-container">
                                <Button onClick={saveFiles} className="save-button" variant="contained" size="small">
                                    <Typography variant="h5" className="regular-serif">Guardar</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
                </>
            </Grid>
            <Grid container item xs={12} lg={2} justify="center" style={{height:'fit-content'}}>
                <NotificationBox
                bgColor="#CFF4FC"
                title="Documentos adjuntos"
                titleColor="#055160"
                description="Por favor adjunte su hoja de vida y soportes en esta sección. Recuerde que los documentos deben estar en formato pdf, con un peso máximo de XX Mb."
                descColor="#055160"
                icon={<ErrorOutlineIcon style={{color:'#055160'}} fontSize="large"/>}
                />
            </Grid>
            <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
            <LoadingLockPage load={promiseInProgress}/>
            <LoadingLockPage load={initLoad}/>
        </>
    )
}

export default Attacments