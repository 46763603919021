import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ReCAPTCHA from "react-google-recaptcha";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Validations from '../../../helpers/Validations';
import SearchIcon from '@material-ui/icons/Search';
import ShowState from '../../../components/ShowStateBox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { getStateUser, getStateCompany } from '../../../services/ConsultService';
import LoadingLockPage from "../../../components/ShareComponents/LoadingLockPage";
import './ConsultState.scss';

const initialDocValues = {
  document: "",
  nit:""
}

const initialDocErrors = {
    document: "",
    nit:""
}

const docValidations = {
    document: {
      error: "",
      validate: "number",
      minLength: 6,
      maxLength: 10,
      required: true
    },
    nit: {
      error: "",
      validate: "number",
      minLength: 7,
      maxLength: 15,
      required: true
    }
}

const ConsultState: React.FC = () =>{
  const { promiseInProgress } = usePromiseTracker();
  const SIEkey = process.env.REACT_APP_SIE_SITE_KEY_RECAPTCHA;
  const [formValues, setFormValues] = useState(initialDocValues);
  const [formErrors, setFormErrors] = useState(initialDocErrors);
  const [disabledSend, setDisableSend]= useState(false);
  const [consult, setConsult] = useState(false);
  const [resultState, setResultState] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userUsername, setUserUsername] = useState('');
  const [id, setId] = useState('');
  const [captchaVerification, setCaptchaVerification] = useState(false);
  const [consultError, setConsultError] = useState('');
  const [check, setCheck] = useState(false);
  const [value, setValue] = React.useState('');
  const widthResolution = window.innerWidth;

  let recaptchaRef: ReCAPTCHA | null;

  const isValid =
    formValues.document.length > 0 &&
    !formErrors.document ||
    formValues.nit.length > 0 &&
    !formErrors.nit

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  useEffect(()=>{
    if( isValid && captchaVerification && check){
      setDisableSend(true);
    } else {
      setDisableSend(false);
    }
  },[isValid, captchaVerification, check]);

  const resetRecaptcha = () => {
    recaptchaRef?.reset();
    setFormValues(prev => ({
      ...prev,
    }))
    setCaptchaVerification(false)
  };


  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setConsultError('')
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
    const error = Validations(name, value, docValidations) || "";
    setFormErrors(prev=>({
        ...prev,
        [name]: error
    }));
  }

  const makeConsult = async() =>{
    if(value === 'cc'){
      const response: string = await trackPromise(getStateUser(formValues));
      if(response !== undefined){
        setResultState(response)
        setId(formValues.document)
        setConsult(true)
      }
      else {
        setResultState('')
        setConsult(false)
        setConsultError('El usuario no se encuentra registrado en el sistema')
      }
    }else if(value === 'nit'){
      formValues.nit = formValues.document
      const response: any = await trackPromise(getStateCompany(formValues));
      console.log(response);      
      if(response?.state !== undefined){
        setResultState(response.state)
        setId(formValues.nit)
        setUserEmail(response.email)
        setUserUsername(response.username)
        setConsult(true)
      }
      else {
        setResultState('')
        setConsult(false)
        setConsultError('La empresa no se encuentra registrado en el sistema')
      }
    }

  }

  const onChange =(response:any)=> {
    if(response){
      setFormValues(prev => ({
        ...prev,
      }))
      setCaptchaVerification(true)
    } else {
      setFormValues(prev => ({
        ...prev,
      }))
      setCaptchaVerification(false)
    }
  }

  const radioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setCheck(true);
  };

  return (
    <>
    <Grid container className="consultState-container">
      <Grid container className="main-container">
        <Grid container item xs={12} justify="center" alignItems="center">
          <Grid container item xs={12}>
            { !consult ?
              <Grid container className="consult-container ">
                <Grid container item xs={12} className="consult-content line">
                  <Typography variant="h2" className="bold-serif-primaryColor">
                    Consulta estado de su registro
                  </Typography>
                  <Grid  container item xs={12}>
                    <Typography variant="h5" className="summary-description">Para consultar el estado de su registro ingrese su número de identificación</Typography>
                  </Grid>
                  <Grid  container item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel className="number-identification" component="legend">
                        <Typography variant="h5" className="regular-serif">
                          Número de identificación
                        </Typography>
                      </FormLabel>
                      <RadioGroup row className="radio-container" aria-label="documentType" name="docType" value={value} onChange={radioChange}>
                        <FormControlLabel value="cc" labelPlacement="start" className="radio-btn" control={<Radio className="color-btn"/>} label="C.C.:" />
                        <FormControlLabel value="nit" labelPlacement="start" className="radio-btn" control={<Radio  className="color-btn"/>} label="NIT:" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <TextField
                      fullWidth
                      name="document"
                      placeholder="Ingrese su número de documento"
                      value={formValues.document}
                      onChange={handleChange}
                      error={!!formErrors.document}
                      helperText={formErrors.document}
                      margin="none"
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} className="h7-regular-primaryError">
                    {consultError}
                  </Grid>
                </Grid>
                <Grid item xs={12} className="recaptcha-container">
                  <div className="recaptcha-widget">
                    <ReCAPTCHA
                      sitekey={SIEkey ? SIEkey : ''}
                      onChange={onChange}
                      ref={e => recaptchaRef = e}
                      size={widthResolution >= 540 ? "normal" : "compact"}
                    />
                      <Button variant="contained" className="reset-recaptcha-btn" startIcon={<RotateLeftIcon />} onClick={resetRecaptcha}>
                      REINTENTAR
                    </Button>
                  </div>
                </Grid>
                <Grid container item xs={12} sm={6} className="consult-button">
                    <Button disabled={!disabledSend} variant="contained" className="const-btn" endIcon={<SearchIcon />} onClick={makeConsult} >
                    CONSULTAR
                  </Button>
                </Grid>
                <LoadingLockPage load={promiseInProgress}/>
              </Grid>
            : <ShowState resultState={resultState} value={value} id={id} email= {userEmail} username={userUsername}/>
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </>
  )
}

export default ConsultState