import { IGeneralParameter } from './IGeneralParameter';
import { IGeneralParOffer } from './IGeneralParOffer';

export class IGetOffer{
    id: number = 0;
    academicLevel: number = 0;
    city: number = 0;
    company: number = 0;
    country: number = 0;
    createdAt: string = "";
    department: number = 0;
    description: string = "";
    descriptionVariableSalary: string = "";
    estimatedDateCompletion: string = "";
    experienceDays: number = 0;
    experienceMonths: number = 0;
    experienceYears: number = 0;
    jobOfferEndDate: string = "";
    jobOfferStartDate: string = "";
    jobPosition: number = 0;
    jobTitle: string = "";
    knowlegeArea: Array<IGeneralParOffer> = [];
    knowlegeAreaParams?: Array<IGeneralParameter> = [];
    laboralSector: number = 0;
    language: Array<IGeneralParOffer> = [];
    languageParams?: Array<IGeneralParameter> = [];
    minValueSalary: number = 0;
    nameCompany: string = "";
    nameCompanyVisible: boolean = false;
    numberOfPostulates: number = 0;
    numberVacancy: number = 0;
    observation: string = "";
    otherSkills: string = "";
    otherSkillsParams?: Array<{name:string}> = [];
    professions: Array<IGeneralParOffer> = [];
    professionsParams?: Array<IGeneralParameter> = [];
    rangeSalary: string = "";
    reopened: string | null = null;
    salaryVisible: boolean = false;
    skills: Array<IGeneralParOffer> = [];
    skillsParams?: Array<IGeneralParameter> = [];
    state: string = "";
    typeContract: number = 0;
    typePosition: number = 0;
    typePositionName: string = "";
    updatedAt: string = "";
    variableSalary: boolean = false;
    keywords: string = "";
    keywordsParams?:Array<{name:string}> = [];
    occupations: Array<IGeneralParOffer> = [];
    occupationsParams?: Array<IGeneralParameter> = [];
    programs: Array<IGeneralParOffer> = [];
    programsParams?: Array<IGeneralParameter> = [];
}