import React, { useState, useEffect } from 'react';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import JobOffersSearch from '../../../../components/LaboralOffers/JobOffersSearch';
import { IFilterJobOffers } from '../../../../interfaces/IFilterJobOffers';
import Validations from '../../../../helpers/Validations';
import OffersListFilters from '../../../../components/LaboralOffers/OffersListFilters';
import { IGetOffer } from '../../../../interfaces/IGetOffer';
import { getlistOffers, getOffersByGraduate } from '../../../../services/LaboralOfferService';
import MoreFiltersOffers from '../../../../components/LaboralOffers/MoreFIltersOffers';
import { IGeneralParameter } from '../../../../interfaces/IGeneralParameter';
import OffersGraduateStateFilters from '../../../../components/LaboralOffers/OffersGraduateStateFilters';
import GraduateOffersList from '../../../../components/LaboralOffers/GraduateOffersList';
import UserContext from '../../../../utils/UserContext';
import ShowFilters from '../../../../components/ManagementState/ShowFilters';
import { ChipData } from '../../../../interfaces/ChipData';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import AlertBox from '../../../../components/ShareComponents/AlertBox';
import { sortByKey, sortByKeyDesc } from '../../../../helpers/sharedFunctions';
import { getCITY, getDEPARTAMENT, getOccupationType, getTypeOfContract } from '../../../../services/ParameterService';
import ministerioTrabajo from '../../../../assets/images/LogoMinisterioDelTrabajo.webp';
import logoUSPE from '../../../../assets/images/LogoUSPE.webp';
import './GraduateOffersView.scss';


interface GraduateOffersViewProps{
    nameProfile: string;
}

const publishDateArray = [
    {id:1, value:1, label:'Hoy'},
    {id:2, value:2, label:'Últimos tres días'},
    {id:3, value:3, label:'Última semana'},
    {id:4, value:4, label:'Último mes'},
]

const salaryArray = [
    {id:1, value:1, label:'Menos de $1.000.000'},
    {id:2, value:1000000, label:'Más de $1.000.000"'},
    {id:3, value:2000000, label:'Más de $2.000.000'},
    {id:4, value:3000000, label:'Más de $3.000.000'},
    {id:5, value:4000000, label:'Más de $4.000.000'},
]

const offersValidations = {
    keyWord: {
      error: "",
      validate: "textNumber",
      required: false
    },
    city: {
        validate: "select",
        required: false
    },
    department: {
        validate: "select",
        required: false
    },
    typeContract: {
        validate: "select",
        required: false
    }
}

const GraduateOffersView: React.FC<GraduateOffersViewProps> = ({ nameProfile }) => {
    const userContext = React.useContext(UserContext);
    const { promiseInProgress } = usePromiseTracker();
    const [formValues, setFormValues] = useState<IFilterJobOffers>(new IFilterJobOffers());
    const [formErrors, setFormErrors] = useState<IFilterJobOffers>(new IFilterJobOffers());
    const [valuedate, setValueDate] = useState<number>(0);
    const [valueSalary, setValueSalary] = useState<number>(0);
    const [offersList, setOffersList] = useState<Array<IGetOffer>>([]);
    const [offersFiltered, setOffersFiltered] = useState<Array<IGetOffer>>([]);
    const [idOrder, setIdOrder]= useState<number>(0);
    const { isArray } = Array;
    const [chipData, setChipData] = React.useState<ChipData[]>([]);
    const [departmentArray, setDepartmentArray] = useState<Array<IGeneralParameter>>([]);
    const [cityArray, setCityArray] = useState<Array<IGeneralParameter>>([]);
    const [cities, setCities] = useState<Array<IGeneralParameter>>([]);
    const [allTypeContract, setAllTypeContract] = useState<Array<IGeneralParameter>>([]);
    const [searchActive, setSearchActive] = useState<boolean>(false);
    const [clearActive, setClearActive] = useState<boolean>(false);
    const [selected, setSelected] = useState<string>('');
    const isValid =
        (
            formValues.keyWord.length>0 &&
            !formErrors.keyWord
        ) ||
        (
            !formErrors.keyWord &&
            (
                formValues.department > 0 ||
                formValues.city > 0
            )
        )

    useEffect(()=>{
        window.scrollTo(0, 0);
        if(userContext.statePostulation){
            setSelected(userContext.statePostulation)
        } else {
            setSelected('Convocatoria abierta')
        }
        const getData = async() =>{
            if(userContext.userId){
                if(userContext.statePostulation === "Mis postulaciones"){
                    const resOffers: Response = await trackPromise(getOffersByGraduate(parseInt(userContext.userId)));
                    getInfoData(resOffers);
                } else {
                    const resOffers: Response = await trackPromise(getlistOffers({state: 'Convocatoria abierta'}));
                    getInfoData(resOffers);
                }
            }
            let dataDepartment: Array<IGeneralParameter> = await getDEPARTAMENT();
            dataDepartment = sortByKey(dataDepartment, 'name');
            setDepartmentArray(dataDepartment ? dataDepartment : []);
            let dataCity: Array<IGeneralParameter> = await getCITY();
            dataCity = sortByKey(dataCity, 'name');
            setCityArray(dataCity ? dataCity : []);
            const responseContract: Array<IGeneralParameter> = await getTypeOfContract();
            setAllTypeContract(responseContract);
        }
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getInfoData = async (responseService: Response) =>{
        if(responseService.status === 200){
            const resultOffers = await responseService.json();
            const responsePostion: Array<IGeneralParameter> = await getOccupationType();
            resultOffers.jobOffers.forEach((offer: IGetOffer) => {
                if(offer.rangeSalary.length>0){
                    const numberSalary = (offer.rangeSalary.split(" "))[0].replace(/\D/g, '');
                    offer.minValueSalary = parseInt(numberSalary);
                } else {
                    offer.minValueSalary = 0;
                }
                const TypeName = responsePostion.find(elm => elm.parameter_code === offer.typePosition);
                if(TypeName){
                    offer.typePositionName = TypeName.name;
                }
            })
            setOffersList(resultOffers.jobOffers);
            setOffersFiltered(resultOffers.jobOffers);
        }
    }

    const handleClick = async(e:any, state:string)=>{
        setSelected(state);
        userContext.setStatePostulation(state);
        if(userContext.userId && state){
            if(state === "Convocatoria abierta"){
                const resOffersCompany: Response = await trackPromise(getlistOffers({state:state}));
                getInfoData(resOffersCompany);
            } else if (state === "Mis postulaciones"){
                const responseOBG = await trackPromise(getOffersByGraduate(parseInt(userContext.userId)));
                if(responseOBG.status === 200){
                    getInfoData(responseOBG);
                }
            }
        }
    }

    useEffect(()=>{
        setCities([]);
        if(formValues.department>0){
            let citiesDep:Array<IGeneralParameter> = cityArray.filter(city=> city.parentId === formValues.department);
            citiesDep = sortByKey(citiesDep, 'name');
            if(citiesDep.length>0){
                setCities(citiesDep)
            }
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formValues.department])

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormValues(prev => ({
            ...prev,
            [name]: value
        }));
        const error = Validations(name, value, offersValidations) || "";
        setFormErrors(prev=>({
            ...prev,
            [name]: error
        }));
        if(name === 'keyWord'){
            if(value !== ''){
                setSearchActive(true)
            } else {
                setSearchActive(false)
            }
        }
    }

    const radioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueDate(parseInt((event.target as HTMLInputElement).value));
    };

    const radioChangeSalary = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueSalary(parseInt((event.target as HTMLInputElement).value));
    };

    const filterOrder = (idOrder:number, action: string)=>{
        let tempArr: Array<IGetOffer> = offersFiltered;
        if(idOrder === 1){
            tempArr = sortByKeyDesc(tempArr, action);
        } else if (idOrder === 2){
            tempArr = sortByKey(tempArr, action);
        } else if (idOrder === 3){
            tempArr = sortByKeyDesc(tempArr, action);
        }
        setOffersFiltered(tempArr);
        setIdOrder(idOrder);
    }

    const requestSearch = () =>{
        let searchKeyWord: Array<IGetOffer> = [];
        let searchDep: Array<IGetOffer> = [];
        let searchCity: Array<IGetOffer> = [];
        let searchDate: Array<IGetOffer> = [];
        let searchSalary: Array<IGetOffer> = [];
        let searchContract: Array<IGetOffer> = [];
        if(formValues.keyWord !== ""){
            const newArray = search(offersList, formValues.keyWord);
            searchKeyWord = newArray;
        } else {
            searchKeyWord = offersList;
        }
        if(formValues.department>0){
            const newArray = searchKeyWord.filter(item => item.department === formValues.department);
            searchDep = newArray;
        } else {
            searchDep = searchKeyWord
        }
        if(formValues.city>0){
            const newArray = searchDep.filter(item => item.city === formValues.city);
            searchCity = newArray;
        } else {
            searchCity = searchDep;
        }
        if(valuedate >=1){
            const today = moment(new Date()).format('YYYY-MM-DD');
            const tommorrow = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
            const days = moment(new Date()).add(-3, 'days').format('YYYY-MM-DD');
            const week = moment(new Date()).add(-7, 'days').format('YYYY-MM-DD');
            const month = moment(new Date()).add(-31, 'days').format('YYYY-MM-DD');
            const newArray = searchCity.filter(item => {
                const offerdate = moment(item.jobOfferStartDate).format('YYYY-MM-DD');
                if(valuedate === 1){
                    return today === offerdate
                } else if (valuedate === 2){
                    return moment(offerdate).isBetween(days, tommorrow);
                } else if (valuedate === 3){
                    return moment(offerdate).isBetween(week, tommorrow);
                } else if (valuedate === 4){
                    return moment(offerdate).isBetween(month, tommorrow);
                }
            });
            searchDate = newArray;
        } else {
            searchDate = searchCity;
        }
        if(valueSalary >= 1){
            let newArray = [];
            if(valueSalary === 1){
                newArray = searchDate.filter(item => item.minValueSalary < 1000000);
            } else {
                newArray = searchDate.filter(item => item.minValueSalary > valueSalary);
            }
            searchSalary = newArray;
        } else {
            searchSalary = searchDate;
        }
        if(formValues.typeContract >0){
            const newArray = searchSalary.filter(item => item.typeContract === formValues.typeContract);
            searchContract = newArray;
        } else {
            searchContract = searchSalary;
        }
        setOffersFiltered(searchContract);
        setClearActive(false);
        setChips();
    }

    const search = (entries: any[], searchVal: string) => {
        searchVal = searchVal.toLowerCase();
        return entries.filter((obj) => {
            const keys: string[] = Object.keys(obj);
            return keys.some((key) => {
                const value = obj[key];
                if(typeof(value) !== 'object'){
                    if (isArray(value)) {
                        return value.some(v => {
                            return v.toLowerCase().includes(searchVal);
                        });
                    }
                    else if (!isArray(value)) {
                        if (typeof value === "string") {
                            return value.toLowerCase().includes(searchVal);
                        }
                    }
                }
            })
        });
    }

    const cancelSearch = ()=>{
        setFormValues(prev => ({
            ...prev,
            keyWord: ''
        }));
        setFormErrors(prev=>({
            ...prev,
            keyWord: ''
        }));
        setSearchActive(false)
    }

    const clearFilter = ()=>{
        setClearActive(true);
        setFormValues(prev => ({
            ...prev,
            typeContract: 0
        }));
        setValueDate(0);
        setValueSalary(0);
        setChips();
    }

    useEffect(()=>{
        requestSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[clearActive])

    useEffect(()=>{
        if((!searchActive && formValues.keyWord === "") && formValues.department <= 0 ){
            requestSearch();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchActive, formValues.department, formValues.city])

    const setChips =()=>{
        let tempArray: Array<ChipData> = [];
        let index = 0;
        const fieldNames=[
            {key: 'keyWord', description: 'Palabra clave'},
            {key: 'department', description: 'Departamento'},
            {key: 'city', description: 'Municipio'},
            {key: 'typeContract', description: 'Tipo de contrato'},
        ]
        for (const [key, value] of Object.entries(formValues)) {
            if(value!==0 && value!=='' && value!==null ){
                const fieldObj = fieldNames.find(field=>field.key === key);
                let obj;
                if(key === 'department'){
                    obj = departmentArray.find(dep=>dep.parameter_code === value);
                } else if (key === 'city'){
                    obj = cities.find(city=>city.parameter_code === value);
                } else if (key === 'typeContract'){
                    obj = allTypeContract.find(Tcontract=>Tcontract.parameter_code === value );
                }
                if(obj){
                    tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + obj.name })
                } else {
                    tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + value})
                }
                index++;
            }
        }
        if (valuedate > 0 ){
            const obj = publishDateArray.find(salary=>salary.value === valuedate );
            tempArray.push({key: tempArray.length + 1, name: 'publishDate', label: 'Fecha de publicación: ' + obj?.label})
        }
        if (valueSalary > 0){
            const obj = salaryArray.find(salary=>salary.value === valueSalary );
            tempArray.push({key: tempArray.length + 1, name: 'rangeSalary', label: 'Salario: ' + obj?.label})
        }
        setChipData(tempArray);
    }

    return (
        <>
            <Grid item xs={12} className="state-offers-section">
                <OffersGraduateStateFilters selected={selected} handleClick={handleClick} offersNumber={offersFiltered.length}/>
            </Grid>
            <Grid container className="main-container">
                <Grid container item xs={12}>
                    <AlertBox
                        title="Información"
                        alert="info"
                        variantType="outlined"
                    >
                        {
                            userContext.statePostulation === "Convocatoria abierta" &&
                            <span className="alert-simple-text">
                                En esta sección usted puede buscar ofertas laborales, ver el detalle y aplicar a una oferta laboral.
                            </span>
                        }
                        {
                            userContext.statePostulation === "Mis postulaciones" &&
                            <span className="alert-simple-text">
                                En esta sección encontrará las ofertas a las que ya ha aplicado. Si desea retirarse de alguna oferta puede hacerlo en el detalle de la oferta
                            </span>
                        }
                    </AlertBox>
                </Grid>
                <Grid container item xs={12}>
                    <JobOffersSearch formValues={formValues} formErrors={formErrors} onChangeForm={handleChange} departmentArray={departmentArray} cities={cities}  requestSearch={requestSearch} searchActive={searchActive} cancelSearch={cancelSearch} isValid={isValid}/>
                </Grid>
                <Grid container item xs={12} className="title-container-result">
                    <Grid container item xs={12} md={3} lg={4}>
                        <Grid className="title-section-line">
                            <SearchIcon className="seach-page-icon"/>
                            <Typography variant="h2" className="bold-serif" >
                                {
                                    isValid ?
                                    <span>Resultado de la busqueda</span>
                                :
                                    <span>Ofertas Laborales</span>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={9} lg={8} className="line-result" />
                </Grid>
                <Grid item xs={12}>
                    <MoreFiltersOffers formValues={formValues} publishDateArray={publishDateArray} salaryArray={salaryArray} allTypeContract={allTypeContract} valuedate={valuedate} valueSalary={valueSalary} radioChange={radioChange} radioChangeSalary={radioChangeSalary} onChangeForm={handleChange} requestSearch={requestSearch} clearFilter={clearFilter}/>
                </Grid>
                <Grid container item xs={12}>
                    <ShowFilters titleSection="Filtros seleccionados" filtersData={chipData} stateCheck={false} showButtons={false} showDelete={false} />
                </Grid>
                <Grid container item xs={12}>
                    <OffersListFilters filterOrder={filterOrder} bgColor="#AFD75E" colorText="#004B73"/>
                </Grid>
                {offersFiltered.length > 0 &&
                    <Grid container item xs={12}>
                        <GraduateOffersList offersList={offersFiltered} idOrder={idOrder}/>
                    </Grid>
                }
                <Grid container  justify='space-around' alignItems='center' xs={12} >
                    <Grid item xs={6} sm={3}>
                        <img src={logoUSPE} className= "img-fluid" />
                    </Grid>
                    <Grid item xs={5} sm={2}>
                        <img src={ministerioTrabajo} className= "img-fluid" />   
                    </Grid>
                </Grid>
            </Grid>
            <LoadingLockPage load={promiseInProgress}/>
        </>
    )
}

export default GraduateOffersView;