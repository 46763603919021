import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { StateByRole } from '../../../interfaces/StateByRole';

import './AditionalData.scss';

interface AditionalDataProps{
    title: string;
    stateCheck:any
    onChangeState: any;
    stateOptions: Array<StateByRole>;
}

const AditionalData:React.FC<AditionalDataProps> = ({title, stateCheck, onChangeState, stateOptions})=>{
    return(
        <Grid container item xs={12} className="select-report-container">
            <Grid container item xs={12} className="title-container">
                <Typography variant="h4" className="title">
                    {title}
                </Typography>
            </Grid>
            <Grid container className="select-report-content">
                <Grid container item xs={12}  className="select-report-options">
                    <FormControl component="fieldset">
                        <FormGroup className="group-checkbox">
                            {stateOptions.map((option: StateByRole) => {
                                const checkedValue = stateCheck[option.value]
                                return (
                                    option.value !== 'Ofertas' &&
                                    <FormControlLabel key={option.id} className="label-checkbox"
                                        control={<Checkbox checked={checkedValue} onChange={onChangeState} name={option.value} />}
                                        label={option.label}
                                    />
                                )
                            })}
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AditionalData;