export const permissionGraduateView = [1]; //Visualizador de egresados - Como Admin
export const permissionGraduateActiveInactiveState = [2]; //Cambiar estado: Activo, Inactivo - Como Admin a Egresados
export const permissionGraduateVerifyRejectState = [3]; //Cambiar estado: Verificado, Rechazado - Como Admin a Egresados
export const permissionCompanyView = [4]; //Visualizador de empresas - Como Admin
export const permissionCompanyActiveRejectedState = [5]; // Cambiar estado: Activo, Rechazado -  - Como Admin a Empresas
export const permissionCompanyInactiveState = [6]; // Cambiar estado: Inactivo -  - Como Admin a Empresas
export const permissionCompanyModifyProfile = [7]; // Modicar datos de la empresa - Como empresa
export const permissionCompanyHeadquartersView = [8]; // Visualizador de sedes- Como empresa
export const permissionCompanyProfilesView = [9]; // Visualizador de perfiles empresa - Como empresa
export const permissionCompanyOffersView = [10]; // Visualizador de las ofertas laborales - Como empresa
export const permissionCreateLaboralOffer = [11]; //Crear oferta laboral - Como empresa
export const permissionAdminModifyOffer = [12]; // Modificar oferta laboral administradores - Como Admin
export const permissionAdminViewOfferDetail = [13]; // Ver detalle de la oferta laboral - Como Admin
export const permissionAdminConvocatoryCancelOffer = [14]; // Cambiar estado: Convocatoria abierta, Cancelada - Como Admin a ofertas
export const permissionCompanyChangeStateOffer = [15]; // Cambiar estado: En definición, En selección, Finalizada - Como Empresa a ofertas
export const permissionGraduateMassMessageView = [16]; // Mensajeria masiva egresados - Como admin
export const permissionCompanyMassMessageView = [17]; // Mensajeria masiva empresas - Como admin
export const permissionCompanyCreateHeadquarter = [18]; // Crear sede - Como empresa
export const permissionCompanyEditHeadquarter = [19]; // Editar sede - Como empresa
export const permissionCreateCompanyProfiles = [20]; // Crear perfiles empresa - Como empresa
export const permissionAdminModifyOfferDates = [21]; // Modificar fechas oferta laboral empresas - Como Admin
export const permissionsNewsView = [22]; // Visualizador de noticias y eventos - Como admin
export const permissionAdminCreateNewEvent = [23]; //Crear noticia o evento - Como Admin
export const permissionAdminModifyNewEvent = [24]; // Modificar noticia o evento - Como Admin
export const permissionSubscribeEvent = [25]; // Inscripción al evento - Como Egresado
export const permissionAdminSurveysView = [26]; // Visualizador de encuestas - Como Admin
export const permissionAdminCreateSurvey = [27]; // Crear encuesta - Como Admin
export const permissionAdminModifySurvey = [28]; // Modificar encuestas - Como Admin
export const permissionAdminModifyGraduates = [29]; // Editar egresados - Como Admin
export const permissionAdminSeeInfoGraduates = [30]; // Ver información de egresados - Como Admin
export const permissionAdminSeeCompanyOffers = [31]; // Visualizador Ofertas de empresas - Como admin
export const permissionCompanyDeleteHeadquarters = [32]; // Eliminar sedes - Como empresa
export const permissionCompanyEditCompanyProfiles = [33]; // Editar perfiles de empresas - Como empresa
export const permissionCompanyActiveInactiveCompanyProfiles = [34]; // Activar y desactivar perfiles de empresas - Como empresa
export const permissionAdminModifyCompanies = [35]; // Editar empresas - Como Admin
export const permissionAdminSeeInfoCompanies = [37]; // Ver información de empresas. - Como Admin
export const permissionAdminSeeNewOffers = [38]; // Visualizador de ofertas laborales nuevas - Como Admin
export const permissionAdminSendMassMsgGraduates = [40]; // Envio de la mensajeria masiva del lado del egresado - Como Admin
export const permissionAdminSendMassMsgCompanies = [42]; // Envio de la mensajeria masiva del lado de la empresa - Como Admin
export const permissionAdminGraduateReportsView = [43]; // Opción de reportes para egresados - Como Admin
export const permissionAdminDinamicReportsGraduates = [44]; // Generar reporte de egresados dinamico - Como Admin
export const permissionAdminStaticReportsGraduates = [45]; // Generar reporte de egresados predeterminados - Como Admin
export const permissionAdminCompanyReportsView = [46]; // Opción de reportes para empresas - Como Admin
export const permissionAdminDinamicReportsCompanies = [47]; // Generar reporte para empresas dinamico - Como Admin
export const permissionAdminStaticReportsCompanies = [48]; // Generar reporte para empresas predeterminados - Como Admin
export const permissionAdminOffersReportsView = [49]; // Opción de reportes para ofertas laborales - Como Admin
export const permissionAdminDinamicReportsOffers = [50]; // Generar reporte para ofertas laborales dinamico - Como Admin
export const permissionAdminStaticReportsOffers = [51]; // Generar reporte para ofertas laborales predeterminados - Como Admin
export const permissionAdminRolesManagementView = [52]; // Gestión de roles usuarios - Como Admin
export const permissionAdminRolesPermissionsView = [53]; // Gestión de permisos - Como Admin
