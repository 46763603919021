import React, { useState, useEffect } from "react";
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { IGetOffer } from "../../../interfaces/IGetOffer";
import LoadingLockPage from "../../ShareComponents/LoadingLockPage";
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { getACADEMIC_LEVEL, getAREA, getCOMPETENCE, getLANGUAGE, getSubArea } from "../../../services/ParameterService";
import "./DescriptionOfferBox.scss";
import UserContext from "../../../utils/UserContext";
import { isLogin } from "../../../services/AuthService";



interface DescriptionOfferBoxProps {
  dataOffer: IGetOffer;
  typePosition: string;
  laboralSector: string;
  contract: string;
  showButtons: boolean;
  handleApply?: any;
  userApply?: boolean;
}

const DescriptionOfferBox: React.FC<DescriptionOfferBoxProps> = ({ dataOffer, typePosition, laboralSector, contract, showButtons, handleApply, userApply }) => {
  const userContext = React.useContext(UserContext);
  const { promiseInProgress } = usePromiseTracker();
  const [areas, setAreas] = useState<string>('');
  const [subAreas, setSubAreas] = useState<string>('');
  const [skills, setSkills] = useState<string>('');
  const [academicLevel, setAcademicLevel] = useState<string>('');
  const [language, setLanguage] = useState<string>('');
  const isLogg = isLogin();

  useEffect(() => {

    const getData = async () => {
      const resAreas: Array<IGeneralParameter> = await trackPromise(getAREA());
      const resSubAreas: Array<IGeneralParameter> = await trackPromise(getSubArea());
      const resSkills: Array<IGeneralParameter> = await trackPromise(getCOMPETENCE());
      const resAcademic: Array<IGeneralParameter> = await trackPromise(getACADEMIC_LEVEL());
      const resLang: Array<IGeneralParameter> = await trackPromise(getLANGUAGE());
      let areasArray: Array<string> = [];
      let subAreasArray: Array<string> = [];
      let skillsArray: Array<string> = [];
      let langArray: Array<string> = [];
      if (dataOffer.knowlegeArea && dataOffer.knowlegeArea.length > 0) {
        dataOffer.knowlegeArea.forEach(item => {
          const elemFound = resAreas.find(obj => obj.parameter_code === item.idParameter);
          if (elemFound) {
            areasArray.push(' ' + elemFound.name);
          }
        })
        if (areasArray.length > 0) {
          setAreas(areasArray.toString())
        }
      }
      if (dataOffer.professions && dataOffer.professions.length > 0) {
        dataOffer.professions.forEach(item => {
          const elemFound = resSubAreas.find(obj => obj.parameter_code === item.idParameter);
          if (elemFound) {
            subAreasArray.push(' ' + elemFound.name);
          }
        })
        if (subAreasArray.length > 0) {
          setSubAreas(subAreasArray.toString())
        }
      }
      if (dataOffer.skills && dataOffer.skills.length > 0) {
        dataOffer.skills.forEach(item => {
          const elemFound = resSkills.find(obj => obj.parameter_code === item.idParameter);
          if (elemFound) {
            skillsArray.push(' ' + elemFound.name);
          }
        })
        if (skillsArray.length > 0) {
          setSkills(skillsArray.toString())
        }
      }
      if (resAcademic.length > 0) {
        const elemFound = resAcademic.find(obj => obj.parameter_code === dataOffer.academicLevel);
        if (elemFound) {
          setAcademicLevel(' ' + elemFound.name);
        }
      }
      if (dataOffer.language && dataOffer.language.length > 0) {
        dataOffer.language.forEach(item => {
          const elemFound = resLang.find(obj => obj.parameter_code === item.idParameter);
          if (elemFound) {
            langArray.push(' ' + elemFound.name);
          }
        })
        if (langArray.length > 0) {
          setLanguage(langArray.toString())
        }
      }
    }
    getData();
  }, [dataOffer])

  return (
    <>
      <Grid container className="desc-offer-box">
        <Grid item className="desc-offer-container">
          <Grid item xs={12}>
            <Typography variant="h2" className="light-serif">
              Descripción de la oferta
            </Typography>
            <hr className="line-description-title" />
          </Grid>
          <Grid item className="second-container-text">
            <Typography variant="h2" className="bold-serif-primaryColor">
              {dataOffer.jobTitle}
            </Typography>
            <Grid>
              <Typography variant="h5" className="title-detail-description-text">
                <span>Profesión del aspirante: </span>
              </Typography>
              <Typography variant="h5" className="italic-description-text">
                {typePosition}
              </Typography>
            </Grid>
            {
              dataOffer.numberVacancy > 0 &&
              <Grid>
                <Typography variant="h5" className="title-detail-description-text">
                  <span>Cantidad de vacantes: </span>
                  <span className="text-description-details">{dataOffer.numberVacancy}</span>
                </Typography>
              </Grid>
            }
            {
              dataOffer.description && dataOffer.description.length > 0 &&


              <Grid>
                <Typography variant="h5" className="title-detail-description-text">
                  <span>Descripción de la Vacante: </span>
                </Typography>
                <Typography variant="h5" className="text-description-details">
                  {dataOffer.description}
                </Typography>
              </Grid>
            }
            <Grid>
              <Typography variant="h5" className="title-detail-description-text">
                <span>Sector laboral: </span>
                <span className="text-description-details">{laboralSector}</span>
              </Typography>
              <Typography variant="h5" className="title-detail-description-text">
                <span>Tipo de contrato: </span>
                <span className="text-description-details">{contract}</span>
              </Typography>
              <hr className="line-description-text" />
            </Grid>
            {
              // (dataOffer.salaryVisible && dataOffer.rangeSalary.length > 0) &&
              ((dataOffer.salaryVisible && dataOffer.rangeSalary.length > 0) || (userContext.roleSelected === 4 || userContext.roleSelected === 5 || userContext.roleSelected === 6)) &&
              <Grid>
                <Typography variant="h5" className="title-detail-description-text">
                  <span>$ Rango salarial: </span>
                </Typography>
                <Typography variant="h5" className="text-description-details">
                  {dataOffer.rangeSalary}
                </Typography>
                <Typography variant="h5" className="text-description-details">
                  {dataOffer.descriptionVariableSalary}
                </Typography>
                <hr className="line-description-text" />
              </Grid>
            }
            <Grid>
              <Typography variant="h5" className="title-detail-description-text">
                <span>Fin del proceso de selección: </span>
              </Typography>
              <Typography variant="h5" className="text-description-details">
                {moment(new Date(dataOffer.estimatedDateCompletion)).locale('es').format("dddd, DD MMMM YYYY")}
              </Typography>
              <Typography variant="h5" className="title-detail-description-text">
                <span>Área de conocimiento: </span>
              </Typography>
              <Typography variant="h5" className="text-description-details">
                {areas}
              </Typography>
              <Typography variant="h5" className="title-detail-description-text">
                <span>Profesión(NBC): </span>
              </Typography>
              <Typography variant="h5" className="text-description-details">
                {subAreas}
              </Typography>
              <hr className="line-description-text" />
            </Grid>
            <Grid>
              <Typography variant="h5" className="title-detail-description-text">
                Requerimientos
              </Typography>
              <Grid item className="list-detail-text">
                <Typography variant="h5" className="regular-serif primaryColor">
                  {
                    (dataOffer.experienceYears > 0 || dataOffer.experienceMonths > 0) &&
                    <li>
                      <span className="subtitle-description-text">Experiencia: </span>
                      <span className="text-description-details">
                        {dataOffer.experienceYears > 0 && dataOffer.experienceYears + ' años '}
                        {dataOffer.experienceMonths > 0 && dataOffer.experienceMonths + ' meses '}
                        {dataOffer.experienceDays > 0 && dataOffer.experienceDays + ' dias '}
                      </span>
                    </li>
                  }
                  {
                    skills.length > 0 &&
                    <li><span className="subtitle-description-text">Competencias: </span><span className="text-description-details">{skills}</span>
                    </li>
                  }
                  {
                    dataOffer.otherSkills && dataOffer.otherSkills.length > 0 &&
                    <li><span className="subtitle-description-text">Otras competencias: </span><span className="text-description-details">{dataOffer.otherSkills}</span>
                    </li>
                  }
                  {
                    academicLevel.length > 0 &&
                    <li><span className="subtitle-description-text">Nivel educativo exigido: </span><span className="text-description-details">{academicLevel}</span>
                    </li>
                  }
                  {
                    language.length > 0 &&
                    <li><span className="subtitle-description-text">Idiomas exigidos: </span><span className="text-description-details">{language}</span>
                    </li>
                  }
                </Typography>
              </Grid>
            </Grid>
            {
              showButtons &&
              <Grid item xs={12} className="submit-button">
                {

                  (userContext.userPercentage &&
                    userContext.userPercentage.total < 100 &&
                    isLogg) ?
                    <>
                      <Button
                        variant="contained"
                        className={"disabled-offer-btn"}
                        disabled={true}
                      >
                        <Typography variant="h5" className="bold-serif">
                          <span>Aplicar a la oferta</span>
                        </Typography>
                      </Button>
                      <Typography variant="h5" className="bold-serif primaryError">
                        No puedes aplicar sin completar los campos obligatorios de tu Hoja de Vida
                      </Typography>
                    </>
                    :
                    <Button
                      variant="contained"
                      className={userApply ? "retry-offer-btn" : "apply-offer-btn"}
                      onClick={handleApply}
                    >
                      <Typography variant="h5" className="bold-serif">
                        {
                          userApply ?
                            <span>Retirarse de la oferta</span>
                            :
                            <span>Aplicar a la oferta</span>
                        }
                      </Typography>
                    </Button>
                }
              </Grid>
            }

          </Grid>
        </Grid>
      </Grid>
      <LoadingLockPage load={promiseInProgress} />
    </>
  );
};

export default DescriptionOfferBox;
