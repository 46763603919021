import React from 'react';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { StateByRole } from '../../../interfaces/StateByRole';
import { IGeneralSIA } from '../../../interfaces/IGeneralSIA';

import './SelectActions.scss';


interface SelectActionsBoxProps{
    usersToChange?: any;
    stateOptions: Array<StateByRole>;
    stateSelected: string;
    onChangeStateSelected: any;
    actionOptions:Array<IGeneralSIA>;
    actionSelected: number;
    onChangeAction: any;
    sendActionSelected: any;
}

const SelectActionsBox:React.FC<SelectActionsBoxProps> = ({usersToChange, stateOptions, actionOptions, actionSelected, onChangeAction, stateSelected, onChangeStateSelected, sendActionSelected})=>{

    return(
        <Grid container item xs={12} className="select-actions-container">
            <Grid item xs={12} md={3}>
                <div className="select-actions-title">
                    <Typography variant="h4" className="bold-serif-primaryColor">Seleccione una acción:</Typography>
                </div>
                <div className="select-actions-control">
                    <TextField
                        fullWidth
                        id="outlined-select-action"
                        select
                        name="actionSelected"
                        label={actionSelected === 0 ? "seleccione" : ""}
                        value={actionSelected === 0 ? "" : actionSelected}
                        onChange={onChangeAction}
                        InputLabelProps={{shrink: false}}
                        margin="none"
                        variant="outlined"
                        disabled={usersToChange.length>0 ? false : true}
                    >
                        {
                            actionOptions.length> 0 ?
                            actionOptions.map((option: IGeneralSIA)=>(
                                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                            ))
                            :
                            <MenuItem></MenuItem>
                        }
                    </TextField>
                </div>
            </Grid>
            {
                actionSelected === 1 &&
                <Grid item xs={12} md={3}>
                    <div className="select-actions-title">
                        <Typography variant="h4" className="bold-serif-primaryColor">Seleccione un estado:</Typography>
                    </div>
                    <div className="select-actions-control">
                        <TextField
                            fullWidth
                            id="outlined-select-action"
                            select
                            name="stateSelected"
                            label={stateSelected === "" ? "seleccione" : ""}
                            value={stateSelected === "" ? "" : stateSelected}
                            onChange={onChangeStateSelected}
                            InputLabelProps={{shrink: false}}
                            margin="none"
                            variant="outlined"
                            disabled={actionSelected === 1 ? false : true}
                        >
                            {stateOptions.map((option: StateByRole)=>(
                                <MenuItem key={option.id} value={option.value}>{option.description}</MenuItem>
                            ))}
                        </TextField>
                    </div>
                </Grid>
            }

            <Grid container item xs={12} md={6} justify="flex-end">
                <Button variant="contained" disabled={(actionSelected > 1 || stateSelected !=="" ) ? false : true} className="select-actions-button" onClick={sendActionSelected}>
                    <Typography variant="h4" className="regular-serif">
                        Ejecutar
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

export default SelectActionsBox;
