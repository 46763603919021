import React from 'react';
import { createStyles, Theme, withStyles, WithStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Switch from '@material-ui/core/Switch';
import './DetailAcademicInfoModal.scss'


const styles = (theme: Theme) =>
createStyles({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#004B73',
  },
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: theme.spacing(2, 0),
    },
  }),
);

const BlueSwitch = withStyles({
  switchBase: {
    color:'#ffffff',
    '&$checked': {
      color:'#004B73',
    },
    '&$checked + $track': {
      backgroundColor: '#004B73',
    },
  },
  checked: {},
  track: {},
})(Switch);

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="register-dialog-header" {...other}>
      <Typography align="center" variant="h2" className="bold-serif-primaryColor" style={{marginRight:'16px'}}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface AcademicInfoModalProps{
    open: any;
    handleClose: any;
    campus: string;
    faculty: string;
    program: string;
    academicLevel: string;
    obtainedTitle: any;
    profession: any;
    proffesionalCard: string;
    diplomaNumber: string;
    startDate: any;
    finalDate: any;
    country: any;
    departament: any;
    city: any;
    descriptionTesis: string;
    continueBtnText?: string;
    handleContinue?: any;
}

const DetailAcademicInfoModal: React.FC<AcademicInfoModalProps> = ({ open, handleClose, campus, faculty, program, academicLevel, obtainedTitle, profession,proffesionalCard, diplomaNumber, startDate, finalDate, country, departament, city, descriptionTesis, continueBtnText, handleContinue }) => {
    const classes = useStyles();

    return(
      <>
        <Dialog
            maxWidth='lg'
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className="academic-information-dialog-container"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          </DialogTitle>
          <DialogContent className="academic-info-container">
            <Grid className="academic-info-modal-details">
                <Grid container item xs={12} direction="row" >
                    <Grid container item xs={12} alignItems="center" className="modal-info-box">
                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                            <Typography variant="h5" className="bold-serif title-box-text">Sede*</Typography>
                            <Typography variant="h5" className="section-preview-title">
                                {campus}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-both">
                            <Typography variant="h5" className="bold-serif title-box-text">Facultad*</Typography>
                            <Typography variant="h5" className="section-preview-title">
                                {faculty}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-left">
                            <Typography variant="h5" className="bold-serif title-box-text">Programa*</Typography>
                            <Typography variant="h5" className="section-preview-title">
                                {program}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                            <Typography variant="h5" className="bold-serif title-box-text">Nivel educativo</Typography>
                            <Typography variant="h5" className="section-preview-title">
                                {academicLevel}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-both">
                            <Typography variant="h5" className="bold-serif title-box-text">Título obtenido</Typography>
                            <Typography variant="h5" className="section-preview-title">
                                {obtainedTitle}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-both">
                            <Typography variant="h5" className="bold-serif title-box-text">Profesión</Typography>
                            <Typography variant="h5" className="section-preview-title">
                                {profession}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                            <Typography variant="h5" className="bold-serif title-box-text">Número de tarjeta profesional</Typography>
                            <Typography variant="h5" className="section-preview-title">
                                {proffesionalCard}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                            <Typography variant="h5" className="bold-serif title-box-text">Número de diploma o acta de grado</Typography>
                            <Typography variant="h5" className="section-preview-title">
                                {diplomaNumber}
                            </Typography>
                        </Grid>
                         <Grid container item xs={12} alignItems="center" className="special-box-right visibility-curriculum-container">
                          <VisibilityIcon/>
                          <FormControlLabel
                              value="start"
                              control={<BlueSwitch size="small"/>}
                              label="Mostrar esta información en su hoja de vida"
                              labelPlacement="start"
                          />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} className="information-box-section special-box-right">
                            <Typography variant="h5" className="bold-serif title-box-text">Fecha de inicio</Typography>
                            <Typography variant="h5" className="section-preview-title">
                                {startDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} className="information-box-section special-box-both">
                            <Typography variant="h5" className="bold-serif title-box-text">Fecha de finalización</Typography>
                            <Typography variant="h5" className="section-preview-title">
                                {finalDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} className="information-box-section special-box-both">
                            <Typography variant="h5" className="bold-serif title-box-text">País</Typography>
                            <Typography variant="h5" className="section-preview-title">
                                {country}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} className="information-box-section special-box-both">
                            <Typography variant="h5" className="bold-serif title-box-text">Departamento</Typography>
                            <Typography variant="h5" className="section-preview-title">
                                {departament}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} className="information-box-section special-box-left">
                            <Typography variant="h5" className="bold-serif title-box-text">Municipio</Typography>
                            <Typography variant="h5" className="section-preview-title">
                                {city}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} className="information-box-section special-box-left">
                            <Typography variant="h5" className="bold-serif title-box-text">Título del trabajo de grado</Typography>
                            <Typography variant="h5" className="section-preview-title">
                                {descriptionTesis}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="academic-info-dialog-actions">
          {handleContinue &&
            <Button
              className="next-button"
              variant="contained"
              size="small"
              onClick={handleContinue}
            >
              <Typography variant="h6" className="regular-serif">
                {continueBtnText}
              </Typography>
            </Button>
            }
          </DialogActions>
        </Dialog>
      </>
    )
}

export default DetailAcademicInfoModal;