export class IGetNewEvent{
  id: number = 0;
  type: string = "";
  category: number = 0;
  categoryName: string = "";
  highlight?: boolean = false;
  fileType?: string = "";
  image?: string = "";
  name: string = "";
  shortDescription: string = "";
  description: string = "";
  dateStart?: string = "";
  timeStart?: string = "";
  dateFinish?: string = "";
  timeFinish?: string = "";
  place?: string = "";
  publish?: boolean = false;
  state?: string = "";
  publishDate?: string = "";
}