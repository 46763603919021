import React, { useEffect } from "react";
import esLocale from "date-fns/locale/es";
import moment from 'moment';
import 'moment/locale/es'
import DateFnsUtils from '@date-io/date-fns';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import RegisterContext from "../../../utils/RegisterContext";
import { IRegisterGraduate } from '../../../interfaces/IRegisterGraduate';
import { IGeneralParameter } from "../../../interfaces/IGeneralParameter";
import { IListParametersInfo } from "../../../interfaces/IListParametersInfo";

import '../Stepper.scss'

const AcademicInfo: React.FC = () => {
  const registerContext = React.useContext(RegisterContext);
  const formValues: IRegisterGraduate = registerContext.formValues;
  const formErrors: IRegisterGraduate = registerContext.formErrors;

  const isValidFirstStep =
    registerContext.firstAccorDone &&
    registerContext.secondAccorDone &&
    registerContext.thirdAccorDone

  const isValid =
    formValues.campus > 0 &&
    formValues.school > 0 &&
    formValues.curriculum > 0 &&
    formValues.academicLevel > 0 &&
    moment(formValues.date).isValid() &&
    !formErrors.dateError &&
    !formErrors.diplomaNumber

  useEffect(()=>{
    registerContext.handleValidForm('panel2', isValid);
  })


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Sede*</Typography>
          <TextField
              fullWidth
              id="outlined-select-campus"
              select
              name="campus"
              label={formValues.campus === 0 ? "Seleccione": ""}
              value={formValues.campus === 0 ? "" : formValues.campus}
              onChange={registerContext.handleChange}
              InputLabelProps={{shrink: false}}
              margin="none"
              variant="outlined"
          >
            {
              registerContext.allCampus && registerContext.allCampus.length> 0 ?
              registerContext.allCampus.map((option: IListParametersInfo) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.parentName}
                </MenuItem>
              ))
            :
              <MenuItem value={formValues.campus}></MenuItem>
            }
          </TextField>
        </Grid>

        <Grid item xs={12} md={6} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Facultad*</Typography>
          <TextField
            fullWidth
            id="outlined-select-school"
            select
            name="school"
            label={formValues.school === 0 ? "Seleccione": ""}
            value={formValues.school === 0 ? "" : formValues.school}
            onChange={registerContext.handleChange}
            InputLabelProps={{shrink: false}}
            margin="none"
            variant="outlined"
            disabled={registerContext.schools && registerContext.schools.length>0 ? false : true}
          >
            {
              registerContext.schools && registerContext.schools.length>0 ?
              registerContext.schools.map((option: IGeneralParameter) => (
                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                  {option.name}
                </MenuItem>
              ))
            :
              <MenuItem value={formValues.school}></MenuItem>
            }
          </TextField>
        </Grid>

        <Grid item xs={12} md={6} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Programa*</Typography>
          <TextField
            fullWidth
            id="outlined-select-curriculum"
            select
            name="curriculum"
            label={formValues.curriculum === 0 ? "Seleccione": ""}
            value={formValues.curriculum === 0 ? "" : formValues.curriculum}
            onChange={registerContext.handleChange}
            InputLabelProps={{shrink: false}}
            margin="none"
            variant="outlined"
            disabled={registerContext.programs && registerContext.programs.length>0 ? false : true}
          >
            {
              registerContext.programs && registerContext.programs.length>0 ?
              registerContext.programs.map((option: IGeneralParameter) => (
                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                  {option.name}
                </MenuItem>
              ))
            :
              <MenuItem value={formValues.curriculum}></MenuItem>
            }
          </TextField>
        </Grid>

        <Grid item xs={12} md={6} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Nivel educativo del Programa*</Typography>
          <TextField
            fullWidth
            id="outlined-select-academicLevel"
            select
            name="academicLevel"
            label={formValues.academicLevel === 0 ? "Seleccione": ""}
            value={formValues.academicLevel === 0 ? "" : formValues.academicLevel}
            onChange={registerContext.handleChange}
            InputLabelProps={{shrink: false}}
            margin="none"
            variant="outlined"
          >
            {
              registerContext.allAcademic && registerContext.allAcademic.length>0 ?
              registerContext.allAcademic.map((option: IGeneralParameter) => (
                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                  {option.name}
                </MenuItem>
              ))
            :
              <MenuItem value={formValues.academicLevel}></MenuItem>
            }
          </TextField>
        </Grid>

        <Grid container item xs={12}>
          <hr className="divider-accordion"/>
        </Grid>

        <Grid item xs={12} md={6} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Fecha del grado*</Typography>
          <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale} >
            <DatePicker
              disableFuture
              views={['year', 'month']}
              minDate={new Date('1901-01')}
              maxDate={new Date('2003-12')}
              value={formValues.date || null}
              onChange={registerContext.handleChangeDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="date"
                  margin="none"
                  label={formValues.date !==null ? "": "Seleccione mes y año"}
                  InputLabelProps={{shrink: false}}
                  error={!!formErrors.dateError}
                  helperText={formErrors.dateError}
                  variant="outlined"
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid container item xs={12}>
          <hr className="divider-accordion"/>
        </Grid>

        <Grid item xs={12} md={6} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Número del diploma o acta de grado</Typography>
          <TextField
            fullWidth
            name="diplomaNumber"
            margin="none"
            label={formValues.diplomaNumber=== "" ? "Ingrese el número de su diploma": ""}
            value={formValues.diplomaNumber || ""}
            onChange={registerContext.handleChange}
            error={!!formErrors.diplomaNumber}
            helperText={formErrors.diplomaNumber}
            InputLabelProps={{shrink: false}}
            variant="outlined"
          />
        </Grid>

      </Grid>

      <div style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          className="accordion-btn"
          endIcon={<ExpandMoreIcon/>}
          disabled={!isValid}
          onClick={isValid ? (isValidFirstStep ? registerContext.handleChangeAccordion('panel3',false, 'panel2') :registerContext.handleChangeAccordion('panel3',true, 'panel2')) : null}
        >
          Siguiente
        </Button>
      </div>

    </>
  )
}

export default AcademicInfo
