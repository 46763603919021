import { IGeneralParameter } from "./IGeneralParameter";
import { IGeneralSIA } from "./IGeneralSIA";
import { IListParametersInfo } from "./IListParametersInfo";

export class IStepRegister{
    formValues?: any;
    setFormValues?: any;
    formErrors?: any;
    setFormErrors?: any;
    handleNext?: any;
    handleBack?: any;
    handleChange?: any;
    handleChangeDate?: any;
    expanded?: any;
    handleChangeAccordion?: any;
    firstAccorDone?:boolean;
    secondAccorDone?: boolean;
    thirdAccorDone?: boolean;
    handleValidForm?: any;
    handleDeleteFile?: any;
    changeStep?: any;
    handleMOpenModal?: any;
    openModal: boolean = false;
    handleCloseModal?:any;
    handleTerms? :any;
    //job bank 
    handleJobBank?: any;
    handleCloseJobModal?:any;
    handleOpenJobModal?:any;
    openJobModal: boolean= false;
    //
    step1Completed?: boolean;
    setStep1Completed?: any = false;
    step2Completed?:boolean = false;
    setStep2Completed?: any;
    countryCitiesArray?: Array<IListParametersInfo> = [];
    contactCitiesArray?: Array<IGeneralParameter> = [];
    departmentArray?: Array<IGeneralParameter> = [];
    cities?: Array<IGeneralParameter> = [];
    gendersType?: Array<IGeneralSIA> = [];
    ethnicGroup?: Array<IGeneralParameter> = [];
    documentsType?: Array<IGeneralParameter> = [];
    allCampus?: Array<IListParametersInfo> = [];
    schools?: Array<IGeneralParameter> = [];
    programs?: Array<IGeneralParameter> = [];
    allAcademic?: Array<IGeneralParameter> = [];
    allEmploySituation?: Array<IGeneralParameter> = [];
    allCompanyType?: Array<IGeneralParameter> = [];
    allTypePerson?: Array<IGeneralParameter> = [];
    allLaboralSector?: Array<IGeneralParameter> = [];
}