import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import esLocale from "date-fns/locale/es";
import moment from 'moment';
import 'moment/locale/es'
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DateRange, DateRangePicker, LocalizationProvider } from '@material-ui/pickers';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import AnnouncementFilters from '../../../components/LaboralOffers/AnnouncementFilters';
import { IFilterOfferForm } from '../../../interfaces/IFilterOfferForm';
import { ChipData } from '../../../interfaces/ChipData';
import ShowFilters from '../../../components/ManagementState/ShowFilters';
import { checkValuesSelected, containsAny, resetStatusCheckArray, sortByKey } from '../../../helpers/sharedFunctions';
import { StateByRole } from '../../../interfaces/StateByRole';
import { IStaticReport } from '../../../interfaces/IStaticReport';
import Validations from '../../../helpers/Validations';
import AditionalData from '../AditionalData';
import AditionalOptions from '../AditionalOptions';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import DownloadModal from '../DownloadModal';
import ErrorModal from '../ErrorModal';
import { ITypeReport } from '../../../interfaces/ITypeReport';
import { IOfferFilterObj } from '../../../interfaces/IOfferFilterObj';
import { getCITY, getCOUNTRIES_CITIES, getDEPARTAMENT, getLABORAL_SECTOR, getOccupationType } from '../../../services/ParameterService';
import { Button, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, TextField } from '@material-ui/core';
import { dinamicReportOffers, reportComparativeJobOffers, reportNBC, reportTotalJobOfferByPosition, 
    reportTotalOffersBySalary, reportOfferTransmition, reportOfferTransmitionSem,
    reportVacancies, reportGeneralVacancies} from '../../../services/ReportsService';
import UserContext from '../../../utils/UserContext';
import NotAuthorized from '../../ShareComponents/NotAuthorized/NotAuthorized';
import { permissionAdminDinamicReportsOffers, permissionAdminStaticReportsOffers } from '../../../utils/PermissionsVariables';

import './ReportFormOffers.scss';
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';

const filterOffersValidation = {
  jobTitle:{
    error: "",
    validate: "text",
    maxLength: 40,
    required: false
  },
  laboralSector:{
    validate: "select",
    required: false
  },
  typePosition:{
    validate: "select",
    required: false
  },
  state:{
    validate: "select",
    required: false
  },
  country:{
    validate: "select",
    required: false
  },
  department: {
    validate: "select",
    required: false
  },
  city: {
  validate: "select",
  required: false
  },
  name: {
    error: "",
    validate: "textNumber",
    minLength: 3,
    required: false
  },
  nit: {
    error: "",
    validate: "number",
    minLength: 7,
    maxLength: 15,
    required: false
  },
  laboralSectorCompany:{
    validate: "select",
    required: false
  },
}

const initialOffersFilterErrors = {
  jobTitle: "",
  laboralSector: "",
  typePosition: "",
  state: "",
  country: "",
  department: "",
  city: "",
  rangeStartDateSince: "",
  rangeStartDateUntil: "",
  rangeEndDateSince: "",
  rangeEndDateUntil: "",
  name: "",
  nit: "",
  laboralSectorCompany: ""
}

const initialStaticReportValues = {
  dateInit: "",
  dateEnd: ""
}

const ReportOption: Array<StateByRole> = [
  { id: 1, label: 'Reporte dinámico', value: 'Dinamic', description: '', permission: [] },
  { id: 2, label: 'Reporte predeterminado', value: 'Predetermined', description: '', permission: [] },
];

const AditionalComponent: Array<StateByRole> = [
  { id: 1, label: 'Ninguno', value: 'None', description: '', permission: [] },
  { id: 2, label: 'Requisitos del aspirante', value: 'AspirantData', description: '', permission: [] },
  { id: 3, label: 'Información oferta laboral', value: 'LaboralOffer', description: '', permission: [] },
];

const JobDataOption: Array<StateByRole> = [
  { id: 1, label: 'Nombre del cargo en la empresa', value: 'jobTitle', description: '', permission: [] },
  { id: 2, label: 'Sector laboral', value: 'laboralSector', description: '', permission: [] },
  { id: 3, label: 'Tipo de cargo', value: 'typePosition', description: '', permission: [] },
  { id: 4, label: 'Descripción del salario variable', value: 'descriptionVariableSalary', description: '', permission: [] },
];

const AspirantDataOption: Array<StateByRole> = [
  { id: 1, label: 'Area de conocimientos', value: 'knowledgeArea', description: '', permission: [] },
  { id: 2, label: 'Profesiones [NBC]', value: 'professions', description: '', permission: [] },
  { id: 3, label: 'Tiempo de experiencia (Años)', value: 'experienceYears', description: '', permission: [] },
  { id: 3, label: 'Tiempo de experiencia (Meses)', value: 'experienceMonths', description: '', permission: [] },
  { id: 3, label: 'Tiempo de experiencia (Dias)', value: 'experienceDays', description: '', permission: [] },
  { id: 4, label: 'Competencias', value: 'skills', description: '', permission: [] },
  { id: 5, label: 'Nivel educativo exigido', value: 'academicLevel', description: '', permission: [] },
  { id: 6, label: 'Idiomas exigidos', value: 'language', description: '', permission: [] },
];

const LaboralOfferDataOption: Array<StateByRole> = [
  { id: 1, label: 'Nombre de la empresa', value: 'name', description: '', permission: [] },
  { id: 2, label: 'NIT de la empresa que publicó', value: 'nit', description: '', permission: [] },
  { id: 3, label: 'Estado de la oferta', value: 'state', description: '', permission: [] },
  { id: 4, label: 'Descripción', value: 'description', description: '', permission: [] },
  { id: 5, label: 'Número de vacantes', value: 'numberVacancy', description: '', permission: [] },
  { id: 6, label: 'País', value: 'country', description: '', permission: [] },
  { id: 7, label: 'Departamento', value: 'department', description: '', permission: [] },
  { id: 8, label: 'Municipio', value: 'city', description: '', permission: [] },
  { id: 9, label: 'Fecha de inicio convocatoria abierta', value: 'jobOfferStartDate', description: '', permission: [] },
  { id: 10, label: 'Fecha fin convocatoria abierta', value: 'jobOfferEndDate', description: '', permission: [] },
  { id: 11, label: 'Fecha estimada de finalización del proceso de selección de la oferta', value: 'estimatedDateCompletion', description: '', permission: [] },
  { id: 12, label: 'Tipo de contrato', value: 'typeContract', description: '', permission: [] },
  { id: 13, label: 'Salario visible para los candidatos', value: 'salaryVisible', description: '', permission: [] },
  { id: 14, label: 'Rango salarial', value: 'rangeSalary', description: '', permission: [] },
  { id: 15, label: 'Salario variable', value: 'variableSalary', description: '', permission: [] },
  { id: 16, label: 'Cantidad de egresados postulados', value: 'count', description: '', permission: [] },
];

const staticReportOption = [
  { id: 1, name: 'Total de ofertas según la profesión NBC más solicitado', value: 'reportNBC' },
  { id: 2, name: 'Comparativo de ofertas', value: 'reportComparativeJobOffers' },
  { id: 3, name: 'Total de ofertas según el cargo más ofrecido', value: 'reportTotalJobOfferByPosition' },
  { id: 4, name: 'Total de ofertas según el salario', value: 'reportTotalOffersBySalary' },
  { id: 5, name: 'Transmisión de oferentes mensual', value: 'reportOfferTransmition' },
  { id: 6, name: 'Transmisión de oferentes semestral', value: 'reportOfferTransmitionSem' },
  { id: 7, name: 'Transmisión de vacantes', value: 'reportVacancies' },
  { id: 8, name: 'Transmisión general de vacantes mensual', value: 'reportGeneralVacancies' },
];

const stateOptionsOffers: Array<StateByRole> = [
  {id: 1, label: 'En definición', value: 'En definición', description: "", permission:[]},
  {id: 2, label: 'Convocatoria abierta', value: 'Convocatoria abierta', description: "", permission:[]},
  {id: 3, label: 'En Selección', value: 'En Selección', description: "", permission:[]},
  {id: 4, label: 'Finalizado', value: 'Finalizado', description: "", permission:[]},
  {id: 5, label: 'Cancelada', value: 'Cancelada', description: "", permission:[]},
];

const ReportFormOffers: React.FC = () => {
  let params: any = useParams();
  const { promiseInProgress } = usePromiseTracker();
  const userContext = React.useContext(UserContext);
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [typeReport, setTypeReport] = useState<ITypeReport>(new ITypeReport());
  const [formValuesStaticReports, setFormValuesStaticReports] = useState<IStaticReport>(new IStaticReport());
  const [formErrorsStaticReport, setFormErrorsStaticReport] = useState(initialStaticReportValues);
  const [staticReportDates, setStaticReportDates] = useState<DateRange<Date>>([null, null]);
  const [filterValues, setFilterValues] = useState<IFilterOfferForm>(new IFilterOfferForm());
  const [formErrors, setFormErrors] = useState(initialOffersFilterErrors);
  const [allLaboralSector, setAllLaboralSector] = useState<Array<IGeneralParameter>>([]);
  const [allTypePositions, setAllTypePositions] = useState<Array<IGeneralParameter>>([]);
  const [countryCitiesArray, setCountryCitiesArray] = useState<Array<IListParametersInfo>>([]);
  const [departmentArray, setDepartmentArray] = useState<Array<IGeneralParameter>>([]);
  const [cityArray, setCityArray] = useState<Array<IGeneralParameter>>([]);
  const [cities, setCities] = useState<Array<IGeneralParameter>>([]);
  const [chipData, setChipData] = useState<ChipData[]>([]);
  const [reportSelected, setReportSelected] = useState<string>("Dinamic");
  const [recordsfound, setRecordsFound] = useState<number>(0);
  const [aditionalComponent, setAditionalComponent] = useState<string>("None");
  const [urlFileReportLog, setUrlFileReportLog] = useState<string>('');
  const [openModalDownload, setOpenModalDownload] = useState<boolean>(false);
  const [openModalError, setOpenModalError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage]= useState('');

  const [mainFieldsCheck, setMainFieldsCheck] = useState<any>({
    jobTitle: false,
    laboralSector: false,
    typePosition: false,
    descriptionVariableSalary: false,
  });

  const [aspirantFields, setAspirantFields] = useState<any>({
    knowledgeArea: false,
    professions: false,
    experienceYears: false,
    experienceMonths: false,
    experienceDays: false,
    skills: false,
    academicLevel: false,
    language: false,
  });

  const [laboralOfferFields, setLaboralOfferFields] = useState<any>({
    name: false,
    nit: false,
    state: false,
    description: false,
    numberVacancy: false,
    country: false,
    department: false,
    city: false,
    jobOfferStartDate: false,
    jobOfferEndDate: false,
    estimatedDateCompletion: false,
    typeContract: false,
    salaryVisible: false,
    rangeSalary: false,
    variableSalary: false,
    count: false,
  });

  const isValid =
    !formErrors.jobTitle &&
    !formErrors.rangeStartDateSince &&
    !formErrors.rangeStartDateUntil &&
    !formErrors.rangeEndDateSince &&
    !formErrors.rangeEndDateUntil &&
    !formErrors.name &&
    !formErrors.nit

  const isValidStaticFilters =
  (
      reportSelected === "Predetermined" &&
      typeReport.type !== "" &&
      moment(staticReportDates[0]).isValid() &&
      moment(staticReportDates[1]).isValid()
  ) ||
  (
      reportSelected === "Dinamic" &&
      typeReport.type !== ""
  )

  useEffect(() => {
    window.scrollTo(0, 0);
    const getInitialData = async () => {
      //  laboral sector
      let responseLaboralSector: Array<IGeneralParameter> = await getLABORAL_SECTOR();
      responseLaboralSector = sortByKey(responseLaboralSector, 'name');
      setAllLaboralSector(responseLaboralSector);
      // type position field
      let responsePostion: Array<IGeneralParameter> = await getOccupationType();
      responsePostion = sortByKey(responsePostion, 'name');
      setAllTypePositions(responsePostion);
      // data countries-cities
      let dataCountryCities:Array<IListParametersInfo> = (await getCOUNTRIES_CITIES()).list;
      dataCountryCities = sortByKey(dataCountryCities, 'grandParentName');
      setCountryCitiesArray(dataCountryCities);
      // type department field
      let dataDepartment: Array<IGeneralParameter> = await trackPromise(getDEPARTAMENT());
      dataDepartment = sortByKey(dataDepartment, 'name');
      setDepartmentArray(dataDepartment || []);
      // type city array
      let dataCity: Array<IGeneralParameter> = await trackPromise(getCITY());
      dataCity = sortByKey(dataCity, 'name');
      setCityArray(dataCity || []);
      setInitLoad(false);
    }
    getInitialData();
  }, [params.nit]);

  useEffect(()=>{
    if(filterValues.country === 0){
      setFilterValues(prev => ({
        ...prev,
        department:0,
        city:0
      }));
      setCities([]);
    }else {
      if(filterValues.country === 170){
        setCities([]);
      } else {
        setFilterValues(prev => ({
          ...prev,
          department:0,
          city:0
        }));
        const countryObjSelected = countryCitiesArray.find(country=>country.id === filterValues.country);
        if(countryObjSelected){
          setCities(countryObjSelected.childs);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filterValues.country])

  useEffect(() => {
    setCities([]);
    setFilterValues(prev => ({
      ...prev,
      city: 0
    }));
    if (filterValues.department > 0) {
      let citiesDep: Array<IGeneralParameter> = cityArray.filter(city => city.parentId === filterValues.department);
      citiesDep = sortByKey(citiesDep, 'name');
      if (citiesDep.length > 0) {
        setCities(citiesDep)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues.department])

  const changeFilterForm = (e: any) => {
    const { name, value } = e.target;
    setFilterValues(prev => ({
        ...prev,
        [name]: value
    }));
    const error: string = Validations(name, value, filterOffersValidation) || "";
    setFormErrors(prev => ({
        ...prev,
        [name]: error
    }));
  }

  const changeFilterDates = (name: string, dateValue: DateRange<Date>)=>{
    setFilterValues(prev => ({
      ...prev,
      [name]: dateValue
    }));
    const startDate: string = moment(dateValue[0]).format('YYYY/MM/DD');
    const endDate: string = moment(dateValue[1]).format('YYYY/MM/DD');
    let labelStart: string = "";
    let labelEnd: string = "";
    const valueStart = startDate === "Fecha inválida" ? "Fecha inválida" : "";
    const valueEnd = endDate === "Fecha inválida" ? "Fecha inválida" : "";
    if(name === "rangeStartDate"){
        labelStart = "rangeStartDateSince";
        labelEnd = "rangeStartDateUntil";
    }
    if(name === "rangeEndDate"){
        labelStart = "rangeEndDateSince";
        labelEnd = "rangeEndDateUntil";
    }
    errorDates(labelStart, valueStart);
    errorDates(labelEnd, valueEnd);
  }

  const errorDates = (label: string, value: string) => {
    setFormErrors(prev => ({
        ...prev,
        [label]: value
    }));
  }

  const changeStaticReportValues = (name: string, dateValue: DateRange<Date>) => {
    setStaticReportDates(dateValue);
    const startDate: string = moment(dateValue[0]).format('YYYY/MM/DD');
    const endDate: string = moment(dateValue[1]).format('YYYY/MM/DD');
    setFormValuesStaticReports(prev => ({
        ...prev,
        dateInit: startDate,
        dateEnd: endDate
    }));
    if(startDate === "Fecha inválida"){
        setFormErrorsStaticReport(prev =>({
            ...prev,
            dateInit:"Fecha inválida"
        }));
    } else {
        setFormErrorsStaticReport(prev =>({
            ...prev,
            dateInit:""
        }));
    }
    if(endDate === "Fecha inválida"){
        setFormErrorsStaticReport(prev =>({
            ...prev,
            dateEnd:"Fecha inválida"
        }));
    } else {
        setFormErrorsStaticReport(prev =>({
            ...prev,
            dateEnd:""
        }));
    }
  }

  useEffect(() => {
    setChips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, isValid])

  const setChips = () => {
    let tempArray: Array<ChipData> = []
    let index = 0;
    let subIndex = 0;
    const fieldNames = [
      { key: 'jobTitle', description: 'Nombre del cargo' },
      { key: 'laboralSector', description: 'Sector laboral' },
      { key: 'typePosition', description: 'Tipo de cargo' },
      { key: 'state', description: 'Estado' },
      { key: 'country', description: 'País' },
      { key: 'department', description: 'Departmento' },
      { key: 'city', description: 'Municipio' },
      { key: 'name', description: 'Razón social' },
      { key: 'nit', description: 'Nit de la empresa' },
      { key: 'rangeStartDate', description: 'Fecha inicio convocatoria' },
      { key: 'rangeEndDate', description: 'Fecha fin convocatoria' },
      { key: 'laboralSectorCompany', description: 'Sector laboral empresa' },
    ];
    for (const [key, value] of Object.entries(filterValues)) {
      if (value !== 0 && value !== '' && value !== null && value.length!==0) {
        if(value[0]!==null && value[1]!==null ){
          let obj;
          const fieldObj = fieldNames.find(field => field.key === key);
          if (key === 'laboralSector') {
            obj = allLaboralSector.find(laboral => laboral.parameter_code === value);
          } else if (key === 'laboralSectorCompany') {
            obj = allLaboralSector.find(laboral => laboral.parameter_code === value);
          } else if (key === 'typePosition') {
            obj = allTypePositions.find(type => type.parameter_code === value);
          } else if (key === 'country') {
            obj = {name: `${value === 170 ? "Colombia" : ""}`}
          } else if (key === 'department') {
            obj = departmentArray.find(dep => dep.parameter_code === value);
          } else if (key === 'city') {
            obj = cityArray.find(city => city.parameter_code === value);
          } else if (key === 'rangeStartDate'){
            const startDate: string = moment(value[0]).format('DD/MM/YYYY');
            const endDate: string = moment(value[1]).format('DD/MM/YYYY');
            obj = {name: `${startDate} hasta ${endDate}`}
          } else if (key === 'rangeEndDate'){
            const startDate: string = moment(value[0]).format('DD/MM/YYYY');
            const endDate: string = moment(value[1]).format('DD/MM/YYYY');
            obj = {name: `${startDate} hasta ${endDate}`}
          } else if (key === 'state'){
            subIndex = index;
            // eslint-disable-next-line no-loop-func
            (value as StateByRole[]).forEach(stateItem=>{
                tempArray.push({key: subIndex, name:stateItem.label + ' state', label: fieldObj?.description + ': ' + stateItem.label });
                subIndex++;
            });
          }
          if(subIndex !==0){
            index = subIndex
            subIndex = 0;
          }
          if (obj) {
            tempArray.push({ key: index, name: key, label: fieldObj?.description + ': ' + obj.name })
          } else {
            if(key !== 'state'){
              tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + value})
            }
          }
          index++;
        }
      }
    }
    setChipData(tempArray);
  }

  const tranformDataOffers = (values: IFilterOfferForm, preview:boolean)=>{
    const temporaryObject: IOfferFilterObj = new IOfferFilterObj();
    temporaryObject.preview = preview;
    if(values.jobTitle !== ""){
      temporaryObject.filters.position.jobTitle = values.jobTitle;
    } else { delete temporaryObject.filters.position.jobTitle }
    if(values.laboralSector >0){
      temporaryObject.filters.position.laboralSector = values.laboralSector;
    } else { delete temporaryObject.filters.position.laboralSector }
    if(values.typePosition >0){
      temporaryObject.filters.position.typePosition = values.typePosition;
    } else { delete temporaryObject.filters.position.typePosition }
    if(values.state.length>0){
      temporaryObject.filters.jobOffer.state = values.state.map(item=>item.value);
    } else { delete temporaryObject.filters.jobOffer.state }
    if(values.country >0){
      temporaryObject.filters.jobOffer.country = values.country;
    } else { delete temporaryObject.filters.jobOffer.country }
    if(values.department >0){
      temporaryObject.filters.jobOffer.department = values.department;
    } else { delete temporaryObject.filters.jobOffer.department }
    if(values.city >0){
      temporaryObject.filters.jobOffer.city = values.city;
    } else { delete temporaryObject.filters.jobOffer.city }
    if(values.rangeStartDate[0] !== null && values.rangeStartDate[1] !== null){
      if(temporaryObject.filters.jobOffer.rangeStartDate){
          temporaryObject.filters.jobOffer.rangeStartDate.sinceDate = moment(values.rangeStartDate[0]).format("YYYY-MM-DD");
          temporaryObject.filters.jobOffer.rangeStartDate.untilDate = moment(values.rangeStartDate[1]).format("YYYY-MM-DD");
      }
    } else { delete temporaryObject.filters.jobOffer.rangeStartDate }
    if(values.rangeEndDate[0] !== null && values.rangeEndDate[1] !== null){
      if(temporaryObject.filters.jobOffer.rangeEndDate){
          temporaryObject.filters.jobOffer.rangeEndDate.sinceDate = moment(values.rangeEndDate[0]).format("YYYY-MM-DD");
          temporaryObject.filters.jobOffer.rangeEndDate.untilDate = moment(values.rangeEndDate[1]).format("YYYY-MM-DD");
      }
    } else { delete temporaryObject.filters.jobOffer.rangeEndDate }
    if(values.name !== ""){
      temporaryObject.filters.company.name = values.name;
    } else { delete temporaryObject.filters.company.name }
    if(values.nit === null || values.nit === ""){
      delete temporaryObject.filters.company.nit
    } else { temporaryObject.filters.company.nit = parseInt(values.nit); }
    if(values.laboralSectorCompany >0){
      temporaryObject.filters.company.laboralSector = values.laboralSectorCompany;
    } else { delete temporaryObject.filters.company.laboralSector }
    const mainFieldsSelected = checkValuesSelected(mainFieldsCheck);
    temporaryObject.attributes.position = mainFieldsSelected || [];
    const aspirantFieldsSelected = checkValuesSelected(aspirantFields);
    temporaryObject.attributes.postulates = aspirantFieldsSelected || [];
    const laboralOfferFieldsSelected = checkValuesSelected(laboralOfferFields);
    temporaryObject.attributes.jobOffer = laboralOfferFieldsSelected || [];
    return temporaryObject;
  }

  const clickApplyFilters = async(value: any, action: string) => {
    if (action === 'apply') {
      const objectToSend = tranformDataOffers(filterValues, true);
      if(objectToSend.attributes.jobOffer && objectToSend.attributes.jobOffer.length===0){
        objectToSend.attributes.jobOffer = ["name"]
      }
      const responseDinamicReportOffers = await trackPromise(dinamicReportOffers(
          objectToSend
      ))
      if(responseDinamicReportOffers.status === 200){
        const result = await responseDinamicReportOffers.json();
        setRecordsFound(result.quantity || 0 );
        setChips();
      } else {
        setOpenModalError(true);
        setErrorMessage("Hubo un error, intente de nuevo mas tarde, o contacte al administrador general");
      }
    } else if (action === 'clear') {
      resetReportOfferDinamic();
    }
  }

  const applyStaticFilters = () =>{
    checkAndRequestService(true, false);
  }

  const handleDeleteChip = (chipToDelete: ChipData) => () => {
    const name = chipToDelete.name;
    setChipData((chips) => chips.filter((chip) => chip.name !== chipToDelete.name));
    if(name === 'laboralSector' || name === 'laboralSectorCompany' || name === 'typeOfCompany' || name === 'country' || name === 'department' || name === 'city'){
      setFilterValues(prev => ({
        ...prev,
        [chipToDelete.name]: 0
      }));
    } else if(name === 'rangeStartDate' || name === "rangeEndDate"){
      setFilterValues(prev => ({
        ...prev,
        [name]: [null,null]
      }));
    } else if(name.includes('state')){
      const newState = filterValues.state.filter(item=>item.label !== chipToDelete.label.split(': ')[1]);
      setFilterValues(prev => ({
        ...prev,
        state: newState
      }));
    } else {
      setFilterValues(prev => ({
        ...prev,
        [chipToDelete.name]: ''
      }));
    }
  };

  const resetReportOfferDinamic = () =>{
    setFilterValues(new IFilterOfferForm());
    setFormErrors(initialOffersFilterErrors);
    setRecordsFound(0);
    const newMainFields = resetStatusCheckArray(mainFieldsCheck);
    setMainFieldsCheck(newMainFields);
    const newAspirantFields = resetStatusCheckArray(aspirantFields);
    setAspirantFields(newAspirantFields);
    const newlaboralOfferFields = resetStatusCheckArray(laboralOfferFields);
    setLaboralOfferFields(newlaboralOfferFields);
    setAditionalComponent("None");
    setChipData([]);
    setUrlFileReportLog("");
  }

  const resetReportOfferStatic = () =>{
    setFormValuesStaticReports(new IStaticReport());
    setFormErrorsStaticReport(initialStaticReportValues);
    setTypeReport(new ITypeReport());
    setStaticReportDates([null, null]);
    setRecordsFound(0);
    setUrlFileReportLog("");
  }

  const changeReportType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReportSelected((event.target as HTMLInputElement).value);
    if((event.target as HTMLInputElement).value === "Dinamic"){
      resetReportOfferDinamic();
    } else {
      resetReportOfferStatic();
    }
  };

  const changeAdditionalComponent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAditionalComponent((event.target as HTMLInputElement).value);
    if((event.target as HTMLInputElement).value === "None"){
      const newAspirantFields = resetStatusCheckArray(aspirantFields);
      setAspirantFields(newAspirantFields);
      const newlaboralOfferFields = resetStatusCheckArray(laboralOfferFields);
      setLaboralOfferFields(newlaboralOfferFields);
    }
  };

  const changeTypeReport = (e: any) => {
    const { name, value } = e.target;
    setTypeReport(prev => ({
      ...prev,
      [name]: value
    }));
    if(value === ""){
      setStaticReportDates([null, null]);
      setRecordsFound(0);
    }
  }

  const changeMainReportFields = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMainFieldsCheck({ ...mainFieldsCheck, [event.target.name]: event.target.checked });
  };

  const changeAspirantFields = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAspirantFields({ ...aspirantFields, [event.target.name]: event.target.checked });
  };

  const changeLaboralOfferFields = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLaboralOfferFields({ ...laboralOfferFields, [event.target.name]: event.target.checked });
  };

  const generateReport = async () => {
    if(reportSelected === "Dinamic"){
      const objectToSend = tranformDataOffers(filterValues, false);
      if(objectToSend.attributes){
        if((objectToSend.attributes.position && objectToSend.attributes.position.length>0)|| (objectToSend.attributes.postulates && objectToSend.attributes.postulates.length>0) || (objectToSend.attributes.jobOffer && objectToSend.attributes.jobOffer.length>0)){
          const responseDinamicReportOffers = await trackPromise(dinamicReportOffers(
            objectToSend
          ))
          updateDataStaticReport(responseDinamicReportOffers, true);
        } else {
          setOpenModalError(true);
          setErrorMessage("Por favor seleccione algun campo para el reporte");
        }
      } else {
        setOpenModalError(true);
        setErrorMessage("Por favor seleccione algun campo para el reporte");
      }
    } else {
      if (typeReport.type === '' ){
        setOpenModalError(true);
        setErrorMessage("Por favor seleccione algun filtro para el reporte");
      }
      checkAndRequestService(false, true);
    }
  }

  const checkAndRequestService = async(preview: boolean, showModal: boolean) => {
    const objectToSend: IStaticReport = formValuesStaticReports;
    objectToSend.preview = preview;
    if (typeReport.type === 'reportNBC' && formValuesStaticReports.dateInit !== "Fecha inválida" && formValuesStaticReports.dateEnd !== "Fecha inválida") {
      const responseNBC = await trackPromise(reportNBC(formValuesStaticReports));
      updateDataStaticReport(responseNBC, showModal);
    } else if (typeReport.type === 'reportComparativeJobOffers'&& formValuesStaticReports.dateInit !== "Fecha inválida" && formValuesStaticReports.dateEnd !== "Fecha inválida") {
      const responseComparativeJobOffers = await trackPromise(reportComparativeJobOffers(formValuesStaticReports));
      updateDataStaticReport(responseComparativeJobOffers, showModal);
    } else if (typeReport.type === 'reportTotalJobOfferByPosition'&& formValuesStaticReports.dateInit !== "Fecha inválida" && formValuesStaticReports.dateEnd !== "Fecha inválida") {
      const responseTotalJobOfferByPosition = await trackPromise(reportTotalJobOfferByPosition(formValuesStaticReports));
      updateDataStaticReport(responseTotalJobOfferByPosition, showModal);
    } else if (typeReport.type === 'reportTotalOffersBySalary'&& formValuesStaticReports.dateInit !== "Fecha inválida" && formValuesStaticReports.dateEnd !== "Fecha inválida") {
      const responseTotalOffersBySalary = await trackPromise(reportTotalOffersBySalary(formValuesStaticReports));
      updateDataStaticReport(responseTotalOffersBySalary, showModal);
    } else if (typeReport.type === 'reportOfferTransmition'&& formValuesStaticReports.dateInit !== "Fecha inválida" && formValuesStaticReports.dateEnd !== "Fecha inválida") {
      const responseOfferTransmition = await trackPromise(reportOfferTransmition(formValuesStaticReports));
      updateDataStaticReport(responseOfferTransmition, showModal);
    } else if (typeReport.type === 'reportOfferTransmitionSem'&& formValuesStaticReports.dateInit !== "Fecha inválida" && formValuesStaticReports.dateEnd !== "Fecha inválida") {
      const responseOfferTransmitionSem = await trackPromise(reportOfferTransmitionSem(formValuesStaticReports));
      updateDataStaticReport(responseOfferTransmitionSem, showModal);
    }else if (typeReport.type === 'reportVacancies'&& formValuesStaticReports.dateInit !== "Fecha inválida" && formValuesStaticReports.dateEnd !== "Fecha inválida") {
      const responseVacanciesTransmition = await trackPromise(reportVacancies(formValuesStaticReports));
      updateDataStaticReport(responseVacanciesTransmition, showModal);
    }else if (typeReport.type === 'reportGeneralVacancies'&& formValuesStaticReports.dateInit !== "Fecha inválida" && formValuesStaticReports.dateEnd !== "Fecha inválida") {
      const responseGeneralVacancies = await trackPromise(reportGeneralVacancies(formValuesStaticReports));
      updateDataStaticReport(responseGeneralVacancies, showModal);
    }

  }

  const updateDataStaticReport = async(responseStatic: Response, showModal: boolean) =>{
    if (responseStatic.status === 200 ) {
        const resultFile = await responseStatic.json();
        if(showModal){
            setUrlFileReportLog(resultFile.url);
            setRecordsFound(parseInt(resultFile.quantity));
            setOpenModalDownload(true);
        } else{
            setRecordsFound(resultFile.quantity);
        }
    } else {
      setOpenModalError(true);
      setErrorMessage("Hubo un error, intente de nuevo mas tarde, o contacte al administrador general");
    }
  }

  const handleCloseModalDownload = () => {
    setOpenModalDownload(false);
    window.location.reload();
  };

  const handleCloseModalError = () => {
    setOpenModalError(false);
    // window.location.reload();
  };

  return (
    <Grid container className="main-container">
      <Grid container item xs={12} className="offer-report-container">
        <Grid container item xs={12} className="report-container">
          <Grid container item xs={12} className="title-container-report">
            <Typography variant="h2" className="title">
              Seleccione el tipo de reporte
            </Typography>
          </Grid>
          <Grid container className="report-content">
            <Grid container item xs={12} md={2}>
              <Typography variant="h4" className="report-state-text">
                Tipo de reporte:
              </Typography>
            </Grid>
            <Grid container item xs={12} md={10} className="report-state-options">
              <FormControl component="fieldset">
                <RadioGroup row className="radio-container" aria-label="state" name="type" value={reportSelected} onChange={changeReportType}>
                  {ReportOption.map(option => (
                    <FormControlLabel key={option.id} value={option.value} className="radio-btn" control={<Radio className="color-btn" />} label={option.label} labelPlacement="start" />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            {containsAny(permissionAdminStaticReportsOffers, userContext.userPermissions) &&
              <>
                <Grid container item xs={12} className={reportSelected === "Predetermined" ? "line" : "no-container"} />
                <Grid item xs={12} className={reportSelected === "Predetermined" ? "field-box-margin" : "no-container"}>
                  <Typography variant="h5" className="field-box-title">Reporte*</Typography>
                  <TextField
                    fullWidth
                    id="outlined-select-type"
                    select
                    name="type"
                    label={typeReport.type === "" ? "Seleccione" : ""}
                    value={typeReport.type === "" ? "" : typeReport.type}
                    onChange={changeTypeReport}
                    InputLabelProps={{ shrink: false }}
                    margin="none"
                    variant="outlined"
                  >
                    {
                      staticReportOption && staticReportOption.length> 0 &&
                      <MenuItem value=""><em>Ninguno</em></MenuItem>
                    }
                    {staticReportOption.map(item => (
                      <MenuItem key={item.id} value={item.value}>
                        <Typography variant="h5" className="regular-serif">
                          {item.name}
                        </Typography>

                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </>
            }
          </Grid>
        </Grid>
      </Grid>
      {reportSelected === "Dinamic" ?
        <>
        {containsAny(permissionAdminDinamicReportsOffers, userContext.userPermissions) ?
          <>
            <Grid container item xs={12}>
              <AnnouncementFilters title="Filtros de búsqueda" formValues={filterValues} formErrors={formErrors} onChangeForm={changeFilterForm} changeFilterDates={changeFilterDates} allLaboralSector={allLaboralSector} allTypePositions={allTypePositions} stateOptions={stateOptionsOffers} countryCitiesArray={countryCitiesArray} departmentArray={departmentArray} cities={cities} reportForm={true}/>
            </Grid>
            <Grid container item xs={12}>
              <ShowFilters titleSection="Filtros seleccionados" filtersData={chipData} showButtons={true} showDelete={true} deleteFilter={handleDeleteChip} isValid={isValid} onApplyFilters={clickApplyFilters} />
            </Grid>
          </>
        :
          <NotAuthorized/>
        }
        </>
      :
        <>
        {containsAny(permissionAdminStaticReportsOffers, userContext.userPermissions) ?
          <>
            <Grid container item xs={12} className="graduate-report-container">
              <Grid container item xs={12} className="report-container2">
                <Grid container item xs={12} className="title-container-report">
                  <Typography variant="h2" className="title">
                    Seleccione el rango de fechas
                  </Typography>
                </Grid>
                <Grid container className="date-content">
                  <Grid item xs={12} md={6} xl={4} className="field-box-margin">
                    <Typography variant="h5" className="field-box-title">Rango de fechas</Typography>
                    <Grid container item xs={12}>
                      <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale} >
                        <DateRangePicker
                          disableFuture
                          calendars={1}
                          PopperProps={{
                            placement: "bottom"
                          }}
                          value={staticReportDates || null}
                          onChange={(value)=>changeStaticReportValues('staticReportDates',value)}
                          renderInput={(startProps, endProps) => (
                            <Grid container item xs={12} alignItems="center">
                              <Grid item xs={12} sm={5}>
                                <TextField
                                  {...startProps}
                                  autoComplete="off"
                                  fullWidth
                                  name="dateInit"
                                  margin="none"
                                  label={staticReportDates[0] !== null ? "" : "DD/MM/AAAA"}
                                  error={!!formErrorsStaticReport.dateInit}
                                  helperText={formErrorsStaticReport.dateInit}
                                  InputLabelProps={{ shrink: false }}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Typography variant="h5" className="field-box-title" style={{ textAlign: 'center' }}>
                                  Hasta
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={5}>
                                <TextField
                                  {...endProps}
                                  fullWidth
                                  autoComplete="off"
                                  name="dateEnd"
                                  margin="none"
                                  label={staticReportDates[1] !== null ? "" : "DD/MM/AAAA"}
                                  error={!!formErrorsStaticReport.dateEnd}
                                  helperText={formErrorsStaticReport.dateEnd}
                                  InputLabelProps={{ shrink: false }}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="center">
                <div>
                  <Button disabled={!isValidStaticFilters} variant="contained" className="apply-filters-button" onClick={applyStaticFilters}>
                      <Typography variant="h5" className="apply-filters-text">Aplicar filtros</Typography>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </>
        :
          <NotAuthorized/>
        }
        </>
      }
      {(containsAny(permissionAdminDinamicReportsOffers, userContext.userPermissions) || containsAny(permissionAdminStaticReportsOffers, userContext.userPermissions)) &&
        <Grid container item xs={12} className="information-result-report-section">
          <Typography variant="h4" className="bold-serif">Total ofertas encontradas: </Typography><span className="total-results-section">{recordsfound} resultados</span>
        </Grid>
      }
      {reportSelected === "Dinamic" && containsAny(permissionAdminDinamicReportsOffers, userContext.userPermissions) &&
        <>
          <Grid container className="description-container">
            <Typography variant="h4" className="description-title">
              1. Por favor seleccione los datos que desea se listen en el reporte.
            </Typography>
          </Grid>

          <Grid container item xs={12}>
            <AditionalData title="Información del cargo ofrecido" stateCheck={mainFieldsCheck} onChangeState={changeMainReportFields} stateOptions={JobDataOption} />
          </Grid>

          <Grid container className="description-container">
            <Typography variant="h4" className="description-title">
              2. Por favor seleccione los componentes adicionales que desea se listen en el reporte.
            </Typography>
          </Grid>

          <Grid container item xs={12} className="graduate-report-container2" >
            <Grid container item xs={12} className="report-container2">
              <Grid container item xs={12} className="title-container-report2">
                <Typography variant="h2" className="title">
                  Componentes adiciones
                </Typography>
              </Grid>
              <Grid container className="report-content2">

                <Grid container item xs={12} md={10} className="report-state-options2" >
                  <FormControl component="fieldset">
                    <RadioGroup row className="radio-container" aria-label="aditionalInfo" name="aditionalInfo" value={aditionalComponent} onChange={changeAdditionalComponent}>
                      {AditionalComponent.map(option => (
                        <FormControlLabel key={option.id} value={option.value} className="radio-btn" control={<Radio className="color-btn" />} label={option.label} labelPlacement="start" />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid container item xs={12} className={aditionalComponent === "" ? "no-container" : "line"} />
                {aditionalComponent !== "None" &&
                  <>
                  {aditionalComponent === "AspirantData" &&
                    <Grid container >
                      <AditionalOptions stateCheck={aspirantFields} onChangeState={changeAspirantFields} stateOptions={AspirantDataOption} />
                    </Grid>
                  }
                  {aditionalComponent === "LaboralOffer" &&
                    <Grid container >
                      <AditionalOptions stateCheck={laboralOfferFields} onChangeState={changeLaboralOfferFields} stateOptions={LaboralOfferDataOption} />
                    </Grid>
                  }
                  </>
                }
              </Grid>
            </Grid>
          </Grid>
        </>
      }
      {(containsAny(permissionAdminDinamicReportsOffers, userContext.userPermissions) || containsAny(permissionAdminStaticReportsOffers, userContext.userPermissions)) &&
        <Grid container item xs={12} className="button-download-report-container">
          <Button disabled={recordsfound>0 ? false : true} className="generate-report-btn" variant="contained" size="small" onClick={generateReport}>
            <Typography variant="h5" className="regular-serif">Generar reporte</Typography>
          </Button>
        </Grid>
      }
      <LoadingLockPage load={promiseInProgress} />
      <LoadingLockPage load={initLoad} />
      <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
      <DownloadModal downloadMsg="El reporte se ha generado con éxito" downloadMsg2="¿Desea descargarlo?" handleCloseModal={handleCloseModalDownload} openModalDownload={openModalDownload} urlReport={urlFileReportLog} total={recordsfound.toString()} />
    </Grid>
  )
}
export default ReportFormOffers;
