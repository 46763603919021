import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SectionPreview from '../ShareComponents/SectionPreview';
import { IProfileCompany } from '../../interfaces/IProfileCompany';
import "./CompanyInfo.scss";

interface CompanyInfoProps{
  companyData: IProfileCompany;
  department: string;
  city: string;
  companyType: string;
  laboralSector: string;
}

const CompanyInfo: React.FC<CompanyInfoProps> = ({companyData, department, city, companyType, laboralSector}) => {
  const [expanded, setExpanded] = useState<string | boolean>('panel');

  const handleChangeAccoridon = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Grid container item xs={12} md={10}>
        <Grid container item xs={12}>
          <Accordion
            expanded={expanded === "panel"}
            onChange={handleChangeAccoridon("panel")}
            className="company-info-accordion-container"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className="accordion-summary"
            >
              <Typography variant="h2" className="bold-serif">
                Información general de la empresa
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <Grid container item xs={12} className="info-content">
                <Grid container item xs={12} md={4}>
                  <Typography variant="h4" className="bold-serif-primaryColor">Información general de la empresa</Typography>
                </Grid>
                <Grid container item xs={12} md={8} className="divider-container" alignItems="center">
                  <hr className="divider"/>
                </Grid>
                <Grid container item xs={12}>
                  <SectionPreview cols={1} firstTitle="Razón social" firtsValue={companyData.name}/>
                  <SectionPreview cols={2} firstTitle="NIT" firtsValue={companyData.nit+""} secondTitle="Dígito de verificación del Nit" secondValue={companyData.verificationDigit+""} />
                  <SectionPreview cols={2} firstTitle="Tipo de empresa" firtsValue={companyType} secondTitle="Sector laboral"  secondValue={laboralSector}/>
                  <SectionPreview cols={3} firstTitle="País" firtsValue="Colombia" secondTitle="Departamento" secondValue={department} thirdTitle="Municipio" thirdValue={city} />
                  <SectionPreview cols={2} firstTitle="Teléfono" firtsValue={companyData.phone} secondTitle="Sitio web de la empresa" secondValue={companyData.website}/>
                </Grid>

                <Grid container item xs={12} md={5}>
                  <Typography variant="h4" className="bold-serif-primaryColor">Información de contacto de representante</Typography>
                </Grid>
                <Grid container item xs={12} md={7} className="divider-container" alignItems="center">
                  <hr className="divider"/>
                </Grid>
                <Grid container item xs={12}>
                  <SectionPreview cols={1} firstTitle="Nombre completo"  firtsValue={companyData.contactName} />
                  <SectionPreview cols={3} firstTitle="Cargo em la empresa" firtsValue={companyData.position} secondTitle="Correo electrónico" secondValue={companyData.email} thirdTitle="Número de teléfono" thirdValue={companyData.phoneContact}/>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyInfo;
