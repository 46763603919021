import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import CachedIcon from '@material-ui/icons/Cached';
import './ShowStateBox.scss';
import AlertBox from "../ShareComponents/AlertBox";

interface ShowStateBoxProps{
  resultState: string;
  value: string;
  id: string;
  email:string;
  username:string;
}

const ShowStateBox: React.FC<ShowStateBoxProps> = ({resultState, value, id,email, username}) => {
  const [stateNumber, setStateNumber]= useState(0)
  const [rejected, setRejected] = useState(false);
  const [consult, setConsult] = useState(false);

  useEffect(()=>{
    if(value === 'cc'){
      setConsult(true)
      if(resultState === "Inscrito"){
        setStateNumber(1)
      } else if (resultState === "Verificado"){
        setStateNumber(2)
      } else if (resultState === "Activo"){
        setStateNumber(3)
      } else if (resultState === "Rechazado"){
        setRejected(true)
      }
    } else if (value === 'nit'){
      setConsult(false)
      if(resultState === "Inscrito"){
        setStateNumber(1)
      } else if (resultState === "Activo"){
        setStateNumber(2)
      } else if (resultState === "Rechazado"){
        setRejected(true)
      }
    }
  }, [resultState, value])

  return (
    <>
      <Grid container className="result-container">
        {consult ?

          <Grid container item xs={12} justify="center">
            <Typography variant="h1" className="summary-title">
              Estado del registro
            </Typography>
            <Grid container justify="center" item xs={12}>
              <Typography variant="h2" className="bold-serif-primaryColor">
                {resultState}
              </Typography>
            </Grid>
            <Typography variant="h5" className="summary-description">
              El proceso de autenticación casi concluye, su estado actual es <span className="result-description"> {resultState} </span>
            </Typography>
            {!rejected ?
              <Grid container className="second-content">
                <Button className="btn-result" variant="contained" disabled style={{backgroundColor: stateNumber>=1 ? (stateNumber ===1 ? '#14A5DC' : '#AFD75E' ) : '#D7D7D7'}}>
                  <Typography variant="h4" className="regular-serif">
                    <span className="number-state">1.</span><span className="btn-text">Inscrito</span><span className="btn-icon">{stateNumber>=1 ? (stateNumber===1 ? <CachedIcon/> : <CheckIcon/>) : ''}</span>
                  </Typography>
                </Button>
                <Button className="btn-result" variant="contained" disabled style={{backgroundColor: stateNumber >=2 ? (stateNumber ===2 ? '#14A5DC' : '#AFD75E' ) : '#D7D7D7'}}>
                  <Typography variant="h4" className="regular-serif">
                    <span className="number-state">2.</span><span className="btn-text">Verificado</span><span className="btn-icon">{stateNumber>=2 ? (stateNumber===2 ? <CachedIcon/> : <CheckIcon/>) : ''}</span>
                  </Typography>
                </Button>
                <Button className="btn-result" variant="contained" disabled style={{backgroundColor: stateNumber>=3 ? '#14A5DC' : '#D7D7D7'}}>
                  <Typography variant="h4" className="regular-serif">
                    <span className="number-state">3.</span><span className="btn-text">Activo</span><span className="btn-icon">{stateNumber>=3 ? <CheckIcon/> : ''}</span>
                  </Typography>
                </Button>
              </Grid>
            :
              <Grid container className="second-content">
                <Typography variant="h5" className="result-description">Lo sentimos:</Typography>
                <Typography variant="h5" className="light-serif primarycolor">
                  El registro realizado con documento Número <span className="result-description">{id}</span> fue <span className="result-description">Rechazado.</span>
                </Typography>
                <Typography variant="h5" className="light-serif primaryColor">
                  Para conocer más información o realizar algún comentario por favor envie un correo electrónico a <a className="dont-break-out" href="mailto:egresados_admin@unal.edu.co">egresados_admin@unal.edu.co</a>
                </Typography>
              </Grid>
            }
            <Grid container className="third-content">
              <Button  variant="contained" className="home-btn" component={ Link } to="/">
                <Typography variant="h4" className="regular-serif">
                  Ir al inicio
                </Typography>
              </Button>
            </Grid>
          </Grid>
        :
        <Grid container item xs={12} justify="center">
          <Typography variant="h1" className="summary-title">
            Estado del registro
          </Typography>
          <Grid container justify="center" item xs={12}>
            <Typography variant="h2" className="bold-serif-primaryColorCompany">
              {resultState}
            </Typography>
          </Grid>
          <Typography variant="h5" className="summary-description">
            El proceso de autenticación casi concluye, su estado actual es <span className="result-description"> {resultState} </span>
          </Typography>
          {!rejected ?
            <Grid container>
            <Grid container className="second-content">
                <Button className="btn-result" variant="contained" disabled style={{backgroundColor: stateNumber>=1 ? (stateNumber ===1 ? '#14A5DC' : '#AFD75E' ) : '#D7D7D7'}}>
                  <Typography variant="h4" className="regular-serif">
                    <span className="number-state">1.</span><span className="btn-text">Inscrito</span><span className="btn-icon">{stateNumber>=1 ? (stateNumber===1 ? <CachedIcon/> : <CheckIcon/>) : ''}</span>
                  </Typography>
                </Button>
                <Button className="btn-result" variant="contained" disabled style={{backgroundColor: stateNumber>=2 ? '#14A5DC' : '#D7D7D7'}}>
                  <Typography variant="h4" className="regular-serif">
                    <span className="number-state">2.</span><span className="btn-text">Activo</span><span className="btn-icon">{stateNumber>=2 ? <CheckIcon/> : ''}</span>
                  </Typography>
                </Button>
            </Grid>
            { stateNumber>=2 &&
              <Grid container direction="column" justify="center"  alignItems="center">
                <Typography variant="h3" className="summary-description">
                  El correo asociado con la cuenta es :   <span className="result-description"> {email} </span>
                </Typography>
                <Typography variant="h3" className="summary-description">
                  El usuario asociado con la cuenta es :  <span className="result-description"> {username} </span>
                </Typography>
                <Grid item >
                <AlertBox
                alert="warning"
                variantType="outlined">
                  {
                    <span className="alert-simple-text">
                      Si el correo no corresponde, por favor escribir a:
                      <a className="result-description" 
                        href="mailto:egresados_admin@unal.edu.co"> egresados_admin@unal.edu.co</a>
                    </span>
                  }
                </AlertBox>
                </Grid>
              </Grid>
            }
            </Grid>
            :
            <Grid container className="second-content">
              <Typography variant="h5" className="result-description">Lo sentimos:</Typography>
              <Grid container>
              <Typography variant="h5" className="light-serif primarycolor">
                  El registro realizado con documento Número <span className="result-description">{id}</span> fue <span className="result-description">Rechazado.</span>
                </Typography>
                <Typography variant="h5" className="light-serif primaryColor">
                  Para conocer más información o realizar algún comentario por favor envie un correo electrónico a <a className="dont-break-out" href="mailto:egresados_admin@unal.edu.co">egresados_admin@unal.edu.co</a>
                </Typography>
              </Grid>
            </Grid>
          }
          
          <Grid container className="third-content">
            <Button  variant="contained" className="home-btn"  component={ Link } to="/">
              <Typography variant="h4" className="regular-serif">
                Ir al inicio
              </Typography>
            </Button>
          </Grid>

        </Grid>
      }
      </Grid>
    </>
  )
}

export default ShowStateBox
