import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonColors from '../ButtonColors';
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './WelcomeBox.scss';

interface WelcomeBoxProps{
  nameProfile: string;
  profile?: number;
}

const WelcomeBox: React.FC<WelcomeBoxProps> = ({nameProfile, profile}) => {
  const [expanded, setExpanded] = React.useState<string | boolean>('panel1');
  const handleChangeAccoridon = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid container item xs={12} className="welcome-accordion-container">
      <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccoridon('panel1')} className="summary-welcome-accordion-container">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          className="accordion-summary"
        >
          <Grid item xs={12}>
            <Grid container item xs={12} className="welcome-title">
              <Typography variant="h2" className="light-serif">
                ¡Bienvenido <span className="welcome-name">{nameProfile}</span>!
              </Typography>
            </Grid>
            <Grid container item xs={9}>
              <ButtonColors />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className="welcome-accordion-details">
          {
            profile === 1 ?
            <>
              <Typography variant="h5" className="welcome-text">
                Bienvenido a su perfil del portal de egresados de la Universidad Nacional de Colombia. Como egresado usted podrá consultar  ofertas laborales, actualizar sus datos de contacto, entro otros.
              </Typography>
            </>
            :
            <>
              <Typography variant="h5" className="welcome-text">
              Bienvenido a su perfil del portal de egresados de la Universidad Nacional de Colombia. Como empresa usted podrá publicar ofertas de empleo, seleccionar y descargar los curriculum de los candidatos seleccionados para cubrir sus ofertas laborales, actualizar sus datos de contacto, entro otros.
            </Typography>
            </>
          }
          
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

export default WelcomeBox
