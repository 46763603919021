import React, { useState, useEffect } from 'react';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { DataGrid, GridColDef, GridOverlay, GridColParams, GridCellParams, GridPageChangeParams, GridBaseComponentProps } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import { IGeneralParameter } from '../../interfaces/IGeneralParameter';
import { IHeadquarter } from '../../interfaces/IProfileCompany';
import HeadquarterModal from '../HeadquarterModal';
import { ICompanyHeadquarters } from '../../interfaces/ICompanyHeadquarters';
import { deleteHeadquarterCompany } from '../../services/CompanyService';
import SuccessModal from '../ShareComponents/SuccessModal';
import ErrorModal from '../ShareComponents/ErrorModal';
import LoadingLockPage from '../ShareComponents/LoadingLockPage';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { permissionCompanyDeleteHeadquarters, permissionCompanyEditHeadquarter } from '../../utils/PermissionsVariables';
import UserContext from '../../utils/UserContext';
import { containsAny } from '../../helpers/sharedFunctions';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 14,
      padding: '5px 6px 10px 12px',
      height: '30px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  }),
)(InputBase);

interface HeadquartersListProps{
    rows: Array<IHeadquarter>;
    pageSize: number;
    onChanhePageSize: any;
    initPage: number;
    setInitPage:any;
    cityArray: Array<IGeneralParameter>;
}

const HeadquartersList: React.FC<HeadquartersListProps> = ({ rows, pageSize, onChanhePageSize,  initPage, setInitPage, cityArray }) => {

    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [rowSelected, setRowSelected] = useState<ICompanyHeadquarters>(new ICompanyHeadquarters());
    const [errorMessage, setErrorMessage]= useState('');
    const [openModalError, setOpenModalError] = React.useState(false);
    const [successMessage, setSuccessMessage]= useState('');
    const [openModalSuccess, setOpenModalSuccess] = React.useState(false);

    const columnsHeadquarter: GridColDef[] = [
        { field: 'name', headerName: 'Nombre de la sede', width: 230, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Nombre de la sede'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'city', headerName: 'Municipio', width: 230, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Municipio'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              if(cityArray.length>0){
                const cityObj = cityArray.find(city => city.parameter_code === params.value);
                if(cityObj){
                    return(
                        <span className="curriculum-cell">{cityObj.name}</span>
                    )
                }
              }
              return(
                <span></span>
              )} },
        { field: 'address', headerName: 'Dirección', width: 230, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Dirección'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'phone', headerName: 'Número telefónico', width: 230, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Número telefónico'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'Acción', headerName: 'Acciones', width: 100, sortable: false ,renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Acciones'}
                </span>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=>handleClick(e,params)}>
                    <SettingsIcon/>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    {containsAny(permissionCompanyEditHeadquarter, userContext.userPermissions) &&
                        <MenuItem onClick={(e)=>{handleCloseMenu();handleRowButton()}}>Editar sede</MenuItem>
                    }
                    {containsAny(permissionCompanyDeleteHeadquarters, userContext.userPermissions) &&
                        <MenuItem onClick={(e)=>{handleCloseMenu();handleRowDelete()}}>Eliminar sede</MenuItem>
                    }
                </Menu>
                <HeadquarterModal
                    open={openModal}
                    handleClose={handleCloseModal}
                    title="Editar sede"
                    backBtnText="CANCELAR"
                    continueBtnText="EDITAR"
                    userSelected={rowSelected}
                    action="Edit"
                />
            </>
        ) },
    ];

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, params:GridCellParams) => {
        setAnchorEl(event.currentTarget);
        const user: ICompanyHeadquarters = Object.assign(params.row);
        setRowSelected(user)
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleRowButton = ()=>{
        setOpenModal(true);
    }

    const handleRowDelete = async()=>{
        if(rowSelected.id){
            const responseDHC: Response = await trackPromise(deleteHeadquarterCompany(rowSelected.id));
            validateModals(responseDHC);
        }
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handlePage = (pageParams: GridPageChangeParams)=>{
        setInitPage(1)
    }

    const validateModals = async(responseService: Response)=>{
        if(responseService.status === 200){
          setSuccessMessage('Acción exitosa');
          setOpenModalSuccess(true);
          setOpenModalError(false);
        } else {
          setErrorMessage("Hubo un error, intente de nuevo mas tarde");
          setOpenModalError(true);
          setOpenModalSuccess(false);
        }
    }

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
        window.location.reload();
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
        window.location.reload();
    };

    return (
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid autoHeight className="data-grid-list" disableColumnMenu rows={rows} columns={columnsHeadquarter} pageSize={pageSize} pagination  density="compact" disableSelectionOnClick  onPageChange={(params:GridPageChangeParams)=>handlePage(params)}
                components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                        Footer:(props)=>CustomFooter(props, initPage, onChanhePageSize, pageSize)
                }}
                />
            </Grid>
            <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess}/>
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError}/>
            <LoadingLockPage load={promiseInProgress}/>
        </Grid>
    );
}

const CustomFooter = (props: GridBaseComponentProps, initPage:number, onChanhePageSize?: any, valueRows?: number)=> {
    const { state, api } = props;
    const rowsPerPageOptions = [5,10,25,50,100];

    useEffect(()=>{
        if(initPage === 0){
            api.current.setPage(0)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[initPage]);

    return (
        <>
            <div className="footer-container">
                <div className="rows-number-container">
                    <span className="rows-number-text">Mostrar</span>
                    <Select
                        id="demo-simple-select"
                        value={valueRows}
                        onChange={onChanhePageSize}
                        input={<BootstrapInput/>}
                    >
                        {rowsPerPageOptions.map(item=>(
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                    <span className="rows-number-text">Registros</span>
                </div>
                <Pagination
                    className="pagination-container"
                    color="primary"
                    page={state.pagination.page+1}
                    count={state.pagination.pageCount}
                    onChange={(event, value) =>api.current.setPage(value-1)}
                    showFirstButton
                    showLastButton
                />
            </div>
        </>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large"/>
            <div className="not-results-text">Sin registros</div>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps)=><KeyboardArrowUpIcon fontSize="small"/>
const customSortDesc = (props: GridBaseComponentProps)=><KeyboardArrowDownIcon fontSize="small"/>

export default HeadquartersList;