import React, {useEffect} from 'react';

import { Button, Grid, Typography } from '@material-ui/core';
import { Link , useParams} from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import GraduateCurriculumView from '../../graduate/GraduateCurriculumView';
import UserContext from '../../../../utils/UserContext';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import { containsAny } from '../../../../helpers/sharedFunctions';
import { permissionAdminModifyGraduates, permissionGraduateView } from '../../../../utils/PermissionsVariables';


const CurriculumManagement: React.FC = () => {
  const userContext = React.useContext(UserContext);
  let params: any = useParams();

  useEffect(()=>{
    const getuserpercentage=async() =>{
      if(userContext.userId && params.id === undefined){
        await trackPromise(userContext.handleSetUserPercentage(userContext.userId));
      }else if(params.id){
          await trackPromise(userContext.handleSetUserPercentage(params.id));
      }
    }
    getuserpercentage()
  },[])
  
  return (
    <>
      <Grid container item xs={12} className="back-button-applicants-section">
        <Grid container item xs={12} className="main-container">
          <Grid container item xs={12} className="back-btn-container">
            <Button
              variant="contained"
              className="step-back-offers"
              startIcon={<NavigateBeforeIcon />}
              component={Link}
              to={'/admin/dashboard/egresados'}
            >
            <Typography variant="h6" className="regular-serif">
              Regresar a listado de egresados
            </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {containsAny(permissionAdminModifyGraduates,userContext.userPermissions) || containsAny(permissionGraduateView,userContext.userPermissions) ?
        <div>
          <GraduateCurriculumView />
        </div>
      :
        <NotAuthorized/>
      }
    </>
  )
}

export default CurriculumManagement
