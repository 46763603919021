import { IFile } from './IFile';
import { IPresignedUrl } from './IPreSignedUrl';
export class IRegisterGraduate{
    names: string = "";
    surname: string = "";
    secondsurname: string = "";
    gender: number = 0;
    ethnicgroup: number = 0;
    phone: number = 0;
    cellphone: number = 0;
    address: string = "";
    documentType: number = 0;
    doc: string = "";
    email: string = "";
    campus: number = 0;
    school: number = 0;
    curriculum: number = 0;
    academicLevel: number = 0;
    date: Date | null = null;
    dateError: string = "";
    diplomaNumber: string = "";
    educationState: number = 3;
    currentJobSituation: number = 0;
    typeOfCompany: number = 0;
    laboralSector: number = 0;
    description_document: string = "";
    fileError: string = "";
    files: Array<IFile> = [];
    signedUrllArr: Array<IPresignedUrl> = [];
    termsOfUse: boolean = false;
    captchaVerification: boolean = false;
    state: string = "Inscrito";
    photo: string = "";
    disability?: number = 1;
    inJobBank: Date | null = null;
}