export class RangeDateValues{
    sinceDate: string = "";
    untilDate: string = "";
}
export class IOfferPosition{
    jobTitle?: string = "";
    laboralSector?: number = 0;
    typePosition?: number = 0;
}
export class IOfferJobOffer{
    state?: Array<string> = [];
    country?: number = 0;
    department?: number = 0;
    city?: number = 0;
    rangeStartDate?: RangeDateValues = new RangeDateValues();
    rangeEndDate?: RangeDateValues = new RangeDateValues();
}
export class IOfferCompany{
    name?: string = "";
    nit?: number | null = null;
    laboralSector?: number = 0;
}
export class IOfferReport{
    position: IOfferPosition = new IOfferPosition();
    jobOffer: IOfferJobOffer = new IOfferJobOffer();
    company: IOfferCompany = new IOfferCompany();
}
export class AttributesOffer{
    position?: Array<string> = [];
    postulates?: Array<string> = [];
    jobOffer?: Array<string> = [];
}

export class IOfferFilterObj{
    preview?: boolean = true; // true return number, false send email
    filters:IOfferReport = new IOfferReport();
    attributes: AttributesOffer = new AttributesOffer();
}
