import React, { useState, useEffect } from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { usePromiseTracker } from 'react-promise-tracker';
import { trackPromise } from 'react-promise-tracker';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Button from "@material-ui/core/Button";
import WarningIcon from '@material-ui/icons/Warning';
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { IFile } from '../../interfaces/IFile';
import SubPreview from "../ShareComponents/SubPreview";
import SectionPreview from "../ShareComponents/SectionPreview";
import LoadingLockPage from "../ShareComponents/LoadingLockPage";
import { IGraduateInformation } from "../../interfaces/IGraduateInformation";
import RegisterContext from "../../utils/RegisterContext";
import { IGeneralParameter } from "../../interfaces/IGeneralParameter";
import { getACADEMIC_LEVEL, getCAMPUS_FACULTIES, getCOMPANY_TYPE, getEMPLOYMENT_SITUATION, getETHNIC_GROUP, getID_TYPE, getLABORAL_SECTOR, getPROGRAMS } from "../../services/ParameterService";
import { IGeneralSIA } from "../../interfaces/IGeneralSIA";
import { GENDER } from "../../services/SIAService";
import { IListParametersInfo } from "../../interfaces/IListParametersInfo";
import { IGetDocuments } from "../../interfaces/IGetDocuments";
import { getGraduateDocuments } from "../../services/GraduateService";
import { IAttachmentDocs } from "../../interfaces/IAttachmentDocs";
import UserContext from '../../utils/UserContext';
import SelectActionsBox2 from "../ManagementState/SelectActionsBox2";
import { UserStateObject } from "../../interfaces/UserStateObject";
import { UsersStateTosendObj } from '../../pages/dashboard/admin/GraduateManagement';
import { Redirect, useParams } from "react-router";
import ModalOffersAction from "../LaboralOffers/ModalOffersAction";
import ModalConfirmedAction from "../ShareComponents/ModalConfirmedAction";
import { changeStateGraduate } from "../../services/ChangeStateService";
import SuccessModal from "../ShareComponents/SuccessModal";
import ErrorModal from "../ShareComponents/ErrorModal";

interface GraduateInfoProps {
  graduateInfo: IGraduateInformation;
  handleGraduate: any;
}

const GraduateInformation: React.FC<GraduateInfoProps> = ({ graduateInfo, handleGraduate }) => {
  const { promiseInProgress } = usePromiseTracker();
  const registerContext = React.useContext(RegisterContext);
  const userContext = React.useContext(UserContext);
  const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
  let params: any = useParams();
  const [dateGraduation, setDateGraduation] = useState<string>('');
  const [genderLabel, setGenderLabel] = useState<string>('');
  const [ethnicLabel, setEthnicLabel] = useState<string>('');
  const [docTypeLabel, setDocTypeLabel] = useState<string>('');
  const [campusName, setCampusName] = useState<string>('');
  const [schoolName, setSchoolName] = useState<string>('');
  const [programName, setProgramName] = useState<string>('');
  const [academicName, setAcademicName] = useState<string>('');
  const [situationName, setSituationName] = useState<string>('');
  const [companyType, setCompanyType] = useState<string>('');
  const [sectorName, setSectorName] = useState<string>('');
  const [formValuesDocs, setFormValuesDocs] = useState<IAttachmentDocs>(new IAttachmentDocs({}));
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [roleSelected, setRoleSelected] = useState<boolean>(false);
  const [actionSelected, setActionSelected]= useState(0);
  const [observationSelected, setObservationSelected]= useState(0);
  const [stateSelected, setStateSelected] = useState<string>('');
  const [otherObservation, setOtherObservation] = useState<string>('');
  const [graduatesStateToSend, setGraduatesStateToSend] = useState(new UsersStateTosendObj());
  const [opeModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [stateChanged, setStateChanged] = useState<boolean>(false);
  const [serviceError, setServiceError]= useState('');
  const [openModalError, setOpenModalError] = React.useState(false);
  const [urlComp, setUrlComp] = useState<string | null>(null);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      //  get date format
      if (graduateInfo.date !== null) {
        const dateLabel = moment(graduateInfo.date).format('MMMM-YYYY')
        setDateGraduation(dateLabel)
      }
      if(userContext.roleSelected === 4 || userContext.roleSelected === 5 || userContext.roleSelected === 6){
      setRoleSelected(true)
    }
      //get gender name
      const dataGender: Array<IGeneralSIA> = GENDER;
      const genderType = dataGender.find(item => item.id === graduateInfo.gender);
      if (genderType) {
        setGenderLabel(genderType.name);
      }

      // get ethnic name
      const dataEthnic: Array<IGeneralParameter> = await getETHNIC_GROUP();
      const ethnicType = dataEthnic.find(item => item.parameter_code === graduateInfo.ethnicgroup);
      if (ethnicType) {
        setEthnicLabel(ethnicType.name);
      }

      //  get doctype name
      const dataDocType: Array<IGeneralParameter> = await getID_TYPE();
      const docType = dataDocType.find(item => item.parameter_code === graduateInfo.documentType);
      if (docType) {
        setDocTypeLabel(docType.name);
      }

      // academic info
      const dataAllCampus: Array<IListParametersInfo> = (await getCAMPUS_FACULTIES()).list;
      let schoolsArray: Array<IGeneralParameter> = [];
      const programsArray: Array<IGeneralParameter> = await getPROGRAMS();
      const academicArray: Array<IGeneralParameter> = await getACADEMIC_LEVEL();

      //  get campus name
      if (dataAllCampus) {
        const elemCampus = dataAllCampus.find(item => item.id === graduateInfo.campus);
        if (elemCampus) {
          setCampusName(elemCampus.parentName);
          schoolsArray = elemCampus.childs;
        }
      }
      //  get school name
      if (schoolsArray) {
        const elemSchool = schoolsArray.find(item => item.parameter_code === graduateInfo.school);
        if (elemSchool) {
          setSchoolName(elemSchool.name);
        }
      }

      //  get curriculum name
      if (programsArray) {
        const elemProgram = programsArray.find(item => item.parameter_code === graduateInfo.curriculum);
        if (elemProgram) {
          setProgramName(elemProgram.name);
        }
      }

      //  get academic name
      if (academicArray) {
        const elemAcademic = academicArray.find(item => item.parameter_code === graduateInfo.academicLevel);
        if (elemAcademic) {
          setAcademicName(elemAcademic.name);
        }
      }

      //  get employ situation name
      let responseEmploySitutaion: Array<IGeneralParameter> = await getEMPLOYMENT_SITUATION();
      if (responseEmploySitutaion && responseEmploySitutaion.length > 0) {
        const elemEmploy = responseEmploySitutaion.find(item => item.parameter_code === graduateInfo.currentJobSituation);
        if (elemEmploy) {
          setSituationName(elemEmploy.name);
        }
      }
      //  get company type
      let responseCompanyType: Array<IGeneralParameter> = await getCOMPANY_TYPE();
      if (responseCompanyType && responseCompanyType.length > 0) {
        const elemCompany = responseCompanyType.find(item => item.parameter_code === graduateInfo.typeOfCompany);
        if (elemCompany) {
          setCompanyType(elemCompany.name);
        }
      }
      //  get laboral sector name
      let responseLaboralSector: Array<IGeneralParameter> = await getLABORAL_SECTOR();
      if (responseLaboralSector && responseLaboralSector.length > 0) {
        const elemSector = responseLaboralSector.find(item => item.parameter_code === graduateInfo.laboralSector);
        if (elemSector) {
          setSectorName(elemSector.name);
        }
      }
    }
    getData();
    const getDocuments = async () => {
      if (graduateInfo.doc && graduateInfo.doc.length > 0) {
        const responseDocuments = await getGraduateDocuments(parseInt(graduateInfo.doc));
        if (responseDocuments.status === 200) {
          const resultDocuments: Array<IGetDocuments> = await responseDocuments.json();
          let newDocs: Array<IFile> = [];
          for (let i in resultDocuments) {
            const url = resultDocuments[i].signedUrl;
            const descriptionFile = resultDocuments[i].metadata.description;
            const fileName = resultDocuments[i].key.split("/")[1];
            const folderFileKey = resultDocuments[i].key;
            await fetch(url)
              .then(async response => {
                const blob = await response.blob()
                const file = new File([blob], fileName, { type: "application/pdf" });
                const previewUrl = URL.createObjectURL(file)
                if (file) {
                  newDocs.push(
                    { description: descriptionFile, file: file, previewUrl: previewUrl, folderFileKey: folderFileKey }
                  )
                }
              })
          }
          if (newDocs.length > 0) {
            setFormValuesDocs(prev => ({
              ...prev,
              files: newDocs
            }));
          }
        }
      }
      setInitLoad(false);
    }
    getDocuments();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerContext, graduateInfo])


  const handleChangeAction = (e: any)=>{
      setActionSelected(e.target.value)
  }

  const changeObservation = (e: any)=>{
      setObservationSelected(e.target.value)
  }

  const changeOtherObservation = (e: any)=>{
      setOtherObservation(e.target.value)
  }
  
  const handleChangeStateSelected = (e:any)=>{
      setStateSelected(e.target.value)
  }

  const closeModalConfirm = () =>{
    setOpenModalConfirm(false);
  }


  const handleCloseModalError = () => {
    setOpenModalError(false);
    window.location.reload();
  };

  const handleClose = () => {
      // setUrlComp(`/admin/dashboard/egresados`);
      setStateChanged(false);
      handleGraduate(false);
  };

  const sendActionSelected =()=>{
    let graduateStructure:Array<UserStateObject>=[];
    if(graduateInfo.doc !== undefined){
      graduateStructure.push({documentId: graduateInfo.doc.toString(), state: stateSelected, observation: observationSelected.toString(), otherObservation: otherObservation})
      setGraduatesStateToSend({graduates: graduateStructure})
      setOpenModalConfirm(true);
    }
  }

  const sendChangeState = async()=>{
    setOpenModalConfirm(false);
    const responseCSGraduate = await trackPromise(changeStateGraduate(
        userToken,
        {...graduatesStateToSend, modifyUser: userContext.userId}
    ));      
    if(responseCSGraduate.status === 200){
        const resultCSGraduate = await responseCSGraduate.json();
        if(graduatesStateToSend.graduates.length>1){
            const checkChanged = resultCSGraduate.messages.filter((respUser: any) =>{
                let validator: boolean = false;
                if(respUser.State === "Issue"){
                    validator = true;
                } else{
                    validator = false;
                }
                return validator && respUser
            });
            if(checkChanged.length>0){
                const usersError = checkChanged.map((user: any)=>user.User);
                setStateChanged(false);
                setServiceError(`Ocurrio un error cambiando de estado a los siguientes usuarios: ${usersError.toString()}`);
                setOpenModalError(true);
            } else {
                setStateChanged(true);
                setServiceError('');
                setOpenModalError(false);
            }
        } else {
            if(resultCSGraduate.messages[0].State === "Issue"){
                setStateChanged(false);
                setServiceError(`Ocurrio un error cambiando el estado del usuario: ${resultCSGraduate.messages[0].User.toString()} ${resultCSGraduate.messages[0].Observation}`);
                setOpenModalError(true);
            } else {
                setStateChanged(true);
                setServiceError('');
                setOpenModalError(false);
            }
        }
    } else {
        setStateChanged(false);
        setServiceError('Hubo un error, intente de nuevo mas tarde')
        setOpenModalError(true);
    }
  };
  
  return (
    <>
    {
    urlComp !== null ?
    <Redirect to={urlComp} />
    :
      <Grid container className="main-container">
        <Grid container className="third-step-container">
          <Grid container item xs={12} className="third-step-content">
            <Typography variant="h2" className="third-title-graduate">
              Información del egresado
            </Typography>
            <SubPreview title="Datos personales del egresado" step={0} panel="panel1" />
            <Grid container item xs={12}>
              <SectionPreview cols={3} firstTitle="nombre" firtsValue={graduateInfo.names} secondTitle="Apellido" secondValue={graduateInfo.surname} thirdTitle="Segundo apellido" thirdValue={graduateInfo.secondsurname} />
              <SectionPreview cols={2} firstTitle="Género" firtsValue={genderLabel} secondTitle="Grupo étnico" secondValue={ethnicLabel} />
              <SectionPreview cols={3} firstTitle="Número celular" firtsValue={graduateInfo.cellphone + ""} secondTitle="Número telefónico" secondValue={graduateInfo.phone + ""} thirdTitle="Dirección de residencia" thirdValue={graduateInfo.address} />
              <SectionPreview cols={2} firstTitle="Documento de identidad" firtsValue={docTypeLabel + ' - ' + graduateInfo.doc} secondTitle="Correo electrónico" secondValue={graduateInfo.email} />
            </Grid>
            <SubPreview title="Datos académicos del egresado" step={0} panel="panel2" />
            <Grid container item xs={12}>
              <SectionPreview cols={3} firstTitle="Sede" firtsValue={campusName} secondTitle="Facultad" secondValue={schoolName} thirdTitle="Programa" thirdValue={programName} />
              <SectionPreview cols={1} firstTitle="Nivel educativo" firtsValue={academicName} />
              <SectionPreview cols={2} firstTitle="Fecha de graduación" firtsValue={dateGraduation} secondTitle="Número de diploma" secondValue={graduateInfo.diplomaNumber} />
            </Grid>
            <SubPreview title="Datos laborales del egresado" step={0} panel="panel3" />
            <Grid container item xs={12}>
              <SectionPreview cols={1} firstTitle="Situación laboral actual" firtsValue={situationName}/>
              <SectionPreview cols={2} firstTitle="Tipo de empresa" firtsValue={companyType} secondTitle="Sector laboral" secondValue={sectorName}/>
            </Grid>
            <SubPreview title="Documentos adjuntos" step={1} />

            <Grid container item xs={12} className="photography-content-container">
              <Grid container item xs={12} justify="space-around">
                {formValuesDocs.files.map((file: IFile, index: number) => (
                  <Grid container item xs={11} md={3} key={index} className="card-container">
                    <div className="card-header-container">
                      <Typography variant="h5" className="regular-serif card-title-item">
                        {file.file?.name}
                      </Typography>
                    </div>
                    <Grid container item xs={12} className="card-desc-container">
                      <Typography variant="h6" className="regular-serif card-desc-item">
                        {file.description !== '' ? file.description : 'sin descripción'}
                      </Typography>
                    </Grid>
                    <Grid container item xs={12} justify="flex-end" className="card-action">
                      <div className="card-action-btn">
                        <Link target="_blank" rel="noopener" href={file.previewUrl}>
                          <Typography align="center" variant="h6" className="regular-serif">
                            Ver documento
                          </Typography>
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {
        roleSelected ?
        <>
        <Grid container item xs={12}>
            <SelectActionsBox2 stateSelected={stateSelected} actionSelected={actionSelected} onChangeAction={handleChangeAction} onChangeStateSelected={handleChangeStateSelected} sendActionSelected={sendActionSelected} onChangeForm={changeObservation} observationSelected={observationSelected} changeOtherObservation={changeOtherObservation} otherObservation={otherObservation} role="graduate"/>
        </Grid>
        
        </>
        :
        <>
        </>
      }

        <div className="step-buttons">
          <div className="step-container-btn">
            <Button onClick={(e) => { handleGraduate(false) }} variant="contained" className="step-back" startIcon={<NavigateBeforeIcon />}>
              ATRAS
            </Button>
          </div>
        </div>
        
        <ModalOffersAction
        open={opeModalConfirm}
        handleClose={closeModalConfirm}
        handleContinue={sendChangeState}
        iconModal={<WarningIcon className="big-info-icon" />}
        title="¿Está seguro de cambiar el estado del egresado??"
        headerdescription=""
        description="Esta intentando cambiar el estado de un egresado"
        backBtnText="Regresar"
        colorBtnBack= ""
        continueBtnText="Si, Continuar"
        colorBtnContinue="#14A5DC"
        valid={true}
      />
      <ModalConfirmedAction
          open={stateChanged}
          handleClose={handleClose}
          icon={<CheckCircleOutlineIcon style={{color: '#7DA52D', fontSize:'50px'}}/>}
          description="Acción ejecutada exitosamente"
      />
      <ErrorModal errorMsg={serviceError} handleCloseModal={handleCloseModalError} openModalError={openModalError}/>
      <LoadingLockPage load={promiseInProgress} />
      <LoadingLockPage load={initLoad} />
      </Grid>
    }
    </>
  )
}

export default GraduateInformation
