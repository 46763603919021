import React, { useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import Button from "@material-ui/core/Button"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import RegisterContext from "../../../utils/RegisterContext"
import { IRegisterGraduate } from '../../../interfaces/IRegisterGraduate';
import { IGeneralSIA } from "../../../interfaces/IGeneralSIA"

const PersonalInfo: React.FC = () => {
  const registerContext = React.useContext(RegisterContext);
  const formValues: IRegisterGraduate = registerContext.formValues;
  const formErrors: IRegisterGraduate = registerContext.formErrors;

  const isValidFirstStep =
    registerContext.firstAccorDone &&
    registerContext.secondAccorDone &&
    registerContext.thirdAccorDone

  const isValid =
    formValues.names.length > 0 &&
    !formErrors.names &&
    formValues.surname.length > 0 &&
    !formErrors.surname &&
    !formErrors.secondsurname &&
    formValues.gender > 0 &&
    formValues.cellphone > 0 &&
    !formErrors.cellphone &&
    !formErrors.phone &&
    formValues.address.length > 0 &&
    !formErrors.address &&
    formValues.documentType > 0 &&
    formValues.doc.length > 0 &&
    !formErrors.doc &&
    formValues.email.length > 0 &&
    !formErrors.email

  useEffect(()=>{
    registerContext.handleValidForm('panel1', isValid);
  })


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={4} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Nombres*</Typography>
          <TextField
            fullWidth
            name="names"
            margin="none"
            label={formValues.names=== "" ? "Ingrese sus nombres": ""}
            value={formValues.names || ""}
            onChange={registerContext.handleChange}
            error={!!formErrors.names}
            helperText={formErrors.names}
            InputLabelProps={{shrink: false}}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Primer apellido*</Typography>
          <TextField
            fullWidth
            name="surname"
            margin="none"
            label={formValues.names=== "" ? "Ingrese su primer apellido": ""}
            value={formValues.surname || ""}
            onChange={registerContext.handleChange}
            error={!!formErrors.surname}
            helperText={formErrors.surname}
            InputLabelProps={{shrink: false}}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Segundo apellido</Typography>
          <TextField
            fullWidth
            name="secondsurname"
            margin="none"
            label={formValues.secondsurname=== "" ? "Ingrese su segundo apellido": ""}
            value={formValues.secondsurname || ""}
            onChange={registerContext.handleChange}
            error={!!formErrors.secondsurname}
            helperText={formErrors.secondsurname}
            InputLabelProps={{shrink: false}}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Sexo*</Typography>
          <TextField
            fullWidth
            id="outlined-select-gender"
            select
            name="gender"
            label={formValues.gender === 0 ? "Seleccione": ""}
            value={formValues.gender === 0 ? "" : formValues.gender}
            onChange={registerContext.handleChange}
            InputLabelProps={{shrink: false}}
            margin="none"
            variant="outlined"
          >
            {
              registerContext.gendersType && registerContext.gendersType.length> 0 ?
              registerContext.gendersType.map((option: IGeneralSIA) => (
                  <MenuItem key={option.id} value={option.id}>
                      {option.name}
                  </MenuItem>
                ))
            :
              <MenuItem value={formValues.gender}></MenuItem>
            }
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Grupo étnico</Typography>
          <TextField
            fullWidth
            id="outlined-select-ethnicgroup"
            select
            name="ethnicgroup"
            label={formValues.ethnicgroup === 0 ? "Seleccione": ""}
            value={formValues.ethnicgroup === 0 ? "" : formValues.ethnicgroup}
            onChange={registerContext.handleChange}
            InputLabelProps={{shrink: false}}
            margin="none"
            variant="outlined"
          >
            {
              registerContext.ethnicGroup && registerContext.ethnicGroup.length> 0 
            }
            {
              registerContext.ethnicGroup && registerContext.ethnicGroup.length> 0 ?
              registerContext.ethnicGroup.map((option: IGeneralParameter) => (
                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                  {option.name}
                </MenuItem>
              ))
            :
              <MenuItem value={formValues.ethnicgroup}></MenuItem>
            }
          </TextField>
        </Grid>

        <Grid container item xs={12}>
          <hr className="divider-accordion"/>
        </Grid>

        <Grid container item xs={12} sm={6} lg={4} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Celular*</Typography>
          <TextField
            fullWidth
            name="cellphone"
            margin="none"
            label={formValues.cellphone=== 0 ? "Ingrese su número celular": ""}
            value={formValues.cellphone === 0 ?  "" : formValues.cellphone}
            onChange={registerContext.handleChange}
            error={!!formErrors.cellphone}
            helperText={formErrors.cellphone === 0 ? "" : formErrors.cellphone}
            InputLabelProps={{shrink: false}}
            variant="outlined"
          />
        </Grid>

        <Grid container item xs={12} sm={6} lg={4} className="field-box-margin">
          <Typography variant="h6" className="field-box-title-graduate">Número telefónico</Typography>
          <TextField
            fullWidth
            name="phone"
            margin="none"
            label={formValues.phone=== 0 ? "Ingrese su número telefónico": ""}
            value={formValues.phone === 0 ?  "" : formValues.phone}
            onChange={registerContext.handleChange}
            error={!!formErrors.phone}
            helperText={formErrors.phone === 0 ? "" : formErrors.phone}
            InputLabelProps={{shrink: false}}
            variant="outlined"
          />
        </Grid>

        <Grid container item xs={12} md={12} lg={4} className="field-box-margin">
            <Typography variant="h6" className="field-box-title-graduate">Dirección de residencia*</Typography>
            <TextField
              fullWidth
              name="address"
              margin="none"
              label={formValues.address=== "" ? "Ingrese su dirección": ""}
              value={formValues.address || ""}
              onChange={registerContext.handleChange}
              error={!!formErrors.address}
              helperText={formErrors.address}
              InputLabelProps={{shrink: false}}
              variant="outlined"
            />
        </Grid>

        <Grid container item xs={12}>
          <hr className="divider-accordion"/>
        </Grid>

        <Grid container item xs={12} direction="row">
          <Grid container item xs={12} xl={6} direction="row" className="special-column-right">
            <Grid item xs={12} md={4} xl={5} className="field-box-margin">
              <Typography variant="h6" className="field-box-title-graduate">Tipo de identificación*</Typography>
              <TextField
                fullWidth
                id="outlined-select-documentType"
                select
                name="documentType"
                label={formValues.documentType === 0 ? "Seleccione": ""}
                value={formValues.documentType === 0 ? "" : formValues.documentType}
                onChange={registerContext.handleChange}
                InputLabelProps={{shrink: false}}
                margin="none"
                variant="outlined"
              >
                {
                  registerContext.documentsType && registerContext.documentsType.length> 0 ?
                  registerContext.documentsType.map((option: IGeneralParameter) => (
                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                      {option.name}
                    </MenuItem>
                  ))
                :
                  <MenuItem value={formValues.documentType}></MenuItem>
                }
              </TextField>
            </Grid>
            <Grid item xs={12} md={8} xl={7} className="field-box-margin special-box-left">
              <Typography variant="h6" className="field-box-title-graduate">Número de identificación*</Typography>
              <TextField
                fullWidth
                name="doc"
                margin="none"
                label={formValues.doc=== "" ? "Ingrese su número de identificación": ""}
                value={formValues.doc || ""}
                onChange={registerContext.handleChange}
                error={!!formErrors.doc}
                helperText={formErrors.doc}
                InputLabelProps={{shrink: false}}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} xl={6} className="special-column-left">
            <Grid item xs={12} className="field-box-margin">
              <Typography variant="h6" className="field-box-title-graduate">Correo electrónico*</Typography>
              <TextField
                fullWidth
                name="email"
                margin="none"
                label={formValues.email=== "" ? "Ingrese su correo electrónico": ""}
                value={formValues.email || ""}
                onChange={registerContext.handleChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
                InputLabelProps={{shrink: false}}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          className="accordion-btn"
          endIcon={<ExpandMoreIcon/>}
          disabled={!isValid}
          onClick={isValid ? (isValidFirstStep ? registerContext.handleChangeAccordion('panel2',false, 'panel1') :registerContext.handleChangeAccordion('panel2',true, 'panel1')) : null}
        >
          Siguiente
        </Button>
      </div>
    </>
  )
}

export default PersonalInfo
