import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../services/AuthService';
import jwt from 'jsonwebtoken';
import UserContext from '../utils/UserContext';
import { logoutAndReset } from '../helpers/sharedFunctions';
import { getListPermissions, getListPermissionsByRole } from '../services/AdminServices';
import LoadingLockPage from '../components/ShareComponents/LoadingLockPage';

const PublicRoute = ({component: Component, layout:Layout, restricted, ...rest}) => {
    const userContext = React.useContext(UserContext);
    const [initLoad, setInitLoad] = useState(true);
    const [ready, setReady]= useState(false);

    useEffect(()=>{
        const getData = async() => {
            if(userContext.permissions.length === 0 && userContext.permissionsRoles.length === 0){
                    const repsonsePermissions = await getListPermissions();
                    if(repsonsePermissions.status === 200){
                      const resultPermissions = (await repsonsePermissions.json()).permissionsInfo;
                      userContext.setPermissions(resultPermissions)
                      const resPermissionsRoles = await getListPermissionsByRole();
                      if(resPermissionsRoles.status === 200){
                        const resultPermissionsRoles = (await resPermissionsRoles.json()).permissionsByRole;
                        userContext.setPermissionsRoles(resultPermissionsRoles);
                        getDataUser(resultPermissionsRoles);
                      }
                    }
            } else {
                getDataUser(userContext.permissionsRoles);
            }
        }
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getDataUser = (permissionsRolesArray) =>{
        let temporaryRole = userContext.roleSelected > 0 ? userContext.roleSelected : 0;
        let permissionsObj;
        if(sessionStorage.getItem('Bearer')){
            const itemProfile = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
            if(jwt.decode(itemProfile)){
                const resDecoded = jwt.decode(itemProfile);
                if(resDecoded){
                    const profileDecoded = resDecoded.message;
                    const getRoles = profileDecoded.roles;
                    if(getRoles.length===0){
                        if(!userContext.logged){
                            logoutAndReset(userContext);
                        }
                    }
                    if(sessionStorage.getItem('temp')){
                        const itemTemp = sessionStorage.getItem('temp') ? sessionStorage.getItem('temp')+"" : "";
                        if(jwt.decode(itemTemp)){
                            const tempDecoded = jwt.decode(itemTemp);
                            if(tempDecoded.roleSelected){
                                permissionsObj = permissionsRolesArray.find(item=>item.id === tempDecoded.roleSelected);
                                temporaryRole = tempDecoded.roleSelected || 0;
                            }
                            userContext.setTemporaryKey(tempDecoded.temp || false);
                        }
                    }
                    userContext.setUsername(profileDecoded.username !== '' ? profileDecoded.username : '');
                    userContext.setUserId(profileDecoded.documentId !== '' ? profileDecoded.documentId : '');
                    userContext.setName(profileDecoded.name !== '' ? profileDecoded.name : '');
                    userContext.setRolesUser(getRoles);
                    userContext.setRoleSelected(temporaryRole);
                    userContext.setRolePermissions(permissionsObj);
                    userContext.setUserPermissions(permissionsObj.permissions.map(x=>x.id));
                }
            }
        } else {
            userContext.setUsername('');
            userContext.setUserId('');
            userContext.setName('');
            userContext.setRolesUser([]);
            userContext.setRolePermissions(undefined);
            userContext.setUserPermissions([]);
        }
        setReady(true);
        setInitLoad(false);
    }

    return (
        <>
        {
            ready &&
            <Route {...rest} render={props =>(
                <>
                {
                    isLogin() && restricted ?
                    <>
                    {
                        (userContext.roleSelected === 2 || userContext.roleSelected === 3 ) &&
                        <Redirect to="/empresa/dashboard" />
                    }
                    {
                        userContext.roleSelected === 1 &&
                        <Redirect to="/egresado/dashboard"/>
                    }
                    {
                        userContext.roleSelected > 3 &&
                        <Redirect to="/admin/dashboard"/>
                    }
                    </>
                :
                    <Layout><Component {...props} /></Layout>
                }
                </>
            )} />
        }
        <LoadingLockPage load={initLoad} />
        </>
    );
};

export default PublicRoute;
