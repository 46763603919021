import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import 'moment/locale/es';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { Link, Redirect } from 'react-router-dom';
import Validations from '../../../helpers/Validations';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import UserContext from '../../../utils/UserContext';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import InfoIcon from '@material-ui/icons/Info';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import NotificationBox from '../../ShareComponents/NotificationBox';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import './TrainingEventForm.scss';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import MenuItem from "@material-ui/core/MenuItem";
import { IRegisterTrainingEvent } from '../../../interfaces/IRegisterTrainingEvent';
import moment from 'moment';
import { getTYPE_EVENT } from '../../../services/ParameterService';
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { createTrainingEvent, downloadTrainGuestFile, updateTrainingEvent } from '../../../services/TrainingEventServices';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import Excel from 'exceljs';
import { IGetTrainingEvents } from '../../../interfaces/IGetTrainingEvents';
import { IGetTrainingEventsGuests } from '../../../interfaces/IGetTrainingEventsGuests';
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttendeesList from '../AttendeesList';
import AlertBox from '../../ShareComponents/AlertBox';

function readFile(fileRes: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(fileRes)
        reader.onload = () => {
            resolve(reader.result)
        }
    })
}

const initialFormValues = {
    name: "",
    typeEvent: '',
    description: '',
    initDate: '',
    endDate: ''
}

const formValidations = {
    name: {
        error: "",
        validate: "textNumber",
        minLength: 3,
        required: true
    },
    typeEvent: {
        validate: "select",
        required: true
    },
    description: {
        error: "",
        validate: "textNumber",
        maxLength: 400,
        minLength: 10,
        required: false
    },
    initDate: {
        error: "",
        validate: "normalDate",
        minDate: '',
        required: true
    },
    endDate: {
        error: "",
        validate: "normalDate",
        minDate: new Date(),
        required: true
    }
}

interface TrainingEventFormProps {
    action?: string;
    surveySelected?: IGetTrainingEvents;
}

const TrainingEventForm: React.FC<TrainingEventFormProps> = ({ action, surveySelected }) => {
    const userContext = React.useContext(UserContext);
    const { promiseInProgress } = usePromiseTracker();
    const [formValues, setFormValues] = useState(new IRegisterTrainingEvent({}));
    const [formErrors, setFormErrors] = useState(initialFormValues);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [openModalError, setOpenModalError] = useState(false);
    const [aux, setAux] = useState<boolean>(false);
    const [typeEventArray, setTypeEventArray] = useState<Array<IGeneralParameter>>([]);
    const [flagUpdatedFile, setFlagUpdatedFile] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [initPage, setInitPage] = useState<number>(0);
    const [addedByUser, setAddedByUser] = useState<boolean>(false);
    const [guestsData, setGuestsData] = useState<Array<IGetTrainingEventsGuests>>([]);
    const isValid =
        formValues.name &&
        !formErrors.name &&
        formValues.description &&
        !formErrors.description &&
        formValues.typeEvent &&
        !formErrors.typeEvent &&
        moment(formValues.initDate).isValid() &&
        !formErrors.initDate &&
        moment(formValues.endDate).isValid() &&
        !formErrors.endDate;

    useEffect(() => {
        const getInitialData = async () => {
            const reqArray: Array<IGeneralParameter> = await getTYPE_EVENT();
            reqArray.sort((a, b) => a.parameter_code - b.parameter_code)
            setTypeEventArray(reqArray);
        }
        getInitialData()
        if (action === "Edit") {
            const formData = async () => {
                const target: IGetTrainingEvents = new IGetTrainingEvents();
                Object.assign(target, surveySelected)
                for (let [key, value] of Object.entries(target)) {
                    setFormValues(prev => ({
                        ...prev,
                        [key]: value
                    }));
                }
                if (surveySelected){
                    const responseCSF = await trackPromise(downloadTrainGuestFile({ id: surveySelected.id, preview: true}));
                    if (responseCSF.status === 200) {
                        const result = await responseCSF.json();
                        console.dir(result.data);
                        setGuestsData(result.data);
                    }
                }
            }
            formData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, surveySelected])

    const handleCloseModalSuccess = () => {
        setOpenModalSuccess(false);
        setAux(true);
    };

    const handleCloseModalError = () => {
        setOpenModalError(false);
        window.location.reload();
    };

    const handleDeleteFile = () => {
        setFlagUpdatedFile(true)
        setFormValues(prev => ({
            ...prev,
            file: null
        }));
    }
    const handleChange = async (e: any) => {
        const { name, value } = e.target;
        if (name === 'file') {
            setFlagUpdatedFile(true)
            const buffer = await readFile(e.target.files[0])
            const workbook = new Excel.Workbook()
            const file = await workbook.xlsx.load(buffer as Buffer)
            const newBuffer = await file.xlsx.writeBuffer();
            setFormValues(prev => ({
                ...prev,
                [name]: newBuffer
            }));
            setAddedByUser(true);
        } else {
            setFormValues(prev => ({
                ...prev,
                [name]: value
            }));
            const error = Validations(name, value, formValidations) || "";
            setFormErrors(prev => ({
                ...prev,
                [name]: error
            }));
        }

    }

    const handleChangeDate = (dateValue: Date | null, formField: string) => {
        const value = dateValue ? dateValue?.toString() : '';
        setFormValues(prev => ({
            ...prev,
            [formField]: dateValue
        }));
        if (formField === "initDate") {
            setFormValues(prev => ({
                ...prev,
                endDate: null
            }));
        }
        if (formField === "endDate") {
            formValidations.endDate.minDate = formValues.initDate ? new Date(formValues.initDate) : moment(new Date()).toDate();
        }
        const error: string = Validations(formField, value, formValidations) || "";
        setFormErrors((prev: any) => ({
            ...prev,
            [formField]: error
        }));
    }
    const requestTrainingEvents = async () => {
        if (action === "Edit") {
            let objectToSend: any = Object.assign({}, formValues);
            if (flagUpdatedFile && objectToSend.file === null) {
                objectToSend['file'] = { type: "Delete" }
            }
            const responseCOF: Response = await trackPromise(updateTrainingEvent(objectToSend));
            if (responseCOF.status === 200) {
                setSuccessMessage("El evento se ha modificado con exito");
                setOpenModalSuccess(true);
                setAux(true)
            } else {
                setErrorMessage("Hubo un error, intente de nuevo mas tarde");
                setOpenModalError(true);
            }
        } else {
            const eventToSend: IRegisterTrainingEvent = new IRegisterTrainingEvent(formValues);
            const { id, ...myNewObject } = eventToSend;
            const responseCOF: Response = await trackPromise(createTrainingEvent(myNewObject));
            if (responseCOF.status === 200) {
                setSuccessMessage("El evento se ha creado con exito");
                setOpenModalSuccess(true);
                setAux(true)
            } else {
                setErrorMessage("Hubo un error, intente de nuevo mas tarde");
                setOpenModalError(true);
            }
        }
    }

    const onChanhePageSize = (e: any) => {
        setRowsPerPage(e.target.value)
    }

    return (
        <>
            {!aux ?
                <>
                    <Grid container item xs={12} lg={10} className="trainingevent-form">

                        <Grid container item xs={12} className="trainingevent-container">
                            <Grid container item xs={12} className="title-container">
                                <Typography variant="h2" className="title">
                                    Contenido
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} direction="row" className="trainingevent-content">
                                <Grid item xs={12} className="field-box-margin">
                                    <Typography variant="h5" className="field-box-title">Nombre del Evento*</Typography>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        placeholder="Título"
                                        multiline
                                        rows={1}
                                        variant="outlined"
                                        value={formValues.name || ""}
                                        onChange={handleChange}
                                        error={!!formErrors.name}
                                        helperText={formErrors.name}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12} className="field-box-margin">
                                    <Typography variant="h5" className="field-box-title">Tipo de Evento*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-typeEvent"
                                        select
                                        name="typeEvent"
                                        label={formValues.typeEvent === 0 ? "Seleccione" : ""}
                                        value={formValues.typeEvent === 0 ? "" : formValues.typeEvent}
                                        onChange={handleChange}
                                        InputLabelProps={{ shrink: false }}
                                        margin="none"
                                        variant="outlined"
                                    >
                                        {
                                            typeEventArray.length > 0 ?
                                                typeEventArray.map((option: IGeneralParameter) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                                :
                                                <MenuItem value={formValues.typeEvent}></MenuItem>
                                        }
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} className="field-box-margin">
                                    <Typography variant="h5" className="field-box-title">Descripción*</Typography>
                                    <TextField
                                        fullWidth
                                        name="description"
                                        placeholder="Descripción "
                                        multiline
                                        rows={5}
                                        variant="outlined"
                                        value={formValues.description || ""}
                                        onChange={handleChange}
                                        error={!!formErrors.description}
                                        helperText={formErrors.description}
                                        margin="none"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                                    <Typography variant="h5" className="field-box-title">Fecha de inicio del evento*</Typography>
                                    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                        <DatePicker
                                            openTo="year"
                                            views={['year', 'month', 'date']}
                                            value={formValues.initDate || null}
                                            onChange={(value) => handleChangeDate(moment(value).toDate(), 'initDate')}
                                            renderInput={(params) => (
                                                <TextField
                                                    disabled
                                                    {...params}
                                                    fullWidth
                                                    name="initDate"
                                                    margin="none"
                                                    label={formValues.initDate !== null ? "" : "Seleccione Fecha dd/mm/aaaa"}
                                                    error={!!formErrors.initDate}
                                                    helperText={formErrors.initDate}
                                                    InputLabelProps={{ shrink: false }}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                                    <Typography variant="h5" className="field-box-title">Fecha de finalización del evento*</Typography>
                                    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                        <DatePicker
                                            openTo="year"
                                            views={['year', 'month', 'date']}
                                            value={formValues.endDate || null}
                                            onChange={(value) => handleChangeDate(moment(value).toDate(), 'endDate')}
                                            renderInput={(params) => (
                                                <TextField
                                                    disabled
                                                    {...params}
                                                    fullWidth
                                                    name="endDate"
                                                    margin="none"
                                                    label={formValues.endDate !== null ? "" : "Seleccione Fecha dd/mm/aaaa"}
                                                    error={!!formErrors.endDate}
                                                    helperText={formErrors.endDate}
                                                    InputLabelProps={{ shrink: false }}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                {formValues.file ?
                                    <Grid container justify="center">
                                        <Grid item xs={6} className="card-image-container">
                                            <div className="card-header-container">
                                                <Typography variant="h5" className="regular-serif card-title-item">
                                                    {formValues.name}_Participantes.xlsx
                                                </Typography>
                                                <IconButton style={{ padding: 0, height: '30px' }} onClick={handleDeleteFile}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                            <div className='card-image-content'>
                                                <DescriptionIcon fontSize="large" />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    : <></>
                                }
                                <Grid container item xs={12} justify="flex-start">
                                    <div className="upload-button-container">
                                        <Button variant="contained" component="label" className="upload-button">
                                            <Typography variant="h5" className="regular-serif">
                                                Adjuntar archivo
                                            </Typography>
                                            <div>
                                                <input type="file" name="file" onChange={handleChange} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" hidden />
                                            </div>
                                        </Button>
                                    </div>
                                </Grid>
                                
                                {formValues.file && !addedByUser &&
                                    <Grid item xs={12} className="field-box-margin">
                                        <AlertBox
                                            alert="warning"
                                            variantType="outlined"
                                        >
                                            {
                                                <span className="alert-simple-text">
                                                    Una vez edites el archivo existente, no podras hacer visualizacion hasta volver a guardar
                                                </span>
                                            }
                                        </AlertBox>
                                        <Accordion className="curriculum-section-accordion-container">
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                className="accordion-summary"
                                            >
                                                <Typography variant="h3" className="bold-serif">Contenido del Archivo</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className="curriculum-information-accordion-details">
                                                <Grid container xs={12}>
                                                    <AttendeesList onChanhePageSize={onChanhePageSize} events={guestsData} pageSize={rowsPerPage} initPage={initPage} setInitPage={setInitPage} />
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={2} justify="center" style={{ height: 'fit-content' }}>
                        <NotificationBox
                            bgColor="#CFF4FC"
                            title="Capacitaciones"
                            titleColor="#055160"
                            description="En esta sección puede crear un nuevo evento. Todos los campos marcados con asterisco (*) son obligatorios. "
                            descColor="#055160"
                            icon={<InfoIcon style={{ color: '#055160' }} fontSize="large" />}
                        />
                    </Grid>
                    <Grid container item xs={12} lg={10} className="buttons-container-trainingevent  ">
                        <Grid container item xs={12} md={6} className="left-buttons">
                            <Button startIcon={<ChevronLeftIcon />} className="cancel-button " variant="contained" size="small" component={Link} to="/admin/dashboard/capacitaciones">Cancelar</Button>
                        </Grid>
                        <Grid container item xs={12} md={6} className="right-buttons">
                            <Button disabled={!isValid} onClick={requestTrainingEvents} className="save-button" variant="contained" size="small">Guardar</Button>
                        </Grid>
                    </Grid>
                    <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
                    <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
                    <LoadingLockPage load={promiseInProgress} />
                </>
                :
                <Redirect to="/admin/dashboard/capacitaciones" />
            }
        </>
    )
}

export default TrainingEventForm
