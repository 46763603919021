class IDefault{
	missing: Array<string> = []
	percentage: number = 0
}

class IAccData{
	missing:{
        otherStudies: Array<string>;
        scholarships: Array<string>;
		languages: Array<string>;
    } = {
        otherStudies:[],
        scholarships: [],
		languages: []
    };
	percentage: number = 0
}

class IAddData{
	missing:{
        associations: Array<string>;
		recognitions: Array<string>;
		studiesInterests: Array<string>;
		proyects: Array<string>;
		ventures: Array<string>;
    } = {
        associations: [],
		recognitions: [],
		studiesInterests: [],
		proyects: [],
		ventures: []
    };
	percentage: number = 0
}
export class IUserPercentage {
    personalData: IDefault
	academicData: IAccData
	laboralData: IDefault
	aditionalData: IAddData
	total: number

	constructor(def:any){
		this.personalData = def.personalData || new IDefault();
        this.academicData = def.academicData || new IAccData();
        this.laboralData = def.laboralData || new IDefault();
        this.aditionalData = def.aditionalData || new IAddData();
        this.total = def.total || 0;
    }
}