import React from 'react'
import './HeaderDashboard.scss';
import HeaderBox from '../../components/ShareComponents/HeaderBox'

const HeaderDashboard: React.FC = ()=>{
    return(
        <header >
            <HeaderBox />
        </header>
    )
}

export default HeaderDashboard