import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import './SectionTitle.scss';

interface SectionTitleProps{
    title: string;
    titleSize?: number;
}

const SectionTitle:React.FC<SectionTitleProps> = ({title, titleSize}) => {
    return (
        <Grid container item xs={12} className="title-additional-info-container" alignItems="center">
            <Grid container item xs={12} md={4} lg={4} >
                <Grid className="title-additional-text">
                        <Typography style={{fontSize: titleSize? titleSize: 24}} className="bold-serif-primaryColor">
                            {title}
                        </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} md={8} lg={8} className="line-section" />
        </Grid>
    )
}

export default SectionTitle
