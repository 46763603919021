export class IGraduateTable{
    id: string = "";
    names: string = "";
    surname: string = "";
    secondsurname: string = "";
    fullname?: string = "";
    doc: string = "";
    curriculum: string = "";
    updatedAt: string = "";
    date: string = "";
    state: string = "";
}