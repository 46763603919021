import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useParams } from "react-router-dom";
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import OfferForm from '../../../../components/LaboralOffers/OfferForm';
import ApplicantList from '../../../../components/LaboralOffers/ApplicantList';
import UserContext from '../../../../utils/UserContext';
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import { listPostulations } from '../../../../services/PostulationService';
import { IGetPostulation } from '../../../../interfaces/IGetPostulation';
import { IApplicantInfo } from '../../../../interfaces/IApplicantInfo';
import { getCompanyInfo } from '../../../../services/CompanyService';
import AlertBox from '../../../../components/ShareComponents/AlertBox';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';

import '../company.scss';


const EditOfferFormView:  React.FC = () => {
  let params: any = useParams();
  const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
  const { promiseInProgress } = usePromiseTracker();
  const userContext = React.useContext(UserContext);
  const [rowsPerPage, setRowsPerPage]= useState(5);
  const [initPage, setInitPage]= useState<number>(0);
  const [applicantsData, setApplicantsData] = useState<IApplicantInfo[]>([]);
  const [companyName, setCompanyName]=useState<string>('');

  useEffect(()=>{
    window.scrollTo(0, 0);
    const getDataCompany = async()=>{
      if(params.id){
        if(userContext.userId){
          const nit = parseInt(userContext.userId, 10);
          const responseCompanyInfo = await trackPromise(getCompanyInfo(userToken, nit));
          if(responseCompanyInfo.status === 200){
            const companyInfoResult = await responseCompanyInfo.json();
            if(companyInfoResult && companyInfoResult.company){
              setCompanyName(companyInfoResult.company.name)
            }
          }
        }
        const applicantRes: Response = await listPostulations({offerId:params.id});
        if(applicantRes.status === 200){
            const result: Array<IGetPostulation> = (await applicantRes.json()).postulates;
            let target: Array<IApplicantInfo> = [];
            result.forEach(item=>{
                target.push(new IApplicantInfo(item))
            })
            setApplicantsData(target)
        }
      }
    };
    getDataCompany();
  },[params.id, userContext.userId])

  const onChanhePageSize = (e: any)=>{
    setRowsPerPage(e.target.value)
  }

  return (
    <>
      {
        userContext.action ?
        <Grid container className="modify-offer-view">
          <Grid container item xs={12} className="back-offers-list-section">
            <ButtonBackListOffers url="/empresa/dashboard/ofertas" text="Regresar al listado de ofertas"/>
          </Grid>
          <Grid container className="main-container">
            <Grid container item xs={12}>
              <Grid container item xs={12} md={4} lg={3}>
                <div className="title-section-line">
                  {
                    userContext.action === 'Add' &&
                    <>
                      <InsertDriveFileIcon className="group-icon"/>
                      <Typography variant="h2" className="bold-serif-primaryColor">
                        Nueva oferta
                      </Typography>
                    </>
                  }
                  {
                    userContext.action === 'Modify' &&
                    <>
                      <EditIcon className="group-icon"/>
                      <Typography variant="h2" className="bold-serif-primaryColor">
                        Modificar oferta
                      </Typography>
                    </>
                  }
                  {
                    userContext.action === 'Reuse' &&
                    <>
                      <EditIcon className="group-icon"/>
                      <Typography variant="h2" className="bold-serif-primaryColor">
                        Reutilizar oferta
                      </Typography>
                    </>
                  }
                </div>
              </Grid>
              <Grid container item xs={12} md={8} lg={9} className="line" />
            </Grid>

            <Grid container item xs={12}>
              <OfferForm companyName={companyName} offerId={0}/>
            </Grid>

            {
              userContext.action === 'Modify' && applicantsData.length > 0 &&
              <>
                <Grid container item xs={12}>
                  <Grid container item xs={12} md={3} lg={2}>
                    <div className="title-section-line">
                      <LocationOnIcon className="group-icon"/>
                      <Typography variant="h2" className="bold-serif-primaryColor" >
                        Aspirantes
                      </Typography>
                    </div>
                  </Grid>
                  <Grid container item xs={12} md={9} lg={10} className="line" />
                </Grid>
                <Grid container item xs={12}>
                  <AlertBox
                    title="Ver información del aspirante"
                    alert="info"
                    variantType="standard"
                  >
                    <span className="alert-simple-text">
                      Para ver la información del aspirante <SettingsIcon/> la oferta laboral debe estar en estado: <span className="alert-simple-title">“en selección.”</span>
                    </span>
                  </AlertBox>
                </Grid>
                <Grid container item xs={12}>
                  <ApplicantList action={userContext.action} onChanhePageSize={onChanhePageSize} rows={applicantsData} pageSize={rowsPerPage} initPage={initPage} setInitPage={setInitPage}/>
                </Grid>
              </>
            }
          </Grid>
        </Grid>
      :
        <Redirect to="/empresa/dashboard" />
      }
      <LoadingLockPage load={promiseInProgress}/>
    </>
  )
}

export default EditOfferFormView
