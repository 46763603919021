import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { Grid } from '@material-ui/core';
import UserContext from '../../../../utils/UserContext';
import { isLogin } from '../../../../services/AuthService';
import GraduateManagement from '../GraduateManagement';
import MassMessageManagementGraduate from '../MassMessageManagement/MassMessageManagementGraduate';
import GraduateStateBar from '../../../../components/ManagementState/GraguateStateBar';
import ReportsGraduate from '../ReportsManagement/ReportsGraduate';

import './GeneralGraduateManagement.scss';

const GeneralGraduateManagement: React.FC = () => {
  const userContext = React.useContext(UserContext);
  const [selectedSection, setSelectedSection] = useState<string>('');

  useEffect(()=>{
    window.scrollTo(0, 0);
    const urlPath = window.location.href;
    if(urlPath.includes('/egresados')){
      setSelectedSection('Gestion');
      userContext.setStateGraduateSection('Gestion');
    } else if(urlPath.includes('mensajeria-egresados')) {
      setSelectedSection('Mensajeria');
      userContext.setStateGraduateSection('Mensajeria');
    } else if(urlPath.includes('reportes-egresados')){
      setSelectedSection('Reportes');
      userContext.setStateGraduateSection('Reportes');
    } else {
      setSelectedSection('Gestion');
      userContext.setStateGraduateSection('Gestion');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[window.location.href])

  return (
    <>
      {isLogin() ?
        <>
          <Grid item xs={12} className="state-bar-section">
            <GraduateStateBar selected={selectedSection}/>
          </Grid>
          {selectedSection=== "Gestion" &&
            <Grid container item xs={12}>
              <GraduateManagement />
            </Grid>
          }
          {selectedSection=== "Mensajeria" &&
            <Grid container item xs={12}>
              <MassMessageManagementGraduate />
            </Grid>
          }
          {selectedSection=== "Reportes" &&
            <ReportsGraduate />
          }
        </>
      :
        <Redirect to ="/"/>
      }
    </>
  )
}

export default GeneralGraduateManagement;