import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { IRole } from '../../../interfaces/IRole';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';

import './AssignRoleModal.scss';


const styles = (theme: Theme) =>
createStyles({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#004B73',
  },
})

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="assign-dialog-header" {...other}>
      <Typography align="center" variant="h2" className="bold-serif-primaryColor" style={{marginRight:'16px'}}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface AssignRoleModalProps{
    open: any;
    title: string;
    handleClose: any;
    handleContinue?: any;
    backBtnText?: string;
    continueBtnText?: string;
    colorBtnContinue?: string;
    userSelected?: any;
    rolesArray: Array<IRole>;
    allCampus: Array<IListParametersInfo>;
    schools: Array<IGeneralParameter>;
    formValues: IRole;
    changeAssignForm: any;
    addRole: any;
    errorLabel: string;
    isValidAdd: boolean;
    assignArray: Array<IRole>;
    deleteRole: any;
}

const AssignRoleModal: React.FC<AssignRoleModalProps> = ({open, handleClose, handleContinue, title, backBtnText, continueBtnText, colorBtnContinue, userSelected, rolesArray, allCampus, schools, formValues, changeAssignForm, addRole, errorLabel, isValidAdd, assignArray, deleteRole })=>{


    return(
        <>
            <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className="assign-dialog-container"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle>
                <DialogContent className="assign-dialog-content">
                    <Grid container item xs={12}>
                        <Typography variant="h6">
                            <span className="text-simple-header">
                                Estas asociando roles para: <span className="text-content-header">{userSelected.name}</span> con el usuario: <span className="text-content-header">{userSelected.username}</span>
                            </span>
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title-company">Rol*</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-role"
                                select
                                name="id"
                                label={formValues.id === 0 ? "Seleccione": ""}
                                value={formValues.id === 0 ? "" : formValues.id}
                                onChange={changeAssignForm}
                                InputLabelProps={{shrink: false}}
                                margin="none"
                                variant="outlined"
                                disabled={(rolesArray.length>0 ? false : true)}
                            >
                                {
                                    rolesArray && rolesArray.length>0 ?
                                    rolesArray.map((option: IRole) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.id}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                            <Typography variant="h5" className="field-box-title-company">Sede</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-campus"
                                select
                                name="campus"
                                label={formValues.campus === 0 ? "Seleccione": ""}
                                value={formValues.campus === 0 ? "" : formValues.campus}
                                onChange={changeAssignForm}
                                InputLabelProps={{shrink: false}}
                                margin="none"
                                variant="outlined"
                                disabled={(formValues.id ===4 || formValues.id ===6) ? (allCampus.length>0 ? false : true) : true}
                            >
                                {
                                    allCampus && allCampus.length>0 ?
                                    allCampus.map((option: IListParametersInfo) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.parentName}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.campus}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                            <Typography variant="h5" className="field-box-title-company">Facultad</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-school"
                                select
                                name="school"
                                label={formValues.school === 0 ? "Seleccione": ""}
                                value={formValues.school === 0 ? "" : formValues.school}
                                onChange={changeAssignForm}
                                InputLabelProps={{shrink: false}}
                                margin="none"
                                variant="outlined"
                                disabled={formValues.id ===6 && schools.length>0 ? false : true}
                            >
                                {
                                    schools && schools.length>0 ?
                                    schools.map((option: IGeneralParameter) => (
                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.school}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} className="add-role-container">
                        <Button
                            className="add-button"
                            variant="contained"
                            size="small"
                            onClick={addRole}
                            disabled={!isValidAdd}
                        >
                            <Typography variant="h5" className="bold-serif">
                                Adicionar
                            </Typography>
                        </Button>
                    </Grid>
                    {
                        errorLabel.length>0 &&
                        <Grid item xs={12}>
                            <Typography variant="h5" className="bold-serif primaryError">
                                {errorLabel}
                            </Typography>
                        </Grid>
                    }
                    <hr className="line" />
                    <Grid container item xs={12}>
                        {
                            assignArray.length>0 &&
                            assignArray.map(assign=>(
                                <Grid key={assign.idAssign} container item xs={12} className="detail-container">
                                    <Grid container item xs={12} className="detail-description">
                                        <Typography variant="h5" className="regular-serif">
                                            {assign.name && <span>{assign.name}</span>}
                                            {assign.campusName && <span> - {assign.campusName}</span>}
                                            {assign.schoolName && <span> - {assign.schoolName}</span>}
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Button onClick={(e)=>deleteRole(e,assign.idAssign)} variant="contained" className="del-btn" endIcon={<DeleteIcon/>} >
                                            <Typography variant="h5" className="regular-serif">
                                                Eliminar
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                </DialogContent>
            <DialogActions className="assign-dialog-actions">
                <Button
                className="back-button"
                variant="contained"
                size="small"
                startIcon={<ArrowBackIosIcon />}
                onClick={handleClose}
                >
                    <Typography variant="h5" className="regular-serif">
                        {backBtnText}
                    </Typography>
                </Button>
                {
                handleContinue &&
                <Button
                    style={{backgroundColor: colorBtnContinue}}
                    className="continue-button"
                    variant="contained"
                    size="small"
                    onClick={handleContinue}
                >
                    <Typography variant="h5" className="regular-serif">
                        {continueBtnText}
                    </Typography>
                </Button>
                }
            </DialogActions>
            </Dialog>
        </>
    )
}

export default AssignRoleModal