import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { IGetOffer } from '../../../interfaces/IGetOffer';
import OfferCard from '../OfferCard';
import Typography from '@material-ui/core/Typography'


interface GraduateOffersListProps{
    offersList: Array<IGetOffer>;
    idOrder: number;
}

const GraduateOffersList: React.FC<GraduateOffersListProps> = ({offersList, idOrder})=>{
    const [url, setUrl] = useState<string | null>(null);

    useEffect(()=>{
        const isEvent = sessionStorage.getItem('eventAnonimous');
        if(isEvent){
            sessionStorage.removeItem('eventAnonimous');
        }
        const isSurvey = sessionStorage.getItem('surveyAnonimous');
        if(isSurvey){
            sessionStorage.removeItem('surveyAnonimous');
        }
    },[])

    const handleSeeOffer = (e: any, idOffer: number)=>{
        setUrl(`/egresado/detalle-oferta/${idOffer}`);
    }


    return(
        <>
            {
                url !== null ?
                <Redirect to={url} />
            :
                <>
                    {
                        offersList.map((offer:IGetOffer)=>(
                            <OfferCard
                                key={offer.id}
                                borderColor="#D7D7D7"
                                bgColor="#F9F9F9"
                                marginDef="10px 0"
                                companyName={offer.nameCompany}
                                nameCompanyVisible={offer.nameCompanyVisible}
                                cityId={offer.city}
                                departmentId={offer.department}
                                initialDate={new Date(offer.jobOfferStartDate)}
                                offerName={offer.jobTitle}
                                offerDescription={offer.description}
                                vacancies={offer.numberVacancy}
                                positionId={offer.typePosition}
                                contractId={offer.typeContract}
                                salaryVisible={offer.salaryVisible}
                                salary={offer.rangeSalary}
                                description_salary={offer.descriptionVariableSalary}
                                finalDate={new Date(offer.jobOfferEndDate)}
                            >
                                <Grid container item xs={12}>
                                    <hr className="divider-accordion"/>
                                </Grid>
                                <Grid className="double-actions">
                                    <div className="firts-actions">
                                        <Typography variant="h5" className="bold-serif-primaryColor">
                                            <span>Estado: </span><span>{offer.state}</span>
                                        </Typography>
                                    </div>
                                    <div className="second-actions">
                                        <Button className="see-offer-btn" onClick={(e)=>handleSeeOffer(e, offer.id)}>
                                            <Typography variant="h5" className="regular-serif">
                                                Ver oferta
                                            </Typography>
                                        </Button>
                                    </div>
                                </Grid>
                            </OfferCard>
                        ))
                    }
                </>
            }
        </>
    )
}

export default GraduateOffersList;