import React, { useState, useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import UserContext from '../../../../utils/UserContext';
import { usePromiseTracker } from 'react-promise-tracker';
import AddIcon from '@material-ui/icons/Add';
import { isLogin } from '../../../../services/AuthService';
import SearchTable from '../../../../components/ManagementState/SearchTable';
import Validations from '../../../../helpers/Validations';
import { containsAny, sortByKeyDesc } from '../../../../helpers/sharedFunctions';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import { IGetTrainingEvents } from '../../../../interfaces/IGetTrainingEvents';
import TrainingEventsList from '../../../../components/TrainingEvents/TrainingEventsList';
import { getTrainingEvents } from '../../../../services/TrainingEventServices';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import { permissionAdminCreateSurvey, permissionAdminSurveysView } from '../../../../utils/PermissionsVariables';

import './TrainingEventsManagement.scss';


const fieldsValidation = {
  searched: {
    error: "",
    validate: "",
    required: true
  }
};
const initialErrorsSearch = {
  searched: ''
}

const TrainingEventsManagement: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker();
  const userContext = React.useContext(UserContext);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [initPage, setInitPage] = useState<number>(0);
  const [searched, setSearched] = useState('');
  const [formErrorSearch, setFormErrorSearch] = useState(initialErrorsSearch);
  const [searchActive, setSearchActive] = useState(false);
  const [data, setData] = useState<Array<IGetTrainingEvents>>([]);
  const [dataFiltered, setDataFiltered] = useState<Array<IGetTrainingEvents>>([]);
  const [surveySelected, setSurveySelected] = useState<IGetTrainingEvents>(new IGetTrainingEvents());
  const [updateReady, setUpdateReady] = useState<string>('');
  const [typeSurveyFilter, setTypeSurveyFilter] = useState<string>("Todos");
  // const [chipData, setChipData] = useState<ChipData[]>([]);

  useEffect(() => {
    const getListSurveys = async () => {
      const getInfo: Response = await trackPromise(getTrainingEvents({}));
      if (getInfo.status === 200) {
        let resultList = (await getInfo.json()).trainingevents;
        resultList = sortByKeyDesc(resultList, 'updatedAt');
        setData(resultList);
        setDataFiltered(resultList);
      }
    }
    getListSurveys();
  }, [])

  const handleUpdate = (value: string, surveySelected: IGetTrainingEvents) => {
    setSurveySelected(surveySelected)
    setUpdateReady(value)
  }

  const onChanhePageSize = (e: any) => {
    setRowsPerPage(e.target.value)
  }

  const filterData = (keyWord: string) => {
    let resultTypes: Array<IGetTrainingEvents> = [];
    let resultKeyword: Array<IGetTrainingEvents> = [];
    if (typeSurveyFilter.length > 0) {
      if (typeSurveyFilter === "Todos") {
        resultTypes = data;
      } else {
        resultTypes = data.filter(item => item.name === typeSurveyFilter)
      }
    } else {
      resultTypes = data;
    }
    if (keyWord.length > 0) {
      resultKeyword = resultTypes.filter(item => item.name.includes(keyWord) || item.description.includes(keyWord));
    } else {
      resultKeyword = resultTypes;
    }
    setInitPage(0);
    setDataFiltered(resultKeyword);
  }

  const requestSearch = (name: string, searchVal: string) => {
    const error: string = searchVal !== '' ? Validations(name, searchVal, fieldsValidation) || "" : '';
    setFormErrorSearch(prev => ({
      ...prev,
      [name]: error
    }));
    setSearched(searchVal);
    if (searchVal !== '') {
      setSearchActive(true);
      filterData(searchVal)
    } else {
      setSearchActive(false);
      filterData('');
    }
  }

  const cancelSearch = () => {
    setSearched('')
    requestSearch('searched', '');
    setSearchActive(false)
  }


  return (
    <>
      {isLogin() ?
        <>
          {updateReady !== "" ?
            <Grid container >
              {updateReady === "Edit" &&
                <Redirect to={`/admin/dashboard/editar-capacitacion/${surveySelected.id}`} />
              }
            </Grid>
            :
            <>
              <Grid container className="main-container">
                <Grid container item xs={12} className="trainingevent-create-container">
                  <Button disabled={!containsAny(permissionAdminCreateSurvey, userContext.userPermissions)} variant="contained" className="create-button" endIcon={<AddIcon />} component={Link} to="/admin/dashboard/crear-capacitacion">
                    <Typography variant="h6" className="regular-serif">Adicionar nuevo evento</Typography>
                  </Button>
                </Grid>
              </Grid>

              <Grid container item xs={12} className="trainingevent-list-container">
                <Grid container className="main-container">
                  <Grid container className="list-table-container">
                    <Grid container item xs={12}>
                      <Typography variant="h2" className="title-trainingevent-table-management">Capacitaciones</Typography>
                    </Grid>
                    <Grid container item xs={12}>
                      <div className="header-table-container">
                        <div className="search-trainingevent-container ">
                          <SearchTable textPlaceHolder="Buscar por palabra clave" title="" searched={searched} requestSearch={requestSearch} formErrorSearch={formErrorSearch} searchActive={searchActive} cancelSearch={cancelSearch} />
                        </div>
                      </div>
                    </Grid>
                    <Grid container item xs={12} className="list-container">
                      <Grid container item xs={12} className="list-view">
                        <TrainingEventsList onChanhePageSize={onChanhePageSize} events={dataFiltered} pageSize={rowsPerPage} initPage={initPage} setInitPage={setInitPage} handleUpdate={handleUpdate} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <LoadingLockPage load={promiseInProgress} />
            </>
          }
        </>
        :
        <Redirect to="/" />
      }
    </>
  )
}

export default TrainingEventsManagement
