import React from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import GradeIcon from '@material-ui/icons/Grade';
import imgNoticias from '../../../assets/images/UN Somos Campus 2016-MEDIA-0432-2.jpg';
import imgEventos from '../../../assets/images/UN Somos Campus 2016-MEDIA-0571.jpg';
import './NewsAndEventsCard.scss';

interface NewEventsProps{
    borderColor?: string;
    bgColor?: string;
    marginDef?: string;
    icon?: any;
    secondIcon?: any;
    type?: string;
    highlight?: boolean;
    name?: string;
    shortDescription?: string;
    image?: string;
    publishDate?: string;
    place?: string;
    dateStart?: string;
    timeStart?: string;
    dateFinish?: string;
    timeFinish?: string;
    state?: string;
    children?:any;
}

const NewsAndEventsListCard: React.FC<NewEventsProps> = ({borderColor, bgColor, marginDef, type, highlight, name, shortDescription, image, publishDate, icon, secondIcon, place,dateStart,timeStart,dateFinish, timeFinish, state, children}) => {


    return (
        <Grid container item xs={12} direction="row" style={{backgroundColor: bgColor ? bgColor : 'white', margin: marginDef? marginDef : 0}}>
            <Grid container item xs={12} className="news-events-card-container" style={{ border: `1px solid ${borderColor ? borderColor : 'none'}` }}>
                <Grid container item xs={12} sm={3} md={2}>
                    <CardMedia
                    component="img"
                    alt={name}
                    image={
                        image === "" && type === "Noticia"? imgNoticias : image === "" && type === "Evento"? imgEventos :image
                    }
                    title={name}
                    />
                </Grid>
                <Grid item xs={12} sm={9} md={10} className="news-events-card-section">
                        <Grid item xs={12} className="news-events-card-header">
                            <Grid item className="news-event-header-title">
                                {highlight ?
                                    <>
                                        <GradeIcon className="grade-icon"/>
                                        {
                                        name &&
                                        <Grid className="news-events-title">
                                            {name}
                                        </Grid>
                                        }
                                    </>
                                    :
                                        name &&
                                        <Grid className="news-events-title">
                                            {name}
                                        </Grid>
                                }
                            </Grid>
                            {type === "Noticia" && publishDate &&
                                <Grid className="second-title-header">
                                    <Grid className="icon-news-events">{icon}</Grid>
                                    {moment(new Date(publishDate)).locale('es').format("dddd, DD MMMM YYYY")}
                                </Grid>
                            }
                            {type === "Evento" && place &&
                                <Grid className="second-title-header">
                                    <Grid className="icon-news-events">{icon}</Grid>
                                    {place}
                                </Grid>
                            }
                        </Grid>
                        <Grid container item xs={12}>
                            <hr className="divider-accordion"/>
                        </Grid>
                        <Grid container item xs={12} className="news-events-header-dates">
                            <Grid className="news-events-title-header">
                                {dateStart &&
                                    <>
                                        <Grid className="date-events-container">
                                            <Grid className="icon-news-events">{secondIcon}</Grid>
                                            {dateStart}
                                        </Grid>
                                        {timeStart &&
                                        <Grid className="time-events-container">
                                            {timeStart}
                                        </Grid>
                                        }
                                    </>
                                }
                            </Grid>
                            <Grid className="news-events-title-header">
                                {dateFinish &&
                                <>
                                    <Grid className="date-events-container">
                                        <Grid className="icon-news-events">{secondIcon}</Grid>
                                        {dateFinish}
                                    </Grid>
                                    {timeFinish &&
                                    <Grid className="time-events-container">
                                        {timeFinish}
                                    </Grid>
                                    }
                                </>
                                }
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} className="card-body-new-event-content">
                            {
                                shortDescription &&
                                <Typography variant="h5" className="summary-description">
                                    {shortDescription}
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={12} md={6} lg={12} className="btn-event-new-container">
                            {
                                children &&
                                <div className="actions">{children}</div>
                            }
                        </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NewsAndEventsListCard;
