import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Redirect } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import WorkIcon from '@material-ui/icons/Work';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { isLogin } from '../../../services/AuthService';
import GraduateOffersView from './GraduateOffersView';
import WelcomeBox from '../../../components/ShareComponents/WelcomeBox';
import LoadingLockPage from '../../../components/ShareComponents/LoadingLockPage';
import GraduateCurriculumView from './GraduateCurriculumView';
import GraduateProfileView from './GraduateProfileView';
import UserContext from '../../../utils/UserContext';
import jwt from 'jsonwebtoken';
import './graduate.scss';
import NotEnoughRequired from '../../../components/ShareComponents/NotEnoughRequired';


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
      className="tab-panel-container"
    >
      {value === index && (
        <Box p={3}>
          <Grid container spacing={1}>
            {children}
          </Grid>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: any)=> {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

interface ITabsProps{
  location:any;
}

const DashboardGraduate: React.FC<ITabsProps> = ({ location }) => {
  const routes = ["/egresado/dashboard/ofertas", "/egresado/dashboard/curriculum", "/egresado/dashboard/perfil"];
  let params: any = useParams();
  const userContext = React.useContext(UserContext);
  const { promiseInProgress } = usePromiseTracker();
  const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
  const [value, setValue] = React.useState(0);
  const [offerPreSelected, setOfferPreSelected] = useState<any>(null);
  const [eventPreSelected, setEventPreSelected] = useState<any>(null);
  const [surveyPreSelected, setSurveyPreSelected] = useState<any>(null);
  const [nameProfile, setNameProfile] = useState<any>('');
  const urlPath = location.pathname;


  useEffect(() => {
    if (userContext.roleSelected > 0) {
      userContext.setLogged(true);
    }
    const offerPre = sessionStorage.getItem('offerAnonimous');
    if (offerPre && offerPre?.length > 0) {
      setOfferPreSelected(offerPre)
    }
    const eventPre = sessionStorage.getItem('eventAnonimous');
    if (eventPre && eventPre?.length > 0) {
      setEventPreSelected(eventPre)
    }
    const surveyPre = sessionStorage.getItem('surveyAnonimous');
    if (surveyPre && surveyPre?.length > 0) {
      setSurveyPreSelected(surveyPre)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (urlPath === "/egresado/dashboard") {
      setValue(0)
    } else if (urlPath === routes[0]) {
      setValue(0)
    } else if (urlPath === routes[1]) {
      setValue(1)
    } else if (urlPath === routes[2]) {
      setValue(2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlPath])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (sessionStorage.getItem('Bearer')) {
      const graduateProfile: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer') + "" : "";
      if (jwt.decode(graduateProfile)) {
        const graduateDecoded: any = jwt.decode(graduateProfile);
        const getUserData = graduateDecoded.message.name;
        setNameProfile(getUserData);
      }
    }
  }, [])

  useEffect(()=>{
    const getuserpercentage=async() =>{
      if(userContext.userId && params.id === undefined){
        await trackPromise(userContext.handleSetUserPercentage(userContext.userId));
      }else if(params.id){
          await trackPromise(userContext.handleSetUserPercentage(params.id));
      }
    }
    getuserpercentage()
  },[])
  return(
    <>
      {isLogin() ?
        <>
          {
            offerPreSelected ?
            <Redirect to={`/egresado/detalle-oferta/${offerPreSelected}`} />
          :
            <>
              {eventPreSelected ?
                <Redirect to={`/egresado/eventos/${eventPreSelected}`} />
              :
                <>
                  {surveyPreSelected ?
                    <Redirect to={`/encuestas/${surveyPreSelected}`} />
                  :
                    <div className="welcome-graduate-section">
                      <Grid container className="welcome-margin">
                        <WelcomeBox nameProfile={nameProfile} profile={1}/>
                      </Grid>
                      <Grid className="admin-tabs">
                        <AppBar position="static" className="appbar-management">
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="scrollable force tabs example"
                            className="tabs-management tabs-management-graduate"
                          >
                            <Tab icon={<WorkIcon />} component={Link} to={routes[0]} label="Ofertas laborales" {...a11yProps(0)} />
                            <Tab icon={<DescriptionIcon />} component={Link} to={routes[1]} label="Mi curriculum" {...a11yProps(1)} />
                          </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                          {
                            userContext.userPercentage !== undefined && userContext.userPercentage?.total >= 100 ?
                            <GraduateOffersView nameProfile="{nameProfile}"/>
                            :
                            <NotEnoughRequired />
                          } 
                          
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <GraduateCurriculumView/>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <GraduateProfileView/>
                        </TabPanel>
                        <LoadingLockPage load={promiseInProgress}/>
                      </Grid>
                    </div>
                  }
                </>
              }
            </>
          }
        </>
      :
        <Redirect to="/"/>
      }
    </>
  )
}

export default DashboardGraduate
