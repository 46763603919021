export class ICompanyHeadquarters {
    id?: number = 0;
    nit?: number | null = null;
    name: string = "";
    description: string = "";
    country: number = 0;
    department: number = 0;
    city: number = 0;
    address: string = "";
    phone: string = "";
    cellphone: string  = "";
    companyId?: number = 0;
    createdAt?: string = "";
    updatedAt?: string  = "";
}