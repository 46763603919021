import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { IGetSurveys } from '../../../interfaces/IGetSurveys';
import UserContext from '../../../utils/UserContext';
import { containsAny } from '../../../helpers/sharedFunctions';

import './PreviewSurvey.scss';


interface SeeSurveysProps{
  surveySelected: IGetSurveys;
}

const PreviewSurvey: React.FC<SeeSurveysProps>  = ({surveySelected}) => {
  const userContext = React.useContext(UserContext);
  const [redirectLogin, setRedirectLogin] = useState<boolean>(false);

  const handleLogin = () => {
    sessionStorage.setItem('surveyAnonimous', surveySelected.id.toString());
    setRedirectLogin(true);
  }

  return (
    <>
      {redirectLogin ?
        <Redirect to="/login"/>
      :
        <Grid container className="main-container">
          <Grid container item className="title-container">
            <Grid container item xs={12} md={4} lg={4}>
              <div className="title-section-line">
                <AssignmentIcon className="group-icon"/>
                <Typography variant="h2" className="prev-survey-title" >
                  {surveySelected.name}
                </Typography>
              </div>
            </Grid>
            <Grid container item xs={12} md={8} lg={8} className="lines" />
          </Grid>
          {containsAny([1,5],[userContext.roleSelected]) ?
            <>
              {surveySelected.state === "Finalizada"?
                <Grid container item >
                  <Typography variant="h1" className="bold-serif finish-title">La encuesta ha sido finalizada</Typography>
                </Grid>
              :
                <>
                  <Grid container item xs={12} className="description-container">
                    <Typography variant="h5" className="description">
                      {surveySelected.description}
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} className="iframe-form">
                    <iframe title="ifram-encuesta" className="responsive-iframe" src={surveySelected.url}></iframe>
                  </Grid>
                </>
              }
            </>
          :
            <Grid container item xs={12} className="not-logged-survey">
              <Grid container item xs={12} justify="center">
                <Typography variant="h2" className="bold-serif-primaryColor">
                  Para responder esta encuesta por favor incie sesion
                </Typography>
              </Grid>
              <Grid container item xs={12} justify="center">
                <Button onClick={handleLogin} variant="contained" className="loggin-survey-btn">
                  <Typography variant="h5" className="regular-serif">Iniciar sesión</Typography>
                </Button>
              </Grid>
            </Grid>
          }
          <Grid container className={containsAny([5],[userContext.roleSelected]) ? 'btn-detail-container' : 'no-container'}>
            <Button variant="contained" className="continue-button" component ={Link} to="/admin/dashboard/encuestas">
              <Typography variant="h6" className="regular-serif">Regresar</Typography>
            </Button>
          </Grid>
        </Grid>
      }
    </>
  )
}

export default PreviewSurvey
