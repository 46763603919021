import React from 'react';
import Grid from "@material-ui/core/Grid";
import { Redirect } from 'react-router';
import ReportFormOffers from '../../../../components/Reports/ReportFormOffers';
import { isLogin } from '../../../../services/AuthService';
import { containsAny } from '../../../../helpers/sharedFunctions';
import UserContext from '../../../../utils/UserContext';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import { permissionAdminOffersReportsView } from '../../../../utils/PermissionsVariables';

const OfferReportManagement: React.FC = () => {
  const userContext = React.useContext(UserContext);

  return (
    <>
      {isLogin() ?
        <>
        {containsAny(permissionAdminOffersReportsView, userContext.userPermissions) ?
          <Grid container>
            <ReportFormOffers />
          </Grid>
        :
          <NotAuthorized/>
        }
        </>
      :
        <Redirect to ="/"/>
      }
    </>
  )
}

export default OfferReportManagement
