import moment from "moment";

export class IRegisterGraduateData{
    names: string;
    surname: string;
    secondsurname: string;
    gender: number;
    ethnicgroup: number;
    phone: number | null;
    cellphone: number | null;
    address: string;
    documentType:number;
    doc: string;
    email: string;
    campus: number;
    school: number;
    curriculum: number;
    academicLevel: number;
    date: string | null;
    diplomaNumber: string;
    currentJobSituation: number;
    typeOfCompany: number;
    laboralSector: number;
    state: string;
    termsOfUse: boolean;
    photo: string;
    disability: number;
    inJobBank:  string | null;
    educationState: number;
    constructor(def:any){
        this.names = def.names || null;
        this.surname = def.surname || null;
        this.secondsurname = def.secondsurname || null;
        this.gender = def.gender || null;
        this.ethnicgroup = def.ethnicgroup || null;
        this.phone = def.phone ? parseInt(def.phone, 10) : 0;
        this.cellphone = def.cellphone ? parseInt(def.cellphone, 10) : 0;
        this.address = def.address || null;
        this.documentType = def.documentType || null;
        this.doc = def.doc || null;
        this.email = def.email || null;
        this.campus = def.campus || null;
        this.school = def.school || null;
        this.curriculum = def.curriculum || null;
        this.academicLevel = def.academicLevel || null;
        this.date = def.date ? moment(def.date).format() : null;
        this.diplomaNumber = def.diplomaNumber || "";
        this.currentJobSituation = def.currentJobSituation || null;
        this.typeOfCompany = def.typeOfCompany || null;
        this.laboralSector = def.laboralSector || null;
        this.state = def.state || null;
        this.termsOfUse = def.termsOfUse || false;
        this.inJobBank = def.inJobBank ? moment(def.inJobBank).format() : null;
        this.educationState = def.educationState || 3;
        this.photo = def.photo || "";
        this.disability = def.disability || null;
    }
}