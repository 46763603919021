/* eslint-disable no-loop-func */
import React, { useState, useEffect } from 'react';
import { trackPromise,usePromiseTracker } from 'react-promise-tracker';
import esLocale from "date-fns/locale/es";
import moment from 'moment';
import 'moment/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { DateRange, DateRangePicker, LocalizationProvider } from '@material-ui/pickers';
import Validations from '../../../helpers/Validations';
import { ChipData } from '../../../interfaces/ChipData';
import MoreFiltersGraduate from '../../../components/ManagementState/MoreFiltersGraduate';
import ShowFilters from '../../../components/ManagementState/ShowFilters';
import { StateByRole } from '../../../interfaces/StateByRole';
import { IGeneralSIA } from '../../../interfaces/IGeneralSIA';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import LoadingLockPage from '../../../components/ShareComponents/LoadingLockPage';
import { checkValuesSelected, containsAny, resetStatusCheckArray, sortByKey } from '../../../helpers/sharedFunctions';
import { IStaticReport } from '../../../interfaces/IStaticReport';
import AditionalData from '../AditionalData';
import AditionalOptions from '../AditionalOptions';
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';
import { GENDER } from '../../../services/SIAService';
import { ITypeReport } from '../../../interfaces/ITypeReport';
import DownloadModal from '../DownloadModal';
import ErrorModal from '../ErrorModal';
import { IGraduateFilterForm } from '../../../interfaces/IGraduateFilterForm';
import { transformDataGraduate } from '../../../pages/dashboard/admin/GraduateManagement';
import { IGraduateFilterObjDinamic } from '../../../interfaces/IGraduateFilterObjDinamic';
import NotAuthorized from '../../ShareComponents/NotAuthorized/NotAuthorized';
import { FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, TextField } from '@material-ui/core';
import { getACADEMIC_LEVEL, getASSOCIATION, getCAMPUS_FACULTIES, getPROGRAMS, getTYPE_OF_ASSOCIATION } from '../../../services/ParameterService';
import { dinamicReportGraduates, reportCurrentWorkLocation, reportGraduatesAssociationsByProgram, reportGraduatesByProgramAndGender, reportLinkedByJobOffer, reportSalaryByProgram, reportAgeRangeAndGender, reportEmploymentModality, reportPerformanceArea, reportCompanyType, reportGraduateAcceptedAndRejected } from '../../../services/ReportsService';

import './ReportFormGraduate.scss';
import { permissionAdminDinamicReportsGraduates, permissionAdminStaticReportsGraduates } from '../../../utils/PermissionsVariables';
import UserContext from '../../../utils/UserContext';
import { listCampusCurriculum } from '../../../services/AdminServices';
import { getGraduateReports } from '../../../services/GraduateService';
import { IGetDocuments } from '../../../interfaces/IGetDocuments';

const filterGraduateValidation = {
    names: {
      error: "",
      validate: "text",
      maxLength: 40,
      required: false
    },
    surname: {
        error: "",
        validate: "text",
        maxLength: 40,
        required: false
    },
    gender: {
        validate: "select",
        required: false
    },
    doc: {
        error: "",
        validate: "textNumber",
        minLength: 6,
        maxLength: 11,
        required: false
    },
    state:{
        validate: "select",
        required: false
    },
    campus: {
        validate: "select",
        required: false
    },
    school: {
        validate: "select",
        required: false
    },
    curriculum: {
        validate: "select",
        required: false
    },
    academicLevel: {
        validate: "select",
        required: false
    },
    typeOfAssociation:{
        validate: "select",
        required: false
    },
    association:{
        validate: "select",
        required: false
    },
    extraEmail:{
        error: "",
        validate: "email",
        required: true
    },
    subject:{
        error: "",
        validate: "textNumber",
        maxLength: 100,
        required: true
    },
    html:{
        error: "",
        validate: "textNumber",
        maxLength: 400,
        required: true
    }
}

const initialGraduateFilterErrors ={
    names: "",
    surname: "",
    gender: "",
    doc: "",
    state: "",
    updatedAtStart: "",
    updatedAtEnd: "",
    inJobBankStart: "",
    inJobBankEnd: "",
    campus: "",
    school: "",
    curriculum: "",
    academicLevel: "",
    graduationStart: "",
    graduationEnd: "",
    typeOfAssociation: "",
    asociation: "",
    extraEmail:"",
    subject: "",
    html: ""
}

const initialStaticReportValues = {
    dateInit: "",
    dateEnd: ""
}

const ReportOption: Array<StateByRole> = [
    { id: 1, label: 'Reporte dinámico', value: 'Dinamic', description: '', permission: [] },
    { id: 2, label: 'Reporte predeterminado', value: 'Predetermined', description: '', permission: [] },
]

const stateOptionsGraduate: Array<StateByRole> = [
    {id:1, label: 'Inscrito', value:'Inscrito', description: 'Cambiar estado a Inscrito', permission:[] },
    {id:2, label: 'Verificado', value:'Verificado', description: 'Cambiar estado a Verificado', permission:  [] },
    {id:3, label: 'Activo', value:'Activo', description: 'Cambiar estado a Activo', permission: [] },
    {id:4, label: 'Inactivo', value:'Inactivo', description: 'Cambiar estado a Inactivo', permission: [] },
    {id:5, label: 'Rechazado', value:'Rechazado', description: 'Cambiar estado a Rechazado', permission: [] }
];

const AditionalComponent: Array<StateByRole> = [
    { id: 1, label: 'Ninguno', value: 'None', description: '', permission: [] },
    { id: 2, label: 'Experiencia laboral', value: 'workExperience', description: '', permission: [] },
    { id: 3, label: 'Estudios', value: 'studies', description: '', permission: [] },
    { id: 4, label: 'Idiomas', value: 'languages', description: '', permission: []  },
    { id: 5, label: 'Información adicional', value: 'AditionalInfo', description: '', permission: [] },
];

const personalDataOption: Array<StateByRole> = [
    { id: 1, label: 'Nombre', value: 'names', description: '', permission: [] },
    { id: 2, label: 'Primer apellido', value: 'surname', description: '', permission: [] },
    { id: 3, label: 'Segundo apellido', value: 'secondsurname', description: '', permission: [] },
    { id: 4, label: 'Tipo de documento', value: 'documentType', description: '', permission: [] },
    { id: 5, label: 'Número de identificación', value: 'doc', description: '', permission: [] },
    { id: 6, label: 'Género', value: 'gender', description: '', permission: [] },
    { id: 7, label: 'Grupo étnico', value: 'ethnicgroup', description: '', permission: [] },
    // { id: 8, label: 'Lugar de nacimiento', value: 'birthplace', description: '', permission: [] },
    { id: 8, label: 'País de nacimiento', value: 'birthCountry', description: '', permission: [] },
    { id: 9, label: 'Departamento de nacimiento', value: 'birthDepartment', description: '', permission: [] },
    { id: 10, label: 'Municipio de nacimiento', value: 'birthCity', description: '', permission: [] },
    { id: 11, label: 'Fecha de nacimiento', value: 'birthdate', description: '', permission: [] },
    { id: 12, label: 'Estrato', value: 'stratum', description: '', permission: [] },
    { id: 13, label: 'Estado civil', value: 'maritalStatus', description: '', permission: [] },
    { id: 14, label: 'País de residencia', value: 'residenceCountry', description: '', permission: [] },
    { id: 15, label: 'Departamento de residencia', value: 'residenceDepartment', description: '', permission: [] },
    { id: 16, label: 'Municipio de residencia', value: 'residenceCity', description: '', permission: [] },
    { id: 17, label: 'Dirección', value: 'address', description: '', permission: [] },
    { id: 18, label: 'Correo electrónico', value: 'email', description: '', permission: [] },
    { id: 19, label: 'Correo adicional', value: 'additionalEmail', description: '', permission: [] },
    { id: 20, label: 'Nombre de red social', value: 'socialnetwork', description: '', permission: [] },
    { id: 21, label: 'URL red social', value: 'urlSocialnetwork', description: '', permission: [] },
    { id: 22, label: 'Número telefónico', value: 'phone', description: '', permission: [] },
    { id: 23, label: 'Número telefónico adicional', value: 'additionalPhone', description: '', permission: [] },
    { id: 24, label: 'Celular', value: 'cellphone', description: '', permission: [] },
    { id: 25, label: 'Nombre del programa especial de admisión', value: 'nameSpecialAdmissionProgram', description: '', permission: [] },
    { id: 26, label: 'Tipo del programa PAES', value: 'paesProgram', description: '', permission: [] },
    { id: 27, label: 'Sede etapa inicial PEAMA', value: 'initialCampusPeama', description: '', permission: [] },
    { id: 28, label: 'Sede etapa final PEAMA', value: 'finalCampusPeama', description: '', permission: [] },
    { id: 29, label: 'Hijos', value: 'sonsAndDaughter', description: '', permission: [] },
    { id: 30, label: 'Contacto cercano', value: 'contacts', description: '', permission: [] },
    { id: 31, label: 'Última fecha de actualización', value: 'updatedAt', description: '', permission: [] },
    { id: 32, label: 'Discapacidad', value: 'impairment', description: '', permission: [] },
    { id: 33, label: 'Estado en el sistema', value: 'state', description: '', permission: [] },
    { id: 34, label: 'Fecha de activacion en la bolsa de empleos', value: 'inJobBank', description: '', permission: [] },
];

const workExperienceOption: Array<StateByRole> = [
    { id: 1, label: 'Estado laboral actual', value: 'currentlyWorking', description: '', permission: [] },
    { id: 2, label: 'Perfil', value: 'laboralProfile', description: '', permission: [] },
    { id: 3, label: 'Competencias', value: 'competences', description: '', permission: [] },
    { id: 4, label: 'Cargo', value: 'position', description: '', permission: [] },
    { id: 5, label: 'Tipo de vinculación', value: 'employmentRelationship', description: '', permission: [] },
    { id: 6, label: 'Área de desempeño', value: 'performanceArea', description: '', permission: [] },
    { id: 7, label: 'Responsabilidades y logros', value: 'achivements', description: '', permission: [] },
    { id: 8, label: 'Afinidad labor -profesión', value: 'employmentAndProfessionRelationship', description: '', permission: [] },
    { id: 9, label: 'Rango salarial', value: 'salaryRange', description: '', permission: [] },
    { id: 10, label: 'Fecha inicio', value: 'startDate', description: '', permission: [] },
    { id: 11, label: 'Fecha fin', value: 'endDate', description: '', permission: [] },
    { id: 12, label: 'Trabajo actual', value: 'actualWork', description: '', permission: [] },
    { id: 13, label: 'Nombre de la empresa', value: 'companyName', description: '', permission: [] },
    { id: 14, label: 'Teléfono empresa', value: 'phoneNumber', description: '', permission: [] },
    { id: 15, label: 'País', value: 'country', description: '', permission: [] },
    { id: 16, label: 'Departamento', value: 'departament', description: '', permission: [] },
    { id: 17, label: 'Municipio', value: 'city', description: '', permission: [] },
    { id: 18, label: 'Jefe inmediato', value: 'bossName', description: '', permission: [] },
    { id: 19, label: 'Cargo jefe inmediato', value: 'bossPositon', description: '', permission: [] },
    { id: 20, label: 'Teléfono jefe inmediato', value: 'bossPhone', description: '', permission: [] }
];

const studiesOption: Array<StateByRole> = [
    { id: 1, label: 'Título Obtenido', value: 'title', description: '', permission: [] },
    { id: 2, label: 'Sede de la UNAL', value: 'campus', description: '', permission: [] },
    { id: 3, label: 'Facultad de la UNAL', value: 'school', description: '', permission: [] },
    { id: 4, label: 'Programa Académico', value: 'curriculum', description: '', permission: [] },
    { id: 5, label: 'Nivel Educativo', value: 'academicLevel', description: '', permission: [] },
    { id: 6, label: 'Número tarjeta profesional', value: 'professionalCardNumber', description: '', permission: [] },
    { id: 7, label: 'Número de diploma o acta de grado', value: 'diplomaNumber', description: '', permission: [] },
    { id: 8, label: 'Fecha Inicio', value: 'dateInit', description: '', permission: [] },
    { id: 9, label: 'Fecha Fin', value: 'date', description: '', permission: [] },
    { id: 10, label: 'Título del Proyecto de Grado', value: 'degreeWorkTitle', description: '', permission: [] },
    { id: 11, label: 'Becas', value: 'scholarship', description: '', permission: [] },
];

const aditionalInfoOption: Array<StateByRole> = [
    { id: 1, label: 'Tipo de asociación', value: 'typeOfAssociation', description: '', permission: [] },
    { id: 2, label: 'Asociación', value: 'association', description: '', permission: [] },
    { id: 3, label: 'Areas de estudio de interés', value: 'studiesOfInterest', description: '', permission: [] },
    { id: 4, label: 'Proyectos de investigación', value: 'researchProjects', description: '', permission: [] },
    { id: 5, label: 'Reconocimientos', value: 'recognitions', description: '', permission: [] },
    { id: 6, label: 'Emprendimiento', value: 'ventures', description: '', permission: [] }
];

const languagueInfoOption: Array<StateByRole> = [
    { id: 1, label: 'Idioma', value: 'language', description: '', permission: [] },
    { id: 2, label: 'Nivel', value: 'level', description: '', permission: [] },
    { id: 3, label: '¿Nativo?', value: 'native', description: '', permission: [] },
]

const reportOption = [
    { id: 1, name: 'Egresados que pertenecen a asociaciones por facultad y programa', value: 'reportGraduatesAssociationsByProgram' },
    { id: 2, name: 'Egresados por programa y género', value: 'reportGraduatesByProgramAndGender' },
    { id: 3, name: 'Egresados vinculados por ofertas laborales del SIE', value: 'reportLinkedByJobOffer' },
    { id: 4, name: 'Egresados según el salario devengado agrupados por programa', value: 'reportSalaryByProgram' },
    { id: 5, name: 'Ubicación laboral por ciudad actual de los egresados', value: 'reportCurrentWorkLocation' },
    { id: 6, name: 'Egresados por rango de edad y género, agrupados por programa académico', value: 'reportAgeRangeAndGender' },
    { id: 7, name: 'Egresados según la modalidad de empleo agrupados por programa', value: 'reportEmploymentModality' },
    { id: 8, name: 'Egresados según el area donde se desempeñan agrupados por programa', value: 'reportPerformanceArea' },
    { id: 9, name: 'Egresados según el tipo de empresa donde laboran agrupados por programa', value: 'reportCompanyType' },
    { id: 10, name: 'Egresados contratados y rechazados por las empresas ', value: 'reportGraduateAcceptedAndRejected' },
];


const ReportFormGraduate: React.FC = () => {
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [reportLoad, setReportLoad] = useState<boolean>(false);
    const [typeReport, setTypeReport] = useState<ITypeReport>(new ITypeReport());
    const [formValuesStaticReports, setFormValuesStaticReports] = useState<IStaticReport>(new IStaticReport());
    const [formErrorsStaticReport, setFormErrorsStaticReport] = useState(initialStaticReportValues);
    const [staticReportDates, setStaticReportDates] = useState<DateRange<Date>>([null, null]);
    const [filterValues, setFilterValues] = useState<IGraduateFilterForm>(new IGraduateFilterForm());
    const [formErrors, setFormErrors] = useState(initialGraduateFilterErrors);
    const [chipData, setChipData] = useState<ChipData[]>([]);
    const [gendersType, setGendersType] = useState<Array<IGeneralSIA>>([]);
    const [allCampus, setAllCampus] = useState<Array<IListParametersInfo>>([]);
    const [allPrograms, setAllPrograms] = useState<Array<IGeneralParameter>>([]);
    const [schools, setSchools] = useState<Array<IGeneralParameter>>([]);
    const [programs, setPrograms] = useState<Array<IGeneralParameter>>([]);
    const [allAcademic, setAllAcademic] = useState<Array<IGeneralParameter>>([]);
    const [associationTypeArray, setAssociationTypeArray] = useState<Array<IGeneralParameter>>([]);
    const [associationArray, setAssociationArray] = useState<Array<IGeneralParameter>>([]);
    const [reportSelected, setReportSelected] = useState<string>("Dinamic");
    const [recordsfound, setRecordsFound] = useState<number>(0);
    const [aditionalComponent, setAditionalComponent] = useState<string>("None");
    const [urlFileReportLog, setUrlFileReportLog] = useState<string>('');
    const [urlReport, setUrlReport] = useState<string>('');
    const [openModalDownload, setOpenModalDownload] = useState<boolean>(false);
    const [openModalError, setOpenModalError] = useState<boolean>(false);
    const [validIs, setValidIs] = useState<boolean>(false);
    const [campusSelected, setCampusSelected] = useState<number>(0);
    const [schoolSelected, setSchoolSelected] = useState<number>(0);
    const [aux, setAux] = useState<boolean>(false);
    const [aux2, setAux2] = useState<boolean>(false);
    const [errorMessage, setErrorMessage]= useState('');

    const [mainFieldsCheck, setMainFieldsCheck] = useState<any>({
        names: false,
        surname: false,
        secondsurname: false,
        documentType: false,
        doc: false,
        gender: false,
        ethnicgroup: false,
        // birthplace: false,
        birthCountry: false,
        birthDepartment: false,
        birthCity: false,
        birthdate: false,
        stratum: false,
        maritalStatus: false,
        residenceCountry: false,
        residenceDepartment: false,
        residenceCity: false,
        address: false,
        email: false,
        additionalEmail: false,
        socialnetwork: false,
        urlSocialnetwork: false,
        phone: false,
        additionalPhone: false,
        cellphone: false,
        nameSpecialAdmissionProgram: false,
        paesProgram: false,
        initialCampusPeama: false,
        finalCampusPeama: false,
        sonsAndDaughter: false,
        contacts: false,
        updatedAt: false,
        impairment: false,
        state: false,
        inJobBank: false
    });

    const [workExperienceFields, setWorkExperienceFields] = useState<any>({
        currentlyWorking: false,
        laboralProfile: false,
        competences: false,
        position: false,
        employmentRelationship: false,
        performanceArea: false,
        achivements: false,
        employmentAndProfessionRelationship: false,
        salaryRange: false,
        startDate: false,
        endDate: false,
        actualWork: false,
        companyName: false,
        phoneNumber: false,
        country: false,
        departament: false,
        city: false,
        bossName: false,
        bossPositon: false,
        bossPhone: false,
    });

    const [studiesFields, setStudiesFields] = useState<any>({
        title: false,
        campus: false,
        school: false,
        curriculum: false,
        academicLevel: false,
        professionalCardNumber: false,
        diplomaNumber: false,
        dateInit: false,
        date: false,
        degreeWorkTitle: false,
        scholarship: false,
    });

    const [languageFields, setLanguageFields] = useState<any>({
        language: false,
        level: false,
        native: false
    });

    const [additionalInfoFields, setAdditionalInfoFields] = useState<any>({
        typeOfAssociation: false,
        association: false,
        studiesOfInterest: false,
        researchProjects: false,
        recognitions: false,
        ventures: false,
    });

    const isValid =
        !formErrors.names &&
        !formErrors.surname &&
        !formErrors.doc &&
        !formErrors.updatedAtStart &&
        !formErrors.updatedAtEnd &&
        !formErrors.graduationStart &&
        !formErrors.graduationEnd &&
        !formErrors.inJobBankStart &&
        !formErrors.inJobBankEnd

    const isValidStaticFilters =
    (
        reportSelected === "Predetermined" &&
        typeReport.type !== "" &&
        moment(staticReportDates[0]).isValid() &&
        moment(staticReportDates[1]).isValid()
    ) ||
    (
        reportSelected === "Dinamic" &&
        typeReport.type !== ""
    )

    useEffect(() => {
        window.scrollTo(0, 0);
        const getInitialData = async () => {
            // data for campus field
            let dataAllCampus: Array<IListParametersInfo> = (await getCAMPUS_FACULTIES()).list;
            dataAllCampus = sortByKey(dataAllCampus, 'parentName');
            setAllCampus(dataAllCampus ? dataAllCampus : []);
            // data for programs
            let dataAllPrograms = await getPROGRAMS();
            dataAllPrograms = sortByKey(dataAllPrograms, 'name')
            setAllPrograms(dataAllPrograms ? dataAllPrograms : []);
            // data for all academic levels
            let responseAcademic: Array<IGeneralParameter> = await getACADEMIC_LEVEL();
            responseAcademic = sortByKey(responseAcademic, 'name')
            setAllAcademic(responseAcademic);
            //data for gender field
            const dataGender: Array<IGeneralSIA> = GENDER;
            setGendersType(dataGender || []);
            // data for association type
            let resAssociationType: Array<IGeneralParameter> = await getTYPE_OF_ASSOCIATION();
            setAssociationTypeArray(resAssociationType);
            // data for association
            let resAssociation: Array<IGeneralParameter> = await getASSOCIATION();
            resAssociation = sortByKey(resAssociation, 'name');
            setAssociationArray(resAssociation);
            setInitLoad(false);
            
            const userToConsult = {
                userId: userContext.userId,
                roleId: userContext.roleSelected
            }
            if(userContext.roleSelected === 4 || userContext.roleSelected === 6){
                const responseConsult = await (listCampusCurriculum(
                    {...userToConsult}
                    ))
                if(responseConsult.status === 200){
                    const result = await responseConsult.json();
                    setCampusSelected(result.campus)
                    setSchoolSelected(result.school)
                    if(userContext.roleSelected === 4){
                        setAux(true)
                    }
                    if(userContext.roleSelected === 6){
                        setAux(true)
                        setAux2(true)
                    }
                    
                } else {
                    setOpenModalError(true);
                    setErrorMessage("Hubo un error, intente de nuevo mas tarde, o contacte al administrador general")
                }
            }
        }
        getInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setSchools([]);
        setPrograms([]);
        setFilterValues((prev:any) => ({
            ...prev,
            school: [],
            curriculum: []
        }))
        if (filterValues.campus > 0) {
            const campusFound = allCampus.find(campus => campus.id === filterValues.campus);
            if (campusFound) {
                const campus: IListParametersInfo = campusFound;
                let schools = campus.childs;
                schools = sortByKey(schools, 'name');
                setSchools(schools);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues.campus])

    useEffect(() => {
        const getPrograms = async () => {
            if (filterValues.school.length > 0) {
                let programsArr: IGeneralParameter[] = []
                filterValues.school.forEach(obj => {
                    const schoolPrograms: IGeneralParameter[] = allPrograms.filter(item => item.parentId === obj.parameter_code);
                    if (schoolPrograms.length > 0) {
                        programsArr.push(...schoolPrograms);
                    }
                })
                if (programsArr.length > 0) {
                    programsArr = sortByKey(programsArr, 'name')
                    setPrograms(programsArr)
                    let newCurriculum: Array<IGeneralParameter> = [];
                    filterValues.curriculum.forEach(option => {
                        const found = programsArr.find(item => item.name === option.name);
                        if (found) {
                            newCurriculum.push(found)
                        }
                    })
                    setFilterValues((prev:any) => ({
                        ...prev,
                        curriculum: newCurriculum
                    }));
                } else {
                    setPrograms(programsArr)
                    setFilterValues((prev:any) => ({
                        ...prev,
                        curriculum: []
                    }));
                }
            } else {
                setPrograms([])
                setFilterValues((prev:any) => ({
                    ...prev,
                    curriculum: []
                }));
            }
        }
        getPrograms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues.school])

    useEffect(() => {
        if (isValid) {
            setChips();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, isValid])

    const setChips =()=>{
        let tempArray: Array<ChipData> = []
        let index = 0;
        let subIndex = 0;
        const fieldNames=[
            {key: 'names', description: 'Nombres'},
            {key: 'surname', description: 'Apellidos'},
            {key: 'gender', description: 'Género'},
            {key: 'doc', description: 'No de documento'},
            {key: 'state', description: 'Estado'},
            {key: 'campus', description: 'Sede'},
            {key: 'school', description: 'Facultad'},
            {key: 'curriculum', description: 'Programa'},
            {key: 'academicLevel', description: 'Nivel educativo'},
            {key: 'updateDate', description: 'Fecha de actualización'},
            {key: 'graduationDate', description: 'Fecha de graduación'},
            {key: 'typeOfAssociation', description: 'Tipo de asociación'},
            {key: 'association', description: 'Asociación'},
            {key: 'inJobBankDate',description: 'Fecha de activacion en la bolsa'}
        ]
        for (const [key, value] of Object.entries(filterValues)) {
            if(value!==0 && value!=='' && value!==null && value !== undefined && value.length!==0 && key !=="subject" && key !=="html" && key !=="extraEmail" && key !=="aditionalEmails"){
                if(value[0]!==null && value[1]!==null ){
                    let obj;
                    const fieldObj = fieldNames.find(field=>field.key === key);
                    if(key === 'gender'){
                        obj = gendersType.find(gender=>gender.id === value);
                    } else if (key === 'campus'){
                        subIndex = index;
                        const tempCampus = allCampus.find(campus=>campus.id === value);
                        if(tempCampus){
                            tempArray.push({key: subIndex, name:tempCampus.parentName + ' campus', label: fieldObj?.description + ': ' + tempCampus?.parentName });
                            subIndex++;
                        }
                    } else if (key === 'school'){
                        subIndex = index;
                        (value as IGeneralParameter[]).forEach(schoolItem=>{
                            tempArray.push({key: subIndex, name:schoolItem.name + ' school', label: fieldObj?.description + ': ' + schoolItem.name });
                            subIndex++;
                        });
                    } else if (key === 'curriculum'){
                        subIndex = index;
                        (value as IGeneralParameter[]).forEach(programItem=>{
                            tempArray.push({key: subIndex, name:programItem.name + ' curriculum', label: fieldObj?.description + ': ' + programItem.name });
                            subIndex++;
                        });
                    } else if (key === 'state'){
                        subIndex = index;
                        (value as StateByRole[]).forEach(stateItem=>{
                            tempArray.push({key: subIndex, name:stateItem.label + ' state', label: fieldObj?.description + ': ' + stateItem.label });
                            subIndex++;
                        });
                    } else if (key === 'academicLevel'){
                        obj = allAcademic.find(academic=>academic.parameter_code === value);
                    } else if (key === 'typeOfAssociation'){
                        obj = associationTypeArray.find(assoType=>assoType.parameter_code === value);
                    } else if (key === 'association'){
                        obj = associationArray.find(asso=>asso.parameter_code === value);
                    } else if (key === 'updateDate'){
                        const startDate: string = moment(value[0]).format('DD/MM/YYYY');
                        const endDate: string = moment(value[1]).format('DD/MM/YYYY');
                        obj = {name: `${startDate} hasta ${endDate}`}
                    } else if (key === 'graduationDate'){
                        const startDate: string = moment(value[0]).format('DD/MM/YYYY');
                        const endDate: string = moment(value[1]).format('DD/MM/YYYY');
                        obj = {name: `${startDate} hasta ${endDate}`}
                    } else if (key === 'inJobBankDate'){
                        const startDate: string = moment(value[0]).format('DD/MM/YYYY');
                        const endDate: string = moment(value[1]).format('DD/MM/YYYY');
                        obj = {name: `${startDate} hasta ${endDate}`}
                    }
                    if(subIndex !==0){
                        index = subIndex
                        subIndex = 0;
                    }
                    if(obj){
                        tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + obj.name })
                    } else {
                        if(key !== 'campus' && key !== 'school' && key !== 'curriculum' && key !== 'state'){
                            tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + value})
                        }
                    }
                    index++;
                }
            }
        }
        setChipData(tempArray)
        if(userContext.roleSelected === 4){
            if(filterValues.campus>0){
            setValidIs(true)
            }else{
                setValidIs(false)
            }
        }else if (userContext.roleSelected === 6){
            if(filterValues.campus>0 && filterValues.school.length>0){
                setValidIs(true)
            }else{
                setValidIs(false)
            }
        }
    };

    const changeFilterForm = (e: any) => {
        const { name, value } = e.target;
        setFilterValues(prev => ({
            ...prev,
            [name]: value
        }));
        const error: string = Validations(name, value, filterGraduateValidation) || "";
        setFormErrors(prev => ({
            ...prev,
            [name]: error
        }));
    }

    const changeFilterDates = (name: string, dateValue: DateRange<Date>)=>{
        setFilterValues(prev => ({
            ...prev,
            [name]: dateValue
        }));
        const startDate: string = moment(dateValue[0]).format('YYYY/MM/DD');
        const endDate: string = moment(dateValue[1]).format('YYYY/MM/DD');
        let labelStart: string = "";
        let labelEnd: string = "";
        const valueStart = startDate === "Fecha inválida" ? "Fecha inválida" : "";
        const valueEnd = endDate === "Fecha inválida" ? "Fecha inválida" : "";
        if(name === "updateDate"){
            labelStart = "updatedAtStart";
            labelEnd = "updatedAtEnd";
        }
        if(name === "graduationDate"){
            labelStart = "graduationStart";
            labelEnd = "graduationEnd";
        }
        if(name === "inJobBankDate"){
            labelStart = "inJobBankStart";
            labelEnd = "inJobBankEnd";
        }
        errorDates(labelStart, valueStart);
        errorDates(labelEnd, valueEnd);
    }

    const errorDates = (label: string, value: string) => {
        setFormErrors(prev => ({
            ...prev,
            [label]: value
        }));
    }

    const changeStaticReportValues = (name: string, dateValue: DateRange<Date>) => {
        setStaticReportDates(dateValue);
        const startDate: string = moment(dateValue[0]).format('YYYY/MM/DD');
        const endDate: string = moment(dateValue[1]).format('YYYY/MM/DD');
        setFormValuesStaticReports(prev => ({
            ...prev,
            dateInit: startDate,
            dateEnd: endDate
        }));
        if(startDate === "Fecha inválida"){
            setFormErrorsStaticReport(prev =>({
                ...prev,
                dateInit:"Fecha inválida"
            }));
        } else {
            setFormErrorsStaticReport(prev =>({
                ...prev,
                dateInit:""
            }));
        }
        if(endDate === "Fecha inválida"){
            setFormErrorsStaticReport(prev =>({
                ...prev,
                dateEnd:"Fecha inválida"
            }));
        } else {
            setFormErrorsStaticReport(prev =>({
                ...prev,
                dateEnd:""
            }));
        }
    }

    const clickApplyFilters = async(value: any, action: string) => {
        if (action === 'apply') {
            const objectToSend = transformDataGraduate(filterValues, true);
            const responseDinamicReportGraduates = await trackPromise(dinamicReportGraduates(
                objectToSend
            ))
            console.dir(objectToSend)
            if(responseDinamicReportGraduates.status === 200){
                const result = await responseDinamicReportGraduates.json();
                setRecordsFound(result.quantity || 0 );
                setChips();
            } else {
                setOpenModalError(true);
                setErrorMessage("Hubo un error, intente de nuevo mas tarde, o contacte al administrador general");
            }
        } else if (action === 'clear') {
            setChipData([]);
            setFilterValues(new IGraduateFilterForm());
            setFormErrors(initialGraduateFilterErrors);
            setRecordsFound(0);
        }
    }

    const applyStaticFilters = () =>{
        checkAndRequestService(true, false);
    }

    const handleDeleteChip = (chipToDelete: ChipData) => () => {
        const name =chipToDelete.name;
        setChipData((chips) => chips.filter((chip) => chip.name !== chipToDelete.name));
        if(name === 'gender' || name === 'academicLevel'){
            setFilterValues(prev => ({
                ...prev,
                [chipToDelete.name]: 0
            }));
        } else if (name.includes('campus')){
            setFilterValues(prev => ({
                ...prev,
                campus: 0
            }));
        } else if(name === 'updateDate' || name === "graduationDate" || name === "inJobBankDate"){
            setFilterValues(prev => ({
                ...prev,
                [name]: [null,null]
            }));
        } else if(name.includes('school')){
            const newSchools = filterValues.school.filter(item=>item.name !== chipToDelete.label.split(': ')[1]);
            setFilterValues(prev => ({
                ...prev,
                school: newSchools
            }));
        } else if(name.includes('curriculum')){
            const newCurriculum = filterValues.curriculum.filter(item=>item.name !== chipToDelete.label.split(': ')[1]);
            setFilterValues(prev => ({
                ...prev,
                curriculum: newCurriculum
            }));
        } else if(name.includes('state')){
            const newState = filterValues.state.filter(item=>item.label !== chipToDelete.label.split(': ')[1]);
            setFilterValues(prev => ({
              ...prev,
              state: newState
            }));
        } else {
            setFilterValues(prev => ({
                ...prev,
                [chipToDelete.name]: ''
            }));
        }
    };

    const resetReportGraduateDinamic = () =>{
        setFilterValues(new IGraduateFilterForm());
        setFormErrors(initialGraduateFilterErrors);
        setRecordsFound(0);
        const newMainFields = resetStatusCheckArray(mainFieldsCheck);
        setMainFieldsCheck(newMainFields);
        const newWorkExperiences = resetStatusCheckArray(workExperienceFields);
        setWorkExperienceFields(newWorkExperiences);
        const newStudiesFields = resetStatusCheckArray(studiesFields);
        setStudiesFields(newStudiesFields);
        const newadditionalInfo = resetStatusCheckArray(additionalInfoFields);
        setAdditionalInfoFields(newadditionalInfo);
        const newLanguagesFields = resetStatusCheckArray(languageFields);
        setLanguageFields(newLanguagesFields)

        setAditionalComponent("None");
        setChipData([]);
        setUrlFileReportLog("");
    }

    const resetReportGraduateStatic = () =>{
        setFormValuesStaticReports(new IStaticReport());
        setFormErrorsStaticReport(initialStaticReportValues);
        setTypeReport(new ITypeReport());
        setStaticReportDates([null, null]);
        setRecordsFound(0);
        setUrlFileReportLog("");
    }

    const changeReportType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReportSelected((event.target as HTMLInputElement).value);
        if((event.target as HTMLInputElement).value === "Dinamic"){
            resetReportGraduateDinamic();
        } else {
            resetReportGraduateStatic();
        }
    };

    const changeAdditionalComponent = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAditionalComponent((event.target as HTMLInputElement).value);
        if((event.target as HTMLInputElement).value === "None"){
            const newWorkExperiences = resetStatusCheckArray(workExperienceFields);
            setWorkExperienceFields(newWorkExperiences);
            const newStudiesFields = resetStatusCheckArray(studiesFields);
            setStudiesFields(newStudiesFields);
            const newadditionalInfo = resetStatusCheckArray(additionalInfoFields);
            setAdditionalInfoFields(newadditionalInfo);
            const newLanguagesFields = resetStatusCheckArray(languageFields);
            setLanguageFields(newLanguagesFields)
        } else if ((event.target as HTMLInputElement).value === "workExperience"){
            const newStudiesFields = resetStatusCheckArray(studiesFields);
            setStudiesFields(newStudiesFields);
            const newadditionalInfo = resetStatusCheckArray(additionalInfoFields);
            setAdditionalInfoFields(newadditionalInfo);
            const newLanguagesFields = resetStatusCheckArray(languageFields);
            setLanguageFields(newLanguagesFields)
        } else if((event.target as HTMLInputElement).value === "studies"){
            const newWorkExperiences = resetStatusCheckArray(workExperienceFields);
            setWorkExperienceFields(newWorkExperiences);
            const newadditionalInfo = resetStatusCheckArray(additionalInfoFields);
            setAdditionalInfoFields(newadditionalInfo);
            const newLanguagesFields = resetStatusCheckArray(languageFields);
            setLanguageFields(newLanguagesFields)
        } else if((event.target as HTMLInputElement).value === "AditionalInfo"){
            const newStudiesFields = resetStatusCheckArray(studiesFields);
            setStudiesFields(newStudiesFields);
            const newWorkExperiences = resetStatusCheckArray(workExperienceFields);
            setWorkExperienceFields(newWorkExperiences);
            const newLanguagesFields = resetStatusCheckArray(languageFields);
            setLanguageFields(newLanguagesFields)
        } else if((event.target as HTMLInputElement).value === "languages"){
            const newStudiesFields = resetStatusCheckArray(studiesFields);
            setStudiesFields(newStudiesFields);
            const newWorkExperiences = resetStatusCheckArray(workExperienceFields);
            setWorkExperienceFields(newWorkExperiences);
            const newadditionalInfo = resetStatusCheckArray(additionalInfoFields);
            setAdditionalInfoFields(newadditionalInfo);
        }
    };

    const changeMainReportFields = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMainFieldsCheck({ ...mainFieldsCheck, [event.target.name]: event.target.checked });
    };

    const changeWorkExperienceFields = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWorkExperienceFields({ ...workExperienceFields, [event.target.name]: event.target.checked });
    };

    const changeStudiesFields = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStudiesFields({ ...studiesFields, [event.target.name]: event.target.checked });
    };

    const changeAdditionalInfoFields = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdditionalInfoFields({ ...additionalInfoFields, [event.target.name]: event.target.checked });
    };

    const changeLanguagesFields = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLanguageFields({ ...languageFields, [event.target.name]: event.target.checked });
    };

    const changeTypeReport = (e: any) => {
        const { name, value } = e.target;
        setTypeReport(prev => ({
            ...prev,
            [name]: value
        }));
        if(value === ""){
            setStaticReportDates([null, null]);
            setRecordsFound(0);
        }
    }

    const updateDataStaticReport = async(responseStatic: Response, showModal: boolean) =>{
        if (responseStatic.status === 200 ) {
            setReportLoad(true)
            const resultFile = await responseStatic.json();
            const key = resultFile.key
            const allReports = await getGraduateReports();
            if (allReports.status === 200){
                if(reportSelected === "Dinamic"){
                    const result: Array<IGetDocuments> = await allReports.json();
                    const cvDoc = result.find(doc=>doc.key.includes(key));
                    if(cvDoc){
                        const urlDoc = cvDoc.signedUrl;
                        if(showModal && reportSelected === "Dinamic" ){
                            console.dir(urlDoc)
                            setUrlFileReportLog(urlDoc);
                            setRecordsFound(parseInt(resultFile.quantity));
                            setOpenModalDownload(true);
                        } else{
                            setRecordsFound(resultFile.quantity);
                        }
                    }
                }
            }

            if(showModal && reportSelected === "Predetermined" ){
                setUrlFileReportLog(resultFile.url);
                setRecordsFound(parseInt(resultFile.quantity));
                setOpenModalDownload(true);
            } else{
                setRecordsFound(resultFile.quantity);
            }
            setReportLoad(false)
        } else {
            setOpenModalError(true);
            setErrorMessage("Hubo un error, intente de nuevo mas tarde, o contacte al administrador general");
        }
    }

    const transformGraduateFields = (prevObjectToSend: IGraduateFilterObjDinamic) => {
        if(prevObjectToSend.graduate){
            const mainFieldsSelected = checkValuesSelected(mainFieldsCheck);
            prevObjectToSend.graduate.fields = mainFieldsSelected || [];
        }
        if(prevObjectToSend.studies){
            const studiesFieldsSelected = checkValuesSelected(studiesFields);
            prevObjectToSend.studies.fields = studiesFieldsSelected || [];
        }
        if(prevObjectToSend.associations){
            const associationsFieldsSelected = checkValuesSelected(additionalInfoFields);
            prevObjectToSend.associations.fields = associationsFieldsSelected || [];
        }
        if(prevObjectToSend.laboralExperience){
            const laboralExperienceFieldsSelected = checkValuesSelected(workExperienceFields);
            prevObjectToSend.laboralExperience.fields = laboralExperienceFieldsSelected || [];
        }
        if(prevObjectToSend.languages){
            const languagesFieldsSelected = checkValuesSelected(languageFields);
            prevObjectToSend.languages.fields = languagesFieldsSelected || [];
        }


        delete prevObjectToSend.limit;
        delete prevObjectToSend.offset;
        return prevObjectToSend
    }

    const generateReport = async () => {
        if(reportSelected === "Dinamic"){
            const prevObjectToSend = transformDataGraduate(filterValues, false);
            const objectToSend = transformGraduateFields(prevObjectToSend);
            objectToSend.userReporter = userContext.username
            if((objectToSend.graduate && objectToSend.graduate.fields && objectToSend.graduate.fields.length>0)|| (objectToSend.studies && objectToSend.studies.fields && objectToSend.studies.fields.length>0) || (objectToSend.associations && objectToSend.associations.fields && objectToSend.associations.fields.length>0) || (objectToSend.laboralExperience && objectToSend.laboralExperience.fields && objectToSend.laboralExperience.fields.length>0)){
                
                const responseDinamicReportGraduates = await trackPromise(dinamicReportGraduates(
                    objectToSend
                ))
                
                updateDataStaticReport(responseDinamicReportGraduates, true);
            } else {
                setOpenModalError(true);
                setErrorMessage("Por favor seleccione algun campo para el reporte");
            }
        } else {
            if (typeReport.type === ''){
                setOpenModalError(true);
                setErrorMessage("Por favor seleccione algun filtro para el reporte");
            }
        }
        checkAndRequestService(false, true);
    }

    const checkAndRequestService = async(preview: boolean, showModal: boolean) => {
        const objectToSend: IStaticReport = formValuesStaticReports;
        objectToSend.preview = preview;
        if (typeReport.type === 'reportGraduatesAssociationsByProgram'&& objectToSend.dateInit !== "Fecha inválida" && objectToSend.dateEnd !== "Fecha inválida") {
            const responseGAP = await trackPromise(reportGraduatesAssociationsByProgram(objectToSend));
            updateDataStaticReport(responseGAP, showModal);
        } else if (typeReport.type === 'reportGraduatesByProgramAndGender'&& objectToSend.dateInit !== "Fecha inválida" && objectToSend.dateEnd !== "Fecha inválida") {
            const responseGPG = await trackPromise(reportGraduatesByProgramAndGender(objectToSend));
            updateDataStaticReport(responseGPG, showModal);
        } else if (typeReport.type === 'reportLinkedByJobOffer'&& objectToSend.dateInit !== "Fecha inválida" && objectToSend.dateEnd !== "Fecha inválida") {
            const responseLJO = await trackPromise(reportLinkedByJobOffer(objectToSend));
            updateDataStaticReport(responseLJO, showModal);
        } else if (typeReport.type === 'reportSalaryByProgram' && objectToSend.dateInit !== "Fecha inválida" && objectToSend.dateEnd !== "Fecha inválida") {
            const responseSP = await trackPromise(reportSalaryByProgram(objectToSend));
            updateDataStaticReport(responseSP, showModal);
        } else if (typeReport.type === 'reportCurrentWorkLocation'&& objectToSend.dateInit !== "Fecha inválida" && objectToSend.dateEnd !== "Fecha inválida") {
            const responseCWL = await trackPromise(reportCurrentWorkLocation(objectToSend));
            updateDataStaticReport(responseCWL, showModal);
        } else if (typeReport.type === 'reportAgeRangeAndGender'&& objectToSend.dateInit !== "Fecha inválida" && objectToSend.dateEnd !== "Fecha inválida") {
            const responseARG = await trackPromise(reportAgeRangeAndGender(objectToSend));
            updateDataStaticReport(responseARG, showModal);
        } else if (typeReport.type === 'reportEmploymentModality'&& objectToSend.dateInit !== "Fecha inválida" && objectToSend.dateEnd !== "Fecha inválida") {
            const responseEM = await trackPromise(reportEmploymentModality(objectToSend));
            updateDataStaticReport(responseEM, showModal);
        } else if (typeReport.type === 'reportPerformanceArea'&& objectToSend.dateInit !== "Fecha inválida" && objectToSend.dateEnd !== "Fecha inválida") {
            const responsePA = await trackPromise(reportPerformanceArea(objectToSend));
            updateDataStaticReport(responsePA, showModal);
        } else if (typeReport.type === 'reportCompanyType'&& objectToSend.dateInit !== "Fecha inválida" && objectToSend.dateEnd !== "Fecha inválida") {
            const responseCT = await trackPromise(reportCompanyType(objectToSend));
            updateDataStaticReport(responseCT, showModal);
        }else if (typeReport.type === 'reportGraduateAcceptedAndRejected'&& objectToSend.dateInit !== "Fecha inválida" && objectToSend.dateEnd !== "Fecha inválida") {
            const responseGAR = await trackPromise(reportGraduateAcceptedAndRejected(objectToSend));
            updateDataStaticReport(responseGAR, showModal);
        }
    }

    const handleCloseModalDownload = () => {
        setOpenModalDownload(false);
        window.location.reload();
    };

    const handleCloseModalError = () => {
        setOpenModalError(false);
        // window.location.reload();
    };

    useEffect(() => {
        if(userContext.roleSelected === 5){
            setValidIs(true)
        }
    }, [])


    return (
        <Grid container className="main-container">
            <Grid container item xs={12}>
                <>
                {
                    userContext.roleSelected === 4?
                    <>
                    <div className="title-section-line">
                        <Typography variant="h3" className="message-title" >
                            <b className="red-title">Importante:</b> Señor administrador(a): Para poder filtrar su población de egresados debe seleccionar su sede
                        </Typography>
                    </div>
                    </>
                    :
                    <>
                    </>
                }
                </>
                <>
                {
                    userContext.roleSelected === 6?
                    <>
                    <div className="title-section-line">
                        <Typography variant="h3" className="message-title" >
                            <b className="red-title">Importante:</b> Señor administrador(a): Para poder filtrar su población de egresados debe seleccionar su sede y facultad
                        </Typography>
                    </div>
                    </>
                    :
                    <>
                    </>
                }
                </>
            </Grid>
            <Grid container item xs={12} className="graduate-report-container">
                <Grid container item xs={12} className="report-container">
                    <Grid container item xs={12} className="title-container-report">
                        <Typography variant="h2" className="title">
                            Seleccione el tipo de reporte
                        </Typography>
                    </Grid>
                    <Grid container className="report-content">
                        <Grid container item xs={12} md={2}>
                            <Typography variant="h4" className="report-state-text">
                                Tipo de reporte:
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} md={10} className="report-state-options">
                            <FormControl component="fieldset">
                                <RadioGroup row className="radio-container" aria-label="state" name="type" value={reportSelected} onChange={changeReportType}>
                                    {ReportOption.map(option => (
                                        <FormControlLabel key={option.id} value={option.value} className="radio-btn" control={<Radio className="color-btn" />} label={option.label} labelPlacement="start" />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {containsAny(permissionAdminStaticReportsGraduates, userContext.userPermissions) &&
                            <>
                                <Grid container item xs={12} className={reportSelected === "Predetermined" ? "line" : "no-container"} />
                                <Grid item xs={12} className={reportSelected === "Predetermined" ? "field-box-margin" : "no-container"}>
                                    <Typography variant="h5" className="field-box-title">Reporte*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-type"
                                        select
                                        name="type"
                                        label={typeReport.type === "" ? "Seleccione" : ""}
                                        value={typeReport.type === "" ? "" : typeReport.type}
                                        onChange={changeTypeReport}
                                        InputLabelProps={{ shrink: false }}
                                        margin="none"
                                        variant="outlined"
                                    >
                                        {
                                            reportOption && reportOption.length> 0 &&
                                            <MenuItem value=""><em>Ninguno</em></MenuItem>
                                        }
                                        {reportOption.map(item => (
                                            <MenuItem key={item.id} value={item.value}>
                                                <Typography variant="h5" className="regular-serif">
                                                    {item.name}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
            </Grid>
            {reportSelected === "Dinamic" ?
                <>
                {containsAny(permissionAdminDinamicReportsGraduates, userContext.userPermissions) ?
                    <>
                        <Grid container item xs={12}>
                            <MoreFiltersGraduate titleSection="Agregar filtros de búsqueda" formValues={filterValues} formErrors={formErrors} onChangeForm={changeFilterForm} changeFilterDates={changeFilterDates} gendersType={gendersType} allCampus={allCampus} schools={schools} programs={programs} allAcademic={allAcademic} associationTypeArray={associationTypeArray} associationArray={associationArray} reportForm={true} stateOptions={stateOptionsGraduate} campusSelected={campusSelected} aux={aux} aux2={aux2} schoolSelected={schoolSelected}/>
                        </Grid>
                        <Grid container item xs={12}>
                            <ShowFilters titleSection="Filtros seleccionados" filtersData={chipData} showButtons={true} showDelete={true} deleteFilter={handleDeleteChip} isValid={validIs} onApplyFilters={clickApplyFilters} />
                        </Grid>
                    </>
                :
                    <NotAuthorized/>
                }
                </>
            :
                <>
                {containsAny(permissionAdminStaticReportsGraduates, userContext.userPermissions) ?
                    <>
                        <Grid container item xs={12} className="graduate-report-container">
                            <Grid container item xs={12} className="report-container2">
                                <Grid container item xs={12} className="title-container-report">
                                    <Typography variant="h2" className="title">
                                        Seleccione el rango de fechas
                                    </Typography>
                                </Grid>
                                <Grid container className="date-content">
                                    <Grid item xs={12} md={6} xl={4} className="field-box-margin">
                                        <Typography variant="h5" className="field-box-title">Rango de fechas</Typography>
                                        <Grid container item xs={12}>
                                            <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale} >
                                                <DateRangePicker
                                                    disableFuture
                                                    calendars={1}
                                                    PopperProps={{
                                                        placement: "bottom"
                                                    }}
                                                    value={staticReportDates || null}
                                                    onChange={(value)=>changeStaticReportValues('staticReportDates',value)}
                                                    renderInput={(startProps, endProps) => (
                                                        <Grid container item xs={12} alignItems="center">
                                                            <Grid item xs={12} sm={5}>
                                                                <TextField
                                                                    {...startProps}
                                                                    autoComplete="off"
                                                                    fullWidth
                                                                    name="dateInit"
                                                                    margin="none"
                                                                    label={staticReportDates[0] !== null ? "" : "DD/MM/AAAA"}
                                                                    error={!!formErrorsStaticReport.dateInit}
                                                                    helperText={formErrorsStaticReport.dateInit}
                                                                    InputLabelProps={{ shrink: false }}
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={2}>
                                                                <Typography variant="h5" className="field-box-title" style={{ textAlign: 'center' }}>
                                                                    Hasta
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={5}>
                                                                <TextField
                                                                    {...endProps}
                                                                    fullWidth
                                                                    autoComplete="off"
                                                                    name="dateEnd"
                                                                    margin="none"
                                                                    label={staticReportDates[1] !== null ? "" : "DD/MM/AAAA"}
                                                                    error={!!formErrorsStaticReport.dateEnd}
                                                                    helperText={formErrorsStaticReport.dateEnd}
                                                                    InputLabelProps={{ shrink: false }}
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} justify="center">
                                <div>
                                    <Button disabled={!isValidStaticFilters} variant="contained" className="apply-filters-button" onClick={applyStaticFilters}>
                                        <Typography variant="h5" className="apply-filters-text">Aplicar filtros</Typography>
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </>
                :
                    <NotAuthorized/>
                }
                </>
            }
            {(containsAny(permissionAdminDinamicReportsGraduates, userContext.userPermissions) || containsAny(permissionAdminStaticReportsGraduates, userContext.userPermissions)) &&
                <Grid container item xs={12} className="information-result-report-section">
                    <Typography variant="h4" className="bold-serif">Total de egresados encontrados: </Typography><span className="total-results-section">{recordsfound} resultados</span>
                </Grid>
            }
            {reportSelected === "Dinamic" && containsAny(permissionAdminDinamicReportsGraduates, userContext.userPermissions)  &&
                <>
                    <Grid container className="description-container">
                        <Typography variant="h4" className="description-title">
                            1. Por favor seleccione los datos que desea se listen en el reporte.
                        </Typography>
                    </Grid>

                    <Grid container item xs={12}>
                        <AditionalData title="Datos personales" stateCheck={mainFieldsCheck} onChangeState={changeMainReportFields} stateOptions={personalDataOption} />
                    </Grid>

                    <Grid container className="description-container">
                        <Typography variant="h4" className="description-title">
                            2. Por favor seleccione los componentes adicionales que desea se listen en el reporte.
                        </Typography>
                    </Grid>

                    <Grid container item xs={12} className="graduate-report-container2" >
                        <Grid container item xs={12} className="report-container2">
                            <Grid container item xs={12} className="title-container-report2">
                                <Typography variant="h2" className="title">
                                    Componentes adiciones
                                </Typography>
                            </Grid>
                            <Grid container className="report-content2">
                                <Grid container item xs={12} md={10} className="report-state-options2" >
                                    <FormControl component="fieldset">
                                        <RadioGroup row className="radio-container" aria-label="aditionalInfo" name="aditionalInfo" value={aditionalComponent} onChange={changeAdditionalComponent}>
                                            {AditionalComponent.map(option => (
                                                <FormControlLabel key={option.id} value={option.value} className="radio-btn" control={<Radio className="color-btn" />} label={option.label} labelPlacement="start" />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={12} className={aditionalComponent === "" ? "no-container" : "line"} />
                                {aditionalComponent !== "None" &&
                                    <>
                                    {aditionalComponent === "workExperience" &&
                                        <Grid container >
                                            <AditionalOptions stateCheck={workExperienceFields} onChangeState={changeWorkExperienceFields} stateOptions={workExperienceOption} />
                                        </Grid>
                                    }
                                    {aditionalComponent === "studies" &&
                                        <Grid container >
                                            <AditionalOptions stateCheck={studiesFields} onChangeState={changeStudiesFields} stateOptions={studiesOption} />
                                        </Grid>
                                    }
                                    {aditionalComponent === "languages" &&
                                        <Grid container >
                                            <AditionalOptions stateCheck={languageFields} onChangeState={changeLanguagesFields} stateOptions={languagueInfoOption} />
                                        </Grid>
                                    }
                                    {aditionalComponent === "AditionalInfo" &&
                                        <Grid container >
                                            <AditionalOptions stateCheck={additionalInfoFields} onChangeState={changeAdditionalInfoFields} stateOptions={aditionalInfoOption} />
                                        </Grid>
                                    }
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
            {(containsAny(permissionAdminDinamicReportsGraduates, userContext.userPermissions) || containsAny(permissionAdminStaticReportsGraduates, userContext.userPermissions)) &&
                <Grid container item xs={12} className="button-download-report-container">
                    <Button disabled={recordsfound>0 ? false : true} className="generate-report-btn" variant="contained" size="small" onClick={generateReport}>
                        <Typography variant="h5" className="regular-serif">Generar reporte</Typography>
                    </Button>
                </Grid>
            }
            <LoadingLockPage load={promiseInProgress} />
            <LoadingLockPage load={initLoad} />
            <LoadingLockPage load={reportLoad} />
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
            <DownloadModal downloadMsg="El reporte se ha generado con éxito" downloadMsg2="¿Desea descargarlo?" handleCloseModal={handleCloseModalDownload} openModalDownload={openModalDownload} urlReport={urlFileReportLog} total={recordsfound.toString()} />
        </Grid>
    )
}

export default ReportFormGraduate;