import React from 'react';
import Typography from '@material-ui/core/Typography';

import './NotEnoughRequired.scss';

const NotEnoughRequired:React.FC = () => {
    return(
        <div className="main-container">
            <div className="not-enough-container">
                <Typography variant="h1" className="bold-serif">
                    No cuentas con los requisitos para entrar a la bolsa de empleo
                </Typography>
                <Typography variant="h3" className="regular-serif">
                    Para poder hacer uso de esta funcion tiene que completar todos los campos obligatorios y aceptar estar en la bolsa de empleo
                </Typography>
            </div>
        </div>
    )
}

export default NotEnoughRequired;