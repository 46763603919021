/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import WarningIcon from '@material-ui/icons/Warning';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from "@material-ui/core/MenuItem";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import { makeStyles, createStyles, Theme, withStyles} from '@material-ui/core/styles';
import AlertBox from '../../ShareComponents/AlertBox';
import NotificationBox from '../../ShareComponents/NotificationBox';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { IGeneralSIA } from '../../../interfaces/IGeneralSIA';
import { sortByKey } from '../../../helpers/sharedFunctions';
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';
import UserContext from '../../../utils/UserContext';
import { getUserPersonalInfo, updatePersonalInfo, getUserPercentage } from '../../../services/GraduateService';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import { IFile } from '../../../interfaces/IFile';
import { IPresignedUrl, IPreSignedUrlReq } from '../../../interfaces/IPreSignedUrl';
import { getSignUrl, uploadFileSignedUrl } from '../../../services/s3Service';
import Validations from '../../../helpers/Validations';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import { CONTACTTYPE, GENDER, SOCIALNETWORK } from '../../../services/SIAService';
import { getCAMPUS_FACULTIES, getCITY, getCOUNTRIES_CITIES, getDEPARTAMENT, getDISABILITIES, getDISTINCTIONS_CATEGORIES, getETHNIC_GROUP, getID_TYPE, getMARITAL_STATUS, getNameSpecialAdmission, getTypeAdmissionPAES } from '../../../services/ParameterService';
import { IChildrenInfo, IDistinctions, IPersonalInfoCV, IReferent } from '../../../interfaces/IPersonalInfoCV';
import './PersonalInformation.scss';
import { useParams } from 'react-router';
import ModalOffersAction from '../../LaboralOffers/ModalOffersAction';
import CircularProgressDet from '../../ShareComponents/CircularProgressDet';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: theme.spacing(2, 0),
    },
  }),
);
const BlueSwitch = withStyles({
  switchBase: {
    color:'#ffffff',
    '&$checked': {
      color:'#004B73',
    },
    '&$checked + $track': {
      backgroundColor: '#004B73',
    },
  },
  checked: {},
  track: {},
})(Switch);


class InitialPersonalErrors {
    // personal identification
    names: string = "";
    surname: string = "";
    secondsurname: string = "";
    documentType: number = 0;
    doc: string = "";
    fileError: string =  "";
    gender: string =  "";
    stratum: string =  "";
    deceased: string =  "";
    conflictVictim:string = "";
    ethnicgroup: string =  "";
    //birthplace: string =  "";
    birthCountry: string =  "";
    birthDepartment: string =  "";
    birthCity: string =  "";
    birthdate: string =  "";
    maritalStatus: string =  "";
    impairment: string =  "";
    residenceCountry: string =  "";
    residenceDepartment: string =  "";
    residenceCity: string =  "";
    address: string =  "";
    phone: string =  "";
    cellphone: string = "";
    additionalPhone: string =  "";
    email: string =  "";
    additionalEmail: string =  "";
    socialnetwork: string =  "";
    urlSocialnetwork: string =  "";
    nameSpecialAdmissionProgram: string =  "";
    paesProgram: string =  "";
    initialCampusPeama: string =  "";
    finalCampusPeama: string =  "";
    // distinctions
    distinctionCategory: string =  "";
    yearDateFormat: string =  "";
    distinctionDescription: string =  "";
    // familiar info
    numSonsAndDaughters: string =  "";
    familyNames: string =  "";
    familySurname: string =  "";
    familySecondSurname: string =  "";
    birthdayYearDateFormat: string =  "";
    familyAddress: string =  "";
    // contact info
    type: string =  "";
    contactNames: string =  "";
    contactSurname: string =  "";
    contactSecondSurnmae: string =  "";
    contactPhone: string =  "";
    contactEmail: string =  "";
    contactPosition: string =  "";
}

const formValidations = {
    names: {
        error: "",
        validate: "specialText",
        minLength: 2,
        maxLength: 40,
        required: true
    },

    surname: {
        error: "",
        validate: "specialText",
        minLength: 3,
        maxLength: 30,
        required: true
    },

    secondsurname: {
        error: "",
        validate: "specialText",
        minLength: 2,
        maxLength: 30,
        required: false
    },
    docTypeUser: {
        validate: "select",
        required: true
    },
    documentType: {
        validate: "select",
        required: true
    },

    doc: {
        error: "",
        validate: "number",
        minLength: 6,
        maxLength: 11,
        required: true
    },
    gender: {
        validate: "select",
        required: true
    },
    stratum: {
        validate: "select",
        required: true
    },
    ethnicgroup: {
        validate: "select",
        required: true
    },
    // birthplace: {
    //     error: "",
    //     validate: "text",
    //     required: false
    // },
    birthCountry: {
        validate: "select",
        required: true
    },
    birthDepartment: {
        validate: "select",
        required: true
    },
    birthCity: {
        validate: "select",
        required: true
    },
    birthdate: {
        error: "",
        validate: "normalDate",
        maxDate: new Date(),
        required: true
    },
    maritalStatus: {
        validate: "select",
        required: true
    },
    impairment: {
        validate: "select",
        required: false
    },
    residenceCountry: {
        validate: "select",
        required: true
    },
    residenceDepartment: {
        validate: "select",
        required: true
    },
    residenceCity: {
        validate: "select",
        required: true
    },
    address: {
        error: "",
        validate: "textNumber",
        minLength: 3,
        required: true
    },
    cellphone:{
        error: "",
        validate: "number",
        minLength: 7,
        maxLength: 10,
        required: true
    },
    additionalPhone: {
        error: "",
        validate: "textNumber",
        minLength: 7,
        maxLength: 30,
        required: false
    },
    email:{
        error: "",
        validate: "email",
        required: false
    },
    additionalEmail:{
        error: "",
        validate: "email",
        required: false
    },
    socialnetwork:{
        validate: "select",
        required: false
    },
    urlSocialnetwork: {
        error: "",
        validate: 'website',
        required: false
    },
    nameSpecialAdmissionProgram:{
        validate: "select",
        required: true
    },
    paesProgram:{
        validate: "select",
        required: true
    },
    initialCampusPeama:{
        validate: "select",
        required: true
    },
    finalCampusPeama:{
        validate: "select",
        required: true
    },
    distinctionCategory:{
        validate: "select",
        required: true
    },
    yearDateFormat: {
        error: "",
        validate: "normalDate",
        maxDate: new Date(),
        required: true
    },
    distinctionDescription:{
        error: "",
        validate: "textNumber",
        minLength: 3,
        maxLength: 100,
        required: true
    },
    numSonsAndDaughters: {
        validate: "select",
        required: false
    },
    familyNames: {
        error: "",
        validate: "text",
        minLength: 3,
        required: true
    },
    familySurname: {
        error: "",
        validate: "text",
        minLength: 3,
        required: true
    },
    familySecondSurname: {
        error: "",
        validate: "text",
        minLength: 3,
        required: false
    },
    birthdayYearDateFormat: {
        error: "",
        validate: "normalDate",
        maxDate: new Date(),
        required: true
    },
    familyAddress:{
        error: "",
        validate: "textNumber",
        minLength: 3,
        required: false
    },
    type: {
        validate: "select",
        required: true
    },
    contactNames:{
        error: "",
        validate: "text",
        minLength: 3,
        required: true
    },
    contactSurname: {
        error: "",
        validate: "text",
        minLength: 3,
        required: true
    },
    contactSecondSurnmae: {
        error: "",
        validate: "text",
        minLength: 3,
        required: false
    },
    contactPhone:{
        error: "",
        validate: "number",
        minLength: 7,
        maxLength: 10,
        required: true
    },
    contactEmail:{
        error: "",
        validate: "email",
        required: false
    },
    contactPosition:{
        error: "",
        validate: "text",
        minLength: 3,
        required: true
    },
}

const PersonalInformation: React.FC = () => {
    let params: any = useParams();
    const userContext = React.useContext(UserContext);
    const { promiseInProgress } = usePromiseTracker();
    const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [initialValues, setInitialValues] = useState<boolean>(true);
    const [expanded, setExpanded] = useState<string | boolean>('panel1');
    const [formValues, setFormValues] = useState<IPersonalInfoCV>(new IPersonalInfoCV({}));
    const [distinctionForm, setDistinctionForm] = useState<IDistinctions>(new IDistinctions());
    const [familyForm, setFamilyForm] = useState<IChildrenInfo>(new IChildrenInfo());
    const [contactForm, setContactForm] = useState<IReferent>(new IReferent());
    const [formErrors, setFormErrors] = useState<InitialPersonalErrors>(new InitialPersonalErrors());
    const [percUser, setPercUser] = useState<number>(0);
    //data
    const [ docTypeUser, setDocTypeUser ] = useState<IGeneralParameter>();
    const [documentsType,setDocumentsType] = useState<Array<IGeneralParameter>>([]);
    const [gendersType, setGendersType] = useState<Array<IGeneralSIA>>([]);
    const economicLevel: Array<number> = [1,2,3,4,5,6];
    const [ethnicGroup, setEthnicGroup] = useState<Array<IGeneralParameter>>([]);
    const [maritalArray, setMaritalArray] = useState<Array<IGeneralParameter>>([]);
    const [disabilitiesArray, setDisabilitiesArray] = useState<Array<IGeneralParameter>>([]);
    const [countryCitiesArray, setCountryCitiesArray] = useState<Array<IListParametersInfo>>([]);
    const [departmentArray, setDepartmentArray] = useState<Array<IGeneralParameter>>([]);
    const [cityArray, setCityArray] = useState<Array<IGeneralParameter>>([]);
    const [citiesArray, setCitiesArray] = useState<Array<IGeneralParameter>>([]);//cities Residence
    const [birthCitiesArray, setBirthCitiesArray] = useState<Array<IGeneralParameter>>([]); //cities Birth
    const networkArray = SOCIALNETWORK;
    const [specialAdmission, setSpecialAdmission] = useState<Array<IGeneralParameter>>([]);
    const [allCampus, setAllCampus] = useState<Array<IListParametersInfo>>([]);
    const [distinctionCategories, setDistinctionCategories] = useState<Array<IGeneralParameter>>([]);
    const [typeAdmissionPAES, setTypeAdmissionPAES] = useState<Array<IGeneralParameter>>([]);
    const childrenArray = new Array(20).join().split(',').map((item, index)=> ++index)
    const contactTypeArray = CONTACTTYPE;
    // modals and errors
    const [errorMessage, setErrorMessage]= useState('');
    const [openModalError, setOpenModalError] = React.useState(false);
    const [successMessage, setSuccessMessage]= useState('');
    const [opeModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
    const [openModalSuccess, setOpenModalSuccess] = React.useState(false);

    const isValidSave =
        formValues.personalIdentificationData.gender>0 &&
        formValues.personalIdentificationData.stratum>0 &&
        formValues.personalIdentificationData.ethnicgroup>0 &&
        moment(formValues.personalIdentificationData.birthdate).isValid() &&
        !formErrors.birthdate &&
        formValues.personalIdentificationData.maritalStatus>0 &&
        formValues.personalIdentificationData.birthCountry>0 &&
        formValues.personalIdentificationData.birthCity>0 &&
        formValues.personalIdentificationData.residenceCountry>0 &&
        formValues.personalIdentificationData.residenceCity>0 &&
        formValues.personalIdentificationData.cellphone.length>0 &&
        !formErrors.cellphone &&
        (
            !formValues.personalIdentificationData.specialAdmissionProgram ||
            (
                formValues.personalIdentificationData.specialAdmissionProgram &&
                (
                    (
                        formValues.personalIdentificationData.nameSpecialAdmissionProgram === 1 &&
                        formValues.personalIdentificationData.paesProgram &&
                        formValues.personalIdentificationData.paesProgram>0
                    ) ||
                    (
                        formValues.personalIdentificationData.nameSpecialAdmissionProgram === 2 &&
                        formValues.personalIdentificationData.initialCampusPeama>0 &&
                        formValues.personalIdentificationData.finalCampusPeama>0
                    )
                )
            )
        )

    const isValidAddDistinction =
        distinctionForm.category>0 &&
        moment(distinctionForm.yearDateFormat).isValid() &&
        !formErrors.yearDateFormat &&
        distinctionForm.description.length>0 &&
        !formErrors.distinctionDescription

    const isValidAddChild =
        familyForm.names.length>0 &&
        !formErrors.familyNames &&
        familyForm.surname.length>0 &&
        !formErrors.familySurname &&
        !formErrors.familySecondSurname &&
        !formErrors.familyAddress &&
        moment(familyForm.birthdayYearDateFormat).isValid() &&
        !formErrors.birthdayYearDateFormat

    const isValidAddContact =
        contactForm.names.length>0 &&
        !formErrors.contactNames &&
        contactForm.surname.length>0 &&
        !formErrors.contactSurname &&
        !formErrors.contactSecondSurnmae &&
        contactForm.phone.length>0 &&
        !formErrors.contactPhone &&
        !formErrors.contactEmail &&
        contactForm.type.length >0 &&
        (
            (contactForm.type !== "Laboral") ||
            (
                contactForm.type === "Laboral" &&
                contactForm.position.length>0 &&
                !formErrors.contactPosition
            )
        )

    const classes = useStyles();

    useEffect(()=>{
        
        const getInitialData = async()=>{
            window.scrollTo(0, 0);
            // data for docs type
            const dataDocType:Array<IGeneralParameter> = await getID_TYPE();
            setDocumentsType(dataDocType ? dataDocType : []);
            // data for gender
            let dataGender:Array<IGeneralSIA> = GENDER;
            dataGender = sortByKey(dataGender, 'name');
            setGendersType(dataGender)
            // data for ethnic field
            let dataEthnic:Array<IGeneralParameter> = await getETHNIC_GROUP();
            setEthnicGroup(dataEthnic);
            // data marital status
            let dataMarital:Array<IGeneralParameter> = await getMARITAL_STATUS();
            setMaritalArray(dataMarital);
            // data countries-cities
            let dataCountryCities:Array<IListParametersInfo> = (await getCOUNTRIES_CITIES()).list;
            dataCountryCities = sortByKey(dataCountryCities, 'grandParentName');
            setCountryCitiesArray(dataCountryCities);
            // department field
            let responseDepartment:Array<IGeneralParameter> = await getDEPARTAMENT();
            responseDepartment = sortByKey(responseDepartment, 'name');
            setDepartmentArray(responseDepartment);
            // data for cities
            const dataCity:Array<IGeneralParameter> = await getCITY();
            setCityArray(dataCity);
            //data for disabilities
            const dataDisabilities:Array<IGeneralParameter> = await getDISABILITIES();
            setDisabilitiesArray(dataDisabilities);
            // data name special admission
            const dataSpecialAdmission = await getNameSpecialAdmission();
            setSpecialAdmission(dataSpecialAdmission);
            // data for campus field
            let dataAllCampus: Array<IListParametersInfo> = (await getCAMPUS_FACULTIES()).list;
            dataAllCampus = sortByKey(dataAllCampus, 'parentName');
            setAllCampus(dataAllCampus ? dataAllCampus : []);
            //data for distiction categories
            let dataAllDistinction: Array<IGeneralParameter>  = await getDISTINCTIONS_CATEGORIES();
            dataAllDistinction = sortByKey(dataAllDistinction, 'name');
            setDistinctionCategories(dataAllDistinction);
            //data type admission paes
            let dataTypePaes: Array<IGeneralParameter>  = await getTypeAdmissionPAES();
            dataTypePaes = sortByKey(dataTypePaes, 'name');
            setTypeAdmissionPAES(dataTypePaes);
            // data user
            if(userContext.userId && params.id === undefined){
                const responseUser = await getUserPersonalInfo(userToken, parseInt(userContext.userId));
                if(responseUser.status === 200){
                    const resultUser = await responseUser.json();
                    const userInfo = new IPersonalInfoCV(resultUser);
                    if(userInfo.distinctions.length>0){
                        userInfo.distinctions.map(item=>item.yearDateFormat=new Date((item.year?.toString() + "-01-31")))
                    }
                    setFormValues(userInfo);
                    //image user
                    if(formValues.personalIdentificationData.files?.length===0){
                        if(userInfo.personalIdentificationData.photo && userInfo.personalIdentificationData.photo.length>0){
                            const url = userInfo.personalIdentificationData.photo;
                            const fileName = 'Egresado_foto_perfil.jpg';
                            fetch(url)
                            .then(async response => {
                                const blob = await response.blob()
                                const file = new File([blob], fileName, {type: "image/jpeg"});
                                if(file){
                                    setFormValues(prev => ({
                                        ...prev,
                                        personalIdentificationData: {
                                            ...prev.personalIdentificationData,
                                            files: [{file:file, previewUrl : URL.createObjectURL(file), description: ''}]
                                        }
                                    }));
                                }
                            })
                        }
                    }
                    const docType = dataDocType.find(item=>item.parameter_code === userInfo.personalIdentificationData.documentType);
                    if(docType){
                      setDocTypeUser(docType);
                    }
                    
                    setInitialValues(false);
                }
            }
            else if(params.id){
                const responseUser = await getUserPersonalInfo(userToken,params.id);
                if(responseUser.status === 200){
                    const resultUser = await responseUser.json();
                    const userInfo = new IPersonalInfoCV(resultUser);
                    
                    if(userInfo.distinctions.length>0){
                        userInfo.distinctions.map(item=>item.yearDateFormat=new Date((item.year?.toString() + "-01-31")))
                    }
                    setFormValues(userInfo);
                    //image user
                    if(formValues.personalIdentificationData.files?.length===0){
                        if(userInfo.personalIdentificationData.photo && userInfo.personalIdentificationData.photo.length>0){
                            const url = userInfo.personalIdentificationData.photo;
                            const fileName = 'Egresado_foto_perfil.jpg';
                            fetch(url)
                            .then(async response => {
                                const blob = await response.blob()
                                const file = new File([blob], fileName, {type: "image/jpeg"});
                                if(file){
                                    setFormValues(prev => ({
                                        ...prev,
                                        personalIdentificationData: {
                                            ...prev.personalIdentificationData,
                                            files: [{file:file, previewUrl : URL.createObjectURL(file), description: ''}]
                                        }
                                    }));
                                }
                            })
                        }
                    }
                    const docType = dataDocType.find(item=>item.parameter_code === userInfo.personalIdentificationData.documentType);
                    if(docType){
                      setDocTypeUser(docType);
                    }
                    
                    setInitialValues(false);
                }
            }
            
            setInitLoad(false);
        }
        getInitialData();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        if(!initialValues){
            setFormValues(prev => ({
                ...prev,
                personalIdentificationData: {
                    ...prev.personalIdentificationData,
                    residenceDepartment:0,
                    residenceCity: 0
                }
            }));
        }
        if(formValues.personalIdentificationData.residenceCountry>0){
            if(formValues.personalIdentificationData.residenceCountry === 170){
                setCitiesArray([]);
            } else {
                const countryObjSelected = countryCitiesArray.find(country=>country.id === formValues.personalIdentificationData.residenceCountry);
                if(countryObjSelected){
                    setCitiesArray(countryObjSelected.childs);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formValues.personalIdentificationData.residenceCountry])

    useEffect(()=>{
        if(!initialValues){
            setFormValues(prev => ({
                ...prev,
                personalIdentificationData: {
                    ...prev.personalIdentificationData,
                    residenceCity: 0
                }
            }));
        }
        if(formValues.personalIdentificationData.residenceDepartment>0){
            let citiesDep:Array<IGeneralParameter> = cityArray.filter(city=> city.parentId === formValues.personalIdentificationData.residenceDepartment);
            citiesDep = sortByKey(citiesDep, 'name');
            if(citiesDep.length>0){
                setCitiesArray(citiesDep)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formValues.personalIdentificationData.residenceDepartment])
    
    useEffect(()=>{
        if(formValues.personalIdentificationData.residenceCountry !== (0 || 170) ){
            setFormValues(prev => ({
                ...prev,
                personalIdentificationData: {
                    ...prev.personalIdentificationData,
                    residenceDepartment: 9999,
                    residenceCity: 9999
                }
            }));
        }
    },[formValues.personalIdentificationData.residenceCity, formValues.personalIdentificationData.residenceDepartment ])

    useEffect(()=>{
        if(!initialValues){
            setFormValues(prev => ({
                ...prev,
                personalIdentificationData: {
                    ...prev.personalIdentificationData,
                    birthDepartment:0,
                    birthCity: 0
                }
            }));
        }
        if(formValues.personalIdentificationData.birthCountry>0){
            if(formValues.personalIdentificationData.birthCountry === 170){
                setBirthCitiesArray([]);
            } else {
                const countryObjSelected = countryCitiesArray.find(country=>country.id === formValues.personalIdentificationData.birthCountry);
                if(countryObjSelected){
                    setBirthCitiesArray(countryObjSelected.childs);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formValues.personalIdentificationData.birthCountry])

    useEffect(()=>{
        if(!initialValues){
            setFormValues(prev => ({
                ...prev,
                personalIdentificationData: {
                    ...prev.personalIdentificationData,
                    birthCity: 0
                }
            }));
        }
        if(formValues.personalIdentificationData.birthDepartment>0){
            let citiesDep:Array<IGeneralParameter> = cityArray.filter(city=> city.parentId === formValues.personalIdentificationData.birthDepartment);
            citiesDep = sortByKey(citiesDep, 'name');
            if(citiesDep.length>0){
                setBirthCitiesArray(citiesDep)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formValues.personalIdentificationData.birthDepartment])
    
    useEffect(()=>{
        if(formValues.personalIdentificationData.birthCountry !== (0 || 170) ){
            setFormValues(prev => ({
                ...prev,
                personalIdentificationData: {
                    ...prev.personalIdentificationData,
                    birthDepartment:9999,
                    birthCity: 9999
                }
            }));
        }
    },[formValues.personalIdentificationData.birthCity, formValues.personalIdentificationData.birthDepartment ])

    useEffect(()=>{
        if(!initialValues){
            setFormValues(prev => ({
                ...prev,
                personalIdentificationData: {
                    ...prev.personalIdentificationData,
                    urlSocialnetwork: ""
                }
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formValues.personalIdentificationData.socialnetwork])

    useEffect(()=>{
        if(!initialValues){
            setFormValues(prev => ({
                ...prev,
                personalIdentificationData: {
                    ...prev.personalIdentificationData,
                    paesProgram: 0,
                    initialCampusPeama: 0,
                    finalCampusPeama: 0
                }
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formValues.personalIdentificationData.nameSpecialAdmissionProgram])

    useEffect(()=>{
        if(!initialValues){
            setFormValues(prev => ({
                ...prev,
                personalIdentificationData: {
                    ...prev.personalIdentificationData,
                    nameSpecialAdmissionProgram: 0,
                    paesProgram: 0,
                    initialCampusPeama: 0,
                    finalCampusPeama: 0
                }
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formValues.personalIdentificationData.specialAdmissionProgram])

    const handleChangeAccoridon = (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        if(name.includes('personalIdentificationData')){
            const propName = (name.split('.'))[1];
            setFormValues(prev => ({
                ...prev,
                personalIdentificationData: {
                    ...prev.personalIdentificationData,
                    [propName]:checked
                }
            }));
        }
    };

    const changeCheckBoxContact = (e: React.ChangeEvent<HTMLInputElement>, idReferent:number | null) => {
        const refAdjusted = formValues.referents.map(ref => {
            if(ref.id === idReferent){
                ref.visble = !ref.visble;
            }
            return ref;
        });
        setFormValues(prev => ({
            ...prev,
            referents: refAdjusted
        }));
    }

    const handleChangeForm = (e: any) => {
        const { name, value } = e.target;
        if(name.includes('personalIdentificationData')){
            const propName = (name.split('.'))[1];
            setFormValues(prev => ({
                ...prev,
                personalIdentificationData: {
                    ...prev.personalIdentificationData,
                    [propName]:value
                }
            }));
            const error: string = Validations(propName, value, formValidations) || "";
            setFormErrors(prev=>({
              ...prev,
              [propName]: error
            }));
        }
        if (name === 'files'){
            if(formValues.personalIdentificationData.files && formValues.personalIdentificationData.files.length<1 && e.target.files.length>0){
                if(e.target.files[0].type === 'image/jpeg'){
                    setFormValues((prev:any) => ({
                        ...prev,
                        personalIdentificationData: {
                            ...prev.personalIdentificationData,
                            [name]: [{file:e.target.files[0], previewUrl : URL.createObjectURL(e.target.files[0])}]
                        }
                    }));
                    setFormErrors((prev:any)=>({
                      ...prev,
                      fileError: ''}
                    ));
                } else {
                    setFormErrors((prev:any)=>({
                      ...prev,
                      fileError: 'Solo puede subir imagenes en formato jpg'
                    }));
                }
            } else {
                if(e.target.files.length>0){
                  setFormErrors((prev:any)=>({
                      ...prev,
                      fileError: 'No puede subir mas archivos, si desea cambiar o eliminar la imagen, utilice el botón eliminar ubicado en la parte superior izquierda de la imagen y cargue de nuevo otra imagen'
                  }));
                }
            }
        }
    }

    const handleChangeDate = (dateValue: Date | null, nameField: string) => {
        const value =dateValue ? moment(dateValue).format("YYYY-MM-DD") : '';
        
        if(nameField.includes('personalIdentificationData')){
            const propName = (nameField.split('.'))[1];
            setFormValues(prev => ({
                ...prev,
                personalIdentificationData: {
                    ...prev.personalIdentificationData,
                    [propName]:value
                }
            }));
            const error: string = Validations(propName, value, formValidations) || "";
            setFormErrors((prev:any)=>({
              ...prev,
              [propName]: error
            }));
        }
    }

    const changeSpecialForm = (e: any, pattern: string, nameError: string) => {
        const { name, value } = e.target;
        if(pattern === "distinction"){
            setDistinctionForm(prev => ({
                ...prev,
                [name]:value
            }));
        } else if (pattern === "family"){
            setFamilyForm(prev => ({
                ...prev,
                [name]:value
            }));
        } else if (pattern === "contact"){
            setContactForm(prev => ({
                ...prev,
                [name]:value
            }));
        }
        const error: string = Validations(nameError, value, formValidations) || "";
        setFormErrors((prev:any)=>({
          ...prev,
          [nameError]: error
        }));
    }

    const changeDistictionDate = (dateValue: Date | null, nameField: string) => {
        const value =dateValue ? dateValue?.toString() : '';
        setDistinctionForm(prev => ({
            ...prev,
            [nameField]: dateValue
        }));
        const error: string = Validations(nameField, value, formValidations) || "";
        setFormErrors((prev:any)=>({
          ...prev,
          [nameField]: error
        }));
    }

    const changeFamiliarDate = (dateValue: Date | null, nameField: string) => {
        const value =dateValue ? dateValue?.toString() : '';
        setFamilyForm(prev => ({
            ...prev,
            [nameField]: dateValue
        }));
        const error: string = Validations(nameField, value, formValidations) || "";
        setFormErrors(prev=>({
          ...prev,
          [nameField]: error
        }));
    }

    const handleDeleteFile = ()=>{
        setFormValues((prev:any) => ({
            ...prev,
            personalIdentificationData: {
                ...prev.personalIdentificationData,
                files: []
            }
        }));
        setFormErrors((prev:any)=>({
          ...prev,
          fileError: ''
        }));
    }

    const addDistinction = () => {
        distinctionForm.id=formValues.distinctions.length+1;
        distinctionForm.year = parseInt(moment(distinctionForm.yearDateFormat).format("YYYY-MM-DD"))
        setFormValues(prev => ({
            ...prev,
            distinctions:[...prev.distinctions, distinctionForm]
        }));
        setDistinctionForm(new IDistinctions());
    }

    const deleteDistinction = (e:any, idToDelete: number | null) => {
        const newArrayDistinction = formValues.distinctions.filter(item=>item.id !== idToDelete);
        setFormValues(prev => ({
            ...prev,
            distinctions:newArrayDistinction
        }));
    }

    const addChild = () => {
        familyForm.id=formValues.familyInformation.childrens.length+1;
        familyForm.birthdayYear = moment(familyForm.birthdayYearDateFormat).format("YYYY-MM-DD")
        setFormValues(prev => ({
            ...prev,
            familyInformation:{
                ...prev.familyInformation,
                childrens: [...prev.familyInformation.childrens, familyForm]
            }
        }));
        setFamilyForm(new IChildrenInfo());
    }

    const deleteChild = (e:any, idToDelete: number | null) => {
        const newArrayChild = formValues.familyInformation.childrens.filter(item=>item.id !== idToDelete);
        setFormValues(prev => ({
            ...prev,
            familyInformation:{
                ...prev.familyInformation,
                childrens: newArrayChild
            }
        }));
    }

    const addContact = () =>{
        contactForm.id=formValues.referents.length+1;
        setFormValues(prev => ({
            ...prev,
            referents: [...prev.referents, contactForm]
        }));
        setContactForm(new IReferent());
    }

    const deleteContact = (e:any, idToDelete: number | null) => {
        const newArrayReferents = formValues.referents.filter(item=>item.id !== idToDelete);
        setFormValues(prev => ({
            ...prev,
            referents:newArrayReferents
        }));
    }

    const savePersonalInfo = async() => {
        setInitLoad(true);
        let urlImagePublic = "";
        delete formValues.personalIdentificationData.createdAt;
        delete formValues.personalIdentificationData.updatedAt;
        formValues.distinctions.forEach(distinction=>{
            delete distinction.id;
            delete distinction.yearDateFormat;
            delete distinction.graduateId;
        });
        formValues.familyInformation.childrens.forEach(child=>{
            delete child.id;
            delete child.birthdayYearDateFormat;
            delete child.graduateId;
        })
        formValues.referents.forEach(ref=>{
            delete ref.id;
            delete ref.graduateId;
        })
        if(formValues.personalIdentificationData.files && formValues.personalIdentificationData.files.length >0 && formValues.personalIdentificationData.files[0].file){
            const fileToUpload: IPreSignedUrlReq = {
              contentType: "image/jpeg",
              fileKey: 'Egresado_foto_perfil.jpg',
              folderId: `Egresado-${formValues.personalIdentificationData.doc}`,
              description: "",
              isPublic: true,
            }
            const resultURL: IPresignedUrl = await getSignUrl(fileToUpload);
            if(resultURL){
                const urlPresigned = resultURL.uploadURL;
                urlImagePublic = urlPresigned.split("?")[0];
                const file: File = formValues.personalIdentificationData.files[0].file;
                await uploadFileSignedUrl(urlPresigned, file);
            }
        }

        const objToUpdate = formValues;
        objToUpdate.personalIdentificationData.photo = urlImagePublic;
        
        const responsePersonalInfo:any = await updatePersonalInfo(
            userToken,
            {...objToUpdate, modifyUser: userContext.userId}
        );
        console.dir({...objToUpdate, modifyUser: userContext.userId})
        
        if(responsePersonalInfo.status === 200){
            setSuccessMessage("Se ha guardado correctamente la información")
            if(userContext.userId && params.id === undefined){
                await trackPromise(userContext.handleSetUserPercentage(userContext.userId));
            }else if(params.id){
                await trackPromise(userContext.handleSetUserPercentage(params.id));
            }
            setOpenModalSuccess(true);
            setOpenModalError(false);
        } else{
            
            setErrorMessage("Hubo un error, intente de nuevo mas tarde");
            setOpenModalError(true);
            setOpenModalSuccess(false);
        }

        
        
        setInitLoad(false);
    }

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
    };

    const confirmSend = async()=>{
        setOpenModalConfirm(true);
    }
    const closeModalConfirm = () =>{
        setOpenModalConfirm(false);
    }

    const nextBtnAction = () =>{
        setOpenModalConfirm(false);
        userContext.setStateCurriculumSection("Información académica");
    }
        
    return (
        <>
            <Grid item xs={12} lg={10} className="curriculum-form-info-container">
                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccoridon('panel1')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                        >
                            <Typography variant="h2" className="summary-title bold-serif">Fotografía para la hoja de vida</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row">
                                 <Grid container item xs={12} className="photography-cv-container">
                                    <Grid container>
                                        <Typography variant="h5" className="description-documents-text regular-serif">Para adjuntar una fotografía de clic en el botón "Adjuntar archivo" y seleccione la imagen que desea adjuntar. La imagen debe estar en formato jpg, debe tener dimensiones de XX pixeles de ancho y XX pixeles de alto, y tener un peso máximo de XX Mb.</Typography>
                                        <Typography style={{color: 'red', fontSize: '14px', marginTop:'8px'}}>{formErrors.fileError}</Typography>
                                    </Grid>
                                    <Grid container item xs={12}>
                                        {formValues.personalIdentificationData.files && formValues.personalIdentificationData.files.length>0 &&
                                        formValues.personalIdentificationData.files.map((file: IFile, index)=>(
                                            <Grid key={index} container item xs={11} md={3} className="card-photography-container">
                                                <div className="card-header-container">
                                                    <Typography variant="h6" className="bold-serif card-title-item">
                                                        Egresado foto perfil.jpg
                                                    </Typography>
                                                    <IconButton style={{padding:0, height: '30px'}} onClick={handleDeleteFile}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                                <Grid container item xs={12} className="card-desc-container">
                                                    <img src={file.previewUrl} alt="imagen perfil" className="curriculum-photography"/>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Grid container item xs={12} justify="flex-start">
                                        <div className="upload-button-container">
                                            <Button variant="contained" component="label" className="upload-button">
                                                <Typography variant="h5" className="regular-serif">
                                                    Adjuntar archivo
                                                </Typography>
                                                <div>
                                                    <input type="file" name="files" onChange={handleChangeForm} accept="image/jpeg" hidden/>
                                                </div>
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccoridon('panel2')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                            className="accordion-summary"
                            >
                            <Typography variant="h2" className="summary-title bold-serif">Datos personales de identificación</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row">
                                <Grid container item xs={12} className="information-box-margin">
                                    <Grid container item xs={12} alignItems="flex-start">
                                        {userContext.roleSelected === 4 || userContext.roleSelected === 5?
                                            <>
                                            <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                                <Typography variant="h5" className="bold-serif title-box-text">Nombres</Typography>
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-select-names"
                                                        name="personalIdentificationData.names"
                                                        label={formValues.personalIdentificationData.names === "" ? "Ingrese sus nombres": ""}
                                                        value={formValues.personalIdentificationData.names === "" ? "" : formValues.personalIdentificationData.names}
                                                        onChange={handleChangeForm}
                                                        error={!!formErrors.names}
                                                        helperText={formErrors.names}
                                                        InputLabelProps={{shrink: false}}
                                                        margin="none"
                                                        variant="outlined"
                                                    >
                                                    </TextField>                                          
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                                <Typography variant="h5" className="bold-serif title-box-text">Primer apellido</Typography>
                                                    <TextField
                                                            fullWidth
                                                            id="outlined-select-surname"
                                                            name="personalIdentificationData.surname"
                                                            label={formValues.personalIdentificationData.surname === "" ? "Ingrese su primer apellido": ""}
                                                            value={formValues.personalIdentificationData.surname === "" ? "" : formValues.personalIdentificationData.surname}
                                                            onChange={handleChangeForm}
                                                            error={!!formErrors.surname}
                                                            helperText={formErrors.surname}
                                                            InputLabelProps={{shrink: false}}
                                                            margin="none"
                                                            variant="outlined"
                                                        >
                                                    </TextField>
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-left">
                                                <Typography variant="h5" className="bold-serif title-box-text">Segundo apellido</Typography>
                                                <TextField
                                                        fullWidth
                                                        id="outlined-select-secondsurname"
                                                        name="personalIdentificationData.secondsurname"
                                                        label={formValues.personalIdentificationData.secondsurname === "" ? "Ingrese su segundo apellido": ""}
                                                        value={formValues.personalIdentificationData.secondsurname === "" ? "" : formValues.personalIdentificationData.secondsurname}
                                                        onChange={handleChangeForm}
                                                        error={!!formErrors.secondsurname}
                                                        helperText={formErrors.secondsurname}
                                                        InputLabelProps={{shrink: false}}
                                                        margin="none"
                                                        variant="outlined"
                                                    >
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                                <Typography variant="h5" className="bold-serif title-box-text">Tipo de identificación</Typography>
                                                <TextField
                                                    fullWidth
                                                    id="outlined-select-documentType"
                                                    select
                                                    name="personalIdentificationData.documentType"
                                                    label={formValues.personalIdentificationData.documentType === 0 ? "Seleccione": ""}
                                                    value={formValues.personalIdentificationData.documentType === 0 ? "" : formValues.personalIdentificationData.documentType}
                                                    onChange={handleChangeForm}
                                                    InputLabelProps={{shrink: false}}
                                                    margin="none"
                                                    variant="outlined"
                                                >
                                                    {
                                                    documentsType && documentsType.length> 0 ?
                                                    documentsType.map((option: IGeneralParameter) => (
                                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))
                                                    :
                                                    <MenuItem value={formValues.personalIdentificationData.documentType}></MenuItem>
                                                    }
                                                </TextField>
                                            </Grid>
                                            </>
                                        :
                                            <>
                                            <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                                <Typography variant="h5" className="bold-serif title-box-text">Nombres</Typography>
                                                <Typography variant="h5" className="section-preview-title">
                                                    {formValues.personalIdentificationData.names}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                                <Typography variant="h5" className="bold-serif title-box-text">Primer apellido</Typography>
                                                <Typography variant="h5" className="section-preview-title">
                                                    {formValues.personalIdentificationData.surname}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-left">
                                                <Typography variant="h5" className="bold-serif title-box-text">Segundo apellido</Typography>
                                                <Typography variant="h5" className="section-preview-title">
                                                    {formValues.personalIdentificationData.secondsurname}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                                <Typography variant="h5" className="bold-serif title-box-text">Tipo de identificación</Typography>
                                                <Typography variant="h5" className="section-preview-title">
                                                    {docTypeUser && docTypeUser.name}
                                                </Typography>
                                            </Grid>
                                            </>
                                        }
                                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                            <Typography variant="h5" className="bold-serif title-box-text">Número de identificación</Typography>
                                            <Typography variant="h5" className="section-preview-title">
                                                {formValues.personalIdentificationData.doc}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Divider className={classes.divider} />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} >
                                        <Grid item xs={12} sm={6} lg={3} className="information-box-section special-box-right">
                                            <Typography variant="h5" className="bold-serif title-box-text">Sexo*</Typography>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-gender"
                                                select
                                                name="personalIdentificationData.gender"
                                                label={formValues.personalIdentificationData.gender === 0 ? "Seleccione": ""}
                                                value={formValues.personalIdentificationData.gender === 0 ? "" : formValues.personalIdentificationData.gender}
                                                onChange={handleChangeForm}
                                                error={!!formErrors.gender}
                                                helperText={formErrors.gender}
                                                InputLabelProps={{shrink: false}}
                                                margin="none"
                                                variant="outlined"
                                            >
                                                {gendersType.length> 0 ?
                                                    gendersType.map((option: IGeneralSIA) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))
                                                :
                                                    <MenuItem value={formValues.personalIdentificationData.gender}></MenuItem>
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3} className="information-box-section special-box-both">
                                            <Typography variant="h5" className="bold-serif title-box-text">Estrato*</Typography>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-stratum"
                                                select
                                                name="personalIdentificationData.stratum"
                                                label={formValues.personalIdentificationData.stratum === 0 ? "Seleccione": ""}
                                                value={formValues.personalIdentificationData.stratum === 0 ? "" : formValues.personalIdentificationData.stratum}
                                                onChange={handleChangeForm}
                                                error={!!formErrors.stratum}
                                                helperText={formErrors.stratum}
                                                InputLabelProps={{shrink: false}}
                                                margin="none"
                                                variant="outlined"
                                            >
                                                {economicLevel && economicLevel.length>0 ?
                                                    economicLevel.map((option: number) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))
                                                :
                                                    <MenuItem value={formValues.personalIdentificationData.stratum}></MenuItem>
                                                }
                                            </TextField>
                                        </Grid>
                                        {userContext.roleSelected !== 1?
                                        <>
                                        <Grid container item xs={12} sm={6} lg={2} className="title-box-text special-box-both checkbox-top-curriculum-container">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                    checked={formValues.personalIdentificationData.deceased}
                                                    onChange={handleChangeCheckBox}
                                                    name="personalIdentificationData.deceased"
                                                    />}
                                                label="Fallecido"
                                                labelPlacement="top"
                                                className="checkbox-top"
                                            />
                                        </Grid>
                                        </>
                                        :
                                        <></>
                                        }
                                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-left">
                                            <Typography variant="h5" className="bold-serif title-box-text">Grupo étnico*</Typography>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-ethnicgroup"
                                                select
                                                name="personalIdentificationData.ethnicgroup"
                                                label={formValues.personalIdentificationData.ethnicgroup === 0 ? "Seleccione": ""}
                                                value={formValues.personalIdentificationData.ethnicgroup === 0 ? "" : formValues.personalIdentificationData.ethnicgroup}
                                                onChange={handleChangeForm}
                                                error={!!formErrors.ethnicgroup}
                                                helperText={formErrors.ethnicgroup}
                                                InputLabelProps={{shrink: false}}
                                                margin="none"
                                                variant="outlined"
                                            >
                                                {ethnicGroup && ethnicGroup.length>0 ?
                                                    ethnicGroup.map((option: IGeneralParameter) => (
                                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))
                                                :
                                                    <MenuItem value={formValues.personalIdentificationData.ethnicgroup}></MenuItem>
                                                }
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    {/* LUGAR DE NACIMIENTO */}
                                    {/* <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                        <Grid container  item xs={12} className="card-information-container">
                                            <Grid item xs={12}>
                                                <Typography variant="h5" className="bold-serif title-box-text">Lugar de nacimiento</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="outlined-select-birthplace"
                                                    name="personalIdentificationData.birthplace"
                                                    label={formValues.personalIdentificationData.birthplace === "" ? "Ingrese su lugar de nacimiento": ""}
                                                    value={formValues.personalIdentificationData.birthplace === "" ? "" : formValues.personalIdentificationData.birthplace}
                                                    onChange={handleChangeForm}
                                                    error={!!formErrors.birthplace}
                                                    helperText={formErrors.birthplace}
                                                    InputLabelProps={{shrink: false}}
                                                    margin="none"
                                                    variant="outlined"
                                                >
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Divider className={classes.divider} />
                                            </Grid>
                                            <Grid container item xs={12} alignItems="center" className="visibility-curriculum-container">
                                                <VisibilityIcon/>
                                                <FormControlLabel
                                                    value={formValues.personalIdentificationData.birthplaceVisible}
                                                    control={
                                                        <BlueSwitch
                                                            size="small"
                                                            checked={formValues.personalIdentificationData.birthplaceVisible}
                                                            onChange={handleChangeCheckBox}
                                                            name="personalIdentificationData.birthplaceVisible"
                                                        />
                                                    }
                                                    label="Mostrar esta información en su hoja de vida"
                                                    labelPlacement="start"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                    <Grid item xs={12} sm={6}  className="information-box-section special-box-right">
                                        <Typography variant="h5" className="bold-serif title-box-text">País de nacimiento*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-BirthCountry"
                                            select
                                            name="personalIdentificationData.birthCountry"
                                            label={formValues.personalIdentificationData.birthCountry === 0 ? "Seleccione": ""}
                                            value={formValues.personalIdentificationData.birthCountry === 0 ? "" : formValues.personalIdentificationData.birthCountry}
                                            onChange={handleChangeForm}
                                            error={!!formErrors.birthCountry}
                                            helperText={formErrors.birthCountry}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {countryCitiesArray && countryCitiesArray.length>0 ?
                                            countryCitiesArray.map((option: IListParametersInfo) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.grandParentName}
                                                </MenuItem>
                                            ))
                                            :
                                                <MenuItem value={formValues.personalIdentificationData.birthCountry}></MenuItem>
                                            }
                                        </TextField>
                                    
                                    </Grid>
                                    <Grid item xs={12} sm={6}  className="information-box-section special-box-left">
                                        <Typography variant="h5" className="bold-serif title-box-text">Departamento de nacimiento*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-BirthDepartament"
                                            select
                                            name="personalIdentificationData.birthDepartment"
                                            label={formValues.personalIdentificationData.birthDepartment === 0 ? "Seleccione": ""}
                                            value={formValues.personalIdentificationData.birthDepartment === 0 ? "" : formValues.personalIdentificationData.birthDepartment}
                                            onChange={handleChangeForm}
                                            error={!!formErrors.birthDepartment}
                                            helperText={formErrors.birthDepartment}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                            disabled={(departmentArray && formValues.personalIdentificationData.birthCountry>0 && formValues.personalIdentificationData.birthCountry === 170 ? false : true)}
                                        >
                                            {departmentArray && departmentArray.length>0 ?
                                                departmentArray.map((option: IGeneralParameter) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                    {option.name}
                                                    </MenuItem>
                                                ))
                                            :
                                                <MenuItem value={formValues.personalIdentificationData.birthDepartment}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}  className="information-box-section special-box-right">
                                        <Typography variant="h5" className="bold-serif title-box-text">Municipio de nacimiento*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-birthCity"
                                            select
                                            name="personalIdentificationData.birthCity"
                                            label={formValues.personalIdentificationData.birthCity === 0 ? "Seleccione": ""}
                                            value={formValues.personalIdentificationData.birthCity === 0 ? "" : formValues.personalIdentificationData.birthCity}
                                            onChange={handleChangeForm}
                                            error={!!formErrors.birthCity}
                                            helperText={formErrors.birthCity}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                            disabled={(birthCitiesArray && ((formValues.personalIdentificationData.birthCountry === 170 && formValues.personalIdentificationData.birthDepartment >0) || (formValues.personalIdentificationData.birthCountry !== 170 && formValues.personalIdentificationData.birthCountry > 0)) ? false : true)}
                                        >
                                            {birthCitiesArray && birthCitiesArray.length>0 ?
                                                birthCitiesArray.map((option: IGeneralParameter) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                    {option.name}
                                                    </MenuItem>
                                                ))
                                            :
                                                <MenuItem value={formValues.personalIdentificationData.birthCity}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                        <Grid container item xs={12} className="card-information-container">
                                            <Grid item xs={12}>
                                                <Typography variant="h5" className="bold-serif title-box-text">Fecha de nacimiento*</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                                    <DatePicker
                                                        showToolbar={false}
                                                        disableFuture
                                                        openTo="year"
                                                        views={['year', 'month', 'date']}
                                                        maxDate={new Date()}
                                                        value={formValues.personalIdentificationData.birthdate || null}
                                                        onChange={(value) => handleChangeDate(value, 'personalIdentificationData.birthdate')}
                                                        renderInput={(params) => (
                                                            <TextField
                                                            disabled
                                                            {...params}
                                                            fullWidth
                                                            name="personalIdentificationData.birthdate"
                                                            margin="none"
                                                            label={(formValues.personalIdentificationData.birthdate && formValues.personalIdentificationData.birthdate !== null )? "": "Seleccione Fecha dd/mm/aaaa"}
                                                            error={!!formErrors.birthdate}
                                                            helperText={formErrors.birthdate}
                                                            InputLabelProps={{shrink: false}}
                                                            variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Divider className={classes.divider} />
                                            </Grid>
                                            <Grid container item xs={12} alignItems="center" className="visibility-curriculum-container">
                                                <VisibilityIcon/>
                                                <FormControlLabel
                                                    value={formValues.personalIdentificationData.birthdateVisible}
                                                    control={
                                                        <BlueSwitch
                                                            size="small"
                                                            checked={formValues.personalIdentificationData.birthdateVisible}
                                                            onChange={handleChangeCheckBox}
                                                            name="personalIdentificationData.birthdateVisible"
                                                        />
                                                    }
                                                    label="Mostrar esta información en su hoja de vida"
                                                    labelPlacement="start"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                        <Grid container  item xs={12} className="card-information-container">
                                            <Grid item xs={12} >
                                                <Typography variant="h5" className="bold-serif title-box-text">Estado civil*</Typography>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    fullWidth
                                                    id="outlined-select-maritalStatus"
                                                    select
                                                    name="personalIdentificationData.maritalStatus"
                                                    label={formValues.personalIdentificationData.maritalStatus === 0 ? "Seleccione": ""}
                                                    value={formValues.personalIdentificationData.maritalStatus === 0 ? "" : formValues.personalIdentificationData.maritalStatus}
                                                    onChange={handleChangeForm}
                                                    error={!!formErrors.maritalStatus}
                                                    helperText={formErrors.maritalStatus}
                                                    InputLabelProps={{shrink: false}}
                                                    margin="none"
                                                    variant="outlined"
                                                >
                                                    {maritalArray && maritalArray.length>0 ?
                                                    maritalArray.map((option: IGeneralParameter) => (
                                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))
                                                    :
                                                        <MenuItem value={formValues.personalIdentificationData.maritalStatus}></MenuItem>
                                                    }
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Divider className={classes.divider} />
                                            </Grid>
                                            <Grid container item xs={12} alignItems="center" className="visibility-curriculum-container">
                                            <VisibilityIcon/>
                                                <FormControlLabel
                                                    value={formValues.personalIdentificationData.maritalStatusVisible}
                                                    control={
                                                        <BlueSwitch
                                                            size="small"
                                                            checked={formValues.personalIdentificationData.maritalStatusVisible}
                                                            onChange={handleChangeCheckBox}
                                                            name="personalIdentificationData.maritalStatusVisible"
                                                        />
                                                    }
                                                    label="Mostrar esta información en su hoja de vida"
                                                    labelPlacement="start"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="information-box-section special-box-left">
                                        <Typography variant="h5" className="bold-serif title-box-text">¿Tiene alguna discapacidad?</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-special_condition"
                                            select
                                            name="personalIdentificationData.impairment"
                                            label={formValues.personalIdentificationData.impairment === 0 ? "Seleccione": ""}
                                            value={formValues.personalIdentificationData.impairment === 0 ? "" : formValues.personalIdentificationData.impairment}
                                            onChange={handleChangeForm}
                                            error={!!formErrors.impairment}
                                            helperText={formErrors.impairment}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {
                                                disabilitiesArray && disabilitiesArray.length>0 ?
                                                disabilitiesArray.map((option: IGeneralParameter) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                            :
                                                <MenuItem value={formValues.personalIdentificationData.impairment}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}  className="information-box-section special-box-right">
                                        <Typography variant="h5" className="bold-serif title-box-text">País de residencia*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-Country"
                                            select
                                            name="personalIdentificationData.residenceCountry"
                                            label={formValues.personalIdentificationData.residenceCountry === 0 ? "Seleccione": ""}
                                            value={formValues.personalIdentificationData.residenceCountry === 0 ? "" : formValues.personalIdentificationData.residenceCountry}
                                            onChange={handleChangeForm}
                                            error={!!formErrors.residenceCountry}
                                            helperText={formErrors.residenceCountry}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {countryCitiesArray && countryCitiesArray.length>0 ?
                                            countryCitiesArray.map((option: IListParametersInfo) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.grandParentName}
                                                </MenuItem>
                                            ))
                                            :
                                                <MenuItem value={formValues.personalIdentificationData.residenceCountry}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}  className="information-box-section special-box-left">
                                        <Typography variant="h5" className="bold-serif title-box-text">Departamento de residencia*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-departament"
                                            select
                                            name="personalIdentificationData.residenceDepartment"
                                            label={formValues.personalIdentificationData.residenceDepartment === 0 ? "Seleccione": ""}
                                            value={formValues.personalIdentificationData.residenceDepartment === 0 ? "" : formValues.personalIdentificationData.residenceDepartment}
                                            onChange={handleChangeForm}
                                            error={!!formErrors.residenceDepartment}
                                            helperText={formErrors.residenceDepartment}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                            disabled={(departmentArray && formValues.personalIdentificationData.residenceCountry>0 && formValues.personalIdentificationData.residenceCountry === 170 ? false : true)}
                                        >
                                            {departmentArray && departmentArray.length>0 ?
                                                departmentArray.map((option: IGeneralParameter) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                    {option.name}
                                                    </MenuItem>
                                                ))
                                            :
                                                <MenuItem value={formValues.personalIdentificationData.residenceDepartment}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}  className="information-box-section special-box-right">
                                        <Typography variant="h5" className="bold-serif title-box-text">Municipio de residencia*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-residenceCity"
                                            select
                                            name="personalIdentificationData.residenceCity"
                                            label={formValues.personalIdentificationData.residenceCity === 0 ? "Seleccione": ""}
                                            value={formValues.personalIdentificationData.residenceCity === 0 ? "" : formValues.personalIdentificationData.residenceCity}
                                            onChange={handleChangeForm}
                                            error={!!formErrors.residenceCity}
                                            helperText={formErrors.residenceCity}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                            disabled={(citiesArray && ((formValues.personalIdentificationData.residenceCountry === 170 && formValues.personalIdentificationData.residenceDepartment >0) || (formValues.personalIdentificationData.residenceCountry !== 170 && formValues.personalIdentificationData.residenceCountry > 0)) ? false : true)}
                                        >
                                            {citiesArray && citiesArray.length>0 ?
                                                citiesArray.map((option: IGeneralParameter) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                    {option.name}
                                                    </MenuItem>
                                                ))
                                            :
                                                <MenuItem value={formValues.personalIdentificationData.residenceCity}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}  className="information-box-section special-box-left">
                                        <Typography variant="h5" className="bold-serif title-box-text">Dirección de residencia*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-personalIdentificationData-address"
                                            name="personalIdentificationData.address"
                                            label={formValues.personalIdentificationData.address === "" ? "Ingrese su dirección": ""}
                                            value={formValues.personalIdentificationData.address === "" ? "" : formValues.personalIdentificationData.address}
                                            onChange={handleChangeForm}
                                            error={!!formErrors.address}
                                            helperText={formErrors.address}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}  className="information-box-section special-box-right">
                                        <Typography variant="h5" className="bold-serif title-box-text">Número telefónico principal*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-cellphone"
                                            name="personalIdentificationData.cellphone"
                                            label={formValues.personalIdentificationData.cellphone === "" ? "Ingrese su número telefónico": ""}
                                            value={formValues.personalIdentificationData.cellphone === "" ? "" : formValues.personalIdentificationData.cellphone}
                                            onChange={handleChangeForm}
                                            error={!!formErrors.cellphone}
                                            helperText={formErrors.cellphone}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid container  item xs={12} sm={6} className="information-box-section special-box-left">
                                        <Grid container  item xs={12} className="card-information-container">
                                            <Grid item xs={12} >
                                                <Typography variant="h5" className="bold-serif title-box-text">Número telefónico adicional</Typography>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    fullWidth
                                                    id="outlined-select-additionalPhone"
                                                    name="personalIdentificationData.additionalPhone"
                                                    label={formValues.personalIdentificationData.additionalPhone === "" ? "Número telefónico adicional": ""}
                                                    value={formValues.personalIdentificationData.additionalPhone === "" ? "" : formValues.personalIdentificationData.additionalPhone}
                                                    onChange={handleChangeForm}
                                                    error={!!formErrors.additionalPhone}
                                                    helperText={formErrors.additionalPhone}
                                                    InputLabelProps={{shrink: false}}
                                                    margin="none"
                                                    variant="outlined"
                                                >
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Divider className={classes.divider} />
                                            </Grid>
                                            <Grid container item xs={12} alignItems="center" className="visibility-curriculum-container">
                                                <VisibilityIcon/>
                                                <FormControlLabel
                                                    value={formValues.personalIdentificationData.additionalPhoneVisible}
                                                    control={
                                                        <BlueSwitch
                                                            size="small"
                                                            checked={formValues.personalIdentificationData.additionalPhoneVisible}
                                                            onChange={handleChangeCheckBox}
                                                            name="personalIdentificationData.additionalPhoneVisible"
                                                        />
                                                    }
                                                    label="Mostrar esta información en su hoja de vida"
                                                    labelPlacement="start"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}  className="information-box-section special-box-right">
                                        <Typography variant="h5" className="bold-serif title-box-text">Correo electrónico principal</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-email"
                                            name="personalIdentificationData.email"
                                            label={formValues.personalIdentificationData.email === "" ? "Ingrese correo principal": ""}
                                            value={formValues.personalIdentificationData.email === "" ? "" : formValues.personalIdentificationData.email}
                                            onChange={handleChangeForm}
                                            error={!!formErrors.email}
                                            helperText={formErrors.email}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                        <Typography variant="h5" className="section-preview-title">
                                            <span className="link-responsive">{formValues.personalIdentificationData.email}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid container  item xs={12} sm={6} className="information-box-section special-box-left">
                                        <Typography variant="h5" className="bold-serif title-box-text">Correo electrónico adicional</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-additionalEmail"
                                            name="personalIdentificationData.additionalEmail"
                                            label={formValues.personalIdentificationData.additionalEmail === "" ? "Ingrese correo adicional": ""}
                                            value={formValues.personalIdentificationData.additionalEmail === "" ? "" : formValues.personalIdentificationData.additionalEmail}
                                            onChange={handleChangeForm}
                                            error={!!formErrors.additionalEmail}
                                            helperText={formErrors.additionalEmail}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>
                                     <Grid container  item xs={12} className="information-box-section">
                                        <Grid container item xs={12} className="card-information-container">
                                            <Grid item xs={12} className="special-box-right">
                                                <Typography variant="h5" className="bold-serif title-box-text">Redes sociales</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} className="special-box-right">
                                                <TextField
                                                    fullWidth
                                                    id="outlined-select-socialnetwork"
                                                    select
                                                    name="personalIdentificationData.socialnetwork"
                                                    label={formValues.personalIdentificationData.socialnetwork === 0 ? "Seleccione": ""}
                                                    value={formValues.personalIdentificationData.socialnetwork === 0 ? "" : formValues.personalIdentificationData.socialnetwork}
                                                    onChange={handleChangeForm}
                                                    error={!!formErrors.socialnetwork}
                                                    helperText={formErrors.socialnetwork}
                                                    InputLabelProps={{shrink: false}}
                                                    margin="none"
                                                    variant="outlined"
                                                >
                                                    {
                                                        networkArray && networkArray.length> 0 &&
                                                        <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                                    }
                                                    {networkArray.length> 0 ?
                                                        networkArray.map((option: IGeneralSIA) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))
                                                    :
                                                        <MenuItem value={formValues.personalIdentificationData.socialnetwork}></MenuItem>
                                                    }
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} sm={6}  className="special-box-left">
                                                <TextField
                                                    fullWidth
                                                    id="outlined-select-urlSocialnetwork"
                                                    name="personalIdentificationData.urlSocialnetwork"
                                                    label={formValues.personalIdentificationData.urlSocialnetwork === "" ? "Link de la red social": ""}
                                                    value={formValues.personalIdentificationData.urlSocialnetwork === "" ? "" : formValues.personalIdentificationData.urlSocialnetwork}
                                                    onChange={handleChangeForm}
                                                    error={!!formErrors.urlSocialnetwork}
                                                    helperText={formErrors.urlSocialnetwork}
                                                    InputLabelProps={{shrink: false}}
                                                    margin="none"
                                                    variant="outlined"
                                                    disabled={formValues.personalIdentificationData.socialnetwork >0 ? false : true}
                                                >
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Divider className={classes.divider} />
                                            </Grid>
                                            <Grid container item xs={12} alignItems="center" className="visibility-curriculum-container">
                                                <VisibilityIcon/>
                                                <FormControlLabel
                                                    value={formValues.personalIdentificationData.urlSocialnetworkVisible}
                                                    control={
                                                        <BlueSwitch
                                                            size="small"
                                                            checked={formValues.personalIdentificationData.urlSocialnetworkVisible}
                                                            onChange={handleChangeCheckBox}
                                                            name="personalIdentificationData.urlSocialnetworkVisible"
                                                        />
                                                    }
                                                    label="Mostrar esta información en su hoja de vida"
                                                    labelPlacement="start"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Divider className={classes.divider} />
                                    </Grid>
                                    <Grid item xs={6} className="special-box-right checkbox-curriculum-container" >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formValues.personalIdentificationData.specialAdmissionProgram}
                                                    onChange={handleChangeCheckBox}
                                                    name="personalIdentificationData.specialAdmissionProgram"
                                                />}
                                            label="¿Pertenece a algún programa de admisión especial?"
                                            labelPlacement="start"
                                            className="checkbox"
                                        />
                                    </Grid>
                                    <Grid item xs={6} className="special-box-right checkbox-curriculum-container" >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formValues.personalIdentificationData.conflictVictim}
                                                    onChange={handleChangeCheckBox}
                                                    name="personalIdentificationData.conflictVictim"
                                                />}
                                            label="¿Fue victima del conflicto armado?"
                                            labelPlacement="start"
                                            className="checkbox"
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="special-box-both">
                                        <AlertBox
                                            title="Programa PAES/PEAMA"
                                            alert="info"
                                            variantType="outlined"
                                        >
                                            {
                                                <span className="alert-simple-text">
                                                    El programa PAES/PEAMA aplica para estudiantes anteriores al 2003, si pertenece a una promoción posterior no marque esta casilla.
                                                </span>
                                            }
                                        </AlertBox>
                                    </Grid>
                                    {formValues.personalIdentificationData.specialAdmissionProgram &&
                                    <>
                                        <Grid item xs={12} sm={6}  className="information-box-section special-box-right">
                                            <Typography variant="h5" className="bold-serif title-box-text">Nombre del programa de admisión especial*</Typography>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-nameSpecialAdmissionProgram"
                                                select
                                                name="personalIdentificationData.nameSpecialAdmissionProgram"
                                                label={formValues.personalIdentificationData.nameSpecialAdmissionProgram === 0 ? "Seleccione": ""}
                                                value={formValues.personalIdentificationData.nameSpecialAdmissionProgram === 0 ? "" : formValues.personalIdentificationData.nameSpecialAdmissionProgram}
                                                onChange={handleChangeForm}
                                                error={!!formErrors.nameSpecialAdmissionProgram}
                                                helperText={formErrors.nameSpecialAdmissionProgram}
                                                InputLabelProps={{shrink: false}}
                                                margin="none"
                                                variant="outlined"
                                            >
                                                {specialAdmission && specialAdmission.length> 0 &&
                                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                                }
                                                {specialAdmission.length> 0 ?
                                                    specialAdmission.map((option: IGeneralParameter) => (
                                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))
                                                :
                                                    <MenuItem value={formValues.personalIdentificationData.nameSpecialAdmissionProgram}></MenuItem>
                                                }
                                            </TextField>
                                        </Grid>
                                        {formValues.personalIdentificationData.nameSpecialAdmissionProgram === 1 &&
                                            <Grid item xs={12} sm={6}  className="information-box-section special-box-left">
                                                <Typography variant="h5" className="bold-serif title-box-text">Tipo del Programa PAES*</Typography>
                                                <TextField
                                                    fullWidth
                                                    id="outlined-select-paesProgram"
                                                    select
                                                    name="personalIdentificationData.paesProgram"
                                                    label={formValues.personalIdentificationData.paesProgram === 0 ? "Seleccione": ""}
                                                    value={formValues.personalIdentificationData.paesProgram === 0 ? "" : formValues.personalIdentificationData.paesProgram}
                                                    onChange={handleChangeForm}
                                                    error={!!formErrors.paesProgram}
                                                    helperText={formErrors.paesProgram}
                                                    InputLabelProps={{shrink: false}}
                                                    margin="none"
                                                    variant="outlined"
                                                    className="typePaes"
                                                >
                                                    {typeAdmissionPAES && typeAdmissionPAES.length> 0 &&
                                                        <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                                    }
                                                    {typeAdmissionPAES.length> 0 ?
                                                        typeAdmissionPAES.map((option: IGeneralParameter) => (
                                                            <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))
                                                    :
                                                        <MenuItem value={formValues.personalIdentificationData.paesProgram}></MenuItem>
                                                    }
                                                </TextField>
                                            </Grid>
                                        }
                                        {formValues.personalIdentificationData.nameSpecialAdmissionProgram === 2 &&
                                            <>
                                                <Grid item xs={12} sm={6}  className="information-box-section special-box-left">
                                                    <Typography variant="h5" className="bold-serif title-box-text">Sede etapa incial PEAMA*</Typography>
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-select-initialCampusPeama"
                                                        select
                                                        name="personalIdentificationData.initialCampusPeama"
                                                        label={formValues.personalIdentificationData.initialCampusPeama === 0 ? "Seleccione": ""}
                                                        value={formValues.personalIdentificationData.initialCampusPeama === 0 ? "" : formValues.personalIdentificationData.initialCampusPeama}
                                                        onChange={handleChangeForm}
                                                        error={!!formErrors.initialCampusPeama}
                                                        helperText={formErrors.initialCampusPeama}
                                                        InputLabelProps={{shrink: false}}
                                                        margin="none"
                                                        variant="outlined"
                                                    >
                                                        {allCampus && allCampus.length> 0 ?
                                                            allCampus.map((option: IListParametersInfo) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    {option.parentName}
                                                                </MenuItem>
                                                            ))
                                                        :
                                                            <MenuItem value={formValues.personalIdentificationData.initialCampusPeama}></MenuItem>
                                                        }
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} sm={6}  className="information-box-section special-box-right">
                                                    <Typography variant="h5" className="bold-serif title-box-text">Sede etapa final PEAMA*</Typography>
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-select-finalCampusPeama"
                                                        select
                                                        name="personalIdentificationData.finalCampusPeama"
                                                        label={formValues.personalIdentificationData.finalCampusPeama === 0 ? "Seleccione": ""}
                                                        value={formValues.personalIdentificationData.finalCampusPeama === 0 ? "" : formValues.personalIdentificationData.finalCampusPeama}
                                                        onChange={handleChangeForm}
                                                        error={!!formErrors.finalCampusPeama}
                                                        helperText={formErrors.finalCampusPeama}
                                                        InputLabelProps={{shrink: false}}
                                                        margin="none"
                                                        variant="outlined"
                                                    >
                                                        {allCampus && allCampus.length> 0 ?
                                                            allCampus.map((option: IListParametersInfo) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    {option.parentName}
                                                                </MenuItem>
                                                            ))
                                                        :
                                                            <MenuItem value={formValues.personalIdentificationData.finalCampusPeama}></MenuItem>
                                                        }
                                                    </TextField>
                                                </Grid>
                                            </>
                                        }
                                    </>
                                    }
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccoridon('panel3')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                            className="accordion-summary"
                        >
                            <Typography variant="h2" className="summary-title bold-serif">Distinciones</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row">
                                <Grid container item xs={12} className="information-box-margin" alignItems="flex-start">
                                    <Grid container item xs={12} className="complementary-text-container">
                                        <Typography variant="h5" className="regular-serif">
                                            En está sección puede adicionar o eliminar sus distinciones, luego de adicionar debe Guardar los cambios.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="information-box-section special-box-right">
                                        <Typography variant="h5" className="bold-serif title-box-text">Distinciones recibidas*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-category"
                                            select
                                            name="category"
                                            label={distinctionForm.category === 0 ? "Seleccione": ""}
                                            value={distinctionForm.category === 0 ? "" : distinctionForm.category}
                                            onChange={(e)=>changeSpecialForm(e, "distinction", "distinctionCategory")}
                                            error={!!formErrors.distinctionCategory}
                                            helperText={formErrors.distinctionCategory}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {distinctionCategories && distinctionCategories.length> 0 &&
                                                <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                            }
                                            {distinctionCategories.length> 0 ?
                                                distinctionCategories.map((option: IGeneralParameter) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                            :
                                                <MenuItem value={distinctionForm.category}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="information-box-section special-box-left">
                                        <Typography variant="h5" className="bold-serif title-box-text">Año de nombramiento*</Typography>
                                        <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale} >
                                            <DatePicker
                                                showToolbar={false}
                                                disableFuture
                                                openTo="year"
                                                views={['year']}
                                                maxDate={new Date()}
                                                value={distinctionForm.yearDateFormat || null}
                                                onChange={(value)=>changeDistictionDate(value, 'yearDateFormat')}
                                                renderInput={(params) => (
                                                    <TextField
                                                        disabled
                                                        {...params}
                                                        fullWidth
                                                        name="yearDateFormat"
                                                        margin="none"
                                                        label={distinctionForm.yearDateFormat !==null ? "": "Año"}
                                                        error={!!formErrors.yearDateFormat}
                                                        helperText={formErrors.yearDateFormat}
                                                        InputLabelProps={{shrink: false}}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} className="information-box-section">
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={2}
                                            id="outlined-select-description"
                                            name="description"
                                            label={distinctionForm.description === "" ? "Descripción de la distinción*": ""}
                                            value={distinctionForm.description === "" ? "" : distinctionForm.description}
                                            onChange={(e)=>changeSpecialForm(e, "distinction", "distinctionDescription")}
                                            error={!!formErrors.distinctionDescription}
                                            helperText={formErrors.distinctionDescription}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} className="information-box-section special-box-left add-container">
                                        <Button disabled={!isValidAddDistinction} onClick={addDistinction} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                                            <Typography variant="h6" className="regular-serif">
                                                Adicionar
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Divider className={classes.divider} />
                                    </Grid>
                                    {formValues.distinctions.length>0 &&
                                        formValues.distinctions.map((distinction, index)=>{
                                            const categoryObj = distinctionCategories.find(item=>item.parameter_code === distinction.category);
                                            const categoryName = categoryObj ? categoryObj.name : "";
                                        return(
                                            <Grid key={index} container item xs={12} className="detail-add-container">
                                                <Grid item xs={12} md={8} lg={9}>
                                                    <Grid className="detail-add-description">
                                                        <Typography variant="h5" className="regular-serif">
                                                            {categoryName.length>0 &&
                                                                <span>{categoryName}</span>
                                                            }
                                                            <span>
                                                            - {moment(distinction.yearDateFormat).format("YYYY")}
                                                            </span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className="detail-add-description">
                                                        <Typography variant="h5" className="light-serif">
                                                            {distinction.description}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={4} lg={3}>
                                                    <div className="delete-container">
                                                        <Button onClick={(e)=>deleteDistinction(e, distinction.id ? distinction.id : null)} variant="contained" className="delete-btn" endIcon={<DeleteIcon/>} >
                                                            <Typography variant="h6" className="regular-serif">
                                                                Eliminar
                                                            </Typography>
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )})
                                    }
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChangeAccoridon('panel4')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                            className="accordion-summary"
                            >
                            <Typography variant="h2" className="summary-title bold-serif">Información familiar</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row">
                                <Grid container item xs={12} className="information-box-margin" alignItems="flex-start">
                                    <Grid container item xs={12} className="complementary-text-container">
                                        <Typography variant="h5" className="regular-serif">
                                            En está sección puede adicionar o eliminar su información familiar, luego de adicionar debe Guardar los cambios.
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={12} sm={6} className="special-box-right information-box-section">
                                        <Grid item xs={12} sm={6} lg={4} className="bold-serif title-box-text special-box-right checkbox-top-curriculum-container">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                    checked={formValues.personalIdentificationData.sonsAndDaughters}
                                                    onChange={handleChangeCheckBox}
                                                    name="personalIdentificationData.sonsAndDaughters"
                                                    />}
                                                label="¿Tiene hijos?"
                                                labelPlacement="top"
                                                className="checkbox-top"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} className="special-box-left information-box-section">
                                            <Typography variant="h5" className="bold-serif title-box-text">¿Cuántos?</Typography>
                                            <TextField
                                                fullWidth
                                                disabled={formValues.personalIdentificationData.sonsAndDaughters ? false : true}
                                                id="outlined-select-numSonsAndDaughters"
                                                select
                                                name="personalIdentificationData.numSonsAndDaughters"
                                                label={formValues.personalIdentificationData.numSonsAndDaughters === 0 ? "Seleccione": ""}
                                                value={formValues.personalIdentificationData.numSonsAndDaughters === 0 ? "" : formValues.personalIdentificationData.numSonsAndDaughters}
                                                onChange={handleChangeForm}
                                                error={!!formErrors.numSonsAndDaughters}
                                                helperText={formErrors.numSonsAndDaughters}
                                                InputLabelProps={{shrink: false}}
                                                margin="none"
                                                variant="outlined"
                                            >
                                                {childrenArray.length> 0 ?
                                                    childrenArray.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))
                                                :
                                                    <MenuItem value={formValues.personalIdentificationData.numSonsAndDaughters}></MenuItem>
                                                }
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Nombres*</Typography>
                                        <TextField
                                            fullWidth
                                            disabled={formValues.personalIdentificationData.sonsAndDaughters ? false : true}
                                            id="outlined-select-names"
                                            name="names"
                                            label={familyForm.names === "" ? "Ingrese Nombres": ""}
                                            value={familyForm.names === "" ? "" : familyForm.names}
                                            onChange={(e)=>changeSpecialForm(e, "family", "familyNames")}
                                            error={!!formErrors.familyNames}
                                            helperText={formErrors.familyNames}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Primer apellido*</Typography>
                                        <TextField
                                            fullWidth
                                            disabled={formValues.personalIdentificationData.sonsAndDaughters ? false : true}
                                            id="outlined-select-surname"
                                            name="surname"
                                            label={familyForm.surname === "" ? "Ingrese primer apellido": ""}
                                            value={familyForm.surname === "" ? "" : familyForm.surname}
                                            onChange={(e)=>changeSpecialForm(e, "family", "familySurname")}
                                            error={!!formErrors.familySurname}
                                            helperText={formErrors.familySurname}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Segundo apellido</Typography>
                                        <TextField
                                            fullWidth
                                            disabled={formValues.personalIdentificationData.sonsAndDaughters ? false : true}
                                            id="outlined-select-secondSurname"
                                            name="secondSurname"
                                            label={familyForm.secondSurname === "" ? "Ingrese segundo apellido": ""}
                                            value={familyForm.secondSurname === "" ? "" : familyForm.secondSurname}
                                            onChange={(e)=>changeSpecialForm(e, "family", "familySecondSurname")}
                                            error={!!formErrors.familySecondSurname}
                                            helperText={formErrors.familySecondSurname}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={4} className="special-box-right information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Año de nacimiento*</Typography>
                                        <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale} >
                                            <DatePicker
                                                showToolbar={false}
                                                disableFuture
                                                disabled={formValues.personalIdentificationData.sonsAndDaughters ? false : true}
                                                openTo="year"
                                                views={['year', 'month', 'date']}
                                                maxDate={new Date()}
                                                value={familyForm.birthdayYearDateFormat || null}
                                                onChange={(value)=>changeFamiliarDate(value, 'birthdayYearDateFormat')}
                                                renderInput={(params) => (
                                                    <TextField
                                                        disabled
                                                        {...params}
                                                        fullWidth
                                                        name="birthdayYearDateFormat"
                                                        margin="none"
                                                        label={familyForm.birthdayYearDateFormat !==null ? "": "Seleccione Fecha dd/mm/aaaa"}
                                                        error={!!formErrors.birthdayYearDateFormat}
                                                        helperText={formErrors.birthdayYearDateFormat}
                                                        InputLabelProps={{shrink: false}}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={8} className="special-box-left information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Dirección</Typography>
                                        <TextField
                                            fullWidth
                                            disabled={formValues.personalIdentificationData.sonsAndDaughters ? false : true}
                                            id="outlined-select-familyForm-address"
                                            name="address"
                                            label={familyForm.address === "" ? "Ingrese la dirección": ""}
                                            value={familyForm.address === "" ? "" : familyForm.address}
                                            onChange={(e)=>changeSpecialForm(e, "family", "familyAddress")}
                                            error={!!formErrors.familyAddress}
                                            helperText={formErrors.familyAddress}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Divider className={classes.divider} />
                                    </Grid>
                                    <Grid item xs={12} className="information-box-section special-box-left add-container">
                                        <Button disabled={!isValidAddChild} onClick={addChild} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                                            <Typography variant="h6" className="regular-serif">
                                                Adicionar
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid container item xs={12} className="information-box-section detail-add-container">
                                        {formValues.familyInformation.childrens.length>0 &&
                                            formValues.familyInformation.childrens.map((child, index)=>{
                                                return(
                                                    <Grid container key={index}>
                                                        <Grid item xs={12} md={8} lg={9}>
                                                            <div className="detail-add-description">
                                                                <Typography variant="h5" className="regular-serif">
                                                                    {child.names} {child.surname} {child.secondSurname} - {child.birthdayYear} - {child.address}
                                                                </Typography>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} md={4} lg={3}>
                                                            <div className="delete-container">
                                                                <Button onClick={(e)=>deleteChild(e, child.id ? child.id : null)} variant="contained" className="delete-btn" endIcon={<DeleteIcon/>} >
                                                                    <Typography variant="h6" className="regular-serif">
                                                                        Eliminar
                                                                    </Typography>
                                                                </Button>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} className="special-box-both">
                                                            <Divider className={classes.divider} />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                        <Grid container item xs={12} alignItems="center" className="special-box-both visibility-curriculum-container ">
                                            <VisibilityIcon/>
                                            <FormControlLabel
                                                value={formValues.personalIdentificationData.familyInformationVisible}
                                                control={
                                                    <BlueSwitch
                                                        size="small"
                                                        checked={formValues.personalIdentificationData.familyInformationVisible}
                                                        onChange={handleChangeCheckBox}
                                                        name="personalIdentificationData.familyInformationVisible"
                                                    />
                                                }
                                                label="Mostrar esta información en su hoja de vida"
                                                labelPlacement="start"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChangeAccoridon('panel5')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel5bh-content"
                            id="panel5bh-header"
                            className="accordion-summary"
                            >
                                <Typography variant="h2" className="summary-title bold-serif">Datos de contacto</Typography>
                        </AccordionSummary>
                         <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row">
                                <Grid container item xs={12} className="information-box-margin" alignItems="flex-start">
                                    <Grid container item xs={12} className="complementary-text-container">
                                        <Typography variant="h5" className="regular-serif">
                                            En está sección puede adicionar o eliminar sus referencias, luego de adicionar debe Guardar los cambios.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Tipo de contacto*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-contactForm-type"
                                            select
                                            name="type"
                                            label={contactForm.type === "" ? "Seleccione": ""}
                                            value={contactForm.type === "" ? "" : contactForm.type}
                                            onChange={(e)=>changeSpecialForm(e, "contact", "type")}
                                            error={!!formErrors.type}
                                            helperText={formErrors.type}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {
                                                contactTypeArray && contactTypeArray.length> 0 &&
                                                <MenuItem value={""}><em>Ninguno</em></MenuItem>
                                            }
                                            {contactTypeArray.length> 0 ?
                                                contactTypeArray.map((option: IGeneralSIA) => (
                                                    <MenuItem key={option.id} value={option.name}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                            :
                                                <MenuItem value={contactForm.type}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Nombres*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-contactForm-names"
                                            name="names"
                                            label={contactForm.names === "" ? "Ingrese nombres": ""}
                                            value={contactForm.names === "" ? "" : contactForm.names}
                                            onChange={(e)=>changeSpecialForm(e, "contact", "contactNames")}
                                            error={!!formErrors.contactNames}
                                            helperText={formErrors.contactNames}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                            disabled={contactForm.type === "" ? true : false}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Primer apellido*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-contactForm-surname"
                                            name="surname"
                                            label={contactForm.surname === "" ? "Ingrese primer apellido": ""}
                                            value={contactForm.surname === "" ? "" : contactForm.surname}
                                            onChange={(e)=>changeSpecialForm(e, "contact", "contactSurname")}
                                            error={!!formErrors.contactSurname}
                                            helperText={formErrors.contactSurname}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                            disabled={contactForm.type === "" ? true : false}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Segundo apellido</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-contactForm-secondSurnmae"
                                            name="secondSurnmae"
                                            label={contactForm.secondSurnmae === "" ? "Ingrese segundo apellido": ""}
                                            value={contactForm.secondSurnmae === "" ? "" : contactForm.secondSurnmae}
                                            onChange={(e)=>changeSpecialForm(e, "contact", "contactSecondSurnmae")}
                                            error={!!formErrors.contactSecondSurnmae}
                                            helperText={formErrors.contactSecondSurnmae}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                            disabled={contactForm.type === "" ? true : false}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} className="special-box-right information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Número telefónico principal*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-contactForm-phone"
                                            name="phone"
                                            label={contactForm.phone === "" ? "Ingrese número telefónico": ""}
                                            value={contactForm.phone === "" ? "" : contactForm.phone}
                                            onChange={(e)=>changeSpecialForm(e, "contact", "contactPhone")}
                                            error={!!formErrors.contactPhone}
                                            helperText={formErrors.contactPhone}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                            disabled={contactForm.type === "" ? true : false}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} className="special-box-both information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Correo electrónico</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-contactForm-email"
                                            name="email"
                                            label={contactForm.email === "" ? "Ingrese correo electrónico": ""}
                                            value={contactForm.email === "" ? "" : contactForm.email}
                                            onChange={(e)=>changeSpecialForm(e, "contact", "contactEmail")}
                                            error={!!formErrors.contactEmail}
                                            helperText={formErrors.contactEmail}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                            disabled={contactForm.type === "" ? true : false}
                                        >
                                        </TextField>
                                    </Grid>
                                    {contactForm.type === "Laboral" &&
                                        <Grid item xs={12} md={6} lg={4} className="special-box-left information-box-section">
                                            <Typography variant="h5" className="bold-serif title-box-text">Cargo*</Typography>
                                            {contactForm.type === "Laboral" &&
                                                <TextField
                                                    fullWidth
                                                    id="outlined-select-contact-position"
                                                    name="position"
                                                    label={contactForm.position === "" ? "Ingrese nombre del cargo": ""}
                                                    value={contactForm.position === "" ? "" : contactForm.position}
                                                    onChange={(e)=>changeSpecialForm(e, "contact", "contactPosition")}
                                                    error={!!formErrors.contactPosition}
                                                    helperText={formErrors.contactPosition}
                                                    InputLabelProps={{shrink: false}}
                                                    margin="none"
                                                    variant="outlined"
                                                >
                                                </TextField>
                                            }
                                        </Grid>
                                    }
                                    <Grid item xs={12} className="information-box-section special-box-left add-container">
                                        <Button disabled={!isValidAddContact} onClick={addContact} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                                            <Typography variant="h6" className="regular-serif">
                                                Adicionar
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    {formValues.referents.length>0 &&
                                        formValues.referents.map((referent, index)=>{
                                            return(
                                                <Grid key={index} container item xs={12} className="information-box-section detail-add-container">
                                                    <Grid item xs={12} md={8} lg={9}>
                                                        <div className="detail-add-description">
                                                            <Typography variant="h5" className="regular-serif">
                                                                {referent.type}- {referent.names} {referent.surname} {referent.secondSurnmae} {referent.position ? " - " + referent.position : ""} - {referent.phone} - <span className="link-responsive">{referent.email}</span>
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} md={4} lg={3}>
                                                        <div className="delete-container">
                                                            <Button onClick={(e)=>deleteContact(e, referent.id ? referent.id : null)} variant="contained" className="delete-btn" endIcon={<DeleteIcon/>} >
                                                                <Typography variant="h6" className="regular-serif">
                                                                    Eliminar
                                                                </Typography>
                                                            </Button>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} className="special-box-both">
                                                        <Divider className={classes.divider} />
                                                    </Grid>
                                                    <Grid container item xs={12} alignItems="center" className="special-box-both visibility-curriculum-container ">
                                                        <VisibilityIcon/>
                                                        <FormControlLabel
                                                            value={referent.visble}
                                                            control={
                                                                <BlueSwitch
                                                                    size="small"
                                                                    checked={referent.visble}
                                                                    onChange={(e)=>changeCheckBoxContact(e,referent.id ? referent.id : null)}
                                                                    name="visble"
                                                                />
                                                            }
                                                            label="Mostrar esta información en su hoja de vida"
                                                            labelPlacement="start"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <>{
                    userContext.roleSelected === 9 ?
                    <>
                    </>
                    :
                    <>
                        <Grid container item xs={12} className="complementary-text-container">
                            <Typography variant="h4" className="regular-serif">
                                Completa los campos obligatorios(*) para poder habilitar el boton de guardar.
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} className="buttons-curriculum-form-container">
                            <Button disabled={!isValidSave} onClick={savePersonalInfo} className="save-button" variant="contained" size="small">
                                <Typography variant="h5" className="regular-serif">Guardar</Typography>
                            </Button>
                            <Button onClick={confirmSend} className="next-button" variant="contained" size="small">
                                <Typography variant="h5" className="regular-serif">Siguiente</Typography>
                            </Button>
                        </Grid>
                    </>
                }</>
            </Grid>
            <Grid container item xs={12} lg={2} justify="center" style={{height:'fit-content'}}>
                <NotificationBox 
                    bgColor="#CFF4FC"
                    title="Porcentaje de Completitud"
                    titleColor="#055160"
                    description="Este es el % de los campos obligatorios necesarios para entrar a la bolsa de empleo"
                    descColor="#055160"
                    extra={
                    <>
                        <CircularProgressDet value={userContext.userPercentage?.total ? userContext.userPercentage.total : 0} size={90} descColor ={"#055160"}/>
                        <>
                            {userContext.userPercentage?.personalData.percentage ===100?
                                <></>
                            :
                                <Typography variant="h6" className="regular-serif" style={{color: '#055160', width: '100%'}}>Te falta ingresar los siguientes campos
                                <ul>
                                {userContext.userPercentage?.personalData.missing.map((item)=>{
                                    return <li>{item}</li>
                                })}
                                </ul>
                                </Typography>
                            }
                        </>
                    </>
                }
                />
                <div className='space-notification-boxes'> </div>
                <NotificationBox
                    bgColor="#CFF4FC"
                    title="Datos personales"
                    titleColor="#055160"
                    description="Por favor actualice sus datos de identificación personales. Los campos marcados con asterisco (*) son obligatorios. En algunos campos puede seleccionar si desea que la información sea visible en su Hoja de vida."
                    descColor="#055160"
                    icon={<ErrorOutlineIcon style={{color:'#055160'}} fontSize="large"/>}
                />
                <div className="space-notification-boxes">
                <NotificationBox
                    bgColor="#CFF4FC"
                    title="Programa PAES/PEAMA"
                    titleColor="#055160"
                    description="El programa PAES/PEAMA aplica para estudiantes anteriores al 2003, si pertenece a una promoción posterior no marque esta casilla."
                    descColor="#055160"
                    icon={<ErrorOutlineIcon style={{color:'#055160'}} fontSize="large"/>}
                />
                </div>
            </Grid>
            <ModalOffersAction
                open={opeModalConfirm}
                handleClose={closeModalConfirm}
                handleContinue={nextBtnAction}
                iconModal={<WarningIcon className="big-info-icon" />}
                title="Advertencia"
                headerdescription=""
                description="Señor usuario(a), Si actualizo información de la hoja de vida, por favor cerciórese de darle primero al botón guardar antes de avanzar a la siguiente sección, esto con la finalidad de registrar las modificaciones realizadas."
                backBtnText="Regresar"
                colorBtnBack= ""
                continueBtnText="Si, Continuar"
                colorBtnContinue="#14A5DC"
                valid={true}
            />
            <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
            <LoadingLockPage load={promiseInProgress}/>
            <LoadingLockPage load={initLoad}/>
        </>
    )
}

export default PersonalInformation
