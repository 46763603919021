import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { IGetOffer } from '../../../interfaces/IGetOffer';
import UserContext from '../../../utils/UserContext';
import OfferCard from '../OfferCard';

interface PublicOffersListProps{
    offersList: Array<IGetOffer>;
    idOrder: number;
}

const PublicOffersList: React.FC<PublicOffersListProps> = ({offersList, idOrder})=>{
    const [url, setUrl] = useState<string | null>(null);

    useEffect(()=>{
        const isEvent = sessionStorage.getItem('eventAnonimous');
        if(isEvent){
            sessionStorage.removeItem('eventAnonimous');
        }
        const isSurvey = sessionStorage.getItem('surveyAnonimous');
        if(isSurvey){
            sessionStorage.removeItem('surveyAnonimous');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleSeeOffer = (e: any, idOffer: number)=>{
        setUrl(`/ofertas/detalle-oferta/${idOffer}`)
    }

    return(
        <>
            {
                url !== null ?
                <Redirect to={url} />
            :
                <>
                    {
                        offersList.length>0 &&
                        offersList.map((offer:IGetOffer)=>(
                            <OfferCard
                                key={offer.id}
                                borderColor="#D7D7D7"
                                bgColor="#F9F9F9"
                                marginDef="10px 0"
                                companyName={offer.nameCompany}
                                nameCompanyVisible={offer.nameCompanyVisible}
                                cityId={offer.city}
                                departmentId={offer.department}
                                initialDate={new Date(offer.jobOfferStartDate)}
                                offerName={offer.jobTitle}
                                offerDescription={offer.description}
                                vacancies={offer.numberVacancy}
                                positionId={offer.typePosition}
                                contractId={offer.typeContract}
                                salaryVisible={offer.salaryVisible}
                                salary={offer.rangeSalary}
                                description_salary={offer.descriptionVariableSalary}
                                finalDate={new Date(offer.jobOfferEndDate)}
                            >
                                <Grid container item xs={12}>
                                    <hr className="divider-accordion"/>
                                </Grid>
                                <Grid className="simple-right-actions">
                                    <Button className="see-offer-btn" onClick={(e)=>handleSeeOffer(e, offer.id)}>
                                        <Typography variant="h5" className="regular-serif">
                                            Ver oferta
                                        </Typography>
                                    </Button>
                                </Grid>
                            </OfferCard>
                        ))
                    }
                </>
            }
        </>
    )
}

export default PublicOffersList;