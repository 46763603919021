const baseUrl = process.env.REACT_APP_SIE_APP_PROXY_BASE_URL;

interface getStateUserProps{
    state: string;
    observation: string;
    otherObservation: string;
    email:string;
    username:string
}

export const getStateUser = async (bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/graduate/checkState`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(bodyRequest)
            }
            )
        if(response.status === 200){
            const result: getStateUserProps = await response.json();
            if(result.state !== undefined){
                return result.state;
            }
        }
    } catch (error) {
        return error
    }
}

export const getStateCompany = async (bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/company/checkState`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(bodyRequest)
            }
            )
        if(response.status === 200){
            const result: getStateUserProps = await response.json();
            if(result.state !== undefined){
                return result;
            }
        }
    } catch (error) {
        return error
    }
}