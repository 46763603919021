import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from "@material-ui/core/MenuItem";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Link from '@material-ui/core/Link';
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import { makeStyles, createStyles, Theme, withStyles} from '@material-ui/core/styles';
import DetailAcademicInfoModal from '../DetailAcademicInfoModal';
import NotificationBox from '../../ShareComponents/NotificationBox';
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';
import UserContext from '../../../utils/UserContext';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import { getUserAcademicInfo, updateAcademicInfo, getUserPercentage } from '../../../services/GraduateService';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { sortByKey } from '../../../helpers/sharedFunctions';
import Validations from '../../../helpers/Validations';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import { getACADEMIC_LEVEL, getCAMPUS_FACULTIES, getCITY, getCOUNTRIES_CITIES, getTYPE_STUDY,
    getDEPARTAMENT, getEDUCATION_STATE, getLANGUAGE, getLANGUAGELEVEL, getPROGRAMS, getAREA, getACADEMIC_LEVEL_NEW } from '../../../services/ParameterService';
import { IAcademicInfoCV, IEducationInfo, ILanguage, IOherStudies, IScholarship } from '../../../interfaces/IAcademicInfoCV';
import '../GraduateCurriculum.scss';
import CircularProgressDet from '../../ShareComponents/CircularProgressDet';
import { IUserPercentage } from '../../../interfaces/IUserPercentage';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: theme.spacing(2, 0),
    },
  }),
);
const BlueSwitch = withStyles({
  switchBase: {
    color:'#ffffff',
    '&$checked': {
      color:'#004B73',
    },
    '&$checked + $track': {
      backgroundColor: '#004B73',
    },
  },
  checked: {},
  track: {},
})(Switch);


class InitialAcademicErrors {
    //other studies unal
    campus: string = "";
    school: string = "";
    curriculum: string =  "";
    otherUnalTitle: string =  "";
    otherUnalCountry: string =  "";
    otherUnalDepartment: string = "";
    otherUnalCity: string =  "";
    diplomaNumber: string = "";
    professionalCardNumber: string ="";
    degreeWorkTitle: string ="";
    degreeWorkDescription: string ="";
    otherUnalDateInitFormat: string = "";
    otherUnalDateFormat: string = "";
    addByUser: boolean = false;
    eduStateOtherUnal: string = "";
    typeStudyOtherUnal: string = "";
    knowAreaOtherUnal: string = "";
    // other studies
    institution: string =  "";
    faculty: string =  "";
    program: string =  "";
    title: string =  "";
    otherCountry: string =  "";
    otherDepartament: string = "";
    otherCity: string =  "";
    otherDateInitFormat: string = "";
    otherDateEndFormat: string = "";
    eduStateOther: string = "";
    typeStudyOther: string = "";
    knowAreaOther: string = "";
    hoursDuration: string = "";
    academicLevelOther: string = "";
    diplomaNumberOther: string = "";
    professionalCardNumberOther: string = "";
    // scholarchip
    name: string =  "";
    entity: string =  "";
    scholarchipCountry: string =  "";
    scholarchipCity: string =  "";
    startDateFormat: string =  "";
    endDateFormat: string =  "";
    // language
    language: string =  "";
    level: string =  "";
}

const formValidations = {
    //other studies unal
    campus: {
        error: "",
        validate: "select",
        required: true
    },
    school: {
        error: "",
        validate: "select",
        required: true
    },
    curriculum: {
        error: "",
        validate: "select",
        required: true
    },
    otherUnalTitle:{
        error: "",
        validate: "textNumber",
        required: false
    },
    otherUnalCountry: {
        validate: "select",
        required: true
    },
    otherUnalDepartment: {
        validate: "select",
        required: true
    },
    otherUnalCity: {
        validate: "select",
        required: true
    },
    diplomaNumber: {
        error: "",
        validate: "textNumber",
        required: false
    },
    professionalCardNumber: {
        error: "",
        validate: "textNumber",
        required: false
    },
    degreeWorkTitle: {
        error: "",
        validate: "text",
        required: false
    },
    degreeWorkDescription: {
        error: "",
        validate: "textNumber",
        required: false
    },
    otherUnalDateInitFormat: {
        error: "",
        validate: "normalDate",
        maxDate: new Date(),
        required: true
    },
    otherUnalDateFormat: {
        error: "",
        validate: "normalDate",
        minDate: new Date(),
        required: true
    },
    eduStateOtherUnal: {
        error: "",
        validate: "select",
        required: true
    },
    typeStudyOtherUnal: {
        error: "",
        validate: "select",
        required: true
    },
    knowAreaOtherUnal: {
        error: "",
        validate: "select",
        required: false
    },
    // other studies
    institution: {
        error: "",
        validate: "textNumber",
        required: true
    },
    faculty: {
        error: "",
        validate: "text",
        required: true
    },
    program: {
        error: "",
        validate: "textNumber",
        required: true
    },
    title: {
        error: "",
        validate: "text",
        required: true
    },
    otherCountry: {
        validate: "select",
        required: true
    },
    otherDepartament: {
        validate: "select",
        required: true
    },
    otherCity: {
        validate: "select",
        required: true
    },
    otherDateInitFormat: {
        error: "",
        validate: "normalDate",
        maxDate: new Date(),
        required: true
    },
    otherDateEndFormat: {
        error: "",
        validate: "normalDate",
        minDate: new Date(),
        required: true
    },
    eduStateOther: {
        error: "",
        validate: "select",
        required: true
    },
    typeStudyOther: {
        error: "",
        validate: "select",
        required: true
    },
    knowAreaOther: {
        error: "",
        validate: "select",
        required: false
    },
    hoursDuration:{
        error: "",
        validate: "number",
        required: false
    },
    academicLevelOther: {
        error: "",
        validate: "select",
        required: true
    },
    diplomaNumberOther:{
        error: "",
        validate: "textNumber",
        required: false
    },
    professionalCardNumberOther: {
        error: "",
        validate: "textNumber",
        required: false
    },
    // scholarchip
    name: {
        error: "",
        validate: "text",
        required: true
    },
    entity: {
        error: "",
        validate: "textNumber",
        required: true
    },
    scholarchipCountry: {
        validate: "select",
        required: true
    },
    scholarchipCity: {
        validate: "select",
        required: true
    },
    startDateFormat: {
        error: "",
        validate: "normalDate",
        maxDate: new Date(),
        required: false
    },
    endDateFormat: {
        error: "",
        validate: "normalDate",
        minDate: new Date(),
        required: true
    },
    // language
    language: {
        validate: "select",
        required: true
    },
    level: {
        validate: "select",
        required: true
    },
}
interface PInfoProps {
    children?: any
    data?: IUserPercentage
}
const PercentageInfo: React.FC<PInfoProps> = ({children, data}) =>{
    const otherStudiesData =  data?.academicData.missing.otherStudies || [];
    const scholarshipsData =  data?.academicData.missing.scholarships || [];
    const languagesData =  data?.academicData.missing.languages || [];

    return(
        <>
            { otherStudiesData.length > 0 &&
                <Typography variant="h6" className="regular-serif" style={{color: '#055160', width: '100%'}}>Seccion otros estudios
                <ul>
                {otherStudiesData.map((item)=>{
                    return <li>{item}</li>
                })}
                </ul>
                </Typography>
            }
            { scholarshipsData.length > 0 &&
                <Typography variant="h6" className="regular-serif" style={{color: '#055160', width: '100%'}}>Seccion Becas
                <ul>
                {scholarshipsData.map((item)=>{
                    return <li>{item}</li>
                })}
                </ul>
                </Typography>
            }
            { languagesData.length > 0 &&
                <Typography variant="h6" className="regular-serif" style={{color: '#055160', width: '100%'}}>Seccion Idiomas
                <ul>
                {languagesData.map((item)=>{
                    return <li>{item}</li>
                })}
                </ul>
                </Typography>
            }
            
        </>
        
    );
}

const AcademicInformation: React.FC = () => {
    let params: any = useParams();
    const userContext = React.useContext(UserContext);
    const { promiseInProgress } = usePromiseTracker();
    const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [expanded, setExpanded] = useState<string | boolean>('panel1');
    const [educationInfoModal, setEducationInfoModal] = useState<IEducationInfo>(new IEducationInfo());
    const [openEducationModal, setOpenEducationModal] = useState(false);
    const [formValues, setFormValues] = useState<IAcademicInfoCV>(new IAcademicInfoCV({}));
    const [otherFormValues, setOtherFormValues] = useState<IOherStudies>(new IOherStudies());
    const [otherEducationalFormValues, setOtherEducationalFormValues] = useState<IEducationInfo>(new IEducationInfo());
    const [scholarchipFormValues, setScholarchipFormValues] = useState<IScholarship>(new IScholarship());
    const [languageFormValues, setLanguageFormValues] = useState<ILanguage>(new ILanguage());
    const [formErrors, setFormErrors] = useState<InitialAcademicErrors>(new InitialAcademicErrors());
    //data arrays
    const [allCampus, setAllCampus] = useState<Array<IListParametersInfo>>([]);
    const [schools, setSchools] = useState<Array<IGeneralParameter>>([]);
    const [allPrograms, setAllPrograms] = useState<Array<IGeneralParameter>>([]);
    const [programs, setPrograms] = useState<Array<IGeneralParameter>>([]);
    const [countryCitiesArray, setCountryCitiesArray] = useState<Array<IListParametersInfo>>([]);
    const [departamentArray, setDepartamentArray] = useState<Array<IGeneralParameter>>([]);
    const [citiesArray, setCitiesArray] = useState<Array<IGeneralParameter>>([]);
    const [citiesOther, setCitiesOther] = useState<Array<IGeneralParameter>>([]);
    const [citiesScholarchip, setCitiesScholarchip] = useState<Array<IGeneralParameter>>([]);
    const [citiesCol, setCitiesCol] = useState<Array<IGeneralParameter>>([]);
    const [allLanguages, setAllLanguages] = useState<Array<IGeneralParameter>>([]);
    const [languageLevels, setLanguageLevels] = useState<Array<IGeneralParameter>>([]);
    const [eduStateArr,setEduStateArr ] = useState<Array<IGeneralParameter>>([]);
    const [typeStudyArr,setTypeStudyArr ] = useState<Array<IGeneralParameter>>([]);
    const [areaKnowledgeArr, setAreaKnowledgeArr] = useState<Array<IGeneralParameter>>([]);
    const [newAcademicLevelArr, setNewAcademicLevelArr] = useState<Array<IGeneralParameter>>([]);
    const [constDataAcLevel, setConstDataAcLevel] = useState<Array<IGeneralParameter>>([]);
    const [constDataEduState, setConstDataEduState] = useState<Array<IGeneralParameter>>([]);
    // modals and errors
    const [errorMessage, setErrorMessage]= useState('');
    const [openModalError, setOpenModalError] = useState(false);
    const [successMessage, setSuccessMessage]= useState('');
    const [openModalSuccess, setOpenModalSuccess] = useState(false);

    const isValidOtherUnal=
        otherEducationalFormValues.campus>0 &&
        !formErrors.campus &&
        otherEducationalFormValues.school > 0 &&
        !formErrors.school &&
        otherEducationalFormValues.curriculum >0 &&
        !formErrors.curriculum &&
        !formErrors.otherUnalTitle &&
        !formErrors.otherUnalCity &&
        !formErrors.diplomaNumber &&
        !formErrors.professionalCardNumber &&
        !formErrors.degreeWorkTitle &&
        !formErrors.degreeWorkDescription &&
        !formErrors.eduStateOtherUnal &&
        !formErrors.typeStudyOtherUnal &&
        moment(otherEducationalFormValues.dateInitFormat).isValid() &&
        !formErrors.otherUnalDateInitFormat &&
        (
            otherEducationalFormValues.educationState ==3 ?
            moment(otherEducationalFormValues.dateFormat).isValid() &&
            !formErrors.otherUnalDateFormat : 
            otherEducationalFormValues.educationState
        )


    const isValidOther =
        otherFormValues.institution.length>0 &&
        !formErrors.institution &&
        // otherFormValues.program.length>0 &&
        // !formErrors.program &&
        otherFormValues.title.length>0 &&
        !formErrors.title &&
        // moment(otherFormValues.dateInitFormat).isValid() &&
        !formErrors.otherDateInitFormat &&
        !formErrors.typeStudyOther &&
        !formErrors.eduStateOther &&
        !formErrors.diplomaNumberOther &&
        !formErrors.academicLevelOther &&
        !formErrors.hoursDuration &&
        !formErrors.professionalCardNumberOther &&
        (otherFormValues.educationState == 3 ?
            moment(otherFormValues.dateEndFormat).isValid() &&
            !formErrors.otherDateEndFormat  :
            otherFormValues.educationState
        )

    const isValidScholarchip=
        scholarchipFormValues.name.length>0 &&
        !formErrors.name &&
        scholarchipFormValues.entity.length>0 &&
        !formErrors.entity &&
        scholarchipFormValues.country>0 &&
        moment(scholarchipFormValues.startDateFormat).isValid() &&
        !formErrors.startDateFormat &&
        moment(scholarchipFormValues.endDateFormat).isValid() &&
        !formErrors.endDateFormat

    const isValidAddLanguage=
        languageFormValues.language>0 &&
        !formErrors.language &&
        (
            (languageFormValues.level>0 &&
            !formErrors.level) ||
            languageFormValues.native
        )

    const classes = useStyles();

    useEffect(()=>{
        const getInitialData = async()=>{
            window.scrollTo(0, 0);
            let dataAllCampus: Array<IListParametersInfo> = (await getCAMPUS_FACULTIES()).list;
            dataAllCampus = sortByKey(dataAllCampus, 'parentName');
            setAllCampus(dataAllCampus ? dataAllCampus : []);
            let schoolsArray;
            const programsArray: Array<IGeneralParameter> = await getPROGRAMS();
            setAllPrograms(programsArray || []);
            const academicArray: Array<IGeneralParameter> = await getACADEMIC_LEVEL();
            let dataCountryCities:Array<IListParametersInfo> = (await getCOUNTRIES_CITIES()).list;
            let dataDepartament: Array<IGeneralParameter> = await getDEPARTAMENT();
            dataDepartament = sortByKey(dataDepartament, 'name');
            setDepartamentArray(dataDepartament ? dataDepartament : []);
            let dataCity: Array<IGeneralParameter> = await getCITY();
            dataCity = sortByKey(dataCity, 'name');
            setCitiesArray(dataCity ? dataCity : []);
            dataCountryCities = sortByKey(dataCountryCities, 'grandParentName');
            setCountryCitiesArray(dataCountryCities);
            let responseLanguages: Array<IGeneralParameter> = await getLANGUAGE();
            responseLanguages = sortByKey(responseLanguages, 'name');
            setAllLanguages(responseLanguages);
            let languageLevelData = await getLANGUAGELEVEL();
            languageLevelData = sortByKey(languageLevelData, 'name');
            setLanguageLevels(languageLevelData);
            const titleArray: Array<IGeneralParameter> = [{parameter_code: 1,
                name: "Ingeniero temporal***", description:""}]
            

            let typeStudyArray: Array<IGeneralParameter> =  await getTYPE_STUDY();
            typeStudyArray = sortByKey(typeStudyArray, 'parameter_code');
            setTypeStudyArr(typeStudyArray);

            let areaKnownArray: Array<IGeneralParameter> =  await getAREA();
            areaKnownArray = sortByKey(areaKnownArray, 'parameter_code');
            setAreaKnowledgeArr(areaKnownArray);

            let newAcLevelArr: Array<IGeneralParameter> =  await getACADEMIC_LEVEL_NEW();
            newAcLevelArr = sortByKey(newAcLevelArr, 'parameter_code');
            setNewAcademicLevelArr(newAcLevelArr);
            setConstDataAcLevel(newAcLevelArr)    
            let eduStateArray: Array<IGeneralParameter> = await getEDUCATION_STATE();
            eduStateArray = sortByKey(eduStateArray, 'parameter_code');
            setEduStateArr(eduStateArray);
            setConstDataEduState(eduStateArray)
            if(userContext.userId){
                const responseUser = await getUserAcademicInfo(userToken, (userContext.roleSelected === 1 ? userContext.userId : params.id));
                if(responseUser.status === 200){
                    const resultAcademicUser: IAcademicInfoCV = await responseUser.json();
                    const tempFormValues = new IAcademicInfoCV(resultAcademicUser);
                    tempFormValues.doc = userContext.roleSelected === 1 ? userContext.userId : params.id;
                    if(tempFormValues.educationUnal.educations.length>0){
                        tempFormValues.educationUnal.educations.forEach(educationItem=>{
                            const campusObj = dataAllCampus.find(item=>item.id === educationItem.campus);
                            educationItem.campusName = campusObj ? campusObj.parentName : "";
                            if(campusObj){
                                schoolsArray = campusObj.childs;
                                const schoolObj = schoolsArray.find(item=> item.parameter_code === educationItem.school);
                                educationItem.schoolName = schoolObj ? schoolObj.name : "";
                            }
                            if(programsArray.length>0){
                                const programObj = programsArray.find(item=> item.parameter_code === educationItem.curriculum);
                                educationItem.curriculumName = programObj ? programObj.name : "";
                            }
                            if(dataCountryCities.length>0 && educationItem.country > 0){
                                const countryObj = dataCountryCities.find(item=> item.id === educationItem.country);
                                educationItem.countryName = countryObj ? countryObj.grandParentName : "";
                            }
                            if(dataDepartament.length>0 && educationItem.department > 0){
                                const departmentObj = dataDepartament.find(item=> item.parameter_code === educationItem.department);
                                educationItem.departmentName = departmentObj ? departmentObj.name : "";
                            }
                            if(dataCity.length>0 && educationItem.city > 0){
                                const cityObj = dataCity.find(item=> item.parameter_code === educationItem.city);
                                educationItem.cityName = cityObj ? cityObj.name : "";
                            }
                            if(areaKnownArray.length > 0  && educationItem.knowArea > 0){
                                const areaObj = areaKnownArray.find(item=> item.parameter_code === educationItem.knowArea);
                                educationItem.KnowAreaName = areaObj ? areaObj.name : "";
                            }
                            const academicObj = academicArray.find(item => item.parameter_code === educationItem.academicLevel);
                            educationItem.academicLevelName = academicObj ? academicObj.name : "";
                            // const titleObj = titleArray.find(item => item.parameter_code === educationItem.title);
                            // educationItem.titleName = titleObj ? titleObj.name : "";
                        })
                    }
                    setFormValues(tempFormValues);
                }
            }
            else if(params.id){
                const responseUser = await getUserAcademicInfo(userToken, params.id);
                if(responseUser.status === 200){
                    const resultAcademicUser: IAcademicInfoCV = await responseUser.json();
                    const tempFormValues = new IAcademicInfoCV(resultAcademicUser);
                    tempFormValues.doc = params.id;
                    if(tempFormValues.educationUnal.educations.length>0){
                        
                        tempFormValues.educationUnal.educations.forEach(educationItem=>{
                            const campusObj = dataAllCampus.find(item=>item.id === educationItem.campus);
                            educationItem.campusName = campusObj ? campusObj.parentName : "";
                            if(campusObj){
                                schoolsArray = campusObj.childs;
                                const schoolObj = schoolsArray.find(item=> item.parameter_code === educationItem.school);
                                educationItem.schoolName = schoolObj ? schoolObj.name : "";
                            }
                            if(programsArray.length>0){
                                const programObj = programsArray.find(item=> item.parameter_code === educationItem.curriculum);
                                educationItem.curriculumName = programObj ? programObj.name : "";
                            }
                            if(dataCountryCities.length>0 && educationItem.country > 0){
                                const countryObj = dataCountryCities.find(item=> item.id === educationItem.country);
                                educationItem.countryName = countryObj ? countryObj.grandParentName : "";
                            }
                            if(dataDepartament.length>0 && educationItem.department > 0){
                                const departmentObj = dataDepartament.find(item=> item.parameter_code === educationItem.department);
                                educationItem.departmentName = departmentObj ? departmentObj.name : "";
                            }
                            if(dataCity.length>0 && educationItem.city > 0){
                                const cityObj = dataCity.find(item=> item.parameter_code === educationItem.city);
                                educationItem.cityName = cityObj ? cityObj.name : "";
                            }
                            const academicObj = academicArray.find(item => item.parameter_code === educationItem.academicLevel)
                            educationItem.academicLevelName = academicObj ? academicObj.name : "";
                            // const titleObj = titleArray.find(item => item.parameter_code === educationItem.title)
                            // educationItem.titleName = titleObj ? titleObj.name : "";
                        })
                    }
                    setFormValues(tempFormValues);
                }
            }
            setInitLoad(false);
        }
        getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        setScholarchipFormValues(prev => ({
            ...prev,
            city:0
        }));
        if(scholarchipFormValues.country>0){
            if(scholarchipFormValues.country>0){
                const countryObjSelected = countryCitiesArray.find(country=>country.id === scholarchipFormValues.country);
                if(countryObjSelected){
                    let cities = countryObjSelected.childs;
                    cities = sortByKey(cities, 'name');
                    setCitiesScholarchip(cities);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scholarchipFormValues.country])

    useEffect(()=>{
        setOtherFormValues(prev => ({
            ...prev,
            city:0
        }));
        if(otherFormValues.country>0){
            if(otherFormValues.country === 170){
                setCitiesOther([]);
            } else {
                const countryObjSelected = countryCitiesArray.find(country=>country.id === otherFormValues.country);
                if(countryObjSelected){
                    let cities = countryObjSelected.childs;
                    cities = sortByKey(cities, 'name');
                    setCitiesOther(cities);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[otherFormValues.country])

    useEffect(()=>{
        setOtherFormValues(prev => ({
            ...prev,
            city: 0
        }));
        if(otherFormValues.departament>0){
            let citiesDep:Array<IGeneralParameter> = citiesArray.filter(city=> city.parentId === otherFormValues.departament);
            citiesDep = sortByKey(citiesDep, 'name');
            if(citiesDep.length>0){
                setCitiesOther(citiesDep)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[otherFormValues.departament])

    useEffect(()=>{
        setSchools([]);
        setPrograms([]);
        setOtherEducationalFormValues(prev => ({
            ...prev,
            school: 0,
            curriculum: 0
        }));
        if(otherEducationalFormValues.campus>0){
            const campusObj = allCampus.find(item=>item.id === otherEducationalFormValues.campus);
            if(campusObj){
                let schools = campusObj.childs;
                schools = sortByKey(schools, 'name');
                setSchools(schools);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[otherEducationalFormValues.campus]);

    useEffect(()=>{
        setOtherEducationalFormValues(prev => ({
            ...prev,
            curriculum:0
        }));
        if(otherEducationalFormValues.school>0){
            let programsArr: IGeneralParameter[] = [];
            const schoolPrograms: IGeneralParameter[] = allPrograms.filter(item=>item.parentId === otherEducationalFormValues.school);
            if(schoolPrograms.length>0){
                programsArr.push(...schoolPrograms);
            }
            setPrograms(programsArr);
        } else {
            setPrograms([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[otherEducationalFormValues.school]);

    useEffect(()=>{
        if(otherEducationalFormValues.country === 0){
            setOtherEducationalFormValues(prev => ({
                ...prev,
                department: 0,
                city: 0
            }));
            setCitiesCol([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[otherEducationalFormValues.country])

    useEffect(() => {
        setCitiesCol([]);
        setOtherEducationalFormValues(prev => ({
            ...prev,
            city: 0
        }));
        if (otherEducationalFormValues.department > 0) {
            let citiesDep: Array<IGeneralParameter> = citiesArray.filter(city => city.parentId === otherEducationalFormValues.department);
            citiesDep = sortByKey(citiesDep, 'name');
            if (citiesDep.length > 0) {
                setCitiesCol(citiesDep);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [otherEducationalFormValues.department])

    useEffect(() => {
        if(otherEducationalFormValues.educationState != 3){
            setOtherEducationalFormValues(prev => ({
                ...prev,
                ["dateFormat"]:null
            }));
        }
    },[otherEducationalFormValues.educationState])

    useEffect(() => {
        if(otherFormValues.educationState != 3){
            setOtherFormValues(prev => ({
                ...prev,
                ["dateEndFormat"]:null
            }));
        }
    },[otherFormValues.educationState])

    useEffect(() => {
        
        if(otherFormValues.typeStudy === 1){
            const newArr = constDataAcLevel.filter(data => data.parentId === 1)
            setNewAcademicLevelArr(newArr)
            const newArr2 = constDataEduState.filter(data => data.parentId === 1)
            setEduStateArr(newArr2)
        }else if(otherFormValues.typeStudy === 2){
            const newArr = constDataAcLevel.filter(data => data.parentId === 2)
            setNewAcademicLevelArr(newArr)
            const newArr2 = constDataEduState.filter(data => data.parentId === 2)
            setEduStateArr(newArr2)
        }else{
            setNewAcademicLevelArr(constDataAcLevel);
            setEduStateArr(constDataEduState);
        }
    },[otherFormValues.typeStudy])

    const handleChangeAccoridon = (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean
    ) => {setExpanded(isExpanded ? panel : false);};

    const changeSpecialForm = (e: any, pattern: string, nameError: string) => {
        const { name, value } = e.target;
        if(pattern === "otherStudy"){
            setOtherFormValues(prev => ({
                ...prev,
                [name]:value
            }));
        } else if(pattern === "otherStudyUnal"){
            setOtherEducationalFormValues(prev => ({
                ...prev,
                [name]:value
            }));
        } else if (pattern === "scholarchip"){
            setScholarchipFormValues(prev => ({
                ...prev,
                [name]:value
            }));
        } else if(pattern === "languages"){
            setLanguageFormValues(prev => ({
                ...prev,
                [name]:value
            }));
        }
        const error: string = Validations(nameError, value, formValidations) || "";
        setFormErrors((prev:any)=>({
          ...prev,
          [nameError]: error
        }));
    }

    const changeOtherStudiesUnalDate = (dateValue: Date | null, nameField: string, nameError: string) => {
        const value =dateValue ? moment(dateValue).format() : '';
        setOtherEducationalFormValues(prev => ({
            ...prev,
            [nameField]:value
        }));
        if(nameField === "dateInitFormat"){
            setOtherEducationalFormValues(prev => ({
                ...prev,
                dateFormat:null
            }));
        }
        if(nameField === "dateFormat"){
            formValidations.otherUnalDateFormat.minDate = otherEducationalFormValues.dateInitFormat ? moment(new Date(otherEducationalFormValues.dateInitFormat)).toDate() : moment(new Date()).toDate();
        }
        const error: string = Validations(nameError, value, formValidations) || "";
        setFormErrors((prev:any)=>({
          ...prev,
          [nameError]: error
        }));
    }

    const changeOtherStudiesDate = (dateValue: Date | null, nameField: string, nameError: string) => {
        const value =dateValue ? dateValue?.toString() : '';
        setOtherFormValues(prev => ({
            ...prev,
            [nameField]: value
        }));
        if(nameField === "dateInitFormat"){
            setOtherFormValues(prev => ({
                ...prev,
                dateEndFormat:null
            }));
        }
        if(nameField === "dateEndFormat"){
            formValidations.otherDateEndFormat.minDate = otherFormValues.dateInitFormat ? moment(new Date(otherFormValues.dateInitFormat)).toDate() : moment(new Date()).toDate();
        }
        const error: string = Validations(nameError, value, formValidations) || "";
        setFormErrors((prev:any)=>({
          ...prev,
          [nameError]: error
        }));
    }

    const changeScholarchipDate = (dateValue: Date | null, nameField: string, nameError: string) => {
        const value =dateValue ? dateValue?.toString() : '';
        setScholarchipFormValues(prev => ({
            ...prev,
            [nameField]:value
        }));
        if(nameField === "startDateFormat"){
            setScholarchipFormValues(prev => ({
                ...prev,
                endDateFormat:null
            }));
        }
        if(nameField === "endDateFormat"){
            formValidations.endDateFormat.minDate = scholarchipFormValues.startDateFormat ? moment(new Date(scholarchipFormValues.startDateFormat)).toDate() : moment(new Date()).toDate();
        }
        const error: string = Validations(nameError, value, formValidations) || "";
        setFormErrors((prev:any)=>({
          ...prev,
          [nameError]: error
        }));
    }

    const changeCheckBoxScholarchip = (e: React.ChangeEvent<HTMLInputElement>, idScholarchip:number | null) => {
        const ScholarchipAdjusted = formValues.scholarships.map(scholarchip => {
            if(scholarchip.id === idScholarchip){
                scholarchip.visible = !scholarchip.visible
            }
            return scholarchip;
        });
        setFormValues(prev => ({
            ...prev,
            scholarships: ScholarchipAdjusted
        }));
    }

    const changeCheckBoxLanguage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        languageFormValues.level=0
        setLanguageFormValues(prev => ({
            ...prev,
            [name]:checked
        }));
    };

    const addOtherStudyUnal = () =>{
        otherEducationalFormValues.id=formValues.educationUnal.educations.length+1;
        otherEducationalFormValues.dateInit = otherEducationalFormValues.dateInitFormat ? moment(new Date(otherEducationalFormValues.dateInitFormat+"")).format() : null;
        otherEducationalFormValues.date = otherEducationalFormValues.dateFormat ? moment(new Date(otherEducationalFormValues.dateFormat+"")).format() : null;
        otherEducationalFormValues.addByUser = true;
        const campusObj = allCampus.find(item=>item.id === otherEducationalFormValues.campus);
        otherEducationalFormValues.campusName = campusObj ? campusObj.parentName : "";
        if(campusObj){
            const schoolsArray = campusObj.childs;
            const schoolObj = schoolsArray.find(item=> item.parameter_code === otherEducationalFormValues.school);
            otherEducationalFormValues.schoolName = schoolObj ? schoolObj.name : "";
        }
        if(allPrograms.length>0){
            const programObj = allPrograms.find(item=> item.parameter_code === otherEducationalFormValues.curriculum);
            otherEducationalFormValues.curriculumName = programObj ? programObj.name : "";
        }
        if(departamentArray.length>0 && otherEducationalFormValues.department > 0){
            const departmentObj = departamentArray.find(item=> item.parameter_code === otherEducationalFormValues.department);
            otherEducationalFormValues.departmentName = departmentObj ? departmentObj.name : "";
        }
        if(citiesCol.length>0 && otherEducationalFormValues.city > 0){
            const cityObj = citiesCol.find(item=> item.parameter_code === otherEducationalFormValues.city);
            otherEducationalFormValues.cityName = cityObj ? cityObj.name : "";
        }
        setFormValues(prev => ({
            ...prev,
            educationUnal:{
                ...prev.educationUnal,
                educations: [...prev.educationUnal.educations, otherEducationalFormValues]
            }
        }));
        setOtherEducationalFormValues(new IEducationInfo());
    }

    const deleteOtherStudyUnal = (e:any, idToDelete: number | null) => {
        const newArrayOtherUnal = formValues.educationUnal.educations.filter(item=>item.id !== idToDelete);
        setFormValues(prev => ({
            ...prev,
            educationUnal:{
                ...prev.educationUnal,
                educations: newArrayOtherUnal
            }
        }));
    }

    const addOtherStudy = () =>{
        otherFormValues.id=formValues.otherStudies.length+1;
        otherFormValues.dateInit = otherFormValues.dateInitFormat ? moment(new Date(otherFormValues.dateInitFormat+"")).format() : "";
        otherFormValues.dateEnd = otherFormValues.dateEndFormat ? moment(new Date(otherFormValues.dateEndFormat+"")).format() : "";
        setFormValues(prev => ({
            ...prev,
            otherStudies:[...prev.otherStudies, otherFormValues]
        }));
        setOtherFormValues(new IOherStudies());
    }

    const deleteOtherStudy = (e:any, idToDelete: number | null) => {
        const newArrayOther = formValues.otherStudies.filter(item=>item.id !== idToDelete);
        setFormValues(prev => ({
            ...prev,
            otherStudies:newArrayOther
        }));
    }

    const addScholarchip = () => {
        scholarchipFormValues.id=formValues.scholarships.length+1;
        scholarchipFormValues.startDate = scholarchipFormValues.startDateFormat ? moment(new Date(scholarchipFormValues.startDateFormat+"")).format() : "";
        scholarchipFormValues.endDate = scholarchipFormValues.endDateFormat ? moment(new Date(scholarchipFormValues.endDateFormat+"")).format() : "";
        setFormValues(prev => ({
            ...prev,
            scholarships:[...prev.scholarships, scholarchipFormValues]
        }));
        setScholarchipFormValues(new IScholarship());
    }

    const deleteScholarchip = (e:any, idToDelete: number | null) => {
        const newArrayScholarchip = formValues.scholarships.filter(item=>item.id !== idToDelete);
        setFormValues(prev => ({
            ...prev,
            scholarships:newArrayScholarchip
        }));
    }

    const addLanguage = () =>{
        languageFormValues.id=formValues.languages.length+1;
        setFormValues(prev => ({
            ...prev,
            languages:[...prev.languages, languageFormValues]
        }));
        setLanguageFormValues(new ILanguage());
    }

    const deleteLanguage = (e:any, idToDelete: number | null) => {
        const newArrayLanguage = formValues.languages.filter(item=>item.id !== idToDelete);
        setFormValues(prev => ({
            ...prev,
            languages:newArrayLanguage
        }));
    }

    const saveAcademicInfo = async() => {
        formValues.educationUnal.educations.forEach(eduUnal=>{
            delete eduUnal.id;
        });
        formValues.otherStudies.forEach(other=>{
            delete other.id;
            delete other.dateEndFormat;
            delete other.dateInitFormat;
        });
        formValues.scholarships.forEach(scholarchip=>{
            delete scholarchip.id;
            delete scholarchip.startDateFormat;
            delete scholarchip.endDateFormat;
        });
        formValues.languages.forEach(language=>{
            delete language.id;
        });
        const objToUpdate = formValues;
        const responseAcademicInfo:any = await trackPromise(updateAcademicInfo(
            userToken,
            {...objToUpdate, modifyUser: userContext.userId}
        ));
        if(responseAcademicInfo.status === 200){
            setSuccessMessage("Se ha guardado correctamente la información");
            if(userContext.userId && params.id === undefined){
                await trackPromise(userContext.handleSetUserPercentage(userContext.userId));
            }else if(params.id){
                await trackPromise(userContext.handleSetUserPercentage(params.id));
            }
            setOpenModalSuccess(true);
            setOpenModalError(false);
        } else{
            setErrorMessage("Hubo un error, intente de nuevo mas tarde");
            setOpenModalError(true);
            setOpenModalSuccess(false);
        }
    }

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
    };

    const nextBtnAction = () =>{
        userContext.setStateCurriculumSection("Información laboral");
    }

    const backBtnAction = () =>{
        userContext.setStateCurriculumSection("Datos personales");
    }

    const clickOpenEducationModal = (education: IEducationInfo) => {
        setOpenEducationModal(true);
        setEducationInfoModal(education);
    };
    const closeEducationModal = () => {
        setOpenEducationModal(false);
    };
    const acceptEducationModal = () => {
        setOpenEducationModal(false);
    }

    return (
        <>
            <Grid item xs={12} lg={10} className="curriculum-form-info-container">
                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccoridon('panel1')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                            >
                            <Typography variant="h2" className="bold-serif">Estudios realizados en la UNAL</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row" className="information-box-margin">
                                <Grid container item xs={12} alignItems="center">
                                    {formValues.educationUnal.educations.length>0 &&
                                        formValues.educationUnal.educations.map((education, index)=>(
                                            <Grid container key={index}>
                                                <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                                    <Typography variant="h5" className="bold-serif title-box-text">Sede</Typography>
                                                    <Typography variant="h5" className="section-preview-title">
                                                        {education.campusName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                                    <Typography variant="h5" className="bold-serif title-box-text">Facultad</Typography>
                                                    <Typography variant="h5" className="section-preview-title">
                                                        {education.schoolName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                                    <Typography variant="h5" className="bold-serif title-box-text">Programa</Typography>
                                                    <Typography variant="h5" className="section-preview-title">
                                                        {education.curriculumName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                                    <Typography variant="h5" className="bold-serif title-box-text">Nivel educativo</Typography>
                                                    <Typography variant="h5" className="section-preview-title">
                                                        {education.academicLevelName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                                    <Typography variant="h5" className="bold-serif title-box-text">Título Formacion Academica</Typography>
                                                    <Typography variant="h5" className="section-preview-title">
                                                        {education.title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                                    <Typography variant="h5" className="bold-serif title-box-text">Profesión</Typography>
                                                    <Typography variant="h5" className="section-preview-title">
                                                        {education.profession}
                                                    </Typography>
                                                </Grid>
                                                
                                                <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                                    <Typography variant="h5" className="bold-serif title-box-text">Area de Conocimiento</Typography>
                                                    <Typography variant="h5" className="section-preview-title">
                                                        {education.KnowAreaName}
                                                    </Typography>
                                                </Grid>
                                                <Grid container item xs={12}  className="button-action">
                                                    <Button variant="contained" className="detail-action-btn" onClick={(e)=>clickOpenEducationModal(education)}>
                                                        <Typography variant="h6" className="regular-serif">
                                                            Ver detalles
                                                        </Typography>
                                                    </Button>
                                                    <DetailAcademicInfoModal
                                                        open={openEducationModal}
                                                        handleClose={closeEducationModal}
                                                        campus={educationInfoModal.campusName || ""}
                                                        faculty={educationInfoModal.schoolName || ""}
                                                        program={educationInfoModal.curriculumName || ""}
                                                        academicLevel={educationInfoModal.academicLevelName || ""}
                                                        obtainedTitle= {education.title || ""}
                                                        profession= {education.profession || ""}
                                                        proffesionalCard= {educationInfoModal.professionalCardNumber || ""}
                                                        diplomaNumber= {educationInfoModal.diplomaNumber || ""}
                                                        startDate= {(moment(educationInfoModal.dateInit).format('DD-MMMM-YYYY') === "Fecha inválida" ? "" : moment(educationInfoModal.dateInit).format('DD-MMMM-YYYY')) || ""}
                                                        finalDate= {moment(educationInfoModal.date).format('DD-MMMM-YYYY') || ""}
                                                        country= {educationInfoModal.countryName || ""}
                                                        departament= {educationInfoModal.departmentName || ""}
                                                        city= {educationInfoModal.cityName || ""}
                                                        descriptionTesis= {educationInfoModal.degreeWorkTitle || ""}
                                                        continueBtnText="Aceptar"
                                                        handleContinue={acceptEducationModal}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="special-box-both">
                                                    <Divider className={classes.divider} />
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {/*ALL THIS SECTION IS COMMENTED BECAUSE THERE IS A NEW HU */}

                {/* <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccoridon('panel2')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                            >
                            <Typography variant="h2" className="bold-serif">Otros estudios realizados en la UNAL</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row" className="information-box-margin">
                                <Grid container item xs={12} className="complementary-text-container">
                                    <Typography variant="h5" className="regular-serif">
                                        En está sección puede adicionar o eliminar otros estudios realizados en la UNAL, luego de adicionar debe Guardar los cambios.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Sede*</Typography>
                                    {allCampus.length>0 &&
                                        <TextField
                                            fullWidth
                                            id="outlined-select-campus"
                                            select
                                            name="campus"
                                            label={otherEducationalFormValues.campus === 0 ? "Seleccione": ""}
                                            value={otherEducationalFormValues.campus === 0 ? "" : otherEducationalFormValues.campus}
                                            onChange={(e)=>changeSpecialForm(e, "otherStudyUnal","campus")}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {
                                                allCampus.length> 0 &&
                                                <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                            }
                                            {
                                                allCampus.length> 0 ?
                                                allCampus.map((option: IListParametersInfo) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.parentName}
                                                    </MenuItem>
                                                ))
                                            :
                                                <MenuItem value={otherEducationalFormValues.campus}></MenuItem>
                                            }
                                        </TextField>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Facultad*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-school"
                                        select
                                        name="school"
                                        label={otherEducationalFormValues.school === 0 ? "Seleccione": ""}
                                        value={otherEducationalFormValues.school === 0 ? "" : otherEducationalFormValues.school}
                                        onChange={(e)=>changeSpecialForm(e, "otherStudyUnal","school")}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                        disabled={schools.length> 0 ? false : true}
                                    >
                                        {
                                            schools.length> 0 &&
                                            <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                        }
                                        {
                                            schools.length> 0 ?
                                            schools.map((option: IGeneralParameter) => (
                                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                    {option.name}
                                                </MenuItem>
                                            ))
                                        :
                                            <MenuItem value={otherEducationalFormValues.school}></MenuItem>
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Programa*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-curriculum"
                                        select
                                        name="curriculum"
                                        label={otherEducationalFormValues.curriculum === 0 ? "Seleccione": ""}
                                        value={otherEducationalFormValues.curriculum === 0 ? "" : otherEducationalFormValues.curriculum}
                                        onChange={(e)=>changeSpecialForm(e, "otherStudyUnal", "curriculum")}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                        disabled={programs.length> 0 ? false : true}
                                    >
                                        {
                                            programs.length> 0 &&
                                            <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                        }
                                        {
                                            programs.length> 0 ?
                                            programs.map((option: IGeneralParameter) => (
                                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                    {option.name}
                                                </MenuItem>
                                            ))
                                        :
                                            <MenuItem value={otherEducationalFormValues.curriculum}></MenuItem>
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Area de Conocimiento</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-knowAreaOtherUnal"
                                            select
                                            name="knowArea"
                                            label={otherEducationalFormValues.knowArea === 0 ? "Seleccione": ""}
                                            value={otherEducationalFormValues.knowArea === 0 ? "" : otherEducationalFormValues.knowArea}
                                            onChange={(e)=>changeSpecialForm(e, "otherStudyUnal","knowAreaOtherUnal")}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {
                                                areaKnowledgeArr.length> 0 ?
                                                areaKnowledgeArr.map((option) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                            :
                                                <MenuItem value={otherEducationalFormValues.knowArea}></MenuItem>
                                            }
                                        </TextField>
                                    
                                </Grid>
                                <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Título Formacion Academica</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-title"
                                        name="title"
                                        label={otherEducationalFormValues.title === "" ? "Título Formacion Academica": ""}
                                        value={otherEducationalFormValues.title === "" ? "" : otherEducationalFormValues.title}
                                        onChange={(e)=>changeSpecialForm(e, "otherStudyUnal","otherUnalTitle")}
                                        error={!!formErrors.otherUnalTitle}
                                        helperText={formErrors.otherUnalTitle}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Número de diploma</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-diplomaNumber"
                                        name="diplomaNumber"
                                        label={otherEducationalFormValues.diplomaNumber === "" ? "Número": ""}
                                        value={otherEducationalFormValues.diplomaNumber === "" ? "" : otherEducationalFormValues.diplomaNumber}
                                        onChange={(e)=>changeSpecialForm(e, "otherStudyUnal","diplomaNumber")}
                                        error={!!formErrors.diplomaNumber}
                                        helperText={formErrors.diplomaNumber}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Número de tarjeta profesional</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-professionalCardNumber"
                                        name="professionalCardNumber"
                                        label={otherEducationalFormValues.professionalCardNumber === "" ? "Número": ""}
                                        value={otherEducationalFormValues.professionalCardNumber === "" ? "" : otherEducationalFormValues.professionalCardNumber}
                                        onChange={(e)=>changeSpecialForm(e, "otherStudyUnal","professionalCardNumber")}
                                        error={!!formErrors.professionalCardNumber}
                                        helperText={formErrors.professionalCardNumber}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Titulo de trabajo de grado</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-degreeWorkTitle"
                                        name="degreeWorkTitle"
                                        label={otherEducationalFormValues.degreeWorkTitle === "" ? "Titulo": ""}
                                        value={otherEducationalFormValues.degreeWorkTitle === "" ? "" : otherEducationalFormValues.degreeWorkTitle}
                                        onChange={(e)=>changeSpecialForm(e, "otherStudyUnal","degreeWorkTitle")}
                                        error={!!formErrors.degreeWorkTitle}
                                        helperText={formErrors.degreeWorkTitle}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Descripción del trabajo de grado</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-degreeWorkDescription"
                                        name="degreeWorkDescription"
                                        label={otherEducationalFormValues.degreeWorkDescription === "" ? "Descripción": ""}
                                        value={otherEducationalFormValues.degreeWorkDescription === "" ? "" : otherEducationalFormValues.degreeWorkDescription}
                                        onChange={(e)=>changeSpecialForm(e, "otherStudyUnal","degreeWorkDescription")}
                                        error={!!formErrors.degreeWorkDescription}
                                        helperText={formErrors.degreeWorkDescription}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={6} className="information-box-section special-box-left">
                                    <Typography variant="h5" className="bold-serif title-box-text">Fecha de inicio*</Typography>
                                    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                        <DatePicker
                                            showToolbar={false}
                                            disableFuture
                                            // disabled={otherEducationalFormValues.city>0 ? false: true}
                                            openTo="year"
                                            views={['year', 'month', 'date']}
                                            maxDate={new Date()}
                                            value={otherEducationalFormValues.dateInitFormat || null}
                                            onChange={(value)=>changeOtherStudiesUnalDate(value, 'dateInitFormat', 'otherUnalDateInitFormat')}
                                            renderInput={(params) => (
                                                <TextField
                                                disabled
                                                {...params}
                                                fullWidth
                                                name="dateInitFormat"
                                                margin="none"
                                                label={(otherEducationalFormValues.dateInitFormat && otherEducationalFormValues.dateInitFormat !== null )? "": "Seleccione Fecha dd/mm/aaaa"}
                                                error={!!formErrors.otherUnalDateInitFormat}
                                                helperText={formErrors.otherUnalDateInitFormat}
                                                InputLabelProps={{shrink: false}}
                                                variant="outlined"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Estado de la Formacion*</Typography>
                                    {eduStateArr.length>0 &&
                                        <TextField
                                            fullWidth
                                            id="outlined-select-eduStateOtherUnal"
                                            select
                                            name="educationState"
                                            label={otherEducationalFormValues.educationState === 0 ? "Seleccione": ""}
                                            value={otherEducationalFormValues.educationState === 0 ? "" : otherEducationalFormValues.educationState}
                                            onChange={(e)=>changeSpecialForm(e, "otherStudyUnal","eduStateOtherUnal")}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {
                                                eduStateArr.length> 0 &&
                                                <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                            }
                                            {
                                                eduStateArr.length> 0 ?
                                                eduStateArr.map((option) => (
                                                    
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                            :
                                                <MenuItem value={otherEducationalFormValues.educationState}></MenuItem>
                                            }
                                        </TextField>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-left">
                                    <Typography variant="h5" className="bold-serif title-box-text">Fecha de finalización{otherEducationalFormValues.educationState == 3 ? "*": ""}</Typography>
                                    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                        <DatePicker
                                            showToolbar={false}
                                            disabled={(otherEducationalFormValues.dateInitFormat && otherEducationalFormValues.educationState == 3) ? false: true}
                                            openTo="year"
                                            views={['year', 'month', 'date']}
                                            value={otherEducationalFormValues.dateFormat || null}
                                            onChange={(value)=>changeOtherStudiesUnalDate(value, 'dateFormat', 'otherUnalDateFormat')}
                                            renderInput={(params) => (
                                                <TextField
                                                disabled
                                                {...params}
                                                fullWidth
                                                name="dateFormat"
                                                margin="none"
                                                label={(otherEducationalFormValues.dateFormat && otherEducationalFormValues.dateFormat !== null ) ? "" : "Seleccione Fecha dd/mm/aaaa"}
                                                error={!!formErrors.otherUnalDateFormat}
                                                helperText={formErrors.otherUnalDateFormat}
                                                InputLabelProps={{shrink: false}}
                                                variant="outlined"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} className="information-box-section add-container">
                                    <Button disabled={!isValidOtherUnal} onClick={addOtherStudyUnal} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                                        <Typography variant="h6" className="regular-serif">
                                            Adicionar
                                        </Typography>
                                    </Button>
                                </Grid>
                                {formValues.educationUnal.educations.length>0 &&
                                    formValues.educationUnal.educations.map(otherUnal=>{
                                    return otherUnal.addByUser &&
                                        <Grid key={otherUnal.id} container item xs={12} className="information-box-section special-box-right detail-add-container">
                                            <Grid item xs={12} md={8} lg={9}>
                                                <div className="detail-add-description">
                                                    <Typography variant="h5" className="regular-serif">
                                                    {otherUnal.campusName} {otherUnal.schoolName ? ` - ${otherUnal.schoolName}` : "" } {otherUnal.curriculumName ? ` - ${otherUnal.curriculumName}` : "" }
                                                    {otherUnal.title ? `- ${otherUnal.title}` : ''} - Colombia {otherUnal.departmentName ? ` - ${otherUnal.departmentName}` : "" }{otherUnal.cityName ? ` - ${otherUnal.cityName}` : "" }
                                                    </Typography>
                                                    <Typography variant='h6' className='regular-serif'>
                                                        {otherUnal.diplomaNumber ? `No Diploma: ${otherUnal.diplomaNumber}`: ""}{otherUnal.professionalCardNumber ? ` - No Tarjeta: ${otherUnal.professionalCardNumber}`: ""}
                                                    </Typography>
                                                    <Typography variant='h6' className='regular-serif'>
                                                        {otherUnal.degreeWorkTitle ? `Trabajo de Grado: ${otherUnal.degreeWorkTitle}`: ""}{otherUnal.degreeWorkDescription ? ` - Descripcion: ${otherUnal.degreeWorkDescription}`: ""}
                                                    </Typography>
                                                    <Typography variant='h6' className='regular-serif'>
                                                        {otherUnal.dateInit ? `${moment(otherUnal.dateInit).format("DD/MM/YYYY")} - `: ''} {otherUnal.date ? moment(otherUnal.date).format("DD/MM/YYYY") : ''} {otherUnal.educationState ? `- ${eduStateArr[otherUnal.educationState-1].name}`  : ''}
                                                        {otherUnal.typeStudy ? `- ${typeStudyArr[otherUnal.typeStudy - 1 ].name}` : ""}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={3}>
                                                <div className="delete-container">
                                                    <Button onClick={(e)=>deleteOtherStudyUnal(e, otherUnal.id ? otherUnal.id : null)} variant="contained" className="delete-btn" endIcon={<DeleteIcon/>} >
                                                        <Typography variant="h6" className="regular-serif">
                                                            Eliminar
                                                        </Typography>
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    })
                                }
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                </Grid> */}

                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccoridon('panel3')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                            >
                            <Typography variant="h2" className="bold-serif">Otros estudios realizados</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row" className="information-box-margin">
                                <Grid container item xs={12} className="complementary-text-container">
                                    <Typography variant="h5" className="regular-serif">
                                        En está sección puede adicionar o eliminar otros estudios, luego de adicionar debe Guardar los cambios.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}  className="special-box-right information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Tipo de formación*</Typography>
                                        {typeStudyArr.length > 0 &&
                                            <TextField
                                                fullWidth
                                                id="outlined-select-typeStudyOther"
                                                select
                                                name="typeStudy"
                                                label={otherFormValues.typeStudy === 0 ? "Seleccione": ""}
                                                value={otherFormValues.typeStudy === 0 ? "" : otherFormValues.typeStudy}
                                                onChange={(e)=>changeSpecialForm(e, "otherStudy","typeStudyOther")}
                                                InputLabelProps={{shrink: false}}
                                                margin="none"
                                                variant="outlined"
                                            >
                                                {
                                                    typeStudyArr.length > 0 &&
                                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                                }
                                                {
                                                    typeStudyArr.length> 0 ?
                                                    typeStudyArr.map((option) => (
                                                        
                                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))
                                                :
                                                    <MenuItem value={otherFormValues.typeStudy}></MenuItem>
                                                }
                                            </TextField>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={6}  className="special-box-left information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Nivel Educativo*</Typography>
                                        {newAcademicLevelArr.length > 0 &&
                                            <TextField
                                                fullWidth
                                                id="outlined-select-academicLevelOther"
                                                select
                                                name="academicLevel"
                                                disabled={otherFormValues.typeStudy === 0 ? true: false}
                                                label={otherFormValues.academicLevel === 0 ? "Seleccione": ""}
                                                value={otherFormValues.academicLevel === 0 ? "" : otherFormValues.academicLevel}
                                                onChange={(e)=>changeSpecialForm(e, "otherStudy","academicLevelOther")}
                                                InputLabelProps={{shrink: false}}
                                                margin="none"
                                                variant="outlined"
                                            >
                                                {
                                                    newAcademicLevelArr.length > 0 &&
                                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                                }
                                                {
                                                    newAcademicLevelArr.length> 0 ?
                                                    newAcademicLevelArr.map((option) => (
                                                        
                                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))
                                                :
                                                    <MenuItem value={otherFormValues.academicLevel}></MenuItem>
                                                }
                                            </TextField>
                                        }
                                    </Grid>
                                <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Nombre de la Institución educativa*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-institution"
                                        name="institution"
                                        label={otherFormValues.institution === "" ? "Nombre de la institución": ""}
                                        value={otherFormValues.institution === "" ? "" : otherFormValues.institution}
                                        onChange={(e)=>changeSpecialForm(e, "otherStudy","institution")}
                                        error={!!formErrors.institution}
                                        helperText={formErrors.institution}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>
                                {/* COMMENT IN CASE OF FUTURE USE */}
                                {/* <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Facultad</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-faculty"
                                        name="faculty"
                                        label={otherFormValues.faculty === "" ? "Nombre de la facultad": ""}
                                        value={otherFormValues.faculty === "" ? "" : otherFormValues.faculty}
                                        onChange={(e)=>changeSpecialForm(e, "otherStudy","faculty")}
                                        error={!!formErrors.faculty}
                                        helperText={formErrors.faculty}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid> */}
                                {/* <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Nombre del programa*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-program"
                                        name="program"
                                        label={otherFormValues.program === "" ? "Nombre del programa": ""}
                                        value={otherFormValues.program === "" ? "" : otherFormValues.program}
                                        onChange={(e)=>changeSpecialForm(e, "otherStudy", "program")}
                                        error={!!formErrors.program}
                                        helperText={formErrors.program}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid> */}
                                <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Título Formacion Academica*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-title"
                                        name="title"
                                        label={otherFormValues.title === "" ? "Título obtenido": ""}
                                        value={otherFormValues.title === "" ? "" : otherFormValues.title}
                                        onChange={(e)=>changeSpecialForm(e, "otherStudy","title")}
                                        error={!!formErrors.title}
                                        helperText={formErrors.title}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Area de Conocimiento</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-knowAreaOther"
                                            select
                                            name="knowArea"
                                            label={otherFormValues.knowArea === 0 ? "Seleccione": ""}
                                            value={otherFormValues.knowArea === 0 ? "" : otherFormValues.knowArea}
                                            onChange={(e)=>changeSpecialForm(e, "otherStudy","knowAreaOther")}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined">
                                            {
                                                areaKnowledgeArr.length> 0 ?
                                                areaKnowledgeArr.map((option) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                            :
                                                <MenuItem value={otherFormValues.knowArea}></MenuItem>
                                            }
                                        </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Duracion en Horas</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-hoursDuration"
                                        name="hoursDuration"
                                        label={!otherFormValues.hoursDuration ? "Número": ""}
                                        value={!otherFormValues.hoursDuration ? "" : otherFormValues.hoursDuration}
                                        onChange={(e)=>changeSpecialForm(e, "otherStudy","hoursDuration")}
                                        error={!!formErrors.hoursDuration}
                                        helperText={formErrors.hoursDuration}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Número de diploma o acta de grado</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-diplomaNumber"
                                        name="diplomaNumber"
                                        label={otherFormValues.diplomaNumber === "" ? "Número": ""}
                                        value={otherFormValues.diplomaNumber === "" ? "" : otherFormValues.diplomaNumber}
                                        onChange={(e)=>changeSpecialForm(e, "otherStudy","diplomaNumberOther")}
                                        error={!!formErrors.diplomaNumberOther}
                                        helperText={formErrors.diplomaNumberOther}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Número de tarjeta profesional</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-professionalCardNumber"
                                        name="professionalCardNumber"
                                        label={otherFormValues.professionalCardNumber === "" ? "Número": ""}
                                        value={otherFormValues.professionalCardNumber === "" ? "" : otherFormValues.professionalCardNumber}
                                        onChange={(e)=>changeSpecialForm(e, "otherStudy","professionalCardNumberOther")}
                                        error={!!formErrors.professionalCardNumberOther}
                                        helperText={formErrors.professionalCardNumberOther}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid container item xs={12} alignItems="center">
                                    <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                        <Typography variant="h5" className="bold-serif title-box-text">País*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-country"
                                            select
                                            name="country"
                                            label={otherFormValues.country === 0 ? "Seleccione": ""}
                                            value={otherFormValues.country === 0 ? "" : otherFormValues.country}
                                            onChange={(e)=>changeSpecialForm(e, "otherStudy", "otherCountry")}
                                            error={!!formErrors.otherCountry}
                                            helperText={formErrors.otherCountry}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {
                                                countryCitiesArray && countryCitiesArray.length> 0 &&
                                                <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                            }
                                            {countryCitiesArray && countryCitiesArray.length>0 ?
                                                countryCitiesArray.map((option: IListParametersInfo) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.grandParentName}
                                                    </MenuItem>
                                                ))
                                            :
                                                    <MenuItem value={otherFormValues.country}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                        <Typography variant="h5" className="bold-serif title-box-text">Departamento</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-departament"
                                            select
                                            name="departament"
                                            label={otherFormValues.departament === 0 ? "Seleccione": ""}
                                            value={otherFormValues.departament === 0 ? "" : otherFormValues.departament}
                                            onChange={(e)=>changeSpecialForm(e, "otherStudy", "otherDepartament")}
                                            error={!!formErrors.otherDepartament}
                                            helperText={formErrors.otherDepartament}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                            disabled={(departamentArray && otherFormValues.country>0 && otherFormValues.country === 170 ? false : true)}
                                        >
                                            {departamentArray && departamentArray.length>0 ?
                                                departamentArray.map((option: IGeneralParameter) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                    {option.name}
                                                    </MenuItem>
                                                ))
                                            :
                                                <MenuItem value={otherFormValues.departament}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                        <Typography variant="h5" className="bold-serif title-box-text">Municipio</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-city"
                                            select
                                            name="city"
                                            label={otherFormValues.city === 0 ? "Seleccione": ""}
                                            value={otherFormValues.city === 0 ? "" : otherFormValues.city}
                                            onChange={(e)=>changeSpecialForm(e, "otherStudy", "otherCity")}
                                            error={!!formErrors.otherCity}
                                            helperText={formErrors.otherCity}
                                            InputLabelProps={{shrink: false}}
                                            margin="none"
                                            variant="outlined"
                                            disabled={(citiesOther && citiesOther.length>0 && ((otherFormValues.country === 170 && otherFormValues.departament >0) || (otherFormValues.country !== 170 && otherFormValues.country > 0)) ? false : true)}
                                        >
                                            {citiesOther && citiesOther.length>0 ?
                                                citiesOther.map((option: IGeneralParameter) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                    {option.name}
                                                    </MenuItem>
                                                ))
                                            :
                                                <MenuItem value={otherFormValues.city}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} alignItems="flex-end">
                                    <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                        <Typography variant="h5" className="bold-serif title-box-text">Fecha de inicio</Typography>
                                        <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                        <DatePicker
                                            showToolbar={false}
                                            disableFuture
                                            
                                            openTo="year"
                                            views={['year', 'month', 'date']}
                                            maxDate={new Date()}
                                            value={otherFormValues.dateInitFormat || null}
                                            onChange={(value)=>changeOtherStudiesDate(value, 'dateInitFormat', 'otherDateInitFormat')}
                                            renderInput={(params) => (
                                                <TextField
                                                disabled
                                                {...params}
                                                fullWidth
                                                name="dateInitFormat"
                                                margin="none"
                                                label={(otherFormValues.dateInitFormat && otherFormValues.dateInitFormat !== null )? "": "Seleccione Fecha dd/mm/aaaa"}
                                                error={!!formErrors.otherDateInitFormat}
                                                helperText={formErrors.otherDateInitFormat}
                                                InputLabelProps={{shrink: false}}
                                                variant="outlined"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4} className="special-box-right information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Estado de la Formacion*</Typography>
                                        {eduStateArr.length>0 &&
                                            <TextField
                                                fullWidth
                                                id="outlined-select-eduStateOther"
                                                select
                                                name="educationState"
                                                label={otherFormValues.educationState === 0 ? "Seleccione": ""}
                                                value={otherFormValues.educationState === 0 ? "" : otherFormValues.educationState}
                                                disabled= {!otherFormValues.typeStudy}
                                                onChange={(e)=>changeSpecialForm(e, "otherStudy","eduStateOther")}
                                                InputLabelProps={{shrink: false}}
                                                margin="none"
                                                variant="outlined"
                                            >
                                                {
                                                    eduStateArr.length> 0 &&
                                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                                }
                                                {
                                                    eduStateArr.length> 0 ?
                                                    eduStateArr.map((option) => (
                                                        
                                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))
                                                :
                                                    <MenuItem value={otherFormValues.educationState}></MenuItem>
                                                }
                                            </TextField>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                        <Typography variant="h5" className="bold-serif title-box-text">Fecha de finalización{otherFormValues.educationState == 3 ? "*": ""}</Typography>
                                        <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                        <DatePicker
                                            showToolbar={false}
                                            disabled={otherFormValues.educationState == 3 ? false: true}
                                            openTo="year"
                                            views={['year', 'month', 'date']}
                                            value={otherFormValues.dateEndFormat || null}
                                            onChange={(value)=>changeOtherStudiesDate(value, 'dateEndFormat', 'otherDateEndFormat')}
                                            renderInput={(params) => (
                                                <TextField
                                                disabled
                                                {...params}
                                                fullWidth
                                                name="dateEndFormat"
                                                margin="none"
                                                label={(otherFormValues.dateEndFormat && otherFormValues.dateEndFormat !== null )? "": "Seleccione Fecha dd/mm/aaaa"}
                                                error={!!formErrors.otherDateEndFormat}
                                                helperText={formErrors.otherDateEndFormat}
                                                InputLabelProps={{shrink: false}}
                                                variant="outlined"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                    </Grid>
                                    
                                    <Grid item xs={12} className="information-box-section add-container">
                                        <Button disabled={!isValidOther} onClick={addOtherStudy} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                                            <Typography variant="h6" className="regular-serif">
                                                Adicionar
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                                {formValues.otherStudies.length>0 &&
                                    formValues.otherStudies.map(other=>{
                                        const countryObj = countryCitiesArray.find(item=>item.id === other.country);
                                        const countryName = (other.country>0 && countryObj) ? countryObj.grandParentName : "";
                                        const cityObj = citiesArray.find(item=>item.parameter_code === other.city);
                                        const cityName = (other.city>0 && cityObj) ? cityObj.name : "";
                                        const depObj = departamentArray.find(item=>item.parameter_code === other.departament);
                                        const depName = (other.departament>0 && depObj) ? depObj.name : "";
                                        return(
                                            <Grid key={other.id} container item xs={12} className="information-box-section special-box-right detail-add-container">
                                                <Grid item xs={12} md={8} lg={9}>
                                                    <div className="detail-add-description">
                                                        <Typography variant="h5" className="regular-serif">
                                                            {other.typeStudy ? `Tipo de formación: ${typeStudyArr[other.typeStudy - 1 ].name} - ` : ""} {other.academicLevel ? `Nivel Educativo:${constDataAcLevel[other.academicLevel - 1 ].name} - ` : ""}
                                                            {other.institution ? `Nombre de la Institución educativa: ${other.institution} - `: ''}{other.title ? `Titulo: ${other.title} - ` : "" } {other.knowArea ? `Área de Conocimiento: ${ areaKnowledgeArr[other.knowArea - 1].name}` : "" }
                                                        </Typography>
                                                        <Typography variant='h6' className='regular-serif'>
                                                            {other.hoursDuration ? `Duración en Horas: ${other.hoursDuration} - ` : "" }{other.diplomaNumber ? `Diploma o acta de grado: ${other.diplomaNumber} - ` : "" }{other.professionalCardNumber ? `Tarjeta Profesional: ${other.professionalCardNumber}` : "" } 
                                                        </Typography> 
                                                        <Typography variant='h6' className='regular-serif'>
                                                            {countryName ? `Pais: ${countryName} - ` : "" } {depName ? `Departamento: ${depName} - ` : "" } {cityName ? `Municipio: ${cityName}` : "" }
                                                        </Typography> 
                                                        <Typography variant='h6' className='regular-serif'>
                                                            {other.dateInit ? `Fecha de inicio: ${moment(other.dateInit).format("DD/MM/YYYY")} - `: ''} {other.dateEnd ? `Fecha de finalización: ${moment(other.dateEnd).format("DD/MM/YYYY")}` : ''} {other.educationState ? `- Estado de la Formacion: ${constDataEduState[other.educationState-1].name}`  : ''}
                                                            
                                                        </Typography> 
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4} lg={3}>
                                                    <div className="delete-container">
                                                        <Button onClick={(e)=>deleteOtherStudy(e, other.id ? other.id : null)} variant="contained" className="delete-btn" endIcon={<DeleteIcon/>} >
                                                            <Typography variant="h6" className="regular-serif">
                                                                Eliminar
                                                            </Typography>
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChangeAccoridon('panel4')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                            >
                            <Typography variant="h2" className="bold-serif">Becas</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row" className="information-box-margin">
                                <Grid container item xs={12} className="complementary-text-container">
                                    <Typography variant="h5" className="regular-serif">
                                        En está sección puede adicionar o eliminar sus becas, luego de adicionar debe Guardar los cambios.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className="special-box-right information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Nombre de la beca*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-scholarShip-name"
                                        name="name"
                                        label={scholarchipFormValues.name === "" ? "Ingrese nombre de la beca": ""}
                                        value={scholarchipFormValues.name === "" ? "" : scholarchipFormValues.name}
                                        onChange={(e)=>changeSpecialForm(e, "scholarchip", "name")}
                                        error={!!formErrors.name}
                                        helperText={formErrors.name}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} className="special-box-right information-box-section">
                                    <Typography variant="h5" className="bold-serif title-box-text">Entidad que otorga la beca*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-scholarShip-entity"
                                        name="entity"
                                        label={scholarchipFormValues.entity === "" ? "Ingrese nombre de la entidad": ""}
                                        value={scholarchipFormValues.entity === "" ? "" : scholarchipFormValues.entity}
                                        onChange={(e)=>changeSpecialForm(e, "scholarchip", "entity")}
                                        error={!!formErrors.entity}
                                        helperText={formErrors.entity}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-right">
                                    <Typography variant="h5" className="bold-serif title-box-text">País*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-scholarchip-country"
                                        select
                                        name="country"
                                        label={scholarchipFormValues.country === 0 ? "Seleccione": ""}
                                        value={scholarchipFormValues.country === 0 ? "" : scholarchipFormValues.country}
                                        onChange={(e)=>changeSpecialForm(e, "scholarchip", "scholarchipCountry")}
                                        error={!!formErrors.scholarchipCountry}
                                        helperText={formErrors.scholarchipCountry}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                        {
                                            countryCitiesArray && countryCitiesArray.length> 0 &&
                                            <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                        }
                                        {countryCitiesArray && countryCitiesArray.length>0 ?
                                            countryCitiesArray.map((option: IListParametersInfo) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.grandParentName}
                                                </MenuItem>
                                            ))
                                        :
                                            <MenuItem value={scholarchipFormValues.country}></MenuItem>
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}  className="information-box-section special-box-left">
                                    <Typography variant="h5" className="bold-serif title-box-text">Municipio</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-scholarchip-city"
                                        select
                                        name="city"
                                        label={scholarchipFormValues.city === 0 ? "Seleccione": ""}
                                        value={scholarchipFormValues.city === 0 ? "" : scholarchipFormValues.city}
                                        onChange={(e)=>changeSpecialForm(e, "scholarchip", "scholarchipCity")}
                                        error={!!formErrors.scholarchipCity}
                                        helperText={formErrors.scholarchipCity}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                        disabled={(citiesScholarchip.length>0 && scholarchipFormValues.country>0)? false : true}
                                    >
                                        {citiesScholarchip && citiesScholarchip.length>0 ?
                                            citiesScholarchip.map((option: IGeneralParameter) => (
                                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                {option.name}
                                                </MenuItem>
                                            ))
                                        :
                                            <MenuItem value={scholarchipFormValues.city}></MenuItem>
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-right">
                                    <Typography variant="h5" className="bold-serif title-box-text">Fecha de inicio*</Typography>
                                    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                        <DatePicker
                                            showToolbar={false}
                                            disableFuture
                                            disabled={scholarchipFormValues.country>0 ? false: true}
                                            openTo="year"
                                            views={['year', 'month', 'date']}
                                            maxDate={new Date()}
                                            value={scholarchipFormValues.startDateFormat || null}
                                            onChange={(value)=>changeScholarchipDate(value, 'startDateFormat', 'startDateFormat')}
                                            renderInput={(params) => (
                                                <TextField
                                                disabled
                                                {...params}
                                                fullWidth
                                                name="startDateFormat"
                                                margin="none"
                                                label={(scholarchipFormValues.startDateFormat && scholarchipFormValues.startDateFormat !== null )? "": "Seleccione Fecha dd/mm/aaaa"}
                                                error={!!formErrors.startDateFormat}
                                                helperText={formErrors.startDateFormat}
                                                InputLabelProps={{shrink: false}}
                                                variant="outlined"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={6} className="information-box-section special-box-left">
                                    <Typography variant="h5" className="bold-serif title-box-text">Fecha de finalización*</Typography>
                                    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                        <DatePicker
                                            showToolbar={false}
                                            disabled={scholarchipFormValues.startDateFormat ? false: true}
                                            openTo="year"
                                            views={['year', 'month', 'date']}
                                            value={scholarchipFormValues.endDateFormat || null}
                                            onChange={(value)=>changeScholarchipDate(value, 'endDateFormat', 'endDateFormat')}
                                            renderInput={(params) => (
                                                <TextField
                                                disabled
                                                {...params}
                                                fullWidth
                                                name="endDateFormat"
                                                margin="none"
                                                label={(scholarchipFormValues.endDateFormat && scholarchipFormValues.endDateFormat !== null )? "": "Seleccione Fecha dd/mm/aaaa"}
                                                error={!!formErrors.endDateFormat}
                                                helperText={formErrors.endDateFormat}
                                                InputLabelProps={{shrink: false}}
                                                variant="outlined"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider className={classes.divider} />
                                </Grid>

                                <Grid item xs={12} className="information-box-section special-box-left add-container">
                                    <Button disabled={!isValidScholarchip} onClick={addScholarchip} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                                        <Typography variant="h6" className="regular-serif">
                                            Adicionar
                                        </Typography>
                                    </Button>
                                </Grid>
                                {formValues.scholarships.length>0 &&
                                    formValues.scholarships.map(scholarchip=>{
                                        const countryObj = countryCitiesArray.find(item=>item.id === scholarchip.country);
                                        const countryName = (scholarchip.country>0 && countryObj) ? countryObj.grandParentName : "";
                                        const cityObj =citiesArray.find(item=>item.parameter_code === scholarchip.city);
                                        const cityName = (scholarchip.city>0 && cityObj) ? cityObj.name : "";
                                        return(
                                            <Grid key={scholarchip.id} container item xs={12} className="information-box-section detail-add-container">
                                                <Grid item xs={12} md={8} lg={9}>
                                                    <div className="detail-add-description">
                                                        <Typography variant="h5" className="regular-serif">
                                                            {scholarchip.name} - {scholarchip.entity} - {countryName} - {cityName} 
                                                        </Typography>
                                                        <Typography variant="h6" className="regular-serif">
                                                            {moment(scholarchip.startDate).format("DD/MM/YYYY")} - {moment(scholarchip.endDate).format("DD/MM/YYYY")}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4} lg={3}>
                                                    <div className="delete-container">
                                                        <Button onClick={(e)=>deleteScholarchip(e, scholarchip.id ? scholarchip.id : null)} variant="contained" className="delete-btn" endIcon={<DeleteIcon/>} >
                                                            <Typography variant="h6" className="regular-serif">
                                                                Eliminar
                                                            </Typography>
                                                        </Button>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} className="special-box-both">
                                                    <Divider className={classes.divider} />
                                                </Grid>
                                                <Grid container item xs={12} alignItems="center" className="special-box-both visibility-curriculum-container">
                                                    <VisibilityIcon/>
                                                    <FormControlLabel
                                                        value={scholarchip.visible}
                                                        control={
                                                            <BlueSwitch
                                                                size="small"
                                                                checked={scholarchip.visible}
                                                                onChange={(e)=>changeCheckBoxScholarchip(e,scholarchip.id ? scholarchip.id : null)}
                                                                name="visble"
                                                            />
                                                        }
                                                        label="Mostrar esta información en su hoja de vida"
                                                        labelPlacement="start"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{marginRight:'10px'}}>
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChangeAccoridon('panel5')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                            >
                            <Typography variant="h2" className="bold-serif">Idiomas</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row" className="information-box-margin">
                                <Grid container item xs={12} className="complementary-text-container">
                                        <Typography variant="h5" className="regular-serif">
                                            En está sección puede adicionar o eliminar sus idiomas, luego de adicionar debe Guardar los cambios.
                                        </Typography>
                                    </Grid>
                                <Grid item xs={12} sm={6} lg={5} className="information-box-section special-box-right">
                                    <Typography variant="h5" className="bold-serif title-box-text">Idioma*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-languages-languages-language"
                                        select
                                        name="language"
                                        label={languageFormValues.language === 0 ? "Seleccione": ""}
                                        value={languageFormValues.language === 0 ? "" : languageFormValues.language}
                                        onChange={(e)=>changeSpecialForm(e, "languages", "language")}
                                        error={!!formErrors.language}
                                        helperText={formErrors.language}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                        {
                                            allLanguages && allLanguages.length>0 ?
                                            allLanguages.map((option: IGeneralParameter) => (
                                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                {option.name}
                                                </MenuItem>
                                            ))
                                        :
                                            <MenuItem value={languageFormValues.language}></MenuItem>
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={5} className="information-box-section special-box-both">
                                    <Typography variant="h5" className="bold-serif title-box-text">Nivel*</Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-scholarchip-languages-level"
                                        select
                                        name="level"
                                        label={languageFormValues.level === 0  ? "Seleccione": ""}
                                        value={languageFormValues.level === 0  ? "" : languageFormValues.level}
                                        onChange={(e) => changeSpecialForm(e, "languages", "level")}
                                        disabled={languageFormValues.native ? true : false}
                                        error={!!formErrors.level}
                                        helperText={formErrors.level}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                        {
                                            languageLevels && languageLevels.length>0 ?
                                            languageLevels.map((option: IGeneralParameter) => (
                                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                {option.name}
                                                </MenuItem>
                                            ))
                                        :
                                            <MenuItem value={languageFormValues.level}></MenuItem>
                                        }
                                    </TextField>
                                </Grid>
                                <Grid container item xs={12} sm={6} lg={2} className="bold-serif title-box-text special-box-left checkbox-top-curriculum-container" justify="center">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                            checked={languageFormValues.native}
                                            onChange={changeCheckBoxLanguage}
                                            name="native"
                                        />}
                                        label="Nativo"
                                        labelPlacement="top"
                                        className="checkbox-top"
                                    />
                                </Grid>
                                <Grid item xs={12} className="information-box-section special-box-left add-container">
                                    <Button disabled={!isValidAddLanguage} onClick={addLanguage} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                                        <Typography variant="h6" className="regular-serif">
                                            Adicionar
                                        </Typography>
                                    </Button>
                                </Grid>
                                {formValues.languages.length>0 &&
                                    <Grid container item xs={12} className="information-box-section detail-add-container">
                                        {formValues.languages.map((language, index)=>{
                                            const languageObj = allLanguages.find(item=>item.parameter_code === language.language);
                                            const languageName = (language.language>0 && languageObj) ? languageObj.name : "";
                                            const levelObj = languageLevels.find(item=>item.parameter_code === language.level);
                                            const levelName = (language.level>0 && levelObj) ? levelObj.name : "";
                                            return(
                                                <Grid container key={index}>
                                                    <Grid item xs={12} md={8} lg={9}>
                                                        <div className="detail-add-description">
                                                            <Typography variant="h5" className="regular-serif">
                                                                {languageName} - {levelName} {language.native ? "Nativo" : ""}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} md={4} lg={3}>
                                                        <div className="delete-container">
                                                            <Button onClick={(e)=>deleteLanguage(e, language.id ? language.id : null)} variant="contained" className="delete-btn" endIcon={<DeleteIcon/>} >
                                                                <Typography variant="h6" className="regular-serif">
                                                                    Eliminar
                                                                </Typography>
                                                            </Button>
                                                        </div>
                                                    </Grid>
                                                    {formValues.languages && index !== formValues.languages.length-1 &&
                                                        <Grid item xs={12} className="special-box-both">
                                                            <Divider className={classes.divider} />
                                                        </Grid>
                                                    }
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <>
                {
                    userContext.roleSelected === 9 ?
                    <></>
                    :
                    <>
                        <Grid container item xs={12}>
                            <Grid container item xs={12} md={3} lg={6} className="step-curriculum-buttons">
                                <Button onClick={backBtnAction} className="back-button" variant="contained" size="small">
                                    <Typography variant="h5" className="regular-serif">Regresar</Typography>
                                </Button>
                            </Grid>
                            <Grid container item xs={12} md={9} lg={6} className="buttons-curriculum-form-container">
                                <Button onClick={saveAcademicInfo} className="save-button" variant="contained" size="small">
                                    <Typography variant="h5" className="regular-serif">Guardar</Typography>
                                </Button>
                                <Button onClick={nextBtnAction} className="next-button" variant="contained" size="small">
                                    <Typography variant="h5" className="regular-serif">Siguiente</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
                </>
            </Grid>
            <Grid container item xs={12} lg={2} justify="center" style={{height:'fit-content'}}>
                <NotificationBox 
                    bgColor="#CFF4FC"
                    title="Porcentaje de Completitud"
                    titleColor="#055160"
                    description="Este es el % de los campos obligatorios necesarios para entrar a la bolsa de empleo"
                    descColor="#055160"
                    extra={<CircularProgressDet value={userContext.userPercentage?.total ? userContext.userPercentage.total : 0} size={90} descColor ={"#055160"}/>}
                    extra2 = {<PercentageInfo data={userContext.userPercentage}/>}
                />
                
                <div className='space-notification-boxes'> </div>
                <NotificationBox
                    bgColor="#CFF4FC"
                    title="Información academica"
                    titleColor="#055160"
                    description="Por favor actualice su información académica. Los campos marcados con asterisco (*) son obligatorios. En algunos campos puede seleccionar si desea que la información sea visible en su Hoja de vida."
                    descColor="#055160"
                    icon={<ErrorOutlineIcon style={{color:'#055160'}} fontSize="large"/>}
                />
                
                
                <div className="space-notification-boxes">
                    <Link target="_blank" href="https://sie.unal.edu.co/link/commentsForm.do"  rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <NotificationBox
                            bgColor="#CFF4FC"
                            title="Estudios realizados en la UNAL"
                            titleColor="#055160"
                            descColor="#055160"
                            icon={<ErrorOutlineIcon style={{color:'#055160'}} fontSize="large"/>}
                        >
                            Si encuentra alguna inconsistencia en los campos de la sección
                            estudios realizados en la UNAL, puede notificar a la siguiente dirección: <span className="link-responsive">https://sie.unal.edu.co/link/</span> commentsForm.do.
                        </NotificationBox>
                    </Link>
                </div>
            </Grid>
            <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
            <LoadingLockPage load={promiseInProgress}/>
            <LoadingLockPage load={initLoad}/>
        </>
    )
}

export default AcademicInformation