import React, { useState, useEffect } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { DataGrid, GridColDef, GridOverlay, GridColParams, GridCellParams, GridPageChangeParams, GridBaseComponentProps } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import './AttendeesList.scss';
import UserContext from '../../../utils/UserContext';
import { IGetTrainingEventsGuests } from '../../../interfaces/IGetTrainingEventsGuests';

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 14,
            padding: '5px 6px 10px 12px',
            height: '30px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
        },
    }),
)(InputBase);

interface AttendeesListProps {
    events: Array<IGetTrainingEventsGuests>;
    pageSize: number;
    onChanhePageSize: any;
    initPage: number;
    setInitPage: any;
}

const AttendeesList: React.FC<AttendeesListProps> = ({ events, pageSize, onChanhePageSize, initPage, setInitPage }) => {
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const columnsAnnouncement: GridColDef[] = [
        {
            field: 'names', headerName: 'Nombre', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Nombres'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'surnames', headerName: 'Apellidos', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Apellidos'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'doc', headerName: 'Documento', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Documento'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'email', headerName: 'Email', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Email'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'contactNumber', headerName: 'Numero de Contacto', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Numero de Contacto'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'userType', headerName: 'Tipo de Usuario', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Tipo de Usuario'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'campus', headerName: 'Sede', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Sede'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'school', headerName: 'Facultad', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Facultad'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'curriculum', headerName: 'Programa Curricular', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Programa Curricular'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'modality', headerName: 'Modalidad', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Modalidad'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'country', headerName: 'Pais', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Pais'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'department', headerName: 'Departamento', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Departamento'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'city', headerName: 'Municipio', width: 200, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'Municipio'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
        {
            field: 'attend', headerName: 'Asistio', width: 110, renderHeader: (params: GridColParams) => (
                <strong className="column-states">
                    <span className="column-header-title">
                        {'¿Asistio?'}
                    </span>
                    <UnfoldMoreIcon fontSize="small" className="color-arrows" />
                </strong>
            ), renderCell: (params: GridCellParams) => {
                return (
                    <span className="name-cell" >{params.value}</span>
                )
            }
        },
    ];

    // useEffect(() => {
    //     const getInitialData = async () => {
    //         const reqArray: Array<IGeneralParameter> = await getTYPE_EVENT();
    //         reqArray.sort((a, b) => a.parameter_code - b.parameter_code)
    //         setTypeEventArray(reqArray);
    //     }
    //     getInitialData()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])


    const handlePage = (pageParams: GridPageChangeParams) => {
        setInitPage(1)
    }

    return (
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid rowHeight={100} autoHeight className="data-grid-list" disableColumnMenu rows={events} columns={columnsAnnouncement} pageSize={pageSize} pagination density="compact" disableSelectionOnClick onPageChange={(params: GridPageChangeParams) => handlePage(params)}
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                        Footer: (props) => CustomFooter(props, initPage, onChanhePageSize, pageSize)
                    }}
                />
                
                <LoadingLockPage load={promiseInProgress} />
            </Grid>
        </Grid>
    );
}

const CustomFooter = (props: GridBaseComponentProps, initPage: number, onChanhePageSize?: any, valueRows?: number) => {
    const { state, api } = props;
    const rowsPerPageOptions = [5, 10, 25, 50, 100];

    useEffect(() => {
        if (initPage === 0) {
            api.current.setPage(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initPage]);

    return (
        <>
            <div className="footer-container">
                <div className="rows-number-container">
                    <span className="rows-number-text">Mostrar</span>
                    <Select
                        id="demo-simple-select"
                        value={valueRows}
                        onChange={onChanhePageSize}
                        input={<BootstrapInput />}
                    >
                        {rowsPerPageOptions.map(item => (
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                    <span className="rows-number-text">Registros</span>
                </div>
                <Pagination
                    className="pagination-container"
                    color="primary"
                    page={state.pagination.page + 1}
                    count={state.pagination.pageCount}
                    onChange={(event, value) => api.current.setPage(value - 1)}
                    showFirstButton
                    showLastButton
                />
            </div>
        </>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large" />
            <div className="not-results-text">Sin registros</div>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps) => <KeyboardArrowUpIcon fontSize="small" />
const customSortDesc = (props: GridBaseComponentProps) => <KeyboardArrowDownIcon fontSize="small" />

export default AttendeesList;