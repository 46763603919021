import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import BusinessIcon from '@material-ui/icons/Business';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import PersonIcon from '@material-ui/icons/Person';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from '@material-ui/core/Button';
import ButtonColors from '../ButtonColors';
import Egresados from '../../../assets/images/lg_sie1.svg';
import { getOffersNumberByState } from '../../../services/LaboralOfferService';
import UserContext from '../../../utils/UserContext';
import { isLogin } from '../../../services/AuthService';
import { containsAny, logoutAndReset } from '../../../helpers/sharedFunctions';
import { IRole } from '../../../interfaces/IRole';
import { getlistRoles } from '../../../services/AdminServices';
import { permissionAdminSeeNewOffers } from '../../../utils/PermissionsVariables';

import './HeaderBox.scss';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);



const HeaderBox: React.FC = () => {
  const classes = useStyles();
  const userContext = React.useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [offersNumber, setOffersNumber] = useState<string>("");
  const [showBtnLogin, setShowBtnLoggin] = useState<boolean>(true);
  const [roleListUser, setRoleListUser] = useState<Array<IRole>>([]);
  const [urlRedirect, setUrlRedirect] = useState<string>("");
  const urlYoutube = "https://www.youtube.com/channel/UCm16-XHzO4YpZ4cPV_jmYng";
  const urlTwitter = "https://twitter.com/EgresadosUN_Nal";
  const urlFacebook = "https://www.facebook.com/UNEgresadosUNAL/";
  const urlPath = window.location.href;

  useEffect(()=>{
    if(urlPath.includes("/dashboard") || urlPath.includes("/login") || urlPath.includes("/cambio-contrasena")){
      setShowBtnLoggin(false)
    } else {
      setShowBtnLoggin(true)
    }
    const getData = async() => {
      const responseOffersNumber = await getOffersNumberByState('En definición');
      if(responseOffersNumber.status === 200){
        const OffNumber = (await responseOffersNumber.json()).jobOffers;
        setOffersNumber(OffNumber.toString());
      }
      const responseRoleList = await getlistRoles();
      if(responseRoleList.status === 200){
          const resultList: Array<IRole> = (await responseRoleList.json()).rolesInfo;
          if(resultList.length>0){
            if(userContext.rolesUser.length>0){
              let newRoleList: Array<IRole> = [];
              userContext.rolesUser.forEach((role: number) => {
                const roleElm = resultList.find(item=>item.id === role);
                if(roleElm){newRoleList.push(roleElm);}
              });
              setRoleListUser(newRoleList);
            }
          }
      }
    }
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[urlPath])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
    logoutAndReset(userContext);
    setUrlRedirect("/")
  }

  const redirectToYoutube =()=>{
    window.open(urlYoutube, '_blank')
  }
  const redirectToTwitter =()=>{
    window.open(urlTwitter, '_blank')
  }
  const redirectToFacebook =()=>{
    window.open(urlFacebook, '_blank')
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleChangeRole = (roleToChange: IRole)=>{
    setMobileMoreAnchorEl(null);
    setAnchorEl(null);
    const value = roleToChange.id || 0;
    const temp = jwt.sign({ roleSelected: value, temp: userContext.temporaryKey || false}, '1234@#');
    sessionStorage.setItem('temp', temp)
    let urlRoute = ""
    if(value>0){
      if(value === 1){
        if(urlPath.includes("/egresado/dashboard")){
          window.location.reload();
        } else {
          urlRoute = "/egresado/dashboard";
        }
      } else if (value === 2 || value === 3){
        if(urlPath.includes("/empresa/dashboard")){
          window.location.reload();
        } else {
          urlRoute = "/empresa/dashboard";
        }
      } else {
        if(urlPath.includes("/admin/dashboard")){
          window.location.reload();
        } else {
          urlRoute = "/admin/dashboard";
        }
      }
    }
    if(urlPath.length>0){
      setUrlRedirect(urlRoute)
      userContext.setRoleSelected(value)
    }
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >

    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {roleListUser.length>0 &&
      roleListUser.map(role=>(
        <Grid key={role.id} container item className="menu-container">
          <MenuItem onClick={()=>handleChangeRole(role)} className="item-menu-user item-menu-color bg-role-selected">
            {role.name} <PersonIcon className="icon-menu-user"/>
          </MenuItem>
        </Grid>
      ))}
      <Grid container item className="menu-container">
        {showBtnLogin ?
          <MenuItem className={`item-menu-user ${roleListUser.length>0 ? "line-menu-user" : ""}`} onClick={handleMobileMenuClose} component ={ Link } to="/login">
            {isLogin() ? "Ir al dashboard" : "Iniciar sesión"} <PersonIcon className="icon-menu-user"/>
          </MenuItem>
        :
          <MenuItem className={`item-menu-user ${roleListUser.length>0 ? "line-menu-user" : ""}`} onClick={handleMobileMenuClose} component ={ Link } to="/">
            Ir al inicio <PersonIcon className="icon-menu-user"/>
          </MenuItem>
        }
      </Grid>
      {userContext.roleSelected === 5 &&
        <Grid container item className="menu-container">
          <MenuItem className="item-menu-user line-menu-user" component ={ Link } to="/admin/dashboard/configuracion-parametros">
            Configuraciones del sistema <SettingsIcon className="icon-menu-user"/>
          </MenuItem>
        </Grid>
      }
      {containsAny([4,5,6],[userContext.roleSelected]) && containsAny(permissionAdminSeeNewOffers, userContext.userPermissions) &&
        <Grid container item className="menu-container">
          <MenuItem className="item-menu-user line-menu-user" component ={ Link } to="/admin/ofertas">
            Ofertas nuevas <BusinessIcon className="icon-menu-user"/>
          </MenuItem>
        </Grid>
      }
      <Grid container item className="menu-container">
        <MenuItem className="item-menu-user line-menu-user" onClick={redirectToYoutube}>
          YouTube <YouTubeIcon className="icon-menu-user"/>
        </MenuItem>
      </Grid>
      <Grid container item className="menu-container">
        <MenuItem className="item-menu-user" onClick={redirectToTwitter}>
          Twitter <TwitterIcon className="icon-menu-user"/>
        </MenuItem>
      </Grid>
      <Grid container item className="menu-container">
        <MenuItem className="item-menu-user" onClick={redirectToFacebook}>
          Facebook <FacebookIcon className="icon-menu-user"/>
        </MenuItem>
      </Grid>
      {isLogin() &&
        <Grid container item className="menu-container">
          <MenuItem className="logout line-menu-user" onClick={handleLogout}>
            Cerrar sesión <ExitToAppIcon className="icon-menu-user"/>
          </MenuItem>
        </Grid>
      }
    </Menu>
  );

  return (
    <div>
      {urlRedirect.length>0 &&
        <Redirect to={urlRedirect} />
      }
      <Grid container>
        <ButtonColors/>
      </Grid>
      <AppBar position="static" className="header-container">
        <Toolbar style={{margin:"0", padding:"0"}}>
          <Grid container item xs={12} className="header-box-container">
            <Grid container item xs={12} sm={4} className="general-box">
              <Link className="more-info-link" to="/">
                <img src={Egresados}  className="image-logo" alt="Egresados"/>
              </Link>
            </Grid>
            <Grid container item xs={12} sm={8} className="general-box">
              <div className={classes.sectionDesktop}>
                <Grid container item justify="flex-end" className="btn-header-menu">
                  {isLogin() &&
                    <>
                      <Button variant="contained" aria-controls="simple-menu" className="button-login" size="small" onClick={handleClick} endIcon={<PersonIcon/>}>
                        <Typography variant="h5" className="light-serif">
                          Menu usuario
                        </Typography>
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {roleListUser.length>0 &&
                        roleListUser.map(role=>(
                          <Grid key={role.id} container item className="menu-container">
                            <MenuItem onClick={()=>handleChangeRole(role)} className={`item-menu-user item-menu-color ${userContext.roleSelected === role.id ? "bg-role-selected" : ""}`}>
                              {role.name} <PersonIcon className="icon-menu-user"/>
                            </MenuItem>
                          </Grid>
                        ))}
                        {userContext.roleSelected === 5 &&
                          <Grid container item className="menu-container">
                            <MenuItem className="item-menu-user line-menu-user" component ={ Link } to="/admin/dashboard/configuracion-parametros">Configuraciones del sistema  <SettingsIcon className="icon-menu-user"/></MenuItem>
                          </Grid>
                        }
                        <Grid container item className="menu-container">
                          <MenuItem className="logout line-menu-user" onClick={handleLogout}>Cerrar sesión <ExitToAppIcon className="icon-menu-user"/> </MenuItem>
                        </Grid>
                      </Menu>
                    </>
                  }
                  {showBtnLogin ?
                    <Button variant="contained" className="button-login classes.button" size="small"  component ={ Link } to="/login" endIcon={<PersonIcon/>}>{isLogin() ? "Página principal" : "Iniciar sesión"}</Button>
                  :
                    <Button variant="contained" className="button-login classes.button" size="small"  component ={ Link } to="/" endIcon={<PersonIcon/>}>Ir al inicio</Button>
                  }
                  {containsAny([4,5,6],[userContext.roleSelected]) && containsAny(permissionAdminSeeNewOffers, userContext.userPermissions) &&
                    <Button variant="contained" className="button-login classes.button" size="small"  component ={ Link } to="/admin/ofertas">
                      <Typography variant="h6" className="regular-serif">
                        Ofertas en definición
                      </Typography>
                      {offersNumber.length>0 &&
                        <Typography variant="h6" className="button-number-active" style={{marginLeft: "8px"}}>
                          {offersNumber}
                        </Typography>
                      }
                    </Button>
                  }

                </Grid>
                <IconButton className="button-icons" onClick={redirectToYoutube}>
                    <YouTubeIcon />
                </IconButton>
                <IconButton className="button-icons" onClick={redirectToTwitter}>
                    <TwitterIcon />
                </IconButton>
                <IconButton className="button-icons" onClick={redirectToFacebook}>
                    <FacebookIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>

          <div className={classes.sectionMobile} >
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              className="button-icons"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

export default HeaderBox;
