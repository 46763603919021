import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router-dom';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import FirstStep from "./FirstStepGraduate";
import SecondStep from "./SecondStepGraduate";
import ThirdStep from "./ThirdStepGraduate";
import Validations from "../../../helpers/Validations";
import RegisterContext from "../../../utils/RegisterContext";
import { IRegisterGraduate } from '../../../interfaces/IRegisterGraduate';
import { IFile } from "../../../interfaces/IFile";
import { IGeneralSIA } from "../../../interfaces/IGeneralSIA";
import { IGeneralParameter } from "../../../interfaces/IGeneralParameter";
import { IPreSignedUrlReq, IPresignedUrl } from '../../../interfaces/IPreSignedUrl';
import { getSignUrl } from "../../../services/s3Service";
import { sortByKey } from "../../../helpers/sharedFunctions";
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';
import { getACADEMIC_LEVEL, getCAMPUS_FACULTIES, getCOMPANY_TYPE, getEMPLOYMENT_SITUATION, getETHNIC_GROUP, getID_TYPE, getLABORAL_SECTOR, getPROGRAMS } from "../../../services/ParameterService";
import { GENDER } from "../../../services/SIAService";

import '../Stepper.scss';

// Step titles
const labels = ["Información personal", "Adjuntar documentos", "Confirmación del registro" ]

const useStyles = makeStyles({
  icon:{
    "&$activeIcon": {
      color: '#7DA52D',
      transform: 'scale(1.4)',
    },
    "&$completedIcon": {
      color: '#7DA52D',
      transform: 'scale(1.4)',
    }
  },
  activeIcon: {},
  completedIcon: {}
});

const fieldsValidationGraduate = {

  names: {
    error: "",
    validate: "specialText",
    minLength: 2,
    maxLength: 40,
    required: true
  },

  surname: {
    error: "",
    validate: "specialText",
    minLength: 3,
    maxLength: 30,
    required: true
  },

  secondsurname: {
    error: "",
    validate: "specialText",
    minLength: 2,
    maxLength: 30,
    required: false
  },

  gender: {
    validate: "select",
    required: true
  },

  ethnicgroup: {
    validate: "select",
    required: false
  },

  cellphone: {
    error: "",
    validate: "number",
    minLength: 10,
    maxLength: 12,
    required: true
  },

  phone: {
    error: "",
    validate: "textNumber",
    minLength: 7,
    maxLength: 30,
    required: false
  },

  address: {
    error: "",
    minLength: 2,
    maxLength: 40,
    required: true
  },

  documentType: {
    validate: "select",
    required: true
  },

  doc: {
    error: "",
    validate: "specialChar",
    minLength: 6,
    maxLength: 11,
    required: true
  },

  email: {
    error: "",
    validate: "email",
    required: true
  },

  campus: {
    validate: "select",
    required: true
  },

  school: {
    validate: "select",
    required: true
  },

  curriculum: {
    validate: "select",
    required: true
  },

  academicLevel: {
    error: "",
    validate: "select",
    required: true
  },

  date: {
    error: "",
    validate: "specialDate",
    required: true,
    minDate: new Date('01/01/1900'),
    maxDate: new Date('12/31/2003')
  },

  diplomaNumber: {
    error: "",
    validate: "textNumber",
    minLength: 4,
    maxLength: 15,
    required: false
  },

  currentJobSituation: {
    validate: "select",
    required: true
  },

  typeOfCompany: {
    validate: "select",
    required: false
  },

  laboralSector: {
    validate: "select",
    required: false
  },

  description_document: {
    error: "",
    validate: "textNumber",
    minLength: 10,
    maxLength: 100,
    required: true
  },

  files : {
    error: "",
    required: true
  }

}

const GraduateStepper = () => {
  const classes = useStyles();
  const preForm: IRegisterGraduate = new IRegisterGraduate();
  const initialErrors: IRegisterGraduate = {...preForm};
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState(preForm)
  const [formErrors, setFormErrors] = useState(initialErrors)
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const [firstAccorDone, setFisrtAccorDone] = useState(false);
  const [secondAccorDone, setSecondAccorDone] = useState(false);
  const [thirdAccorDone, setThirdAccorDone] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openJobModal, setOpenJobModal] = React.useState(false);
  //validation steps before send
  const [step1Completed, setStep1Completed] = React.useState(false);
  const [step2Completed, setStep2Completed] = React.useState(false);
  //  initial data
  const [gendersType, setGendersType] = useState<Array<IGeneralSIA>>([]);
  const [ethnicGroup, setEthnicGroup] = useState<Array<IGeneralParameter>>([]);
  const [documentsType,setDocumentsType] = useState<Array<IGeneralParameter>>([]);
  const [allCampus, setAllCampus] = useState<Array<IListParametersInfo>>([]);
  const [schools, setSchools] = useState<Array<IGeneralParameter>>([]);
  const [programsArray, setProgramsArray] = useState<Array<IGeneralParameter>>([]);
  const [programs, setPrograms] = useState<Array<IGeneralParameter>>([]);
  const [allAcademic, setAllAcademic] = useState<Array<IGeneralParameter>>([]);
  const [allEmploySituation, setAllEmploySituation] = useState<Array<IGeneralParameter>>([]);
  const [allCompanyType, setAllCompanyType] = useState<Array<IGeneralParameter>>([]);
  const [allLaboralSector, setAllLaboralSector] = useState<Array<IGeneralParameter>>([]);

  //  get initial data
  useEffect(()=>{
    const getInitialData = async()=>{
      //data for gender field
      const dataGender:Array<IGeneralSIA> = GENDER;
      setGendersType(dataGender ? dataGender : []);
      // data for ethnic field
      const dataEthnic:Array<IGeneralParameter> = await getETHNIC_GROUP();
      setEthnicGroup(dataEthnic ? dataEthnic : []);
      //  data for doc types
      const dataDocType:Array<IGeneralParameter> = await getID_TYPE();
      setDocumentsType(dataDocType ? dataDocType : []);
      // data for campus field
      let dataAllCampus: Array<IListParametersInfo> = (await getCAMPUS_FACULTIES()).list;
      dataAllCampus = sortByKey(dataAllCampus, 'parentName');
      setAllCampus(dataAllCampus ? dataAllCampus : []);
      let dataAllPrograms = await getPROGRAMS();
      setProgramsArray(dataAllPrograms);
      // data for all academic levels
      let responseAcademic: Array<IGeneralParameter> = await getACADEMIC_LEVEL();
      responseAcademic = sortByKey(responseAcademic, 'name');
      setAllAcademic(responseAcademic ? responseAcademic : []);
      //  laboral situation
      let responseEmploySitutaion: Array<IGeneralParameter> = await getEMPLOYMENT_SITUATION();
      setAllEmploySituation(responseEmploySitutaion ? responseEmploySitutaion : []);
      //  company type
      let responseCompanyType: Array<IGeneralParameter> = await getCOMPANY_TYPE();
      responseCompanyType = sortByKey(responseCompanyType, 'name');
      setAllCompanyType(responseCompanyType ? responseCompanyType : [])
      //  laboral sector
      let responseLaboralSector: Array<IGeneralParameter> = await getLABORAL_SECTOR();
      responseLaboralSector = sortByKey(responseLaboralSector, 'name');
      setAllLaboralSector(responseLaboralSector ? responseLaboralSector : [])
    }
    getInitialData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    setSchools([]);
    setPrograms([]);
    setFormValues(prev => ({
        ...prev,
        school: 0,
        curriculum: 0
    }));
    if(formValues.campus>0){
      const campusFound = allCampus.find(campus=> campus.id === formValues.campus);
      if(campusFound){
        const campus: IListParametersInfo = campusFound;
        let schools = campus.childs;
        schools = sortByKey(schools, 'name');
        setSchools(schools);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.campus])

  useEffect(()=>{
    const getPrograms = async () => {
      setPrograms([]);
      setFormValues(prev => ({
          ...prev,
          curriculum: 0
      }))

      if(formValues.school>0){
        let programsBySchool = programsArray.filter(item=>item.parentId === formValues.school);
        programsBySchool = sortByKey(programsBySchool, 'name');
        setPrograms(programsBySchool ? programsBySchool : [])
      }
    }
    getPrograms();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.school])

  useEffect(()=>{
    const getPrograms = async () => {
      if(formValues.currentJobSituation!==3 && formValues.currentJobSituation!==4 ){
        setFormValues(prev => ({
          ...prev,
          typeOfCompany: 0,
          laboralSector: 0
        }))
      }
    }
    getPrograms();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.currentJobSituation])

  // accordion controls
  const handleChangeAccordion = (panel: string, buttonExp?: boolean, infoValid?:string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    if(buttonExp){
      newExpanded = buttonExp;
      if(infoValid === 'panel1'){
        setFisrtAccorDone(true)
      } else if (infoValid === 'panel2'){
        setSecondAccorDone(true)
      } else if (infoValid === 'panel3'){
        setThirdAccorDone(true)
        newExpanded = false;
      }
    }
    setExpanded(newExpanded ? panel : false);
  };

  const handleValidForm = (panel: string, formValid: boolean)=>{
    if(panel === 'panel1'){
      setFisrtAccorDone(formValid)
    } else if (panel === 'panel2'){
      setSecondAccorDone(formValid)
    } else if (panel === 'panel3'){
      setThirdAccorDone(formValid)
    }
  }

  // Proceed to next step
  const handleNext = () => {
    if(activeStep === 0 ){
      setOpenModal(false)
      handleCloseJobModal()
    }
    setActiveStep(prev => prev + 1)
  }
  // Go back to prev step
  const handleBack = () => {
    setOpenModal(false)
    handleCloseJobModal();
    setActiveStep(prev => prev - 1)
  }

  const handleMOpenModal = ()=> setOpenModal(true);

  const handleCloseModal = () =>setOpenModal(false);

  const handleTerms = (event: React.ChangeEvent<HTMLInputElement>)=>{
    setFormValues(prev => ({
      ...prev,
      termsOfUse: event.target.checked
    }))
  }
  //JobModal
  const handleOpenJobModal = ()=> {
    setOpenModal(false);
    setOpenJobModal(true);
  }

  const handleCloseJobModal = () =>setOpenJobModal(false);

  const handleJobBank = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const actualDate = new Date()
    setFormValues(prev => ({
      ...prev,
      inJobBank: (event.target.checked ? actualDate : null)
    }))
  }

  // Handle form change
  const handleChange = async(e: any) => {
    const {name, value} = e.target;
    if (name === 'files'){
      if(formValues.files.length<3 && e.target.files.length>0){
        if (e.target.files[0].type === 'application/pdf') {
          if (e.target.files[0].size < (1000000)) {
            setFormValues(prev => ({
              ...prev,
              [name]: [...formValues.files, { file: e.target.files[0], description: formValues.description_document, previewUrl: URL.createObjectURL(e.target.files[0]) }],
              description_document: ''
            }));
            setFormErrors(prev => ({
              ...prev,
              fileError: ''
            }));
            if (e.target.files[0]) {
              const fileToUpload: IPreSignedUrlReq = {
                contentType: e.target.files[0].type,
                fileKey: e.target.files[0].name.replace(/[^a-zA-Z0-9_. ]/g, "").split(' ').join('_'),
                folderId: `Egresado-${formValues.doc}`,
                description: formValues.description_document
              }
              const result: IPresignedUrl = await getSignUrl(fileToUpload);
              result.name = e.target.files[0].name;
              if (result) {
                setFormValues(prev => ({
                  ...prev,
                  signedUrllArr: [...formValues.signedUrllArr, result]
                }))
              }
            }
          } else {
            setFormErrors(prev => ({
              ...prev,
              fileError: 'El archivo no debe superar 1MB'
            }));
          }
        } else {
          setFormErrors(prev => ({
            ...prev,
            fileError: 'Solo puede subir archivos en pdf'
          }));
        }
      } else {
        if(e.target.files.length>0 && e.target.files[0].type === 'application/pdf'){
          setFormErrors(prev=>({
            ...prev,
            fileError: 'No puede subir mas archivos, si desea cambiar o eliminar un archivo, utilice los botones en cada una de las tarjetas'
          }));
        }
      }
    } else {
      setFormValues(prev => ({
        ...prev,
        [name]: value
      }))
      const error: string = Validations(name, value, fieldsValidationGraduate) || "";
      setFormErrors(prev=>({
        ...prev,
        [name]: error
      }));
    }
  }

  // Handle form date change
  const handleChangeDate = (dateValue: Date | null) => {
    const value =dateValue ? dateValue?.toString() : '';
    const error: string = Validations('date', value, fieldsValidationGraduate) || "";
    setFormErrors(prev=>({
      ...prev,
      dateError: error
    }));
    setFormValues(prev => ({
      ...prev,
      date: dateValue
    }))
  }

  const handleDeleteFile = (fileToDelete:IFile)=>{
    const name = fileToDelete.file?.name;
    const newFiles = formValues.files.filter(obj=>obj.file?.name !== name);
    const newsignUrls = formValues.signedUrllArr.filter(obj=>obj.name !== name);
    setFormValues(prev => ({
      ...prev,
      files: newFiles,
      signedUrllArr:newsignUrls
    }));
  }

  const changeStep = (step:number, panel?: string)=>{
    setActiveStep(step);
    if(panel !== undefined){
      setExpanded(panel);
    }
    if(openModal){
      setOpenModal(false)
    }
  }

  const handleSteps = (step: number) => {
    switch (step) {
      case 0:
        return (
          <FirstStep/>
        )
      case 1:
        return (
            <SecondStep/>
        )
      case 2:
        return (
          <ThirdStep/>
        )
      case 3:
        sessionStorage.setItem('successRegister', 'graduate');
        return (
          <Redirect to="/exitoso"/>
        )
      default:
        break
    }
  }

  return (
    <>
      <RegisterContext.Provider value={{formValues, setFormValues,formErrors, setFormErrors,handleNext,
        handleBack,handleChange,handleChangeDate, expanded, handleChangeAccordion, firstAccorDone, secondAccorDone, 
        thirdAccorDone, handleValidForm, handleDeleteFile, changeStep, handleMOpenModal, openModal, handleCloseModal, 
        handleTerms, step1Completed, setStep1Completed, step2Completed, setStep2Completed, gendersType, ethnicGroup, 
        documentsType, allCampus, schools, programs, allAcademic, allEmploySituation, allCompanyType, allLaboralSector,
        handleJobBank,handleCloseJobModal,handleOpenJobModal,openJobModal}}>
        <Grid container item xs={12}>
          <Grid container  className="stepper-container">
            <Stepper activeStep={activeStep}  alternativeLabel className="steps-header">
              {labels.map(label => (
                <Step key={label} className="step-header-container-graduate">
                  <StepLabel StepIconProps={{ classes:{ root: classes.icon, active: classes.activeIcon, completed: classes.completedIcon } }}><span className="step-label-text">{label}</span></StepLabel>
                </Step>
              ))}
            </Stepper>
            <Grid container item xs={12} className="steps-container">
              {handleSteps(activeStep)}
            </Grid>
          </Grid>
        </Grid>
      </RegisterContext.Provider>
    </>
  )
}

export default GraduateStepper
