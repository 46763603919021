import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DescriptionIcon from '@material-ui/icons/Description';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { IFilterJobOffers } from '../../../interfaces/IFilterJobOffers';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import './MoreFiltersOffers.scss';



interface MoreFiltersOffersProps{
  formValues: IFilterJobOffers;
  publishDateArray?: Array<any>;
  salaryArray?: Array<any>;
  allTypeContract: Array<IGeneralParameter>;
  valuedate: number;
  valueSalary: number;
  radioChange: any;
  radioChangeSalary: any;
  onChangeForm: any;
  requestSearch: any;
  clearFilter: any;
}
const MoreFiltersOffers:React.FC<MoreFiltersOffersProps> = ({formValues, publishDateArray, salaryArray, allTypeContract, valuedate, valueSalary, radioChange, radioChangeSalary, onChangeForm, requestSearch, clearFilter})=>{
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const handleClickFilters = ()=>{
    setShowFilters(!showFilters)
  }

  return(
    <Grid container item xs={12} className="more-filters-box">
      <Grid container item xs={12} style={{width: '100%'}}>
        <Button variant="contained" className="more-filters-button" onClick={handleClickFilters}>
          <Typography variant="h5" className="regular-serif">
            Agregar otros filtros de búsqueda
          </Typography>
          <span className="more-button-icon">{!showFilters? <AddIcon/> : <RemoveIcon/>}</span>
        </Button>
      </Grid>
      {showFilters?
        <Grid container item xs={12} className="more-filters-container">
          <Grid container item xs={12} style={{height: '300px'}} className="more-filters-form">
            <Grid container item xs={12}>
              <CalendarTodayIcon className="form-icon"/>
              <Typography variant="h5" className="more-filters-form-title">
                Fecha de publicación
              </Typography>
            </Grid>
            <Grid item xs={12}  className="field-box-margin">
              <FormControl component="fieldset">
                <RadioGroup row className="radio-container" aria-label="publishDate" name="publishDate" value={valuedate} onChange={radioChange}>
                  {
                    publishDateArray && publishDateArray.map(item=>(
                      <FormControlLabel key={item.id} value={item.value} className="radio-btn" control={<Radio className="color-btn"/>} label={item.label} />
                    ))
                  }
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid container item xs={12}>
              <AttachMoneyIcon className="form-icon"/>
              <Typography variant="h5" className="regular-serif more-filters-form-title">
                Salario
              </Typography>
            </Grid>
            <Grid item xs={12}  className="field-box-margin">
              <FormControl component="fieldset">
                <RadioGroup row className="radio-container" aria-label="rangeSalary" name="rangeSalary" value={valueSalary} onChange={radioChangeSalary}>
                  {
                    salaryArray && salaryArray.map(item=>(
                      <FormControlLabel key={item.id} value={item.value} className="radio-btn" control={<Radio className="color-btn"/>} label={item.label} />
                    ))
                  }
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
              <Grid container item xs={12}>
                <DescriptionIcon className="form-icon" />
                <Typography variant="h5" className="regular-serif more-filters-form-title">Tipo de contrato</Typography>
              </Grid>
              <TextField
                fullWidth
                id="outlined-select-typeContract"
                select
                name="typeContract"
                label={formValues.typeContract === 0 ? "Seleccione": ""}
                value={formValues.typeContract === 0 ? "" : formValues.typeContract}
                onChange={onChangeForm}
                InputLabelProps={{shrink: false}}
                margin="none"
                variant="outlined"
              >
                {
                  allTypeContract && allTypeContract.length> 0 &&
                  <MenuItem value={0}><em>Ninguno</em></MenuItem>
                }
                {
                  allTypeContract && allTypeContract.length>0 ?
                  allTypeContract.map((option: IGeneralParameter) => (
                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                      {option.name}
                    </MenuItem>
                  ))
                  :
                    <MenuItem value={formValues.typeContract}></MenuItem>
                }
              </TextField>
            </Grid>
            <Grid container item xs={12} className="filter-buttons-offers">
              <Button className="filter-button" onClick={requestSearch}>
                <Typography variant="h5" className="regular-serif">
                  Filtrar
                </Typography>
              </Button>
              <Button className="clear-filter-button" onClick={clearFilter}>
                <Typography variant="h5" className="regular-serif">
                  Borrar filtros
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      :
        null
      }
    </Grid>
  )
}

export default MoreFiltersOffers;