import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import 'moment/locale/es'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { LocalizationProvider, DateRangePicker } from "@material-ui/pickers";
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { IGeneralSIA } from '../../../interfaces/IGeneralSIA';
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';
import { IGraduateFilterForm } from '../../../interfaces/IGraduateFilterForm';
import { StateByRole } from '../../../interfaces/StateByRole';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import './MoreFiltersGraduate.scss';
import UserContext from '../../../utils/UserContext';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 220,
      width: 250,
    },
  },
};

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      border: '1px solid #0000003b',
      padding: '18.5px 32px 18.5px 14px',
      '&:focus': {
        borderRadius: 4,
        borderWidth: 2,
        borderColor: '#3f51b5',
      },
    },
  }),
)(InputBase);

interface MoreFiltersGraduateProps {
    titleSection: string;
    formValues: IGraduateFilterForm;
    formErrors: any;
    gendersType: Array<IGeneralSIA>;
    allCampus: Array<IListParametersInfo>;
    schools: Array<IGeneralParameter>;
    programs: Array<IGeneralParameter>;
    allAcademic: Array<IGeneralParameter>;
    associationTypeArray: Array<IGeneralParameter>;
    associationArray: Array<IGeneralParameter>;
    onChangeForm: any;
    changeFilterDates: any;
    reportForm: boolean;
    stateOptions?: Array<StateByRole>;
    campusSelected?: any;
    schoolSelected?: any;
    aux?: boolean;
    aux2?: boolean;
}

const MoreFiltersGraduate: React.FC<MoreFiltersGraduateProps> = ({titleSection, formValues, formErrors, gendersType, allCampus, schools, programs, allAcademic, associationTypeArray, associationArray, onChangeForm,changeFilterDates, reportForm, stateOptions, campusSelected, schoolSelected, aux, aux2 })=>{
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const userContext = React.useContext(UserContext);
    const handleClickFilters = ()=>{
        setShowFilters(!showFilters)
    }

    return(
        <Grid container item xs={12} className="more-filters-box">
            <Grid container item xs={12} style={{width: '100%'}}>
                <Button variant="contained" className="more-filters-button" onClick={handleClickFilters}>
                    <Typography variant="h4" className="regular-serif">
                        {titleSection}
                    </Typography>
                    <span className="more-button-icon" >{!showFilters? <AddIcon/> : <RemoveIcon/>}</span>
                </Button>
            </Grid>
            {showFilters?
                <Grid container item xs={12} className="more-filters-container">
                    <Grid container item xs={12} style={{height: '300px'}} className="more-filters-form">
                        <Grid container item xs={12}>
                            <Typography variant="h4" className="more-filters-form-title">
                                Información personal
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Nombres</Typography>
                            <TextField
                                fullWidth
                                name="names"
                                margin="none"
                                label={formValues.names === "" ? "Ingrese los nombres": ""}
                                value={formValues.names || ""}
                                onChange={onChangeForm}
                                error={!!formErrors.names}
                                helperText={formErrors.names}
                                InputLabelProps={{shrink: false}}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Apellidos</Typography>
                            <TextField
                                fullWidth
                                name="surname"
                                margin="none"
                                label={formValues.surname === "" ? "Ingrese los apellidos": ""}
                                value={formValues.surname || ""}
                                onChange={onChangeForm}
                                error={!!formErrors.surname}
                                helperText={formErrors.surname}
                                InputLabelProps={{shrink: false}}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Género</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-gender"
                                select
                                name="gender"
                                label={formValues.gender === 0 ? "Seleccione": ""}
                                value={formValues.gender === 0 ? "" : formValues.gender}
                                onChange={onChangeForm}
                                InputLabelProps={{shrink: false}}
                                margin="none"
                                variant="outlined"
                            >
                                {
                                    gendersType && gendersType.length> 0 &&
                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                }
                                {
                                    gendersType.length> 0 ?
                                    gendersType.map((option: IGeneralSIA) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.gender}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Documento de identidad</Typography>
                            <TextField
                                fullWidth
                                name="doc"
                                margin="none"
                                label={formValues.doc=== "" ? "Ingrese su número de documento": ""}
                                value={formValues.doc || ""}
                                onChange={onChangeForm}
                                error={!!formErrors.doc}
                                helperText={formErrors.doc}
                                InputLabelProps={{shrink: false}}
                                variant="outlined"
                            />
                        </Grid>
                        {reportForm &&
                        <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
                            <FormControl fullWidth>
                                <Typography variant="h5" className="field-box-title">Estado en el sistema</Typography>
                                <Select
                                    id="demo-mutiple-checkbox-state"
                                    multiple
                                    name="state"
                                    value={formValues.state}
                                    onChange={onChangeForm}
                                    disabled={stateOptions && stateOptions.length>0 ? false : true}
                                    input={<BootstrapInput />}
                                    renderValue={(selected) => {
                                        return ((selected as StateByRole[]).map(elm => elm.value)).join(', ')
                                    }}
                                    MenuProps={MenuProps}
                                    variant="outlined"
                                >
                                {stateOptions && stateOptions.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        // @ts-ignore [1]
                                        value={option}
                                    >
                                        <Checkbox checked={(formValues.state.findIndex(item=>item.value === option.value)) > -1} />
                                        <ListItemText primary={option.value} />
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        }
                        <Grid item xs={12} md={6} xl={4} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Fecha de actualización</Typography>
                            <Grid container item xs={12}>
                                <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale} >
                                    <DateRangePicker
                                        disableFuture
                                        calendars={1}
                                        PopperProps={{
                                            placement:"bottom"
                                        }}
                                        value={formValues.updateDate || null}
                                        onChange={(value)=>changeFilterDates('updateDate',value)}
                                        renderInput={(startProps, endProps) => (
                                            <Grid container item xs={12} alignItems="center">
                                                <Grid item xs={12} sm={5}>
                                                    <TextField
                                                        {...startProps}
                                                        autoComplete ="off"
                                                        fullWidth
                                                        name="updatedAtStart"
                                                        margin="none"
                                                        label={formValues.updateDate[0] === null ? "DD/MM/AAAA" : ""}
                                                        error={!!formErrors.updatedAtStart}
                                                        helperText={formErrors.updatedAtStart}
                                                        InputLabelProps={{shrink: false}}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Typography variant="h5" className="field-box-title" style={{textAlign: 'center'}}>
                                                        Hasta
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={5}>
                                                    <TextField
                                                        {...endProps}
                                                        fullWidth
                                                        autoComplete ="off"
                                                        name="updatedAtEnd"
                                                        margin="none"
                                                        label={formValues.updateDate[1] === null ? "DD/MM/AAAA" : ""}
                                                        error={!!formErrors.updatedAtEnd}
                                                        helperText={formErrors.updatedAtEnd}
                                                        InputLabelProps={{shrink: false}}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} style={{paddingTop: '8px'}}>
                            <Typography variant="h4" className="more-filters-form-title">
                                Información académica
                            </Typography>
                        </Grid>
                        {
                            aux ?
                            <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
                                <Typography variant="h5" className="field-box-title">Sede</Typography>
                                {
                                    allCampus.length>0 &&
                                    <TextField
                                        fullWidth
                                        id="outlined-select-campus"
                                        select
                                        name="campus"
                                        label={formValues.campus === 0 ? "Seleccione": ""}
                                        value={formValues.campus === 0 ? "" : formValues.campus}
                                        onChange={onChangeForm}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                        {
                                            allCampus && allCampus.length> 0 &&
                                            <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                        }
                                        {
                                            allCampus.length> 0 ?
                                            allCampus.map((option: IListParametersInfo) => (
                                                <MenuItem disabled={option.id === campusSelected ? false : true} key={option.id} value={option.id}>
                                                    {option.parentName}
                                                </MenuItem>
                                            ))
                                            
                                        :
                                            <MenuItem value={formValues.campus}></MenuItem>
                                        }
                                    </TextField>
                                }
                            </Grid>
                            :
                            <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
                                <Typography variant="h5" className="field-box-title">Sede</Typography>
                                {
                                    allCampus.length>0 &&
                                    <TextField
                                        fullWidth
                                        id="outlined-select-campus"
                                        select
                                        name="campus"
                                        label={formValues.campus === 0 ? "Seleccione": ""}
                                        value={formValues.campus === 0 ? "" : formValues.campus}
                                        onChange={onChangeForm}
                                        InputLabelProps={{shrink: false}}
                                        margin="none"
                                        variant="outlined"
                                    >
                                        {
                                            allCampus && allCampus.length> 0 &&
                                            <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                        }
                                        {
                                            allCampus.length> 0 ?
                                            allCampus.map((option: IListParametersInfo) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.parentName}
                                                </MenuItem>
                                            ))
                                        :
                                            <MenuItem value={formValues.campus}></MenuItem>
                                        }
                                    </TextField>
                                }
                            </Grid>
                        }
                        {
                            aux2 ?
                            <Grid item xs={12} sm={6} lg={6} className="field-box-margin">
                                <FormControl fullWidth>
                                    <Typography variant="h5" className="field-box-title">Facultad</Typography>
                                    <Select
                                        id="demo-mutiple-checkbox-school"
                                        multiple
                                        name="school"
                                        value={formValues.school}
                                        onChange={onChangeForm}
                                        disabled={schools.length>0 ? false : true}
                                        input={<BootstrapInput />}
                                        renderValue={(selected) => {
                                            return ((selected as IGeneralSIA[]).map(elm => elm.name)).join(', ')
                                        }}
                                        MenuProps={MenuProps}
                                        variant="outlined"
                                    >
                                        {schools.map((option) => (
                                            <MenuItem
                                                disabled={option.parameter_code === schoolSelected ? false : true} 
                                                key={option.parameter_code} 
                                                // @ts-ignore [1]
                                                value={option}
                                            >
                                                <Checkbox checked={(formValues.school.findIndex(item=>item.name === option.name)) > -1} />
                                                <ListItemText primary={option.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            :
                            <Grid item xs={12} sm={6} lg={6} className="field-box-margin">
                                <FormControl fullWidth>
                                    <Typography variant="h5" className="field-box-title">Facultad</Typography>
                                    <Select
                                        id="demo-mutiple-checkbox-school"
                                        multiple
                                        name="school"
                                        value={formValues.school}
                                        onChange={onChangeForm}
                                        disabled={schools.length>0 ? false : true}
                                        input={<BootstrapInput />}
                                        renderValue={(selected) => {
                                            return ((selected as IGeneralSIA[]).map(elm => elm.name)).join(', ')
                                        }}
                                        MenuProps={MenuProps}
                                        variant="outlined"
                                    >
                                        {schools.map((option) => (
                                            <MenuItem
                                                key={option.parameter_code}
                                                // @ts-ignore [1]
                                                value={option}
                                            >
                                                <Checkbox checked={(formValues.school.findIndex(item=>item.name === option.name)) > -1} />
                                                <ListItemText primary={option.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12} sm={10} lg={10} className="field-box-margin">
                            <FormControl fullWidth>
                                <Typography variant="h5" className="field-box-title">Programa</Typography>
                                <Select
                                    id="demo-mutiple-checkbox-curriculum"
                                    multiple
                                    name="curriculum"
                                    value={formValues.curriculum}
                                    onChange={onChangeForm}
                                    disabled={programs.length>0 ? false : true}
                                    input={<BootstrapInput />}
                                    renderValue={(selected) => {
                                        return ((selected as IGeneralSIA[]).map(elm => elm.name)).join(', ')
                                    }}
                                    MenuProps={MenuProps}
                                    variant="outlined"
                                >
                                    {programs.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            // @ts-ignore [1]
                                            value={option}
                                        >
                                            <Checkbox checked={(formValues.curriculum.findIndex(program=>program.name === option.name)) > -1} />
                                            <ListItemText primary={option.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} xl={4} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Nível académico del programa</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-academicLevel"
                                select
                                name="academicLevel"
                                label={formValues.academicLevel === 0 ? "Seleccione": ""}
                                value={formValues.academicLevel === 0 ? "" : formValues.academicLevel}
                                onChange={onChangeForm}
                                InputLabelProps={{shrink: false}}
                                margin="none"
                                variant="outlined"
                            >
                                {
                                    allAcademic && allAcademic.length> 0 &&
                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                }
                                {
                                    allAcademic.length>0 ?
                                    allAcademic.map((option: IGeneralParameter) => (
                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.academicLevel}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6} xl={4} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Fecha de grado</Typography>
                            <Grid container item xs={12}>
                                <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale} >
                                    <DateRangePicker
                                        disableFuture
                                        calendars={1}
                                        PopperProps={{
                                            placement:"bottom"
                                        }}
                                        value={formValues.graduationDate || null}
                                        onChange={(value)=>changeFilterDates('graduationDate',value)}
                                        renderInput={(startProps, endProps) => (
                                            <Grid container item xs={12} alignItems="center">
                                                <Grid item xs={12} sm={5}>
                                                    <TextField
                                                        {...startProps}
                                                        autoComplete ="off"
                                                        fullWidth
                                                        name="graduationStart"
                                                        margin="none"
                                                        label={formValues.graduationDate[0] ===null ? "DD/MM/AAAA" : ""}
                                                        error={!!formErrors.graduationStart}
                                                        helperText={formErrors.graduationStart}
                                                        InputLabelProps={{shrink: false}}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Typography variant="h5" className="field-box-title" style={{textAlign: 'center'}}>
                                                        Hasta
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={5}>
                                                    <TextField
                                                        {...endProps}
                                                        fullWidth
                                                        autoComplete ="off"
                                                        name="graduationEnd"
                                                        margin="none"
                                                        label={formValues.graduationDate[1] ===null ? "DD/MM/AAAA" : ""}
                                                        error={!!formErrors.graduationEnd}
                                                        helperText={formErrors.graduationEnd}
                                                        InputLabelProps={{shrink: false}}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} style={{paddingTop: '8px'}}>
                            <Typography variant="h3" className="more-filters-form-title">
                                Información adicional
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Tipo de asociación</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-typeOfAssociation"
                                select
                                name="typeOfAssociation"
                                label={formValues.typeOfAssociation === 0 ? "Seleccione": ""}
                                value={formValues.typeOfAssociation === 0 ? "" : formValues.typeOfAssociation}
                                onChange={onChangeForm}
                                InputLabelProps={{shrink: false}}
                                margin="none"
                                variant="outlined"
                            >
                                {
                                    associationTypeArray && associationTypeArray.length> 0 &&
                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                }
                                {associationTypeArray && associationTypeArray.length>0 ?
                                    associationTypeArray.map((option: IGeneralParameter) => (
                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.typeOfAssociation}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Asociación</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-association"
                                select
                                name="association"
                                label={formValues.association === 0 ? "Seleccione": ""}
                                value={formValues.association === 0 ? "" : formValues.association}
                                onChange={onChangeForm}
                                InputLabelProps={{shrink: false}}
                                margin="none"
                                variant="outlined"
                            >
                                {
                                    associationArray && associationArray.length> 0 &&
                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                }
                                {associationArray && associationArray.length>0 ?
                                    associationArray.map((option: IGeneralParameter) => (
                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={formValues.association}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                        { userContext.stateGraduateSection === "Mensajeria" ?
                            <Grid item xs={12} sm={6} lg={4} className="field-box-margin">
                                <FormControl required className="checkbox"
                                    error = {!!formErrors.inJobBank}
                                    hiddenLabel ={false}
                                >
                                    <FormLabel>¿Enviar correos únicamente a los candidatos de la bolsa de empleo?</FormLabel>
                                    <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={formValues.inJobBank === undefined ? false : formValues.inJobBank }
                                            onChange={onChangeForm}
                                            name="inJobBank1"
                                        />}
                                        label="Si"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={formValues.inJobBank === undefined ? false : !formValues.inJobBank }
                                            onChange={onChangeForm}
                                            name="inJobBank2"
                                        />}
                                        label="No"
                                    />
                                    </FormGroup>
                                    <FormHelperText 
                                        
                                    > {formErrors.inJobBank}</FormHelperText>
                                </FormControl>
                            </Grid>
                            :
                            <Grid item xs={12} md={6} xl={4} className="field-box-margin">
                            <Typography variant="h5" className="field-box-title">Fecha de Activacion en la Bolsa de empleo</Typography>
                                <Grid container item xs={12}>
                                    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale} >
                                        <DateRangePicker
                                            disableFuture
                                            calendars={1}
                                            PopperProps={{
                                                placement:"bottom"
                                            }}
                                            value={formValues.inJobBankDate || null}
                                            onChange={(value)=>changeFilterDates('inJobBankDate',value)}
                                            renderInput={(startProps, endProps) => (
                                                <Grid container item xs={12} alignItems="center">
                                                    <Grid item xs={12} sm={5}>
                                                        <TextField
                                                            {...startProps}
                                                            autoComplete ="off"
                                                            fullWidth
                                                            name="inJobBankStart"
                                                            margin="none"
                                                            label={formValues.inJobBankDate[0] ===null ? "DD/MM/AAAA" : ""}
                                                            error={!!formErrors.inJobBankStart}
                                                            helperText={formErrors.inJobBankStart}
                                                            InputLabelProps={{shrink: false}}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <Typography variant="h5" className="field-box-title" style={{textAlign: 'center'}}>
                                                            Hasta
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={5}>
                                                        <TextField
                                                            {...endProps}
                                                            fullWidth
                                                            autoComplete ="off"
                                                            name="inJobBankEnd"
                                                            margin="none"
                                                            label={formValues.inJobBankDate[1] ===null ? "DD/MM/AAAA" : ""}
                                                            error={!!formErrors.inJobBankEnd}
                                                            helperText={formErrors.inJobBankEnd}
                                                            InputLabelProps={{shrink: false}}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            :
                null
            }
        </Grid>
    )
}

export default MoreFiltersGraduate

/*
[1]:
Type 'ITicketTopicOption' is not assignable to type 'string | number | string[] | undefined'.
  Type 'ITicketTopicOption' is missing the following properties from type 'string[]': length, pop, push, concat, and 28 more.
*/