import React, { useState } from 'react';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import SearchBox from '../../../../components/RolesAdmin/SearchBox';
import UsersLDAPTable from '../../../../components/RolesAdmin/UsersLDAPTable';
import ModalOffersAction from '../../../../components/LaboralOffers/ModalOffersAction';
import { getUserlDAP, registerUserSIE } from '../../../../services/AdminServices';
import { IGetUserLdap } from '../../../../interfaces/IGetUserLdap';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import SuccessModal from '../../../../components/ShareComponents/SuccessModal';
import ErrorModal from '../../../../components/ShareComponents/ErrorModal';

import './SearchLDAPView.scss';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import UserContext from '../../../../utils/UserContext';


const SearchLDAPView:React.FC = () =>{
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [username, setUsername] = useState<string>("");
    const [userLdap, setUsersLdap] = useState<Array<IGetUserLdap>>([]);
    const [opeModalRegister, setOpenModalRegister] = useState<boolean>(false);
    const [userSelected, setUserSelected] = useState<IGetUserLdap>(new IGetUserLdap());
    const [resultSearch, setResultSearch] = useState<string>("");
    const [errorMessage, setErrorMessage]= useState('');
    const [openModalError, setOpenModalError] = useState(false);
    const [successMessage, setSuccessMessage]= useState('');
    const [openModalSuccess, setOpenModalSuccess] = useState(false);

    const changeUsername = (e: any) => {
        const {value} = e.target;
        setUsername(value);
    }

    const searchUserLdap = async() =>{
        const respUser = await trackPromise(getUserlDAP(username));
        if(respUser.status === 200){
            const userLdapGet = await respUser.json();
            if(userLdapGet.cn === null){
                userLdapGet.cn = "";
            }
            if(userLdapGet.sn === null){
                userLdapGet.sn = "";
            }
            if(userLdapGet.username.length>0){
                userLdapGet.fullname = userLdapGet.cn + ' ' + userLdapGet.sn;
            }
            if(userLdapGet.documentId){
                userLdapGet.id = userLdapGet.documentId;
                setResultSearch("")
                setUsersLdap([userLdapGet])
            }
        } else if(respUser.status === 404){
            const result = await respUser.json();         
            setResultSearch(decodeURIComponent(result.message))
            setUsersLdap([])
        } else {
            setUsersLdap([])
        }
    }

    const handleRowSelected = (userLdap: IGetUserLdap)=>{
        setUserSelected(userLdap)
        setOpenModalRegister(true);
    }

    const closeModalRegister = () =>{
        setOpenModalRegister(false);
    }

    const registerUser = async()=>{
        const userToRegister = userSelected;
        delete userToRegister.id;
        delete userToRegister.fullname;
        setOpenModalRegister(false);
        const responseRegister = await trackPromise(registerUserSIE({...userToRegister, modifyUser: userContext.userId}));
        validateModals(responseRegister);
    }

    const validateModals = async(responseService: Response)=>{
        if(responseService.status === 200){
          const result = await responseService.json();
          setSuccessMessage(result.message);
          setOpenModalSuccess(true);
          setOpenModalError(false);
        } else if (responseService.status === 404){
          const result = await responseService.json();
          setErrorMessage(result.message);
          setOpenModalError(true);
          setOpenModalSuccess(false);
        } else {
          setErrorMessage("Hubo un error, intente de nuevo mas tarde");
          setOpenModalError(true);
          setOpenModalSuccess(false);
        }
    }

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
        window.location.reload();
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
    };


    return(
        <>
            <Grid container item xs={12} className="back-offers-list-section">
                <ButtonBackListOffers url="/admin/dashboard/usuarios" text="Regresar al listado de usuarios"/>
            </Grid>
            <Grid container className="search-ldap-view-container">
                {userContext.roleSelected === 5 ?
                    <>
                        <Grid container className="main-container">
                            <Grid container item xs={12} className="title-container">
                                <Typography variant="h2" className="bold-serif-primaryColor" >
                                    Buscar usuarios LDAP
                                </Typography>
                            </Grid>
                            <SearchBox username={username} changeUsername={changeUsername} searchUserLdap={searchUserLdap}/>
                        </Grid>
                        <Grid container item xs={12} className="roles-container-list">
                            <Grid container className="main-container">
                                {
                                    username.length>0 && resultSearch.length>0 &&
                                    <Grid container item xs={12} className="title-container">
                                        <Typography variant="h3" className="bold-serif-primaryColor" >
                                            {resultSearch}
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    userLdap.length>0 &&
                                    <>
                                        <Grid container item xs={12} className="title-container">
                                            <Typography variant="h3" className="bold-serif-primaryColor" >
                                                Usuarios Encontrados
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={12} className="roles-table-container">
                                            <UsersLDAPTable rows={userLdap} handleRowSelected={handleRowSelected}/>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Grid>
                        <ModalOffersAction
                            open={opeModalRegister}
                            handleClose={closeModalRegister}
                            handleContinue={registerUser}
                            iconModal={<WarningIcon className="big-info-icon" />}
                            title="¿Está seguro de registar este usuario?"
                            headerdescription=""
                            description="Esta intentando registrar un usuario del LDAP a la base de datos del SIE."
                            backBtnText="Regresar"
                            colorBtnBack= ""
                            continueBtnText="Si, Continuar"
                            colorBtnContinue="#14A5DC"
                            valid={true}
                        />
                        <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess}/>
                        <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError}/>
                        <LoadingLockPage load={promiseInProgress}/>
                    </>
                :
                    <Grid container className="main-container">
                        <NotAuthorized/>
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default SearchLDAPView