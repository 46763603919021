import React, { useEffect, useState} from "react";
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Grid from "@material-ui/core/Grid";
import { IGeneralParameter } from "../../../interfaces/IGeneralParameter";
import ShowOfferDetail from "../ShowOfferDetail";
import DescriptionOfferBox from "../DescriptionOfferBox";
import SummaryDescriptionOffer from "../SummaryDescriptionOffer";
import LoadingLockPage from "../../ShareComponents/LoadingLockPage";
import { IGetOffer } from "../../../interfaces/IGetOffer";
import { getCompanyInfo } from "../../../services/CompanyService";
import { listPostulations } from "../../../services/PostulationService";
import { IGetPostulation } from "../../../interfaces/IGetPostulation";
import { IApplicantInfo } from "../../../interfaces/IApplicantInfo";
import { IApplicant } from "../../../interfaces/IApplicant";
import { Typography } from "@material-ui/core";
import PeopleIcon from '@material-ui/icons/People';
import AlertBox from "../../ShareComponents/AlertBox";
import SettingsIcon from '@material-ui/icons/Settings';
import ApplicantList from "../ApplicantList";
import ApplicantCurriculumVitae from "../ApplicantCurriculumVitae";
import {  getLABORAL_SECTOR, getOccupationType, getTypeOfContract } from "../../../services/ParameterService";

interface OfferDetailAdminProps{
    offerSelected: IGetOffer;
    department: string;
    city: string;
    graduateId?: any;

}

const OfferDetailAdmin: React.FC<OfferDetailAdminProps> = ({offerSelected, department, city}) => {
  const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
  const { promiseInProgress } = usePromiseTracker();
  const [urlCompany, setUrlCompany]  = useState<string>('');
  const [typePosition, setTypePosition] = useState<string>('');
  const [laboralSector, setLaboralSector]= useState<string>('');
  const [contract, setContract]= useState<string>('');
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [openCV, setOpenCV] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage]= useState(5);
  const [initPage, setInitPage]= useState<number>(0);
  const [applicantsData, setApplicantsData] = useState<IApplicantInfo[]>([]);
  const [graduateId, setGraduateId] = useState<number | null>(null);
  const [stateOffer, setStateOffer]= useState<string>('');

  useEffect(() => {
    if(offerSelected.state === "Convocatoria abierta"){
      setStateOffer(offerSelected.state)
    }
  }, [offerSelected])

  useEffect(()=>{
    window.scrollTo(0, 0);
    const getDataOffer = async()=>{
        if(offerSelected.id){
            const applicantRes: Response = await listPostulations({offerId:offerSelected.id});
            if(applicantRes.status === 200){
                const result: Array<IGetPostulation> = (await applicantRes.json()).postulates;
                let target: Array<IApplicantInfo> = [];
                result.forEach(item=>{
                    target.push(new IApplicantInfo(item))
                })
                setApplicantsData(target)
            }
        }
        setInitLoad(false);
    }
    getDataOffer();
  },[offerSelected.id])

  const onChanhePageSize = (e: any)=>{
      setRowsPerPage(e.target.value)
  }
  const handleView = (rowSelected: IApplicant) => {
      setGraduateId(rowSelected.graduateId ? rowSelected.graduateId : null)
      setOpenCV(true);
  }

  const goBack = (value: string) => {
      if (value === 'Back') {
          setOpenCV(false);
      }
  }

  useEffect(()=>{
    const getDataOffer = async()=>{
      if(offerSelected.id){
        const responseCompany = await trackPromise(getCompanyInfo(userToken, offerSelected.company));
        if(responseCompany.status === 200){
          const companyInfo = await responseCompany.json();
          if(companyInfo && companyInfo.company){
            if(companyInfo.company.website !== null ){
              setUrlCompany(companyInfo.company.website);
            }else{
              setUrlCompany('');
            }
          }
        }
        let responsePostion: Array<IGeneralParameter> = await trackPromise(getOccupationType());
        const positionSelected = responsePostion.find(item=>item.parameter_code === offerSelected.typePosition);
        setTypePosition(positionSelected?.name ? positionSelected.name : '');
        let respLaboralSector: Array<IGeneralParameter> = await trackPromise(getLABORAL_SECTOR());
        const laboralSelected = respLaboralSector.find(item=>item.parameter_code === offerSelected.laboralSector);
        setLaboralSector(laboralSelected?.name ? laboralSelected?.name : '');
        let respTypeContrat: Array<IGeneralParameter> = await trackPromise(getTypeOfContract());
        const contractSelected = respTypeContrat.find(item=>item.parameter_code === offerSelected.typeContract);
        setContract(contractSelected?.name ? contractSelected?.name : '');
      }
    }
    getDataOffer();
  },[offerSelected]);
  
  return (
    <>
    {
      openCV && graduateId && stateOffer && graduateId > 0 ?
        <ApplicantCurriculumVitae graduateId={graduateId } goBack={goBack}/>
      :
    <>
      <Grid container item xs={12} className="job-offers-detail-section">
        <Grid container className="main-container">
          <Grid container item xs={12}>
            <ShowOfferDetail dataOffer={offerSelected} department={department} city={city} companyName={offerSelected.nameCompany} urlCompany={urlCompany}/>
          </Grid>
          <Grid container item xs={12}>
            <Grid container item xs={12} md={8}>
              <Grid container className="description-container">
                <DescriptionOfferBox dataOffer={offerSelected} showButtons={false} typePosition={typePosition} laboralSector={laboralSector} contract={contract}/>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={4}>
              <SummaryDescriptionOffer dataOffer={offerSelected} showButtons={false} department={department} city={city} companyName={offerSelected.nameCompany} typePosition={typePosition} laboralSector={laboralSector} contract={contract}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {
      stateOffer !== "Convocatoria abierta" ?
      <>
      </>
      :
      <Grid container item xs={12} className="main-container">
        <Grid container item xs={12}>
            <Grid container item xs={12} md={3} lg={2}>
                <div className="title-section-line">
                <PeopleIcon className="group-icon"/>
                <Typography variant="h2" className="bold-serif-primaryColor" >
                    Aspirantes
                </Typography>
                </div>
            </Grid>
            <Grid container item xs={12} md={9} lg={10} className="line" />
        </Grid>
        <Grid container item xs={12}>
            <AlertBox
                title="Ver información del aspirante"
                alert="info"
                variantType="standard"
            >
                <span className="alert-simple-text">Para ver la información del aspirante,</span><SettingsIcon/><span className="alert-simple-text">desde aca podrá descargar la hoja de vida del aspirante o ver en línea la información</span>
            </AlertBox>
        </Grid>
        <Grid container item xs={12}>
            <ApplicantList action="Select" onChanhePageSize={onChanhePageSize} rows={applicantsData} pageSize={rowsPerPage} initPage={initPage} setInitPage={setInitPage} handleView={handleView} status="OfferDetailAdmin"/>
        </Grid>
      </Grid>
      }
    </>
    }
      <LoadingLockPage load={initLoad}/>
      <LoadingLockPage load={promiseInProgress}/>
    </>
  );
};

export default OfferDetailAdmin;
