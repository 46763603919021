import React, { useEffect, useState } from 'react';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import PermissionsTable from '../../../../components/RolesAdmin/PermissionsTable';
import { IPermission } from '../../../../interfaces/IPermission';
import { IPermissionRole } from '../../../../interfaces/IPermissionRole';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import SuccessModal from '../../../../components/ShareComponents/SuccessModal';
import ErrorModal from '../../../../components/ShareComponents/ErrorModal';
import { sortByKey } from '../../../../helpers/sharedFunctions';
import { associatePermissionRole, getListPermissions, getListPermissionsByRole } from '../../../../services/AdminServices';

import './PermissionsView.scss';
import UserContext from '../../../../utils/UserContext';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';


const PermissionsView: React.FC = ()=>{
    const { promiseInProgress } = usePromiseTracker();
    let params: any = useParams();
    const userContext = React.useContext(UserContext);
    const [roleSelected, setRoleSelected] = useState<IPermissionRole>(new IPermissionRole());
    const [permissionsArray, setPermissionsArray] = useState<Array<IPermission>>([]);
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [errorMessage, setErrorMessage]= useState('');
    const [openModalError, setOpenModalError] = useState(false);
    const [successMessage, setSuccessMessage]= useState('');
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [updatedPermissions, setUpdatedPermissions] = useState(false);

    useEffect(()=>{
        window.scrollTo(0, 0);
        const getData = async() => {
            if(params.idRole > 0){
                const repsonsePermissions = await getListPermissions();
                if(repsonsePermissions.status === 200){
                    const resultPermissions = (await repsonsePermissions.json()).permissionsInfo;
                    let target: Array<IPermission> = [];
                    let permissionsUpdated: Array<IPermission> = [];
                    resultPermissions.forEach((obj:any)=>{
                        target.push(new IPermission(obj))
                    });
                    const responseByRoles = await getListPermissionsByRole();
                    if(responseByRoles.status === 200){
                        const resultbyRoles = (await responseByRoles.json()).permissionsByRole;
                        const byRoleFound = resultbyRoles.find((elm: any)=>elm.id === parseInt(params.idRole));
                        if(byRoleFound){
                            setRoleSelected(byRoleFound)
                            if(byRoleFound.permissions.length>0){
                                const newArray =target.map(permission=>{
                                    const permFound = byRoleFound.permissions.find((item: any) => item.id === permission.id);
                                    if(permFound){
                                        permission.checked = true;
                                    }
                                    return permission
                                })
                                permissionsUpdated = newArray;
                            } else {
                                permissionsUpdated=target;
                            }
                        } else {
                            permissionsUpdated=target;
                        }
                        if(permissionsUpdated.length>0){
                            permissionsUpdated = sortByKey(permissionsUpdated, 'name');
                        }
                        setPermissionsArray(permissionsUpdated)
                    }
                }
                setInitLoad(false);
                
            }
        }
        if(userContext.roleSelected === 5){
            getData();
        }
        setUpdatedPermissions(false)
    },[params.idRole, userContext.roleSelected, updatedPermissions])

    const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        const {name, checked} = event.target;
        const newArray = permissionsArray.map(permission=>{
            if(permission.id === parseInt(name)){
                permission.checked = checked
            }
            return permission
        })
        setPermissionsArray(newArray)
    };

    const savePermissions = async() => {
        const newPermissions = permissionsArray.filter(permission=>permission.checked === true);
        const permissionsIds: Array<number> = newPermissions.map(permission=>permission.id);
        const permissionsRoleToChange:{roleId:number;permissions:Array<number>} = {
            roleId: roleSelected.id,
            permissions: permissionsIds
        }
        const responseAssignPermissions = await trackPromise(associatePermissionRole({...permissionsRoleToChange, modifyUser: userContext.userId}))
        validateModals(responseAssignPermissions);
        setUpdatedPermissions(true);
    }

    const validateModals = async(responseService: Response)=>{
        if(responseService.status === 200){
          const result = await responseService.json();
          setSuccessMessage(result.message);
          setOpenModalSuccess(true);
          setOpenModalError(false);
        } else {
          setErrorMessage("Hubo un error, intente de nuevo mas tarde");
          setOpenModalError(true);
          setOpenModalSuccess(false);
        }
    }

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
        
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
    };


    return(
        <>
            <Grid container item xs={12} className="back-offers-list-section">
                <ButtonBackListOffers url="/admin/dashboard/gestion-roles" text="Regresar al listado de roles"/>
            </Grid>
            <Grid container className="permissions-view-container">
                <Grid container className="main-container">
                    {userContext.roleSelected === 5 ?
                        <>
                            <Grid container item xs={12} className="title-container">
                                <VerifiedUserIcon className="security-icon"/>
                                <Typography variant="h2" className="bold-serif-primaryColor">
                                    Permisos de {roleSelected.name}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12}>
                                <PermissionsTable rows={permissionsArray} handleChangeCheck={handleChangeCheck}/>
                            </Grid>
                            <Grid container item xs={12} className="container-save-permissions">
                                <Button variant="contained" className="save-permissions-btn" onClick={savePermissions}>
                                    <Typography variant="h5" className="bold-serif">
                                        Guardar Cambios
                                    </Typography>
                                </Button>
                            </Grid>
                        </>
                    :
                        <NotAuthorized/>
                    }
                </Grid>
            </Grid>
            <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess}/>
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError}/>
            <LoadingLockPage load={initLoad}/>
            <LoadingLockPage load={promiseInProgress}/>
        </>
    )
}

export default PermissionsView