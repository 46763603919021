import React from 'react';
import Grid from "@material-ui/core/Grid";
import { Redirect } from 'react-router';
import ReportFormTransactional from '../../../../components/Reports/ReportFormTransactional';
import { isLogin } from '../../../../services/AuthService';

const TransactionalReports = () => {
  const isLogg = isLogin();
  return (
    <>
      {isLogg ?
        <>
          <Grid container>
            <ReportFormTransactional />
          </Grid>
        </>
      :
        <Redirect to ="/"/>
      }
    </>
  )
}

export default TransactionalReports
