import React from 'react';
import { Link } from 'react-router-dom';
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import './SuccessRegister.scss';

const SuccessRegister:React.FC = ()=>{
    const successUser = sessionStorage.getItem('successRegister');


    return(
        <>
            <Grid container className="success-container">
                <Grid container className="main-container" justify="center" alignItems="center">
                    <div className="success-notification">
                        <div className="alert-box">
                            <div className="alert-icon">
                                <CheckCircleOutlineIcon className={successUser === 'graduate' ? "icon-alert-graduate" : "icon-alert-company"}/>
                            </div>
                            <Typography variant="h2" align="center" className="alert-title" style={{color: '#004B73'}}>
                                Su registro ha sido enviado con éxito
                            </Typography>
                            <Typography variant="h5" className="alert-desc">
                                El proceso de verificación se ha iniciado.
                            </Typography>
                            <Typography variant="h5" className="alert-desc">
                                En los próximos días llegará al correo electrónico que usted indicó, la aceptación o motivos del rechazo de su inscripción al Sistema de Información de Egresados -SIE.
                            </Typography>
                            <Grid container item xs={12} justify="center">
                                <div style={{margin: '20px 0'}}>
                                    <Button component={ Link } to="/" variant="contained" className="alert-btn" size="small" style={{color:'#fff', backgroundColor: successUser === 'graduate' ? '#7DA52D' : "#00B5C0"}}>
                                        <Typography variant="h4" className="regular-serif">
                                            Ir al inicio
                                        </Typography>
                                    </Button>
                                </div>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
      </>
    )
}

export default SuccessRegister;