import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import OffersStateFilters from '../../../../components/LaboralOffers/OffersStateFilters/index';
import OffersListFilters from '../../../../components/LaboralOffers/OffersListFilters';
import OffersList from '../../../../components/LaboralOffers/OffersList';
import UserContext from '../../../../utils/UserContext';
import { IGetOffer } from '../../../../interfaces/IGetOffer';
import { getlistOffers } from '../../../../services/LaboralOfferService';
import { IGeneralParameter } from '../../../../interfaces/IGeneralParameter';
import { getOccupationType } from '../../../../services/ParameterService';
import { containsAny, sortByKey, sortByKeyDesc } from '../../../../helpers/sharedFunctions';
import AlertBox from '../../../../components/ShareComponents/AlertBox';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import { permissionCompanyOffersView, permissionCreateLaboralOffer } from '../../../../utils/PermissionsVariables';

import '../company.scss';

interface CompanyOffersViewProps{
    location:any;
    routes:Array<string>;
  }

const CompanyOffersView: React.FC<CompanyOffersViewProps> = ({location, routes}) => {
    const userContext = React.useContext(UserContext);
    const { isArray } = Array;
    const { promiseInProgress } = usePromiseTracker();
    const [url, setUrl] = useState<string | null>(null);
    const [offersList, setOffersList] = useState<Array<IGetOffer>>([]);
    const [idOrder, setIdOrder] = useState(0);
    const [offersFiltered, setOffersFiltered] = useState<Array<IGetOffer>>([]);
    const [searched, setSearched] = useState('');
    const [searchActive, setSearchActive] = useState(false);
    const [selected, setSelected] = useState<string>('');

    useEffect(()=>{
        window.scrollTo(0, 0);
        let stateSelected= "";
        const urlPath = location.pathname;
        if(urlPath==="/empresa/dashboard"){
            setSelected("En definición");
            userContext.setStateOffer("En definición");
            stateSelected="En definición";
        } else if(urlPath === routes[0]){
            setSelected("En definición");
            userContext.setStateOffer("En definición");
            stateSelected="En definición";
        } else if(urlPath === routes[1]){
            setSelected("Convocatoria abierta");
            userContext.setStateOffer("Convocatoria abierta");
            stateSelected="Convocatoria abierta";
        } else if(urlPath === routes[2]){
            setSelected("En Selección");
            userContext.setStateOffer("En Selección");
            stateSelected="En Selección";
        } else if(urlPath === routes[3]){
            setSelected("Finalizado");
            userContext.setStateOffer("Finalizado");
            stateSelected="Finalizado";
        } else if(urlPath === routes[4]){
            setSelected("Cancelada");
            userContext.setStateOffer("Cancelada");
            stateSelected="Cancelada";
        }
        const getdata = async()=>{
            if(userContext.userId && stateSelected){
                if(stateSelected.length > 0){
                    const resOffersCompany: Response = await trackPromise(getlistOffers({company: userContext.userId, state: stateSelected}));
                    getInfoData(resOffersCompany);
                } else {
                    const resOffersCompany: Response = await trackPromise(getlistOffers({company: userContext.userId, state: 'En definición'}));
                    getInfoData(resOffersCompany);
                }
            }
        }
        getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location.pathname])

    const getInfoData = async (responseService: Response) =>{
        if(responseService.status === 200){
            const resultOffersCompany = await responseService.json();
            const responsePostion: Array<IGeneralParameter> = await getOccupationType();
            resultOffersCompany.jobOffers.forEach((offer: IGetOffer) => {
                if(offer.rangeSalary.length>0){
                    const numberSalary = (offer.rangeSalary.split(" "))[0].replace(/\D/g, '');
                    offer.minValueSalary = parseInt(numberSalary);
                } else {
                    offer.minValueSalary = 0;
                }
                const TypeName = responsePostion.find(elm => elm.parameter_code === offer.typePosition);
                if(TypeName){
                    offer.typePositionName = TypeName.name;
                }
            })
            let offersOrder = resultOffersCompany.jobOffers;
            offersOrder = sortByKeyDesc(offersOrder, 'updatedAt');
            setOffersList(offersOrder);
            setOffersFiltered(offersOrder);
        }
    }
    
    const addOffer = ()=>{
        userContext.setAction('Add');
        setUrl('/empresa/dashboard/formulario-oferta/1');
    }

    const filterOrder = (idOrder:number, action: string)=>{
        let tempArr: Array<IGetOffer> = offersFiltered;
        if(idOrder === 1){
            tempArr = sortByKeyDesc(tempArr, action);
        } else if (idOrder === 2){
            tempArr = sortByKey(tempArr, action);
        } else if (idOrder === 3){
            tempArr = sortByKeyDesc(tempArr, action);
        }
        setOffersFiltered(tempArr);
        setIdOrder(idOrder);
    }

    const requestSearch = (name: string, searchVal:string)=>{
        setSearched(searchVal);
        if(searchVal !== ''){
            const resultSearch = search(offersList, searchVal);
            setOffersFiltered(resultSearch);
            setSearchActive(true)
        } else {
            setSearchActive(false)
            setOffersFiltered(offersList);
        }
    }

    const search = (entries: any[], searchVal: string) => {
        searchVal = searchVal.toLowerCase();
        return entries.filter((obj) => {
            const keys: string[] = Object.keys(obj);
            return keys.some((key) => {
                const value = obj[key];
                if(typeof(value) !== 'object'){
                    if (isArray(value)) {
                        return value.some(v => {
                            return v.toLowerCase().includes(searchVal);
                        });
                    }
                    else if (!isArray(value)) {
                        if (typeof value === "string") {
                            return value.toLowerCase().includes(searchVal);
                        }
                    }
                }
            })
        });
    }

    const cancelSearch = ()=>{
        setSearched('')
        requestSearch('searched', '');
        setOffersFiltered(offersList);
        setSearchActive(false)
    }


    return (
        <>
        {containsAny(permissionCompanyOffersView,userContext.userPermissions) ?
            <>
            {
                url !== null ?
                <Redirect to={url} />
            :
                <>
                    <Grid item xs={12} className="state-offers-section">
                        <OffersStateFilters selected={selected} offersNumber={offersFiltered.length}/>
                    </Grid>
                    <Grid container className="main-container">
                        { selected === 'En definición' &&
                            <>
                                <AlertBox
                                    title=""
                                    alert="info"
                                    variantType="outlined"
                                >
                                    <span className="alert-simple-text">
                                        Las ofertas en estado <span className="alert-simple-title"><b>En definición</b> </span>serán evaluadas por la Universidad Nacional de Colombia, una vez evaluadas, pasarán a <span className="alert-simple-title"><b>Convocatoria abierta: </b></span>desde allí los egresados pueden aplicar a las ofertas.<br/>El estado En definición es en el único estado donde las ofertas se pueden modificar.
                                    </span>
                                </AlertBox>
                                {containsAny(permissionCreateLaboralOffer,userContext.userPermissions) &&
                                    <Button disabled={!containsAny(permissionCreateLaboralOffer,userContext.userPermissions)} variant="contained" className="add-offer-btn" endIcon={<AddIcon />}  onClick={addOffer}>
                                        <Typography variant="h6" className="regular-serif">
                                            Adicionar nueva oferta laboral
                                        </Typography>
                                    </Button>
                                }
                            </>
                        }
                        {
                            selected === 'Convocatoria abierta' &&
                            <AlertBox
                                title=""
                                alert="info"
                                variantType="outlined"
                            >
                                <span className="alert-simple-text">
                                    Las ofertas en <span className="alert-simple-title">Convocatoria abierta </span>serán visibles por los egresados y ellos podrán aplicar a las ofertas.<br/>Desde esta interfaz podrá administrar las ofertas y cambiar su estado de <span className="alert-simple-title">Convocatoria abierta </span>al estado <span className="alert-simple-title">En selección, </span>para iniciar la selección de los aspirantes.
                                </span>
                            </AlertBox>
                        }
                        {
                            selected === 'En Selección' &&
                            <AlertBox
                                title=""
                                alert="info"
                                variantType="outlined"
                            >
                                <span className="alert-simple-text">
                                    Las ofertas en <span className="alert-simple-title">En selección </span>serán visibles por los egresados y ellos podrán aplicar a las ofertas y usted podrá iniciar la selección de los aspirantes.<br/>Desde esta interfaz podrá administrar las ofertas y cambiar su estado de <span className="alert-simple-title">En selección </span>al estado <span className="alert-simple-title">Finalizado, </span>dónde dará cierre al proceso.
                                </span>
                            </AlertBox>
                        }
                        {
                            selected === 'Finalizado' &&
                            <AlertBox
                                title=""
                                alert="info"
                                variantType="outlined"
                            >
                                <span className="alert-simple-text">
                                    Las ofertas en <span className="alert-simple-title">Finalizado </span>no son visibles para egresados que no hallan aplicado.<br/>En esta interfaz usted podrá reutilizar las ofertas que ya estén cerradas para crear nuevas ofertas.<br/>En esta interfaz usted podrá Reabrir convocatorias que no finalizaron con exito, para ampliar los plazos de la convocatoria.
                                </span>
                            </AlertBox>
                        }
                        <OffersListFilters filterOrder={filterOrder} searched={searched} requestSearch={requestSearch} cancelSearch={cancelSearch} searchActive={searchActive} bgColor="#004B73"/>
                        {offersFiltered.length>0 &&
                            <OffersList offersList={offersFiltered} idOrder={idOrder}/>
                        }
                        <LoadingLockPage load={promiseInProgress}/>
                    </Grid>
                </>
            }
            </>
        :
            <NotAuthorized/>
        }
        </>
    )
}

export default CompanyOffersView
