const baseUrl = process.env.REACT_APP_SIE_APP_PROXY_BASE_URL;
const TOKEN_KEY = "Bearer";

export const getEventsNews = async (bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/publishing/list`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const createEventsNews = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/publishing/create`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const updateEventsNews = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/publishing/update`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const enrollUserEvent = async (bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/publishing/create_event_log`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const getEventsByUser = async (bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/publishing/check_in_list`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const getRegisteredNumberEvent = async (bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/publishing/event_log_amount`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const getOutstanding = async (bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/publishing/relational`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const fileEventLog = async (bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/publishing/file_event_log`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}
