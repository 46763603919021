import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import moment from 'moment';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { Grid, Typography } from '@material-ui/core';
import { DateRange } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import { GridSelectionModelChangeParams, GridSortModelParams } from '@material-ui/data-grid';
import { CompanyStateObject } from '../../../../interfaces/CompanyStateObject';
import { StateByRole } from '../../../../interfaces/StateByRole';
import UserContext from '../../../../utils/UserContext';
import { getlistFilterCompanies } from '../../../../services/AdminServices';
import DataTableCompany from '../../../../components/ManagementState/DataTableCompany';
import { IGeneralParameter } from '../../../../interfaces/IGeneralParameter';
import { IListCompanies } from '../../../../interfaces/IListCompanies';
import { ICompanyTable } from '../../../../interfaces/ICompanyTable';
import SimpleFilterBox from '../../../../components/ManagementState/SimpleFilterBox';
import MoreFiltersCompany from '../../../../components/ManagementState/MoreFiltersCompany';
import Validations from '../../../../helpers/Validations';
import ShowFilters from '../../../../components/ManagementState/ShowFilters';
import { ChipData } from '../../../../interfaces/ChipData';
import SearchTable from '../../../../components/ManagementState/SearchTable';
import SelectActionsBox from '../../../../components/ManagementState/SelectActionsBox/index';
import { IGeneralSIA } from '../../../../interfaces/IGeneralSIA';
import { isLogin } from '../../../../services/AuthService';
import { ICompanyData } from '../../../../interfaces/ICompanyData';
import { ICompanyFilterForm } from '../../../../interfaces/ICompanyFilterForm';
import { ICompanyFilterObjDinamic } from '../../../../interfaces/ICompanyFilterObjDinamic';
import CompanyChangeAction from '../../../../components/ManagementState/CompanyChangeAction';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import { checkValuesSelected, containsAny, resetStatusCheckArray, sortByKey, sortByKeyDesc } from '../../../../helpers/sharedFunctions';
import { getCITY, getCOMPANY_TYPE, getCOUNTRIES_CITIES, getDEPARTAMENT, getLABORAL_SECTOR } from '../../../../services/ParameterService';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import { permissionCompanyActiveRejectedState, permissionCompanyInactiveState, permissionCompanyView } from '../../../../utils/PermissionsVariables';
import { IListParametersInfo } from '../../../../interfaces/IListParametersInfo';


const stateOptionsCompany: Array<StateByRole> = [
    {id:1, label: 'Inscrito', value:'Inscrito', description: 'Cambiar estado a Inscrito', permission:[] },
    {id:2, label: 'Activo', value:'Activo', description: 'Cambiar estado a Activo', permission: permissionCompanyActiveRejectedState },
    {id:3, label: 'Rechazado', value:'Rechazado', description: 'Cambiar estado a Rechazado', permission:  permissionCompanyActiveRejectedState },
    {id:4, label: 'Inactivo', value:'Inactivo', description: 'Cambiar estado a Inactivo', permission: permissionCompanyInactiveState }
];

const actionsForCompanies:Array<IGeneralSIA> = [
    {id:1, name:'Cambiar estado a...'},
]

export class CompanyStateTosendObj{
    companies: Array<CompanyStateObject> = []
}

const filterCompanyValidation = {

    name: {
      error: "",
      validate: "textNumber",
      maxLength: 40,
      required: false
    },
    nit: {
        error: "",
        validate: "number",
        minLength: 7,
        maxLength: 15,
        required: false
    },
    laboralSector: {
        validate: "select",
        required: false
    },
    typeOfCompany: {
        validate: "select",
        required: false
    },
    country: {
        validate: "select",
        required: false
    },
    department: {
        validate: "select",
        required: false
    },
    city: {
        validate: "select",
        required: false
    },
    state:{
        validate: "select",
        required: false,
    },
    observation: {
        validate: "select",
        required: false
    },
    otherObservation: {
        error: "",
        validate: "textNumber",
        minLength: 20,
        maxLength: 200,
        required: true
    },
}

const initialCompanyFilterErrors ={
    name: "",
    nit: "",
    laboralSector: "",
    typeOfCompany: "",
    country: "",
    department: "",
    city: "",
    state: "",
    createdAtStart: "",
    createdAtEnd: ""
}

const fieldsValidation = {
    searched: {
      error: "",
      validate: "number",
      required: false
    }
};

const initialErrorsSearch={
    searched:''
}

export const tranformDataCompany = (values: ICompanyFilterForm, preview:boolean)=>{
    const temporaryObject: ICompanyFilterObjDinamic = new ICompanyFilterObjDinamic();
    
    temporaryObject.preview = preview;

    // company info filters
    if(temporaryObject.company && temporaryObject.company.data){
        if(values.name !== ""){
            temporaryObject.company.data.name = values.name;
        } else { delete temporaryObject.company.data.name }
        if(values.nit === null || values.nit === ""){
            delete temporaryObject.company.data.nit
        } else { temporaryObject.company.data.nit = parseInt(values.nit); }
        if(values.laboralSector >0){
            temporaryObject.company.data.laboralSector = values.laboralSector;
        } else { delete temporaryObject.company.data.laboralSector }
        if(values.typeOfCompany >0){
            temporaryObject.company.data.typeOfCompany = values.typeOfCompany;
        } else { delete temporaryObject.company.data.typeOfCompany }
        if(values.country >0){
            temporaryObject.company.data.country = values.country;
        } else { delete temporaryObject.company.data.country }
        if(values.department >0){
            temporaryObject.company.data.department = values.department;
        } else { delete temporaryObject.company.data.department }
        if(values.city >0){
            temporaryObject.company.data.city = values.city;
        } else { delete temporaryObject.company.data.city }
        if(values.state.length>0){
            temporaryObject.company.data.state = values.state.map(item=>item.value);
        } else { delete temporaryObject.company.data.state }
        if(values.inJobBankDate[0] !== null && values.inJobBankDate[1] !== null){
            temporaryObject.company.data.inJobBankInit = moment(values.inJobBankDate[0]).format();
            temporaryObject.company.data.inJobBankEnd = moment(values.inJobBankDate[1]).format();
        } else {
            delete temporaryObject.company.data.inJobBankInit;
            delete temporaryObject.company.data.inJobBankEnd;
        }
    }
    return temporaryObject;
}

const CompanyManagement:React.FC = ()=>{
    const userContext = React.useContext(UserContext);
    const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
    const { promiseInProgress } = usePromiseTracker();
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [filterValues, setFilterValues] = useState<ICompanyFilterForm>(new ICompanyFilterForm());
    const [formErrors, setFormErrors] = useState(initialCompanyFilterErrors);
    const [checkRows, setCheckRows] = useState<Array<string>>([]);
    const [allLaboralSector, setAllLaboralSector] = useState<Array<IGeneralParameter>>([]);
    const [allCompanyType, setAllCompanyType] = useState<Array<IGeneralParameter>>([]);
    const [countryCitiesArray, setCountryCitiesArray] = useState<Array<IListParametersInfo>>([]);
    const [departmentArray, setDepartmentArray] = useState<Array<IGeneralParameter>>([]);
    const [cityArray, setCityArray] = useState<Array<IGeneralParameter>>([]);
    const [cities, setCities] = useState<Array<IGeneralParameter>>([]);
    const [chipData, setChipData] = useState<ChipData[]>([]);
    const [rowsPerPage, setRowsPerPage]= useState(5);
    const [initPage, setInitPage]= useState<number>(0);
    const [stateOptions, setStateOptions] = useState<Array<StateByRole>>([]);
    const [searched, setSearched] = useState('');
    const [formErrorSearch, setFormErrorSearch] = useState(initialErrorsSearch);
    const [searchActive, setSearchActive] = useState(false);
    const [originData, setOriginData] = useState<ICompanyTable[]>([]);
    const [dataFiltered, setDataFiltered] = useState<ICompanyTable[]>([]);
    const [tempDataFiltered, setTempDataFiltered] = useState<ICompanyTable[]>([]);
    const [stateSelected, setStateSelected] = useState<string>('');
    const [actionSelected, setActionSelected]= useState(0);
    const [companiesToChange, setCompaniesToChange] = useState<Array<string>>([]);
    const [companiesStateToSend, setCompaniesStateToSend] = useState(new CompanyStateTosendObj());
    const [companiesToChangeTable, setCompaniesToChangeTable] = useState<Array<ICompanyTable>>([]);
    const [url, setUrl] = useState<string | null>(null);
    const [currrentPage, setCurrentPage] = useState<number>(0);
    const [deleteChipActive, setDeleteChipActive] = useState<boolean>(false);
    const [applyFilterActive, setApplyFilterActive] = useState<boolean>(false);
    const [total, setTotal] = useState(0);
    const limitNumber = 100;

    const [stateCheck, setStateCheck] = useState<any>({
        Inscrito: false,
        Activo: false,
        Rechazado: false,
        Inactivo: false
    });

    const isValid =
        !formErrors.name &&
        !formErrors.nit &&
        !formErrors.createdAtStart &&
        !formErrors.createdAtEnd

    useEffect(()=>{
        window.scrollTo(0, 0);
        const getInitialData = async()=>{
            const optionsInit = [""];
            let originData: IListCompanies;
            //data for table
            setStateCheck({ ...stateCheck, "Inscrito": false });         
            if(dataFiltered.length !== 0){
                const responseCompaniesList = await getlistFilterCompanies(userToken, 
                    {
                        company: {
                            data: {
                              state: optionsInit
                            }
                        },
                        limit: limitNumber === rowsPerPage ? limitNumber+25 : limitNumber,
                        offset: 0
                    }
                );
                if(responseCompaniesList.status === 200){
                    originData = await responseCompaniesList.json();
                    let tempArr:Array<ICompanyTable> = [];
                    if(originData && originData.companies.length>0){
                        originData.companies.forEach((item: ICompanyData)=>{
                            const target: ICompanyTable = new ICompanyTable();
                            if(item.company){
                                const result = Object.assign(target, {...item.company});
                                result.fullNit = result.nit + '-' + result.verificationDigit;
                                tempArr.push(result)
                            }
                        });
                        setOriginData(tempArr);
                        setDataFiltered(tempArr);
                        setTempDataFiltered(tempArr);
                    }
                }
            }
            //  company type
            let responseCompanyType: Array<IGeneralParameter> = await getCOMPANY_TYPE();
            responseCompanyType = sortByKey(responseCompanyType, 'name');
            setAllCompanyType(responseCompanyType)
            //  laboral sector
            let responseLaboralSector: Array<IGeneralParameter> = await getLABORAL_SECTOR();
            responseLaboralSector = sortByKey(responseLaboralSector, 'name');
            setAllLaboralSector(responseLaboralSector);
            // data countries-cities
            let dataCountryCities:Array<IListParametersInfo> = (await getCOUNTRIES_CITIES()).list;
            dataCountryCities = sortByKey(dataCountryCities, 'grandParentName');
            setCountryCitiesArray(dataCountryCities);
            //data for departments field
            let dataDepartment:Array<IGeneralParameter> = await getDEPARTAMENT();
            dataDepartment = sortByKey(dataDepartment, 'name');
            setDepartmentArray(dataDepartment ? dataDepartment : []);
            //data for cities
            const dataCity:Array<IGeneralParameter> = await getCITY();
            setCityArray(dataCity || []);
            if(userContext.userPermissions.length>0){
                let optionsRole: Array<StateByRole> =  stateOptionsCompany.filter((option)=> {
                    return containsAny(option.permission,userContext.userPermissions);
                });
                optionsRole = sortByKey(optionsRole, 'id');
                setStateOptions(optionsRole);
            }
            setInitLoad(false);
        }
        getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if(filterValues.country === 0){
            setFilterValues(prev => ({
                ...prev,
                department:0,
                city:0
            }));
            setCities([]);
        }else {
            if(filterValues.country === 170){
                setCities([]);
            } else {
                setFilterValues(prev => ({
                    ...prev,
                    department:0,
                    city:0
                }));
                const countryObjSelected = countryCitiesArray.find(country=>country.id === filterValues.country);
                if(countryObjSelected){
                    setCities(countryObjSelected.childs);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filterValues.country])

    useEffect(()=>{
        setFilterValues(prev => ({
            ...prev,
            city:0
        }))
        if(filterValues.department>0){
            let citiesDep:Array<IGeneralParameter> = cityArray.filter(city=> city.parentId === filterValues.department);
            citiesDep = sortByKey(citiesDep, 'name');
            if(citiesDep.length>0){
                setCities(citiesDep)
            }
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filterValues.department])

    useEffect(()=>{
        if(isValid){
            setChips();
        }
        if(deleteChipActive && applyFilterActive){
            const prevObjectToSend = tranformDataCompany(filterValues, true);
            const objectToSend = transformCompanyFields(prevObjectToSend, limitNumber === rowsPerPage ? limitNumber+25 : limitNumber, 0);
            updateDataCompanies(objectToSend, []);
            setInitPage(0);
            setDeleteChipActive(false);
        }
        if(chipData.length === 0 ){
            setApplyFilterActive(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, isValid])

    const setChips =()=>{
        let tempArray: Array<ChipData> = []
        let index = 0;
        const fieldNames=[
            {key: 'name', description: 'Razón social'},
            {key: 'nit', description: 'Nit de la empresa'},
            {key: 'laboralSector', description: 'Sector laboral'},
            {key: 'typeOfCompany', description: 'Tipo de empresa'},
            {key: 'country', description: 'País'},
            {key: 'department', description: 'Departamento'},
            {key: 'city', description: 'Municipio'},
            {key: 'rangeRegister', description: 'Fecha de inscripción'}
        ]
        for (const [key, value] of Object.entries(filterValues)) {
            if(value!==0 && value!=='' && value!==null && value.length!==0){
                if(value[0]!==null && value[1]!==null ){
                    let obj;
                    const fieldObj = fieldNames.find(field=>field.key === key);
                    if(key === 'laboralSector'){
                        obj = allLaboralSector.find(sector=>sector.parameter_code === value);
                    } else if (key === 'typeOfCompany'){
                        obj = allCompanyType.find(Tcompany=>Tcompany.parameter_code === value );
                    } else if (key === 'country' && value === 170){
                        obj = {name:'Colombia'}
                    } else if (key === 'department'){
                        obj = departmentArray.find(dep=>dep.parameter_code === value);
                    } else if (key === 'city'){
                        obj = cities.find(city=>city.parameter_code === value);
                    } else if (key === 'rangeRegister'){
                        const startDate: string = moment(value[0]).format('DD/MM/YYYY');
                        const endDate: string = moment(value[1]).format('DD/MM/YYYY');
                        obj = {name: `${startDate} hasta ${endDate}`}
                    }
                    if(obj){
                        tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + obj.name })
                    } else {
                        tempArray.push({key: index, name:key, label: fieldObj?.description + ': ' + value})
                    }
                    index++;
                }
            }
        }
        setChipData(tempArray)
    };

    const handleDeleteChip = (chipToDelete: ChipData) => () => {
        setDeleteChipActive(true);
        const name =chipToDelete.name;
        setChipData((chips) => chips.filter((chip) => chip.name !== chipToDelete.name));
        if(name === 'laboralSector' || name === 'typeOfCompany' || name === 'country' || name === 'department' || name === 'city'){
            setFilterValues(prev => ({
                ...prev,
                [chipToDelete.name]: 0
            }));
        } else if(name === 'rangeRegister'){
            setFilterValues(prev => ({
                ...prev,
                [name]: [null,null]
            }));
        } else {
            setFilterValues(prev => ({
                ...prev,
                [chipToDelete.name]: ''
            }));
        }
    };

    const transformCompanyFields = (prevObjectToSend: ICompanyFilterObjDinamic, limit: number, offset:number) => {
        if(prevObjectToSend.company && prevObjectToSend.company.data){
            delete prevObjectToSend.company.fields;
            const stateFieldsSelected = checkValuesSelected(stateCheck);
            prevObjectToSend.company.data.state = stateFieldsSelected || [];
        }
        if(prevObjectToSend.contact){
            delete prevObjectToSend.contact;
        }
        if(prevObjectToSend.headquarter){
            delete prevObjectToSend.headquarter;
        }
        delete prevObjectToSend.preview;
        prevObjectToSend.limit = limit;
        prevObjectToSend.offset = offset;
        return prevObjectToSend;
    }

    const updateDataCompanies = async(objectToSend: ICompanyFilterObjDinamic, currentData: Array<any>) => {
        const responseCompaniesList = await trackPromise(getlistFilterCompanies(userToken, 
            objectToSend
        ));
        if(responseCompaniesList.status === 200){
            const newData = await responseCompaniesList.json();
            setTotal(newData.total);
            let tempArr:Array<ICompanyTable> = [];
            if(newData && newData.companies.length>0){
                newData.companies.forEach((item: ICompanyData)=>{
                    const target: ICompanyTable = new ICompanyTable();
                    if(item.company){
                        const result = Object.assign(target, {...item.company});
                        result.fullNit = result.nit + '-' + result.verificationDigit;
                        tempArr.push(result)
                    }
                });
                setOriginData([...currentData, ...tempArr]);
                setDataFiltered([...currentData, ...tempArr]);
                setTempDataFiltered([...currentData, ...tempArr]);
            }
            if(objectToSend.offset === 0 && newData.companies.length === 0){
                setOriginData([]);
                setDataFiltered([]);
                setTempDataFiltered([]);
            }
        }
    }

    useEffect(()=>{
        const checkForNewData = async() => {
            if(originData.length>0){
                const totalRows = originData.length;
                const totalPages = Math.ceil(totalRows/rowsPerPage);
                let lastPage = false;
                if(totalPages-currrentPage === 0){
                    lastPage = true;
                } else {
                    lastPage = false;
                }
                const prevObjectToSend = tranformDataCompany(filterValues, true);
                const objectToSend = transformCompanyFields(prevObjectToSend, limitNumber === rowsPerPage ? limitNumber+25 : limitNumber, totalRows);
                if(lastPage){
                    updateDataCompanies(objectToSend, originData);
                }
            }
        }
        checkForNewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currrentPage, originData, rowsPerPage])

    const clickApplyFilters = (value:any, action:string)=>{
        if(action === 'apply'){
            requestSearch('searched', '');
            const prevObjectToSend = tranformDataCompany(filterValues, true);
            const objectToSend = transformCompanyFields(prevObjectToSend, limitNumber === rowsPerPage ? limitNumber+25 : limitNumber, 0);
            updateDataCompanies(objectToSend, []);
            setInitPage(0);
            setChips();
            setApplyFilterActive(true);
        } else if (action === 'clear'){
            setSearched('')
            setSearchActive(false);
            setChipData([]);
            setFormErrors(initialCompanyFilterErrors);
            setApplyFilterActive(false);
            const newState = resetStatusCheckArray(stateCheck);
            setStateCheck({ ...newState, "Inscrito": true });
            const prevObjectToSend = tranformDataCompany(new ICompanyFilterForm(), true);
            const objectToSend = transformCompanyFields(prevObjectToSend, limitNumber === rowsPerPage ? limitNumber+25 : limitNumber, 0);
            if(objectToSend.company && objectToSend.company.data){
                objectToSend.company.data.state = ["Inscrito"];
                updateDataCompanies(objectToSend, []);
                setInitPage(0);
            }
            setFilterValues(new ICompanyFilterForm());
        }
    }

    const requestSearch = (name: string, searchVal:string)=>{
        const error: string = searchVal !== '' ? Validations(name, searchVal, fieldsValidation) || "" : '';
        setFormErrorSearch(prev=>({
            ...prev,
            [name]: error
        }));
        setSearched(searchVal);
        if(searchVal !== ''){
            setSearchActive(true);
            const filteredData = originData.filter(item=>item.nit.toString().includes(searchVal));
            setDataFiltered(filteredData)
            
            setTempDataFiltered(filteredData);
        } else {
            setSearchActive(false);
            setDataFiltered(originData);
            setTempDataFiltered(originData);
        }
        setInitPage(0);
    }

    const cancelSearch = ()=>{
        setSearched('')
        requestSearch('searched', '');
        setSearchActive(false)
    }

    const handleClickAll = (allSelect:boolean)=>{
        if(allSelect){
            let list = [];
            for (let i = 0; i < dataFiltered.length; i++) {
                list.push((dataFiltered[i].id).toString());
            }
            setCheckRows(list)
        } else {
            setCheckRows([])
        }
    }

    const handleSortedRows = (params: any)=>{
        if(params.sortModel.length>0){
            let datasorted: ICompanyTable[] = [...dataFiltered];
            datasorted.forEach(item =>{
                if(item.name){
                    item.name = (item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()).trim();
                }
            });
            const fieldToSort = params.sortModel[0].field;
            const sortOption = params.sortModel[0].sort;
            if(sortOption === "asc"){
                datasorted = sortByKey(datasorted, fieldToSort);
            } else if (sortOption === "desc") {
                datasorted = sortByKeyDesc(datasorted, fieldToSort);
            }
            setDataFiltered(datasorted);
        } else {
            setDataFiltered(tempDataFiltered);
        }
    }

    const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
        setApplyFilterActive(false);
        setStateCheck({ ...stateCheck, [event.target.name]: event.target.checked });
    };

    const onChanhePageSize = (e: any)=>{
        setRowsPerPage(e.target.value);
        setInitPage(0);
    }

    const handleSelectionRows = (params:any)=>{
        setCompaniesToChange(params.selectionModel)
    }

    const handleRowButton = (action:string, rowSelected: ICompanyTable)=>{
        const companySelected: ICompanyTable = rowSelected;
        const companyId = rowSelected.id+"";
        const stateSelected = action;
        let companiesObject:Array<ICompanyTable> = [companySelected];
        let companyStructure:Array<CompanyStateObject>=[];
        if(companyId.length>0){
            companyStructure.push({nit: companyId, state: stateSelected, observation: "", otherObservation:''})
        }
        if(action === 'Ofertas'){
            setUrl(`/admin/dashboard/empresa-info/${rowSelected.nit}`);
        }
        setCompaniesToChangeTable(companiesObject)
        setCompaniesStateToSend({companies: companyStructure})
    }

    const changeFilterForm = (e: any) => {
        setApplyFilterActive(false);
        const {name,value} = e.target;
        setFilterValues(prev => ({
            ...prev,
            [name]: value
        }));
        const error: string = Validations(name, value, filterCompanyValidation) || "";
        setFormErrors(prev=>({
            ...prev,
            [name]: error
        }));
    }

    const changeFilterDates = (name: string, dateValue: DateRange<Date>)=>{
        setApplyFilterActive(false);
        setFilterValues(prev => ({
            ...prev,
            [name]: dateValue
        }));
        const startDate: string = moment(dateValue[0]).format('YYYY/MM/DD');
        const endDate: string = moment(dateValue[1]).format('YYYY/MM/DD');
        let labelStart: string = "";
        let labelEnd: string = "";
        const valueStart = startDate === "Fecha inválida" ? "Fecha inválida" : "";
        const valueEnd = endDate === "Fecha inválida" ? "Fecha inválida" : "";
        if(name === "rangeRegister"){
            labelStart = "createdAtStart";
            labelEnd = "createdAtEnd";
        }
        errorDates(labelStart, valueStart);
        errorDates(labelEnd, valueEnd);
    }

    const errorDates = (label: string, value: string) => {
        setFormErrors(prev => ({
            ...prev,
            [label]: value
        }));
    }

    const handleChangeAction = (e: any)=>{
        setActionSelected(e.target.value)
    }

    const handleChangeStateSelected = (e:any)=>{
        setStateSelected(e.target.value)
    }

    const sendActionSelected =()=>{
        let companyStructure:Array<CompanyStateObject>=[];
        let companiesObject:Array<ICompanyTable> = [];
        if(companiesToChange.length>0){
            companiesToChange.forEach((companyNit:string)=>{
                companyStructure.push({nit: companyNit, state: stateSelected, observation: "", otherObservation:''})
                const companyFound = dataFiltered.find(obj=>obj.nit+"" === companyNit);
                if(companyFound){
                    companiesObject.push(companyFound)
                }
            })
        }
        setCompaniesToChangeTable(companiesObject)
        setCompaniesStateToSend({companies: companyStructure})
    }


    return(
        <>
        {isLogin() ?
            <>
            {containsAny(permissionCompanyView,userContext.userPermissions) ?
                <>
                {
                    url !== null ?
                    <Redirect to={url} />
                    :
                    companiesStateToSend.companies.length>0 ?
                        <CompanyChangeAction companiesObject={companiesToChangeTable} companiesStateToSend={companiesStateToSend} formValues={filterValues} formErrors={formErrors} onChangeForm = {changeFilterForm} cityArr={cityArray}  allLaboralSector={allLaboralSector}/>
                    :
                    <Grid container className="main-container">
                        <Grid container item xs={12}>
                            <Typography variant="h5" className="tab-description">
                                En esta interfaz puede administrar los usuarios de empresas, hacer búsquedas específicas, realizar acciones masivas, entre otras.
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <SimpleFilterBox title="Estado del registro de la empresa" subtitle="Filtrar por estados:" stateCheck={stateCheck} onChangeState={handleChangeState} stateOptions={stateOptionsCompany}/>
                        </Grid>
                        <Grid container item xs={12}>
                            <MoreFiltersCompany titleSection="Agregar otros filtros de búsqueda" formValues={filterValues} formErrors={formErrors} onChangeForm={changeFilterForm} changeFilterDates={changeFilterDates} allLaboralSector={allLaboralSector} allCompanyType={allCompanyType} countryCitiesArray={countryCitiesArray} departmentArray={departmentArray} cities={cities} reportForm={false} stateOptions={stateOptionsCompany}/>
                        </Grid>
                        <Grid container item xs={12}>
                            <ShowFilters titleSection="Filtros seleccionados" filtersData={chipData} stateCheck={stateCheck} showButtons={true} showDelete={true} deleteFilter={handleDeleteChip} isValid={isValid} onApplyFilters={clickApplyFilters} />
                        </Grid>
                        <Grid container item xs={12}>
                            <Typography variant="h2" className="bold-serif-primaryColor">Empresas</Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <div className="header-table-container">
                                <div className="search-container-outer">
                                    {/* <SearchTable textPlaceHolder="Ingresa número de NIT" title="Buscar por número de NIT" searched={searched} requestSearch={requestSearch} formErrorSearch={formErrorSearch} searchActive={searchActive} cancelSearch={cancelSearch}/> */}
                                </div>
                                <div className="results-found-container">
                                    <Typography variant="h5" className="bold-serif">
                                        Total empresas encontradas {total}
                                    </Typography>
                                </div>
                                <div className="select-all-btns">
                                    <Button style={{display: checkRows.length>0 ? 'none' : 'initial'}} variant="outlined" className="select-all-check" size="small" onClick={(e)=>handleClickAll(true)}>
                                        <Typography variant="h6" className="regular-serif">
                                            Seleccionar todos los registros
                                        </Typography>
                                    </Button>
                                    <Button style={{display: checkRows.length>0 ? 'initial' : 'none'}} variant="outlined" className="select-any-check" size="small" onClick={(e)=>handleClickAll(false)}>
                                        <Typography variant="h6" className="regular-serif">
                                            No seleccionar ninguno
                                        </Typography>
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                        <Grid container item xs={12}>
                            <DataTableCompany checkRows={checkRows} setCheckRows={setCheckRows} allLaboralSector={allLaboralSector} cityArr={cityArray} onChanhePageSize={onChanhePageSize} rows={dataFiltered} pageSize={rowsPerPage} onSelectionRows={(params: GridSelectionModelChangeParams)=>handleSelectionRows(params)} handleRowButton={handleRowButton} initPage={initPage} setInitPage={setInitPage} stateOptions={stateOptions} setCurrentPage={setCurrentPage} onSortedRows={(params: GridSortModelParams)=>handleSortedRows(params)}/>
                        </Grid>
                        {stateOptions.length>0 &&
                            <Grid container item xs={12}>
                                <SelectActionsBox usersToChange={companiesToChange} sendActionSelected={sendActionSelected} stateOptions={stateOptions} stateSelected={stateSelected} actionOptions={actionsForCompanies} actionSelected={actionSelected} onChangeAction={handleChangeAction} onChangeStateSelected={handleChangeStateSelected} />
                            </Grid>
                        }
                    </Grid>
                }
                <LoadingLockPage load={promiseInProgress}/>
                <LoadingLockPage load={initLoad}/>
                </>
            :
                <NotAuthorized/>
            }
            </>
        :
            <Redirect to ="/"/>
        }
        </>
    )
}

export default CompanyManagement;