import React from 'react';
import Typography from '@material-ui/core/Typography';

import './NotAuthorized.scss';

const NotAuthorized:React.FC = () => {
    return(
        <div className="main-container">
            <div className="not-authorized-container">
                <Typography variant="h1" className="bold-serif">
                    Este Rol no tiene permisos para acceder a esta funcionalidad.
                </Typography>
                <Typography variant="h3" className="regular-serif">
                    Si necesita utilizar esta funcionalidad contacte al administrador del sistema
                </Typography>
            </div>
        </div>
    )
}

export default NotAuthorized;