import React, { useState, useEffect } from 'react';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import WarningIcon from "@material-ui/icons/Warning";
import { Redirect } from 'react-router-dom';
import UserContext from '../../../utils/UserContext';
import ModalOffersAction from '../ModalOffersAction';
import {IGetOffer} from '../../../interfaces/IGetOffer';
import OfferCard from '../OfferCard';
import { changeStateLaboralOffer } from '../../../services/ChangeStateService';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import { permissionCompanyChangeStateOffer } from '../../../utils/PermissionsVariables';
import { containsAny } from '../../../helpers/sharedFunctions';


interface OffersListProps{
    offersList: Array<IGetOffer>;
    idOrder: number;
}

const OffersList: React.FC<OffersListProps> = ({offersList, idOrder})=>{
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [url, setUrl] = useState<string | null>(null);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [openChangeModal, setOpenChangeModal] = useState<boolean>(false);
    const [openReopenModal, setOpenReopenModal] = useState<boolean>(false);
    const [idOfferSelected, setIdOfferSelected] = useState<number | null>(null);
    const [stateToChange, setstateToChange]= useState<string>('');
    const [errorMessage, setErrorMessage]= useState('');
    const [openModalError, setOpenModalError] = React.useState(false);
    const [successMessage, setSuccessMessage]= useState('');
    const [openModalSuccess, setOpenModalSuccess] = React.useState(false);
    const [newList, setNewList] = useState<boolean>(true);

    useEffect(()=>{
        setUrl(null);
    },[offersList])

    useEffect(()=>{
        if(idOrder>0){
            setNewList(!newList)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[idOrder])

    const handleDeleteModal = (e: any, idOffer: number)=>{
        setIdOfferSelected(idOffer);
        setstateToChange("Cancelada");
        setOpenDeleteModal(true);
    }

    const deleteOffer = async()=>{
        setOpenDeleteModal(false);
        const responseCSD = await trackPromise(changeStateLaboralOffer(
            {id: idOfferSelected, state:'Cancelada', causal: "Proceso cancelado", modifyUser: userContext.userId}
        ));
        if(responseCSD.status === 200){
            const result = await responseCSD.json();
            setSuccessMessage(result.message)
            setOpenChangeModal(false);
            setOpenModalSuccess(true);
            setOpenModalError(false);
          } else {
            setErrorMessage("Hubo un error, intente de nuevo mas tarde");
            setOpenModalError(true);
            setOpenModalSuccess(false);
          }
    }

    const closeModalDelete = ()=>{
        setOpenDeleteModal(false);
    }

    const modifyOffer = (e: any, idOffer: number)=>{
        userContext.setAction('Modify');
        setUrl(`/empresa/dashboard/formulario-oferta/${idOffer}`);
    }

    const handleSeeOffer = (e: any, idOffer: number)=>{
        setIdOfferSelected(idOffer);
        userContext.setAction('See');
        setUrl(`/empresa/detalle-oferta/${idOffer}`);
    }

    const handleChangeModal = (e: any, stateToChange: string, idOffer: number)=>{
        setIdOfferSelected(idOffer);
        setstateToChange(stateToChange)
        setOpenChangeModal(true);
    }

    const handleReopen = (e: any, stateToChange: string, idOffer: number)=>{
        setIdOfferSelected(idOffer);
        setstateToChange(stateToChange)
        setOpenReopenModal(true);
    }

    const handleChangeFinalizada = (e: any, stateToChange: string, idOffer: number)=>{
        setUrl(`/empresa/finalizar-oferta/${idOffer}/${stateToChange}`);
    }

    const changeStateOffer = async()=>{
        setOpenChangeModal(false);
        const responseCSF = await trackPromise(changeStateLaboralOffer(
            {id: idOfferSelected, state: stateToChange, causal: "", modifyUser: userContext.userId}
        ));
        if(responseCSF.status === 200){
            const result = await responseCSF.json();
            setSuccessMessage(result.message)
            setOpenChangeModal(false);
            setOpenModalSuccess(true);
            setOpenModalError(false);
        } else {
            setErrorMessage("Hubo un error, intente de nuevo mas tarde");
            setOpenModalError(true);
            setOpenModalSuccess(false);
        }
    }

    const changeStateOfferReOpen = async() => {
        setOpenReopenModal(false);
        const responseCSRO = await trackPromise(changeStateLaboralOffer(
            {id: idOfferSelected, state: stateToChange, causal: "", modifyUser: userContext.userId}
        ));
        if(responseCSRO.status === 200){
            const result = await responseCSRO.json();
            setSuccessMessage(result.message)
            setOpenChangeModal(false);
            setOpenModalSuccess(true);
            setOpenModalError(false);
        } else {
            setErrorMessage("Hubo un error, intente de nuevo mas tarde");
            setOpenModalError(true);
            setOpenModalSuccess(false);
        }
    }

    const closeModalChange = ()=>{
        setOpenChangeModal(false);
    }

    const closeModalReopen = ()=>{
        setOpenReopenModal(false);
    }

    const selectApplicants = (e: any, idOffer: number)=>{
        setUrl(`/empresa/aplicantes-oferta/${idOffer}`);
    }

    const reuseOffer = (e: any, idOffer: number)=>{
        userContext.setAction('Reuse');
        setUrl(`/empresa/dashboard/formulario-oferta/${idOffer}`);
    }

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
        if(stateToChange === "En Selección"){
            setUrl('/empresa/dashboard/ofertas/seleccion');
        } else if(stateToChange === "Finalizado"){
            setUrl('/empresa/dashboard/ofertas/finalizadas');
        } else if(stateToChange === "En definición"){
            setUrl('/empresa/dashboard/ofertas');
        } else if(stateToChange === "Cancelada"){
            setUrl('/empresa/dashboard/ofertas/canceladas');
        }
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
    };

    return(
        <>
            {
                url !== null ?
                <Redirect to={url} />
            :
                <>
                    {
                        offersList.length>0 && userContext.stateOffer &&
                        offersList.map((offer: IGetOffer)=>(
                            <OfferCard
                                key={offer.id}
                                borderColor="#D7D7D7"
                                bgColor="#F9F9F9"
                                marginDef="10px 0"
                                companyName={offer.nameCompany}
                                nameCompanyVisible={offer.nameCompanyVisible}
                                cityId={offer.city}
                                departmentId={offer.department}
                                initialDate={new Date(offer.jobOfferStartDate)}
                                offerName={offer.jobTitle}
                                offerDescription={offer.description}
                                vacancies={offer.numberVacancy}
                                positionId={offer.typePosition}
                                contractId={offer.typeContract}
                                salaryVisible={offer.salaryVisible}
                                salary={offer.rangeSalary}
                                description_salary={offer.descriptionVariableSalary}
                                finalDate={new Date(offer.jobOfferEndDate)}
                            >
                                <>
                                    <Grid container item xs={12}>
                                        <hr className="divider-accordion"/>
                                    </Grid>
                                    {
                                        userContext.stateOffer === 'En definición' &&
                                        <Grid className="double-actions">
                                            <div className="firts-actions">
                                                <Button className="delete-offer-btn" onClick={(e)=>handleDeleteModal(e, offer.id)}>
                                                    <Typography variant="h5" className="regular-serif">
                                                        Eliminar
                                                    </Typography>
                                                </Button>
                                                <Button className="modify-offer-btn" onClick={(e)=>modifyOffer(e, offer.id)}>
                                                    <Typography variant="h5" className="regular-serif">
                                                        Modificar
                                                    </Typography>
                                                </Button>
                                            </div>
                                            <div className="second-actions">
                                                <Button className="see-offer-btn" onClick={(e)=>handleSeeOffer(e, offer.id)}>
                                                    <Typography variant="h5" className="regular-serif">
                                                        ver oferta
                                                    </Typography>
                                                </Button>
                                            </div>
                                        </Grid>
                                    }
                                    {
                                        userContext.stateOffer === 'Convocatoria abierta' &&
                                        <Grid className="simple-right-actions">
                                            <Grid container item xs={12} className="vacancy-box">
                                                <Typography variant="h3" className="regular-serif">
                                                    Aspirantes que han aplicado a la oferta: <span>{offer.numberOfPostulates}</span>
                                                </Typography>
                                                <Typography variant="h4" className="bold-serif">
                                                    <span>{offer.numberVacancy}</span> Vacantes
                                                </Typography>
                                            </Grid>
                                            <Button className="applicants-btn" onClick={(e)=>selectApplicants(e, offer.id)}>
                                                <Typography variant="h5" className="regular-serif">
                                                    Seleccionar aspirantes
                                                </Typography>
                                            </Button>
                                            <Button className="see-offer-btn" onClick={(e)=>handleSeeOffer(e, offer.id)}>
                                                <Typography variant="h5" className="regular-serif">
                                                    ver oferta
                                                </Typography>
                                            </Button>
                                            {containsAny(permissionCompanyChangeStateOffer,userContext.userPermissions) &&
                                                <Button disabled={!containsAny(permissionCompanyChangeStateOffer,userContext.userPermissions)} className="change-offer-btn" onClick={(e)=>handleChangeModal(e, 'En Selección', offer.id)}>
                                                    <Typography variant="h5" className="regular-serif">
                                                        Cambiar estado a: En Selección
                                                    </Typography>
                                                </Button>
                                            }
                                        </Grid>
                                    }
                                    {
                                        userContext.stateOffer === 'En Selección' &&
                                        <Grid className="simple-right-actions">
                                           <Grid container item xs={12} className="vacancy-box">
                                                <Typography variant="h3" className="regular-serif">
                                                    Aspirantes que han aplicado a la oferta: <span>{offer.numberOfPostulates}</span>
                                                </Typography>
                                                <Typography variant="h4" className="bold-serif">
                                                    <span>{offer.numberVacancy}</span> Vacantes
                                                </Typography>
                                            </Grid>
                                            <Button className="applicants-btn" onClick={(e)=>selectApplicants(e, offer.id)}>
                                                <Typography variant="h5" className="regular-serif">
                                                    Seleccionar aspirantes
                                                </Typography>
                                            </Button>
                                            {containsAny(permissionCompanyChangeStateOffer,userContext.userPermissions) &&
                                                <Button disabled={!containsAny(permissionCompanyChangeStateOffer,userContext.userPermissions)} className="change-offer-btn" onClick={(e)=>handleChangeFinalizada(e,'Finalizado', offer.id)}>
                                                    <Typography variant="h5" className="regular-serif">
                                                        Cambiar estado a: Finalizada
                                                    </Typography>
                                                </Button>
                                            }
                                        </Grid>
                                    }
                                    {
                                        userContext.stateOffer === 'Finalizado' &&
                                        <Grid className="finalized-container">
                                            <Grid container item xs={12} md={6} lg={6} className="vacancy-box2">
                                                <Typography variant="h3" className="regular-serif">
                                                    Aspirantes que aplicaron a la oferta: <span>{offer.numberOfPostulates}</span>
                                                </Typography>
                                            </Grid>
                                            <Grid container item xs={12} md={6} lg={6}  className="simple-right-actions2">
                                                <Button className="see-offer-btn" onClick={(e)=>handleSeeOffer(e, offer.id)}>
                                                    <Typography variant="h5" className="regular-serif">
                                                        ver oferta
                                                    </Typography>
                                                </Button>
                                                <Button className="reuse-offer-btn" onClick={(e)=>reuseOffer(e, offer.id)}>
                                                    <Typography variant="h5" className="regular-serif">
                                                        Reutilizar
                                                    </Typography>
                                                </Button>
                                                <Button className="change-offer-btn" onClick={(e)=>handleReopen(e, 'En definición', offer.id)}>
                                                    <Typography variant="h5" className="regular-serif">
                                                        Reabrir oferta
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    }
                                    {
                                        userContext.stateOffer === 'Cancelada' &&
                                        <>
                                            {
                                                offer.observation && offer.observation.length>0 &&
                                                <Grid item xs={12} style={{marginTop: '8px'}}>
                                                    <Alert severity="error">
                                                        <span className="alert-bol-title">Causal de rechazo: </span>
                                                        <span className="alert-simple-text">{offer.observation}</span></Alert>
                                                </Grid>
                                            }
                                            <Grid className="simple-right-actions">
                                                <Button className="see-offer-btn" onClick={(e)=>handleSeeOffer(e, offer.id)}>
                                                    <Typography variant="h5" className="regular-serif">
                                                        ver oferta
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </>
                                    }
                                </>
                            </OfferCard>
                        ))
                    }
                    <ModalOffersAction
                        open={openDeleteModal}
                        handleClose={closeModalDelete}
                        handleContinue={deleteOffer}
                        iconModal={<WarningIcon className="big-warning-icon" />}
                        title="¿Está seguro de eliminar la oferta?"
                        headerdescription="Esta intentando eliminar una oferta laboral,"
                        description=" una vez eliminada la única forma de restaurarla será creando una nueva oferta."
                        backBtnText="Regresar"
                        colorBtnBack= ""
                        continueBtnText="Si, Eliminar"
                        colorBtnContinue="#f44336"
                        valid={true}
                    />
                    <ModalOffersAction
                        open={openChangeModal}
                        handleClose={closeModalChange}
                        handleContinue={changeStateOffer}
                        iconModal={<WarningIcon className="big-warning-icon" />}
                        title={`¿Está seguro de cambiar la oferta al estado ${stateToChange}?`}
                        headerdescription="Esta intentando cambiar de estado una oferta laboral,"
                        description="una vez cambiada ..."
                        backBtnText="Regresar"
                        colorBtnBack= ""
                        continueBtnText="Si, Cambiar"
                        colorBtnContinue="#f44336"
                        valid={true}
                    />
                    <ModalOffersAction
                        open={openReopenModal}
                        handleClose={closeModalReopen}
                        handleContinue={changeStateOfferReOpen}
                        iconModal={<WarningIcon className="big-warning-icon" />}
                        title="¿Está seguro de Reabrir esta oferta?"
                        headerdescription="Esta intentando Reabrir esta oferta laboral, "
                        description="cuando se reabre una oferta, se cambia a estado EN DEFINICIÓN y solo podra ampliar o modificar las fechas de la oferta."
                        backBtnText="Regresar"
                        colorBtnBack= ""
                        continueBtnText="Si, Reabrir"
                        colorBtnContinue="#f44336"
                        valid={true}
                    />
                    <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
                    <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
                    <LoadingLockPage load={promiseInProgress}/>
                </>
            }
        </>
    )
}

export default OffersList