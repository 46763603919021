import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import './EditButton.scss';


const EditButton:React.FC<{colorInside:string}> = ({colorInside})=>{
    return(
        <>
            <Typography variant="h6" className="icon-text" style={{color: colorInside}}>
                Editar
            </Typography>
            <EditIcon style={{color: colorInside}} fontSize="small"/>
        </>
    )
}

export default EditButton;