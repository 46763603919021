import React from 'react';
import Typography from '@material-ui/core/Typography'
import Grid from "@material-ui/core/Grid";
import './NotificationBox.scss';

interface INotificationProps {
    bgColor: string
    title?: string;
    titleColor?: string;
    description?: string;
    children?:any;
    descColor?: string;
    icon?: any;
    positionBox?: any;
    extra?: any;
    extra2?: any;
}

const NotificationBox: React.FC<INotificationProps> = ({bgColor, title, titleColor, description, children, descColor, icon, positionBox, extra, extra2})=>{
    return(
        <>
            <Grid container item xs={12} style={{width:'100%'}}>
                <Grid container item xs={12} justify="center">
                    <Grid container item xs={12} alignItems="flex-start">
                        <div className="notification-box" style={{backgroundColor:bgColor, position: positionBox ? positionBox : 'relative'}}>
                            {icon}
                            <Typography variant="h4" align="center" className="bold-serif" style={{color: titleColor}}>{title}</Typography>
                            {description && description.length>0 &&
                                <Typography variant="h6" className="regular-serif" style={{color: descColor, width: '100%'}}>{description}</Typography>
                            }
                            {extra}
                            {extra2}
                            {children && children.length>0 &&
                                <Typography variant="h6" className="regular-serif" style={{color: descColor, width: '100%'}}>{children}</Typography>
                            }
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default NotificationBox