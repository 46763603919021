import { IUserContext } from "../interfaces/IUserContext";
import { logout } from "../services/AuthService";

export const sortByKey =(array:any, key:any)=>{
    return array.sort(function(a:any, b:any) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}


export const sortByKeyDesc =(array:any, key:any)=>{
    return array.sort(function(a:any, b:any) {
      var x = a[key]; var y = b[key];
      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
}

export const convertCurrency = (value:number)=>{
  return new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
  }).format(value)
}

export const containsAny = (source: Array<any>, target: Array<any>) =>{
  const result = source.filter((item:any)=> target.indexOf(item) > -1);
  return (result.length > 0);
}

export const logoutAndReset = (userContext: IUserContext) => {
  logout();
  userContext.setRoleSelected(0);
  userContext.setLogged(false);
  userContext.setRolesUser([]);
  userContext.setRoleSelected(0);
  userContext.setUserId("");
  userContext.setUsername("");
  userContext.setName("");
  userContext.setTemporaryKey(false);
  userContext.setRolePermissions(undefined);
  userContext.setUserPermissions([]);
}

export const resetStatusCheckArray = (objectToReset: any) => {
  const newObject = Object.keys(objectToReset).map(key=>{
      return objectToReset[key] = false
  })
  return newObject;
}

export const checkValuesSelected = (objectToEvalue: any) => {
  const valuesSelected = Object.keys(objectToEvalue).filter(key=>{
    return objectToEvalue[key] === true && key
  })
  return valuesSelected;
}

export const convertToBase64 = (file: any) => {
  return new Promise((resolve, reject)=>{
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (()=>{
          resolve(fileReader.result)
      });
      fileReader.onerror = ((error)=>{
          reject(error)
      })
  })
}