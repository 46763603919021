import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Typography from '@material-ui/core/Typography';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import UserContext from '../../../utils/UserContext';
import { containsAny } from '../../../helpers/sharedFunctions';
import { getCITY, getDEPARTAMENT, getOccupationType, getTypeOfContract } from '../../../services/ParameterService';

import './OfferCard.scss';


interface OfferCardProps{
    borderColor?: string;
    bgColor?: string;
    marginDef?: string;
    companyName?: string;
    nameCompanyVisible:boolean;
    cityId?: number;
    departmentId?: number;
    initialDate?: Date | null;
    offerName?: string;
    offerDescription?: string;
    vacancies?: number;
    positionId?: number;
    contractId?: number;
    salaryVisible?: boolean;
    salary?: string;
    description_salary?: string;
    finalDate?: Date | null;
    children?:any;
}

const OfferCard: React.FC<OfferCardProps> = ({borderColor, bgColor, marginDef, nameCompanyVisible, companyName, cityId, departmentId, initialDate, offerName, offerDescription, vacancies, positionId, contractId, salaryVisible, salary, description_salary, finalDate, children})=>{
    const userContext = React.useContext(UserContext);
    const numberVacancyVal = vacancies ? vacancies.toString() : null;
    const [departmentSelected, setDepartmentSelected]= useState<string>('');
    const [citySelected, setCitySelected]= useState<string>('');
    const [typePositionSelected, setTypePositionSelected]= useState<string>('');
    const [contractSelected, setContractSelected]= useState<string>('');

    useEffect(()=>{
        const getData = async()=>{
            let responseDepartment:Array<IGeneralParameter> = await getDEPARTAMENT();
            const depSelected = responseDepartment.find(item=>item.parameter_code === departmentId);
            setDepartmentSelected(depSelected?.name ? depSelected?.name : '');
            const dataCity:Array<IGeneralParameter> = await getCITY();
            if(dataCity){
                const citySelected = dataCity.find(item=>item.parameter_code === cityId);
                setCitySelected(citySelected?.name ? citySelected?.name : '');

            }
            let responsePostion: Array<IGeneralParameter> = await getOccupationType();
            const positionSelected = responsePostion.find(item=>item.parameter_code === positionId);
            setTypePositionSelected(positionSelected?.name ? positionSelected.name : '');
            let responseContract: Array<IGeneralParameter> = await getTypeOfContract();
            const contractSelected = responseContract.find(item=>item.parameter_code === contractId);
            setContractSelected(contractSelected?.name ? contractSelected.name : '')
        }
        getData();
    },[cityId, contractId, departmentId, positionId])

    return(
        <>
            <Grid container item xs={12} direction="row" style={{backgroundColor: bgColor ? bgColor : 'white', margin: marginDef? marginDef : 0}}>
                <div className="offer-card-container" style={{border: `1px solid ${borderColor ? borderColor : 'none'}`}}>
                    <Grid container item xs={12} className="offer-card-header">
                        {containsAny([1,2,3],[userContext.roleSelected])  && nameCompanyVisible && companyName &&
                            <div className="name-header">
                                <Typography variant="h5" className="regular-serif">
                                    {companyName}
                                </Typography>
                            </div>
                        }
                        {
                            departmentSelected &&
                            <div className="name-header">
                                <Typography variant="h5" className="regular-serif">
                                    <LocationOnIcon />
                                    <>
                                        {citySelected && <span>{citySelected} - </span>} {departmentSelected && <span>{departmentSelected} - </span>} COL
                                    </>
                                </Typography>
                            </div>
                        }
                        {
                            initialDate &&
                            <div className="date-header">
                                <Typography variant="h5" className="bold-serif">
                                    <CalendarTodayIcon />
                                    Fecha de publicación: {moment(initialDate).locale('es').utcOffset(-5, true).format('DD/MM/YYYY')}
                                </Typography>
                            </div>
                        }
                    </Grid>
                    <Grid container item xs={12}>
                        <hr className="divider-accordion"/>
                    </Grid>
                    <Grid container item xs={12} className="card-body-content">
                        {
                            offerName &&
                            <div className="summary-title">
                                <Typography variant="h1" className="bold-serif">
                                    {offerName}
                                </Typography>
                            </div>
                        }
                        {
                            offerDescription &&
                            <div className="summary-description">
                                <Typography variant="h5" className="regular-serif">
                                    {offerDescription}
                                </Typography>
                            </div>
                        }
                        <Grid container className="summary-description2">
                            <Typography variant="h5" className="regular-serif">
                                {
                                    numberVacancyVal &&
                                    <> Cantidad de vacantes: <span className="summary-description-bold">{numberVacancyVal}</span></>
                                }
                                {
                                    typePositionSelected &&
                                    <> | Cargo: <span className="summary-description-bold">{typePositionSelected}</span></>
                                }
                                {
                                    contractSelected &&
                                    <> | contrato: <span className="summary-description-bold">{contractSelected}</span></>
                                }
                            </Typography>
                        </Grid>
                        {
                            salaryVisible && salary &&
                            <Grid container className="summary-description2">
                                <Typography variant="h5" className="regular-serif">
                                    $ Rango salarial: {salary} {description_salary}
                                </Typography>
                            </Grid>
                        }
                        {
                            finalDate &&
                            <Grid container className="container-date">
                                <div className="summary-description3">
                                    <CalendarTodayIcon />
                                    <Typography variant="h5" className="bold-serif">
                                        Fecha de cierre de la oferta: {moment(finalDate).locale('es').utcOffset(-5, true).format('DD/MM/YYYY')}
                                    </Typography>
                                </div>
                            </Grid>
                        }
                    </Grid>
                    {
                        children &&
                        <div className="actions">{children}</div>
                    }
                </div>
            </Grid>
        </>
    )
}

export default OfferCard