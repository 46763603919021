export class ICompanyDinamicReport{
    name?: string = "";
    nit?: number | null = null;
    laboralSector?: number = 0;
    typeOfCompany?: number = 0;
    country?: number = 0;
    department?: number = 0;
    city?: number = 0;
    state?: Array<string> = [];
    inJobBankInit?: string = "";
    inJobBankEnd?: string = "";
}

export class ICompanyFilterObjDinamic{
    preview?: boolean = true; // true return number, false send email
    company?:{
        fields?: Array<string>;
        data?: ICompanyDinamicReport;
    } = {
        fields: [],
        data: new ICompanyDinamicReport()
    };
    contact?:{
        fields?: Array<string>;
    } = {
        fields: []
    };
    headquarter?:{
        fields?: Array<string>;
    } = {
        fields: []
    };
    limit?: number = 0;
    offset?: number = 0;
}
