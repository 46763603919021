import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';

const styles = (theme: Theme) =>
createStyles({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#004B73',
  },
})

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="headquarter-register-dialog-header" {...other}>
      <Typography className="headquarter-register-dialog-title">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface ActivateUserModalProps{
    open: any;
    handleClose: any;
    handleContinue: any;
    backBtnText?: string;
    colorBtnBack?: string;
    continueBtnText?: string;
    continueBtnColor?: string;
    userSelected?: any;
    action?: string;
}

const ActivateUserModal: React.FC<ActivateUserModalProps> = ({open, handleClose, handleContinue, backBtnText, colorBtnBack, continueBtnText, continueBtnColor, userSelected, action})=>{


    return(
        <>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="headquarter-register-dialog-container"
          fullWidth
          maxWidth = "lg"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          </DialogTitle>
          <DialogContent className="action-dialog-content">
            <Grid container item xs={12} className="action-container">
              <Grid item xs={12} lg={6} xl={6} className="action-text-container">
                <Grid container item xs={2} sm={1} justify="center">
                  <EmojiObjectsIcon className="action-icon"/>
                </Grid>
                <Grid item xs={10} sm={11} className="action-description-container">
                  <Typography variant="h2" className="bold-serif-primaryColor">
                    Accion a Ejecutar:
                  </Typography>
                  <Typography variant="h3" className="bold-serif-primaryColor">
                    <span className="action-state-text">"{action}"</span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} xl={6} className="actions-buttons">
                <Button variant="contained" className="cancel-action-button" onClick={handleClose}>
                  <Typography variant="h5" className="regular-serif">
                    {backBtnText}
                  </Typography>
                </Button>
                <Button variant="contained" className="confirm-action-button" onClick={handleContinue}>
                  <Typography variant="h5" className="regular-serif">
                    {continueBtnText}
                    </Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    )
}

export default ActivateUserModal