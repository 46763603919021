import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { FiltersRoles } from '../../../interfaces/FiltersRoles';
import { IRole } from '../../../interfaces/IRole';

const styles = {
    input1: {
      height: 5
    }
};

interface MoreFiltersRolesProps{
    rolesArray:Array<IRole>;
    formFilters: FiltersRoles;
    changeFilters: any;
}

const MoreFiltersRoles:React.FC<MoreFiltersRolesProps> = ({rolesArray, formFilters, changeFilters}) => {
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const handleClickFilters = ()=>{
        setShowFilters(!showFilters)
    }


    return(
        <Grid container item xs={12} className="more-filters-box">
            <Grid container item xs={12} style={{width: '100%'}}>
                <Button variant="contained" className="more-filters-button" onClick={handleClickFilters}>
                    <Typography variant="h4" className="regular-serif">
                        Agregar otros filtros de búsqueda
                    </Typography>
                    <span className="more-button-icon" >{!showFilters? <AddIcon/> : <RemoveIcon/>}</span>
                </Button>
            </Grid>
            {showFilters &&
                <Grid container item xs={12} className="more-filters-container">
                    <Grid container item xs={12} style={{height: '350px'}} >
                        <Grid container item xs={12} md={4}>
                            <Grid item xs={12} md={10} className="field-box-margin special-box-left">
                                <Typography variant="h5" className="bold-serif-primaryColor">Nombre de usuario</Typography>
                                <TextField
                                    fullWidth
                                    name="username"
                                    margin="none"
                                    label={formFilters.username === "" ? "Ingrese Nombre de usuario": ""}
                                    value={formFilters.username}
                                    onChange={changeFilters}
                                    InputLabelProps={{shrink: false}}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={4}>
                            <Grid item xs={12} className="field-box-margin special-box-left">
                                <FormControl component="fieldset">
                                    <Typography variant="h5" className="bold-serif-primaryColor">Estado</Typography>
                                    <RadioGroup row className="radio-container" name="stateUser" value={formFilters.stateUser} onChange={changeFilters}>
                                        <FormControlLabel value="Activo" className="radio-btn" control={<Radio className="color-btn"/>} label="Activo" />
                                        <FormControlLabel value="Inactivo" className="radio-btn" control={<Radio  className="color-btn"/>} label="Inactivo" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={4}>
                            <Grid container item xs={12} className="field-box-margin special-box-left">
                                <Typography variant="h5" className="bold-serif-primaryColor">Tipo de rol</Typography>
                                <TextField
                                    fullWidth
                                    id="outlined-select-roleUser"
                                    select
                                    name="roleUser"
                                    label={formFilters.roleUser === 0 ? "Seleccione": ""}
                                    value={formFilters.roleUser === 0 ? "" : formFilters.roleUser}
                                    onChange={changeFilters}
                                    InputLabelProps={{shrink: false}}
                                    margin="none"
                                    variant="outlined"
                                >
                                    {
                                    rolesArray && rolesArray.length>0 ?
                                    rolesArray.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                        </MenuItem>
                                    ))
                                    :
                                        <MenuItem value={formFilters.roleUser}></MenuItem>
                                    }
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

export default MoreFiltersRoles