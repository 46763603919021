import React from 'react';
import {Link}  from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


interface RolesOptionsProps{
    selected: string;
    handleClick?: any;
}

const RolesOptions: React.FC<RolesOptionsProps> = ({selected, handleClick}) => {

    const buttons = [
        {id: 1, name: 'Usuarios administradores', state: 'Usuarios administradores', url:'/admin/dashboard/usuarios'},
        {id: 2, name: 'Gestionar roles', state: 'Gestionar roles', url: '/admin/dashboard/gestion-roles'},
        {id: 3, name: 'Reporte de transacciones', state: 'Reportes', url: '/admin/dashboard/reportes-transacciones'},
    ]

    return(
        <Grid item xs={12} className="state-bar-container">
        <Grid container item xs={12} className="container-buttons">
          {buttons.map(item=>(
            <Link key={item.id}  to={item.url} className="link-state-bar">
              <div className={selected === item.state ? 'button-definition-active' : 'button-definition'}>
                <Typography variant="h5" className="regular-serif">
                  {item.name}
                </Typography>
              </div>
            </Link>
          ))}
        </Grid>
      </Grid>
    )
}

export default RolesOptions