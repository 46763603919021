import React from 'react';
import Grid from "@material-ui/core/Grid"
import './SectionPreview.scss'
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography'


interface SectionPreviewProps{
    cols: number;
    firstTitle?:string;
    firtsValue?:string;
    secondTitle?:string;
    secondValue?: string;
    thirdTitle?: string;
    thirdValue?: string;
}

const SectionPreview:React.FC<SectionPreviewProps> = ({cols, firstTitle,firtsValue,secondTitle,secondValue,thirdTitle,thirdValue})=>{
    return(
        <Grid container item xs={12} direction="row">
            <Grid container item xs={12} md={6} lg={4} direction="row" className="subsection-preview">
                <Grid container item xs={12}>
                    <Typography variant="h5" className="section-preview-title">
                        {firstTitle}
                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    <Typography variant="h5" className="section-preview-value">
                        {firtsValue}
                    </Typography>
                </Grid>
            </Grid>
            <Hidden only={cols>=2 ? [] : ['xs','sm']}>
                <Grid container item xs={12} md={6} lg={4} direction="row" className="subsection-preview">
                    <Grid item xs={12}>
                        <Typography variant="h5" className="section-preview-title">{cols>=2 ? secondTitle : ''}</Typography>
                    </Grid>
                    <Grid item xs={12} className="section-preview-value">
                        <Typography variant="h5" className="bold-serif">{cols>=2 ? secondValue : ''}</Typography>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden only={cols>=3 ? [] : ['xs','sm', 'md']}>
                <Grid container item xs={12} md={6} lg={4} direction="row" className="subsection-preview">
                    <Grid item xs={12}>
                        <Typography variant="h5" className="section-preview-title">{cols>=2 ? thirdTitle : ''}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" className="section-preview-value">{cols>=2 ? thirdValue : ''}</Typography>
                    </Grid>
                </Grid>
            </Hidden>
        </Grid>
    )
}

export default SectionPreview