import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import './SearchTable.scss';

interface SearchTableProps{
    textPlaceHolder: string;
    title: string;
    searched?: string;
    requestSearch: any;
    formErrorSearch: any;
    searchActive?: boolean;
    cancelSearch: any;
}

const SearchTable:React.FC<SearchTableProps> = ({textPlaceHolder, title, searched, requestSearch, formErrorSearch, searchActive, cancelSearch})=>{
    return(
        <div className="search-container-inner">
            <Typography variant="h5" className="search-title">{title}</Typography>
            <div className="search-box">
                <TextField
                    fullWidth
                    name="searched"
                    margin="none"
                    placeholder={textPlaceHolder}
                    autoComplete ="off"
                    value={searched || ""}
                    onChange={(e) => requestSearch(e.target.name, e.target.value)}
                    error={!!formErrorSearch.searched}
                    helperText={formErrorSearch.searched}
                    variant="outlined"

                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                                <SearchIcon fontSize="small" color="disabled"/>
                        </InputAdornment>
                        ),
                        endAdornment: searchActive ? (
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={cancelSearch}>
                                    <CloseIcon fontSize="small"/>
                                </IconButton>
                            </InputAdornment>
                        ) : null
                    }}
                />
            </div>
        </div>
    )
}

export default SearchTable