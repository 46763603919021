import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import {Link}  from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import somosEmprendimiento from '../../../assets/images/Egresados3.png';
import UserContext from '../../../utils/UserContext';

import './LoginBox.scss';


const LoginBox: React.FC = ()=>{
    const userContext = React.useContext(UserContext);

    return(
        <>
            <Grid container className="login-container-pre">
                <Grid className="filter-view">
                    <Grid container item direction="column" className="text-overvideo" justify="center" alignContent="center" >
                        <img src={somosEmprendimiento} alt="unal" className="somos-img"/>
                        <Typography variant="h1" className="title-container">
                            ¡Portal de egresados de la Universidad Nacional de Colombia!
                        </Typography>
                        <Grid container item justify="center" className="btn-container">
                            <Button variant="contained" className="button-login" size="small" component ={ Link } to="/login">
                                <Typography variant="h5" className="regular-serif">
                                    {userContext.logged ? "Ir al dashboard" : "Iniciar sesión"}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <video autoPlay muted loop id="videoBgIndex">
                        <source src="../../../../Assets/media/unal1700x400.mp4" type="video/mp4"/>
                        <source src="../../../../Assets/media/unal1700x400.webmhd.webm" type="video/webm"/>
                </video>
            </Grid>
        </>
    )
}

export default LoginBox