import React, { useState, useEffect } from 'react';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import moment from 'moment';
import { TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IUserByCompany } from '../../interfaces/IUserByCompany';
import Validations from '../../helpers/Validations';
import { changeStateUserByCompany, UpdateUserByCompany } from '../../services/CompanyService';
import LoadingLockPage from '../ShareComponents/LoadingLockPage';
import SuccessModal from '../ShareComponents/SuccessModal';
import ErrorModal from '../ShareComponents/ErrorModal';
import UserContext from '../../utils/UserContext';
import { containsAny } from '../../helpers/sharedFunctions';
import { permissionCompanyActiveInactiveCompanyProfiles, permissionCompanyEditCompanyProfiles } from '../../utils/PermissionsVariables';

import './UserByCompanyForm.scss';

const formValidations = {
    name: {
      error: "",
      validate: "textNumber",
      minLength: 3,
      required: true
    },
    email: {
      error: "",
      validate: "email",
      required: true
    },
    phone: {
      error: "",
      validate: "textNumber",
      minLength: 7,
      maxLength: 30,
      required: true
    }
}

interface UserByCompanyFormProps{
    profile:IUserByCompany;
}

const UserByCompanyForm: React.FC<UserByCompanyFormProps> = ({profile})=>{

    const userContext = React.useContext(UserContext);
    const { promiseInProgress } = usePromiseTracker();
    const [formValues, setFormValues] = useState(new IUserByCompany());
    const [formErrors, setFormErrors] = useState(new IUserByCompany());
    const [errorMessage, setErrorMessage]= useState('');
    const [openModalError, setOpenModalError] = React.useState(false);
    const [successMessage, setSuccessMessage]= useState('');
    const [openModalSuccess, setOpenModalSuccess] = React.useState(false);

    const isValid =
        formValues.name.length > 0 &&
        !formErrors.name &&
        formValues.phone.length > 0 &&
        !formErrors.phone &&
        formValues.email.length > 0 &&
        !formErrors.email

    const handleChangeForm = (e: any) => {
        const { name, value } = e.target;
        setFormValues(prev => ({
          ...prev,
          [name]: value
        }));
        const error = Validations(name, value, formValidations) || "";
        setFormErrors(prev=>({
            ...prev,
            [name]: error
        }));
    }

    const updateOneUserCompany = () => {
        const updateUser = async()=>{
            const responseUUC:Response = await trackPromise(UpdateUserByCompany(
                {...formValues, modifyUser: userContext.userId}
            ));
            if(responseUUC.status === 200){
                const result = await responseUUC.json();
                setSuccessMessage(result.message)
                setOpenModalSuccess(true);
                setOpenModalError(false);
            }else if (responseUUC.status === 400){
                const result = await responseUUC.json();
                setErrorMessage(result.message);
                setOpenModalError(true);
                setOpenModalSuccess(false);
            } else {
                setErrorMessage("Hubo un error, intente de nuevo mas tarde");
                setOpenModalError(true);
                setOpenModalSuccess(false);
            }
        }
        updateUser();
    };

    const disableUserCompany = ()=>{
        const userToDisable = {
            id: formValues.id,
            status: "Inactivo"
        }
        const changeState = async()=>{
            const responseSUC: Response = await trackPromise(changeStateUserByCompany(
                {...userToDisable, modifyUser: userContext.userId}
            ));
            if(responseSUC.status === 200){
                const result = await responseSUC.json();
                setSuccessMessage(result.message)
                setOpenModalSuccess(true);
                setOpenModalError(false);
            }else if (responseSUC.status === 400){
                const result = await responseSUC.json();
                setErrorMessage(result.message);
                setOpenModalError(true);
                setOpenModalSuccess(false);
            } else {
                setErrorMessage("Hubo un error, intente de nuevo mas tarde");
                setOpenModalError(true);
                setOpenModalSuccess(false);
            }
        }
        changeState()
    };

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
        window.location.reload();
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
        window.location.reload();
    };

    useEffect(()=>{
        const target: IUserByCompany = new IUserByCompany();
        const result = Object.assign(target, profile);
        if(result){
            setFormValues(prev => ({
                ...prev,
                ...result
            }));
        }
        setFormValues(prev => ({
            ...prev,
            nit: parseInt(userContext.userId? userContext.userId : '', 10)
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <Grid container item xs={12} direction="row" className="users-company-form">
            <Grid item xs={12}>
                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                    <Typography variant="h5" className="bold-serif-primaryColor">Fecha de creación del perfil</Typography>
                    <Typography>
                        {moment(formValues.createdAt).utcOffset(-5, true).format('DD/MM/YYYY')}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                <Typography variant="h5" className="bold-serif-primaryColor">Nombre del perfil*</Typography>
                <TextField
                    fullWidth
                    name="name"
                    margin="none"
                    value={formValues.name}
                    onChange={handleChangeForm}
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                    InputLabelProps={{shrink: false}}
                    variant="outlined"
                />
            </Grid>

            <Grid item xs={12} sm={6} className="field-box-margin special-box-left">
                <Typography variant="h5" className="bold-serfi field-box-title">Correo electrónico*</Typography>
                <TextField
                    fullWidth
                    name="email"
                    margin="none"
                    value={formValues.email}
                    onChange={handleChangeForm}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    InputLabelProps={{shrink: false}}
                    variant="outlined"
                />
            </Grid>

            <Grid item xs={12}>
                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                    <Typography variant="h5" className="bold-serif-primaryColor">Número telefónico*</Typography>
                    <TextField
                        fullWidth
                        name="phone"
                        margin="none"
                        value={formValues.phone}
                        onChange={handleChangeForm}
                        error={!!formErrors.phone}
                        helperText={formErrors.phone}
                        InputLabelProps={{shrink: false}}
                        variant="outlined"
                    />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid item xs={12} sm={6} className="field-box-margin special-box-right">
                    <Typography variant="h5" className="bold-serif-primaryColor">Última fecha de modificación</Typography>
                    <Typography>
                        {moment(formValues.updatedAt).utcOffset(-5, true).format('DD/MM/YYYY')}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={6} className="disable-container">
                {containsAny(permissionCompanyActiveInactiveCompanyProfiles, userContext.userPermissions) &&
                    <Button disabled={!containsAny(permissionCompanyActiveInactiveCompanyProfiles, userContext.userPermissions)} className="disable-btn" variant="contained" endIcon={<PersonAddDisabledIcon />} size="small" onClick={disableUserCompany}>
                        <Typography variant="h6" className="regular-serif">
                            DESACTIVAR
                        </Typography>
                    </Button>
                }
            </Grid>
            <Grid item xs={12} sm={6} className="update-container">
                {containsAny(permissionCompanyEditCompanyProfiles, userContext.userPermissions) &&
                    <Button disabled={!isValid} endIcon={<ChevronRightIcon />} className="update-btn" variant="contained"  size="small" onClick={updateOneUserCompany}>
                        <Typography variant="h6" className="regular-serif">
                            ACTUALIZAR
                        </Typography>
                    </Button>
                }
            </Grid>
            <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
            <LoadingLockPage load={promiseInProgress}/>
        </Grid>
    )
}

export default UserByCompanyForm