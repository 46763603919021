import React from 'react';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridColDef, GridOverlay, GridColParams, GridCellParams, GridBaseComponentProps } from '@material-ui/data-grid';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import { IPermission } from '../../../interfaces/IPermission';
import Checkbox from '@material-ui/core/Checkbox';


interface PermissionsTableProps{
    rows: Array<IPermission>;
    handleChangeCheck: any;
}

const PermissionsTable: React.FC<PermissionsTableProps> = ({ rows, handleChangeCheck }) => {
    const pageSize = 10;

    const columnsAnnouncement: GridColDef[] = [
        { field: 'name', headerName: 'Permisos', width: 1000, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Permisos'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell">{params.value}</span>
        )} },
        { field: 'checked', headerName: 'Permitir', width: 100, sortable: false ,renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Permitir'}
                </span>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
            <>
                <Checkbox
                    checked={Boolean(params.value)}
                    onChange={handleChangeCheck}
                    name={params.row.id+""}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </>
        )} },
    ];


    return(
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid autoHeight className="data-grid-list" disableColumnMenu rows={rows} columns={columnsAnnouncement} pageSize={pageSize} pagination density="compact" disableSelectionOnClick
                components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                }}
                />
            </Grid>
        </Grid>
    )
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large"/>
            <div className="not-results-text">Sin registros</div>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps)=><KeyboardArrowUpIcon fontSize="small"/>
const customSortDesc = (props: GridBaseComponentProps)=><KeyboardArrowDownIcon fontSize="small"/>

export default PermissionsTable