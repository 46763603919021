import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { Grid } from '@material-ui/core';
import UserContext from '../../../../utils/UserContext';
import { isLogin } from '../../../../services/AuthService';
import NewsSurveysStateBar from '../../../../components/NewsAndEvents/NewsSurveysStateBar';
import NewsEventsManagement from '../NewsEventsManagement';
import SurveysManagement from '../SurveysManagement';
import TrainingEventsManagement from '../TrainingEventsManagement';

const NewsSurveysManagement: React.FC = () => {
  const userContext = React.useContext(UserContext);
  const [selectedSection, setSelectedSection] = useState<string>('');
  const isLogg = isLogin();

  useEffect(()=>{
    window.scrollTo(0, 0);
    const urlPath = window.location.href;
    if(urlPath.includes('noticias')){
      setSelectedSection('Noticias');
      userContext.setStateSurveysSection('Noticias');
    } else if(urlPath.includes('encuestas')){
      setSelectedSection('Encuestas');
      userContext.setStateSurveysSection('Encuestas');
    } else {
      setSelectedSection('Capacitaciones');
      userContext.setStateSurveysSection('Capacitaciones');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[window.location.href])


  return (
    <>
      {isLogg ?
        <>
          <Grid item xs={12} className="state-bar-section">
            <NewsSurveysStateBar selected={selectedSection}/>
          </Grid>
          {selectedSection=== "Encuestas" &&
            <Grid container item xs={12}>
              <SurveysManagement />
            </Grid>
          }
          {selectedSection=== "Noticias" &&
            <Grid container item xs={12}>
              <NewsEventsManagement />
            </Grid>
          }
          {selectedSection === "Capacitaciones" &&
            <Grid container item xs={12}>
              <TrainingEventsManagement />
            </Grid>
          }
        </>
      :
        <Redirect to ="/"/>
      }
    </>
  )
}

export default NewsSurveysManagement;