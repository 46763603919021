import moment from 'moment';
import { IFile } from './IFile';

export class IRegisterTrainingEvent {
    id: number;
    name: string;
    typeEvent: number;
    description: string;
    initDate: string | null;
    endDate: string | null;
    file: Array<number> | null;
    constructor(def: any) {
        this.id = def.id || null;
        this.name = def.name || "";
        this.typeEvent = def.typeEvent || null;
        this.description = def.description || "";
        this.initDate = def.initDate ? (moment(def.initDate).format()) : null;
        this.endDate = def.endDate ? (moment(def.endDate).format()) : null;
        this.file = def.file || null
    }
}