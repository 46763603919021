import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import imgHomeNoticias from '../../../assets/images/IMG_HOME_NOTICIAS.png';
import TabPanelNewsEvents from '../../TabPanelNewsEvents';
import './NewsEventsBox.scss';

const NewsEventsBox: React.FC = () => {
  return (
    <Grid container item xs={12} className='news-event-container'>
      <Grid container item xs={12} md={6} className="left-side">
        <Grid container item className="top-side">
          <img src={imgHomeNoticias} alt="registro empresas" className="company-img"/>
          <Grid container item md={6} className="text-container">
              <Typography variant="h1" className="title-container">
                  Noticias y eventos
              </Typography>
              <Typography variant="h5" className="summary-text">Consulte las noticias y los eventos del Programa de Egresados de la Universidad Nacional de Colombia, así como accesos directos a los canales de noticias de la Agencia de Noticias UNAL.</Typography>
            </Grid>
        </Grid>
        <Grid container item className="bottom-side">
          <Typography variant="h2" className="bottom-title">
            Agencia de Noticias UNAL
          </Typography>
          <Grid container item className="column-container">
            <Typography variant="h5" className="un-radio">Radio UNAL</Typography>
            <Typography variant="h5" className="un-periodico">Periódico UNAL</Typography>
            <Typography variant="h5" className="un-tv">Televisión UNAL</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6} className="right-side">
        <TabPanelNewsEvents />
      </Grid>
    </Grid>
  )
}

export default NewsEventsBox
