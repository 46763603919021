import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import RegisterContext from '../../../utils/RegisterContext';
import EditButton from "../EditButton";

interface SubPreviewProps{
    title:string;
    step?:number;
    panel?:string;
    classEdit?: string;
}

const SubPreview:React.FC<SubPreviewProps> = ({title, step, panel, classEdit})=>{
  const registerContext = React.useContext(RegisterContext);
  return(
    <>
      {
      classEdit !== undefined ?
      <Grid container item xs={12} className="subPreview-container">
        <Grid container item xs={8} md={4} alignItems="center">
          <Typography variant="h4" className="preview-subtitle">{title}</Typography>
        </Grid>
        <Grid container item xs={4} md={2} alignItems="center">
          <Button variant="outlined" size="small" onClick={()=>registerContext.changeStep(step, panel)} className={classEdit}>
            <EditButton colorInside="#fff"/>
          </Button>
        </Grid>
        <Grid container item xs={12} md={6} className="divider-container" alignItems="center">
          <hr className="divider"/>
        </Grid>
      </Grid>
      :
      <Grid container item xs={12} className="subPreview-container">
        <Grid container item xs={8} md={4} alignItems="center">
          <Typography variant="h4" className="preview-subtitle">{title}</Typography>
        </Grid>

        <Grid container item xs={12} md={8} className="divider-container" alignItems="center">
          <hr className="divider"/>
        </Grid>
      </Grid>
      }
    </>
  )
}

export default SubPreview