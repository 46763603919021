import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { Redirect } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Grid from "@material-ui/core/Grid";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventIcon from '@material-ui/icons/Event';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ButtonBackListOffers from '../../../components/LaboralOffers/ButtonBackListOffers';
import NewsCard from  '../../../components/NewsAndEvents/NewsCard';
import { IGetNewEvent } from '../../../interfaces/IGetNewEvent';
import imgNoticias from '../../../assets/images/UN Somos Campus 2016-MEDIA-0432-2.jpg';
import imgEventos from '../../../assets/images/UN Somos Campus 2016-MEDIA-0571.jpg';
import { getCATEGORY, getEventEstate } from '../../../services/ParameterService';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import LoadingLockPage from '../../../components/ShareComponents/LoadingLockPage';
import { isLogin } from '../../../services/AuthService';
import ModalOffersAction from '../../../components/LaboralOffers/ModalOffersAction';
import UserContext from '../../../utils/UserContext';
import SuccessModal from '../../../components/ShareComponents/SuccessModal';
import ErrorModal from '../../../components/ShareComponents/ErrorModal';
import { containsAny } from '../../../helpers/sharedFunctions';
import { permissionSubscribeEvent } from '../../../utils/PermissionsVariables';
import {getOutstanding, getEventsNews, getEventsByUser, enrollUserEvent} from '../../../services/NewsEventsService';

import './EventDetailView.scss';



const EventDetailView: React.FC = ()=> {
  let params: any = useParams();
  const isLogg = isLogin();
  const userContext = React.useContext(UserContext);
  const { promiseInProgress } = usePromiseTracker();
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  const [alreadyEnrol, setAlreadyEnrol] = useState(false);
  const [modalSubscribe, setModalSubscribe] = useState(false);
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [urlBack, setUrlBack] = useState<string>('');
  const [backPage, setBackPage] = useState<string>('');
  const [detailInfo, setDetailInfo] = useState<IGetNewEvent>();
  const [relationArray, setRelationArray] = useState<Array<IGetNewEvent>>([]);
  const [type, setType] = useState('');
  const [category, setCategory] = useState<string>("");
  const [url, setUrl] = useState<string | null>(null);
  const [estateArray, setEstateArray] = useState<Array<IGeneralParameter>>([]);
  const [estateValue, setEstateValue] = useState<number>(0);
  const [errorMessage, setErrorMessage]= useState('');
  const [openModalError, setOpenModalError] = React.useState(false);
  const [successMessage, setSuccessMessage]= useState('');
  const [openModalSuccess, setOpenModalSuccess] = React.useState(false);

  useEffect(()=>{
    window.scrollTo(0, 0);
    sessionStorage.removeItem('eventAnonimous');
    sessionStorage.removeItem('surveyAnonimous');
    const urlPath = window.location.href;
      if(urlPath.includes('noticias')){
        setBackPage('noticias');
        if(isLogg){
          setUrlBack('/egresado/noticias');
        } else {
          setUrlBack('/noticias');
        }

        if (userContext.roleSelected !== 1) {
          setUrlBack('/noticias');
        }
      } else if (urlPath.includes('eventos')){
        setBackPage('eventos');
        if(isLogg){
          setUrlBack('/egresado/eventos');
        } else {
          setUrlBack('/eventos')
        }
        if (userContext.roleSelected !== 1) {
          setUrlBack('/eventos');
        }
      }
    const getInitialData = async()=>{
      if(params.id){
        setInitLoad(true);
        setUrl(null);
        const getDetailInfo: Response = await getEventsNews({id: params.id});
        if (getDetailInfo.status === 200){
          let result = await getDetailInfo.json();
          setDetailInfo(result.publishings[0]);
          const categoryId = result.publishings[0].category;
          const type = result.publishings[0].type;
          const responseCategory:Array<IGeneralParameter> = await getCATEGORY();
          if(responseCategory){
            const catSelected = responseCategory.find(item=> item.parameter_code === categoryId);
            setCategory(catSelected?.name ? catSelected?.name : '');
          }
          setType(result.publishings[0].type);
          const getInfoRelated: Response = await getOutstanding({id: params.id, type: type, category: categoryId });
          if (getInfoRelated.status === 200){
            let resultInfoRelated = await getInfoRelated.json();
            setRelationArray(resultInfoRelated.relational);
          }
          if(userContext.userId){
            const resEventsUser = await getEventsByUser({"documentId": userContext.userId});
            if(resEventsUser.status === 200){
              const eventsUser = (await resEventsUser.json()).checkInList;
              const eventApplied = eventsUser.find((item: any)=>item.event === parseInt(params.id));
              if(eventApplied){
                setAlreadyEnrol(true);
              }else {
                setAlreadyEnrol(false);
              }
            }
          }
        }
      }
      const responseEstate = await getEventEstate();
      if(responseEstate.length>0){
        setEstateArray(responseEstate);
      }
      setInitLoad(false);
    }
    getInitialData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[params.id]);

  const handleEnroll = () => {
    if(!isLogg){
      setOpenLoginModal(true);
      sessionStorage.setItem('eventAnonimous', params.id);
    } else {
      if(!alreadyEnrol){
        setModalSubscribe(true);
      }
    }
  }

  const handleCloseModalSuccess = ()=>{
    setOpenModalSuccess(false);
    window.location.reload();
  };

  const handleCloseModalError = ()=>{
    setOpenModalError(false);
    window.location.reload();
  };

  const closeModalLogin = ()=>{
    setOpenLoginModal(false);
    sessionStorage.removeItem('eventAnonimous');
  }

  const closeModalSubscribe = () => {
    setModalSubscribe(false);
  }

  const changeEstateValue = (e: any) => {
    const { value } = e.target;
    setEstateValue(value);
  }

  const confirmSubscribe = async() => {
    setModalSubscribe(false);
    if(!alreadyEnrol && userContext.username !== undefined){
      const userToSubscribe = {
        "documentId": userContext.userId,
        "username": userContext.username,
        "estate": estateValue,
        "event": params.id
      }
      const responseSubscribe = await trackPromise(enrollUserEvent(userToSubscribe));
      validateModals(responseSubscribe)
    }else{
      const userToSubscribe = {
        "documentId": userContext.userId,
        "username": "",
        "estate": estateValue,
        "event": params.id
      }
      const responseSubscribe = await trackPromise(enrollUserEvent(userToSubscribe));
      validateModals(responseSubscribe)
    }
  }

  const validateModals = async(responseService: Response)=>{
    if(responseService.status === 200){
      setSuccessMessage('Su inscipción al evento ha sido exitosa');
      setOpenModalSuccess(true);
      setOpenModalError(false);
    } else if (responseService.status === 400){
      setErrorMessage("Hubo un error, intente de nuevo mas tarde");
      setErrorMessage('espacio para mensaje de error');
      setOpenModalError(true);
      setOpenModalSuccess(false);
    } else {
      setErrorMessage("Hubo un error, intente de nuevo mas tarde");
      setOpenModalError(true);
      setOpenModalSuccess(false);
    }
  }

  const handleSeeEvent = (e: any, idEvent: number)=>{
    setUrl(`/${backPage}/${idEvent}`);
  }


  return (
    <>
    {
      url !== null ?
      <Redirect to={url} />
      :
      <div>
        <Grid container item xs={12} className="back-offers-list-section">
          <ButtonBackListOffers url={urlBack} text={`regresar al listado de ${backPage}`}/>
        </Grid>
        <Grid container className="main-container">
          <Grid container className="detail-new-container-view">
            <Grid container item xs={12} className ="img-container-view">
              {
                detailInfo &&
                <img className="img-view" src={detailInfo.image === null && type === "Noticia" ? imgNoticias : detailInfo.image === null && type === "Evento" ? imgEventos : detailInfo.image} alt={detailInfo.name}/>
              }
            </Grid>
            <Grid container item xs={12} className="summary-container-view">
              {detailInfo && category.length>0 &&
              <Grid container item xs={12}>
                <Typography variant="h4" className="category-container-text" >
                  <BookmarkIcon className="category-icon"/> {category}
                </Typography>
              </Grid>
              }
              {
                detailInfo && type &&
                <Grid container item xs={12} md={12} lg={type === 'Noticia' ? 12 : 9}>
                  {detailInfo.name.length>0 &&
                    <Grid container>
                      <Typography variant="h1" className="summary-title-view">
                        {detailInfo.name}
                      </Typography>
                    </Grid>
                  }
                  {detailInfo.shortDescription.length>0 &&
                    <Grid container>
                      <Typography variant="h2" className="summary-description-view">
                        {detailInfo.shortDescription}
                      </Typography>
                    </Grid>
                  }
                  {detailInfo.description.length>0 &&
                    <Grid container item xs={12}>
                      <div className={type === 'Noticia' ? "preview-description-html" : "preview-event-description-html"}>
                        {ReactHtmlParser(detailInfo.description)}
                      </div>
                    </Grid>
                  }
                </Grid>
              }
              {
                detailInfo && type &&
                <Grid container item xs={12} md={12} lg={3} className={type === 'Noticia' ? "no-container" : "info-event-container-view"}>
                  <Grid container className="event-summary-content-view">
                    <Grid container>
                      <Typography variant="h5" className="event-title">
                        Lugar del evento
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Typography variant="h5" className="date-section">
                        <LocationOnIcon className="icon-class"/>{detailInfo.place}
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Typography variant="h5" className="event-title">
                        Fecha y hora de inicio del evento
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Typography variant="h5" className="date-section">
                        <EventIcon className="icon-class"/>
                        {moment(new Date(detailInfo.dateStart+"")).locale('es').format("dddd, DD MMMM YYYY")}
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Typography variant="h5" className="date-section">
                        <AccessTimeIcon className="icon-class"/>
                        {moment(new Date(moment(detailInfo.dateStart).format("YYYY-MM-DD") + " " + detailInfo.timeStart)).format("hh:mm a")}
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Typography variant="h5" className="event-title">
                        Fecha y hora de fin del evento
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Typography variant="h5" className="date-section">
                        <EventIcon className="icon-class"/>
                        {moment(new Date(detailInfo.dateFinish+"")).locale('es').format("dddd, DD MMMM YYYY")}
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Typography variant="h5" className="date-section">
                        <AccessTimeIcon className="icon-class"/>
                        {moment(new Date(moment(detailInfo.dateFinish).format("YYYY-MM-DD") + " " + detailInfo.timeFinish)).format("hh:mm a")}
                      </Typography>
                    </Grid>
                  </Grid>
                    <Grid container className="enroll-container">
                      {alreadyEnrol ?
                        <div className="enroll-text-container" style={{background:'#99C246'}}>
                          <Typography variant="h5" className="bold-serif">
                            Ya esta inscrito
                          </Typography>
                        </div>
                      :
                        new Date(moment(detailInfo.dateFinish).format("YYYY-MM-DD") + " " + detailInfo.timeFinish) < new Date() ?
                          <div className="enroll-text-container" style={{background:'#FBBA2B'}}>
                            <Typography variant="h5" className="bold-serif">
                              Evento finalizado
                            </Typography>
                          </div>
                        :
                          <Button disabled={(userContext.roleSelected === 0 || containsAny(permissionSubscribeEvent,userContext.userPermissions)) ? false : true} className="enroll-btn" onClick={handleEnroll}>
                            <Typography variant="h5" className="reguar-serif">
                              Inscribirse al evento
                            </Typography>
                          </Button>
                      }
                    </Grid>
                </Grid>
              }
              <Grid container item xs={12} className="cards-related-container">
                <Grid container item xs={12} md={12} lg={12} direction="column">
                  {
                    detailInfo && type && relationArray.length > 0 &&
                    <Typography variant="h1" className="cards-related-container-title" >
                      {type === "Noticia" ? "Noticias relacionadas" : "Eventos relacionados"}
                    </Typography>
                  }
                </Grid>
                <Grid container className="cards-related-grid">
                  {
                    relationArray.length> 0  && relationArray.map((news: IGetNewEvent)=>(
                      <NewsCard
                        key={news.id}
                        type={news.type}
                        categoryId={news.category}
                        image = {news.image === null && news.type === "Noticia" ? imgNoticias : news.image === null && news.type === "Evento" ? imgEventos : news.image}
                        name = {news.name}
                        publishDate = {news.publishDate}
                        shortDescription = {news.shortDescription}
                        dateStart={news.dateStart}
                        timeStart={news.timeStart}
                        dateFinish={news.dateFinish}
                        timeFinish={news.timeFinish}
                        place={news.place}
                      >
                        <Button size="small" className="view-button" onClick={(e)=>handleSeeEvent(e, news.id)}>
                          <Typography variant="h5" className="regular-serif">
                            Ver {news.type}
                          </Typography>
                        </Button>
                      </NewsCard>
                    ))
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ModalOffersAction
          open={openLoginModal}
          handleClose={closeModalLogin}
          iconModal={<InfoIcon className="big-info-icon"/>}
          title="Es necesario iniciar sesión para poder vincularse"
          headerdescription=""
          description=""
          backBtnText="Regresar"
          colorBtnBack= ""
          continueBtnText="Continuar"
          colorBtnContinue="#14A5DC"
          linkContinue="/login"
          valid={true}
        />
        <ModalOffersAction
          open={modalSubscribe}
          handleClose={closeModalSubscribe}
          handleContinue={confirmSubscribe}
          iconModal={<WarningIcon className="big-warning-icon" />}
          title=""
          headerdescription="¿Está seguro de inscribirte a este evento?"
          description=""
          backBtnText="Regresar"
          colorBtnBack= ""
          continueBtnText="Si, inscribirme"
          colorBtnContinue="#f44336"
          valid={estateValue>0 ? true : false}
        >
          <Grid container item xs={12} justify="center">
            <Grid item xs={12} className="field-box-margin">
              <Typography variant="h5" className="bold-serif">Seleccione el estamento</Typography>
              <TextField
                fullWidth
                id="outlined-select-estateValue"
                select
                name="estateValue"
                label={estateValue === 0 ? "Seleccione": ""}
                value={estateValue === 0 ? "" : estateValue}
                onChange={changeEstateValue}
                InputLabelProps={{shrink: false}}
                margin="none"
                variant="outlined"
                disabled={estateArray.length>0 ? false : true}
              >
                {
                  estateArray && estateArray.length>0 ?
                  estateArray.map((option: IGeneralParameter) => (
                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                      {option.name}
                    </MenuItem>
                  ))
                  :
                    <MenuItem value={estateValue}></MenuItem>
                }
              </TextField>
            </Grid>
          </Grid>
        </ModalOffersAction>
        <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
        <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
        <LoadingLockPage load={promiseInProgress}/>
        <LoadingLockPage load={initLoad}/>
      </div>
    }
    </>
  )
}

export default EventDetailView
