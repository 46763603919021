import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import moment from 'moment';
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from "@material-ui/core/MenuItem";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import UserContext from '../../../utils/UserContext';
import { IExperience, ILaboralInfoCV } from '../../../interfaces/ILaboralInfoCV';
import { getUserLaboralInfo, updateLaboralInfo, getUserPercentage } from '../../../services/GraduateService';
import NotificationBox from '../../ShareComponents/NotificationBox';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { convertCurrency, sortByKey } from '../../../helpers/sharedFunctions';
import { IListParametersInfo } from '../../../interfaces/IListParametersInfo';
import Validations from '../../../helpers/Validations';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import {
    getCITY, getCOUNTRIES_CITIES, getDEPARTAMENT, getEMPLOYMENT_AND_PROFESSION_RELATIONSHIP,
    getEMPLOYMENT_RELATIONSHIP, getPERFORMANCE_AREA, getRangeMinimun, getSALARY_ASPIRATION, getOCCUPATION
} from '../../../services/ParameterService';
import AlertBox from '../../ShareComponents/AlertBox';
import '../GraduateCurriculum.scss';
import CircularProgressDet from '../../ShareComponents/CircularProgressDet';
import { IUnenrollOptions } from '../../../interfaces/IUnenrollOptions';
import UnenrollModal from '../../ShareComponents/UnenrollModal';
import { leaveReasons } from '../../../interfaces/ILaboralInfoCV';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            margin: theme.spacing(2, 0),
        },
    }),
);
const BlueSwitch = withStyles({
    switchBase: {
        color: '#ffffff',
        '&$checked': {
            color: '#004B73',
        },
        '&$checked + $track': {
            backgroundColor: '#004B73',
        },
    },
    checked: {},
    track: {},
})(Switch);

class InitialLaboralErrors {
    // laboral info
    laboralProfile: string = "";
    competences: string = "";
    salaryAspiration: string = "";
    keyword:string =  "";
    // experiences
    position: string = "";
    ocupation: string = "";
    employmentRelationship: string = "";
    performanceArea: string = "";
    achivements: string = "";
    employmentAndProfessionRelationship: string = "";
    salaryRange: string = "";
    startDateFormat: string = "";
    endDateFormat: string = "";
    companyName: string = "";
    phoneNumber: string = "";
    country: string = "";
    departament: string = "";
    city: string = "";
    bossName: string = "";
    bossPositon: string = "";
    bossPhone: string = "";
    companyType: string = "";
}

const formValidations = {
    laboralProfile: {
        error: "",
        validate: "textNumber",
        required: false
    },
    competences: {
        error: "",
        validate: "textNumber",
        required: false
    },
    salaryAspiration: {
        error: "",
        validate: "select",
        required: false
    },
    // all laboral info
    position: {
        error: "",
        validate: "textNumber",
        required: true
    },
    ocupation: {
        error: "",
        validate: "select",
        required: true
    },
    employmentRelationship: {
        error: "",
        validate: "select",
        required: false
    },
    performanceArea: {
        error: "",
        validate: "select",
        required: false
    },
    achivements: {
        error: "",
        validate: "textNumber",
        maxLength: 2000,
        required: false
    },
    employmentAndProfessionRelationship: {
        error: "",
        validate: "select",
        required: false
    },
    salaryRange: {
        error: "",
        validate: "select",
        required: false
    },
    startDateFormat: {
        error: "",
        validate: "normalDate",
        maxDate: new Date(),
        required: true
    },
    endDateFormat: {
        error: "",
        validate: "normalDate",
        minDate: new Date(),
        required: false
    },
    companyName: {
        error: "",
        validate: "textNumber",
        required: true
    },
    phoneNumber: {
        error: "",
        validate: "textNumber",
        minLength: 7,
        maxLength: 30,
        required: false
    },
    country: {
        error: "",
        validate: "select",
        required: false
    },
    departament: {
        error: "",
        validate: "select",
        required: false
    },
    city: {
        error: "",
        validate: "select",
        required: false
    },
    bossName: {
        error: "",
        validate: "text",
        required: false
    },
    bossPositon: {
        error: "",
        validate: "textNumber",
        required: false
    },
    bossPhone: {
        error: "",
        validate: "textNumber",
        minLength: 7,
        maxLength: 30,
        required: false
    },
    companyType: {
        error: "",
        validate: "select",
        required: false
    },
    keyword: {
        error: "",
        validate: "textNumber",
        minLength: 3,
        required: false
    },
}

const compType = [
    { id: 1, label: 'Privada', value: 1, description: '' },
    { id: 2, label: 'Publica', value: 2, description: '' },
    { id: 3, label: 'Mixta', value: 3, description: '' },
];

const unenrollList: Array<IUnenrollOptions> = [
    { id: 1, label: 'Estoy laborando actualmente', value: 1 },
    { id: 2, label: 'Soy emprendedor o empresario', value: 2 },
    { id: 3, label: 'Estoy pensionado(a)', value: 3 },
    { id: 4, label: 'Estoy fuera del país', value: 4 },
    { id: 5, label: 'La Bolsa de empleo de la UNAL no me fue útil', value: 5 },
    { id: 6, label: 'Estoy estudiando.', value: 6 },
    { id: 7, label: 'No estoy en búsqueda activa de empleo.', value: 7 },
    { id: 8, label: 'Otra causa', value: 8 }
];

const LaboralInformation: React.FC = () => {
    let params: any = useParams();
    const userContext = React.useContext(UserContext);
    const { promiseInProgress } = usePromiseTracker();
    const userToken: string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer') + "" : "";
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [initialValues, setInitialValues] = useState<boolean>(true);
    const [expanded, setExpanded] = useState<string | boolean>('panel1');
    const [formValues, setFormValues] = useState<ILaboralInfoCV>(new ILaboralInfoCV({}));
    const [experienceFormValues, setExperienceFormValues] = useState<IExperience>(new IExperience());
    const [formErrors, setFormErrors] = useState<InitialLaboralErrors>(new InitialLaboralErrors());
    const [percUser, setPercUser] = useState<number>(0);
    const [myObjReasons, setMyObjReasons] = useState<leaveReasons>(new leaveReasons())
    //data
    const [employRelationArray, setEmployRelationArray] = useState<Array<IGeneralParameter>>([]);
    const [perfomanceAreaArray, setPerfomanceAreaArray] = useState<Array<IGeneralParameter>>([]);
    const [relationProffessionArray, setrelationProffessionArray] = useState<Array<IGeneralParameter>>([]);
    const minimumSalary = 1000000;
    const [salaryRangeArray, setSalaryRangeArray] = useState<Array<string>>([]);
    const [countryCitiesArray, setCountryCitiesArray] = useState<Array<IListParametersInfo>>([]);
    const [departamentArray, setDepartamentArray] = useState<Array<IGeneralParameter>>([]);
    const [cityArray, setCityArray] = useState<Array<IGeneralParameter>>([]);
    const [cities, setCities] = useState<Array<IGeneralParameter>>([]);
    const [salaryAspArr, setSalaryAspArr] = useState<Array<IGeneralParameter>>([]);
    const [ocupationArr, setOcupationArr] = useState<Array<IGeneralParameter>>([]);
    // modals and errors
    const [errorMessage, setErrorMessage] = useState('');
    const [openModalError, setOpenModalError] = React.useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [openModalSuccess, setOpenModalSuccess] = React.useState(false);
    const [openUnenrollModal, setOpenUnenrollModal] = React.useState(false);
    const [flagUdatedJobBank, setFlagUdatedJobBank] = React.useState(false);

    const isValidAddExperience =
        experienceFormValues.position.length > 0 &&
        !formErrors.position &&
        !formErrors.employmentRelationship &&
        !formErrors.performanceArea &&
        !formErrors.achivements &&
        !formErrors.employmentAndProfessionRelationship &&
        !formErrors.salaryRange &&
        moment(experienceFormValues.startDateFormat).isValid() &&
        !formErrors.startDateFormat &&
        experienceFormValues.companyName.length > 0 &&
        !formErrors.companyName &&
        !formErrors.phoneNumber &&
        !formErrors.country &&
        !formErrors.departament &&
        !formErrors.city &&
        !formErrors.bossName &&
        !formErrors.bossPhone &&
        !formErrors.bossPositon

    const classes = useStyles();

    useEffect(() => {
        const getInitialData = async () => {
            window.scrollTo(0, 0);
            let responseEmployRel: Array<IGeneralParameter> = await getEMPLOYMENT_RELATIONSHIP();
            setEmployRelationArray(responseEmployRel);
            let responsePerformance: Array<IGeneralParameter> = await getPERFORMANCE_AREA();
            setPerfomanceAreaArray(responsePerformance);
            let responseRelProffessions: Array<IGeneralParameter> = await getEMPLOYMENT_AND_PROFESSION_RELATIONSHIP();
            setrelationProffessionArray(responseRelProffessions);
            let salaryAspArray: Array<IGeneralParameter> = await getSALARY_ASPIRATION();
            salaryAspArray = sortByKey(salaryAspArray, "parameter_code")
            setSalaryAspArr(salaryAspArray);
            let ocupationArray: Array<IGeneralParameter> = await getOCCUPATION();
            ocupationArray = sortByKey(ocupationArray, 'name')
            setOcupationArr(ocupationArray)
            // salary range field
            const responseSalary = await getRangeMinimun();
            let minValSalary: number = 0;
            if (responseSalary.status === 200) {
                const result = await responseSalary.json();
                minValSalary = parseInt(result.message.value);
            } else {
                minValSalary = minimumSalary;
            }
            const maxSalary = (minValSalary) + (500000 * 24);
            let arrSalary: Array<string> = [];
            for (let i = minValSalary; i <= maxSalary; i += 500000) {
                if (i === maxSalary) {
                    arrSalary.push(`${convertCurrency(i)} o mas`)
                }
                else {
                    arrSalary.push(`${convertCurrency(i)} a ${convertCurrency(i + 500000)}`)
                }
            }
            setSalaryRangeArray(arrSalary);
            // data countries-cities
            let dataCountryCities: Array<IListParametersInfo> = (await getCOUNTRIES_CITIES()).list;
            dataCountryCities = sortByKey(dataCountryCities, 'grandParentName');
            setCountryCitiesArray(dataCountryCities);
            // departament field
            let responseDepartament: Array<IGeneralParameter> = await getDEPARTAMENT();
            responseDepartament = sortByKey(responseDepartament, 'name');
            setDepartamentArray(responseDepartament);
            // data for cities
            const dataCity: Array<IGeneralParameter> = await getCITY();
            setCityArray(dataCity);

            if (userContext.userId) {
                const responseUser = await getUserLaboralInfo(userToken, parseInt(userContext.roleSelected === 1 ? userContext.userId : params.id));
                if (responseUser.status === 200) {
                    const resultLaboralUser: ILaboralInfoCV = await responseUser.json();
                    const tempFormValues = new ILaboralInfoCV(resultLaboralUser);
                    
                    tempFormValues.doc = userContext.roleSelected === 1 ? userContext.userId : params.id;

                    let convertedOtherSkills: Array<{name:string}> = [];
                    if(resultLaboralUser.laboralInformation.keywords){
                      const otherArr =resultLaboralUser.laboralInformation.keywords.split('|');
                      otherArr.forEach(item=>{
                        convertedOtherSkills.push({name:item});
                      })
                    }
                    if(convertedOtherSkills.length>0){
                        resultLaboralUser.laboralInformation.tempKeywords = convertedOtherSkills;
                    }else{
                        tempFormValues.laboralInformation.tempKeywords = [];
                    }
                    setFormValues(tempFormValues);
                }
                setInitialValues(false);
            }
            else if (params.id) {
                const responseUser = await getUserLaboralInfo(userToken, params.id);
                if (responseUser.status === 200) {
                    const resultLaboralUser: ILaboralInfoCV = await responseUser.json();
                    const tempFormValues = new ILaboralInfoCV(resultLaboralUser);
                    tempFormValues.doc = params.id;
                    let convertedOtherSkills: Array<{name:string}> = [];
                    if(resultLaboralUser.laboralInformation.keywords){
                      const otherArr =resultLaboralUser.laboralInformation.keywords.split('|');
                      otherArr.forEach(item=>{
                        convertedOtherSkills.push({name:item});
                      })
                    }
                    if(convertedOtherSkills.length>0){
                        resultLaboralUser.laboralInformation.tempKeywords = convertedOtherSkills;
                    }else{
                        tempFormValues.laboralInformation.tempKeywords = [];
                    }
                    setFormValues(tempFormValues);
                }
                setInitialValues(false);
            }
            setInitLoad(false);
        }
        getInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(formValues.laboralInformation.tempKeywords.length > 0){
            const arrObj = formValues.laboralInformation.tempKeywords;
            setFormValues(prev => ({
                ...prev,
                laboralInformation: {
                    ...prev.laboralInformation,
                    keywords: (arrObj.map(obj=>obj.name)).toString().split(",").join("|")
                }
            }));
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues.laboralInformation.tempKeywords])

    useEffect(() => {
        if (!initialValues) {
            setExperienceFormValues(prev => ({
                ...prev,
                departament: 0,
                city: 0
            }));
        }
        if (experienceFormValues.country > 0) {
            if (experienceFormValues.country === 170) {
                setCities([]);
            } else {
                const countryObjSelected = countryCitiesArray.find(country => country.id === experienceFormValues.country);
                if (countryObjSelected) {
                    setCities(countryObjSelected.childs);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experienceFormValues.country])

    useEffect(() => {
        if (!initialValues) {
            setExperienceFormValues(prev => ({
                ...prev,
                city: 0
            }));
        }
        if (experienceFormValues.departament > 0) {
            let citiesDep: Array<IGeneralParameter> = cityArray.filter(city => city.parentId === experienceFormValues.departament);
            citiesDep = sortByKey(citiesDep, 'name');
            if (citiesDep.length > 0) {
                setCities(citiesDep)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experienceFormValues.departament])

    const handleChangeAccoridon = (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false);
    };

    const   handleChangeForm = (e: any) => {
        const { name, value } = e.target;
        setFormValues(prev => ({
            ...prev,
            laboralInformation: {
                ...prev.laboralInformation,
                [name]: value
            }
        }));
        const error: string = Validations(name, value, formValidations) || "";
        setFormErrors((prev: any) => ({
            ...prev,
            [name]: error
        }));
    }

    const changeExperienceForm = (e: any) => {
        const { name, value } = e.target;
        setExperienceFormValues(prev => ({
            ...prev,
            [name]: value
        }));
        const error: string = Validations(name, value, formValidations) || "";
        setFormErrors((prev: any) => ({
            ...prev,
            [name]: error
        }));
    }

    const changeCheckBoxLaboral = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value: any = e.target.checked;
        if (e.target.name === 'inJobBank') {
            value = e.target.checked ? new Date() : null;
            setFlagUdatedJobBank(true)
        }
        setFormValues(prev => ({
            ...prev,
            laboralInformation: {
                ...prev.laboralInformation,
                [e.target.name]: value
            }
        }));
    };

    const changeExperienceDate = (dateValue: Date | null, nameField: string) => {
        const value = dateValue ? dateValue?.toString() : '';
        setExperienceFormValues(prev => ({
            ...prev,
            [nameField]: value
        }));
        if (nameField === "startDateFormat") {
            setExperienceFormValues(prev => ({
                ...prev,
                endDateFormat: null
            }));
        }
        if (nameField === "endDateFormat") {
            formValidations.endDateFormat.minDate = experienceFormValues.startDateFormat ? moment(new Date(experienceFormValues.startDateFormat)).toDate() : moment(new Date()).toDate();
        }
        const error: string = Validations(nameField, value, formValidations) || "";
        setFormErrors((prev: any) => ({
            ...prev,
            [nameField]: error
        }));
    }

    const changeCheckBoxExperience = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setExperienceFormValues(prev => ({
            ...prev,
            [name]: checked
        }));
    };

    const addExperience = () => {
        experienceFormValues.id = formValues.laboralExperiences.length + 1;
        experienceFormValues.startDate = experienceFormValues.startDateFormat ? moment(new Date(experienceFormValues.startDateFormat + "")).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD");
        experienceFormValues.endDate = experienceFormValues.endDateFormat ? moment(new Date(experienceFormValues.endDateFormat + "")).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD");
        setFormValues(prev => ({
            ...prev,
            laboralExperiences: [...prev.laboralExperiences, experienceFormValues]
        }));
        setExperienceFormValues(new IExperience());
    }

    const deleteExperience = (e: any, idToDelete: number | null) => {
        const newArrayExp = formValues.laboralExperiences.filter(item => item.id !== idToDelete);
        setFormValues(prev => ({
            ...prev,
            laboralExperiences: newArrayExp
        }));
    }

    const changeCheckBoxExpItem = (e: React.ChangeEvent<HTMLInputElement>, idExp: number | null) => {
        const experiencesAdjusted = formValues.laboralExperiences.map(experience => {
            if (experience.id === idExp) {
                experience.visible = !experience.visible
            }
            return experience;
        });
        setFormValues(prev => ({
            ...prev,
            laboralExperiences: experiencesAdjusted
        }));
    }


    const saveLaboralInfo = async () => {
        formValues.laboralExperiences.forEach(experience => {
            delete experience.id;
            delete experience.startDateFormat;
            delete experience.endDateFormat;
        });
        const objToUpdate = formValues;
        if (!objToUpdate.laboralInformation.inJobBank) {
            objToUpdate.laboralInformation.reasons = myObjReasons
        }

        
        const responseLaboralInfo = await trackPromise(updateLaboralInfo(
            userToken,
            { ...objToUpdate, modifyUser: userContext.userId }
        ));
        if (responseLaboralInfo.status === 200) {
            setSuccessMessage("Se ha guardado correctamente la información")
            if (userContext.userId && params.id === undefined) {
                await trackPromise(userContext.handleSetUserPercentage(userContext.userId));
            } else if (params.id) {
                await trackPromise(userContext.handleSetUserPercentage(params.id));
            }

            setOpenModalSuccess(true);
            setOpenModalError(false);
        } else {
            setErrorMessage("Hubo un error, intente de nuevo mas tarde");
            setOpenModalError(true);
            setOpenModalSuccess(false);
        }
    }

    const handleCloseModalSuccess = () => {
        setOpenModalSuccess(false);
    };

    const handleCloseModalError = () => {
        setOpenModalError(false);
    };

    const nextBtnAction = () => {
        userContext.setStateCurriculumSection("Información adicional");
    }

    const backBtnAction = () => {
        userContext.setStateCurriculumSection("Información académica");
    }

    const handleCloseModalEnrrollModal = () => {
        setOpenUnenrollModal(false);
        setFlagUdatedJobBank(false);
        saveLaboralInfo()
    }

    const handleCancelModalEnrrollModal = () => {
        setOpenUnenrollModal(false);
    }

    const handleOpenUnenrollModal = () => {
        if (flagUdatedJobBank && !formValues.laboralInformation.inJobBank) {
            setOpenUnenrollModal(true)
        } else {
            saveLaboralInfo()
        }
    }
    const handleOnChangeUnenroll = (value: string, formField: string) => {
        setMyObjReasons(prev => ({
            ...prev,
            [formField]: value
        }));
    }
    const AddKeyword = ()=>{
        const keyWordObj = {
          name: formValues.laboralInformation.keyword
        }
        setFormValues(prev => ({
            ...prev,
            laboralInformation: {
                ...prev.laboralInformation,
                tempKeywords: [...formValues.laboralInformation.tempKeywords, keyWordObj]
            }
        }));
        setFormValues(prev => ({
            ...prev,
            laboralInformation: {
                ...prev.laboralInformation,
                keyword: ""
            }
        }));
      }
    
      const deleteKeyword = (e:any, keyword: any)=>{
        const newArray = formValues.laboralInformation.tempKeywords.filter(key=>key.name !== keyword);
        setFormValues(prev => ({
            ...prev,
            laboralInformation: {
                ...prev.laboralInformation,
                tempKeywords: newArray
            }
        }));
      }

    return (
        <>
            <Grid item xs={12} lg={10} className="curriculum-form-info-container">
                <Grid item xs={12} style={{ marginRight: '10px' }}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccoridon('panel1')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                        >
                            <Typography variant="h2" className="bold-serif">Información laboral</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row">
                                <Grid item xs={12} className="information-box-margin ">
                                    <Grid container item xs={12} direction="row" alignItems="center">
                                        <Grid item xs={12} sm={6} className="checkbox-curriculum-container">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={formValues.laboralInformation.currentlyWorking}
                                                        onChange={changeCheckBoxLaboral}
                                                        name="currentlyWorking"
                                                    />}
                                                label="¿Se encuentra trabajando actualmente?"
                                                labelPlacement="start"
                                                className="checkbox"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} className="information-box-section">
                                            <Typography variant="h5" className="bold-serif title-box-text">Aspiracion Salarial</Typography>
                                            {salaryAspArr.length > 0 &&
                                                <TextField
                                                    fullWidth
                                                    id="outlined-select-salaryAspiration"
                                                    select
                                                    name="salaryAspiration"
                                                    label={formValues.laboralInformation.salaryAspiration === 0 ? "Seleccione" : ""}
                                                    value={formValues.laboralInformation.salaryAspiration === 0 ? "" : formValues.laboralInformation.salaryAspiration}
                                                    onChange={handleChangeForm}
                                                    InputLabelProps={{ shrink: false }}
                                                    margin="none"
                                                    variant="outlined"
                                                >
                                                    {
                                                        salaryAspArr && salaryAspArr.length > 0 &&
                                                        <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                                    }
                                                    {
                                                        salaryAspArr.length > 0 ?
                                                            salaryAspArr.map((option) => (

                                                                <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))
                                                            :
                                                            <MenuItem value={formValues.laboralInformation.salaryAspiration}></MenuItem>
                                                    }
                                                </TextField>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                    <Grid item xs={12} sm={10} className='information-box-section'>
                                        <Typography variant="h5" className="bold-serif">Palabras Clave</Typography>
                                        <TextField
                                            fullWidth
                                            name="keyword"
                                            margin="none"
                                            label={formValues.laboralInformation.keyword === "" ? "Ingrese otra palabra clave" : ""}
                                            value={formValues.laboralInformation.keyword}
                                            onChange={handleChangeForm}
                                            error={!!formErrors.keyword}
                                            helperText={formErrors.keyword}
                                            InputLabelProps={{ shrink: false }}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={2} className='add-container'>
                                        <Button disabled={formErrors.keyword.length > 0 || formValues.laboralInformation.keyword === ""} onClick={AddKeyword} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                                            <Typography variant="h6" className="regular-serif">
                                                Adicionar
                                            </Typography>
                                        </Button>
                                    </Grid>

                                    {
                                        formValues.laboralInformation.tempKeywords && formValues.laboralInformation.tempKeywords.length > 0 &&
                                        formValues.laboralInformation.tempKeywords.map((keywordSelected: any, index) => (
                                            <Grid key={index} container item xs={12} className="detail-container">
                                                <div className="detail-description">
                                                    <Typography variant="h5" className="regular-serif">
                                                        {keywordSelected.name}
                                                    </Typography>
                                                </div>
                                                <Button onClick={(e) => deleteKeyword(e, keywordSelected.name)} variant="contained" className="del-btn" endIcon={<DeleteIcon />} >
                                                    <Typography variant="h6" className="regular-serif">
                                                        Eliminar
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        ))
                                    }
                                    </Grid>
                                    
                                    <Grid item xs={12}>
                                        <Divider className={classes.divider} />
                                    </Grid>
                                    <Grid container item xs={12} direction="row" alignItems="center">
                                        <Grid item xs={12} sm={6} className="checkbox-curriculum-container">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={formValues.laboralInformation.inJobBank != null ? true : false}
                                                        onChange={changeCheckBoxLaboral}
                                                        name="inJobBank"
                                                    />}
                                                label="¿Desea estar en la bolsa de empleos?"
                                                labelPlacement="start"
                                                className="checkbox"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} className="checkbox-curriculum-container">
                                            {!formValues.laboralInformation.inJobBank &&
                                                <AlertBox
                                                    alert="warning"
                                                    variantType="outlined"
                                                >
                                                    {
                                                        <span className="alert-simple-text">
                                                            !Alerta! Usted no se encuentra la bolsa de empleo
                                                        </span>
                                                    }
                                                </AlertBox>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider className={classes.divider} />
                                    </Grid>
                                    <Grid item xs={12} className="information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Perfil</Typography>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            id="outlined-select-laboralProfile"
                                            name="laboralProfile"
                                            label={formValues.laboralInformation.laboralProfile === "" ? "Ingrese su perfil laboral" : ""}
                                            value={formValues.laboralInformation.laboralProfile === "" ? "" : formValues.laboralInformation.laboralProfile}
                                            onChange={handleChangeForm}
                                            error={!!formErrors.laboralProfile}
                                            helperText={formErrors.laboralProfile}
                                            InputLabelProps={{ shrink: false }}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} className="information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Competencias</Typography>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            id="outlined-select-competences"
                                            name="competences"
                                            label={formValues.laboralInformation.competences === "" ? "Ingrese sus competencias" : ""}
                                            value={formValues.laboralInformation.competences === "" ? "" : formValues.laboralInformation.competences}
                                            onChange={handleChangeForm}
                                            error={!!formErrors.competences}
                                            helperText={formErrors.competences}
                                            InputLabelProps={{ shrink: false }}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} style={{ marginRight: '10px' }}>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccoridon('panel2')} className="curriculum-section-accordion-container">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                        >
                            <Typography variant="h2" className="bold-serif">Experiencia laboral</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="curriculum-information-accordion-details">
                            <Grid container item xs={12} direction="row">
                                <Grid container item xs={12} className="information-box-margin">
                                    <Grid container item xs={12} className="complementary-text-container">
                                        <Typography variant="h5" className="regular-serif">
                                            En está sección puede adicionar o eliminar su experiencia laboral, luego de adicionar debe Guardar los cambios.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Nombre del Cargo*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-experience-position"
                                            name="position"
                                            label={experienceFormValues.position === "" ? "Nombre del cargo" : ""}
                                            value={experienceFormValues.position === "" ? "" : experienceFormValues.position}
                                            onChange={changeExperienceForm}
                                            error={!!formErrors.position}
                                            helperText={formErrors.position}
                                            InputLabelProps={{ shrink: false }}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Ocupación</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-ocupation"
                                            select
                                            name="ocupation"
                                            label={experienceFormValues.ocupation === 0 ? "Seleccione" : ""}
                                            value={experienceFormValues.ocupation === 0 ? "" : experienceFormValues.ocupation}
                                            onChange={changeExperienceForm}
                                            error={!!formErrors.ocupation}
                                            helperText={formErrors.ocupation}
                                            InputLabelProps={{ shrink: false }}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {
                                                ocupationArr && ocupationArr.length > 0 &&
                                                <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                            }
                                            {ocupationArr && ocupationArr.length > 0 ?
                                                ocupationArr.map((option: IGeneralParameter) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                                :
                                                <MenuItem value={experienceFormValues.ocupation}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="special-box-right information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Tipo de vinculación</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-employmentRelationship"
                                            select
                                            name="employmentRelationship"
                                            label={experienceFormValues.employmentRelationship === 0 ? "Seleccione" : ""}
                                            value={experienceFormValues.employmentRelationship === 0 ? "" : experienceFormValues.employmentRelationship}
                                            onChange={changeExperienceForm}
                                            error={!!formErrors.employmentRelationship}
                                            helperText={formErrors.employmentRelationship}
                                            InputLabelProps={{ shrink: false }}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {
                                                employRelationArray && employRelationArray.length > 0 &&
                                                <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                            }
                                            {employRelationArray && employRelationArray.length > 0 ?
                                                employRelationArray.map((option: IGeneralParameter) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                                :
                                                <MenuItem value={experienceFormValues.employmentRelationship}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Área de desempeño</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-performanceArea"
                                            select
                                            name="performanceArea"
                                            label={experienceFormValues.performanceArea === 0 ? "Seleccione" : ""}
                                            value={experienceFormValues.performanceArea === 0 ? "" : experienceFormValues.performanceArea}
                                            onChange={changeExperienceForm}
                                            error={!!formErrors.performanceArea}
                                            helperText={formErrors.performanceArea}
                                            InputLabelProps={{ shrink: false }}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {
                                                perfomanceAreaArray && perfomanceAreaArray.length > 0 &&
                                                <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                            }
                                            {perfomanceAreaArray && perfomanceAreaArray.length > 0 ?
                                                perfomanceAreaArray.map((option: IGeneralParameter) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                                :
                                                <MenuItem value={experienceFormValues.employmentRelationship}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} className="information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Funciones realizadas y logros</Typography>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={4}
                                            id="outlined-select-experience-achivements"
                                            name="achivements"
                                            label={experienceFormValues.achivements === "" ? "Funciones realizadas y logros del cargo" : ""}
                                            value={experienceFormValues.achivements === "" ? "" : experienceFormValues.achivements}
                                            onChange={changeExperienceForm}
                                            error={!!formErrors.achivements}
                                            helperText={formErrors.achivements}
                                            InputLabelProps={{ shrink: false }}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className=" special-box-right information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Afinidad con labor o profesión</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-employmentAndProfessionRelationship"
                                            select
                                            name="employmentAndProfessionRelationship"
                                            label={experienceFormValues.employmentAndProfessionRelationship === 0 ? "Seleccione" : ""}
                                            value={experienceFormValues.employmentAndProfessionRelationship === 0 ? "" : experienceFormValues.employmentAndProfessionRelationship}
                                            onChange={changeExperienceForm}
                                            error={!!formErrors.employmentAndProfessionRelationship}
                                            helperText={formErrors.employmentAndProfessionRelationship}
                                            InputLabelProps={{ shrink: false }}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {
                                                relationProffessionArray && relationProffessionArray.length > 0 &&
                                                <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                            }
                                            {relationProffessionArray && relationProffessionArray.length > 0 ?
                                                relationProffessionArray.map((option: IGeneralParameter) => (
                                                    <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                                :
                                                <MenuItem value={experienceFormValues.employmentAndProfessionRelationship}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} sm={6} className="special-box-left information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Rango salarial</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-salaryRange"
                                            select
                                            name="salaryRange"
                                            label={experienceFormValues.salaryRange === "" ? "Seleccione" : ""}
                                            value={experienceFormValues.salaryRange === "" ? "" : experienceFormValues.salaryRange}
                                            onChange={changeExperienceForm}
                                            error={!!formErrors.salaryRange}
                                            helperText={formErrors.salaryRange}
                                            InputLabelProps={{ shrink: false }}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {salaryRangeArray && salaryRangeArray.length > 0 ?
                                                salaryRangeArray.map((option: string, index: number) => (
                                                    <MenuItem key={index} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))
                                                :
                                                <MenuItem value={experienceFormValues.salaryRange}></MenuItem>

                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                            <Typography variant="h5" className="bold-serif title-box-text">Fecha de inicio*</Typography>
                                            <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                                <DatePicker
                                                    showToolbar={false}
                                                    disableFuture
                                                    openTo="year"
                                                    views={['year', 'month', 'date']}
                                                    maxDate={new Date()}
                                                    value={experienceFormValues.startDateFormat || null}
                                                    onChange={(value) => changeExperienceDate(value, 'startDateFormat')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            disabled
                                                            {...params}
                                                            fullWidth
                                                            name="startDateFormat"
                                                            margin="none"
                                                            label={(experienceFormValues.startDateFormat && experienceFormValues.startDateFormat !== null) ? "" : "Seleccione Fecha dd/mm/aaaa"}
                                                            error={!!formErrors.startDateFormat}
                                                            helperText={formErrors.startDateFormat}
                                                            InputLabelProps={{ shrink: false }}
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                            <Typography variant="h5" className="bold-serif title-box-text">Fecha de finalización <span className={experienceFormValues.actualWork === false ? "" : "no-container"}>*</span></Typography>
                                            <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                                                <DatePicker
                                                    showToolbar={false}
                                                    disabled={experienceFormValues.startDateFormat ? false : true}
                                                    openTo="year"
                                                    views={['year', 'month', 'date']}
                                                    value={experienceFormValues.endDateFormat || null}
                                                    onChange={(value) => changeExperienceDate(value, 'endDateFormat')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            disabled
                                                            {...params}
                                                            fullWidth
                                                            name="endDateFormat"
                                                            margin="none"
                                                            label={(experienceFormValues.endDateFormat && experienceFormValues.endDateFormat !== null) ? "" : "Seleccione Fecha dd/mm/aaaa"}
                                                            error={!!formErrors.endDateFormat}
                                                            helperText={formErrors.endDateFormat}
                                                            InputLabelProps={{ shrink: false }}
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid container item xs={12} sm={6} lg={4} className="bold-serif title-box-text special-box-left checkbox-top-curriculum-container">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={experienceFormValues.actualWork}
                                                        onChange={changeCheckBoxExperience}
                                                        name="actualWork"
                                                    />}
                                                label="Trabajo Actual"
                                                labelPlacement="top"
                                                className="checkbox-top"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={5} className="special-box-right information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Empresa*</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-companyName"
                                            name="companyName"
                                            label={experienceFormValues.companyName === "" ? "Ingrese nombre de la empresa" : ""}
                                            value={experienceFormValues.companyName === "" ? "" : experienceFormValues.companyName}
                                            onChange={changeExperienceForm}
                                            error={!!formErrors.companyName}
                                            helperText={formErrors.companyName}
                                            InputLabelProps={{ shrink: false }}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} sm={3} lg={3} className="information-box-section special-box-right">
                                        <Typography variant="h5" className="bold-serif title-box-text">Tipo de empresa</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-companyType"
                                            select
                                            name="companyType"
                                            label={experienceFormValues.companyType === 0 ? "Seleccione" : ""}
                                            value={experienceFormValues.companyType === 0 ? '' : experienceFormValues.companyType}
                                            onChange={changeExperienceForm}
                                            error={!!formErrors.companyType}
                                            helperText={formErrors.companyType}
                                            InputLabelProps={{ shrink: false }}
                                            margin="none"
                                            variant="outlined"
                                        >
                                            {
                                                compType && compType.length > 0 &&
                                                <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                            }
                                            {compType.map(item => (
                                                <MenuItem key={item.id} value={item.value}>
                                                    <Typography variant="h5" className="regular-serif">
                                                        {item.label}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} sm={4} className="special-box-left information-box-section">
                                        <Typography variant="h5" className="bold-serif title-box-text">Número telefónico</Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-select-phoneNumber"
                                            name="phoneNumber"
                                            label={experienceFormValues.phoneNumber === null ? "Ingrese número de la empresa" : ""}
                                            value={experienceFormValues.phoneNumber === null ? "" : experienceFormValues.phoneNumber}
                                            onChange={changeExperienceForm}
                                            error={!!formErrors.phoneNumber}
                                            helperText={formErrors.phoneNumber}
                                            InputLabelProps={{ shrink: false }}
                                            margin="none"
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center">
                                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                            <Typography variant="h5" className="bold-serif title-box-text">País</Typography>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-country"
                                                select
                                                name="country"
                                                label={experienceFormValues.country === 0 ? "Seleccione" : ""}
                                                value={experienceFormValues.country === 0 ? "" : experienceFormValues.country}
                                                onChange={changeExperienceForm}
                                                error={!!formErrors.country}
                                                helperText={formErrors.country}
                                                InputLabelProps={{ shrink: false }}
                                                margin="none"
                                                variant="outlined"
                                            >
                                                {
                                                    countryCitiesArray && countryCitiesArray.length > 0 &&
                                                    <MenuItem value={0}><em>Ninguno</em></MenuItem>
                                                }
                                                {countryCitiesArray && countryCitiesArray.length > 0 ?
                                                    countryCitiesArray.map((option: IListParametersInfo) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.grandParentName}
                                                        </MenuItem>
                                                    ))
                                                    :
                                                    <MenuItem value={experienceFormValues.country}></MenuItem>
                                                }
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                            <Typography variant="h5" className="bold-serif title-box-text">Departamento</Typography>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-departament"
                                                select
                                                name="departament"
                                                label={experienceFormValues.departament === 0 ? "Seleccione" : ""}
                                                value={experienceFormValues.departament === 0 ? "" : experienceFormValues.departament}
                                                onChange={changeExperienceForm}
                                                error={!!formErrors.departament}
                                                helperText={formErrors.departament}
                                                InputLabelProps={{ shrink: false }}
                                                margin="none"
                                                variant="outlined"
                                                disabled={(departamentArray && experienceFormValues.country > 0 && experienceFormValues.country === 170 ? false : true)}
                                            >
                                                {departamentArray && departamentArray.length > 0 ?
                                                    departamentArray.map((option: IGeneralParameter) => (
                                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))
                                                    :
                                                    <MenuItem value={experienceFormValues.departament}></MenuItem>
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                            <Typography variant="h5" className="bold-serif title-box-text">Municipio</Typography>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-city"
                                                select
                                                name="city"
                                                label={experienceFormValues.city === 0 ? "Seleccione" : ""}
                                                value={experienceFormValues.city === 0 ? "" : experienceFormValues.city}
                                                onChange={changeExperienceForm}
                                                error={!!formErrors.city}
                                                helperText={formErrors.city}
                                                InputLabelProps={{ shrink: false }}
                                                margin="none"
                                                variant="outlined"
                                                disabled={(cities && cities.length > 0 && ((experienceFormValues.country === 170 && experienceFormValues.departament > 0) || (experienceFormValues.country !== 170 && experienceFormValues.country > 0)) ? false : true)}
                                            >
                                                {cities && cities.length > 0 ?
                                                    cities.map((option: IGeneralParameter) => (
                                                        <MenuItem key={option.parameter_code} value={option.parameter_code}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))
                                                    :
                                                    <MenuItem value={experienceFormValues.city}></MenuItem>
                                                }
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} alignItems="center">
                                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                            <Typography variant="h5" className="bold-serif title-box-text">Nombre del jefe inmediato</Typography>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-bossName"
                                                name="bossName"
                                                label={experienceFormValues.bossName === "" ? "Ingrese nombre del jefe inmediato" : ""}
                                                value={experienceFormValues.bossName === "" ? "" : experienceFormValues.bossName}
                                                onChange={changeExperienceForm}
                                                error={!!formErrors.bossName}
                                                helperText={formErrors.bossName}
                                                InputLabelProps={{ shrink: false }}
                                                margin="none"
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                            <Typography variant="h5" className="bold-serif title-box-text">Cargo del jefe inmediato</Typography>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-bossPositon"
                                                name="bossPositon"
                                                label={experienceFormValues.bossPositon === "" ? "Ingrese cargo del jefe inmediato" : ""}
                                                value={experienceFormValues.bossPositon === "" ? "" : experienceFormValues.bossPositon}
                                                onChange={changeExperienceForm}
                                                error={!!formErrors.bossPositon}
                                                helperText={formErrors.bossPositon}
                                                InputLabelProps={{ shrink: false }}
                                                margin="none"
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} className="information-box-section special-box-right">
                                            <Typography variant="h5" className="bold-serif title-box-text">Número telefónico del jefe inmediato</Typography>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-bossPhone"
                                                name="bossPhone"
                                                label={experienceFormValues.bossPhone === null ? "Ingrese número del jefe inmediato" : ""}
                                                value={experienceFormValues.bossPhone === null ? "" : experienceFormValues.bossPhone}
                                                onChange={changeExperienceForm}
                                                error={!!formErrors.bossPhone}
                                                helperText={formErrors.bossPhone}
                                                InputLabelProps={{ shrink: false }}
                                                margin="none"
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider className={classes.divider} />
                                    </Grid>
                                    <Grid item xs={12} className="information-box-section special-box-left add-container">
                                        <Button disabled={!isValidAddExperience} onClick={addExperience} variant="contained" className="add-btn" endIcon={<AddIcon />} >
                                            <Typography variant="h6" className="regular-serif">
                                                Adicionar
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    {formValues.laboralExperiences.length > 0 &&
                                        formValues.laboralExperiences.map((exp, index) => {
                                            const employRelObj = employRelationArray.find(item => item.parameter_code === exp.employmentRelationship);
                                            const employRelName = (exp.employmentRelationship > 0 && employRelObj) ? employRelObj.name : "";
                                            const areaObJ = perfomanceAreaArray.find(item => item.parameter_code === exp.performanceArea);
                                            const areaName = (exp.performanceArea > 0 && areaObJ) ? areaObJ.name : "";
                                            const relationProfObj = relationProffessionArray.find(item => item.parameter_code === exp.employmentAndProfessionRelationship);
                                            const relationProfName = (exp.employmentAndProfessionRelationship > 0 && relationProfObj) ? relationProfObj.name : "";
                                            const countryObj = countryCitiesArray.find(item => item.id === exp.country);
                                            const countryName = (exp.country > 0 && countryObj) ? countryObj.grandParentName : "";
                                            const cityObj = cities.find(item => item.parameter_code === exp.city);
                                            const cityName = (exp.city > 0 && cityObj) ? cityObj.name : "";
                                            const ocuObj = ocupationArr.find(item => item.parameter_code === exp.ocupation);
                                            const ocuName = (exp.ocupation > 0 && ocuObj) ? ocuObj.name : "";
                                            return (
                                                <Grid key={index} container item xs={12} className="information-box-section  detail-add-container">
                                                    <Grid item xs={12} md={8} lg={9}>
                                                        <div className="detail-add-description">
                                                            <Typography variant="h5" className="regular-serif">
                                                            {exp.position ? `Cargo: ${exp.position} - ` : ""} {exp.ocupation ? `Ocupación: ${ocuName} - ` : ""} {exp.employmentRelationship ? `Tipo de Vinculación: ${employRelName} - ` : ""} {exp.performanceArea ? `Area de Desempeño: ${areaName}` : ""}
                                                            </Typography>
                                                            <Typography variant="h6" className="regular-serif">
                                                                {exp.achivements ? `Funciones Realizadas: ${exp.achivements}` : ""}
                                                            </Typography>
                                                            <Typography variant="h6" className="regular-serif">
                                                                {exp.employmentAndProfessionRelationship ? `Afinidad: ${relationProfName} - ` : ""}{exp.salaryRange ? `Rango Salarial: ${exp.salaryRange}` : ""}
                                                            </Typography>
                                                            <Typography variant="h6" className="regular-serif">
                                                                {exp.startDate ? `Fecha de inicio: ${moment(exp.startDate).format("DD/MM/YYYY")} - ` : ""}{exp.endDate ? `Fecha de finalización: ${moment(exp.endDate).format("DD/MM/YYYY")} ` : ""}{exp.actualWork ? `Actual` : ""}
                                                            </Typography>
                                                            <Typography variant="h6" className="regular-serif">
                                                                {exp.companyName ? `Empresa: ${exp.companyName} - ` : ""} {exp.companyType ? `Tipo: ${compType[exp.companyType - 1].label} - ` : ""} {exp.phoneNumber ? `Numero: ${exp.phoneNumber} - ` : ""}
                                                                {exp.country ? `${countryName} - ` : ""}{exp.city ? `${cityName}` : ""}
                                                            </Typography>
                                                            <Typography variant="h6" className="regular-serif">
                                                                {exp.bossName ? `Nombre del Jefe: ${exp.bossName} - ` : ""} {exp.bossPositon ? `Cargo: ${exp.bossPositon} - ` : ""} {exp.bossPhone ? `Numero: ${exp.bossPhone}` : ""}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} md={4} lg={3}>
                                                        <div className="delete-container">
                                                            <Button onClick={(e) => deleteExperience(e, exp.id ? exp.id : null)} variant="contained" className="delete-btn" endIcon={<DeleteIcon />} >
                                                                <Typography variant="h6" className="regular-serif">
                                                                    Eliminar
                                                                </Typography>
                                                            </Button>
                                                        </div>
                                                    </Grid>
                                                    {formValues.laboralExperiences && index !== formValues.laboralExperiences.length - 1 &&
                                                        <Grid item xs={12} className="special-box-both">
                                                            <Divider className={classes.divider} />
                                                        </Grid>
                                                    }
                                                    <Grid container item xs={12} alignItems="center" className="special-box-both visibility-curriculum-container">
                                                        <VisibilityIcon />
                                                        <FormControlLabel
                                                            value={exp.visible}
                                                            control={
                                                                <BlueSwitch
                                                                    size="small"
                                                                    checked={exp.visible}
                                                                    onChange={(e) => changeCheckBoxExpItem(e, exp.id ? exp.id : null)}
                                                                    name="visible"
                                                                />
                                                            }
                                                            label="Mostrar esta información en su hoja de vida"
                                                            labelPlacement="start"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <>
                    {
                        userContext.roleSelected === 9 ?
                            <></>
                            :
                            <>
                                <Grid container item xs={12}>
                                    <Grid container item xs={12} md={3} lg={6} className="step-curriculum-buttons">
                                        <Button onClick={backBtnAction} className="back-button" variant="contained" size="small">
                                            <Typography variant="h5" className="regular-serif">Regresar</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid container item xs={12} md={9} lg={6} className="buttons-curriculum-form-container">
                                        <Button onClick={handleOpenUnenrollModal} className="save-button" variant="contained" size="small">
                                            <Typography variant="h5" className="regular-serif">Guardar</Typography>
                                        </Button>
                                        <Button onClick={nextBtnAction} className="next-button" variant="contained" size="small">
                                            <Typography variant="h5" className="regular-serif">Siguiente</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                    }
                </>
            </Grid>
            <Grid container item xs={12} lg={2} justify="center" style={{ height: 'fit-content' }}>
                <NotificationBox
                    bgColor="#CFF4FC"
                    title="Porcentaje de Completitud"
                    titleColor="#055160"
                    description="Este es el % de los campos obligatorios necesarios para entrar a la bolsa de empleo"
                    descColor="#055160"
                    extra={
                        <>
                            <CircularProgressDet value={userContext.userPercentage?.total ? userContext.userPercentage.total : 0} size={90} descColor={"#055160"} />
                            <>
                                {userContext.userPercentage?.laboralData.percentage === 100 ?
                                    <></>
                                    :
                                    <Typography variant="h6" className="regular-serif" style={{ color: '#055160', width: '100%' }}>Te falta ingresar los siguientes campos
                                        <ul>
                                            {userContext.userPercentage?.laboralData.missing.map((item) => {
                                                return <li>{item}</li>
                                            })}
                                        </ul>
                                    </Typography>
                                }
                            </>
                        </>
                    }
                />
                <div className='space-notification-boxes'> </div>
                <NotificationBox
                    bgColor="#CFF4FC"
                    title="Información laboral"
                    titleColor="#055160"
                    description="Por favor actualice su información laboral. Los campos marcados con asterisco (*) son obligatorios. En algunos campos puede seleccionar si desea que la información sea visible en su Hoja de vida."
                    descColor="#055160"
                    icon={<ErrorOutlineIcon style={{ color: '#055160' }} fontSize="large" />}
                />
            </Grid>
            <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
            <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
            <UnenrollModal list={unenrollList} handleCloseModal={handleCloseModalEnrrollModal} handleCancelModal={handleCancelModalEnrrollModal} openModalUnenroll={openUnenrollModal} onChangeUnenroll={handleOnChangeUnenroll} />
            <LoadingLockPage load={promiseInProgress} />
            <LoadingLockPage load={initLoad} />

        </>
    )
}

export default LaboralInformation