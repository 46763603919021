import React from 'react';
import {Link}  from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import './GraduateStateBar.scss';

interface GraduateStateBarProps{
    selected: string;
}

const GraduateStateBar: React.FC<GraduateStateBarProps> = ({selected})=>{

  const buttons = [
    {id: 1, name: 'Gestión de estados', state: 'Gestion', url: "/admin/dashboard/egresados"},
    {id: 2, name: 'Mensajería masiva', state: 'Mensajeria', url: "/admin/dashboard/mensajeria-egresados"},
    {id: 3, name: 'Reporte de egresados', state: 'Reportes', url: "/admin/dashboard/reportes-egresados"},
  ]


  return (
    <Grid item xs={12} className="state-bar-container">
      <Grid container item xs={12} className="container-buttons">
        {buttons.map(item=>(
          <Link key={item.id}  to={item.url} className="link-state-bar">
            <div className={selected === item.state ? 'button-definition-active' : 'button-definition'}>
              <Typography variant="h5" className="regular-serif">
                {item.name}
              </Typography>
            </div>
          </Link>
        ))}
      </Grid>
    </Grid>
  )
}

export default GraduateStateBar;
