import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { Grid } from '@material-ui/core';
import UserContext from '../../../../utils/UserContext';
import { isLogin } from '../../../../services/AuthService';
import CompanyManagement from '../CompanyManagement';
import MassMessageManagementCompany from '../MassMessageManagement/MassMessageManagementCompany';
import CompanyStateBar from '../../../../components/ManagementState/CompanyStateBar';
import ReportsManagementCompany from '../ReportsManagement/ReportsCompany';
import OfferReportManagement from'../OfferReportManagement';
import './GeneralCompanyManagement.scss';

const GeneralCompanyManagement: React.FC = () => {
  const userContext = React.useContext(UserContext);
  const [selectedSection, setSelectedSection] = useState<string>('');

  useEffect(()=>{
    window.scrollTo(0, 0);
    const urlPath = window.location.href;
    if(urlPath.includes('/empresas')){
      setSelectedSection('Gestion');
      userContext.setStateCompanySection('Gestion');
    } else if(urlPath.includes('mensajeria')) {
      setSelectedSection('Mensajeria');
      userContext.setStateCompanySection('Mensajeria');
    } else if(urlPath.includes('empresas')) {
      setSelectedSection('Reportes');
      userContext.setStateCompanySection('Reportes');
    }
    else {
      setSelectedSection('Ofertas');
      userContext.setStateCompanySection('Ofertas');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[window.location.href])


  return (
    <>
      {isLogin() ?
        <>
          <Grid item xs={12} className="state-bar-section">
              <CompanyStateBar selected={selectedSection}/>
          </Grid>
          {selectedSection=== "Gestion" &&
            <Grid container item xs={12}>
              <CompanyManagement />
            </Grid>
          }
          {selectedSection=== "Mensajeria" &&
            <Grid container item xs={12}>
              <MassMessageManagementCompany />
            </Grid>
          }
          {selectedSection=== "Reportes" &&
            <ReportsManagementCompany />
          }
          {selectedSection=== "Ofertas" &&
            <OfferReportManagement />
          }
        </>
      :
        <Redirect to ="/"/>
      }
    </>
  )
}

export default GeneralCompanyManagement;