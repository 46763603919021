export class IPermission{
    id: number = 0;
    module: string = "";
    name: string = "";
    createdAt?: string = "";
    updatedAt?: string = "";
    checked?: boolean = false;
    constructor (def:any){
        this.id = def.id || 0;
        this.module = def.module || "";
        this.name = def.name || "";
        this.createdAt = def.createdAt || "";
        this.updatedAt = def.updatedAt || "";
        this.checked = def.checked || false;
    }
}