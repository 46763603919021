import React, { useState, useEffect } from "react";
import jwt from 'jsonwebtoken';
import { Redirect } from "react-router-dom";
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import SecurityIcon from "@material-ui/icons/Security";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Validations from "../../../helpers/Validations";
import ShowChangePasswordBox from "../../../components/ShowChangePasswordBox";
import UserContext from "../../../utils/UserContext";
import { changePassword } from "../../../services/AuthService";
import LoadingLockPage from "../../../components/ShareComponents/LoadingLockPage";
import ErrorModal from "../../../components/ShareComponents/ErrorModal";
import './ChangePassword.scss';
import { containsAny } from "../../../helpers/sharedFunctions";


class initialChangePassValues {
  username:string = "";
  password:string = "";
  secondPassword:string = "";
};

const changePassValidations = {
  password: {
    error: "",
    validate: "password",
    required: true,
  },
  secondPassword: {
    error: "",
    validate: "secondPassword",
    required: true,
  },
};

const ChangePassword: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker();
  const userContext = React.useContext(UserContext);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [formValues, setFormValues] = useState<initialChangePassValues>(new initialChangePassValues());
  const [formErrors, setFormErrors] = useState<initialChangePassValues>(new initialChangePassValues());
  const [auxError, setAuxError] = useState<string>('');
  const [clickDone, setClickDone] = useState<boolean>(false);
  const [sended, setSended] = useState<boolean>(false);
  const [ready, setReady]= useState<boolean>(false);
  const [serviceError, setServiceError]= useState('');
  const [openModalError, setOpenModalError] = React.useState<boolean>(false);

  const isValid = formValues.password.length > 0 &&
    formValues.secondPassword.length >0 &&
    !formErrors.password &&
    !formErrors.secondPassword &&
    formValues.password === formValues.secondPassword 
  
  useEffect(()=>{
    window.scrollTo(0, 0);
    setFormValues(prev => ({
      ...prev,
      username:userContext.username || ''
    }));
    setReady(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  const handleChange = async(e: any) => {
    const {name, value} = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }))
    const error: string = Validations(name, value, changePassValidations) || "";
    setFormErrors(prev=>({
        ...prev,
        [name]: error
    }));
  };

  useEffect(()=>{
    if(clickDone){
      if(formValues.password !== formValues.secondPassword){
        setAuxError('Verifique que las contraseñas coincidan');
      } else {
        setAuxError('')
      }
    }
  },[formValues, clickDone])

  const makeChangePassword = async () => {
    setClickDone(true)
    if(formValues.password === formValues.secondPassword){
      const credentials = {
        username:formValues.username,
        password: formValues.password
      }
      const responseCHP = await trackPromise(changePassword({...credentials, modifyUser: userContext.userId}));
      if (responseCHP !== undefined && responseCHP.state) {
        const temp = jwt.sign({ roleSelected: userContext.roleSelected, temp: false }, '1234@#');
        sessionStorage.setItem('temp', temp)
        setServiceError('');
        setOpenModalError(false);
        setSended(true);
      } else {
        setServiceError('Hubo un error');
        setOpenModalError(true);
        setSended(false);
      }
    }
  };

  const handleCloseModalError = ()=>{
    setOpenModalError(false);
}

  return (
    <>
      {ready &&
        <>
          {containsAny([2,3],[userContext.roleSelected]) ?
            userContext.temporaryKey ?
              <Grid container className="change-container">
                {!sended ?
                  <Grid container className="main-container">
                    <Grid container className="title-container" alignItems="center">
                      <SecurityIcon className="security-icon" />
                      <Typography variant="h3" className="title-text">
                        Actualizar mi contraseña
                      </Typography>
                      <Grid item xs={12} sm={8}>
                        <hr className="line-title" />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} className="password-container">
                      <Grid item xs={12}>
                        <Typography variant="h5" className="regular-serif">
                          Por favor ingrese su nueva contraseña. Su contraseña debe
                          tener:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className="policies-container">
                        <Grid item xs={12} className="policiesterm-box">
                          <ErrorOutlineIcon />
                          <Typography variant="h5" className="errorOutline-text">
                            Mínimo 4 LETRAS
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className="policiesterm-box">
                          <ErrorOutlineIcon />
                          <Typography variant="h5" className="errorOutline-text">
                            Mínimo una letra MAYÚSCULA
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className="policiesterm-box">
                          <ErrorOutlineIcon />
                          <Typography variant="h5" className="errorOutline-text">
                            Mínimo un NÚMERO
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item className="inputPassword-container">
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            name="password"
                            margin="none"
                            placeholder="Ingrese una nueva contraseña "
                            type={showPass ? "text" : "password"}
                            value={formValues.password}
                            onChange={handleChange}
                            error={!!formErrors.password}
                            helperText={formErrors.password}
                            variant="outlined"
                            required
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPass ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} className="inputPassword-title">
                          <Typography variant="h5" className="regular-serif">
                            Confirme su nueva contraseña
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            name="secondPassword"
                            margin="none"
                            placeholder="Confirme su nueva contraseña"
                            type={showConfirmPass ? "text" : "password"}
                            value={formValues.secondPassword}
                            onChange={handleChange}
                            error={!!formErrors.secondPassword}
                            helperText={formErrors.secondPassword}
                            variant="outlined"
                            required
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  onClick={handleClickShowConfirmPassword}
                                  edge="end"
                                >
                                  {showConfirmPass ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                      {
                        auxError.length>0 &&
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h5" className="error-password">
                            {auxError}
                          </Typography>
                        </Grid>
                      }
                      <Grid container item xs={12} className="submit-button">
                        <Button
                          variant="contained"
                          className="sub-btn"
                          disabled={!isValid}
                          onClick={makeChangePassword}
                        >
                          <Typography variant="h5" className="regular-serif">
                            ACTUALIZAR CONTRASEÑA
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                :
                  <Grid container className="main-container">
                    <ShowChangePasswordBox />
                  </Grid>
                }
                  <ErrorModal errorMsg={serviceError} handleCloseModal={handleCloseModalError} openModalError={openModalError}/>
                <LoadingLockPage load={promiseInProgress}/>
              </Grid>
            :
              <Redirect to="/empresa/dashboard"/>
          :
            <Redirect to="/"/>
          }
        </>
      }
    </>
  );
};

export default ChangePassword;
