const baseUrl = process.env.REACT_APP_SIE_APP_PROXY_BASE_URL;
const TOKEN_KEY = "Bearer";

export const createLaboralOffer = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
        token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/job-offer/create`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const getlistOffers = async (bodyRequest: object) => {
    try {
        const response = await fetch(
            `${baseUrl}/job-offer/list`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const getOffersNumberByState = async (stateName: string) => {
    try {
        const response = await fetch(
            `${baseUrl}/job-offer/amount`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({state: stateName})
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const getOffersByGraduate = async (graduateId: any) => {
    try {
        const response = await fetch(
            `${baseUrl}/job-offer/list/${graduateId}`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const updateLaboralOffers = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/job-offer/update`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}
